import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import omitBy from 'lodash/omitBy'
import head from 'lodash/head'
import queryString from 'query-string'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

import {
  useAdminOrders,
  useMediaQuery,
  useNavigation,
  useBeforeUnload,
  useLoaders
} from 'hooks'
import { URL } from 'constants/navigation'
import { REFUND_REASONS } from 'constants/general'
import { SIZE, COLOR, BUTTON_VARIANT } from 'constants/enums'

import Block from 'components/Block'
import Button from 'components/Button'
import Header from 'components/Header'
import Layout from 'components/Layout'
import RefundSummary from 'components/RefundSummary'
import ReturnItems from 'components/ReturnItems'
import CustomerDetails from 'components/CustomerDetails'
import StatusTags from 'components/StatusTags'
import Checkbox from 'components/Checkbox'
import Dialog from 'components/Dialog'
import AdditionalDetails from 'components/AdditionalDetails'

import styles from './OrderRefundStyles'

const initialValues = {
  line_item_refunds: [],
  credit_applied_refund_amount: 0,
  payment_method_refund_amount: 0,
  notify_customer: false,
  details: ''
}

const PAYMENT_CHECK_KEY = 'init_payment'
const CREDIT_CHECK_KEY = 'init_credit'

const OrderRefund = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  const { hideLoading, showLoading } = useLoaders()

  const [returnData, setReturnData] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [withoutReturn, setWithoutReturn] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [refundObject, setRefundObject] = useState(initialValues)
  const [initialObject, setInitialObject] = useState(initialValues)

  const { order, readOrderReturn, orderRefund } = useAdminOrders({ id })

  const { handleClick: handleUserClick } = useNavigation({
    url: URL.ADMIN_USERS
  })

  const initRefundObject = data => {
    const dataObject = {
      ...refundObject,
      line_item_refunds: data?.line_item_returns?.map(lineItem => ({
        line_item_id: lineItem.line_item.id,
        quantity: withoutReturn ? 0 : lineItem.quantity
      })),
      ...(data.id && { order_return_id: data.id })
    }
    setRefundObject(dataObject)
    setInitialObject({ ...dataObject, init_payment: true, init_credit: true })
  }

  useEffect(() => {
    ;(async () => {
      if (!order) return
      if (queryParams.return) {
        const response = await readOrderReturn(id, queryParams.return)
        Object.assign(response, { line_items: order.line_items })
        setReturnData(response)
        initRefundObject(response)
        hideLoading()
        setIsLoading(false)
        return
      }
      const data = {
        line_item_returns: order?.refundable_line_items.map(lineItem => ({
          id: lineItem.id,
          line_item: {
            id: lineItem.id,
            price: lineItem.price,
            quantity: 0,
            product_id: lineItem.product_id
          },
          quantity: 0
        }))
      }
      hideLoading()
      setWithoutReturn(true)
      initRefundObject(data)
      setIsLoading(false)
      if (isEmpty(order?.refundable_line_items)) {
        setReturnData({})
        return
      }
      setReturnData(data)
      setOpenDialog(true)
    })()
  }, [order])

  const breadcrumbMap = {
    default: [
      { title: 'Orders', link: URL.ADMIN_ORDERS },
      {
        title: `#${order?.number}`,
        link: `${URL.ADMIN_ORDERS}/${id}`
      }
    ]
  }

  const { isDesktopScreen, isTabletScreen, isMobileScreen } = useMediaQuery()

  const containerClasses = classNames({
    [classes.container]: true,
    [classes.isMobile]: Boolean(isMobileScreen),
    [classes.isTablet]: Boolean(isTabletScreen),
    [classes.isDesktop]: Boolean(isDesktopScreen)
  })

  const handleRefund = async () => {
    showLoading()
    setSubmitting(true)
    const response = await orderRefund(order.id, {
      ...refundObject,
      line_item_refunds: refundObject.line_item_refunds.filter(
        item => item.quantity !== 0
      )
    })
    hideLoading()
    if (response) history.push(`${URL.ADMIN_ORDERS}/${id}`)
    setSubmitting(false)
  }

  const handleChangeNotifyClient = checked =>
    setRefundObject({ ...refundObject, notify_customer: checked })

  const handleChangeStoreCreditRefund = value => {
    if (value && initialObject.init_credit) {
      setInitialObject(
        omitBy(
          {
            ...initialObject,
            credit_applied_refund_amount: parseFloat(value) * 100
          },
          (value, key) => key === CREDIT_CHECK_KEY
        )
      )
    }
    setRefundObject({
      ...refundObject,
      credit_applied_refund_amount: parseFloat(value) * 100
    })
  }

  const handleChangePaymentMethodRefund = value => {
    if (value && initialObject.init_payment) {
      setInitialObject(
        omitBy(
          {
            ...initialObject,
            payment_method_refund_amount: parseFloat(value) * 100
          },
          (value, key) => key === PAYMENT_CHECK_KEY
        )
      )
    }
    setRefundObject({
      ...refundObject,
      payment_method_refund_amount: parseFloat(value) * 100
    })
  }

  const handleItemChange = (itemId, value) => {
    setRefundObject(state => ({
      ...refundObject,
      line_item_refunds: state.line_item_refunds.map(lineItem =>
        lineItem.line_item_id === itemId
          ? {
              ...lineItem,
              quantity: parseFloat(value)
            }
          : lineItem
      )
    }))
    setReturnData(state => ({
      ...returnData,
      line_item_returns: state.line_item_returns.map(lineItem =>
        lineItem.line_item.id === itemId
          ? {
              ...lineItem,
              quantity: parseFloat(value),
              line_item: {
                ...lineItem.line_item,
                quantity: parseFloat(value)
              }
            }
          : lineItem
      )
    }))
  }

  const handleTextChange = e =>
    setRefundObject({
      ...refundObject,
      details: e.target.value
    })

  const handleSelectChange = e =>
    setRefundObject({
      ...refundObject,
      refund_reason: e.target.value
    })

  const objectHasChanged = !isEqual(
    refundObject,
    omitBy(
      initialObject,
      (value, key) => key === PAYMENT_CHECK_KEY || key === CREDIT_CHECK_KEY
    )
  )

  const { OnBeforeUnloadPrompt } = useBeforeUnload(
    objectHasChanged && !submitting
  )

  const actions = (
    <>
      <Button
        size={SIZE.medium}
        color={COLOR.secondary}
        variant={BUTTON_VARIANT.contained}
        label="Cancel"
        startIcon={<CloseIcon />}
        onClick={() => history.push(`${URL.ADMIN_ORDERS}/${id}`)}
      >
        Cancel
      </Button>
      <Button
        size={SIZE.medium}
        color={COLOR.primary}
        variant={BUTTON_VARIANT.contained}
        label="Refund"
        onClick={handleRefund}
      >
        Refund{' '}
        {refundObject?.payment_method_refund_amount
          ? `$${(refundObject?.payment_method_refund_amount / 100).toFixed(2)}`
          : null}
      </Button>
    </>
  )

  return (
    <Layout>
      {!isEmpty(order) && !isLoading && (
        <Box className={containerClasses}>
          <Header
            breadcrumbs={breadcrumbMap.default}
            title={withoutReturn ? 'Refund' : 'Refund Returned Items'}
            actions={actions}
            actionBox
          />
          <Box display="flex" gridGap={12}>
            <StatusTags order={order} />
          </Box>
          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid item sm={12} md={8} className={classes.gridItem}>
              <Block>
                {(!isEmpty(order?.refundable_line_items) ||
                  queryParams.return) && (
                  <Box className={classes.paddingBlock}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography data-test="order-items" variant="h5">
                        {withoutReturn ? 'Order Items' : 'Returned Items'}
                      </Typography>
                    </Box>

                    <Box className={classes.productsContainer}>
                      {withoutReturn &&
                        order.refundable_line_items.map((lineItem, idx) => (
                          <ReturnItems
                            key={lineItem.id}
                            product={head(
                              order.line_items.filter(
                                item => item.id === lineItem.id
                              )
                            )}
                            showQuantity
                            showSku
                            isOdd={idx % 2 !== 0}
                            onChange={handleItemChange}
                          />
                        ))}
                      {!withoutReturn &&
                        returnData?.line_item_returns.map((lineItem, idx) =>
                          lineItem.quantity > 0 ? (
                            <ReturnItems
                              key={lineItem.id}
                              product={head(
                                returnData?.line_items.filter(
                                  item => item.id === lineItem.line_item.id
                                )
                              )}
                              showQuantity
                              showSku
                              showVariantTitle
                              isOdd={idx % 2 !== 0}
                              returnQuantity={lineItem.quantity}
                              checked={refundObject.line_item_refunds.some(
                                item =>
                                  item.line_item_id === lineItem.line_item.id
                              )}
                            />
                          ) : null
                        )}
                    </Box>
                  </Box>
                )}

                {withoutReturn && (
                  <AdditionalDetails
                    onSelectChange={handleSelectChange}
                    onTextChange={handleTextChange}
                    textValue={refundObject.details}
                    selectValue={refundObject.refund_reason}
                    selectOptions={REFUND_REASONS}
                  />
                )}
              </Block>
            </Grid>
            <Grid item sm={12} md={4} className={classes.gridItem}>
              <Block autoHeight>
                <Box className={classes.paddingBlock}>
                  {returnData && (
                    <RefundSummary
                      onChangeNotifyClient={handleChangeNotifyClient}
                      onChangeStoreCreditRefund={handleChangeStoreCreditRefund}
                      onChangePaymentMethodRefund={
                        handleChangePaymentMethodRefund
                      }
                      order={order}
                      returnData={returnData}
                    />
                  )}
                </Box>
              </Block>
              <Block title="Customer Details" withPadding>
                <CustomerDetails user={order?.user} onClick={handleUserClick} />
                {order?.email && (
                  <Box>
                    <Button
                      href={`mailto:${order?.email}`}
                      color={COLOR.default}
                      size={SIZE.medium}
                      variant={BUTTON_VARIANT.outlined}
                    >
                      Email Customer
                    </Button>
                  </Box>
                )}
              </Block>
            </Grid>
          </Grid>
        </Box>
      )}
      {withoutReturn && (
        <Dialog
          open={openDialog}
          onCancel={() => history.push(`${URL.ADMIN_ORDERS}/${id}`)}
          onConfirm={() => setOpenDialog(false)}
          confirmText="Refund"
          title="Return Items Before Issuing Refunds"
        >
          <Box py={2}>
            <Typography variant="body1">
              If you want to track shipping on returned items, return items
              before issuing a refund. You can’t return items if they’ve already
              been refunded.
            </Typography>
          </Box>
        </Dialog>
      )}
      <OnBeforeUnloadPrompt />
    </Layout>
  )
}

OrderRefund.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object
}

export default withStyles(styles)(OrderRefund)
