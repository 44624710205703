import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import { withStyles, useTheme } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import { useLocationsContext, useUIContext } from 'context'
import { useAuth } from 'hooks'
import { isSwitchLocationsEnabled } from 'utils/user'
import { SIZE, COLOR, BUTTON_VARIANT } from 'constants/enums'
import { ADMIN_NAV_ROUTES, NAV_ROUTES, URL } from 'constants/navigation'

import Button from 'components/Button'
import FastAFLogo from 'components/FastAFLogo'
import DarkstoreIcon from 'icons/DarkstoreIcon'
import ChevronRightIcon from 'icons/ChevronRightIcon'

import styles from './SidenavStyles'

const Sidenav = ({
  classes,
  variant = 'permanent',
  open = false,
  onClick,
  onClose
}) => {
  const [activeNavValues, setActiveNavValues] = useState(null)
  const [openSub, setOpenSub] = useState(null)
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()
  const { currentUser } = useAuth()
  const { setShowSwitchDarkstoreModal } = useUIContext()
  const { location: darkstoreLocation, locations } = useLocationsContext()

  const isPathActive = path => location.pathname.startsWith(path)
  const isSwitchingLocationsActive = () => isSwitchLocationsEnabled(currentUser)

  const showNavItem = item =>
    !(!isSwitchingLocationsActive() && item.disableForSpecificLocation)

  useEffect(() => {
    const mainNavActive = Object.values(ADMIN_NAV_ROUTES).find(
      item => isPathActive(item.path) && item.value !== 'logout'
    )

    const subNavActive = Object.values(ADMIN_NAV_ROUTES).find(item =>
      item?.children?.find(ch => isPathActive(ch.path))
    )

    if (mainNavActive?.children) {
      const subNavPath = mainNavActive.children?.[0]?.path
      subNavPath && history.push(subNavPath)
      return
    }

    if (mainNavActive && !subNavActive) {
      setActiveNavValues({
        main: mainNavActive.value,
        sub: null
      })
      return
    }

    setActiveNavValues({
      main: subNavActive?.value,
      sub: subNavActive?.children.find(ch =>
        location.pathname.startsWith(ch.path)
      )?.value
    })
  }, [location])

  const onActionClick = (e, action) => {
    if (action.children) {
      setOpenSub(openSub === action ? null : action)
      return
    }

    onClick && onClick()
    setOpenSub(null)

    if (e?.ctrlKey || e?.metaKey) {
      window.open(action.path, '_blank')
      return
    }

    history.push(action.path)
  }

  if (!currentUser) return null

  return (
    <Drawer
      open={open}
      variant={variant}
      className={classes.root}
      classes={{
        paper: classes.drawerPaper
      }}
      onClose={onClose}
    >
      <List classes={{ root: classes.list }}>
        <ListItem
          className={classes.locationListItem}
          dense
          disableGutters
          button={isSwitchingLocationsActive()}
          onClick={() =>
            isSwitchingLocationsActive() && setShowSwitchDarkstoreModal(true)
          }
          data-test="change-location-button"
        >
          <ListItemIcon>
            <DarkstoreIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ root: classes.locationListText }}
            primary={
              <Typography variant="subtitle2" color="textPrimary">
                {locations ? darkstoreLocation?.name || 'Unknown' : 'Loading'}
              </Typography>
            }
          />
          {isSwitchingLocationsActive() && (
            <ListItemSecondaryAction
              classes={{ root: classes.locationListSecondary }}
            >
              <ChevronRightIcon />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>

      <List classes={{ root: classes.list }}>
        {currentUser &&
          ADMIN_NAV_ROUTES.map(
            (action, index) =>
              showNavItem(action) && (
                <Box key={index}>
                  <ListItem
                    button
                    key={action.value}
                    className={classNames({
                      [classes.listItem]: true,
                      [classes.listItemActive]:
                        action.value === activeNavValues?.main,
                      [classes.listItemSelected]:
                        activeNavValues?.main === action.value &&
                        !activeNavValues.sub
                    })}
                    onClick={e => onActionClick(e, action)}
                    onAuxClick={() => window.open(action.path, '_blank')}
                    data-test={`sidebar-menu-${action.text}`}
                  >
                    <ListItemIcon>
                      {['analytics', 'adminLicensePlates'].includes(
                        action.value
                      ) ? (
                        <action.icon
                          fontSize={24}
                          className={classes.navIcon}
                        />
                      ) : (
                        <action.icon className={classes.navIcon} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          {action.text}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {(action.value === activeNavValues?.main ||
                    action === openSub) &&
                    action.children?.map(ch => (
                      <ListItem
                        button
                        key={ch.value}
                        className={classNames({
                          [classes.listItem]: true,
                          [classes.subListItem]: true,
                          [classes.listItemSelected]:
                            activeNavValues?.sub === ch.value
                        })}
                        onClick={e => onActionClick(e, ch)}
                        onAuxClick={() => window.open(ch.path, '_blank')}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              {ch.text}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                </Box>
              )
          )}
      </List>
      <Box flex={1} />
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {currentUser ? (
          <>
            <List classes={{ root: classes.list }}>
              <ListItem
                className={classes.userListItem}
                dense
                disableGutters
                button
                onClick={e =>
                  history.push(`${URL.ADMIN_INTERNAL_USERS}/${currentUser.id}`)
                }
              >
                <ListItemText
                  primary={
                    <Typography variant="h5" color="textSecondary">
                      {currentUser.name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {currentUser.email}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </>
        ) : null}
      </Box>
      <Box px={3}>
        <Button
          color={COLOR.default}
          size={SIZE.small}
          variant={BUTTON_VARIANT.outlined}
          className={classes.logoutButton}
          onClick={e => onActionClick(e, NAV_ROUTES.logout)}
        >
          Logout
        </Button>
      </Box>
      <Box mt="38px" mb="34px" px={3}>
        <FastAFLogo width={73} fill={theme.palette.text.primary} />
      </Box>
    </Drawer>
  )
}

Sidenav.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func
}

export default withStyles(styles)(Sidenav)
