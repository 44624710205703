import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useLocationsContext } from 'context'
import { useAdminZipCodes, useMediaQuery } from 'hooks'

import Block from 'components/Block'
import ZipCodeMap from 'components/ZipCodeMap'
import ZipCodePanel from 'components/ZipCodePanel'
import ZipCodeModal from 'components/ZipCodeModal'

import styles from './ZipCodesPaneStyles'

const ZipCodesPane = ({
  classes,
  zipcodes,
  openModal,
  onChange,
  onCloseModal
}) => {
  const { isDesktopScreen } = useMediaQuery()
  const { showAllLocationIds, location } = useLocationsContext()
  const {
    updateZipCodes,
    updateBulkZipCodes,
    deleteZipCode
  } = useAdminZipCodes()

  const [modalOpen, setModalOpen] = useState(false)
  const [highlightedId, setHighlightedId] = useState(null)

  useEffect(() => {
    setModalOpen(openModal)
  }, [openModal])

  const handleUpdateZipCode = async (codeId, key, value) => {
    await updateZipCodes({ id: codeId, [key]: value })
    onChange && onChange()
  }

  const handleDeleteZipcodes = async ids => {
    await Promise.all(ids.map(id => deleteZipCode({ id })))
    onChange && onChange()
  }

  const closeModal = () => {
    setModalOpen(false)
    onCloseModal && onCloseModal()
  }

  const handleZipCodeSubmit = async zipCodeData => {
    await updateBulkZipCodes(zipCodeData)
    onChange && onChange()
  }

  return (
    <Box
      className={classNames({
        [classes.root]: true,
        [classes.isDesktop]: isDesktopScreen
      })}
    >
      <Grid className={classes.gridContainer} container spacing={3}>
        <Grid item sm={12} md={8} className={classes.gridItem}>
          <Block className={classes.mapContainer}>
            <ZipCodeMap
              location={location}
              zipcodes={zipcodes}
              highlightedId={highlightedId}
              setHighlightedId={setHighlightedId}
            />
          </Block>
        </Grid>
        <Grid item sm={12} md={4} className={classes.gridItem}>
          <Block className={classes.codesPanel}>
            {showAllLocationIds ? (
              <Box pt={4} pb={4} className={classes.allLocationsNotice}>
                <Typography variant="h5">
                  Select a location to edit Zipcodes.
                </Typography>
              </Box>
            ) : (
              <ZipCodePanel
                zipcodes={zipcodes}
                onZipCodeUpdate={handleUpdateZipCode}
                onDelete={handleDeleteZipcodes}
                setHighlightedId={setHighlightedId}
                highlightedId={highlightedId}
              />
            )}
          </Block>
        </Grid>
      </Grid>
      <ZipCodeModal
        open={modalOpen}
        onClose={closeModal}
        onSubmit={handleZipCodeSubmit}
      />
    </Box>
  )
}

ZipCodesPane.propTypes = {
  classes: PropTypes.object.isRequired,
  zipcodes: PropTypes.arrayOf(PropTypes.object),
  openModal: PropTypes.bool,
  onChange: PropTypes.func,
  onCloseModal: PropTypes.func
}

export default withStyles(styles)(ZipCodesPane)
