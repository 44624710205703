const jssStyles = theme => ({
  muiIconButton: {
    borderRadius: 5,
    width: '100%',
    height: '100%',
    padding: 0
  },
  circle: {
    borderRadius: '50%'
  },
  variantLight: {
    backgroundColor: theme.palette.grays.light,
    '&:hover': {
      backgroundColor: theme.palette.grays.med
    },
    '& path': {
      fill: theme.palette.common.black
    }
  },
  variantMedium: {
    backgroundColor: theme.palette.grays.med,
    '&:hover': {
      backgroundColor: theme.palette.grays.dark
    },
    '& path': {
      fill: theme.palette.common.black
    }
  },
  variantTransparent: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& path': {
      fill: theme.palette.grays.dark
    },
    '&:hover path': {
      fill: theme.palette.common.black
    }
  },
  variantBlack: {
    backgroundColor: theme.palette.common.black,
    '& path': {
      fill: theme.palette.common.white
    },
    '&:hover': {
      backgroundColor: theme.palette.common.black
    },
    '&:hover path': {
      fill: theme.palette.grays.light
    }
  },
  variantOutlined: {
    border: `1px solid ${theme.palette.grays.med}`,
    backgroundColor: theme.palette.common.white,
    '& path': {
      fill: theme.palette.grays.dark
    },
    '&:hover': {
      border: `1px solid ${theme.palette.grays.dark}`,
      backgroundColor: theme.palette.common.white
    },
    '&:hover path': {
      fill: theme.palette.common.black
    }
  },
  variantBlue: {
    backgroundColor: theme.palette.blues.fast,
    '& path': {
      fill: theme.palette.common.white
    },
    '&:hover': {
      backgroundColor: theme.palette.blues.dark
    },
    '&:hover path': {
      fill: theme.palette.common.white
    }
  }
})

export default jssStyles
