import { useEffect, useState } from 'react'

const useScrollPosition = (height = 0) => {
  const [hasScrolled, setScroll] = useState(0)

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > height
      if (scrollCheck !== hasScrolled) {
        setScroll(scrollCheck)
      }
    })
  }, [])

  return {
    hasScrolled
  }
}

export default useScrollPosition
