import { primary, grays } from 'theme/colors'

export default {
  root: {
    borderRadius: 2
  },
  bar: {
    borderRadius: 2
  },
  colorPrimary: {
    backgroundColor: grays.med
  },
  barColorPrimary: {
    backgroundColor: primary
  }
}
