import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import * as yup from 'yup'

import { makeObjectResolver } from 'constants/yupSchemas'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import PrintIcon from '@material-ui/icons/Print'

import { TextFieldControlled } from 'components/TextField'
import Select from 'components/Select'
import { PRINTER_TYPES } from 'constants/enums'
import Button from 'components/Button'

import styles from './PrinterConfigStyles'

const TEST_ID = 'printer'

const PRINTER_FIELDS = {
  printNode: 'print_node_id',
  name: 'name',
  type: 'type'
  // TODO(@cristobalchao): Uncomment when BE is ready
  // enabled: 'enabled'
}

const yupUserSchemas = {
  [PRINTER_FIELDS.printNode]: yup
    .number()
    .integer('Printer node have to be an integer')
    .typeError('Printer node have to be an integer')
    .required('Node is required'),
  [PRINTER_FIELDS.name]: yup.string().required('Name is required'),
  [PRINTER_FIELDS.type]: yup.string().required('Type is required')
  // TODO(@cristobalchao): Uncomment when BE is ready
  // [PRINTER_FIELDS.enabled]: yup.boolean()
}

const PrinterForm = ({
  classes,
  printer,
  onCancel,
  onSuccess,
  onDelete,
  onTest
}) => {
  const defaultValues = {
    [PRINTER_FIELDS.printNode]: printer?.print_node_id ?? '',
    [PRINTER_FIELDS.name]: printer?.name ?? '',
    [PRINTER_FIELDS.type]: printer?.type ?? ''
    // [PRINTER_FIELDS.enabled]: printer?.enabled ?? true
  }
  const formContext = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: makeObjectResolver(yupUserSchemas),
    defaultValues
  })

  const { reset, handleSubmit, errors } = formContext

  useEffect(() => {
    reset(defaultValues)
  }, [reset, printer])

  const handleCancel = () => {
    onCancel && onCancel()
  }

  const handleAccept = data => {
    onSuccess(data)
  }

  return (
    <Box className={classes.printerForm}>
      <FormProvider {...formContext}>
        <Box className={classes.testButton}>
          <IconButton disabled={!(printer?.print_node_id && printer?.type)}>
            <PrintIcon onClick={onTest} />
          </IconButton>
        </Box>
        <Box className={classes.delButton}>
          <IconButton>
            <DeleteIcon onClick={onDelete} />
          </IconButton>
        </Box>
        <Typography variant="body1">PrinterNode Id</Typography>
        <TextFieldControlled
          name={PRINTER_FIELDS.printNode}
          placeholder="Enter a node"
          number
          fullWidth
          centeredInput
          dataTest={`${TEST_ID}-${PRINTER_FIELDS.printNode}`}
        />
        <Typography variant="body1">Name</Typography>
        <TextFieldControlled
          name={PRINTER_FIELDS.name}
          placeholder="Enter a name"
          fullWidth
          centeredInput
          dataTest={`${TEST_ID}-${PRINTER_FIELDS.name}`}
        />
        <Typography variant="body1">Type</Typography>
        <Controller
          name={PRINTER_FIELDS.type}
          render={({ ref, ...methods }) => (
            <Select
              name={PRINTER_FIELDS.type}
              error={Boolean(errors[PRINTER_FIELDS.type])}
              helperText={
                errors[PRINTER_FIELDS.type] &&
                errors[PRINTER_FIELDS.type].message
              }
              items={[
                { label: 'Barcode', value: PRINTER_TYPES.BARCODE },
                { label: 'Label', value: PRINTER_TYPES.LABEL }
              ]}
              {...methods}
              inputRef={ref}
              onChange={e => methods.onChange(e.target.value)}
            />
          )}
        />
        {/* TODO(@cristobalchao): Uncomment when BE is ready
            <Typography variant="body1">Enable</Typography>
            <Controller
            name={PRINTER_FIELDS.enabled}
            render={({ ref, ...methods }) => (
            <Select
            name={PRINTER_FIELDS.enabled}
            disabled
            error={Boolean(errors[PRINTER_FIELDS.enabled])}
            helperText={
            errors[PRINTER_FIELDS.enabled] &&
            errors[PRINTER_FIELDS.enabled].message
            }
            items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
            ]}
            {...methods}
            inputRef={ref}
            onChange={e => methods.onChange(e.target.value)}
            />
            )}
            /> */}
      </FormProvider>
      <Box display="flex" flexDirection="row-reverse">
        <Button size="small" onClick={handleSubmit(handleAccept)}>
          Accept
        </Button>
        <Box ml={2} />
        <Button size="small" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Box ml={1} />
      </Box>
    </Box>
  )
}

PrinterForm.propTypes = {
  classes: PropTypes.object.isRequired,
  printer: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default withStyles(styles)(PrinterForm)
