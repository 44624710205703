import React from 'react'
import PropTypes from 'prop-types'

import { TableRow, TableCell, Typography } from '@material-ui/core'

import {
  useAdminPlateList,
  useAdminPlate
} from 'hooks'

import { useLocationsContext } from 'context'

import DataTable from 'components/DataTable'
import Button from 'components/Button'

const StockLevels = ({ variant }) => {
  const { locationId } = useLocationsContext()
  const {
    isFetchingPlates,
    plates,
  } = useAdminPlateList({ params: { location_id: locationId, search: variant?.sku } })
  const platesIn900A1 = plates.filter(p => p.item_location.label === '900.A.1')

  const plate = platesIn900A1.length > 0 ? platesIn900A1[0] : {}
  const { createPlate, updatePlate, isLoadingPlate } = useAdminPlate(plate?.id)

  const title = 'Available For Sale'
  const quantity = variant?.available_for_sale_inventory?.quantity ?? 0

  // This is just a quick way for testing or demos to add inventory.
  // It shouldn't be used in general as inventory should be managed by
  // the warehouse.
  const handleAddQuantityClick = () => {
    if (platesIn900A1.length > 0) {
      updatePlate({ quantity: plate.quantity + 10, audit_comment: 'admin adjustment' })
    } else {
      createPlate({ variant_id: variant.id, location_id: locationId, label: '900.A.1', quantity: 10 })
    }
  }

  return (
    <DataTable
      columns={[
        { title: 'Stock Location' },
        { title: 'Quantity' },
        { title: '' } // extra column for menu options
      ]}
    >
      <TableRow key="available-for-sale-stock-levels">
        <TableCell>
          <Typography variant="body1">{title}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">{quantity}</Typography>
        </TableCell>
        <TableCell>
          <Button disabled={isFetchingPlates || isLoadingPlate} onClick={handleAddQuantityClick}>+10 (900.A.1)</Button>
        </TableCell>
      </TableRow>
    </DataTable>
  )
}

StockLevels.propTypes = {
  productId: PropTypes.number.isRequired,
  variant: PropTypes.object.isRequired
}

export default StockLevels
