import React from 'react'
import PropTypes from 'prop-types'

import { useLocationsContext } from 'context'
import * as propTypes from 'constants/propTypes'
import { ALL_LOCATION } from 'constants/general'
import { COLLECTION_LAYOUT_VARIANT } from 'constants/enums'
import { getBrandName } from 'utils/general'

import Box from '@material-ui/core/Box'
import CollectionBlock from 'components/CollectionBlock'
import CollectionGroupCard from 'components/CollectionGroupCard.jsx'

const CollectionVirtualizedCell = ({
  data: {
    items,
    variant,
    selections,
    columnCount,
    onSelect,
    withRemove,
    onItemClick,
    menu,
    showPublish
  },
  columnIndex,
  rowIndex,
  style
}) => {
  const item = items[rowIndex * columnCount + columnIndex]
  const isBrand = variant === COLLECTION_LAYOUT_VARIANT.brand
  const isGroup = variant === COLLECTION_LAYOUT_VARIANT.group
  const { getLocationAtId, locationId } = useLocationsContext()

  if (!item) {
    return <Box style={style} />
  }

  if (isGroup) {
    return (
      <Box style={style} px={1} py={1} data-test="collection-item">
        <CollectionGroupCard collectionGroup={item} onItemClick={onItemClick} />
      </Box>
    )
  }

  // If it is a collection, check where it is published.
  const collectionPublishedInLocation =
    locationId === ALL_LOCATION.id
      ? item.location_ids?.length > 0
      : item.location_ids?.includes(locationId)

  // If we are viewing all locations, collections get a tooltip like: [LA, NYC,]
  const tooltipMessage =
    locationId === ALL_LOCATION.id && !isBrand
      ? item.location_ids.map(id => getLocationAtId(id)?.code).join(', ')
      : null

  const itemActive = isBrand ? item?.active : collectionPublishedInLocation

  return (
    <Box style={style} px={1} py={1} data-test="collection-item">
      <CollectionBlock
        item={item}
        selected={selections ? Boolean(selections[item?.id]) : null}
        onSelect={onSelect}
        withRemove={withRemove}
        onItemClick={onItemClick}
        title={
          isBrand ? getBrandName(item) : !item.image_url ? item.title : null
        }
        imageUrl={isBrand ? item?.cover_photo_url : item?.image_url}
        active={itemActive}
        menu={menu}
        tooltipMessage={tooltipMessage}
        showPublish={showPublish}
      />
    </Box>
  )
}

CollectionVirtualizedCell.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    variant: propTypes.collectionLayoutVariant,
    selections: PropTypes.object,
    columnCount: PropTypes.number.isRequired,
    onSelect: PropTypes.func,
    withRemove: PropTypes.bool,
    onItemClick: PropTypes.func,
    menu: PropTypes.arrayOf(PropTypes.object),
    locationId: PropTypes.number,
    showPublish: PropTypes.bool
  }),
  columnIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  style: PropTypes.object
}

export default CollectionVirtualizedCell
