export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 16px)'
  },
  gridContainer: {
    flex: 1,
    height: 'calc(100% - 80px)',
    overflow: 'hidden'
  },
  isDesktop: {
    '& $gridContainer': {
      marginTop: 0,
      flex: 'none',
      height: 'calc(100% - 32px)',
      overflow: 'auto'
    }
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    width: '100%',
    height: '100%'
  },
  mapContainer: {
    height: '100%',
    '&:last-child': {
      height: '100%'
    }
  },
  allLocationsNotice: {
    textAlign: 'center',
    color: theme.palette.grays.dark
  },
  codesPanel: {
    overflow: 'auto',
    height: '100%'
  }
})
