import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { uniqBy } from 'lodash'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import { SIZE, PRODUCT_RESULTS_POPOVER_TYPES } from 'constants/enums'
import { useAdminDistributionCenterVariantsList } from 'hooks'

import Button from 'components/Button'
import Drawer from 'components/Drawer'
import ProductSummary from 'components/ProductSummary'
import TextField from 'components/TextField'
import ProductResultsPopover from 'components/ProductResultsPopover'

import styles from './PurchaseOrderVariantsDrawerStyles'

const PurchaseOrderVariantsDrawer = ({
  classes,
  open,
  onClose,
  onSubmit,
  orderVariants,
  supplierId,
  distributionCenterId,
  location
}) => {
  const [searchString, setSearchString] = useState('')

  const {
    distributionCenterVariants: variants
  } = useAdminDistributionCenterVariantsList({
    supplierId,
    distributionCenterId,
    fetchingEnabled: !!distributionCenterId && open,
    params: { search: searchString }
  })
  const [selectedVariants, setSelectedVariants] = useState([])
  const [quantities, setQuantities] = useState({})

  const handleClose = () => {
    setSelectedVariants([])
    setSearchString('')
    onClose && onClose()
  }

  const handleSearchChange = e => {
    setSearchString(e.target.value)
  }

  const handleSubmit = () => {
    handleClose()
    onSubmit(
      selectedVariants
        .filter(v => quantities[v.variant_id])
        .map(v => {
          return { id: v.variant_id, quantity: quantities[v.variant_id] }
        })
    )
  }

  const handleAddVariant = item => {
    setSelectedVariants(uniqBy([...selectedVariants, item], v => v.variant_id))
  }

  const handleRemoveVariant = currVariant => {
    setSelectedVariants(
      selectedVariants.filter(
        variant => variant.variant_id !== currVariant.variant_id
      )
    )
  }

  const handleQuantityChange = (id, val) => {
    const num = parseInt(val)
    if (num >= 0) {
      setQuantities({ ...quantities, [id]: num })
    } else {
      setQuantities({ ...quantities, [id]: '' })
    }
  }

  const drawerActions = (
    <Button
      fullWidth
      disabled={
        selectedVariants.length === 0 ||
        !selectedVariants.every(
          v => quantities[v.variant_id] && quantities[v.variant_id] > 0
        )
      }
      onClick={handleSubmit}
      dataTest="add-to-po-button"
    >
      Add to PO
    </Button>
  )

  return (
    <Drawer
      title="Add Products"
      open={open}
      onClose={handleClose}
      actions={drawerActions}
    >
      <Box className={classes.container}>
        <Box>
          <Typography variant="h6">Adding For</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">{location}</Typography>
        </Box>

        <Box mt={4} mb={2}>
          <Typography variant="h6">Search Products</Typography>
        </Box>

        <ProductResultsPopover
          options={variants}
          selectedOptions={selectedVariants}
          onTextChange={handleSearchChange}
          onAddItem={handleAddVariant}
          onRemoveItem={handleRemoveVariant}
          activeProducts={orderVariants}
          type={PRODUCT_RESULTS_POPOVER_TYPES.po}
        />

        <Box mt={4}>
          {selectedVariants.map((variant, i) => (
            <Box key={variant.variant_id} className={classes.selectedItem}>
              <Box className={classes.productSummary}>
                <ProductSummary
                  product={{
                    ...variant.product,
                    ...variant
                  }}
                  showPrice
                />
              </Box>
              <Box className={classes.itemActions}>
                <IconButton
                  edge="end"
                  onClick={() => handleRemoveVariant(variant)}
                >
                  <RemoveCircleIcon />
                </IconButton>
                <Box className={classes.quantity}>
                  <Box mr="1px">Qty</Box>
                  <TextField
                    dataTest={'product-quantity' + i}
                    size={SIZE.small}
                    value={quantities[variant.variant_id] || ''}
                    onChange={e =>
                      handleQuantityChange(variant.variant_id, e.target.value)
                    }
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  )
}

PurchaseOrderVariantsDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  brandId: PropTypes.number,
  location: PropTypes.string,
  orderVariants: PropTypes.arrayOf(PropTypes.object)
}

export default withStyles(styles)(PurchaseOrderVariantsDrawer)
