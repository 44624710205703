import { createTheme } from '@material-ui/core/styles'

import layout from 'theme/layout'
import { zIndex } from 'theme/general'
import { lightPalette, darkPalette } from 'theme/palette'
import overrides from 'theme/overrides'
import typography from 'theme/typography'

export const darkTheme = createTheme({
  layout,
  palette: darkPalette,
  overrides,
  typography,
  zIndex
})

export const lightTheme = createTheme({
  layout,
  palette: lightPalette,
  overrides,
  typography,
  zIndex
})

export default lightTheme
