import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'

import { useAuth, useMediaQuery } from 'hooks'

import Sidenav from 'components/Sidenav'

const ResponsiveHeader = ({ classes, children }) => {
  const { isDesktopScreen } = useMediaQuery()
  const { currentUser } = useAuth()

  const [isSidenavOpen, setIsSidenavOpen] = useState(false)

  const toolbarContainerClassNames = classNames({
    [classes.toolbarContainer]: true
  })

  return (
    <>
      {currentUser && (
        <Box className={toolbarContainerClassNames}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            onClick={() => setIsSidenavOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      )}
      <>
        <Sidenav
          variant={isDesktopScreen ? 'temporary' : 'permanent'}
          open={isSidenavOpen}
          onClick={() => setIsSidenavOpen(false)}
          onClose={() => setIsSidenavOpen(false)}
        />
        {children}
      </>
    </>
  )
}

ResponsiveHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
}

export default withStyles(theme => ({
  root: {
    width: theme.layout.drawerWidth
  },
  toolbarContainer: {
    alignItems: 'flex-start',
    minHeight: 80,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    position: 'absolute'
  },
  toolbarContainerDesktopLarge: {},
  menuButton: { marginTop: theme.spacing(1), color: theme.palette.text.primary }
}))(ResponsiveHeader)
