const jssStyles = theme => ({
  root: {
    '& .DateRangePicker': {
      width: '100%'
    },
    '& .DateRangePickerInput': {
      width: '100%',
      display: 'flex'
    },
    '& .DateRangePicker_picker': {
      zIndex: 999
    },
    '& .DateInput_input': {
      textAlign: 'center'
    },
    '& .DateRangePickerInput_calendarIcon': {
      margin: 0,
      padding: theme.spacing(0.5),
      '& svg': {
        verticalAlign: 'middle'
      }
    },
    '& .DateInput_input__focused': {
      borderBottom: `2px solid ${theme.palette.blues.fast}`
    },
    '& .CalendarMonth_table': {
      marginTop: theme.spacing(0.5)
    },
    '& .CalendarDay__selected_span': {
      background: theme.palette.blues.dark,
      color: 'white',
      border: '1px solid white'
    },
    '& .CalendarDay__selected': {
      background: theme.palette.blues.fast,
      color: 'white',
      border: '1px solid white'
    },
    '& .CalendarDay__selected:hover': {
      background: theme.palette.blues.light,
      color: 'white',
      border: '1px solid white'
    },
    '& .CalendarDay__hovered_span:hover, .CalendarDay__hovered_span': {
      background: theme.palette.blues.light,
      border: '1px solid white',
      color: 'white'
    },
    '& .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover': {
      background: theme.palette.blues.light,
      border: '1px solid white'
    },
    '& .DateInput__small': {
      width: 65
    },
    '& .DateInput_input__small': {
      fontSize: 14,
      lineHeight: '14px',
      fontWeight: 400
    },
    '& .DateInput_fang': {
      top: '34px !important',
      left: '38px'
    },
    '& .DateRangePickerInput_arrow': {
      color: theme.palette.grays.med
    }
  },
  resetButton: {
    color: theme.palette.blues.fast,
    fontWeight: 'bold',
    fontVariant: 'all-petite-caps',
    minWidth: 0,
    margin: '12px 0px',
    padding: '2px 8px',
    height: 'auto'
  }
})

export default jssStyles
