import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

import { FiTrash } from 'react-icons/fi'

import { TableRow, TableCell, IconButton } from '@material-ui/core/'

import { DISTRIBUTION_CENTER_VARIANT_FIELDS } from 'constants/suppliers'

import { TextFieldControlled } from 'components/TextField'

import { SIZE } from 'constants/enums'
import { variantFieldName } from './DistributionCenterForm'
import { colorsAF } from 'theme/colors'

const InputField = ({
  id,
  name,
  isNumeric,
  endAdornment,
  helperText,
  noAdornment
}) => {
  const rest = { endAdornment }
  const adornment = isNumeric && !endAdornment && !noAdornment ? '$' : null
  return (
    <TableCell>
      <TextFieldControlled
        name={variantFieldName(name, id)}
        number={isNumeric}
        size={SIZE.small}
        skeletonUntilDefault
        dataTest={`variant-${name}`}
        startAdornment={adornment}
        noMarginBottom
        fullWidth
        {...rest}
      />
      {helperText && <Typography variant="caption">{helperText}</Typography>}
    </TableCell>
  )
}

const FIELDS = [
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.unit_cost,
    isNumeric: true
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_order_quantity,
    isNumeric: true,
    noAdornment: true
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_order_value,
    isNumeric: true
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_case_pack_quantity,
    isNumeric: true,
    noAdornment: true
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.case_cost,
    isNumeric: true
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.case_length,
    isNumeric: true,
    endAdornment: 'in'
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.case_width,
    isNumeric: true,
    endAdornment: 'in'
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.case_height,
    isNumeric: true,
    endAdornment: 'in'
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.case_weight,
    isNumeric: true,
    endAdornment: 'lbs'
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.case_upc
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.case_pack_quantity,
    isNumeric: true,
    noAdornment: true
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.lead_time,
    isNumeric: true,
    endAdornment: 'days'
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.shelf_life_guarantee_at_delivery,
    isNumeric: true,
    endAdornment: 'days'
  },
  {
    name: DISTRIBUTION_CENTER_VARIANT_FIELDS.total_shelf_life,
    isNumeric: true,
    endAdornment: 'days'
  }
]

const when = value => (value && ` ${value}`) || ''

const DistributionCenterVariantItem = ({
  variant,
  deleteVariant,
  evenOrOdd,
  sticky
}) => (
  <TableRow>
    <TableCell
      style={{
        ...(sticky && {
          position: 'sticky',
          left: 0,
          zIndex: 2,
          backgroundColor: evenOrOdd ? 'white' : colorsAF.ultraLightGray,
          width: 500,
          overflowX: 'hidden',
          minHeight: 70,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingLeft: 8,
          borderRight: `1px solid ${colorsAF.lightGray}`
        })
      }}
    >
      {`${variant.product.title}${when(variant.product?.size)}${when(
        variant.product?.color
      )}`}
    </TableCell>
    <TableCell>{variant.sku}</TableCell>
    {FIELDS.map(field => ({
      ...field,
      ...{
        id: variant.id
      }
    })).map((field, i) => (
      <InputField {...field} key={field.name} />
    ))}
    <TableCell align="right" data-test="table-row-delete-button">
      <IconButton edge="end" onClick={() => deleteVariant([variant])}>
        <FiTrash />
      </IconButton>
    </TableCell>
  </TableRow>
)

DistributionCenterVariantItem.propTypes = {
  variant: PropTypes.object.isRequired,
  deleteVariant: PropTypes.func.isRequired
}

export default DistributionCenterVariantItem
