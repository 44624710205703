import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'

import { SIZE, COLLECTION_LAYOUT_VARIANT } from 'constants/enums'

import {
  COLLECTIONS_TYPE_OPTIONS,
  COLLECTION_RELATIONSHIP,
  COLLECTION_RELATIONSHIP_OPTIONS,
  IS_PARENT,
  IS_CHILD,
  COLLECTION_RELATIONSHIP_VALUES
} from 'constants/collections'

import { URL } from 'constants/navigation'
import { LIST_PATHS } from 'constants/queryParams'
import { useLocationsContext } from 'context'
import {
  useAdminCollectionsList,
  useAdminCollections,
  useNavigation,
  useQuery,
  useAlerts
} from 'hooks'

import Header from 'components/Header'
import Button from 'components/Button'
import SearchInput from 'components/SearchInput'
import CollectionLayout from 'components/CollectionLayout'
import GlanceTile from 'components/GlanceTile'
import Select from 'components/Select'
import CreateCollectionModal from 'components/CreateCollectionModal'

const ALL_TYPES = ''
const PUBLISHED_OPTIONS = [
  { label: 'Published', value: 'true' },
  { label: 'Unpublished', value: 'false' },
  { label: 'All Statuses', value: ALL_TYPES }
]
const TYPE_OPTIONS = COLLECTIONS_TYPE_OPTIONS.concat({
  value: ALL_TYPES,
  label: 'All'
})

const CollectionList = () => {
  const { showAlertError, showAlertSuccess } = useAlerts()

  const { query, handleQueryChange, updateQuery } = useQuery(false)

  const {
    locationId,
    locationOptionsWithAll,
    showAllLocationIds
  } = useLocationsContext()

  const { handleClick: navigateToCollection } = useNavigation({
    url: URL.ADMIN_COLLECTIONS
  })

  const {
    isLoadingCollections,
    collections,
    hasCollections,
    hasCollectionsNext,
    fetchCollectionsNext,
    collectionsMeta
  } = useAdminCollectionsList(query)

  const { createCollection, updatePublishStatuses } = useAdminCollections(
    null,
    { onSuccess: showAlertSuccess, onError: showAlertError }
  )

  const [createCollectionModalOpen, setCreateCollectionModalOpen] = useState(
    false
  )

  const manageCollectionPublishState = (
    manageCollections,
    published,
    locationIds
  ) => {
    updatePublishStatuses({
      collection_ids: manageCollections.map(c => c.id),
      location_ids: locationIds,
      published
    })
  }

  const foldableActions = (
    <SearchInput
      name="search"
      placeholder="Search Collections"
      value={query.search}
      onChange={handleQueryChange}
      dataTest="collection-search"
    />
  )

  const handlePublishStatusChange = ({ target: { value } }) => {
    if (!showAllLocationIds) {
      updateQuery({
        [LIST_PATHS.COLLECTIONS_PUBLISHED]: value,
        [LIST_PATHS.LOCATION_ID]: value === ALL_TYPES ? '' : locationId
      })
      return
    }
    updateQuery({
      [LIST_PATHS.COLLECTIONS_PUBLISHED]: value
    })
  }

  const actions = (
    <Button
      adaptive
      label="add collection button"
      startIcon={<AddIcon />}
      color="primary"
      variant="contained"
      size={SIZE.medium}
      onClick={() => setCreateCollectionModalOpen(true)}
      dataTest="add-collection-button"
    >
      Add Collection
    </Button>
  )

  const getSelectedRelationship = () => {
    if (query[IS_CHILD]) return COLLECTION_RELATIONSHIP_VALUES.CHILD
    if (query[IS_PARENT]) return COLLECTION_RELATIONSHIP_VALUES.PARENT
    return COLLECTION_RELATIONSHIP_VALUES.ALL
  }

  const filters = (
    <>
      {showAllLocationIds && (
        <Select
          minimal
          name={LIST_PATHS.LOCATION_ID}
          innerLabel="Location"
          value={query[LIST_PATHS.LOCATION_ID] || ''}
          items={locationOptionsWithAll ?? []}
          onChange={handleQueryChange}
          dataTest="collection-location"
        />
      )}
      <Select
        minimal
        name={LIST_PATHS.COLLECTIONS_PUBLISHED}
        innerLabel="Publish Status"
        value={query[LIST_PATHS.COLLECTIONS_PUBLISHED] || ''}
        items={PUBLISHED_OPTIONS}
        onChange={handlePublishStatusChange}
        dataTest="collection-publish-status"
      />
      <Select
        minimal
        name={LIST_PATHS.COLLECTIONS_COLLECTION_TYPE}
        innerLabel="Collection Type"
        value={query[LIST_PATHS.COLLECTIONS_COLLECTION_TYPE] || ''}
        items={TYPE_OPTIONS}
        onChange={handleQueryChange}
      />
      <Select
        minimal
        innerLabel="Relationship"
        value={getSelectedRelationship()}
        items={COLLECTION_RELATIONSHIP_OPTIONS}
        onChange={({ target: { value } }) =>
          updateQuery(COLLECTION_RELATIONSHIP[value])
        }
      />
    </>
  )

  const summarizers = (
    <GlanceTile
      minimal
      label="Total Collections"
      value={collectionsMeta?.total_count}
    />
  )

  return (
    <Box data-test="collections-list-page" height="100%" width="100%">
      <Header
        title="Collections"
        foldableActions={foldableActions}
        summarizers={summarizers}
        filters={filters}
        actions={actions}
      />
      <CollectionLayout
        items={collections}
        variant={COLLECTION_LAYOUT_VARIANT.default}
        hasItems={hasCollections}
        hasItemsNext={hasCollectionsNext}
        listItemsNext={fetchCollectionsNext}
        onItemClick={navigateToCollection}
        isLoading={isLoadingCollections}
        onManageSubmit={manageCollectionPublishState}
        currentPage={collectionsMeta?.page}
      />
      <CreateCollectionModal
        open={createCollectionModalOpen}
        createCollection={createCollection}
        onClose={() => setCreateCollectionModalOpen(false)}
      />
    </Box>
  )
}

export default CollectionList
