export default theme => ({
  pdpButton: {
    color: theme.palette.colorsAF.darkGray,
    backgroundColor: theme.palette.colorsAF.ultraLightGray,

    '&:hover': {
      backgroundColor: theme.palette.colorsAF.semiGray
    }
  }
})
