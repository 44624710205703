export const STATUS_FILTER_ITEMS = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  }
]

export const PARTNER_OPTIONS = [
  {
    label: 'Partner',
    value: 'partner'
  },
  {
    label: 'Non Partner',
    value: 'non-partner'
  },
  {
    label: 'Exclusive',
    value: 'exclusive'
  },
  {
    label: 'Not Exclusive',
    value: 'not-exclusive'
  },
  {
    label: 'On Amazon',
    value: 'on-amazon'
  },
  {
    label: 'Not On Amazon',
    value: 'not-on-amazon'
  },
  {
    label: 'On Other qComm',
    value: 'on-qcomm'
  },
  {
    label: 'Not On Other qComm',
    value: 'not-on-qcomm'
  }
]

export const GROUPED_STATUS_FILTER_ITEMS = [
  {
    group: 'Active Status',
    items: STATUS_FILTER_ITEMS
  },
  {
    group: 'Partner Status',
    items: PARTNER_OPTIONS
  }
]
