import { cloneDeep } from 'lodash'

import { api } from 'api'
import { useResource, useResourceList, useAlerts } from 'hooks'
import { SOLD_OUT_INVENTORY } from 'constants/queryParams'
import { FORM_FIELDS } from 'constants/products'

const API_PRODUCTS = '/api/v1/admin/products'
const API_NUTRITION_FACTS = '/api/v1/admin/nutrition_facts'
const API_NUTRIENTS = '/api/v1/admin/nutrients'
const API_TAXJAR_TAX_CODES = '/api/v1/admin/tax_categories'

const API_PRODUCT_BADGES = '/api/v1/admin/badges'
const API_PRODUCT_STATUS_CODES = '/api/v1/admin/status_codes'
const API_PRODUCT_TAGS = '/api/v1/admin/tags'
const API_PARENT_CATEGORIES = '/api/v1/admin/categories'
const API_SUB_CATEGORIES = '/api/v1/admin/subcategories'

const API_PRODUCT_SIZES = '/api/v1/admin/sizes'
const API_PRODUCT_ACCESSORY_OPTIONS = '/api/v1/admin/accessory_options'

const useAdminProducts = ({ id }) => {
  const { showAlertError } = useAlerts()

  const {
    isLoading: isLoadingProducts,
    data: products,
    hasData: hasProducts,
    hasNext: hasProductsNext,
    list: listProducts,
    listNext: listProductsNext,
    updateItemsLocally: updateProductsLocally
  } = useResourceList({ url: API_PRODUCTS })

  const {
    data: product,
    read: readProduct,
    isLoading: isLoadingProduct,
    hasData: hasProduct,
    setData: setProductLocally,
    destroy: deleteProduct
  } = useResource({
    url: API_PRODUCTS
  })

  const archiveProduct = async productId => {
    try {
      const res = await api.put(`${API_PRODUCTS}/${productId}/archive`)
      return res.data
    } catch (e) {
      console.error(e)
      showAlertError(e?.data?.message)
    }
  }

  // will restore a product from deleted or archived status
  const restoreProduct = async productId => {
    try {
      const res = await api.put(`${API_PRODUCTS}/${productId}/restore`)
      return res.data
    } catch (e) {
      console.error(e)
      showAlertError(e?.data?.message)
    }
  }

  const createProduct = async (data, variantData) => {
    const name = 'product'
    try {
      const res = await api.post(API_PRODUCTS, {
        [name]: data,
        batch_create_variants: variantData
      })
      return res.data
    } catch (e) {
      showAlertError(e.data?.message ?? `${name} error`)
    }
  }

  const {
    list: getProductsWithSoldOutVariantsCount,
    count: productsWithSoldOutVariantsCount
  } = useResourceList({
    url: API_PRODUCTS
  })

  const uploadProductImages = async (productId, files) => {
    try {
      const formData = new FormData()
      files.forEach(file => {
        formData.append('product[upload_images_files][]', file)
      })
      const res = await api.put(`${API_PRODUCTS}/${productId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return res.data
    } catch (e) {
      console.error(e)
    }
  }

  const createVariant = async (productId, data) => {
    try {
      const res = await api.post(`${API_PRODUCTS}/${productId}/variants`, {
        product_id: productId,
        variant: data
      })
      return res.data
    } catch (e) {
      console.error(e)
      showAlertError(e?.data?.message)
    }
  }

  const fetchProductsWithSoldOutVariantsCount = () =>
    getProductsWithSoldOutVariantsCount(SOLD_OUT_INVENTORY)

  const updateProduct = async (productId, updates) => {
    try {
      await api.put(`${API_PRODUCTS}/${productId}`, {
        product: updates
      })
      return true
    } catch (e) {
      console.error(e)
      showAlertError(e?.data?.message)
      return false
    }
  }

  const generateNutritionFactsRequestBody = formFields =>
    Object({
      ingredients: formFields[FORM_FIELDS.INGREDIENTS],
      allergens: formFields[FORM_FIELDS.ALLERGENS],
      calories_per_serving: formFields[FORM_FIELDS.CALORIES_PER_SERVING],
      calories_from_fat_per_serving:
        formFields[FORM_FIELDS.FAT_CALORIES_PER_SERVING],
      serving_size: formFields[FORM_FIELDS.SERVING_SIZE],
      servings_per_container: formFields[FORM_FIELDS.SERVINGS_PER_CONTAINER],
      nutrients: formFields[FORM_FIELDS.NUTRITION_FACTS]?.data
        ?.filter(line => !!line?.name && (!!line?.value || !!line?.percent))
        ?.map(line => {
          return {
            name: line?.name,
            value: line?.value,
            percent: line?.percent
          }
        })
    })

  const createNutritionFacts = async data => {
    try {
      await api.post(API_NUTRITION_FACTS, {
        nutrition_fact: data
      })
      return true
    } catch (e) {
      console.error(e)
      showAlertError(e?.data?.message)
      return false
    }
  }

  const updateNutritionFacts = async (nutritionFactId, updates) => {
    try {
      await api.put(`${API_NUTRITION_FACTS}/${nutritionFactId}`, {
        nutrition_fact: updates
      })
      return true
    } catch (e) {
      console.error(e)
      showAlertError(e?.data?.message)
      return false
    }
  }

  const getAllNutrientOptions = async () => {
    try {
      return await api.get(`${API_NUTRIENTS}`)
    } catch (e) {
      console.error(e)
      showAlertError(e?.data?.message)
      return false
    }
  }

  const bulkUpdateProduct = async (
    productIds,
    locationIds,
    published,
    pdpBlocked
  ) => {
    try {
      await api.put(`${API_PRODUCTS}/bulk_update`, {
        product_ids: productIds,
        location_ids: locationIds,
        published,
        locked: pdpBlocked
      })
      return true
    } catch (e) {
      console.error(e)
      showAlertError(e?.data?.message)
      return false
    }
  }

  const addAccessCodes = async codes => {
    try {
      const res = await api.put(
        `${API_PRODUCTS}/${product.id}/product_access_codes/bulk_update`,
        {
          codes
        }
      )
      setProductLocally({
        ...cloneDeep(product),
        access_codes: res.data
      })
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const updateAccessCodeAvailability = async (id, available) => {
    try {
      const res = await api.put(
        `${API_PRODUCTS}/${product.id}/product_access_codes/${id}`,
        {
          product_access_code: { available }
        }
      )
      setProductLocally({
        ...cloneDeep(product),
        access_codes: res.data
      })
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const deleteAccessCode = async id => {
    try {
      await api.delete(
        `${API_PRODUCTS}/${product.id}/product_access_codes/${id}`
      )
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const bulkApplyTags = async ({ productIds, tagsIds }) => {
    try {
      const res = await api.post(`${API_PRODUCT_TAGS}/bulk_apply`, {
        product_ids: productIds,
        tag_ids: tagsIds
      })
      return res.data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const addTag = async tagName => {
    try {
      const response = await api.post(`${API_PRODUCT_TAGS}`, {
        tag: { name: tagName }
      })
      if (!response.data) return false
      return response.data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const {
    isLoading: isLoadingHistory,
    data: historyData,
    hasData: hasHistory,
    hasNext: hasHistoryNext,
    list: fetchHistory,
    listNext: fetchHistoryNext,
    count: historyCount
  } = useResourceList({
    url: `${API_PRODUCTS}/${id}/product_history`
  })

  const {
    isLoading: isLoadingTaxCodes,
    data: taxCodes,
    hasData: hasTaxCodes,
    hasNext: hasTaxCodesNext,
    list: listTaxCodes,
    listNext: listTaxCodesNext
  } = useResourceList({ url: API_TAXJAR_TAX_CODES })

  const {
    isLoading: isLoadingParentCategories,
    data: parentCategories,
    hasData: hasParentCategories,
    hasNext: hasParentCategoriesNext,
    list: listParentCategories,
    listNext: listParentCategoriesNext
  } = useResourceList({ url: API_PARENT_CATEGORIES })

  const {
    isLoading: isLoadingSubCategories,
    data: subCategories,
    hasData: hasSubCategories,
    hasNext: hasSubCategoriesNext,
    list: listSubCategories,
    listNext: listSubCategoriesNext
  } = useResourceList({ url: API_SUB_CATEGORIES })

  const {
    isLoading: isLoadingBadges,
    data: badges,
    hasData: hasBadges,
    hasNext: hasBadgesNext,
    list: listBadges,
    listNext: listBadgesNext
  } = useResourceList({ url: API_PRODUCT_BADGES })

  const {
    isLoading: isLoadingTags,
    data: tags,
    hasData: hasTags,
    hasNext: hasTagsNext,
    list: listTags,
    listNext: listTagsNext
  } = useResourceList({ url: API_PRODUCT_TAGS })

  const { create: createTag } = useResource({
    url: API_PRODUCT_TAGS,
    name: 'tag'
  })

  const {
    isLoading: isLoadingStatusCodes,
    data: statusCodes,
    hasData: hasStatusCodes,
    hasNext: hasStatusCodesNext,
    list: listStatusCodes,
    listNext: listStatusCodesNext
  } = useResourceList({ url: API_PRODUCT_STATUS_CODES })

  const {
    isLoading: isLoadingSizes,
    data: sizes,
    hasData: hasSizes,
    hasNext: hasSizesNext,
    list: listSizes,
    listNext: listSizesNext
  } = useResourceList({ url: API_PRODUCT_SIZES })

  const {
    isLoading: isLoadingAccessoryOptions,
    data: accessoryOptions,
    hasData: hasAccessoryOptions,
    hasNext: hasAccessoryOptionsNext,
    list: listAccessoryOptions,
    listNext: listAccessoryOptionsNext
  } = useResourceList({ url: API_PRODUCT_ACCESSORY_OPTIONS })

  return {
    isLoadingProducts,
    products,
    hasProducts,
    hasProductsNext,
    listProducts,
    listProductsNext,
    updateProductsLocally,
    product,
    hasProduct,
    isLoadingProduct,
    readProduct,
    updateProduct,
    generateNutritionFactsRequestBody,
    createNutritionFacts,
    updateNutritionFacts,
    getAllNutrientOptions,
    createProduct,
    deleteProduct,
    restoreProduct,
    archiveProduct,
    uploadProductImages,
    fetchProductsWithSoldOutVariantsCount,
    productsWithSoldOutVariantsCount,
    bulkUpdateProduct,
    addAccessCodes,
    updateAccessCodeAvailability,
    deleteAccessCode,
    fetchHistory,
    historyData,
    hasHistory,
    hasHistoryNext,
    fetchHistoryNext,
    isLoadingHistory,
    historyCount,
    isLoadingTaxCodes,
    taxCodes,
    hasTaxCodes,
    hasTaxCodesNext,
    listTaxCodes,
    listTaxCodesNext,
    isLoadingParentCategories,
    parentCategories,
    hasParentCategories,
    hasParentCategoriesNext,
    listParentCategories,
    listParentCategoriesNext,
    isLoadingSubCategories,
    subCategories,
    hasSubCategories,
    hasSubCategoriesNext,
    listSubCategories,
    listSubCategoriesNext,
    isLoadingBadges,
    badges,
    hasBadges,
    hasBadgesNext,
    listBadges,
    listBadgesNext,
    isLoadingStatusCodes,
    statusCodes,
    hasStatusCodes,
    hasStatusCodesNext,
    listStatusCodes,
    listStatusCodesNext,
    isLoadingTags,
    tags,
    hasTags,
    hasTagsNext,
    listTags,
    listTagsNext,
    createTag,
    isLoadingSizes,
    sizes,
    hasSizes,
    hasSizesNext,
    listSizes,
    listSizesNext,
    isLoadingAccessoryOptions,
    accessoryOptions,
    hasAccessoryOptions,
    hasAccessoryOptionsNext,
    listAccessoryOptions,
    listAccessoryOptionsNext,
    createVariant,
    bulkApplyTags,
    addTag
  }
}

export default useAdminProducts
