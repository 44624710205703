import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { LIST_PATHS } from 'constants/queryParams'

import Header from 'components/Header'
import DataTable from 'components/DataTable'
import GlanceTile from 'components/GlanceTile'

import styles from './ColorListStyles'
import MasterColorsItem from './MasterColorsItem'

const columns = [
  {
    title: 'Colorway',
    sortKeys: [LIST_PATHS.MASTER_COLOR_LABEL]
  },

  {
    title: 'Usage',
    sortKeys: [LIST_PATHS.MASTER_COLOR_COLORS_COUNT]
  },
  {
    title: 'Created On',
    sortKeys: [LIST_PATHS.MASTER_COLOR_CREATED_AT]
  },
  {
    title: 'Status'
  },
  {}
]

const MasterColorsPane = ({
  classes,
  query,
  colors = [],
  hasColors,
  colorsCount,
  hasListNext,
  isLoadingList,
  updateQuery,
  listNext,
  handleQueryChange
}) => {
  const summarizers = (
    <GlanceTile minimal label="Master Colorways" value={colorsCount} />
  )

  return (
    <>
      <Header summarizers={summarizers} hideHeader />
      <DataTable
        classes={{ TableContainer: classes.TableContainer }}
        columns={columns}
        message={colors?.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingList}
        hasListNext={hasListNext}
        listNext={listNext}
        query={query}
        updateQuery={updateQuery}
      >
        {hasColors &&
          colors.map(color => (
            <MasterColorsItem color={color} key={color.id} />
          ))}
      </DataTable>
    </>
  )
}

MasterColorsPane.propTypes = {
  classes: PropTypes.object.isRequired,
  colors: PropTypes.object,
  query: PropTypes.object,
  isLoadingList: PropTypes.bool,
  hasListNext: PropTypes.bool,
  listNext: PropTypes.func,
  updateQuery: PropTypes.func,
  handleQueryChange: PropTypes.func,
  hasColors: PropTypes.bool,
  colorsCount: PropTypes.number
}

export default withStyles(styles)(MasterColorsPane)
