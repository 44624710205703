import React from 'react'
import PropTypes from 'prop-types'

const SearchIcon = ({ fill, className }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="search-path-1-inside-1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8667 6C10.8667 8.50369 8.83702 10.5333 6.33333 10.5333C3.82964 10.5333 1.79999 8.50369 1.79999 6C1.79999 3.49631 3.82964 1.46667 6.33333 1.46667C8.83702 1.46667 10.8667 3.49631 10.8667 6ZM11.0336 9.72971C11.8472 8.70565 12.3333 7.40959 12.3333 6C12.3333 2.68629 9.64704 0 6.33333 0C3.01962 0 0.333328 2.68629 0.333328 6C0.333328 9.31371 3.01962 12 6.33333 12C7.70885 12 8.97627 11.5371 9.98831 10.7586L12.6433 13.4136L13.6803 12.3765L11.0336 9.72971Z"
      />
    </mask>
    <path
      d="M11.0336 9.72971L9.88525 8.81729L9.07131 9.84165L9.99646 10.7668L11.0336 9.72971ZM9.98831 10.7586L11.0254 9.72155L10.1148 8.81096L9.09408 9.59612L9.98831 10.7586ZM12.6433 13.4136L11.6062 14.4507L12.6433 15.4878L13.6803 14.4507L12.6433 13.4136ZM13.6803 12.3765L14.7174 13.4136L15.7545 12.3765L14.7174 11.3394L13.6803 12.3765ZM6.33333 12C9.64704 12 12.3333 9.31371 12.3333 6H9.4C9.4 7.69367 8.027 9.06667 6.33333 9.06667V12ZM0.333328 6C0.333328 9.31371 3.01962 12 6.33333 12V9.06667C4.63966 9.06667 3.26666 7.69367 3.26666 6H0.333328ZM6.33333 0C3.01962 0 0.333328 2.68629 0.333328 6H3.26666C3.26666 4.30633 4.63966 2.93333 6.33333 2.93333V0ZM12.3333 6C12.3333 2.68629 9.64704 0 6.33333 0V2.93333C8.027 2.93333 9.4 4.30633 9.4 6H12.3333ZM10.8667 6C10.8667 7.06649 10.5002 8.04334 9.88525 8.81729L12.1819 10.6421C13.1943 9.36797 13.8 7.7527 13.8 6H10.8667ZM6.33333 1.46667C8.83702 1.46667 10.8667 3.49631 10.8667 6H13.8C13.8 1.87627 10.4571 -1.46667 6.33333 -1.46667V1.46667ZM1.79999 6C1.79999 3.49631 3.82964 1.46667 6.33333 1.46667V-1.46667C2.2096 -1.46667 -1.13334 1.87627 -1.13334 6H1.79999ZM6.33333 10.5333C3.82964 10.5333 1.79999 8.50369 1.79999 6H-1.13334C-1.13334 10.1237 2.2096 13.4667 6.33333 13.4667V10.5333ZM9.09408 9.59612C8.32941 10.1843 7.37421 10.5333 6.33333 10.5333V13.4667C8.0435 13.4667 9.62313 12.8899 10.8825 11.9212L9.09408 9.59612ZM13.6803 12.3765L11.0254 9.72155L8.95122 11.7957L11.6062 14.4507L13.6803 12.3765ZM12.6432 11.3394L11.6062 12.3765L13.6803 14.4507L14.7174 13.4136L12.6432 11.3394ZM9.99646 10.7668L12.6432 13.4136L14.7174 11.3394L12.0706 8.69262L9.99646 10.7668Z"
      mask="url(#search-path-1-inside-1)"
    />
  </svg>
)

SearchIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}

export default SearchIcon
