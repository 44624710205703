const jssStyles = theme => ({
  root: {},
  linkButton: {
    fontWeight: 400
  },
  autocompleteSearchLocations: {
    minWidth: 180
  }
})

export default jssStyles
