import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminMasterColors } from 'hooks'
import AutocompleteSearch from 'components/AutocompleteSearch'

import styles from './AutocompleteSearchMasterColorsStyles'

const AutocompleteSearchMasterColorsRaw = ({
  name,
  classes,
  onChange,
  value,
  defaultOpen,
  multiple,
  disabled,
  error,
  helperText
}) => {
  const [query, setQuery] = useState('')

  const {
    isLoadingMasterColors,
    masterColors,
    listMasterColorsNext
  } = useAdminMasterColors({ search: query })

  const options = useMemo(() => uniqBy(masterColors ?? [], v => v?.id), [
    masterColors
  ])

  return (
    <Box mt={1} width="100%">
      <AutocompleteSearch
        name={name}
        disabled={disabled}
        withEndAdornment={false}
        defaultOpen={defaultOpen}
        value={value}
        loading={isLoadingMasterColors}
        setQuery={val => {
          setQuery(val)
        }}
        error={error}
        helperText={helperText}
        onChange={onChange}
        onScrollBottom={listMasterColorsNext}
        options={options}
        placeholder="Color"
        multiple={multiple}
        getOptionLabel={opt => opt?.label ?? ''}
        renderOption={(option, { selected }) => (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="100%"
            className={selected ? classes.selectedItem : null}
          >
            <Typography variant="body1" className={classes.label}>
              {`${option?.label}`}
            </Typography>
          </Box>
        )}
      />
    </Box>
  )
}

AutocompleteSearchMasterColorsRaw.defaultProps = {
  value: [],
  defaultOpen: false,
  multiple: true,
  disabled: false
}

AutocompleteSearchMasterColorsRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultOpen: PropTypes.bool,
  multiple: PropTypes.bool, // value [] === true, value === {} = false
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string
}

const AutocompleteSearchMasterColors = withStyles(styles)(
  AutocompleteSearchMasterColorsRaw
)

const AutocompleteSearchMasterColorsControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchMasterColors
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => {
            option && methods.onChange(option)
          }}
        />
      )}
    />
  )
}

AutocompleteSearchMasterColorsControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchMasterColors as default,
  AutocompleteSearchMasterColorsControlled
}
