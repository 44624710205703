import { WRAP_WORD } from 'constants/styles'

export default theme => ({
  productMediaItem: {
    height: 48,
    width: 48,
    objectFit: 'cover',
    marginRight: theme.spacing(1)
  },

  wrap: {
    // this selector must have more weight to out-class the DataTable's no wrap property
    '&.MuiTableCell-root.MuiTableCell-body': {
      ...WRAP_WORD
    }
  },
  alignTop: {
    '&.MuiTableCell-root.MuiTableCell-body': {
      verticalAlign: 'top'
    }
  }
})
