import { thinInput } from 'constants/styles'

const jssStyles = theme => ({
  gridContainer: {
    flex: 1,
    marginTop: theme.spacing(7)
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    width: '100%'
  },
  paddingBlock: {
    padding: theme.spacing(3)
  },
  divider: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grays.med}`
  },
  image: {
    maxHeight: '100%'
  },
  headerBox: {
    display: 'flex',

    '& > .MuiBox-root': {
      flexGrow: 1,
      height: 45
    }
  },
  flagBlock: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  historyMenuButton: {
    cursor: 'pointer',
    width: 42,
    height: 42,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      backgroundColor: '#e5e5e5'
    }
  },
  smallInput: {
    width: 125,
    height: 25,
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.5, 1.5, 1.5, 1)
    },
    '&:not(last-of-type)': {
      marginRight: theme.spacing(2)
    }
  },
  thinInput: thinInput(theme),
  qtyInput: {
    '& .MuiOutlinedInput-root': {
      width: 88
    },
    '& .MuiFormHelperText-root.Mui-error': {
      maxWidth: 88,
      marginLeft: theme.spacing(1),
      marginRight: 0
    }
  },
  autocompleteBox: {
    width: '100%',
    '& .MuiFormControl-root': {
      marginBottom: 0
    }
  },
  reorderButton: {
    minWidth: 'auto',
    padding: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
})

export default jssStyles
