import { alpha } from '@material-ui/core/styles'
import { grays } from 'theme/colors'

export default {
  root: {
    height: 44,
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'none'
  },
  contained: {
    boxShadow: 'none',
    '&$disabled': {
      color: grays.dark,
      backgroundColor: alpha(grays.light, 0.3)
    }
  },

  outlinedSecondary: {
    color: grays.darkest,
    borderColor: grays.darkest
  },
  sizeLarge: {
    height: 60,
    fontSize: 14
  },
  sizeSmall: {
    height: 28,
    fontSize: 12
  }
}
