import { useResourceListRQ } from 'hooks'
import { supplierEndpoint } from 'api/endpoints'

const useAdminSupplierList = params => {
  const {
    isFetching: isFetchingSuppliers,
    data: suppliers,
    hasData: hasSuppliers,
    hasNextPage: hasSuppliersNext,
    fetchNextPage: fetchSuppliersNext,
    meta
  } = useResourceListRQ({ endpoint: supplierEndpoint, params })

  return {
    isFetchingSuppliers,
    suppliers: suppliers || [],
    hasSuppliers,
    hasSuppliersNext,
    fetchSuppliersNext,
    summary: meta?.summary
  }
}

export default useAdminSupplierList
