import { useHistory } from 'react-router-dom'

const useNavigation = ({ url = '' }) => {
  const history = useHistory()

  const go = (e, path, aux, state) => {
    if (e?.ctrlKey || e?.metaKey || aux) {
      window.open(path, '_blank')
    } else {
      history.push(path, state)
    }
  }

  const handleShowClick = (e, resource, aux = false, state = false) =>
    go(e, `${url}/${resource.id}`, aux, state)

  const handleShowDetailsClick = (
    e,
    resource,
    detailsId,
    aux = false,
    state = false
  ) => go(e, `${url}/${resource.id}/${detailsId}`, aux, state)

  const handleEditClick = (e, resource, aux = false, state = false) =>
    go(e, `${url}/${resource.id}/edit`, aux, state)
  const handleAddClick = (e, aux = false, state = false) =>
    go(e, `${url}/new`, aux, state)

  return {
    go,
    handleClick: handleShowClick,
    handleShowClick,
    handleShowDetailsClick,
    handleEditClick,
    handleAddClick,

    toShow: handleShowClick,
    toEdit: handleEditClick,
    toAdd: handleAddClick
  }
}

export default useNavigation
