export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  waitlistCount: {
    ...theme.typography.h3,
    fontSize: 26,
    fontWeight: 600,
    marginTop: theme.spacing(3)
  },
  waitlistCountLabel: {
    ...theme.typography.body1,
    fontWeight: 400,
    color: theme.palette.grays.dark,
    paddingTop: 4
  },
  emailLabel: {
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.grays.dark,
    paddingRight: theme.spacing(4)
  },
  locationLabel: {
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.grays.dark,
    paddingLeft: theme.spacing(1)
  },
  divider: {
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.grays.med}`
  }
})
