import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { REFUND_REASONS } from 'constants/general'

import ReturnItems from 'components/ReturnItems'

import styles from './RefundedItemsStyles'

const RefundedItems = ({ classes, order, refund }) => {
  const RefundStatus = () => (
    <Box
      className={classNames({
        [classes.refunded]: true
      })}
    >
      <>
        <CheckCircleIcon />
        <Typography variant="body1">Refunded {refund?.number}</Typography>
      </>
    </Box>
  )

  return (
    <>
      <RefundStatus />
      {refund?.line_item_refunds.map((lineItem, idx) =>
        lineItem.quantity > 0 ? (
          <ReturnItems
            key={lineItem.id}
            product={
              order?.line_items.filter(
                item => item.id === lineItem.line_item.id
              )[0]
            }
            showQuantity
            showSku
            showVariantTitle
            isOdd={idx % 2 !== 0}
            inProgress
            returnQuantity={lineItem.quantity}
          />
        ) : null
      )}
      <Typography variant="h5" className={classes.paddingBlock}>
        Reason for Refund
      </Typography>
      <Typography variant="body1">
        {REFUND_REASONS[refund?.refund_reason] || 'No reason specified'}
      </Typography>
      {refund?.details && (
        <>
          <Typography variant="h5" className={classes.paddingBlock}>
            Additional Details
          </Typography>
          <Typography variant="body1">{refund?.details}</Typography>
        </>
      )}
    </>
  )
}

RefundedItems.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.object.isRequired,
  refund: PropTypes.object.isRequired
}

export default withStyles(styles)(RefundedItems)
