import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Header from 'components/Header'
import DataTable from 'components/DataTable'
import NotificationsPaneRow from './NotificationsPaneRow'

import styles from './PaneStyles'

const columns = [
  {
    title: 'Title',
  },
  {
    title: 'Path'
  },
  {
    title: 'Created On'
  },
  {
    title: 'Status'
  }
]

const NotificationsPane = ({
  classes, 
  headerSummarizers,
  notifications,
  isLoadingNotifs,
  hasNotifs,
  hasNotifsNext,
  fetchNotifsNext
}) => {

  return (
    <>
      <Header summarizers={headerSummarizers} hideHeader />
      <DataTable
        id="notifications"
        classes={{ TableContainer: classes.TableContainer }}
        columns={columns}
        message={notifications?.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingNotifs}
        hasListNext={hasNotifsNext}
        listNext={fetchNotifsNext}
      >
        {
          hasNotifs && 
          notifications.map(notif => (
            <NotificationsPaneRow
              key={`NotifPaneRow-${notif?.id}`}
              notification={notif}
            />
          ))
        }
      </DataTable>
    </>
  )
}

export default withStyles(styles)(NotificationsPane)