import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { useAdminProducts } from 'hooks'

import Tag from 'components/Tag'
import SearchIcon from 'icons/SearchIcon'

import styles from './AutocompleteSearchProductTagsStyles'

const AutocompleteSearchProductTagsRaw = ({
  name,
  classes,
  onChange,
  value,
  clearAll,
  tagValues
}) => {
  const {
    isLoadingTags,
    tags,
    listTags,
    listTagsNext,
    addTag
  } = useAdminProducts({})
  const [query, setQuery] = useState('')
  const [localValue, setLocalValue] = useState([])
  const currentValue = value ?? localValue

  useEffect(() => {
    if (query.length) {
      listTags({ search: query })
    } else {
      listTags()
    }
  }, [query])

  useEffect(() => {
    if (clearAll) {
      setLocalValue([])
      onChange && onChange([])
    }
  }, [clearAll])

  useEffect(() => {
    if (isEmpty(tagValues)) return
    setLocalValue(tagValues)
  }, [tagValues])

  const options = useMemo(
    () => uniqBy((tags ?? []).concat(currentValue), v => v?.id),
    [tags]
  )

  const handleChange = (e, val) => {
    setLocalValue(val)
    onChange && onChange(val)
  }

  const handleInputChange = (e, val) => {
    setQuery(val ?? '')
  }

  const onScrollBottom = () => listTagsNext()

  const listboxProps = {
    onScroll: ({ currentTarget: el }) => {
      if ((Math.trunc(el.scrollTop), el.scrollHeight - el.clientHeight)) {
        onScrollBottom()
      }
    }
  }

  const handleSaveTag = async () => {
    const response = await addTag(query)
    if (!response) return
    await listTags({ search: query })
    handleChange(null, currentValue.concat([response]))
  }

  const SaveTag = () => (
    <Box
      className={classes.saveTag}
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      onMouseDown={handleSaveTag}
    >
      <span>
        Save <Tag label={query} /> as a new tag
      </span>
    </Box>
  )

  return (
    <Autocomplete
      name={name}
      classes={{
        root: classes.root,
        paper: classes.autocompletePaper,
        inputRoot: classes.autocompleteInputRoot
      }}
      noOptionsText={<SaveTag />}
      loading={isLoadingTags}
      fullWidth
      size="medium"
      disableClearable
      multiple
      value={currentValue}
      limitTags={2}
      id="multiple-limit-tags"
      options={options}
      onInputChange={handleInputChange}
      onChange={handleChange}
      getOptionLabel={opt => opt?.name ?? ''}
      getOptionSelected={(opt, val) => opt?.id === val?.id}
      ListboxProps={listboxProps}
      renderTags={inputTags =>
        inputTags.map((tag, i) => (
          <Tag
            key={i}
            label={tag.name}
            onDelete={e =>
              handleChange(
                e,
                localValue.filter(localTag => localTag.id !== tag.id)
              )
            }
          />
        ))
      }
      renderOption={(option, { selected }) => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          className={selected ? classes.selectedItem : null}
        >
          <Typography variant="body1">{option?.name ?? ''}</Typography>
        </Box>
      )}
      renderInput={params => {
        const { startAdornment } = params.InputProps
        return (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Tags"
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <Box mx={1}>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  </Box>
                  {startAdornment}
                </>
              )
            }}
          />
        )
      }}
    />
  )
}

AutocompleteSearchProductTagsRaw.defaultProps = {}

AutocompleteSearchProductTagsRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string
  ]),
  clearAll: PropTypes.bool,
  tagValues: PropTypes.arrayOf(PropTypes.object)
}

const AutocompleteSearchProductTags = withStyles(styles)(
  AutocompleteSearchProductTagsRaw
)

const AutocompleteSearchProductTagsControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchProductTags
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchProductTagsControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchProductTags as default,
  AutocompleteSearchProductTagsControlled
}
