import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiIconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useHistory } from 'react-router-dom'

import {
  CHECKBOX_VARIANT,
  PUBLISHED_STATUS_VARIANT,
  COLOR,
  ICON_BUTTON_VARIANT
} from 'constants/enums'

import Checkbox from 'components/Checkbox'
import PublishStatusIndicator from 'components/PublishStatusIndicator'
import IconButton from 'components/IconButton'
import RemoveIcon from 'icons/RemoveIcon'
import { URL } from 'constants/navigation'

import styles from './CollectionBlockStyles'

const CollectionBlock = ({
  classes,
  className,
  item,
  title,
  active,
  imageUrl,
  selected,
  onSelect,
  onItemClick,
  showPublish,
  withRemove,
  tooltipMessage,
  menu,
  id
}) => {
  const clickBoxRef = useRef()
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const history = useHistory()

  const handleCheckBoxChange = state => {
    onSelect(item, state)
  }

  const handleRemoveClick = e => {
    onSelect(item, false)
  }

  const handleMenuClick = e => {
    setMenuAnchorEl(e.currentTarget)
  }

  const handleClick = e => {
    e.target === clickBoxRef.current && onItemClick && onItemClick(e, item)
  }

  const handleAuxClick = e => {
    e.target === clickBoxRef.current &&
      onItemClick &&
      onItemClick(e, item, true)
  }

  const handleParentCollectionClick = () => {
    history.push(`${URL.ADMIN_COLLECTIONS}/${item.parent_collection_id}`)
  }

  return (
    <Box
      className={classNames({
        [classes.root]: true,
        [className]: Boolean(className),
        [classes.rootClickable]: Boolean(onItemClick)
      })}
    >
      <Box className={classes.aspectRatio} />
      <Box className={classes.title}>
        <Typography variant="h4" data-test={`collection-title`}>
          {title}
        </Typography>
      </Box>
      <Box
        className={classes.backgroundImage}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <Box
        className={classes.content}
        onClick={handleClick}
        onAuxClick={handleAuxClick}
        ref={clickBoxRef}
      >
        <Box display="flex" alignItems="center" width="100%">
          {typeof selected === 'boolean' && (
            <Checkbox
              onChange={handleCheckBoxChange}
              checked={selected}
              variant={CHECKBOX_VARIANT.light}
              dataTest={`collection-checked-${title}`}
            />
          )}
          <Box flexGrow="1" />
          {showPublish && (
            <PublishStatusIndicator
              tooltipMessage={tooltipMessage}
              variant={
                active
                  ? PUBLISHED_STATUS_VARIANT.active
                  : PUBLISHED_STATUS_VARIANT.inactive
              }
            />
          )}
          {withRemove && (
            <IconButton
              size={12}
              circle
              variant={ICON_BUTTON_VARIANT.black}
              onClick={handleRemoveClick}
              className={classes.removeButton}
            >
              <RemoveIcon fontSize={10} />
            </IconButton>
          )}
          {menu && (
            <>
              <MuiIconButton
                className={classes.menuIconButton}
                edge="end"
                color={COLOR.secondary}
                onClick={handleMenuClick}
              >
                <MoreVertIcon />
              </MuiIconButton>
              <Menu
                className={classes.menu}
                classes={{ paper: classes.menuPaper }}
                open={Boolean(menuAnchorEl)}
                keepMounted
                anchorEl={menuAnchorEl}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                onClose={() => setMenuAnchorEl(null)}
              >
                {menu.map(option => (
                  <MenuItem
                    key={option.label}
                    onClick={() => {
                      option.handleClick(item)
                      setMenuAnchorEl(null)
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
          <Box className={classes.parentCollectionInfo}>
            {item?.is_parent && (
              <Chip label={<Typography>Parent</Typography>} color="secondary" />
            )}
            {item?.parent_collection_id && (
              <Chip
                label={
                  <Typography>
                    Parent collection: {item.parent_collection_id}
                  </Typography>
                }
                color="secondary"
                clickable
                onClick={handleParentCollectionClick}
                data-test="parent-collection-link-chip"
              />
            )}
            {item?.id && (
              <Chip
                label={<Typography>{item.id}</Typography>}
                color="secondary"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

CollectionBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  item: PropTypes.object,
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  handleSelect: PropTypes.func,
  withRemove: PropTypes.bool,
  onItemClick: PropTypes.func,
  showPublish: PropTypes.bool,
  menu: PropTypes.arrayOf(PropTypes.object),
  tooltipMessage: PropTypes.string
}

export default withStyles(styles)(React.memo(CollectionBlock))
