import React from 'react'
import PropTypes from 'prop-types'

import { colorsAF } from 'theme/colors'
import { Box, Typography } from '@material-ui/core'

import DisplayCollectionMedia from 'components/DisplayCollectionMedia'

const CollectionGroupPreview = ({ numberPreviewItems, collectionGroup }) => {
  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2px',
    width: 116,
    height: 193,
    mt: '8px'
  }

  return (
    <Box display="flex" bgcolor={colorsAF.white}>
      {collectionGroup.collections
        ?.slice(0, numberPreviewItems)
        .map((collection, idx) => (
          <Box {...contentStyle} key={idx}>
            <DisplayCollectionMedia collection={collection} />
            <Box mt="8px" mb="3px">
              <Typography variant="overline" color="textSecondary">
                {collection.title}
              </Typography>
            </Box>
          </Box>
        ))}
    </Box>
  )
}

CollectionGroupPreview.defaultProps = {
  numberPreviewItems: 4
}

CollectionGroupPreview.propTypes = {
  collectionGroup: PropTypes.object,
  numberPreviewItems: PropTypes.number
}

export default CollectionGroupPreview
