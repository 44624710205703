import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import styles from './CustomerDeliveryPhotoStyles'

const CustomerDeliveryPhoto = ({ classes, order, onClick }) => (
  <Box className={classes.root}>
    <Typography variant="body1" component="div" className={classes.text}>
      Photo taken by {order.onfleet_driver_name}
    </Typography>
    <Typography variant="body1" component="div" className={classes.text}>
      <Link
        color="primary"
        href={order.delivery_photo_url}
        target="_blank"
      >
        Click here to open a link to the photo
      </Link>
    </Typography>

    <img className={classes.deliveryImage} src={order.delivery_photo_url} />
  </Box>
)

CustomerDeliveryPhoto.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object,
  onClick: PropTypes.func
}

export default withStyles(styles)(CustomerDeliveryPhoto)
