import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import AutocompleteSearch from 'components/AutocompleteSearch'

import { countriesWithCodes } from 'constants/localization'

const AutocompleteSearchCountryRaw = ({
  classes,
  onChange,
  value,
  defaultOpen,
  getOptionLabel,
  queryParams,
  name,
  multiple,
  ...rest
}) => {
  const [query, setQuery] = useState()

  const searchByQuery = country =>
    !query || country?.search(new RegExp(query, 'i')) > -1

  const countries = Object.values(countriesWithCodes)
  const options = useMemo(
    () => countries?.filter(country => searchByQuery(country)) ?? [],
    [countries]
  )

  return (
    <AutocompleteSearch
      name={name}
      defaultOpen={defaultOpen}
      value={value}
      loading={false}
      setQuery={setQuery}
      onChange={onChange}
      options={options}
      placeholder={multiple ? 'Select Countries' : 'Select Country'}
      multiple={multiple}
      getOptionLabel={getOptionLabel}
      renderOption={(option, { selected }) => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          className={selected ? classes.selectedItem : null}
          data-test={`option-${option}`}
        >
          <Typography variant="body1" className={classes.country}>
            {option}
          </Typography>
        </Box>
      )}
      {...rest}
    />
  )
}

AutocompleteSearchCountryRaw.defaultProps = {
  value: '',
  defaultOpen: false,
  getOptionLabel: opt => opt,
  multiple: false
}

AutocompleteSearchCountryRaw.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string
  ]),
  defaultOpen: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  placeholder: PropTypes.string,
  optWithEmail: PropTypes.bool,
  queryParams: PropTypes.object,
  name: PropTypes.string.isRequired
}

const AutocompleteSearchCountry = withStyles(theme => ({
  state: {},
  selectedItem: {
    '& $state': {
      fontWeight: 700
    }
  }
}))(AutocompleteSearchCountryRaw)

const AutocompleteSearchCountryControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchCountry
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchCountryControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchCountry as default,
  AutocompleteSearchCountryControlled
}
