import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Header from 'components/Header'
import DataTable from 'components/DataTable'
import AnalyticsPaneRow from './AnalyticsPaneRow'

import styles from './PaneStyles'

const columns = [
  {
    title: 'Title',
  },
  {
    title: 'Sent'
  },
  {
    title: 'Seen'
  },
  {
    title: 'Clicked On'
  },
  {
    title: 'Deleted'
  }
]

const AnalyticsPane = ({
  classes,
  headerSummarizers,
  notifications,
  isLoadingNotifs,
  hasNotifs,
  hasNotifsNext,
  fetchNotifsNext
}) => {
  return (
    <>
      <Header summarizers={headerSummarizers} hideHeader />
      <DataTable
        id="analytics"
        classes={{ TableContainer: classes.TableContainer }}
        columns={columns}
        message={notifications?.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingNotifs}
        hasListNext={hasNotifsNext}
        listNext={fetchNotifsNext}
      >
        {
          hasNotifs && 
          notifications.map(notif => (
            <AnalyticsPaneRow
              key={`NotifPaneRow-${notif?.id}`}
              notification={notif}
            />
          ))
        }
      </DataTable>
    </>
  )
}

export default  withStyles(styles)(AnalyticsPane)