import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import AddIcon from '@material-ui/icons/Add'

import { dateRangeToQueryParams } from 'utils/query'
import { LIST_PATHS, API_PARAMS, SORT } from 'constants/queryParams'
import { SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'

import { useLocationsContext } from 'context'
import {
  useQuery,
  useAdminBrandsRQ,
  useNavigation,
  useAdminPurchaseOrder,
  useAdminPurchaseOrderList
} from 'hooks'

import Layout from 'components/Layout'
import Header from 'components/Header'
import DataTable from 'components/DataTable'
import GlanceTile from 'components/GlanceTile'
import SearchInput from 'components/SearchInput'
import Button from 'components/Button'
import Select from 'components/Select'
import DateRangePicker from 'components/DateRangePickerNew'

import InventoryOrderItem from './InventoryOrderItem'

// type will be used for the WIP API changes
const InventoryOrders = ({
  shortName,
  title,
  type,
  columns,
  filters,
  statusLabels,
  glanceTiles
}) => {
  const { locationId, getLocationAtId } = useLocationsContext()
  const [newInventoryOrderEvent, setNewInventoryOrderEvent] = useState(null)

  const { brands: allBrands } = useAdminBrandsRQ()
  const brandMap = {}
  if (allBrands) {
    allBrands.forEach(v => {
      brandMap[v.id] = v.name
    })
  }

  const { query, handleQueryChange, setQuery, updateQuery } = useQuery(false, {
    initFallback: {
      [API_PARAMS.SORT]: LIST_PATHS.PURCHASE_ORDERS_PO_NUMBER,
      [API_PARAMS.SORT_DIRECTION]: SORT.DESC
    },
    locationFilter: LIST_PATHS.LOCATION_ID
  })

  const { createPurchaseOrder, purchaseOrder } = useAdminPurchaseOrder()

  const {
    isFetchingPurchaseOrders: isLoadingPurchaseOrders,
    purchaseOrders,
    hasPurchaseOrdersNext,
    fetchPurchaseOrdersNext,
    summary
  } = useAdminPurchaseOrderList(query)

  const summarizers = glanceTiles?.map(({ key, label }) => (
    <GlanceTile key={key} minimal label={label} value={summary?.[key]} />
  ))

  const { handleClick: handleNewPONavigate } = useNavigation({
    url: URL.ADMIN_PURCHASE_ORDERS
  })

  const handleNewPOClick = e => {
    createPurchaseOrder({
      location_id: locationId,
      status: 'draft',
      record_type: type
    })
    setNewInventoryOrderEvent(e)
  }

  // navigate to newly created PO
  useEffect(() => {
    if (purchaseOrder?.id) {
      handleNewPONavigate(newInventoryOrderEvent, purchaseOrder)
    }
  }, [purchaseOrder])

  const foldableActions = (
    <>
      <SearchInput
        name="search"
        placeholder={`Search ${shortName}'s...`}
        value={query.search}
        onChange={handleQueryChange}
      />
    </>
  )

  const actions = (
    <Button
      adaptive
      startIcon={<AddIcon />}
      color="primary"
      variant="contained"
      label="new purhcase order button"
      size={SIZE.medium}
      onClick={e => handleNewPOClick(e)}
      dataTest="new-po"
    >
      New {shortName}
    </Button>
  )

  const dateRangeValue = () =>
    query[LIST_PATHS.PURCHASE_ORDERS_ETA]
      ? query[LIST_PATHS.PURCHASE_ORDERS_ETA].split(',').map(s => moment(s))
      : [null, null]

  const handleDateRangeChange = ([start, end]) => {
    // reset
    if (start === null && end === null) {
      setQuery('') // this removes the key
      return
    }

    if (start && end) {
      const params = dateRangeToQueryParams(LIST_PATHS.PURCHASE_ORDERS_ETA, [
        start,
        end
      ])
      setQuery({ ...query, ...params })
    }
  }

  const handleDateRangeReset = () => {
    handleDateRangeChange([null, null])
    const newQuery = { ...query }
    if (newQuery[LIST_PATHS.PURCHASE_ORDERS_ETA]) {
      delete newQuery[LIST_PATHS.PURCHASE_ORDERS_ETA]
    }
    setQuery(newQuery)
  }

  const allFilters = (
    <>
      <DateRangePicker
        name="daterangepicker"
        onChange={handleDateRangeChange}
        value={dateRangeValue()}
        onClearDates={handleDateRangeReset}
        noDatesOutsideRange
      />
      {filters && filters({ query, handleQueryChange, updateQuery })}
      <Select
        minimal
        name={LIST_PATHS.PURCHASE_ORDERS_STATUS_FILTER}
        innerLabel="Status"
        value={query[LIST_PATHS.PURCHASE_ORDERS_STATUS_FILTER] || ''}
        items={[{ label: 'All', value: '' }, ...statusLabels]}
        onChange={handleQueryChange}
      />
    </>
  )

  return (
    <Layout>
      <Header
        foldableActions={foldableActions}
        title={title}
        summarizers={summarizers}
        filters={allFilters}
        actions={actions}
        breadcrumbs={[
          {
            title: 'Back Of House',
            link: URL.ADMIN_BOH
          }
        ]}
      />
      <DataTable
        columns={columns}
        message={purchaseOrders.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingPurchaseOrders}
        hasListNext={hasPurchaseOrdersNext}
        listNext={fetchPurchaseOrdersNext}
        query={query}
        updateQuery={updateQuery}
      >
        {purchaseOrders.map(po => (
          <InventoryOrderItem
            key={po.id}
            purchaseOrder={{
              ...po,
              brand: brandMap[po.brand_id],
              location: getLocationAtId(po.location_id)?.display_name
            }}
          />
        ))}
      </DataTable>
    </Layout>
  )
}

InventoryOrders.propTypes = {
  shortName: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.func,
  statusLabels: PropTypes.arrayOf(PropTypes.object),
  glanceTiles: PropTypes.arrayOf(PropTypes.object)
}

export default InventoryOrders
