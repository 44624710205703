import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from 'icons/SearchIcon'

import { useConditionalEffect, useMediaQuery } from 'hooks'
import { MARGINS } from 'constants/enums'
import { syntheticEvent } from 'utils/general'

import TextField from 'components/TextField'

import styles from './SearchInputStyles'

const SEARCH_DEBOUNCE_DURATION_MS = 250

const SearchInput = ({
  classes,
  name,
  label,
  value,
  disabled,
  placeholder = 'Search...',
  onChange,
  onKeyPress,
  onFocus,
  className,
  dataTest,
  hideStartAdornment,
  ...rest
}) => {
  const { isMobileScreen } = useMediaQuery()
  const [localChange, setLocalChange] = useState('')
  const timer = useRef(null)

  useConditionalEffect(() => {
    if (timer) {
      timer.current && clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        onChange && onChange(syntheticEvent(localChange, name))
      }, SEARCH_DEBOUNCE_DURATION_MS)
    }
    return () => clearTimeout(timer.current)
  }, [localChange])

  const handleLocalChange = e => {
    setLocalChange(e.target.value)
  }

  const handleClear = () => {
    setLocalChange('')
    onChange && onChange(syntheticEvent('', name))
  }

  const rootClassNames = classNames({
    [classes.root]: true,
    [classes.mobileScreen]: isMobileScreen,
    [className]: Boolean(className)
  })

  // in the case that the input gets cleared via params in parent component
  useConditionalEffect(() => {
    if (!value) {
      setLocalChange('')
    }
  }, [value])

  return (
    <TextField
      name={name}
      label={label}
      value={localChange || ''}
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleLocalChange}
      onKeyPress={onKeyPress}
      margin={MARGINS.dense}
      className={rootClassNames}
      dataTest={dataTest}
      classes={{ input: classes.input }}
      onFocus={onFocus}
      InputProps={{
        startAdornment: hideStartAdornment ? null : (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment:
          value && value.length > 0 ? (
            <InputAdornment position="end">
              <IconButton className={classes.iconButton} onClick={handleClear}>
                <ClearIcon
                  className={classes.clearIcon}
                  data-test="search-input-clear-text"
                />
              </IconButton>
            </InputAdornment>
          ) : null
      }}
      {...rest}
    />
  )
}

SearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onClearClick: PropTypes.func,
  disabled: PropTypes.bool,
  dataTest: PropTypes.string,
  onFocus: PropTypes.func
}

export default withStyles(styles)(SearchInput)
