export default theme => ({
  paper: {
    backgroundColor: theme.palette.colorsAF.white,
    boxShadow: '0px 0px 18px 0px rgba(141, 141, 141, 0.32)'
  },
  header: {
    margin: theme.spacing(2, 2, 0)
  },
  subtitle: {
    size: 10,
    fontWeight: 400,
    marginTop: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.colorsAF.semiGray}`
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2),
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  }
})
