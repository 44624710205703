import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Radio } from '@material-ui/core'
import { PROMO_CODE_FIELDS, APPLIES_TO_LABELS } from 'constants/promoCodes'

import { AutocompleteSearchProductControlled } from 'components/AutocompleteSearchProduct'
import { AutocompleteSearchCollectionsControlled } from 'components/AutocompleteSearchCollections'

import { useControlledForm } from 'components/ControlledForm'

const ApplicationRadioInputs = ({ classes }) => {
  const {
    formContext: { setValue, register, unregister, watch }
  } = useControlledForm()

  useEffect(() => {
    register(PROMO_CODE_FIELDS.application_selection)
    return () => {
      unregister(PROMO_CODE_FIELDS.application_selection)
    }
  }, [register, unregister, PROMO_CODE_FIELDS.application_selection])

  const {
    [PROMO_CODE_FIELDS.application_selection]: applicationSelection,
    [PROMO_CODE_FIELDS.eligible_product_ids]: eligibleProducts,
    [PROMO_CODE_FIELDS.excluded_product_ids]: excludedProducts,
    [PROMO_CODE_FIELDS.eligible_collection_ids]: eligibleCollections,
    [PROMO_CODE_FIELDS.excluded_collection_ids]: excludedCollections
  } = watch(
    [
      PROMO_CODE_FIELDS.application_selection,
      PROMO_CODE_FIELDS.eligible_product_ids,
      PROMO_CODE_FIELDS.excluded_product_ids,
      PROMO_CODE_FIELDS.eligible_collection_ids,
      PROMO_CODE_FIELDS.excluded_collection_ids
    ].filter(n => n && typeof n === 'string')
  )

  const renderInput = APPLIES_TO_LABELS?.filter(
    ({ value }) => value === applicationSelection
  )[0]?.renderInput

  return (
    <>
      <Typography variant="caption">Applications</Typography>
      <Box className={classes.radioButtonContainer}>
        {APPLIES_TO_LABELS.map(({ label: thisLabel, value }) => (
          <Box className={classes.radioButtonWithLabel} key={value}>
            <Radio
              color="primary"
              checked={applicationSelection === value}
              onChange={e =>
                setValue(
                  PROMO_CODE_FIELDS.application_selection,
                  e.target.value,
                  { shouldDirty: true }
                )
              }
              value={value}
            />
            <Typography variant="body1">{thisLabel}</Typography>
          </Box>
        ))}
      </Box>
      {Boolean(renderInput) && (
        <Box className={classes.verticalDisplay}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            width="100%"
          >
            {applicationSelection === 'select_products' && (
              <>
                <Box mb={1}>
                  <Typography variant="caption">Included Products</Typography>
                </Box>
                <AutocompleteSearchProductControlled
                  name={PROMO_CODE_FIELDS.eligible_product_ids}
                  className={classes.controlContainer}
                  excludedProducts={excludedProducts}
                  multiple
                />
                <Box mt={1}>
                  <Typography variant="caption">Excluded Products</Typography>
                </Box>
                <AutocompleteSearchProductControlled
                  name={PROMO_CODE_FIELDS.excluded_product_ids}
                  className={classes.controlContainer}
                  excludedProducts={eligibleProducts}
                  multiple
                />
              </>
            )}
            {applicationSelection === 'select_collections' && (
              <>
                <Box mb={1}>
                  <Typography variant="caption">
                    Included Collections
                  </Typography>
                </Box>
                <AutocompleteSearchCollectionsControlled
                  multiple
                  name={PROMO_CODE_FIELDS.eligible_collection_ids}
                  className={classes.controlContainer}
                  hiddenItems={excludedCollections}
                />
                <Box mt={1}>
                  <Typography variant="caption">
                    Excluded Collections
                  </Typography>
                </Box>
                <AutocompleteSearchCollectionsControlled
                  multiple
                  name={PROMO_CODE_FIELDS.excluded_collection_ids}
                  className={classes.controlContainer}
                  hiddenItems={eligibleCollections}
                />
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

ApplicationRadioInputs.propTypes = {
  classes: PropTypes.object.isRequired
}

export default ApplicationRadioInputs
