import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEqual, isEmpty } from 'lodash'
import moment from 'moment-timezone'
import saveCsv from 'save-csv'
import * as yup from 'yup'
import { useFormContext } from 'react-hook-form'

import { AiFillPrinter } from 'react-icons/ai'

import { withStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography, TableRow, TableCell } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import {
  PO_FIELDS,
  PO_SCHEMAS,
  PO_SUBMIT_EDITABLE_SCHEMAS,
  PO_FORM_DEFAULTS,
  VARIANT_COLUMNS,
  PURCHASE_ORDER_STATE_TYPES,
  PURPOSE_POSSIBILITIES
} from 'constants/purchaseOrders'
import { BUTTON_VARIANT, COLOR, SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'
import { TERM_POSSIBILITIES } from 'constants/general'

import { useLocationsContext } from 'context'

import {
  useConditionalEffect,
  useMenu,
  useNavigation,
  useMediaQuery,
  useAlerts,
  useLoaders,
  useQuery,
  useNotifications,
  usePrevious,
  useAdminPurchaseOrder,
  useAdminBrands,
  useAdminPurchaseOrderVariantList,
  useAdminSupplier
} from 'hooks'

import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'

import AddIcon from 'icons/AddIcon'
import ImportIcon from 'icons/ImportIcon'
import SubmitIcon from 'icons/SubmitIcon'

import { AutocompleteSearchBrandsControlled } from 'components/AutocompleteSearchBrands'
import { AutocompleteSearchDistributionCentersControlled } from 'components/AutocompleteSearchDistributionCenters'
import { AutocompleteSearchSuppliersControlled } from 'components/AutocompleteSearchSuppliers'
import { DatePickerField } from 'components/DatePicker'
import { SelectField } from 'components/Select'
import { TextFieldControlled } from 'components/TextField'
import Block from 'components/Block'
import Button from 'components/Button'
import Confirm from 'components/Confirm'
import ConfirmLeaveWhenDirty from 'components/ConfirmLeaveWhenDirty'
import ControlledForm, { useControlledForm } from 'components/ControlledForm'
import DataTable from 'components/DataTable'
import Dialog from 'components/Dialog'
import Fluid from 'components/Fluid'
import FormHiddenInput from 'components/FormHiddenInput'
import Header from 'components/Header'
import Layout from 'components/Layout'
import MemoContainer from 'components/MemoContainer'
import MemoList from 'components/MemoList'
import PurchaseOrderStatus from 'components/PurchaseOrderStatus'
import PurchaseOrderVariantItem from 'containers/admin/PurchaseOrderDetails/PurchaseOrderVariantItem'
import PurchaseOrderVariantsDrawer from 'components/PurchaseOrderVariantsDrawer'
import SearchInput from 'components/SearchInput'

import styles from './PurchaseOrderDetailsStyles'

const SUBMIT_TYPE_NAME = 'submitType'

export const variantFieldName = id => `quantity${id}`

const POMenu = ({
  classes,
  menuToggle,
  menuOpen,
  menuClose,
  menuEl,
  setShowConfirmDeletePurchaseOrderModal,
  purchaseOrder
}) => {
  const { go } = useNavigation({
    url: URL.ADMIN_PRODUCTS
  })
  return (
    <Box
      className={classes.menuButton}
      onClick={menuToggle}
      data-test="po-menu"
    >
      <MoreVertIcon />
      <Menu open={menuOpen} onClose={menuClose} anchorEl={menuEl}>
        <MenuItem
          onClick={e =>
            go(e, `${URL.ADMIN_PURCHASE_ORDERS}/${purchaseOrder?.id}/history`)
          }
          data-test="po-history-menu-item"
        >
          History
        </MenuItem>
        {purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.draft && (
          <MenuItem onClick={e => setShowConfirmDeletePurchaseOrderModal(true)}>
            Delete
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}

const DistributionCenterSupplierSelect = ({ editable, isMobile }) => {
  const {
    formContext: { getValues, setValue }
  } = useControlledForm()
  const supplier = getValues(PO_FIELDS.supplier)
  const supplierId = supplier?.id

  useConditionalEffect(() => {
    if (!supplierId) {
      setValue(PO_FIELDS.distributionCenter, {})
    }
  }, [supplierId])

  return (
    <>
      <Box>
        <Box mb={1}>
          <Typography variant="caption">Supplier</Typography>
        </Box>
        <AutocompleteSearchSuppliersControlled
          name={PO_FIELDS.supplier}
          disabled={!editable}
          withStartAdornment={false}
          fullWidth={isMobile}
        />
      </Box>
      {supplierId && (
        <Box>
          <Box mb={1}>
            <Typography variant="caption">Location</Typography>
          </Box>
          <AutocompleteSearchDistributionCentersControlled
            name={PO_FIELDS.distributionCenter}
            disabled={!editable}
            withStartAdornment={false}
            fullWidth={isMobile}
            queryParams={{
              supplierId
            }}
          />
        </Box>
      )}
    </>
  )
}

const PaymentTermsPicker = ({ isMobile, editable, classes }) => {
  const {
    formContext: { getValues, setValue }
  } = useControlledForm()
  const supplier = getValues(PO_FIELDS.supplier)
  const supplierId = supplier?.id

  const { supplier: supp } = useAdminSupplier({ id: supplierId })

  useConditionalEffect(() => {
    if (
      Number.isInteger(Number(supplierId)) &&
      !isEmpty(supp) &&
      !!supplier.payment_terms
    ) {
      setValue(PO_FIELDS.paymentTerms, supplier.payment_terms)
    }
  }, [supp])

  return (
    <SelectField
      name={PO_FIELDS.paymentTerms}
      items={TERM_POSSIBILITIES}
      label="Payment Terms"
      fullWidth={isMobile}
      disabled={!editable}
      dataTest="po-terms"
      className={classNames({
        [classes.terms]: !isMobile
      })}
    />
  )
}

const PurchaseOrderDetails = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const [
    showConfirmDeletePurchaseOrderModal,
    setShowConfirmDeletePurchaseOrderModal
  ] = useState(false)
  const [addProductDrawerOpen, setAddProductDrawerOpen] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)
  const csvName = useRef()

  const {
    open: menuOpen,
    anchorEl: menuEl,
    handleToggleMenu: menuToggle,
    closeMenu: menuClose
  } = useMenu()

  const { showAlertError, showAlertSuccess, showAlertGeneral } = useAlerts()
  const { showLoading, hideLoading } = useLoaders()

  const {
    purchaseOrder,
    updatePurchaseOrder,
    isLoadingPurchaseOrder,
    isPurchaseOrderError,
    purchaseOrderError,
    getExport: printPDF,
    destroyPurchaseOrder
  } = useAdminPurchaseOrder(id)

  // brands used for backwards compatibility
  const { readBrand } = useAdminBrands()

  useNotifications(
    isLoadingPurchaseOrder,
    isPurchaseOrderError,
    purchaseOrderError
  )

  const { go } = useNavigation({
    url: URL.ADMIN_PURCHASE_ORDERS
  })

  const { locationOptionsDisplayName, getLocationAtId } = useLocationsContext()

  const {
    query: variantsQuery,
    handleQueryChange: handleVariantsQueryChange,
    updateQuery: updateVariantsQuery
  } = useQuery(false, {
    initFallback: {
      purchase_order_id: id
    }
  })

  const onImportCSVSuccess = res => {
    const translateErrorReason = reasons => {
      if (reasons.variant_not_found) {
        return 'Variant SKU not found'
      }

      if (reasons.brand_products_include_variant_product === false) {
        return 'Variant brand does not match PO brand'
      }

      if (reasons.distribution_center_includes_variant === false) {
        return 'Distribution center does not include that Variant'
      }

      return 'Unknown error'
    }
    const name = csvName.current
    saveCsv(
      res.errored.map(obj => {
        const sku = Object.keys(obj)[0] // weird but that's how we get it from the back-end
        // keys are column names, values are the value in that column
        return { SKU: sku, QTY: '', REASON: translateErrorReason(obj[sku]) }
      }),
      { filename: `${name.slice(0, name.length - 4)}-errors.csv` }
    ) // append -errors to original name
  }

  const onCreateSuccess = hideLoading

  const {
    createPurchaseOrderVariants,
    updatePurchaseOrderVariants,
    deletePurchaseOrderVariant,
    importCSV,
    variants: purchaseOrderVariants,
    hasVariantsNext: hasPurchaseOrderVariantsNext,
    fetchVariantsNext: listPurchaseOrderVariantsNext,
    isLoading: isLoadingPurchaseOrderVariants,
    variantsMeta
  } = useAdminPurchaseOrderVariantList(id, variantsQuery, {
    onImportCSVSuccess,
    onCreateSuccess
  })

  const variantSums = {
    damaged: variantsMeta?.purchase_order_variant_damaged_sum,
    quantity: variantsMeta?.purchase_order_variant_quantity_sum,
    pastDamaged: variantsMeta?.purchase_order_variant_past_damaged_sum,
    received: variantsMeta?.purchase_order_variant_received_sum,
    pastReceived: variantsMeta?.purchase_order_variant_past_received_sum,
    totalPrice: variantsMeta?.purchase_order_variant_total_price_sum,
    totalCost: variantsMeta?.purchase_order_variant_total_cost_sum
  }

  const { isMobileScreen } = useMediaQuery()
  const isMobile = Boolean(isMobileScreen)

  const editable =
    purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.draft ||
    purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.open

  const submitEditable =
    editable || purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.submitted

  const SaveButton = () => {
    const {
      formState: { isDirty, dirtyFields }
    } = useFormContext()
    return (
      <Button
        size={SIZE.medium}
        color={COLOR.primary}
        dataTest="po-save-button"
        variant={BUTTON_VARIANT.contained}
        startIcon={<SaveIcon />}
        adaptive
        label="Save"
        disabled={!isDirty || Object.keys(dirtyFields).length === 0}
        type="submit"
      >
        Save
      </Button>
    )
  }

  const handleDeletePurchaseOrder = async () => {
    showLoading()
    await destroyPurchaseOrder(purchaseOrder.id)
    setShowConfirmDeletePurchaseOrderModal(false)
    go({}, URL.ADMIN_PURCHASE_ORDERS)
    hideLoading()
  }

  const handlePrint = async () => {
    showLoading()
    showAlertGeneral(`PO#${id}.pdf will download shortly!`)
    await printPDF(id)
    hideLoading()
  }

  const printButton = (
    <Button
      onClick={handlePrint}
      label="Export"
      adaptive
      startIcon={<AiFillPrinter color="black" />}
      color={isMobile ? COLOR.primary : COLOR.secondary}
      size={SIZE.medium}
      disabled={
        purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.draft ||
        purchaseOrderVariants.length === 0
      }
      className={classes.printButton}
    />
  )

  let actions = (
    <>
      <Skeleton width="105px" height="44px" />{' '}
      <Skeleton width="90px" height="44px" />
    </>
  )
  if (
    purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.draft ||
    purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.open
  ) {
    const SubmitButton = () => {
      const { setValue } = useFormContext()
      const { handleSubmit } = useControlledForm()
      return (
        <Confirm
          description="Are you sure you want to submit the PO?"
          onConfirm={e => {
            setValue(SUBMIT_TYPE_NAME, PURCHASE_ORDER_STATE_TYPES.submitted)
            handleSubmit(e)
          }}
          onClose={() => setOpenPopover(false)}
          open={openPopover}
        >
          <Button
            variant={BUTTON_VARIANT.outlined}
            size={SIZE.medium}
            color={COLOR.secondary}
            startIcon={<SubmitIcon />}
            adaptive
            disabled={
              purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.draft ||
              purchaseOrderVariants.length === 0
            }
            label="Submit"
            onClick={() => setOpenPopover(true)}
            dataTest="po-submit-button"
          >
            Submit
          </Button>
        </Confirm>
      )
    }
    actions = (
      <>
        <POMenu
          classes={classes}
          menuToggle={menuToggle}
          menuOpen={menuOpen}
          menuClose={menuClose}
          menuEl={menuEl}
          setShowConfirmDeletePurchaseOrderModal={
            setShowConfirmDeletePurchaseOrderModal
          }
          purchaseOrder={purchaseOrder}
        />
        {purchaseOrder?.status === PURCHASE_ORDER_STATE_TYPES.open &&
          printButton}
        <SubmitButton />
        <SaveButton />
      </>
    )
  } else if (purchaseOrder?.status !== PURCHASE_ORDER_STATE_TYPES.closed) {
    const CloseButton = () => {
      const { setValue } = useFormContext()
      const { handleSubmit } = useControlledForm()
      return (
        <Button
          variant={BUTTON_VARIANT.outlined}
          size={SIZE.medium}
          type="submit"
          color={COLOR.primary}
          startIcon={<CloseIcon />}
          adaptive
          label="Close"
          onClick={e => {
            setValue(SUBMIT_TYPE_NAME, PURCHASE_ORDER_STATE_TYPES.closed)
            handleSubmit(e)
          }}
          name="type"
          value="closed"
        >
          Close
        </Button>
      )
    }
    actions = (
      <>
        <POMenu
          classes={classes}
          menuToggle={menuToggle}
          menuOpen={menuOpen}
          menuClose={menuClose}
          menuEl={menuEl}
          setShowConfirmDeletePurchaseOrderModal={
            setShowConfirmDeletePurchaseOrderModal
          }
          purchaseOrder={purchaseOrder}
        />
        {printButton}
        <CloseButton />
        <SaveButton />
      </>
    )
  } else if (purchaseOrder?.status) {
    actions = (
      <>
        <POMenu
          classes={classes}
          menuToggle={menuToggle}
          menuOpen={menuOpen}
          menuClose={menuClose}
          menuEl={menuEl}
          setShowConfirmDeletePurchaseOrderModal={
            setShowConfirmDeletePurchaseOrderModal
          }
          purchaseOrder={purchaseOrder}
        />
        {printButton}
        <SaveButton />
      </>
    )
  }

  const handleSubmit = async ({
    submitType,
    memo,
    distributionCenter,
    status,
    location_id,
    ...fields
  }) => {
    const memoObject = memo ? { memo: { subject: '', body: memo } } : {}
    const distribution_center_id = distributionCenter.id
    const { distribution_center_locations } = distributionCenter
    delete fields[PO_FIELDS.distributionCenter]
    delete fields[PO_FIELDS.supplier]

    await updatePurchaseOrder(
      {
        ...fields,
        ...memoObject,
        distribution_center_id,
        location_id:
          location_id ?? distribution_center_locations?.[0]?.location_id, // NOTE: @awitherow we are currently limiting to one location
        status:
          submitType ||
          (editable ? PURCHASE_ORDER_STATE_TYPES.open : purchaseOrder.status)
      },
      submitType === PURCHASE_ORDER_STATE_TYPES.submitted
        ? 'submitted'
        : undefined
    )
    showAlertSuccess(
      submitType === PURCHASE_ORDER_STATE_TYPES.submitted
        ? 'Purchase order successfully submitted'
        : 'Purchase order successfully updated'
    )
  }

  // The update endpoint replaces so we make sure to also include the
  // existing variants.
  const handleVariantSubmit = variants => {
    showLoading()
    createPurchaseOrderVariants(variants)
  }

  const variantFormDefaults = {}
  const variantSchemas = {}
  if (purchaseOrderVariants) {
    purchaseOrderVariants.forEach(v => {
      variantFormDefaults[variantFieldName(v.id)] =
        v.purchase_order_variant_quantity
      variantSchemas[variantFieldName(v.id)] = yup
        .number()
        .positive()
        .required('Must be positive number')
    })
  }

  const VariantSaveButton = () => {
    const {
      formState: { isDirty, dirtyFields }
    } = useFormContext()
    if (isDirty && Object.keys(dirtyFields).length > 0) {
      return (
        <Button
          className={classes.productButton}
          variant="outlined"
          size={SIZE.medium}
          fullWidth={isMobile}
          type="submit"
          dataTest="variant-save-button"
        >
          Save
        </Button>
      )
    }
    return <></>
  }

  const handleVariantQuantitySubmit = async quantities => {
    await updatePurchaseOrderVariants(
      purchaseOrderVariants
        .filter(
          v =>
            v.purchase_order_variant_quantity !==
            quantities[variantFieldName(v.id)]
        )
        .map(v => {
          return { id: v.id, quantity: quantities[variantFieldName(v.id)] }
        })
    )
  }

  const handleDeleteVariant = variant => {
    deletePurchaseOrderVariant(variant)
  }

  const handleImportClick = async e => {
    if (e.target.files.length > 0) {
      // need the name for handling the result but the BE doesn't give it to us
      csvName.current = e.target.files[0].name
      importCSV(e.target)
    } else {
      showAlertError('No files specified for upload')
    }
  }

  const [formDefaults, setFormDefaults] = useState(PO_FORM_DEFAULTS)
  const getDefaultValues = async po =>
    !isEmpty(po)
      ? {
          ...(po.brand_id && {
            [PO_FIELDS.brand]: await readBrand(po.brand_id)
          }),
          ...(po.brand_id && { [PO_FIELDS.location]: po.location_id || '' }),
          [PO_FIELDS.supplier]: po.supplier ?? {},
          [PO_FIELDS.distributionCenter]: po.distribution_center ?? {},
          [PO_FIELDS.paymentTerms]: po.terms || '',
          [PO_FIELDS.purpose]: po.purpose || '',
          [PO_FIELDS.orderDate]: po.order_date ? moment(po.order_date) : null,
          [PO_FIELDS.estimatedArrival]: po.estimated_arrival_at
            ? moment(po.estimated_arrival_at)
            : null,
          [PO_FIELDS.shipDate]: po.ship_at ? moment(po.ship_at) : null,
          [PO_FIELDS.cancelDate]: po.cancel_at ? moment(po.cancel_at) : null,
          [PO_FIELDS.notesForSupplier]: po.notes || '',
          [PO_FIELDS.memo]: ''
        }
      : PO_FORM_DEFAULTS

  const setDefaults = async po => setFormDefaults(await getDefaultValues(po))
  const prevPurchaseOrder = usePrevious(purchaseOrder)
  useConditionalEffect(() => {
    ;(async () => {
      if (
        !isEmpty(purchaseOrder) &&
        !isEqual(prevPurchaseOrder, purchaseOrder)
      ) {
        // backwards compatibility to ensure that brands are no longer
        // allowed and we force the use of suppliers/distribution centers
        // on any PO's that are draft/open with brands already selected
        if (
          [
            PURCHASE_ORDER_STATE_TYPES.draft,
            PURCHASE_ORDER_STATE_TYPES.open
          ].includes(purchaseOrder.status) &&
          !!purchaseOrder.brand_id
        ) {
          showLoading()
          const po = await updatePurchaseOrder({
            brand_id: null
          })
          setDefaults(po)
          hideLoading()
          showAlertGeneral(
            `Brand unset on Purchase Order. Select a Supplier to continue!`
          )
        }

        setDefaults(purchaseOrder)
      }
    })()
  }, [purchaseOrder])

  const skeleton = { width: '140px', height: '60px' }

  return (
    <Layout id="purchase-order-details">
      <ControlledForm
        handleSubmit={handleSubmit}
        schemas={
          editable
            ? submitEditable
              ? PO_SUBMIT_EDITABLE_SCHEMAS
              : PO_SCHEMAS
            : {}
        }
        defaultValues={formDefaults}
      >
        <FormHiddenInput name={SUBMIT_TYPE_NAME} />
        <ConfirmLeaveWhenDirty />
        <Header
          breadcrumbs={[
            { title: 'Purchase Order', link: URL.ADMIN_PURCHASE_ORDERS }
          ]}
          title={id}
          actions={actions}
        />
        <Box
          className={classNames({
            [classes.root]: true,
            [classes.isMobile]: isMobile
          })}
          data-test="po-details"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.gridItem}>
              <Block withPadding className={classes.details}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5">Details for PO #{id}</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row">
                      <Typography variant="h5">Status: </Typography>
                      {purchaseOrder?.status ? (
                        <PurchaseOrderStatus status={purchaseOrder.status} />
                      ) : (
                        <Skeleton width="60px" />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Fluid classes={{ root: classes.detailsContainer }}>
                  {purchaseOrder ? (
                    <>
                      {purchaseOrder.brand_id &&
                      ![
                        PURCHASE_ORDER_STATE_TYPES.draft,
                        PURCHASE_ORDER_STATE_TYPES.open
                      ].includes(purchaseOrder.status) ? (
                        <Box className={classes.brandSearchContainer}>
                          <Box mb={1}>
                            <Typography variant="caption">Brand</Typography>
                          </Box>
                          <AutocompleteSearchBrandsControlled
                            name={PO_FIELDS.brand}
                            className={classNames({
                              [classes.brandSearch]: !isMobile
                            })}
                            disabled={!editable}
                            withStartAdornment={false}
                            fullWidth={isMobile}
                          />
                        </Box>
                      ) : (
                        <DistributionCenterSupplierSelect
                          editable={editable}
                          isMobile={isMobile}
                        />
                      )}
                      {purchaseOrder.brand_id && (
                        <SelectField
                          isLoaded={Boolean(locationOptionsDisplayName)}
                          skeleton={skeleton}
                          name={PO_FIELDS.location}
                          items={locationOptionsDisplayName || []}
                          label="Location"
                          fullWidth={isMobile}
                          disabled={!editable}
                          dataTest="po-location"
                        />
                      )}
                      <PaymentTermsPicker
                        {...{
                          isMobile,
                          editable,
                          classes
                        }}
                      />
                      <SelectField
                        name={PO_FIELDS.purpose}
                        items={PURPOSE_POSSIBILITIES}
                        label="Purpose"
                        fullWidth={isMobile}
                        disabled={!editable}
                        dataTest="po-purpose"
                        className={classNames({
                          [classes.terms]: !isMobile
                        })}
                      />
                      <DatePickerField
                        name={PO_FIELDS.orderDate}
                        label="Order Date"
                        fullWidth={isMobile}
                        disabled={!editable}
                        dataTest="po-order-date"
                        disablePortal={false}
                      />
                      <DatePickerField
                        name={PO_FIELDS.shipDate}
                        label="Start Ship Date"
                        fullWidth={isMobile}
                        disabled={!submitEditable}
                        dataTest="po-ship-date"
                        disablePortal={false}
                      />
                      <DatePickerField
                        name={PO_FIELDS.estimatedArrival}
                        label="Estimated Arrival"
                        fullWidth={isMobile}
                        disabled={!submitEditable}
                        dataTest="po-eta-date"
                        disablePortal={false}
                      />
                      <DatePickerField
                        name={PO_FIELDS.cancelDate}
                        label="Cancel Date"
                        fullWidth={isMobile}
                        disabled={!submitEditable}
                        dataTest="po-cancel-date"
                        disablePortal={false}
                      />
                    </>
                  ) : (
                    <Skeleton />
                  )}
                </Fluid>
              </Block>
            </Grid>
            <Grid item xs={12} md={4}>
              <Block withPadding className={classes.memoContainer}>
                {!isLoadingPurchaseOrder && (
                  <MemoContainer
                    name={PO_FIELDS.memo}
                    placeholder="Purchase Order Notes"
                    defaultActive={false}
                    selfContainedStyles={false}
                  />
                )}
                <MemoList
                  memos={
                    purchaseOrder?.memos
                      ? purchaseOrder.memos
                          .slice(0)
                          .sort(
                            (a, b) =>
                              new Date(b.updated_at) - new Date(a.updated_at)
                          )
                      : []
                  }
                />
              </Block>
            </Grid>
            <Grid item xs={12} md={4}>
              <Block withPadding>
                Notes for Supplier
                <TextFieldControlled
                  dataTest="po-notes-for-supplier"
                  multiline
                  name={PO_FIELDS.notesForSupplier}
                />
              </Block>
            </Grid>
          </Grid>
        </Box>
        {purchaseOrder?.distribution_center_id && submitEditable && (
          <PurchaseOrderVariantsDrawer
            open={addProductDrawerOpen}
            onClose={() => setAddProductDrawerOpen(false)}
            onSubmit={handleVariantSubmit}
            supplierId={purchaseOrder?.supplier?.id}
            distributionCenterId={purchaseOrder?.distribution_center_id}
            location={getLocationAtId(purchaseOrder.location_id)?.display_name}
            orderVariants={purchaseOrderVariants}
          />
        )}
      </ControlledForm>

      <ControlledForm
        handleSubmit={handleVariantQuantitySubmit}
        schemas={variantSchemas}
        defaultValues={variantFormDefaults}
      >
        {submitEditable && <ConfirmLeaveWhenDirty />}
        <Box mt={2} mb={2}>
          <Box display="flex" justifyContent="space-between">
            <SearchInput
              dataTest="search-variants"
              name="search"
              placeholder="Search purchase order"
              value={variantsQuery.search}
              onChange={handleVariantsQueryChange}
              disabled={
                purchaseOrderVariants.length === 0 &&
                !isLoadingPurchaseOrderVariants &&
                !variantsQuery?.search
              }
            />
            {purchaseOrder?.distribution_center_id && submitEditable && (
              <Box pr={isMobile ? 1 : 0}>
                <VariantSaveButton />
                <label htmlFor="import-csv">
                  <input
                    accept=".csv"
                    style={{ display: 'none' }}
                    id="import-csv"
                    name="import-csv"
                    type="file"
                    onChange={handleImportClick}
                  />
                  <Button
                    className={classes.productButton}
                    variant="outlined"
                    size={SIZE.medium}
                    startIcon={<ImportIcon />}
                    fullWidth={isMobile}
                    component="span"
                  >
                    Import
                  </Button>
                </label>

                <Button
                  className={classes.productButton}
                  variant="outlined"
                  size={SIZE.medium}
                  onClick={() => setAddProductDrawerOpen(true)}
                  startIcon={<AddIcon />}
                  fullWidth={isMobile}
                  dataTest="add-products"
                >
                  Products
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        <DataTable
          message={
            purchaseOrderVariants?.length === 0 ? 'No results found.' : null
          }
          isLoadingList={isLoadingPurchaseOrderVariants}
          hasListNext={hasPurchaseOrderVariantsNext}
          listNext={listPurchaseOrderVariantsNext}
          query={variantsQuery}
          updateQuery={updateVariantsQuery}
          columns={VARIANT_COLUMNS}
        >
          {purchaseOrderVariants?.map(variant => (
            <PurchaseOrderVariantItem
              key={variant.id}
              variant={variant}
              openVariantsDrawer={() => setAddProductDrawerOpen(true)}
              editable={submitEditable}
              deleteVariant={handleDeleteVariant}
            />
          ))}
          {purchaseOrderVariants && (
            <TableRow>
              <TableCell colSpan={5} />
              <TableCell>Total</TableCell>
              <TableCell>${variantSums?.totalPrice}</TableCell>
              <TableCell>${variantSums?.totalCost}</TableCell>
              <TableCell>{variantSums?.quantity}</TableCell>
              <TableCell>
                {variantSums?.pastReceived &&
                variantSums.pastReceived > variantSums.received
                  ? variantSums.pastReceived
                  : variantSums.received}
              </TableCell>
              <TableCell>
                {variantSums?.pastDamaged &&
                variantSums.pastDamaged > variantSums.damaged
                  ? variantSums.pastDamaged
                  : variantSums?.damaged}
              </TableCell>
              <TableCell />
            </TableRow>
          )}
        </DataTable>
      </ControlledForm>
      <Dialog
        open={showConfirmDeletePurchaseOrderModal}
        onClose={() => setShowConfirmDeletePurchaseOrderModal(false)}
        onConfirm={handleDeletePurchaseOrder}
        confirmText="Okay"
        hideCancel
      >
        <Box mt={1}>
          <Typography variant="h5">Confirm Action</Typography>
        </Box>
        <Box my={3}>
          <Typography variant="body1">
            Are you sure you want to delete this purchase order? This action
            cannot be undone.
          </Typography>
        </Box>
      </Dialog>
    </Layout>
  )
}

PurchaseOrderDetails.defaultProps = {}

PurchaseOrderDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(PurchaseOrderDetails)
