import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

const DIAMETER = 24
const RADIUS = DIAMETER / 2
const BORDER_RADIUS = 32

const ColorCircle = ({ hex, image }) => (
  <Box height={DIAMETER} width={DIAMETER} borderRadius={RADIUS} bgcolor={hex}>
    {image && (
      <img
        alt="color-circle"
        width="100%"
        height="100%"
        style={{ borderRadius: BORDER_RADIUS }}
        src={image}
      />
    )}
  </Box>
)

ColorCircle.propTypes = {
  hex: PropTypes.string,
  image: PropTypes.string
}

export default ColorCircle
