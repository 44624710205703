import { addOpacity } from 'utils/general'

const jssStyles = theme => ({
  TableContainer: {
    height: 'calc(100vh - 229px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 139px)'
    }
  },
  statusCell: {
    width: 75
  },
  statusTag: {
    borderRadius: 5,
    width: 'fit-content',
    padding: theme.spacing(0.5, 1)
  },
  active: {
    backgroundColor: addOpacity(theme.palette.warnings.success, 0.1),
    color: theme.palette.warnings.success
  },
  inactive: {
    backgroundColor: addOpacity(theme.palette.warnings.danger, 0.1),
    color: theme.palette.warnings.danger
  }
})

export default jssStyles
