import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { AiFillCheckCircle } from 'react-icons/ai'
import { FiTrash } from 'react-icons/fi'
import { BsFillCalendarXFill, BsFillCalendarCheckFill } from 'react-icons/bs'

import IconButton from '@material-ui/core/IconButton'

import { URL } from 'constants/navigation'
import { colorsAF } from 'theme/colors'

import { useNavigation, useAdminStorefront } from 'hooks'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { parseDateIgnoreTZ } from 'utils/general'

const StorefrontItem = ({ storefront, timezone }) => {
  const { handleClick: handleStorefrontClick } = useNavigation({
    url: URL.ADMIN_STOREFRONT
  })

  const { deleteStoreFront } = useAdminStorefront({ id: storefront.id })
  const publishDateTime = storefront.publish_at
    ? parseDateIgnoreTZ(storefront.publish_at)
    : null

  const renderStorefrontIcon = () => {
    if (storefront.current) {
      return (
        <AiFillCheckCircle
          {...{
            fontSize: 26,
            color: colorsAF.fast
          }}
        />
      )
    }

    const restSharedProps = {
      color: colorsAF.lightGray,
      fontSize: 22
    }

    if (publishDateTime.isBefore(moment())) {
      return <BsFillCalendarXFill {...restSharedProps} />
    }

    return <BsFillCalendarCheckFill {...restSharedProps} />
  }

  return (
    <TableRow data-test="storefront-row">
      <TableCell>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          onClick={e => handleStorefrontClick(e, storefront)}
        >
          <Box mr={2}>{renderStorefrontIcon()}</Box>
          <Typography variant="body1">{storefront?.name}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        {!!publishDateTime ? (
          <>
            <Typography variant="body1">
              {publishDateTime.format('YYYY-MM-DD hh:mm A')}
            </Typography>
            <Typography variant="caption">{timezone}</Typography>
          </>
        ) : (
          <Typography variant="body1">N/A</Typography>
        )}
      </TableCell>
      <TableCell>
        <IconButton
          disabled={storefront.current}
          edge="end"
          onClick={async () => await deleteStoreFront()}
        >
          <FiTrash />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

StorefrontItem.propTypes = {
  storefront: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
}

export default StorefrontItem
