import { useResourceListRQ } from 'hooks'
import { internalUserEndpoint } from 'api/endpoints'

const useAdminInternalUserList = ({ params }) => {
  const {
    isFetching: isFetchingInternalUsers,
    isLoading: isLoadingInternalUsers,
    data: internalUsers,
    hasData: hasInternalUsers,
    hasNextPage: hasInternalUsersNext,
    fetchNextPage: fetchInternalUsersNext,
    meta
  } = useResourceListRQ({ endpoint: internalUserEndpoint, params })

  return {
    isFetchingInternalUsers,
    isLoadingInternalUsers,
    internalUsers: internalUsers || [],
    hasInternalUsers,
    hasInternalUsersNext,
    fetchInternalUsersNext,
    summary: meta?.summary
  }
}

export default useAdminInternalUserList
