import { thinInput } from 'constants/styles'

export default theme => ({
  thinInput: thinInput(theme),
  divider: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grays.med}`
  },
  smallNoticeText: {
    fontSize: 12,
    color: theme.palette.colorsAF.mediumGray
  }
})
