import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import { ReactQueryDevtools } from 'react-query/devtools'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline, Box } from '@material-ui/core'
import MomentAdapter from '@material-ui/pickers/adapter/moment'
import { LocalizationProvider } from '@material-ui/pickers'

import theme from 'theme'
import Routes from 'routes'
import { useAuth, useConfig } from 'hooks'
import { useAuthContext, useUIContext, useLocalStorageContext } from 'context'
import { isProductionInstance } from 'utils/general'

import ResponsiveHeader from 'containers/common/ResponsiveHeader'
import Notification from 'components/Notification'
import Spinner from 'components/Spinner'
import SwitchDarkstoreModal from 'components/SwitchDarkstoreModal'
import SiteTitle from 'components/SiteTitle'

moment.tz.setDefault(moment.tz.guess())

const App = ({ ...props }) => {
  const {
    alert,
    closeAlert,
    isLoading,
    showSwitchDarkstoreModal
  } = useUIContext()
  const { authToken } = useLocalStorageContext()
  const { setIsInitialized } = useAuthContext()
  const { fetchMe } = useAuth()
  const { fetchAppConfig } = useConfig()

  useEffect(() => {
    ;(async () => {
      if (authToken) {
        // failed fetch will cause logout and redirect to login
        await fetchMe()
        await fetchAppConfig()
      }
      setIsInitialized(true)
    })()
  }, [])


  // Just some fun to create a number out of the server URL
  // for setting the banner background color.
  const letterToNumber = l => l.charCodeAt(0) - 65
  const serverNum = [...process.env.REACT_APP_SERVER_URL]
    .map((l, idx) => idx * 100 + letterToNumber(l))
    .reduce((thisA, thisB) => thisA + thisB, 0)

  return (
    <MuiThemeProvider theme={theme}>
      <LocalizationProvider
        dateLibInstance={moment}
        dateAdapter={MomentAdapter}
        locale="en"
      >
        {!isProductionInstance() && (
          <Box
            position="fixed"
            top="0"
            right="0"
            left="0"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor={`hsl(${serverNum % 999}deg 98% 56%)`}
            zIndex={9999}
            color="white"
          >
            <span style={{ fontSize: 12, fontWeight: 300 }}>
              <strong>server_url</strong>: {process.env.REACT_APP_SERVER_URL}
            </span>
          </Box>
        )}
        <SiteTitle />
        <CssBaseline />
        <Notification
          open={alert?.open}
          message={alert?.message}
          type={alert?.type}
          autoHideDuration={alert?.autoHideDuration}
          onClose={closeAlert}
        />
        <ResponsiveHeader>
          <Routes {...props} />
        </ResponsiveHeader>

        <SwitchDarkstoreModal open={showSwitchDarkstoreModal} />

        <Spinner active={isLoading} />
        <ReactQueryDevtools initialIsOpen={false} />
      </LocalizationProvider>
    </MuiThemeProvider>
  )
}

export default App
