import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import head from 'lodash/head'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Select from 'components/Select'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'

import {
  useAdminOrders,
  useMediaQuery,
  useLoaders,
  useNavigation,
  useBeforeUnload
} from 'hooks'
import { URL } from 'constants/navigation'
import { SIZE, COLOR, BUTTON_VARIANT } from 'constants/enums'

import Block from 'components/Block'
import Button from 'components/Button'
import Header from 'components/Header'
import Layout from 'components/Layout'
import ReturnSummary from 'components/ReturnSummary'
import ReturnItems from 'components/ReturnItems'
import CustomerDetails from 'components/CustomerDetails'
import StatusTags from 'components/StatusTags'

import styles from './OrderReturnStyles'
import { isEmpty } from 'lodash'

const TEXT_MAX_LENGTH = 480

const RETURN_REASONS = [
  { label: 'Select a reason', value: '', disabled: true },
  { label: 'Size was too small', value: 'too_small' },
  { label: 'Size was too large', value: 'too_large' },
  {
    label: 'Customer changed their mind',
    value: 'changed_mind'
  },
  { label: 'Item not as described', value: 'wrong_description' },
  { label: 'Received the wrong item', value: 'wrong_item' },
  { label: 'Damaged or defective', value: 'damaged' },
  { label: 'Style', value: 'style' },
  { label: 'Color', value: 'color' },
  { label: 'Other', value: 'other' }
]

const initialValues = {
  line_item_returns: [],
  shipping_option: '',
  details: '',
  return_reason: ''
}

const OrderReturn = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const history = useHistory()

  const { order, orderReturn } = useAdminOrders({ id })

  const { handleClick: handleUserClick } = useNavigation({
    url: URL.ADMIN_USERS
  })

  const { state: locationState } = useLocation()

  const [submitting, setSubmitting] = useState(false)

  const [returnObject, setReturnObject] = useState(initialValues)
  const [initialObject, setInitialObject] = useState(initialValues)

  const initData = () => {
    const data = {
      ...returnObject,
      line_item_returns: order?.returnable_line_items.map(item => {
        return {
          line_item_id: item.id,
          quantity: 0
        }
      })
    }
    setReturnObject(data)
    setInitialObject(data)
  }

  useEffect(() => {
    if (!order?.returnable_line_items) return
    initData()
  }, [order])

  const breadcrumbMap = {
    default: [
      { title: 'Orders', link: URL.ADMIN_ORDERS },
      {
        title: `#${order?.number}`,
        link: `${URL.ADMIN_ORDERS}/${order?.id}`
      }
    ]
  }

  const { isDesktopScreen, isTabletScreen, isMobileScreen } = useMediaQuery()

  const containerClasses = classNames({
    [classes.container]: true,
    [classes.isMobile]: Boolean(isMobileScreen),
    [classes.isTablet]: Boolean(isTabletScreen),
    [classes.isDesktop]: Boolean(isDesktopScreen)
  })

  const handleTextChange = e => {
    setReturnObject({ ...returnObject, details: e.target.value })
  }

  const handleSelectChange = e => {
    setReturnObject({ ...returnObject, return_reason: e.target.value })
  }

  const handleShippingChange = value => {
    setReturnObject({ ...returnObject, shipping_option: value })
  }

  const handleItemChange = (itemId, value) => {
    setReturnObject(state => {
      return {
        ...returnObject,
        line_item_returns: state.line_item_returns.map(lineItem =>
          lineItem.line_item_id === itemId
            ? {
                line_item_id: itemId,
                quantity: parseFloat(value)
              }
            : lineItem
        )
      }
    })
  }

  const handleReturn = async () => {
    setSubmitting(true)
    const response = await orderReturn(id, {
      ...returnObject,
      line_item_returns: returnObject.line_item_returns.filter(
        item => item.quantity !== 0
      )
    })
    if (response) history.push(`${URL.ADMIN_ORDERS}/${id}`)
    setSubmitting(false)
  }

  const itemsCount = returnObject?.line_item_returns.reduce((acc, item) => {
    return acc + item.quantity
  }, 0)

  const objectHasChanged = !isEqual(returnObject, initialObject)

  const { OnBeforeUnloadPrompt } = useBeforeUnload(
    objectHasChanged && !submitting
  )

  const actions = (
    <>
      <Button
        size={SIZE.medium}
        color={COLOR.secondary}
        variant={BUTTON_VARIANT.contained}
        label="Cancel"
        startIcon={<CloseIcon />}
        onClick={() => history.push(`${URL.ADMIN_ORDERS}/${id}`)}
      >
        Cancel
      </Button>
      <Button
        size={SIZE.medium}
        color={COLOR.primary}
        variant={BUTTON_VARIANT.contained}
        label="Return items"
        disabled={itemsCount === 0}
        onClick={handleReturn}
      >
        Return {itemsCount} items
      </Button>
    </>
  )

  return (
    <Layout>
      {!isEmpty(order) && (
        <Box className={containerClasses}>
          <Header
            breadcrumbs={breadcrumbMap[locationState || 'default']}
            title="Return"
            actions={actions}
            actionBox
          />
          <Box display="flex" gridGap={12}>
            <StatusTags order={order} />
          </Box>
          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid item sm={12} md={8} className={classes.gridItem}>
              <Block>
                <Box className={classes.paddingBlock}>
                  <Typography data-test="order-items" variant="h5">
                    Order Items
                  </Typography>

                  <Box className={classes.productsContainer}>
                    {order.returnable_line_items.map((lineItem, idx) => (
                      <ReturnItems
                        key={lineItem.id}
                        product={head(
                          order.line_items.filter(
                            orderLineItem => orderLineItem.id === lineItem.id
                          )
                        )}
                        showQuantity
                        showSku
                        isOdd={idx % 2 !== 0}
                        onChange={handleItemChange}
                      />
                    ))}
                  </Box>
                </Box>
                <Box className={classes.paddingBlock}>
                  <Typography data-test="Reason" variant="h5">
                    Reason for return
                  </Typography>
                  <Typography className={classes.subtitle}>
                    Only staff will see this information.
                  </Typography>
                  <Select
                    className={classes.selectReason}
                    name="select-a-reason"
                    value={returnObject?.return_reason}
                    items={RETURN_REASONS}
                    displayEmpty
                    onChange={handleSelectChange}
                  />
                </Box>
                <Typography
                  data-test="Details"
                  variant="caption"
                  className={classes.paddingBlock}
                >
                  Additional details
                </Typography>
                <Box className={classes.divider} />
                <TextField
                  className={classes.textField}
                  placeholder="Optionally, add additional detail here if needed."
                  variant="outlined"
                  inputProps={{
                    maxLength: TEXT_MAX_LENGTH
                  }}
                  multiline
                  rows={7}
                  helperText={`${returnObject?.details?.length} / ${TEXT_MAX_LENGTH}`}
                  onChange={handleTextChange}
                />
              </Block>
            </Grid>
            <Grid item sm={12} md={4} className={classes.gridItem}>
              <Block autoHeight>
                <Box className={classes.paddingBlock}>
                  <ReturnSummary
                    onChange={handleShippingChange}
                    itemsCount={itemsCount}
                    shipping={returnObject.shipping}
                  />
                </Box>
              </Block>
              <Block title="Customer Details" withPadding>
                <CustomerDetails user={order?.user} onClick={handleUserClick} />
                {order?.email && (
                  <Box>
                    <Button
                      href={`mailto:${order.email}`}
                      color={COLOR.default}
                      size={SIZE.medium}
                      variant={BUTTON_VARIANT.outlined}
                    >
                      Email Customer
                    </Button>
                  </Box>
                )}
              </Block>
            </Grid>
          </Grid>
        </Box>
      )}
      <OnBeforeUnloadPrompt />
    </Layout>
  )
}

OrderReturn.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object
}

export default withStyles(styles)(OrderReturn)
