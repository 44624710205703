import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import Drawer from 'components/Drawer'
import Button from 'components/Button'

const ActionDrawer = ({
  classes,
  filters,
  actions,
  open,
  setDrawer,
  title
}) => {
  const onClose = () => setDrawer(false)

  const drawerActions = (
    <>
      <Button fullWidth onClick={onClose}>
        Update
      </Button>
    </>
  )

  return (
    <Drawer title={title} open={open} onClose={onClose} actions={drawerActions}>
      <div className={classes.drawerInner}>
        <Box className={classes.drawerInnerBox}>{actions}</Box>
        <Box className={classes.drawerInnerBox}>{filters}</Box>
      </div>
    </Drawer>
  )
}

ActionDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.node,
  actions: PropTypes.node,
  open: PropTypes.bool,
  setDrawer: PropTypes.func,
  title: PropTypes.string.isRequired
}

export default ActionDrawer
