import PropTypes from 'prop-types'

import * as enums from './enums'
import { LIST_PATHS } from './queryParams'

export const size = PropTypes.oneOf([...Object.values(enums.SIZE)])
export const buttonColor = PropTypes.oneOf([...Object.values(enums.COLOR)])

export const tooltipVariants = PropTypes.oneOf([
  ...Object.values(enums.TOOLTIP_VARIANT)
])

export const tooltipPlacements = PropTypes.oneOf([
  ...Object.values(enums.TOOLTIP_PLACEMENT)
])

export const notificationTypes = PropTypes.oneOf([
  ...Object.values(enums.NOTIFICATION_TYPE)
])

export const checkboxVariants = PropTypes.oneOf([
  ...Object.values(enums.CHECKBOX_VARIANT)
])

export const statusDotVariants = PropTypes.oneOf([
  ...Object.values(enums.STATUS_DOT_VARIANTS)
])

export const mapMarkerVariants = PropTypes.oneOf([
  ...Object.values(enums.MAP_MARKER_VARIANTS)
])

export const publishedStatusVariants = PropTypes.oneOf([
  ...Object.values(enums.PUBLISHED_STATUS_VARIANT)
])

export const iconButtonVariants = PropTypes.oneOf([
  ...Object.values(enums.ICON_BUTTON_VARIANT)
])

export const collectionLayoutVariant = PropTypes.oneOf([
  ...Object.values(enums.COLLECTION_LAYOUT_VARIANT)
])

export const dragNDropMode = PropTypes.oneOf([
  ...Object.values(enums.DRAG_N_DROP_MODE)
])

export const selectValue = props => {
  if (!('value' in props)) return

  if (props.multiple && !Array.isArray(props.value)) {
    return new Error(
      'Invalid prop `value` supplied to Select with multiple=true, expected an array.'
    )
  }

  const valueType = typeof props.value
  if (!props.multiple && valueType !== 'string' && valueType !== 'number') {
    return new Error(
      'Invalid prop `value` supplied to Select with multiple=false, expected a string or number.'
    )
  }
}

export const sortKeys = PropTypes.arrayOf(
  PropTypes.oneOf([...Object.values(LIST_PATHS)])
)

export const dataColumn = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.node
    ]).isRequired,
    sortKeys: PropTypes.arrayOf(PropTypes.string)
  })
)

export const numberPair = (props, propName) => {
  if (
    !Array.isArray(props[propName]) ||
    props[propName].length !== 2 ||
    !props[propName].every(Number.isInteger)
  ) {
    return new Error(`${propName} needs to be an array of two numbers`)
  }

  return null
}

export const tabPanes = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    pane: PropTypes.node
  })
)

export const overflowMenuOptions = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func
  })
)

export const origin = PropTypes.shape({
  horizontal: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['center', 'left', 'right'])
  ]),
  vertical: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['bottom', 'center', 'top'])
  ])
})

export const color = PropTypes.shape({
  id: PropTypes.number,
  label: PropTypes.string,
  hex: PropTypes.string,

  master_color_id: PropTypes.number,
  master_color: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    hex: PropTypes.string
  })
})

export const BreadCrumbs = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  })
)
