export const getPerPageDefault = () => {
  const { innerHeight: ih } = window

  // this assumes a row height of 50px[1] and that the datatable can take up 100vh
  // as most of our list endpoints are served via a datatable view.
  // per_page count can be equated to row items needed for the datatable entries to render
  // past the fold, which allows us to use the onScrollBottom hook to load next page.
  //
  // [1] 50px is the smallest measured row for a single line, and there are rows up to 100px as well.

  if (ih < 1200) {
    return 20
  } else if (ih > 1200 && ih < 2400) {
    return 40
  } else if (ih > 2400 && ih < 4800) {
    return 80
  } else {
    return 120
  }
}

// TODO: apparently Alex Warren said we should because it's unused?
const api = {}

export const create = async (resource, url, param) =>
  api.post(url, {
    [param]: resource
  })

export const update = async (resource, url, param) =>
  api.put(`${url}/${resource.id}`, {
    [param]: resource
  })

export const get = async (resourceId, url) => api.get(`${url}/${resourceId}`)

export const getAll = async (query, url) =>
  api.get(url, {
    params: {
      ...query
    }
  })

export const destroy = async (resourceId, url) =>
  api.delete(`${url}/${resourceId}`)
