import { useResourceList, useResource } from 'hooks'

const API_USERS = '/api/v1/admin/users'
const API_RESOURCE_NAME = 'user'

const useAdminUsers = () => {
  const {
    isLoading: isLoadingUsers,
    data: users,
    hasData: hasUsers,
    hasNext: hasUsersNext,
    list: listUsers,
    listNext: listUsersNext,
    error: usersError,
    count: usersCount,
    read: readUsers
  } = useResourceList({ url: API_USERS })

  const {
    data: user,
    read: readUser,
    create: createUser,
    update: updateUser,
    hasData: hasUser,
    isLoading: isLoadingUser,
    errors: userError
  } = useResource({
    url: API_USERS,
    name: API_RESOURCE_NAME
  })

  return {
    readUsers,
    isLoadingUsers,
    users,
    usersError,
    usersCount,
    hasUsers,
    hasUsersNext,
    listUsers,
    listUsersNext,
    user,
    hasUser,
    isLoadingUser,
    readUser,
    createUser,
    updateUser,
    userError
  }
}

export default useAdminUsers
