import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import MuiDialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Slide from '@material-ui/core/Slide'

import { COLOR, SIZE } from 'constants/enums'
import { useMediaQuery } from 'hooks'
import Button from 'components/Button'

import styles from './DialogStyles'

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

const Dialog = ({
  classes,
  fullWidth,
  open,
  onClose,
  title,
  subtitle,
  children,
  confirmText,
  onConfirm,
  onCancel,
  confirmDisabled,
  hideCancel,
  cancelText,
  additionalActions,
  dataTest,
  hideActions
}) => {
  const { isMobileScreen } = useMediaQuery()

  const handleConfirm = () => {
    onClose && onClose()
    onConfirm && onConfirm()
  }

  const header =
    title || subtitle ? (
      <Box className={classes.header}>
        {title && <Typography variant="h2">{title}</Typography>}
        {subtitle && (
          <Typography color="textSecondary" className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
      </Box>
    ) : null

  return (
    <MuiDialog
      fullScreen={isMobileScreen}
      fullWidth={fullWidth}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.paper
      }}
      TransitionComponent={isMobileScreen ? Transition : undefined}
    >
      {header}
      <Box className={classes.content} flexGrow={isMobileScreen ? 1 : 0}>
        {children}
      </Box>
      {!hideActions && (
        <Box className={classes.actionsContainer}>
          <Box pl={2}>{additionalActions}</Box>
          <Box className={classes.actions}>
            <Button
              onClick={handleConfirm}
              color={COLOR.primary}
              size={SIZE.medium}
              disabled={confirmDisabled}
              type="button"
              dataTest={dataTest && `${dataTest}-dialog-confirm`}
            >
              {confirmText}
            </Button>
            {!hideCancel && (
              <Button
                onClick={onCancel || onClose}
                color={COLOR.secondary}
                size={SIZE.medium}
                type="button"
                dataTest={dataTest && `${dataTest}-dialog-cancel`}
              >
                {cancelText}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </MuiDialog>
  )
}

Dialog.defaultProps = {
  confirmText: 'Apply',
  fullWidth: true,
  hideCancel: false,
  cancelText: 'Cancel'
}

Dialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  confirmDisabled: PropTypes.bool,
  hideCancel: PropTypes.bool,
  additionalActions: PropTypes.node,
  cancelText: PropTypes.string,
  dataTest: PropTypes.string,
  hideActions: PropTypes.bool
}

export default withStyles(styles)(Dialog)
