import React, { useState, useEffect, createContext, useContext } from 'react'
import PropTypes from 'prop-types'

import { makeObjectResolver } from 'constants/yupSchemas'
import { useForm, FormProvider } from 'react-hook-form'
import isEqual from 'lodash/isEqual'

import Box from '@material-ui/core/Box'

const ControlledFormContext = createContext({})

const useControlledForm = () => useContext(ControlledFormContext)

/* Creates a form that supported components will plug in to without
 * requiring any wiring up between MUI input fields and the form.
 * Usage:
 * const schemas = {
 *   brand: yup.string().required(),
 * }
 *
 * const defaults = {
 *   brand: '',
 * }
 * const MyComponent = () => (
 *   <ControlledForm
 *     handleSubmit={() => console.info('submit')}
 *     schemas={schemas}
 *     defaultValues={defaults}
 *   >
 *     <SelectField
 *       name='brand'
 *       items={[{ label: '', value: '' }, { label: 'FastAF', value: 'FastAF' }]}
 *       label="brand"
 *     />
 *     <Button type="submit">Submit</Button>
 *   </ControlledForm>
 * ) */
const ControlledForm = ({
  children,
  handleSubmit,
  schemas,
  defaultValues,
  resetOnSubmit = true,
  boxProps,
  loading = false,
  onError,
  shouldUnregister
}) => {
  const [blockSave, setBlockSave] = useState(false)
  const [initialized, setInitialized] = useState(!loading)
  const [defaults, setDefaults] = useState(defaultValues)
  const formContext = useForm({
    mode: 'onSubmit',
    resolver: makeObjectResolver(schemas),
    defaultValues,
    shouldUnregister
  })
  const { reset, setValue, watch } = formContext
  const allValues = watch()
  const formChanged = !Object.entries(allValues).every(([key, value]) =>
    isEqual(defaults?.[key], value)
  )

  useEffect(() => {
    if (!isEqual(defaults, defaultValues)) {
      reset(defaultValues)
      !initialized && setInitialized(true)
      setDefaults(defaultValues)
    }
  }, [defaultValues])

  const onSubmit = e => {
    e.preventDefault()
    const wrapHandleSubmitProp = (data, event) => {
      const changedNames = new Set(
        Object.entries(data)
          .map(([key, value]) =>
            !isEqual(defaultValues?.[key], value) ? key : null
          )
          .filter(v => v !== null)
      )

      handleSubmit(data, event, changedNames)
      resetOnSubmit && reset()
    }

    formContext.handleSubmit(wrapHandleSubmitProp, onError)(e)
  }

  const restoreDefaultsFor = names =>
    names.forEach(name => {
      setValue(name, defaults[name])
    })

  return (
    <ControlledFormContext.Provider
      value={{
        handleSubmit: onSubmit,
        loading: loading || !initialized,
        defaults,
        restoreDefaultsFor,
        readyToSave: !blockSave && formChanged,
        setBlockSave,
        formContext
      }}
    >
      <FormProvider {...formContext}>
        <Box onSubmit={onSubmit} component="form" {...boxProps}>
          {children}
        </Box>
      </FormProvider>
    </ControlledFormContext.Provider>
  )
}

ControlledForm.propTypes = {
  children: PropTypes.node,
  boxProps: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  schemas: PropTypes.object.isRequired,
  defaultValues: PropTypes.object,
  resetOnSubmit: PropTypes.bool,
  watchFunctions: PropTypes.objectOf(PropTypes.func),
  loading: PropTypes.bool,
  onError: PropTypes.func,
  shouldUnregister: PropTypes.bool
}

ControlledForm.defaultProps = {
  defaultValues: {},
  shouldUnregister: true
}

export { ControlledForm as default, useControlledForm }
