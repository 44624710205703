import { useState } from 'react'

import { api } from 'api'
import { useLoaders, useAlerts } from 'hooks'

/* useResource
  A base hook that allows for CRUD operations of a REST API that follows
  standard REST patterns of GET POST PUT and DELETE to read, update, create and
  destroy objects.

  @param url - The API endpoint. The is set dynamically using setEndpoint
  @param name - The name of the resource needed when using POST and PUT
*/

const useResource = ({ url = '/', name, showServerError }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError } = useAlerts()

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState(null)

  const handleErrors = e => {
    hideLoading()
    setErrors(e.data)
    showServerError && e.status === 422
      ? showAlertError(
          (e.data.errors?.length && e.data.errors) ||
            (e.data?.message ?? `${name} error`)
        )
      : showAlertError(e.data?.message ?? `${name} error`)
    console.error('useResource Error:', e, e?.data?.message)
  }

  const read = async (id, params) => {
    try {
      setIsLoading(true)
      const res = await api.get(id === undefined ? url : `${url}/${id}`, {
        params
      })
      setData(res.data)
      return res.data
    } catch (e) {
      handleErrors(e)
    } finally {
      setIsLoading(false)
    }
  }

  const create = async resource => {
    try {
      showLoading()
      const res = await api.post(`${url}`, {
        [name]: resource
      })
      setData(res.data)
      return res.data
    } catch (e) {
      handleErrors(e)
    } finally {
      hideLoading()
    }
  }

  const createMultipartFormData = async resource => {
    try {
      showLoading()
      const formData = new FormData()
      Object.entries(resource).forEach(([key, value]) =>
        formData.append(`${name}[${key}]`, value)
      )
      const res = await api.post(`${url}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      setData(res.data)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
  }

  const update = async (resource, params) => {
    try {
      showLoading()
      const { id, ...rest } = resource

      const res = await api.put(
        id === undefined ? url : `${url}/${id}`,
        {
          [name]: rest
        },
        { params }
      )
      setData(res.data)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
  }

  const updateMultipartFormData = async resource => {
    try {
      showLoading()
      const { id, ...rest } = resource
      const formData = new FormData()
      Object.entries(rest).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.map(arrValue => formData.append(`${name}[${key}][]`, arrValue))
        } else {
          formData.append(`${name}[${key}]`, value)
        }
      })
      const res = await api.put(`${url}/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      setData(res.data)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
  }

  const bulkUpdate = async resource => {
    try {
      showLoading()
      const { id, ...rest } = resource
      const res = await api.put(`${url}/bulk_update`, rest)
      setData(res.data)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
  }

  // Note: delete is a JS keyword
  const destroy = async resource => {
    try {
      showLoading()
      await api.delete(`${url}/${resource.id}`)
      setData(null)
      hideLoading()
    } catch (e) {
      handleErrors(e)
    }
  }

  return {
    data,
    isLoading,
    hasData: data !== null,
    errors,
    read,
    update,
    bulkUpdate,
    create,
    createMultipartFormData,
    updateMultipartFormData,
    destroy,
    setData
  }
}

export default useResource
