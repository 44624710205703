import { useMutation, useQueryClient } from 'react-query'

import { api } from 'api'
import {
  collectionEndpoint,
  collectionBulkUpdateEndpoint,
  collectionCloneEndpoint
} from 'api/endpoints'

import { useResourceRQ } from 'hooks'
import {
  multiFieldFileInputProcessor,
  multiPartFormParams,
  OPERATION_TYPE
} from 'hooks/common/useResourceRQ'
import { STALE_TIME_MEDIUM } from 'constants/reactQuery'

const voidFunc = () => undefined
const COLLECTION = 'collection'

const useAdminCollections = (
  collectionId,
  { onSuccess = voidFunc, onError = voidFunc } = {}
) => {
  const queryClient = useQueryClient()

  const {
    data: collection,
    update: updateCollection,
    create: createCollection
  } = useResourceRQ({
    endpoint: collectionEndpoint,
    args: [collectionId],
    name: COLLECTION,
    dependents: [() => [collectionEndpoint()]],
    fetchingEnabled: Number.isInteger(collectionId),
    disableOptimisticUpdate: true,
    inputProcessor: multiFieldFileInputProcessor(COLLECTION),
    mutateParams: multiPartFormParams,
    options: { staleTime: STALE_TIME_MEDIUM },
    onSuccess: {
      [OPERATION_TYPE.create]: data => `Created new collection, ${data?.title}`,
      [OPERATION_TYPE.update]: data => `Updated collection, ${data?.title}`
    },
    onError: err =>
      onError(`Collections error, ${err?.data?.message ?? err?.statusText}`)
  })

  const { mutateAsync: updatePublishStatuses } = useMutation(
    ({ collection_ids, location_ids, published }) =>
      api.put(collectionBulkUpdateEndpoint(), {
        collection_ids,
        location_ids,
        published
      }),
    {
      onSuccess: (_, { collection_ids }) => {
        queryClient.resetQueries(collectionEndpoint())
        onSuccess(`Updated ${collection_ids.join(', ')}.`)
      },
      onError: err =>
        onError(`Error updating, ${err?.data?.message ?? err?.statusText}`)
    }
  )

  const {
    mutateAsync: cloneCollection,
    isLoading: cloneCollectionIsLoading
  } = useMutation(
    () => {
      return api.post(collectionCloneEndpoint({ collectionId }))
    },
    {
      onSuccess: ({ data }) => {
        queryClient.resetQueries(
          collectionEndpoint({ collection_id: collectionId })
        )
        onSuccess(`Cloned ${collectionId} to new collection ${data.id}.`)
      },
      onError: err =>
        onError(`Error cloning, ${err?.data?.message ?? err?.statusText}`)
    }
  )

  const updateCollectionOther = async (id, params) => {
    const queryKey = [collectionEndpoint(id), params]
    try {
      const resp = await queryClient.fetchQuery(queryKey, val =>
        api.put(val.queryKey[0], { [COLLECTION]: val.queryKey[1] })
      )
      queryClient.setQueryData([collectionEndpoint(resp.data.id)], resp.data)
    } catch (err) {
      onError(`Error updating, ${err?.data?.message ?? err?.statusText}`)
    }
  }

  const updateChildren = async (parentId, newChildrenIds, ogChildrenIds) => {
    const newChildrenIdsSet = new Set(newChildrenIds)
    const childrenIdsSet = new Set(ogChildrenIds)
    const toAdd = newChildrenIds.filter(cid => !childrenIdsSet.has(cid))
    const toRemove = ogChildrenIds.filter(cid => !newChildrenIdsSet.has(cid))

    const additions = toAdd.map(id =>
      updateCollectionOther(id, { parent_collection_id: parentId })
    )
    const removals = toRemove.map(id =>
      updateCollectionOther(id, { parent_collection_id: null })
    )
    return Promise.all([...additions, ...removals])
  }

  return {
    createCollection,
    collection,
    updateCollection,
    updateChildren,
    updatePublishStatuses,
    cloneCollection,
    cloneCollectionIsLoading
  }
}

export default useAdminCollections
