import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import uniqBy from 'lodash/uniqBy'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminProducts } from 'hooks'
import AutocompleteSearch from 'components/AutocompleteSearch'

import styles from './AutocompleteSearchSubCategoriesStyles'

const AutocompleteSearchSubCategoriesRaw = ({
  name,
  classes,
  onChange,
  value,
  defaultOpen,
  multiple = false,
  parentCategoryId,
  clearOnParentCategoryIdClear
}) => {
  const {
    isLoadingSubCategories,
    subCategories,
    listSubCategories,
    listSubCategoriesNext
  } = useAdminProducts({})
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (!parentCategoryId && clearOnParentCategoryIdClear) {
      onChange({})
      setQuery('')
      return
    }
    const params = parentCategoryId
      ? { search: query, category_id: parentCategoryId }
      : { category_id: parentCategoryId }

    if (query.length) {
      listSubCategories(params)
    } else {
      listSubCategories(params)
    }
  }, [query, parentCategoryId])

  const options = useMemo(
    () =>
      uniqBy((subCategories ?? []).concat(value), v => v?.id).filter(
        category => category?.active
      ),
    [subCategories]
  )

  return (
    <Box mt={1}>
      <AutocompleteSearch
        name={name}
        defaultOpen={defaultOpen}
        value={value}
        loading={isLoadingSubCategories}
        setQuery={setQuery}
        onChange={onChange}
        onScrollBottom={listSubCategoriesNext}
        options={options}
        placeholder="Subcategory"
        multiple={multiple}
        getOptionDisabled={option => !option?.active}
        getOptionLabel={opt => opt?.name ?? ''}
        renderOption={(option, { selected }) => (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="100%"
            className={selected ? classes.selectedItem : null}
          >
            <Typography variant="body1" className={classes.name}>
              {option?.name ?? ''}
            </Typography>
          </Box>
        )}
      />
    </Box>
  )
}

AutocompleteSearchSubCategoriesRaw.defaultProps = {
  value: {},
  defaultOpen: false,
  multiple: false,
  clearOnParentCategoryIdClear: false
}

AutocompleteSearchSubCategoriesRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  defaultOpen: PropTypes.bool,
  multiple: PropTypes.bool,
  parentCategoryId: PropTypes.number,
  clearOnParentCategoryIdClear: PropTypes.bool
}

const AutocompleteSearchSubCategories = withStyles(styles)(
  AutocompleteSearchSubCategoriesRaw
)

const AutocompleteSearchSubCategoriesControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchSubCategories
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchSubCategoriesControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchSubCategories as default,
  AutocompleteSearchSubCategoriesControlled
}
