import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import { withStyles } from '@material-ui/core/styles'

import * as propTypes from 'constants/propTypes'
import { SIZE } from 'constants/enums'

import styles from './UserAvatarStyles'

const UserAvatar = ({ classes, className, src, size, style }) => (
  <Avatar
    className={classNames({
      [classes[size]]: true,
      [className]: Boolean(className)
    })}
    src={src && src}
    style={style}
  />
)

UserAvatar.defaultProps = {
  size: SIZE.medium
}

UserAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  src: PropTypes.string,
  size: propTypes.size,
  style: PropTypes.object
}

export default withStyles(styles)(UserAvatar)
