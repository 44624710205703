import React from 'react'
import PropTypes from 'prop-types'

const SubmitIcon = ({ fontSize, className }) => (
  <svg
    width={fontSize}
    height={fontSize}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.999 2L10.999 13"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.999 2L14.999 22L10.999 13L1.99902 9L21.999 2Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

SubmitIcon.defaultProps = {
  fontSize: 20
}

SubmitIcon.propTypes = {
  fontSize: PropTypes.number,
  className: PropTypes.string
}

export default SubmitIcon
