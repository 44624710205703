import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { LIST_PATHS } from 'constants/queryParams'

import InventoryOrders from 'components/InventoryOrders'
import Select from 'components/Select'
import AutocompleteSearchSuppliers from 'components/AutocompleteSearchSuppliers'
import { RECORD_TYPE } from 'constants/inventoryRecords'

import {
  TERM_POSSIBILITIES_ALL,
  PURPOSE_POSSIBILITIES_ALL,
  PO_LIST_COLUMNS,
  PURCHASE_ORDER_STATE_LABELS,
  PURCHASE_ORDER_STATE_TYPES
} from 'constants/purchaseOrders'

import styles from './PurchaseOrdersStyles'

const PurchaseOrders = ({ classes }) => {
  const [filteredSupplier, setFilteredBrand] = useState({})
  const handleSupplierQueryChange = (supplier, query, updateQuery) => {
    setFilteredBrand(supplier)
    updateQuery({
      [LIST_PATHS.PURCHASE_ORDERS_BRAND_FILTER]: supplier?.id ?? ''
    })
  }

  return (
    <InventoryOrders
      shortName="PO"
      title="Purchase Orders"
      type={RECORD_TYPE.PURCHASE_ORDER}
      columns={PO_LIST_COLUMNS}
      statusLabels={PURCHASE_ORDER_STATE_LABELS}
      glanceTiles={[
        {
          key: PURCHASE_ORDER_STATE_TYPES.open,
          label: (
            <>
              Open <br /> PO's
            </>
          )
        },
        { key: PURCHASE_ORDER_STATE_TYPES.submitted, label: "Inbound PO's" },
        {
          key: PURCHASE_ORDER_STATE_TYPES.partiallyReceived,
          label: 'Partially Received'
        }
      ]}
      filters={({ query, handleQueryChange, updateQuery }) => (
        <>
          <AutocompleteSearchSuppliers
            name={LIST_PATHS.PURCHASE_ORDERS_BRAND_FILTER}
            className={classes.brandSearch}
            onChange={supplier =>
              handleSupplierQueryChange(supplier, query, updateQuery)
            }
            value={filteredSupplier}
            withStartAdornment={false}
          />
          <Select
            minimal
            name={LIST_PATHS.PURCHASE_ORDERS_PURPOSE_FILTER}
            innerLabel="Purpose"
            value={query[LIST_PATHS.PURCHASE_ORDERS_PURPOSE_FILTER] || ''}
            items={PURPOSE_POSSIBILITIES_ALL}
            onChange={handleQueryChange}
          />
          <Select
            minimal
            name={LIST_PATHS.PURCHASE_ORDERS_TERM_FILTER}
            innerLabel="Term"
            value={query[LIST_PATHS.PURCHASE_ORDERS_TERM_FILTER] || ''}
            items={TERM_POSSIBILITIES_ALL}
            onChange={handleQueryChange}
          />
        </>
      )}
    />
  )
}

PurchaseOrders.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PurchaseOrders)
