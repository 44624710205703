import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { useControlledFormController, useAlerts } from 'hooks'
import {
  COLLECTION_CAN_BE_PARENT,
  COLLECTION_DESCRIPTION_MAX_CHARACTERS,
  COLLECTION_DISPLAY_TYPES,
  COLLECTION_TYPE_BY_VALUE,
  COLLECTION_TYPES,
  COLLECTIONS_DISPLAY_TYPE_OPTIONS,
  COLLECTIONS_TYPE_OPTIONS,
  DROPZONE_EDITORIAL_MEDIA_TYPES,
  DROPZONE_IMAGE_TYPES
} from 'constants/collections'
import { useLocationsContext } from 'context'

import { CheckboxField } from 'components/Checkbox'
import { MarkdownEditorControlled } from 'components/MarkdownEditor'
import { SelectField } from 'components/Select'
import { TextFieldControlled } from 'components/TextField'
import { useControlledForm } from 'components/ControlledForm'
import AutocompleteSearchCollections from 'components/AutocompleteSearchCollections'
import Block from 'components/Block'
import Button from 'components/Button'
import ImageDropzone from 'components/ImageDropzone'
import MediaDialog from 'components/MediaDialog'
import ProgressBar from 'components/ProgressBar'
import Tabs from 'components/Tabs'
import VideoPlayer from 'components/VideoPlayer'

import CollectionAvailability from './CollectionAvailability'
import CollectionProducts from './CollectionProducts'
import CollectionChildren from './CollectionChildren'
import { FORM_FIELDS, VIDEO_TYPE } from './CollectionFormFields'

import styles from './CollectionDetailsViewStyles'

const NO_BRAND_COLLECTIONS_TYPE_OPTIONS = COLLECTIONS_TYPE_OPTIONS.filter(
  t => t.value !== COLLECTION_TYPES.brand
)

const CollectionDetailsView = ({
  classes,
  collection,
  loadingLocationCollection
}) => {
  const { showAlertGeneral } = useAlerts()
  const { showAllLocationIds } = useLocationsContext()
  const { restoreDefaultsFor, defaults: formDefaults } = useControlledForm()
  const { watch } = useFormContext()
  const [openMediaDialog, setOpenMediaDialog] = useState(false)
  const [openEditorialMediaDialog, setOpenEditorialMediaDialog] = useState(
    false
  )
  const [displayName, setDisplayName] = useState(
    COLLECTION_DISPLAY_TYPES.defaultSlider
  )

  useEffect(() => {
    collection?.editorial_media_url &&
      setDisplayName(COLLECTION_DISPLAY_TYPES.editorial)
  }, [])

  const handleCloseMediaDialog = () => {
    setOpenMediaDialog(false)
    setOpenEditorialMediaDialog(false)
  }

  const handleDisplayChange = (e, value) => {
    setDisplayName(value)
  }

  const onChangeCollectionType = (type, { setValue }) => {
    if (type !== COLLECTION_TYPES.category) {
      setValue(FORM_FIELDS.PARENT_COLLECTION, null)
      setValue(FORM_FIELDS.PARENT_COLLECTION_ID, null)
      setValue(FORM_FIELDS.CHILDREN, [])
      setValue(FORM_FIELDS.CHILDREN_IDS, [])
    } else {
      restoreDefaultsFor([
        FORM_FIELDS.PARENT_COLLECTION,
        FORM_FIELDS.PARENT_COLLECTION_ID,
        FORM_FIELDS.CHILDREN,
        FORM_FIELDS.CHILDREN_IDS
      ])
    }
  }

  const collectionType = watch(FORM_FIELDS.COLLECTION_TYPE)
  const parentCollection = watch(FORM_FIELDS.PARENT_COLLECTION)
  const children = watch(FORM_FIELDS.CHILDREN)
  const imageUrl = watch(FORM_FIELDS.IMAGE_URL)
  const editorialMedia = watch(FORM_FIELDS.EDITORIAL_MEDIA)
  const editorialMediaURL = watch(FORM_FIELDS.EDITORIAL_MEDIA_URL)
  const isParent = collection.is_parent || (children && children.length > 0)
  const canParent =
    !parentCollection && collectionType === COLLECTION_TYPES.category

  if (!isParent && showAllLocationIds) {
    showAlertGeneral('Select a Location to show Products.')
  }

  const {
    field: { onChange: setParentCollectionId }
  } = useControlledFormController({
    name: FORM_FIELDS.PARENT_COLLECTION_ID
  })

  const {
    field: { onChange: clearImageUrl }
  } = useControlledFormController({
    name: FORM_FIELDS.IMAGE
  })

  const {
    field: { onChange: clearMediaUrl }
  } = useControlledFormController({
    name: FORM_FIELDS.EDITORIAL_MEDIA
  })

  const { field: parentCollectionFieldProps } = useControlledFormController({
    name: FORM_FIELDS.PARENT_COLLECTION,
    onChange: c => setParentCollectionId(c?.id ?? null)
  })

  const isServerVideo = Boolean(
    collection?.editorial_media_resource_type === VIDEO_TYPE &&
      editorialMediaURL &&
      !editorialMediaURL?.includes('blob')
  )

  const isFileVideo = editorialMedia?.type === 'video/mp4'

  const leftLayoutItems = [
    {
      title: 'Name',
      withPadding: false,
      show: true,
      items: (
        <>
          <Box mx={3}>
            <TextFieldControlled
              name={FORM_FIELDS.TITLE}
              placeholder="Name"
              fullWidth
              className={classes.smallInput}
              dataTest="collection-detail-title"
            />
          </Box>
          {collectionType === COLLECTION_TYPES.editorial && (
            <Box mx={3}>
              <Typography variant="h5">Subheader</Typography>
              <TextFieldControlled
                name={FORM_FIELDS.SUBHEADER}
                placeholder="Subheader"
                fullWidth
                className={classes.smallInput}
              />
            </Box>
          )}
        </>
      )
    },
    {
      title: 'Collection Cover',
      show: true,
      items: (
        <>
          <Box maxWidth="299px" height="199px" m={2}>
            <ImageDropzone
              name={FORM_FIELDS.IMAGE}
              urlName={FORM_FIELDS.IMAGE_URL}
              accept={DROPZONE_IMAGE_TYPES}
              imageCover
              onClear={clearImageUrl}
            />
          </Box>
          <Divider className={classes.divider} />
          <Box display="flex" pb={1}>
            <Button
              size="small"
              className={classes.editButton}
              onClick={() => setOpenMediaDialog(true)}
            >
              Add Files
            </Button>
          </Box>
          <MediaDialog
            open={openMediaDialog}
            onClose={handleCloseMediaDialog}
            name={FORM_FIELDS.IMAGE}
            urlName={FORM_FIELDS.IMAGE_URL}
            accept={DROPZONE_IMAGE_TYPES}
            defaultUrl={formDefaults?.[FORM_FIELDS.IMAGE_URL]}
            onCancel={() => {
              imageUrl &&
                restoreDefaultsFor([FORM_FIELDS.IMAGE, FORM_FIELDS.IMAGE_URL])
              handleCloseMediaDialog()
            }}
            subtitle="Collection covers can be .jpg or .png images files only"
          />
        </>
      )
    },
    {
      title: 'Editorial Cover',
      show:
        collectionType === COLLECTION_TYPES.editorial &&
        displayName !== COLLECTION_DISPLAY_TYPES.defaultSlider,
      items: (
        <>
          <Box
            maxWidth="299px"
            height="199px"
            m={2}
            style={{
              display: (isFileVideo || isServerVideo) && 'none'
            }}
          >
            <ImageDropzone
              name={FORM_FIELDS.EDITORIAL_MEDIA}
              urlName={FORM_FIELDS.EDITORIAL_MEDIA_URL}
              accept={DROPZONE_EDITORIAL_MEDIA_TYPES}
              placeholder="Click to upload or drag and drop a video or a cover image here."
              imageCover
              onClear={clearMediaUrl}
            />
          </Box>
          {(isFileVideo || isServerVideo) && (
            <Box maxWidth="299px" m={2}>
              <VideoPlayer
                url={
                  isFileVideo
                    ? URL.createObjectURL(editorialMedia)
                    : formDefaults?.[FORM_FIELDS.EDITORIAL_MEDIA_URL]
                }
              />
            </Box>
          )}

          <Divider className={classes.divider} />
          <Box display="flex" pb={1}>
            <Button
              size="small"
              className={classes.editButton}
              onClick={() => setOpenEditorialMediaDialog(true)}
            >
              Add Files
            </Button>
          </Box>
          <MediaDialog
            open={openEditorialMediaDialog}
            onClose={handleCloseMediaDialog}
            name={FORM_FIELDS.EDITORIAL_MEDIA}
            urlName={FORM_FIELDS.EDITORIAL_MEDIA_URL}
            accept={DROPZONE_EDITORIAL_MEDIA_TYPES}
            defaultUrl={formDefaults?.[FORM_FIELDS.EDITORIAL_MEDIA_URL]}
            onCancel={() => {
              editorialMediaURL &&
                restoreDefaultsFor([
                  FORM_FIELDS.EDITORIAL_MEDIA,
                  FORM_FIELDS.EDITORIAL_MEDIA_URL
                ])
              handleCloseMediaDialog()
            }}
            onClear={clearMediaUrl}
            subtitle="Editorial covers can be an image or video file. Images can be .jpg or .png, while videos can be .mp4."
            video={
              isFileVideo
                ? editorialMedia
                : formDefaults?.[FORM_FIELDS.EDITORIAL_MEDIA_URL]
            }
            isVideo={isFileVideo || isServerVideo}
          />
        </>
      )
    },
    {
      title: 'Collection Description',
      last: true,
      show: true,
      items: (
        <MarkdownEditorControlled
          name={FORM_FIELDS.DESCRIPTION}
          placeholder="Collection description."
          height={300}
          characterLimit={COLLECTION_DESCRIPTION_MAX_CHARACTERS}
        />
      )
    }
  ].filter(item => item.show)

  const rightLayoutItems = [
    {
      title: 'Availability',
      items: (
        <Box mt={2}>
          <CollectionAvailability classes={classes} collection={collection} />
          <Box className={classes.checkBoxItem} mt={2}>
            <Box>
              <Typography variant="body1">See All</Typography>
              <Typography variant="body1" color="textSecondary">
                Show the &apos;See All&apos; link?
              </Typography>
            </Box>
            <CheckboxField name={FORM_FIELDS.HAS_SEE_ALL_LINK} />
          </Box>
        </Box>
      ),
      divider: true
    },
    {
      title: 'Display',
      divider: false,
      show: collectionType !== COLLECTION_TYPES.editorial,
      items: (
        <>
          <Typography
            variant="body1"
            className={classes.displayDescLabel}
            color="textSecondary"
          >
            Choose how this collection should display for users.
          </Typography>
          <Box mt={2} className={classes.displayBox}>
            <RadioGroup
              aria-label="display"
              name="display"
              value={COLLECTION_DISPLAY_TYPES.defaultSlider}
              onChange={handleDisplayChange}
            >
              {COLLECTIONS_DISPLAY_TYPE_OPTIONS.map(data => (
                <FormControlLabel
                  key={data.label}
                  value={data.value}
                  control={<Radio color="primary" />}
                  checked={displayName === data.value}
                  label={data.label}
                />
              ))}
            </RadioGroup>
          </Box>
        </>
      )
    },
    {
      title: 'Organization',
      divider: false,
      last: true,
      items: (
        <Box mt={2}>
          {collectionType === COLLECTION_TYPES.brand ? (
            <>
              <Typography variant="caption" color="textSecondary">
                Collection Type
              </Typography>
              <Typography variant="body2" fontWeight="fontWeightRegular">
                Brand
              </Typography>
              <Box mt={4} />
              <Typography variant="caption" color="textSecondary">
                Vendor
              </Typography>
              <Typography variant="body2" fontWeight="fontWeightRegular">
                {collection?.title}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="caption" color="textSecondary">
                Collection Type
              </Typography>
              <SelectField
                name={FORM_FIELDS.COLLECTION_TYPE}
                fullWidth
                margin="none"
                items={NO_BRAND_COLLECTIONS_TYPE_OPTIONS}
                onChange={onChangeCollectionType}
                dataTest="collection-brand-type"
              />
              <Box mt={4} />
            </>
          )}
          <Box mt={4} />
          <Typography variant="caption" color="textSecondary">
            Parent Collection
          </Typography>
          {collectionType === COLLECTION_TYPES.category && !isParent ? (
            <AutocompleteSearchCollections
              name="select-parent-collection"
              placeholder="Select collection"
              value={parentCollectionFieldProps.value}
              onChange={parentCollectionFieldProps.onChange}
              queryProps={COLLECTION_CAN_BE_PARENT}
            />
          ) : (
            <Box>
              <Typography variant="body2">
                {isParent
                  ? 'Unavailable for parent collections'
                  : `Unavailable for ${COLLECTION_TYPE_BY_VALUE[collectionType]}`}
              </Typography>
            </Box>
          )}
        </Box>
      )
    }
  ]

  const gridLayoutItems = [
    <>
      {leftLayoutItems.map(({ title, items, withPadding, last }, i) => (
        <Block title={title} withPadding={withPadding} key={i} last={last}>
          {items}
        </Block>
      ))}
    </>,
    <Block withPadding last>
      {rightLayoutItems.map(
        ({ title, items, divider, show }, i) =>
          !show && (
            <React.Fragment key={i}>
              <Box my={2}>
                <Typography variant="h5">{title}</Typography>
                {items}
              </Box>
              {divider && <Divider className={classes.divider} />}
            </React.Fragment>
          )
      )}
    </Block>
  ]

  const collectionProducts = (
    <CollectionProducts
      disableAdd={collectionType === COLLECTION_TYPES.brand || isParent}
      disableRemove={collectionType === COLLECTION_TYPES.brand}
      collection={collection}
    />
  )
  const collectionChildren = <CollectionChildren collection={collection} />
  const collectionOrdering =
    canParent || isParent ? (
      <Tabs
        defaultTabIndex={1}
        panes={[
          { label: 'Sort Categories', pane: collectionChildren },
          { label: 'Sort Carousel', pane: collectionProducts }
        ]}
      />
    ) : (
      <>
        <Typography variant="body2">Products in Collection</Typography>
        {collectionProducts}
      </>
    )

  return (
    <>
      {collection?.updated && (
        <Typography className={classes.updatedText} variant="body2">
          Updated {moment(collection?.updated).toNow()}
        </Typography>
      )}
      <Grid spacing={3} container>
        {gridLayoutItems.map((content, i) => (
          <Grid item xs={12} md={6} key={i}>
            {content}
          </Grid>
        ))}
      </Grid>
      <Box mt={3} />
      {loadingLocationCollection ? (
        <Box my={6}>
          <ProgressBar />
        </Box>
      ) : (
        !showAllLocationIds && collectionOrdering
      )}
    </>
  )
}

CollectionDetailsView.defaultProps = {}

CollectionDetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
  collection: PropTypes.object,
  loadingLocationCollection: PropTypes.bool
}

export default withStyles(styles)(CollectionDetailsView)
