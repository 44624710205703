import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useLoaders } from 'hooks'

import Tag from 'components/Tag'
import Dialog from 'components/Dialog'
import Button from 'components/Button'
import AutocompleteSearchProductTags from 'components/AutocompleteSearchProductTags'

import styles from './EditTagsStyles'

const EditTags = ({
  bulkApplyTags,
  open,
  onClose,
  selectedProducts,
  classes,
  onUpdate
}) => {
  const { showLoading, hideLoading } = useLoaders()

  const [existingTags, setExistingTags] = useState([])
  const [addedTags, setAddedTags] = useState([])
  const [inputTags, setInputTags] = useState([])
  const [clearAll, setClearAll] = useState(false)

  useEffect(() => {
    setExistingTags(
      Object.values(selectedProducts)
        .map(value => value.tags)
        .flat()
    )
    setAddedTags([])
  }, [open])

  const onDeleteTag = tagToDelete => {
    setExistingTags(existingTags.filter(tag => tag.id !== tagToDelete.id))
    setAddedTags(addedTags.filter(tag => tag.id !== tagToDelete.id))
  }

  const allTags = useMemo(
    () => uniqBy(addedTags.concat(existingTags), tag => tag?.id),
    [addedTags, existingTags]
  )

  const handleAddTags = () => {
    if (!inputTags) return
    setAddedTags(
      addedTags.concat(
        inputTags.filter(tag => !allTags.some(someTag => tag.id === someTag.id))
      )
    )
    setClearAll(true)
  }

  const handleUpdate = async () => {
    showLoading()
    const updated = await bulkApplyTags({
      productIds: Object.values(selectedProducts).map(value => value.id),
      tagsIds: allTags.map(tag => tag.id)
    })
    onUpdate &&
      onUpdate(updated, () => {
        hideLoading()
      })
  }

  if (!open) return null

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onConfirm={handleUpdate}
      confirmText="Update"
    >
      <Box mt={1}>
        <Typography variant="h5">
          Add tags to {Object.keys(selectedProducts).length} product(s)
        </Typography>
      </Box>
      <Box pt={1}>
        <Typography className={classes.description}>
          Use descriptive keywords to help organize products.
        </Typography>
      </Box>
      <Box display="flex" gridGap="10px" alignItems="center" py={3}>
        <AutocompleteSearchProductTags
          onChange={setInputTags}
          clearAll={clearAll}
        />
        <Button
          className={classes.addButton}
          size="medium"
          onClick={handleAddTags}
          disabled={isEmpty(inputTags)}
        >
          Add
        </Button>
      </Box>
      <Box pt={1}>
        <Typography variant="h5">Existing Tags</Typography>
        <Box mt={1}>
          {allTags.map(tag => (
            <Tag
              key={tag.id}
              label={tag.name}
              isNew={addedTags.some(addedTag => addedTag.id === tag.id)}
              onDelete={() => onDeleteTag(tag)}
            />
          ))}
        </Box>
      </Box>
    </Dialog>
  )
}

EditTags.propTypes = {
  bulkApplyTags: PropTypes.func.isRequired,
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  selectedProducts: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func
}

export default withStyles(styles)(EditTags)
