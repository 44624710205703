import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { useAdminDistributionCenterList } from 'hooks'

import DataTable from 'components/DataTable'

import DistributionCenterItem from './DistributionCenterItem'

import styles from './DistributionCentersListStyles'

const DistributionCentersList = ({ supplierId }) => {
  const {
    distributionCenters,
    hasDistributionCentersNext,
    isLoadingDistributionCenters,
    listDistributionCentersNext
  } = useAdminDistributionCenterList({ supplierId })

  return (
    <DataTable
      columns={[{ title: 'Service Location' }, { title: '' }]}
      message={
        distributionCenters?.length === 0 && !isLoadingDistributionCenters
          ? 'No results found.'
          : null
      }
      isLoadingList={isLoadingDistributionCenters}
      hasListNext={hasDistributionCentersNext}
      listNext={listDistributionCentersNext}
    >
      {distributionCenters?.map(distributionCenter => (
        <DistributionCenterItem
          supplierId={supplierId}
          distributionCenter={distributionCenter}
          key={distributionCenter.id}
        />
      ))}
    </DataTable>
  )
}

DistributionCentersList.defaultProps = {}

DistributionCentersList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DistributionCentersList)
