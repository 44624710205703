import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

import { useMediaQuery } from 'hooks'
import { formatCurrency } from 'utils/general'
import { DELIVERY_METHOD_KEYS } from 'constants/enums'

import styles from './PaymentDetailsStyles'

const PriceTag = ({
  classes,
  label,
  sublabel,
  isNegative,
  isBold,
  value,
  link,
  className
}) => (
  <ListItem
    key={label}
    className={classNames({
      [classes.item]: true,
      [className]: className
    })}
  >
    <Box flex={[0.6, 0.3]}>
      <Typography
        className={classNames({
          [classes.bold]: isBold
        })}
        variant="body1"
      >
        {label}
      </Typography>
    </Box>
    <Box flex={1}>
      {sublabel && <Typography variant="body1">{sublabel}</Typography>}
    </Box>
    {link || (
      <Typography
        className={classNames({
          [classes.priceValue]: true,
          [classes.negativeTag]: isNegative
        })}
        variant="body1"
      >
        {formatCurrency(value)}
      </Typography>
    )}
  </ListItem>
)

PriceTag.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  isNegative: PropTypes.bool,
  isBold: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sublabel: PropTypes.string,
  link: PropTypes.object,
  className: PropTypes.string
}

const fastAFOnlyAfter = new Date(2021, 9, 1) // Oct 1st

const PaymentDetails = ({ classes, order }) => {
  const { isMobileScreen } = useMediaQuery()
  const {
    tip,
    total_price: price,
    crypto_discount_cents,
    doordash_tip: doordashTip,
    credit_applied: storeCredit,
    credit_refunded: storeCreditRefunded,
    total_line_items_price: subtotal,
    delivery_method: deliveryMethod,
    discount_code: discountCode,
    code_discounts: codeDiscounts,
    total_discounts: discountValue,
    total_line_items_quantity: itemsQuantity,
    delivery_fee: shippingCost,
    total_tax: totalTax,
    stripe_id: stripeId,
    stripe_amount_refunded: refundAmount,
    created_at: orderDate,
    driver_benefits: driverBenefits
  } = order

  const hideLongDistanceTip =
    deliveryMethod === DELIVERY_METHOD_KEYS.onfleet &&
    new Date(orderDate) > fastAFOnlyAfter

  return (
    <Box className={classes.root}>
      <Box className={classes.priceTagList}>
        <PriceTag
          classes={classes}
          label="Subtotal"
          sublabel={`${itemsQuantity} item${itemsQuantity > 1 ? 's' : ''}`}
          value={subtotal}
        />

        <PriceTag classes={classes} label="Tax" value={totalTax || 0} />

        <PriceTag
          classes={classes}
          label="Delivery Fee"
          sublabel={startCase(deliveryMethod)}
          value={shippingCost}
        />

        {parseFloat(driverBenefits, 10) > 0 && (
          <PriceTag
            classes={classes}
            label="Driver Benefits & Fuel Support Fee"
            value={driverBenefits}
          />
        )}

        {Boolean(discountCode) && (
          <PriceTag
            classes={classes}
            label="Promo"
            sublabel={discountCode}
            value={codeDiscounts}
            isNegative
          />
        )}

        <PriceTag classes={classes} label="Tip" value={tip || 0} />

        {Boolean(Number(storeCredit)) && (
          <PriceTag
            classes={classes}
            label="Store Credit"
            value={storeCredit}
            isNegative
          />
        )}

        {Boolean(Number(crypto_discount_cents)) && (
          <PriceTag
            classes={classes}
            label="Crypto Discount"
            value={crypto_discount_cents}
            isNegative
          />
        )}

        {Boolean(Number(storeCreditRefunded)) && (
          <PriceTag
            classes={classes}
            label="Store Credit Refunded"
            value={storeCreditRefunded}
          />
        )}

        <PriceTag
          classes={classes}
          label={`Total${
            order.crypto_used_as_payment ? ' (in USD-Paid with crypto)' : ''
          }`}
          value={price}
        />
      </Box>

      {!isEmpty(order.refunds) &&
        order.refunds.map(refund => (
          <Box key={refund.id}>
            <Typography
              data-test="Payment"
              variant="h5"
              className={classes.paddingBlock}
            >
              Refund
            </Typography>
            <PriceTag
              classes={classes}
              label="Refund"
              value={
                parseFloat(refund.credit_applied_refund_amount) +
                parseFloat(refund.payment_method_refund_amount)
              }
            />
            <PriceTag
              classes={classes}
              label="Store Credit Refund"
              value={refund.credit_applied_refund_amount}
            />
            <PriceTag
              classes={classes}
              label="Payment Method Refund"
              value={refund.payment_method_refund_amount}
            />
          </Box>
        ))}

      {!hideLongDistanceTip && Boolean(Number(doordashTip)) && (
        <>
          <Typography
            data-test="Payment"
            variant="h5"
            className={classes.paddingBlock}
          >
            FASTAF Payout
          </Typography>
          <Box>
            <PriceTag
              classes={classes}
              label="Long-distance Tip"
              value={doordashTip}
            />
          </Box>
        </>
      )}

      {Boolean(Number(refundAmount)) && (
        <Box className={classes.dividerSection}>
          <PriceTag
            className={classes.bold}
            classes={classes}
            label="Refunded"
            value={refundAmount}
            isBold
            isNegative
          />
          <PriceTag
            className={classes.bold}
            classes={classes}
            label="Net Payment"
            isBold
            value={price - refundAmount}
          />
        </Box>
      )}

      {stripeId && (
        <Box className={classes.stripeContainer}>
          <Typography
            data-test="Payment"
            variant="h5"
            className={classes.paddingBlock}
          >
            Stripe Details
          </Typography>
          <PriceTag
            classes={classes}
            className={classNames({
              [classes.paymentId]: isMobileScreen
            })}
            label="Stripe Payment ID"
            link={
              <Link
                color="primary"
                href={`https://dashboard.stripe.com/payments/${stripeId}`}
                target="_blank"
              >
                {stripeId}
              </Link>
            }
          />
        </Box>
      )}
    </Box>
  )
}

PaymentDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object
}

export default withStyles(styles)(PaymentDetails)
