import { useUIContext } from 'context'

const useLoaders = () => {
  const { isLoading, setIsLoading } = useUIContext()

  const showLoading = () => setIsLoading(true)
  const hideLoading = () => setIsLoading(false)

  return {
    isLoading,
    setIsLoading,
    showLoading,
    hideLoading
  }
}

export default useLoaders
