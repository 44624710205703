import { useResourceRQ } from 'hooks'

import { internalUserEndpoint } from 'api/endpoints'

const useAdminInternalUser = ({ id, fetchingEnabled }) => {
  const {
    create: createInternalUser,
    data: internalUser,
    update: updateInternalUser,
    isLoading: isLoadingInternalUser,
    isError: isInternalUserError,
    error: internalUserError
  } = useResourceRQ({
    endpoint: internalUserEndpoint,
    args: [id],
    name: 'internalUser',
    fetchingEnabled: Boolean(id) || fetchingEnabled,
    dependents: [{ func: internalUserEndpoint }]
  })

  return {
    internalUser,
    updateInternalUser,
    createInternalUser,
    isLoadingInternalUser,
    isInternalUserError,
    internalUserError
  }
}

export default useAdminInternalUser
