import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Box } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'

import { SIZE, COLOR } from 'constants/enums'

import { useMediaQuery } from 'hooks'
import { PROMO_CODE_FIELDS } from 'constants/promoCodes'

import { useControlledForm } from 'components/ControlledForm'
import { TextFieldControlled } from 'components/TextField'
import Button from 'components/Button'

const CodeInputField = ({
  classes,
  generatePromo,
  formModified,
  setFormModified,
  setFormDefaults
}) => {
  const { isDesktopScreen } = useMediaQuery()
  const {
    formContext: { register, unregister, getValues }
  } = useControlledForm()

  useEffect(() => {
    register(PROMO_CODE_FIELDS.label)
    return () => {
      unregister(PROMO_CODE_FIELDS.label)
    }
  }, [register, unregister, PROMO_CODE_FIELDS.label])

  const handleGeneratePromoCode = async () => {
    const generatedCode = await generatePromo()
    // @awitherow note: setValue does not trigger a ui re-render in this case
    // due to react-hook-form being designed to not do so, requires this method
    // of setting the default values of the form. setFormModified is required
    // to make sure the SaveButton is not disabled.
    setFormDefaults({
      ...getValues(),
      [PROMO_CODE_FIELDS.label]: generatedCode
    })
    !formModified && setFormModified(true)
  }

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      width="100%"
    >
      <TextFieldControlled
        dataTest="promo-code-input"
        name={PROMO_CODE_FIELDS.label}
        placeholder="FASTAF50"
        className={classNames({
          [classes.controlContainer]: true,
          [classes.filledInput]: true
        })}
      />
      <Button
        className={classNames({
          [classes.generateButton]: true,
          [classes.generateButtonMobile]: isDesktopScreen
        })}
        label="generate promo code button"
        onClick={handleGeneratePromoCode}
        size={SIZE.medium}
        color={COLOR.secondary}
        dataTest="generate-promo-code-button"
        startIcon={<CachedIcon />}
        adaptive
        type="button"
      >
        Generate
      </Button>
    </Box>
  )
}

CodeInputField.propTypes = {
  classes: PropTypes.object.isRequired,
  generatePromo: PropTypes.func.isRequired,
  formModified: PropTypes.bool.isRequired,
  setFormModified: PropTypes.func.isRequired,
  setFormDefaults: PropTypes.func.isRequired
}

export default CodeInputField
