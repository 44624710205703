import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'

import {
  SIZE,
  BUTTON_VARIANT,
  COLLECTION_LAYOUT_VARIANT
} from 'constants/enums'

import { URL } from 'constants/navigation'
import {
  useAdminCollectionGroupsList,
  useAdminCollectionGroups,
  useNavigation,
  useQuery,
  useAlerts
} from 'hooks'

import Header from 'components/Header'
import Button from 'components/Button'
import SearchInput from 'components/SearchInput'
import CollectionLayout from 'components/CollectionLayout'
import GlanceTile from 'components/GlanceTile'
import CreateCollectionGroupModal from 'components/CreateCollectionGroupModal.jsx'

const CollectionGroupList = () => {
  const { showAlertError, showAlertSuccess } = useAlerts()

  const { query, handleQueryChange } = useQuery(false)

  const { handleClick: navigateToCollectionGroup } = useNavigation({
    url: URL.ADMIN_COLLECTION_GROUPS
  })

  const {
    isLoadingCollectionGroups,
    collectionGroups,
    hasCollectionGroups,
    hasCollectionGroupsNext,
    fetchCollectionGroupsNext,
    collectionGroupsMeta
  } = useAdminCollectionGroupsList(query)

  const { createCollectionGroup } = useAdminCollectionGroups(null, {
    onSuccess: showAlertSuccess,
    onError: showAlertError
  })

  const [
    createCollectionGroupModalOpen,
    setCreateCollectionGroupModalOpen
  ] = useState(false)

  const foldableActions = (
    <SearchInput
      name="search"
      placeholder="Search Collection Groups"
      value={query.search}
      onChange={handleQueryChange}
      dataTest="collection-group-search"
    />
  )

  const actions = (
    <Button
      adaptive
      label="add collection group button"
      startIcon={<AddIcon />}
      variant={BUTTON_VARIANT.outlined}
      size={SIZE.medium}
      onClick={() => setCreateCollectionGroupModalOpen(true)}
      dataTest="add-collection-group-button"
    >
      Add Collection Group
    </Button>
  )

  const summarizers = (
    <GlanceTile
      minimal
      label="Total Collection Groups"
      value={collectionGroupsMeta?.total_count}
    />
  )

  return (
    <Box data-test="collections-group-list-page" height="100%" width="100%">
      <Header
        title="Collection Groups"
        foldableActions={foldableActions}
        summarizers={summarizers}
        actions={actions}
      />
      <CollectionLayout
        items={collectionGroups}
        variant={COLLECTION_LAYOUT_VARIANT.group}
        hasItems={hasCollectionGroups}
        hasItemsNext={hasCollectionGroupsNext}
        listItemsNext={fetchCollectionGroupsNext}
        onItemClick={navigateToCollectionGroup}
        isLoading={isLoadingCollectionGroups}
        currentPage={collectionGroupsMeta?.page}
        selectable={false}
        wide
      />
      <CreateCollectionGroupModal
        open={createCollectionGroupModalOpen}
        createCollectionGroup={createCollectionGroup}
        onClose={() => setCreateCollectionGroupModalOpen(false)}
      />
    </Box>
  )
}

export default CollectionGroupList
