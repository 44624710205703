import React from 'react'
import PropTypes from 'prop-types'

const EditIcon = ({ color, size, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.90141 11.8619L13.2969 1.46643C13.3061 1.46378 13.3158 1.4611 13.3258 1.45839C13.4626 1.42133 13.6605 1.38264 13.8925 1.37944C14.3367 1.37331 14.9278 1.49293 15.5133 2.07843C16.0988 2.66392 16.2184 3.25504 16.2123 3.69925C16.2091 3.93131 16.1704 4.1292 16.1334 4.266C16.1307 4.27601 16.128 4.28563 16.1253 4.29486L5.72984 14.6904L2.51571 15.076L2.90141 11.8619Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M10.7002 2.64844L14.7408 6.68905" stroke={color} strokeWidth="2" />
  </svg>
)

EditIcon.defaultProps = {
  size: 18,
  color: 'currentColor'
}

EditIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
}

export default EditIcon
