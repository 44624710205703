import React from 'react'
import PropTypes from 'prop-types'
import { FORM_FIELDS } from 'constants/products'
import { withStyles } from '@material-ui/core/styles'
import { TextFieldControlled } from 'components/TextField'
import { Controller } from 'react-hook-form'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from 'components/Button'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import 'react-markdown-editor-lite/lib/index.css'

import { SIZE, COLOR } from 'constants/enums'

import styles from './ProductNutriFactFieldsStyles'

const handleNutriFieldOnChange = ({
  e,
  fieldName,
  nutriLineId,
  onChange,
  currentNutriLineState
}) => {
  const val = e.target.value
  const data = currentNutriLineState?.data
  const indexOfNutriLineToChange = data?.findIndex(
    nl => Number(nl?.id) === Number(nutriLineId)
  )
  data[indexOfNutriLineToChange][fieldName] = val
  onChange({ data, lastUpdate: val })
}

const generateUniqueRowId = currentArray => {
  const currentIds = currentArray.map(line => line?.id)
  const generateUniqueId = () => -Math.floor(Math.random() * 1000000000)
  let generationAttempts = 0
  let uniqueId = generateUniqueId()
  while (currentIds.indexOf(uniqueId) && generationAttempts < 3) {
    uniqueId = generateUniqueId()
    generationAttempts++
  }
  return uniqueId
}

const generateNewNutriLine = (
  currentArray,
  name = '',
  value = '',
  percent = ''
) => {
  const uniqueId = generateUniqueRowId(currentArray)
  return { id: uniqueId, name, value, percent }
}

const generateTemplateNutrientLines = async (
  currentArray,
  getAllNutrientOptions,
  useStandardFacts
) => {
  const nutrients = await getAllNutrientOptions()
  const existingNutrientNames = currentArray
    .filter(nutrient => !!nutrient.name)
    .map(nutrient => nutrient?.name)
  const standardNutrientLines = nutrients?.data
    .filter(
      nutrient =>
        nutrient.standard === useStandardFacts &&
        !existingNutrientNames.includes(nutrient.name)
    )
    .map(nutrient => generateNewNutriLine(currentArray, nutrient.name))

  const newRowArray =
    standardNutrientLines.length > 0
      ? [...currentArray, ...standardNutrientLines]
      : currentArray

  return newRowArray
}

const NutriFactsControlled = ({ name, getAllNutrientOptions }) => (
  <Controller
    name={name}
    render={({ onChange, value }) => (
      <Box>
        <Box marginX="30px" display="flex" justifyContent="space-between">
          <Typography variant="caption">Nutrition Facts</Typography>
          <Box display="flex" flexDirection="column">
            <Box>
              <Button
                dataTest="product-add-nutrition-fact"
                onClick={() =>
                  onChange({
                    data: [
                      ...value?.data,
                      { ...generateNewNutriLine(value?.data) }
                    ]
                  })
                }
                size={SIZE.small}
                color={COLOR.primary}
                fullWidth
              >
                Add Nutrition Fact
              </Button>
            </Box>
            <Box mt={1}>
              <Button
                size={SIZE.small}
                color={COLOR.default}
                onClick={async () =>
                  onChange({
                    data: [
                      ...(await generateTemplateNutrientLines(
                        value?.data,
                        getAllNutrientOptions,
                        true
                      ))
                    ]
                  })
                }
                fullWidth
              >
                Add Missing Standard Facts
              </Button>
            </Box>
            <Box mt={1}>
              <Button
                size={SIZE.small}
                color={COLOR.default}
                onClick={async () =>
                  onChange({
                    data: [
                      ...(await generateTemplateNutrientLines(
                        value?.data,
                        getAllNutrientOptions,
                        false
                      ))
                    ]
                  })
                }
                fullWidth
              >
                Add Missing Non-Standard Facts
              </Button>
            </Box>
          </Box>
        </Box>
        {value?.data?.map((nutriLine, index) => (
          <Box
            key={`nutriline-${nutriLine?.id}`}
            display="flex"
            marginTop="10px"
            justifyContent="space between"
            width="100%"
          >
            <Box flex={0.1}></Box>
            <Box flex={0.3} pl="10px">
              <Typography variant="caption">Name</Typography>
              <OutlinedInput
                name={`${FORM_FIELDS.NUTRITION_FACTS}-${nutriLine?.id}-name`}
                data-test="nutrition-fact-name"
                defaultValue={nutriLine?.name}
                onChange={e =>
                  handleNutriFieldOnChange({
                    e,
                    fieldName: 'name',
                    nutriLineId: nutriLine?.id,
                    onChange,
                    currentNutriLineState: value
                  })
                }
                placeholder="Calcium"
                fullWidth
                centeredInput
              />
            </Box>
            <Box flex={0.2} pl="10px">
              <Typography variant="caption">Value</Typography>
              <OutlinedInput
                name={`${FORM_FIELDS.NUTRITION_FACTS}-${nutriLine?.id}-value`}
                data-test="nutrition-fact-value"
                defaultValue={nutriLine?.value}
                onChange={e =>
                  handleNutriFieldOnChange({
                    e,
                    fieldName: 'value',
                    nutriLineId: nutriLine?.id,
                    onChange,
                    currentNutriLineState: value
                  })
                }
                placeholder="1g"
                fullWidth
                centeredInput
              />
            </Box>
            <Box flex={0.2} pl="10px">
              <Typography variant="caption">Percent</Typography>
              <OutlinedInput
                name={`${FORM_FIELDS.NUTRITION_FACTS}-${nutriLine?.id}-percent`}
                data-test="nutrition-fact-percent"
                defaultValue={nutriLine?.percent}
                onChange={e =>
                  handleNutriFieldOnChange({
                    e,
                    fieldName: 'percent',
                    nutriLineId: nutriLine?.id,
                    onChange,
                    currentNutriLineState: value
                  })
                }
                placeholder="20"
                fullWidth
                centeredInput
              />
            </Box>
            <Box flex={0.3}>
              <Box marginTop="28px" marginLeft="14px">
                <Button
                  dataTest="product-remove-nutrition-fact-btn"
                  onClick={() => {
                    onChange({
                      data: value?.data?.filter((val, i) => i !== index) || []
                    })
                  }}
                  size={SIZE.small}
                  color={COLOR.secondary}
                >
                  X
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    )}
  />
)

const ProductNutriFactFieldsRaw = ({ classes, getAllNutrientOptions }) => (
  <Box width="100%" marginBottom="20px" marginTop="30px">
    <Box display="flex">
      <Box flex={0.5} marginX="30px">
        <TextFieldControlled
          name={FORM_FIELDS.SERVING_SIZE}
          dataTest="product-serving-size"
          label={'Serving Size'}
          placeholder="1 Unit"
          fullWidth
          centeredInput
        />
      </Box>
      <Box flex={0.5} marginX="30px">
        <TextFieldControlled
          name={FORM_FIELDS.SERVINGS_PER_CONTAINER}
          dataTest="product-serving-per-container"
          label={'Servings Per Container'}
          placeholder="4 Servings"
          fullWidth
          centeredInput
        />
      </Box>
    </Box>
    <Box width="100%" marginBottom="20px" marginTop="30px">
      <Box display="flex">
        <Box flex={0.5} marginX="30px">
          <TextFieldControlled
            name={FORM_FIELDS.CALORIES_PER_SERVING}
            dataTest="product-calories-per-serving"
            label={'Calories Per Serving'}
            placeholder="400 Calories"
            fullWidth
            className={classes.textField}
            centeredInput
          />
        </Box>
        <Box flex={0.5} marginX="30px">
          <TextFieldControlled
            name={FORM_FIELDS.FAT_CALORIES_PER_SERVING}
            dataTest="product-fat-calories-per-serving"
            label={'Fat Calories Per Serving'}
            placeholder="200 Calories"
            fullWidth
            className={classes.textField}
            centeredInput
          />
        </Box>
      </Box>
    </Box>
    <NutriFactsControlled
      name={FORM_FIELDS.NUTRITION_FACTS}
      getAllNutrientOptions={getAllNutrientOptions}
    />
  </Box>
)

ProductNutriFactFieldsRaw.propTypes = {}

const ProductNutriFactFields = withStyles(styles)(ProductNutriFactFieldsRaw)

export default ProductNutriFactFields
