import React, { forwardRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import * as propTypes from 'constants/propTypes'
import { useOnScrollBottom } from 'hooks'

import { withStyles } from '@material-ui/core/styles'

import TableContainer from '@material-ui/core/TableContainer'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'

import SortableCell from 'components/SortableCell'
import ProgressBar from 'components/ProgressBar'

import styles from './DataTableStyles'

// Used to detect when to start fetching new items when scrolling
const OFFSET_PX = 10

const DataTable = forwardRef(
  (
    {
      classes,
      columns,
      children,
      message,
      isLoadingList,
      hasListNext,
      listNext,
      query,
      updateQuery,
      headerActionOpen,
      headerAction,
      useWindowScrollListener = false,
      id,
      offset = false,
      slimTable = true,
      stickyColumnWidth = 0 // See DATATABLE_STICKY_COLUMN_NOTE
    },
    ref
  ) => {
    const calculatedOffset =
      offset && typeof offset === 'boolean' ? OFFSET_PX : offset

    const handleScroll = e => {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (
        scrollTop + calculatedOffset >= scrollHeight - clientHeight &&
        hasListNext &&
        listNext
      ) {
        listNext()
      }
    }

    useOnScrollBottom({
      listNext,
      isLoading: isLoadingList,
      shouldEngage: useWindowScrollListener
    })

    return (
      <TableContainer
        onScroll={handleScroll}
        classes={{
          root: classes.TableContainer
        }}
        data-test={`${id}-datatable`}
        ref={ref}
      >
        <Table>
          <TableHead
            classes={{
              root: slimTable ? classes.TableHeadSlim : classes.TableHead
            }}
          >
            {headerActionOpen ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className={classes.actionHeader}
                >
                  {headerAction}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                {columns.map((column, idx) =>
                  column.sortKeys ? (
                    <SortableCell
                      {...(idx === 0 &&
                        stickyColumnWidth > 0 && {
                          style: {
                            minWidth: stickyColumnWidth,
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }
                        })}
                      key={idx}
                      sortKeys={column.sortKeys}
                      query={query}
                      updateQuery={updateQuery}
                    >
                      {column.title}
                    </SortableCell>
                  ) : (
                    <TableCell
                      {...(idx === 0 &&
                        stickyColumnWidth > 0 && {
                          style: {
                            minWidth: stickyColumnWidth,
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }
                        })}
                      className={classNames({
                        [classes.emptyColumn]: !column.title
                      })}
                      key={idx}
                    >
                      {column.title}
                    </TableCell>
                  )
                )}
              </TableRow>
            )}
          </TableHead>
          <TableBody
            classes={{
              root: slimTable ? classes.TableBodySlim : classes.TableBody
            }}
          >
            {children}
          </TableBody>
        </Table>

        {isLoadingList && (
          <Box my={4}>
            <ProgressBar />
          </Box>
        )}

        {!isLoadingList && hasListNext && (
          <Box my={4}>
            <ProgressBar value={0} />
          </Box>
        )}

        {message && (
          <Box my={4} textAlign="center">
            <Typography variant="h3">{message}</Typography>
          </Box>
        )}
      </TableContainer>
    )
  }
)

/* 

DATATABLE_STICKY_COLUMN_NOTE

      <TableCell
        style={{
          position: 'fixed',
          left: !isMobileScreen ? 224 : 0, // accounts for sidenav width.
          zIndex: 9999,
          backgroundColor: 'white',
          width: 500,
          overflowX: 'hidden',
          minHeight: 60.63,
          paddingLeft: 32
        }}
      >

is the Table Cell props I used on DistributionCenterVariantItem.jsx to implement a sticky column
on the SKU column. 

*/

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: propTypes.dataColumn.isRequired,
  isLoadingList: PropTypes.bool,
  hasListNext: PropTypes.bool,
  listNext: PropTypes.func,
  headerAction: PropTypes.node,
  headerActionOpen: PropTypes.bool,
  query: PropTypes.object,
  updateQuery: PropTypes.func,
  message: PropTypes.string,
  children: PropTypes.node,
  useWindowScrollListener: PropTypes.bool,
  id: PropTypes.string,
  offset: PropTypes.oneOf([PropTypes.number, PropTypes.bool])
}

export default withStyles(styles)(DataTable)
