import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import { useAdminProducts } from 'hooks'
import { useLocationsContext } from 'context'
import { PRODUCT_RESULTS_POPOVER_TYPES } from 'constants/enums'

import Button from 'components/Button'
import Drawer from 'components/Drawer'
import ProductSummary from 'components/ProductSummary'
import ProductResultsPopover from 'components/ProductResultsPopover'

import styles from './AddCollectionProductDrawerStyles'

const AddCollectionProductDrawer = ({
  classes,
  open,
  onClose,
  activeProducts,
  onSubmit
}) => {
  const [searchString, setSearchString] = useState('')
  const [selectedProducts, setSelectedProducts] = useState([])

  const { location } = useLocationsContext()

  const { products, listProducts } = useAdminProducts({})

  useEffect(() => {
    listProducts({ search: searchString })
  }, [searchString])

  const handleClose = () => {
    setSelectedProducts([])
    setSearchString('')
    onClose && onClose()
  }

  const handleSearchChange = e => {
    setSearchString(e.target.value)
  }

  const handleSubmit = () => {
    onSubmit(selectedProducts)
    handleClose()
  }

  const handleAddProduct = item => {
    setSelectedProducts(uniqBy([...selectedProducts, item], p => p.id))
  }

  const handleRemoveProduct = item => {
    setSelectedProducts(selectedProducts.filter(p => p.id !== item.id))
  }

  // TODO: Replace with the utility for this
  const getProductQuantity = item =>
    sum(
      item?.variants.map(v =>
        sumBy(
          v.inventory.filter(i => i.location_id === location.id),
          i => i.quantity
        )
      )
    )

  const drawerActions = (
    <Button
      fullWidth
      disabled={isEmpty(selectedProducts)}
      onClick={handleSubmit}
    >
      Add to Collection
    </Button>
  )

  return (
    <Drawer
      title="Add Products"
      open={open}
      onClose={handleClose}
      actions={drawerActions}
    >
      <Box className={classes.container}>
        <Box>
          <Typography variant="h6">Adding For</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body1">{location?.city || 'Unknown'}</Typography>
        </Box>

        <Box mt={4} mb={2}>
          <Typography variant="h6">Search Products</Typography>
        </Box>

        <ProductResultsPopover
          options={products ?? []}
          selectedOptions={selectedProducts}
          onTextChange={handleSearchChange}
          onAddItem={handleAddProduct}
          onRemoveItem={handleRemoveProduct}
          activeProducts={activeProducts}
          type={PRODUCT_RESULTS_POPOVER_TYPES.collection}
        />

        <Box mt={4}>
          {selectedProducts.map(product => (
            <Box key={product.id} className={classes.selectedItem} mb={1}>
              <Box className={classes.productSummary}>
                <ProductSummary product={product} showPrice />
              </Box>
              <Box className={classes.itemActions}>
                <IconButton
                  edge="end"
                  onClick={() => handleRemoveProduct(product)}
                >
                  <RemoveCircleIcon />
                </IconButton>
                <Box flex={1} />
                <Box className={classes.quantity} pb={1}>
                  <Typography variant="body1">
                    Qty {getProductQuantity(product)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  )
}

AddCollectionProductDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  activeProducts: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func
}

export default withStyles(styles)(AddCollectionProductDrawer)
