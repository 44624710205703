import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { Box, Typography, Radio, IconButton } from '@material-ui/core'

import { useAlerts } from 'hooks'
import {
  PROMO_CODE_FIELDS,
  ELIGIBLE_CUSTOMER_LABELS
} from 'constants/promoCodes'
import { ICON_BUTTON_VARIANT, SIZE, COLOR } from 'constants/enums'

import { useControlledForm } from 'components/ControlledForm'
import { AutocompleteSearchUserControlled } from 'components/AutocompleteSearchUser'
import Button from 'components/Button'

import CloseIcon from 'icons/CloseIcon'
import DownloadIcon from 'icons/DownloadIcon'
import ExportIcon from 'icons/ExportIcon'

const EligibleUsersInputRadios = ({
  classes,
  formModified,
  editMode,
  handleImport,
  handleExport,
  savedEligibleUserIds = []
}) => {
  const { showAlertGeneral } = useAlerts()
  const {
    formContext: {
      setValue,
      register,
      unregister,
      watch,
      getValues,
      formState: { dirtyFields }
    }
  } = useControlledForm()

  useEffect(() => {
    register(PROMO_CODE_FIELDS.eligible_users_selection)
    register(PROMO_CODE_FIELDS.eligible_user_ids)
    return () => {
      unregister(PROMO_CODE_FIELDS.eligible_users_selection)
      unregister(PROMO_CODE_FIELDS.eligible_user_ids)
    }
  }, [register, unregister, PROMO_CODE_FIELDS.eligible_users_selection])

  const {
    [PROMO_CODE_FIELDS.eligible_users_selection]: eligibleUsersSelection
  } = watch(
    [PROMO_CODE_FIELDS.eligible_users_selection].filter(
      n => n && typeof n === 'string'
    )
  )

  useEffect(() => {
    if (eligibleUsersSelection === ELIGIBLE_CUSTOMER_LABELS[0].value) {
      const currentValue = getValues(PROMO_CODE_FIELDS.eligible_user_ids)
      const reset = []
      if (currentValue !== reset) {
        setValue(PROMO_CODE_FIELDS.eligible_user_ids, reset, {
          shouldDirty: true
        })
      }
    }
  }, [eligibleUsersSelection])

  const renderInput = ELIGIBLE_CUSTOMER_LABELS?.filter(
    ({ value }) => value === eligibleUsersSelection
  )[0]?.renderInput

  if (PROMO_CODE_FIELDS.eligible_users_selection in dirtyFields) {
    delete dirtyFields[PROMO_CODE_FIELDS.eligible_users_selection]
  }
  const csvUploadDisabled = formModified || !isEmpty(dirtyFields) || true

  return (
    <>
      <Typography variant="caption">Customer Eligibility</Typography>
      <Box className={classes.radioButtonContainer}>
        {ELIGIBLE_CUSTOMER_LABELS.map(({ label: thisLabel, value }) => (
          <Box className={classes.radioButtonWithLabel} key={value}>
            <Radio
              color="primary"
              checked={eligibleUsersSelection === value}
              onChange={e =>
                setValue(
                  PROMO_CODE_FIELDS.eligible_users_selection,
                  e.target.value,
                  { shouldDirty: true }
                )
              }
              value={value}
            />
            <Typography variant="body1">{thisLabel}</Typography>
          </Box>
        ))}
      </Box>
      {Boolean(renderInput) && (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
          width="100%"
        >
          <Box mb={1}>
            <Typography variant="caption">Included Customers</Typography>
          </Box>
          <Box display="flex" width="100%" justifyContent="flex-end">
            {savedEligibleUserIds?.length > 15 &&
            !(PROMO_CODE_FIELDS.eligible_user_ids in dirtyFields) ? (
              <Box display="flex" alignItems="center">
                <Typography variant="body1">
                  {savedEligibleUserIds?.length} Eligible Users
                </Typography>
                <Box ml={1}>
                  <IconButton
                    onClick={() =>
                      setValue(PROMO_CODE_FIELDS.eligible_user_ids, [], {
                        shouldDirty: true
                      })
                    }
                    size={20}
                    variant={ICON_BUTTON_VARIANT.transparent}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <AutocompleteSearchUserControlled
                name={PROMO_CODE_FIELDS.eligible_user_ids}
                className={classes.controlContainer}
              />
            )}
            {editMode && !savedEligibleUserIds?.length ? (
              <label htmlFor="import-csv">
                <input
                  accept=".csv"
                  style={{ display: 'none' }}
                  id="import-csv"
                  name="import-csv"
                  type="file"
                  onChange={handleImport}
                  disabled={csvUploadDisabled}
                />
                <Button
                  className={classes.csvUploadButton}
                  label="upload eligible user ids csv"
                  size={SIZE.medium}
                  color={COLOR.secondary}
                  dataTest="csv-upload-eligible-user-ids"
                  startIcon={<ExportIcon />}
                  component="span"
                  adaptive
                  onClick={() =>
                    csvUploadDisabled &&
                    showAlertGeneral('CSV Uploads of user_ids coming soon!')
                  }
                >
                  Upload
                </Button>
              </label>
            ) : (
              savedEligibleUserIds?.length > 15 &&
              !(PROMO_CODE_FIELDS.eligible_user_ids in dirtyFields) && (
                <Button
                  className={classes.csvUploadButton}
                  label="download eligible user ids csv"
                  size={SIZE.medium}
                  color={COLOR.secondary}
                  dataTest="csv-download-eligible-user-ids"
                  startIcon={<DownloadIcon />}
                  type="button"
                  adaptive
                  onClick={() =>
                    csvUploadDisabled
                      ? showAlertGeneral(
                          'CSV Downloads of user_ids coming soon!'
                        )
                      : handleExport()
                  }
                >
                  Download
                </Button>
              )
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

EligibleUsersInputRadios.propTypes = {
  classes: PropTypes.object.isRequired,
  formModified: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  handleImport: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  savedEligibleUserIds: PropTypes.arrayOf(PropTypes.number).isRequired
}

export default EligibleUsersInputRadios
