import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Skeleton from '@material-ui/lab/Skeleton'

import { useNavigation } from 'hooks'
import { URL } from 'constants/navigation'
import {
  ICON_BUTTON_VARIANT,
  PUBLISHED_STATUS_VARIANT,
  CATEGORY_TYPES
} from 'constants/enums'
import {
  STOREFRONT_SECTION_TYPES,
  STOREFRONT_SECTION_TITLES,
  STOREFRONT_SECTION_FRIENDLY_NAMES
} from 'constants/general'
import { priceLabel, getProductImage } from 'utils/products'
import IconButton from 'components/IconButton'
import PublishStatusIndicator from 'components/PublishStatusIndicator'
import CollectionGroupPreview from 'components/CollectionGroupPreview.jsx'
import DisplayCollectionMedia from 'components/DisplayCollectionMedia'
import RemoveIcon from 'icons/RemoveIcon'
import GrabIcon from 'icons/GrabIcon'
import ReferralCodePlaceholder from 'assets/referral_code_placeholder.jpg'
import UnknownProduct from 'assets/unknown_product.jpg'

import styles from './DraggableSectionStyles'

const NUMBER_PREVIEW_ITEMS = 3

const isPublished = (item, locationId) =>
  item.location_ids?.findIndex?.(id => id === locationId) >= 0

const SimpleProductCard = ({ classes, product }) => (
  <Box className={classes.previewCard}>
    <img src={getProductImage(product) ?? UnknownProduct} alt={product.title} />
    <Typography
      variant="overline"
      color="textSecondary"
      className={classes.previewBrand}
    >
      {product.brand}
    </Typography>
    <Typography variant="body1" className={classes.previewTitle}>
      {product.title}
    </Typography>
    <Typography variant="body2" className={classes.previewPrice}>
      {priceLabel(product)}
    </Typography>
  </Box>
)

SimpleProductCard.propTypes = {
  classes: PropTypes.object,
  product: PropTypes.object
}

const SimpleBrandCard = ({ classes, brand }) => (
  <Box className={classes.previewCard}>
    <img
      src={brand.image_url ?? UnknownProduct}
      alt={brand.title}
      className={classes.circleImage}
    />
    <Typography
      variant="overline"
      color="textSecondary"
      className={classes.previewBrand}
    >
      {brand.title}
    </Typography>
  </Box>
)

SimpleBrandCard.propTypes = {
  classes: PropTypes.object,
  brand: PropTypes.object
}

const SimpleCollectionCard = ({ classes, collection }) => (
  <Box className={classes.previewCard}>
    <img
      src={collection.image_url ?? UnknownProduct}
      alt={collection.title}
      className={classes.circleImage}
    />
    <Typography
      variant="overline"
      color="textSecondary"
      className={classes.previewBrand}
    >
      {collection.title}
    </Typography>
  </Box>
)

SimpleCollectionCard.propTypes = {
  classes: PropTypes.object,
  collection: PropTypes.object
}

const PlaceholderCard = ({ classes }) => (
  <Box className={classes.previewCard}>
    <Skeleton variant="rect" height={110} width={110} animation={false} />
    <Typography className={classes.previewBrand}>
      <Skeleton width={80} animation={false} />
    </Typography>
    <Typography className={classes.previewTitle}>
      <Skeleton width={100} animation={false} />
    </Typography>
    <Typography className={classes.previewPrice}>
      <Skeleton width={40} animation={false} />
    </Typography>
  </Box>
)

PlaceholderCard.propTypes = {
  classes: PropTypes.object
}

const getSectionPreview = ({ type, data }, locationId) => {
  switch (type) {
    case STOREFRONT_SECTION_TYPES.COLLECTION_SLIDER:
      return { collections: data }
    case STOREFRONT_SECTION_TYPES.COLLECTION_GROUP:
      const { collections } = data
      return {
        collectionGroup: {
          collections
        },
        imageUrl: collections ? undefined : UnknownProduct
      }
    case STOREFRONT_SECTION_TYPES.PRODUCT_SLIDER:
      const { products } = data
      return {
        products,
        imageUrl: products ? undefined : data.image_url,
        summary: {
          productCount: products?.length ?? 0,
          published: isPublished(data, locationId),
          seeAll: data.has_see_all_link ?? false,
          collection: data
        }
      }
    case STOREFRONT_SECTION_TYPES.BRAND_SLIDER:
      return { brands: data, placeholder: !data?.length }
    case STOREFRONT_SECTION_TYPES.REFERRAL_CODE_BLOCK:
      return { imageUrl: ReferralCodePlaceholder }
    default:
      return { usePlaceholder: true }
  }
}

const hasCustomTitle = type => {
  return (
    type === STOREFRONT_SECTION_TYPES.PRODUCT_SLIDER ||
    type === STOREFRONT_SECTION_TYPES.COLLECTION_GROUP
  )
}

const getTitle = (type, data) => {
  if (hasCustomTitle(type)) {
    return data.title
  }
  return STOREFRONT_SECTION_TITLES[type]
}

const Subtitle = ({ type, classes }) => {
  if (hasCustomTitle(type)) {
    return (
      <Box className={classes.subtitle}>
        <Typography variant="body1">
          {STOREFRONT_SECTION_FRIENDLY_NAMES[type]}
        </Typography>
      </Box>
    )
  }

  return null
}

Subtitle.propTypes = {
  type: PropTypes.string,
  classes: PropTypes.object
}

const DraggableSection = ({
  classes,
  item: section,
  width,
  height,
  locationId,
  onRemove,
  draggable
}) => {
  const { handleClick: navigateToCollection } = useNavigation({
    url: URL.ADMIN_COLLECTIONS
  })

  const { type, data } = section
  const title = getTitle(type, data)

  const {
    usePlaceholder,
    collections,
    collectionGroup,
    products,
    brands,
    imageUrl,
    summary
  } = getSectionPreview(section, locationId)

  const extraPreviewProps = imageUrl
    ? {
        style: {
          backgroundImage: `url(${imageUrl})`,
          backgroundColor: '#D1D1D1'
        }
      }
    : { width: 500 }

  const placeholderCards = usePlaceholder
    ? [...Array(NUMBER_PREVIEW_ITEMS)].map((_, i) => (
        <PlaceholderCard key={i} classes={classes} />
      ))
    : null

  const clickableProps = {
    className: classes.pointer,
    onClick: e => navigateToCollection(e, summary.collection),
    onAuxClick: e => navigateToCollection(e, summary.collection, true)
  }

  return (
    <Box width={width} height={height} className={classes.root}>
      <Box className={classes.body} p={1}>
        {draggable && <GrabIcon className={classes.grab} size={20} />}
        <Box
          display="flex"
          flex={1}
          {...(summary?.collection ? clickableProps : {})}
        >
          <Box pl={1} flexShrink={1}>
            <Typography variant="body2" className={title}>
              {title}
            </Typography>
          </Box>

          <Box flex={1} />

          {summary && (
            <>
              <Chip label={summary.productCount} className={classes.chip} />
              {summary.seeAll && (
                <Chip label="See All" className={classes.chip} />
              )}
              <PublishStatusIndicator
                size={19}
                variant={
                  summary.published
                    ? PUBLISHED_STATUS_VARIANT.active
                    : PUBLISHED_STATUS_VARIANT.inactive
                }
              />
            </>
          )}
        </Box>
        {onRemove && (
          <Box ml={0.5} mt="2.2px">
            <IconButton
              size={14}
              circle
              variant={ICON_BUTTON_VARIANT.black}
              onClick={() => onRemove(section)}
            >
              <RemoveIcon fontSize={8} />
            </IconButton>
          </Box>
        )}
      </Box>
      {section.data?.collection_type === CATEGORY_TYPES.editorial ? (
        <DisplayCollectionMedia collection={section.data} expanded />
      ) : (
        <Box className={classes.preview} {...extraPreviewProps}>
          {products?.length &&
            products
              .slice(0, NUMBER_PREVIEW_ITEMS)
              .map((product, idx) => (
                <SimpleProductCard
                  key={idx}
                  classes={classes}
                  product={product}
                />
              ))}
          {brands?.length &&
            brands
              .slice(0, NUMBER_PREVIEW_ITEMS)
              .map((brand, idx) => (
                <SimpleBrandCard key={idx} classes={classes} brand={brand} />
              ))}
          {collections?.length &&
            collections
              .slice(0, NUMBER_PREVIEW_ITEMS)
              .map((collection, idx) => (
                <SimpleCollectionCard
                  key={idx}
                  classes={classes}
                  collection={collection}
                />
              ))}
          {collectionGroup && (
            <CollectionGroupPreview
              numberPreviewItems={NUMBER_PREVIEW_ITEMS}
              collectionGroup={collectionGroup}
            />
          )}
          {placeholderCards}
        </Box>
      )}
      <Subtitle type={type} classes={classes} />
    </Box>
  )
}

DraggableSection.defaultProps = {}

DraggableSection.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object)
    ])
  }),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  locationId: PropTypes.number,
  onRemove: PropTypes.func,
  draggable: PropTypes.bool
}

export default withStyles(styles)(DraggableSection)
