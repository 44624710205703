import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import { URL } from 'constants/navigation'

import Header from 'components/Header'
import Layout from 'components/Layout'
import PromoCodeHistoryTable from 'components/ProductHistoryTable'

import { useAdminPromoCodes, useLoaders } from 'hooks'

import styles from './PromoCodeHistoryStyles'

const PromoCodeHistory = ({ classes, match }) => {
  const { id } = match.params

  const {
    isLoadingPromoCodesHistory,
    promoCodesHistory,
    hasPromoCodesHistory,
    hasPromoCodesHistoryNext,
    listPromoCodesHistory,
    listPromoCodesHistoryNext,
    readPromoCode,
    promoCode
  } = useAdminPromoCodes(id)

  const { showLoading, hideLoading } = useLoaders()

  useEffect(() => {
    ;(async () => {
      listPromoCodesHistory(id)

      showLoading()
      await readPromoCode(id)
      hideLoading()
    })()
  }, [id])

  const listHistoryNext = () => listPromoCodesHistoryNext(id)

  return (
    <Layout>
      {id && (
        <Box>
          <Box className={classes.headerBox}>
            <Header
              breadcrumbs={[
                { title: 'Back of House', link: URL.ADMIN_PRODUCTS },
                { title: 'Promo Codes', link: URL.ADMIN_PROMO_CODES },
                {
                  title: promoCode?.code ?? 'Loading',
                  link: `${URL.ADMIN_PROMO_CODES}/${id}`
                }
              ]}
              title="History"
              actions={<></>}
            />
          </Box>
        </Box>
      )}
      <PromoCodeHistoryTable
        history={promoCodesHistory}
        hasHistory={hasPromoCodesHistory}
        hasHistoryNext={hasPromoCodesHistoryNext}
        listHistoryNext={listHistoryNext}
        isLoadingHistory={isLoadingPromoCodesHistory}
      />
    </Layout>
  )
}

PromoCodeHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(PromoCodeHistory)
