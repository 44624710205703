import { useResourceListRQ } from 'hooks'
import { brandEndpoint } from 'api/endpoints'

const useAdminBrandsRQ = params => {
  const {
    isFetching: isLoadingBrands,
    data: brands,
    hasData: hasBrands,
    hasNextPage: hasBrandsNext,
    fetchNextPage: fetchBrandsNext
  } = useResourceListRQ({ endpoint: brandEndpoint, params })

  return {
    isLoadingBrands,
    brands,
    hasBrands,
    hasBrandsNext,
    fetchBrandsNext
  }
}

export default useAdminBrandsRQ
