import { useCallback, useRef } from 'react'

/**
 * useLatestCallback
 * Returns a wrapper around the most recent input function passed to the hook.
 *
 * Compare to useCallback that returns a wrapper around the input function
 * defined when its dependencies last changed.
 *
 * Another difference is that useCallback's wrapper will change and cause
 * re-renders, whereas useLatestCallback always returns the same wrapper.
 */
const useLatestCallback = (
  fn = () => console.warn('useLatestCallback fn undefined')
) => {
  const ref = useRef(fn)
  ref.current = fn
  return useCallback((...args) => ref.current.apply(undefined, args), [])
}

export default useLatestCallback
