import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

import { AiOutlineInfoCircle } from 'react-icons/ai'

import { useAdminPromotions, useNavigation, useQuery } from 'hooks'
import { SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'
import { useLocationsContext } from 'context'
import { API_PARAMS, LIST_PATHS, SORT } from 'constants/queryParams'

import { TitleElement } from 'components/SiteTitle'
import Button from 'components/Button'
import DataTable from 'components/DataTable'
import Header from 'components/Header'
import Layout from 'components/Layout'
import Select from 'components/Select'

import PromotionsItem from './PromotionsItem'

import styles from './PromotionsListStyles'
import { Typography } from '@material-ui/core'

const columns = [
  {
    title: 'Promotion'
  },
  {
    title: 'Start'
  },
  {
    title: 'End'
  }
]

const PromotionsList = () => {
  const { locationId } = useLocationsContext()
  const { query, handleQueryChange } = useQuery(false, {})

  const {
    promotions,
    hasPromotions,
    hasPromotionsNext,
    isLoadingPromotions,
    listPromotionsNext
  } = useAdminPromotions({
    params: {
      [API_PARAMS.SORT]: LIST_PATHS.DISCOUNTS_CREATED_AT,
      [API_PARAMS.SORT_DIRECTION]: SORT.DESC,
      [API_PARAMS.LOCATION]: locationId,
      [LIST_PATHS.PROMOTIONS_ACTIVE_STATUS]:
        query[LIST_PATHS.PROMOTIONS_ACTIVE_STATUS]
    }
  })

  const { handleAddClick: createNewPromotionClick } = useNavigation({
    url: URL.ADMIN_PROMOTIONS
  })

  const actions = (
    <>
      <Button
        adaptive
        startIcon={<AddIcon />}
        color="primary"
        variant="contained"
        label="new promotion button"
        size={SIZE.medium}
        onClick={createNewPromotionClick}
        dataTest="new-promotion"
      >
        New Promotion
      </Button>
    </>
  )

  const filters = (
    <Select
      minimal
      name={LIST_PATHS.PROMOTIONS_ACTIVE_STATUS}
      innerLabel="Active Status"
      value={query[LIST_PATHS.PROMOTIONS_ACTIVE_STATUS] || ''}
      items={[
        { label: 'All', value: '' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' }
      ]}
      onChange={handleQueryChange}
      dataTest={`active-status-select-${LIST_PATHS.PROMOTIONS_ACTIVE_STATUS}`}
    />
  )

  const pageTitle = 'Promotions'

  return (
    <Layout id="PromotionsList">
      <TitleElement title={pageTitle} />
      <Header
        breadcrumbs={[{ title: 'Back of House', link: URL.ADMIN_BOH }]}
        title={pageTitle}
        actions={actions}
        filters={filters}
      />
      <Box mb={2} display="flex" alignItems="center" justifyConte="flex-start">
        <AiOutlineInfoCircle />
        <Box ml={1}>
          <Typography variant="body1">
            Promotions are filtered by Location, use the Sidebar to set the
            Filter.
          </Typography>
        </Box>
      </Box>
      <DataTable
        columns={columns}
        message={promotions?.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingPromotions}
        hasListNext={hasPromotionsNext}
        listNext={listPromotionsNext}
      >
        {hasPromotions &&
          promotions.map(promotion => (
            <PromotionsItem promotion={promotion} key={promotion.id} />
          ))}
      </DataTable>
    </Layout>
  )
}

PromotionsList.defaultProps = {}

PromotionsList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PromotionsList)
