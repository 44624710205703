import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { URL } from 'constants/navigation'
import { SIZE } from 'constants/enums'
import { LIST_PATHS } from 'constants/queryParams'

import {
  useAdminBrandCustomers,
  useMediaQuery,
  useNavigation,
  useLoaders,
  useQuery,
  useAuth
} from 'hooks'

import Layout from 'components/Layout'
import Header from 'components/Header'
import UserAvatar from 'components/UserAvatar'
import Block from 'components/Block'
import GlanceTile from 'components/GlanceTile/'
import DataTable from 'components/DataTable'
import Currency from 'components/Currency'

import BrandCustomerSaleItem from './BrandCustomerSaleItem'

import styles from './BrandCustomerDetailsStyles'

const brandCustomerSalesColumns = [
  { title: 'Order #', sortKeys: [LIST_PATHS.BRAND_SALES_ORDER_NUMBER] },
  { title: 'Product' },
  { title: 'SKU' },
  { title: 'Qty' },
  { title: 'Size' },
  { title: 'Color' },
  { title: 'Window' },
  { title: 'Returned' }
]

const BrandCustomerDetails = ({ classes, match }) => {
  const { currentUser } = useAuth()
  const { id } = match?.params
  const {
    brandCustomer,
    readBrandCustomer,
    listBrandCustomerSales,
    brandCustomerSales,
    isLoadingBrandCustomerSales,
    hasBrandCustomerSalesNext,
    listBrandCustomerSalesNext
  } = useAdminBrandCustomers({ id })

  const { isLoading, showLoading, hideLoading } = useLoaders()
  const { isMobileScreen } = useMediaQuery()

  const { handleClick: navigateToProduct } = useNavigation({
    url: URL.ADMIN_PRODUCTS
  })

  useEffect(() => {
    if (id || id === 0) {
      showLoading()
      readBrandCustomer(id)
    }
  }, [])

  useEffect(() => {
    isLoading && hideLoading()
  }, [brandCustomer])

  const {
    query: brandCustomerSalesQuery,
    updateQuery: updateBrandCustomerSalesQuery
  } = useQuery(listBrandCustomerSales)

  const { handleClick: navigateToOrder } = useNavigation({
    url: URL.ADMIN_ORDERS
  })

  const user = brandCustomer?.user
  const userName = user?.name ?? 'User Name'
  const userPrivateInfo = [
    {
      name: 'email',
      label: 'Email',
      value: (
        <>
          <Typography variant="body2">
            {user?.email || user?.checkout_email || 'No Email Set'}
          </Typography>
        </>
      )
    },
    {
      name: 'phone',
      label: 'Phone',
      value: (
        <>
          <Typography variant="body2">
            {user?.phone ?? 'No Phone Number'}
          </Typography>
        </>
      )
    },
    {
      name: 'address',
      label: 'Address',
      value:
        !user?.address1 && !user?.address2 ? (
          <>
            <Typography variant="body2">Address Not Set</Typography>
          </>
        ) : (
          <>
            <Typography variant="body2">{user?.address1}</Typography>
            {user?.address2 && (
              <Typography variant="body2">{user?.address2}</Typography>
            )}
          </>
        )
    }
  ]

  const topTiles = [
    {
      name: 'total_orders',
      label: 'Total Orders',
      value: brandCustomer?.total_orders
    },
    {
      name: 'total_returns',
      label: 'Total Returns',
      value: brandCustomer?.total_returns
    }
  ]

  const bottomTiles = [
    {
      name: 'lifetime_value',
      label: 'Lifetime Value',
      value: <Currency val={brandCustomer?.lifetime_value} />
    }
  ]

  const brandId = brandCustomer?.brand?.id

  return (
    <Layout id="brand-customer">
      <Header
        breadcrumbs={[
          {
            title: brandCustomer?.brand?.name ?? 'Brand',
            link: `${URL.ADMIN_BRANDS}/${brandId}`
          },
          {
            title: 'Customers',
            link: `${URL.ADMIN_BRANDS}/${brandId}?customerTab`
          }
        ]}
        title={userName}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Block
            className={classes.profile}
            withPadding
            title="Customer Details"
          >
            <Box className={classes.customerDetailsHeader}>
              <UserAvatar
                size={SIZE.xlarge}
                src={user?.image_url}
                className={classes.avatar}
              />
              <Typography variant="body2">{userName}</Typography>
            </Box>
            {currentUser.role === 'admin' && (
              <Box className={classes.stats}>
                {userPrivateInfo.map(stat => (
                  <Box className={classes.stat} key={stat.name}>
                    <Typography variant="caption">{stat.label}</Typography>
                    {stat.value}
                  </Box>
                ))}
              </Box>
            )}
          </Block>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.orderStatsContainer}>
              {topTiles.map(tile => (
                <GlanceTile
                  key={tile.name}
                  large
                  name={tile.name}
                  label={tile.label}
                  value={tile.value}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              {bottomTiles.map(tile => (
                <GlanceTile
                  className={classes.largeTile}
                  key={tile.name}
                  large
                  name={tile.name}
                  label={tile.label}
                  value={tile.value}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={isMobileScreen ? 1 : 4}>
        <DataTable
          columns={brandCustomerSalesColumns}
          message={
            brandCustomerSales?.length === 0 ? 'No results found.' : null
          }
          isLoadingList={isLoadingBrandCustomerSales}
          hasListNext={hasBrandCustomerSalesNext}
          listNext={listBrandCustomerSalesNext}
          query={brandCustomerSalesQuery}
          updateQuery={updateBrandCustomerSalesQuery}
          useWindowScrollListener
        >
          {brandCustomerSales?.map(sale => (
            <BrandCustomerSaleItem
              key={sale.id}
              sale={sale}
              navigateToOrder={navigateToOrder}
              navigateToProduct={navigateToProduct}
            />
          ))}
        </DataTable>
      </Box>
    </Layout>
  )
}

BrandCustomerDetails.defaultProps = {}

BrandCustomerDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(BrandCustomerDetails)
