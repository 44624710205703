const jssStyles = theme => ({
  dateRangePicker: {
    '& .MuiInputBase-root': {
      width: '100%',
      height: '48px !important',

      '& .MuiInputBase-input': {
        paddingTop: 14
      }
    }
  }
})

export default jssStyles
