export default {
  marginNormal: {
    marginTop: 8,
    marginBottom: 8
  },
  marginDense: {
    marginTop: 0,
    marginBottom: 0
  }
}
