import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import AutoSizer from 'react-virtualized-auto-sizer'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { priceLabel, getProductImage } from 'utils/products'
import {
  STOREFRONT_SECTION_TYPES,
  STOREFRONT_SECTION_TITLES
} from 'constants/general'
import DragScroll from 'components/DragScroll'

import iPhone12 from 'assets/iphone12.png'
import UnknownProduct from 'assets/unknown_product.jpg'

import styles, {
  BASE_HEIGHT_PX,
  BASE_WIDTH_PX
} from './StorefrontPreviewStyles'

const PREVENT_DRAG = {
  draggable: 'false',
  onDragStart: e => {
    e.preventDefault()
  }
}

const FAKE_PRODUCT = {
  images: [{ thumbnail_url: UnknownProduct }],
  brand: 'brand',
  title: 'Product',
  variants: [
    {
      price: 1
    }
  ]
}

const FAKE_PRODUCTS = [
  FAKE_PRODUCT,
  FAKE_PRODUCT,
  FAKE_PRODUCT,
  FAKE_PRODUCT,
  FAKE_PRODUCT
]

const ProductSlider = ({ classes, title, seeAll, products, scale }) => (
  <Box pt="80px">
    <Box className={classes.sectionHeader}>
      <Typography className={classes.textHeader}>{title}</Typography>
      {seeAll && (
        <Typography className={classes.textOverline}>SEE ALL &gt;</Typography>
      )}
    </Box>
    <DragScroll className={classes.sectionProducts} mt="45px" scale={scale}>
      {products?.map((product, idx) => (
        <Box key={idx} className={classes.sectionProduct}>
          <img
            src={getProductImage(product) ?? UnknownProduct}
            alt={product.title}
            {...PREVENT_DRAG}
          />
          <Typography className={classes.textOverline}>
            {product.brand}
          </Typography>
          <Typography className={classes.textHeader}>
            {product.title}
          </Typography>
          <Typography className={classes.textPrice}>
            {priceLabel(product)}
          </Typography>
        </Box>
      ))}
    </DragScroll>
  </Box>
)

ProductSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  seeAll: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  scale: PropTypes.number
}

const CollectionGroup = ({ classes, title, seeAll, collections, scale }) => (
  <Box pt="80px">
    <Box className={classes.sectionHeader}>
      <Typography className={classes.textHeader}>{title}</Typography>
      {seeAll && (
        <Typography className={classes.textOverline}>SEE ALL &gt;</Typography>
      )}
    </Box>
    <DragScroll className={classes.sectionProducts} mt="45px" scale={scale}>
      {collections?.map((collection, idx) => (
        <Box key={idx} className={classes.sectionProduct}>
          <img
            src={collection.image_url ?? UnknownProduct}
            alt={collection.title}
            {...PREVENT_DRAG}
          />
          <Typography className={classes.textHeader}>
            {collection.title}
          </Typography>
        </Box>
      ))}
    </DragScroll>
  </Box>
)

CollectionGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  seeAll: PropTypes.bool.isRequired,
  collections: PropTypes.arrayOf(PropTypes.object).isRequired,
  scale: PropTypes.number
}

const Section = ({ classes, section: { type, data }, scale }) => {
  switch (type) {
    case STOREFRONT_SECTION_TYPES.COLLECTION_SLIDER:
      return (
        <DragScroll
          pt="80px"
          scale={scale}
          className={classes.sectionCollections}
        >
          {data.map((collection, idx) => (
            <Box key={idx}>
              <img
                src={collection.image_url}
                alt={collection.title}
                {...PREVENT_DRAG}
              />
            </Box>
          ))}
        </DragScroll>
      )
    case STOREFRONT_SECTION_TYPES.COLLECTION_GROUP:
      return (
        <CollectionGroup
          classes={classes}
          title={data.title}
          seeAll={data.has_see_all_link ?? true}
          collections={data.collections}
          scale={scale}
        />
      )
    case STOREFRONT_SECTION_TYPES.PRODUCT_SLIDER:
      return (
        <ProductSlider
          classes={classes}
          title={data.title}
          seeAll={data.has_see_all_link ?? true}
          products={data.products}
          scale={scale}
        />
      )
    case STOREFRONT_SECTION_TYPES.BRAND_SLIDER:
      return (
        <Box pt="80px">
          <Box className={classes.sectionHeader} mb="50px">
            <Typography className={classes.textHeader}>
              {STOREFRONT_SECTION_TITLES[STOREFRONT_SECTION_TYPES.BRAND_SLIDER]}
            </Typography>
            <Typography className={classes.textOverline}>
              SEE ALL &gt;
            </Typography>
          </Box>
          <DragScroll scale={scale} className={classes.sectionCollections}>
            {data.map((brand, idx) => (
              <Box key={idx}>
                <img
                  src={brand.image_url ?? UnknownProduct}
                  alt={brand.title}
                  {...PREVENT_DRAG}
                />
              </Box>
            ))}
          </DragScroll>
        </Box>
      )
    case STOREFRONT_SECTION_TYPES.REFERRAL_CODE_BLOCK:
      return (
        <Box className={classes.sectionReferralCode}>
          <Typography className={classes.textHeader}>Referral Code</Typography>
        </Box>
      )
    case STOREFRONT_SECTION_TYPES.ORDER_AGAIN_BLOCK:
      return (
        <ProductSlider
          classes={classes}
          title={
            STOREFRONT_SECTION_TITLES[
              STOREFRONT_SECTION_TYPES.ORDER_AGAIN_BLOCK
            ]
          }
          seeAll
          products={FAKE_PRODUCTS}
          scale={scale}
        />
      )
    case STOREFRONT_SECTION_TYPES.RECOMMENDED_PRODUCTS_SLIDER:
      return (
        <ProductSlider
          classes={classes}
          title={
            STOREFRONT_SECTION_TITLES[
              STOREFRONT_SECTION_TYPES.RECOMMENDED_PRODUCTS_SLIDER
            ]
          }
          seeAll
          products={FAKE_PRODUCTS}
          scale={scale}
        />
      )
    default:
      return (
        <Box>
          <Typography className={classes.textHeader}>
            Unknown Section type, {type}
          </Typography>
        </Box>
      )
  }
}

Section.propTypes = {
  classes: PropTypes.object.isRequired,
  scale: PropTypes.number,
  section: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  })
}

const StorefrontPreview = ({ classes, storefront }) => {
  const SizedPreview = ({ width, height }) => {
    const scale = Math.min(width / BASE_WIDTH_PX, height / BASE_HEIGHT_PX)
    return (
      <Box width={width} height={height} position="relative">
        <Box
          className={classes.scaledView}
          style={{
            transform: `translate(-50%, -50%) scale(${scale})`
          }}
        >
          <Box className={classes.contentPadder}>
            <DragScroll className={classes.content} scale={scale}>
              {storefront.map((section, idx) => (
                <Section
                  key={idx}
                  section={section}
                  classes={classes}
                  scale={scale}
                />
              ))}
            </DragScroll>
          </Box>
          <img src={iPhone12} alt="iphone frame" className={classes.frame} />
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <AutoSizer>{SizedPreview}</AutoSizer>
    </Box>
  )
}

StorefrontPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  storefront: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default withStyles(styles)(StorefrontPreview)
