import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

import Dialog from 'components/Dialog'
import { AutocompleteSearchCollectionGroups } from 'components/AutocompleteSearchCollections'

const AddCollectionGroupDialog = ({
  title,
  subtitle,
  open,
  existingCollections,
  onClose,
  onConfirm
}) => {
  const [newCollectionGroups, setNewCollectionGroups] = useState([])
  const handleConfirm = () => {
    onConfirm(newCollectionGroups)
    setNewCollectionGroups([])
  }

  return (
    <Dialog
      title={title}
      subtitle={subtitle}
      open={open}
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmText="Add"
      confirmDisabled={newCollectionGroups.length === 0}
    >
      <Box height={200}>
        <AutocompleteSearchCollectionGroups
          value={newCollectionGroups}
          onChange={setNewCollectionGroups}
          hiddenCollections={existingCollections}
          multiple
        />
      </Box>
    </Dialog>
  )
}

AddCollectionGroupDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  existingCollections: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

export default AddCollectionGroupDialog
