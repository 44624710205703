const jssStyles = theme => ({
  modalActions: {
    borderTop: `1px solid ${theme.palette.colorsAF.semiGray}`,
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2)
  }
})

export default jssStyles
