const jssStyles = theme => ({
  queryList: {
    transition: 'all 0.5s ease',
    height: 200
  },
  queryListEmpty: {
    height: 0,
    transition: 'all 0.5s ease'
  },
  queryListItem: {
    display: 'flex',
    padding: theme.spacing(0, 1)
  }
})

export default jssStyles
