import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Typography, Box, TableRow, TableCell } from '@material-ui/core'

import { URL } from 'constants/navigation'
import { useAdminDistributionCenter } from 'hooks'
import { useTableRow } from 'templates'

const DistributionCenterItem = ({ supplierId, distributionCenter }) => {
  const distributionCenterId = distributionCenter.id
  const { destroyDistributionCenter } = useAdminDistributionCenter({
    distributionCenterId: distributionCenterId,
    supplierId,
    fetchingEnabled: false
  })

  const {
    ConfirmDeleteDialogCell,
    classes: tableRowClasses,
    navigateToItem
  } = useTableRow({
    itemUrl: `${URL.ADMIN_SUPPLIERS}/${supplierId}/distribution_centers`
  })

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const confirmationDialogCellProps = {
    open: confirmDeleteOpen,
    setOpen: setConfirmDeleteOpen,
    onConfirm: async () => {
      setConfirmDeleteOpen(false)
      await destroyDistributionCenter()
    },
    description:
      'Are you sure? This action also deletes ALL associated variants, which CANNOT be undone.'
  }

  return (
    <TableRow data-test="distribution-center-row">
      <TableCell>
        <Box onClick={e => navigateToItem(e, { id: distributionCenterId })}>
          <Typography variant="body1" className={tableRowClasses.link}>
            {distributionCenter?.distribution_center_locations?.[0]?.name ??
              distributionCenter?.name}
          </Typography>
        </Box>
      </TableCell>
      <ConfirmDeleteDialogCell {...confirmationDialogCellProps} />
    </TableRow>
  )
}

DistributionCenterItem.propTypes = {
  distributionCenter: PropTypes.object.isRequired
}

export default DistributionCenterItem
