import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { useNavigation } from 'hooks'
import { URL } from 'constants/navigation'

import PurchaseOrderStatus from 'components/PurchaseOrderStatus'

import styles from './TransferOrderStyles'

const DATE_NOT_SET = 'Not set'

const TransferOrderItem = ({ classes, transferOrder }) => {
  const {
    id,
    origin_location,
    destination_location,
    estimated_arrival_at,
    status,
    order_date
  } = transferOrder
  const { handleClick } = useNavigation({ url: URL.ADMIN_TRANSFER_ORDERS })
  return (
    <TableRow data-test="to-row">
      <TableCell>
        <Button
          color="primary"
          onClick={e => handleClick(e, transferOrder)}
          onAuxClick={e => handleClick(e, transferOrder, true)}
          data-test="to-item"
          className={classes.linkButton}
        >
          #{id}
        </Button>
      </TableCell>
      <TableCell>{origin_location?.display_name}</TableCell>
      <TableCell>{destination_location?.display_name}</TableCell>
      <TableCell>
        {order_date ? moment(order_date).format('L') : DATE_NOT_SET}
      </TableCell>
      <TableCell>
        {estimated_arrival_at
          ? moment(estimated_arrival_at).format('L')
          : DATE_NOT_SET}
      </TableCell>
      <TableCell>
        <PurchaseOrderStatus status={status} />
      </TableCell>
    </TableRow>
  )
}

TransferOrderItem.propTypes = {
  classes: PropTypes.object.isRequired,
  transferOrder: PropTypes.object.isRequired
}

export default withStyles(styles)(TransferOrderItem)
