import React from 'react'
import PropTypes from 'prop-types'

const DarkstoreIcon = ({ background, foreground, size, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="50" fill={background} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.6857 76C69.1309 76 76 69.0736 76 60.5582V40.4434C76 31.9264 69.1309 25 60.6857 25H40.3144C31.8691 25 25 31.9264 25 40.4434V48.9031C24.9979 49.5909 25.2687 50.2516 25.753 50.7401C26.2373 51.2286 26.8956 51.505 27.5835 51.5088H32.0508C33.4788 51.5088 34.6406 50.3518 34.6406 48.9588L34.647 39.7788C34.647 37.3946 37.0711 34.7267 40.3144 34.7267H60.6841C63.8078 34.7267 66.3514 37.291 66.3514 40.4418V60.5582C66.3514 63.6867 63.8429 66.2335 60.7494 66.2686H36.5706C35.8825 66.2719 35.2238 66.5482 34.7391 67.0368C34.2545 67.5253 33.9835 68.1862 33.9856 68.8743V73.3783C33.9856 74.8174 35.1331 75.9904 36.5149 75.9904L60.6857 76Z"
      fill={foreground}
    />
  </svg>
)

DarkstoreIcon.defaultProps = {
  background: 'black',
  foreground: 'white',
  size: 24
}

DarkstoreIcon.propTypes = {
  foreground: PropTypes.string,
  background: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
}

export default DarkstoreIcon
