import React from 'react'
import PropTypes from 'prop-types'

import { VariableSizeList } from 'react-window'

import ListSubheader from '@material-ui/core/ListSubheader'

const HEADER_HEIGHT_PX = 40
const ITEM_HEIGHT_PX = 40

// Adapter for react-window from https://material-ui.com/components/autocomplete
const renderRow = ({ data, index, style }) =>
  React.cloneElement(data[index], { style })

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef(({ onScroll, ...props }, ref) => {
  const { onScroll: outerOnScroll, ...outerProps } = React.useContext(
    OuterElementContext
  )
  const bothOnScroll = e => {
    onScroll && onScroll(e)
    outerOnScroll && outerOnScroll(e)
  }
  return <div ref={ref} {...props} {...outerProps} onScroll={bothOnScroll} />
})

OuterElementType.propTypes = {
  onScroll: PropTypes.func
}

function useResetCache(data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

const createGetChildSize = ({ headerHeight, itemHeight }) => child =>
  React.isValidElement(child) && child.type === ListSubheader
    ? headerHeight
    : itemHeight

const AutocompleteListBox = React.forwardRef(
  ({ children, headerHeight, itemHeight, ...other }, ref) => {
    const getChildSize = createGetChildSize({ headerHeight, itemHeight })
    const itemData = React.Children.toArray(children)
    const itemCount = itemData.length
    const height =
      itemCount > 8
        ? 8 * itemHeight
        : itemData.map(getChildSize).reduce((a, b) => a + b, 0)
    const gridRef = useResetCache(itemCount)
    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={height}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={index => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    )
  }
)

AutocompleteListBox.defaultProps = {
  itemHeight: ITEM_HEIGHT_PX,
  headerHeight: HEADER_HEIGHT_PX
}

AutocompleteListBox.propTypes = {
  children: PropTypes.node,
  itemHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  onScroll: PropTypes.func
}

export default AutocompleteListBox
