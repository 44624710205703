import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import startCase from 'lodash/startCase'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

import { formatCurrency } from 'utils/general'

import styles from './RefundDetailsStyles'

const PriceTag = ({
  classes,
  label,
  sublabel,
  isNegative,
  value,
  className
}) => (
  <ListItem
    key={label}
    className={classNames({
      [classes.item]: true,
      [className]: className
    })}
  >
    <Box>
      <Typography variant="body1">{label}</Typography>
    </Box>
    <Box flex={1} ml={2}>
      {sublabel && <Typography variant="body1">{sublabel}</Typography>}
    </Box>
    <Typography
      className={classNames({
        [classes.negativeTag]: isNegative
      })}
      variant="body1"
    >
      {formatCurrency(value)}
    </Typography>
  </ListItem>
)

PriceTag.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  isNegative: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sublabel: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

const RefundDetails = ({ classes, details }) => {
  const {
    subtotal,
    tax,
    storeCredit,
    creditRefunded,
    shipping,
    deliveryMethod,
    itemsCount,
    storeCreditRefund,
    paymentMethodRefund,
    discount,
    driverBenefits
  } = details

  return (
    <Box className={classes.root}>
      <Box className={classes.priceTagList}>
        <PriceTag
          classes={classes}
          label="Subtotal"
          sublabel={
            itemsCount ? `${itemsCount} item${itemsCount > 1 ? 's' : ''}` : ''
          }
          value={subtotal || 0}
        />

        <PriceTag classes={classes} label="Tax" value={tax || 0} />

        <PriceTag
          classes={classes}
          label="Delivery Fee"
          sublabel={startCase(deliveryMethod)}
          value={shipping}
        />

        {parseFloat(driverBenefits, 10) > 0 && (
          <PriceTag
            classes={classes}
            label="Driver Benefits & Fuel Support Fee"
            value={driverBenefits}
          />
        )}

        <PriceTag classes={classes} label="Store Credit" value={storeCredit} />

        <PriceTag
          classes={classes}
          label="Store Credit Refunded"
          value={creditRefunded}
        />

        <PriceTag
          classes={classes}
          label="Discount"
          value={discount}
          isNegative
        />

        <Box className={classes.totals}>
          <PriceTag
            classes={classes}
            label="Total Store Credit Refund"
            value={storeCreditRefund || 0}
          />
          <PriceTag
            classes={classes}
            label="Total Payment Method Refund"
            value={paymentMethodRefund || 0}
          />
        </Box>
      </Box>
    </Box>
  )
}

RefundDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  details: PropTypes.object
}

export default withStyles(styles)(RefundDetails)
