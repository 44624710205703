import React from 'react'

const CheckThinIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5423 4.72402C15.8202 5.02272 15.8202 5.50701 15.5423 5.80571L6.98794 15L2.45916 10.1324C2.18126 9.83373 2.18126 9.34945 2.45916 9.05075C2.73707 8.75205 3.18765 8.75205 3.46556 9.05075L6.98794 12.8366L14.5359 4.72402C14.8138 4.42533 15.2644 4.42533 15.5423 4.72402Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2614 4.46858C14.6876 4.01047 15.3906 4.01047 15.8168 4.46858C16.2287 4.91124 16.2287 5.61849 15.8168 6.06114L6.98794 15.5505L2.18462 10.3879C1.77277 9.94522 1.77277 9.23797 2.18462 8.79531C2.61084 8.3372 3.31388 8.3372 3.74011 8.79531L6.98794 12.2861L14.2614 4.46858ZM15.2678 4.97946C15.1382 4.84018 14.94 4.84018 14.8105 4.97946L6.98794 13.3872L3.19101 9.30619C3.06142 9.16691 2.8633 9.16691 2.73371 9.30619C2.58974 9.46093 2.58974 9.72225 2.73371 9.87699L6.98794 14.4495L15.2678 5.55027C15.4117 5.39553 15.4117 5.1342 15.2678 4.97946Z"
      fill="white"
    />
  </svg>
)

export default CheckThinIcon
