import { LIST_PATHS } from 'constants/queryParams'
import { useResourceRQ, useResourceListRQ } from 'hooks'
import {
  subCategoriesEndpoint,
  subCategoriesActiveEndpoint
} from 'api/endpoints'

const API_RESOURCE_NAME = 'subcategory'
const ACTIVE_STATUS_PATH = `${LIST_PATHS.SUBCATEGORIES_STATUS}=true`

const useAdminSubcategories = params => {
  const {
    isFetching: isLoadingSubcategories,
    data: subcategories,
    hasData: hasSubcategories,
    hasNextPage: hasSubcategoriesNext,
    count: subcategoriesCount,
    fetchNextPage: listSubcategoriesNext
  } = useResourceListRQ({ endpoint: subCategoriesEndpoint, params })

  const { count: activeSubcategoriesCount } = useResourceListRQ({
    endpoint: subCategoriesActiveEndpoint,
    args: [ACTIVE_STATUS_PATH]
  })

  const {
    create: createSubcategory,
    update: updateSubcategory
  } = useResourceRQ({
    endpoint: subCategoriesEndpoint,
    name: API_RESOURCE_NAME,
    activeLoaders: true,
    showServerError: true,
    onSuccess: {
      updateMsg: () => 'Category successfully updated',
      createMsg: () => 'Category successfully created'
    },
    dependents: [
      () => subCategoriesEndpoint(),
      () => subCategoriesActiveEndpoint(ACTIVE_STATUS_PATH)
    ],
    fetchingEnabled: false
  })

  return {
    isLoadingSubcategories,
    subcategories,
    hasSubcategories,
    hasSubcategoriesNext,
    subcategoriesCount,
    listSubcategoriesNext,
    createSubcategory,
    updateSubcategory,
    activeSubcategoriesCount
  }
}

export default useAdminSubcategories
