import isEmpty from 'lodash/isEmpty'

import { RECORD_TYPE, RECORD_TYPE_GET_PARAM } from 'constants/inventoryRecords'

// PATH ROOTS

const ROOT = '/api'
const V1 = `${ROOT}/v1`
const V2 = `${ROOT}/v2`
const ADMIN = `${V1}/admin`
const ADMIN_V2 = `${V2}/admin`

// HELPERS

// when arg is truthy encode it and prepend with a slash otherwise return ''
export const when = arg => (arg ? `/${encodeURIComponent(arg)}` : '')
export const whenQuery = arg => (arg ? `?${arg}` : '')
export const fullPath = path => ADMIN + path
export const fullPathV2 = path => ADMIN_V2 + path
export const setQueryParams = ({ path, params }) =>
  isEmpty(params) ? path : `${path}?${new URLSearchParams(params).toString()}`

// INVENTORY RECORDS FOR PURCHASE ORDERS
const inventoryRecord = (path, type = RECORD_TYPE.PURCHASE_ORDER) =>
  setQueryParams({ path, params: { [RECORD_TYPE_GET_PARAM]: type } })

const purchaseOrderPath = id => fullPath(`/purchase_orders${when(id)}`)
const purchaseOrderListPath = purchaseOrderPath

export const purchaseOrderEndpoint = (id, type) =>
  inventoryRecord(`${purchaseOrderPath(id)}`, type)
export const purchaseOrderHistoryEndpoint = (id, type) =>
  inventoryRecord(`${purchaseOrderPath(id)}/purchase_order_history`, type)
export const purchaseOrderExportEndpoint = id =>
  `${purchaseOrderPath(id)}/export`
export const purchaseOrderListEndpoint = type =>
  inventoryRecord(purchaseOrderListPath(), type)
export const purchaseOrderSummaryEndpoint = type =>
  inventoryRecord(`${purchaseOrderListPath()}/summary`, type)
export const purchaseOrderCsvImportEndpoint = id =>
  `${purchaseOrderPath(id)}/import_variants`
export const purchaseOrderVariantsEndpoint = id =>
  `${purchaseOrderPath(id)}/variants`
export const purchaseOrderVariantsUpdateEndpoint = (id, variant) =>
  `${purchaseOrderPath(id)}/purchase_order_variants/${variant.id}`
export const purchaseOrderVariantsReceiveEndpoint = (id, variant) =>
  `${purchaseOrderPath(id)}/purchase_order_variants/${variant.id}/receive`

// TRANSFER ORDER SUPPORT FOR NEW ENDPOINTS
const transferOrderPath = id => fullPath(`/transfer_orders${when(id)}`)
const transferOrderListPath = transferOrderPath

export const transferOrderEndpoint = id => transferOrderPath(id)
export const transferOrderHistoryEndpoint = id =>
  `${transferOrderPath(id)}/transfer_order_history`
export const transferOrderExportEndpoint = id =>
  `${transferOrderPath(id)}/export`
export const transferOrderListEndpoint = () => transferOrderListPath()
export const transferOrderSummaryEndpoint = () =>
  `${transferOrderListPath()}/summary`
export const transferOrderCsvImportEndpoint = id =>
  `${transferOrderPath(id)}/import_variants`
export const transferOrderShipmentItemsEndpoint = id =>
  `${transferOrderPath(id)}/shipment_items`
export const transferOrderSubmitEndpoint = id =>
  `${transferOrderPath(id)}/submit`
export const transferOrderCloseEndpoint = id => `${transferOrderPath(id)}/close`

// VARIANT ENDPOINT
export const variantEndpoint = idOrSku => fullPath(`/variants${when(idOrSku)}`)
export const variantGenDsinEndpoint = id => `${variantEndpoint(id)}/gen_dsin`
export const variantBulkUpdateEndpoint = id =>
  `${variantEndpoint(id)}/bulk_update`
export const variantPrintLabelEndpoint = sku =>
  `${variantEndpoint(sku)}/print_label`
export const variantListEndpoint = () => variantEndpoint()
export const variantCsvImportEndpoint = () =>
  `${variantEndpoint()}/import_prices`

// VARIANT_LOCATIONS ENDPOINT
export const variantLocationsBulkUpdateEndpoint = () =>
  fullPath('/variant_locations/bulk_update')

// PURCHASE ORDER, TRANSFER ORDER VARIANTS
export const variantPurchaseOrderListEndpoint = () =>
  fullPath('/variants/po_index')
export const variantTransferOrderListEndpoint = () =>
  fullPath('/variants/to_index')

// BRANDS
export const brandEndpoint = id => fullPath(`/brands${when(id)}`)

// COLLECTIONS
export const collectionEndpoint = id => fullPath(`/collections${when(id)}`)

export const collectionBulkUpdateEndpoint = () =>
  fullPath('/collections/bulk_update_status')

export const collectionCloneEndpoint = ({ collectionId }) =>
  fullPath(`/collections${when(collectionId)}/clone`)

export const locationCollectionEndpoint = (collectionId, locationId) =>
  fullPath(`/collections/${collectionId}/locations/${locationId}`)

// COLLECTION GROUPS
export const collectionGroupEndpoint = id =>
  fullPath(`/collection_groups${when(id)}`)
export const collectionGroupListEndpoint = () => fullPath(`/collection_groups`)

// PRODUCTS
export const productsListEndpoint = () => fullPath('/products')

// PROMO CODES
const promoCodesPath = id => fullPath(`/discount_codes${when(id)}`)
export const promoCodesCsvImportEndpoint = id =>
  `${promoCodesPath(id)}/import_eligible_user_ids_csv`

// SIZES
export const sizesEndpoint = id => fullPath(`/sizes${when(id)}`)
export const sizesActiveEndpoint = query =>
  fullPath(`/sizes${whenQuery(query)}`)

// COLORWAYS
export const masterColorsEndpoint = () => fullPath('/master_colors')
export const colorsEndpoint = colorId => fullPath(`/colors${when(colorId)}`)
export const colorsEndpointV2 = () => fullPathV2('/colors')

// BADGES
export const masterBadgeEndpoint = badgeId =>
  fullPath(`/badges${when(badgeId)}`)
export const masterBadgeActiveEndpoint = query =>
  fullPath(`/badges${whenQuery(query)}`)

// STATUS CODES
export const statusCodesEndpoint = codeId =>
  fullPath(`/status_codes${when(codeId)}`)
export const statusCodesActiveEndpoint = query =>
  fullPath(`/status_codes${whenQuery(query)}`)

// ACCESSORY OPTIONS
export const accesoryOptionsEndpoint = id =>
  fullPath(`/accessory_options${when(id)}`)
export const accesoryOptionsActiveEndpoint = query =>
  fullPath(`/accessory_options${whenQuery(query)}`)

// CATEGORIES
export const categoriesEndpoint = id => fullPath(`/categories${when(id)}`)
export const categoriesActiveEndpoint = query =>
  fullPath(`/categories${whenQuery(query)}`)

export const subCategoriesEndpoint = id => fullPath(`/subcategories${when(id)}`)
export const subCategoriesActiveEndpoint = query =>
  fullPath(`/subcategories${whenQuery(query)}`)

// DELIVERED ORDERS
export const deliveredOrderListEndpoint = () => fullPath(`/delivered_orders`)

// ORDERS
const orderPath = id => fullPath(`/orders${when(id)}`)
const orderReturnsPath = id => `${orderPath(id)}/order_returns`
const orderReturnPath = (orderId, returnId) =>
  `${orderReturnPath(orderId)}/${returnId}`

export const orderEndpoint = id => orderPath(id)
export const ordersEndpoint = () => orderPath()
export const gmvEndpoint = () => `${orderPath()}/gmv`
export const orderHistoryEndpoint = id => `${orderPath(id)}/order_history`

export const orderReturns = orderId => `${orderReturnsPath(orderId)}`
export const orderMarkAsReturnedEndpoint = (orderId, returnId) =>
  `${orderReturnsPath(orderId)}/${returnId}/mark_as_returned`
export const orderReturnEndpoint = (orderId, returnId) =>
  orderReturnPath(orderId, returnId)

// LOCATIONS
export const locationEndpoint = locationId =>
  fullPath(`/locations${when(locationId)}`)
export const locationPrintersEndpoint = (locationId, printerId) =>
  `${locationEndpoint(locationId)}/printers${when(printerId)}`

// DELIVERY WINDOWS
export const deliveryWindowsEndpoint = locationId =>
  `${locationEndpoint(locationId)}/delivery_windows`

// DEEP LINKING
export const deepLinksEndpoint = () => fullPath('/deep_links')

// NOTIFICATIONS
export const notificationsEndpoint = () => fullPath('/notifications')

// PROMOTIONS
export const promotionsEndpoint = id => fullPath(`/discounts${when(id)}`)

// SUPPLIERS
export const supplierListEndpoint = () => fullPath(`/suppliers`)
export const supplierEndpoint = id => `${supplierListEndpoint()}${when(id)}`

export const distributionCenterListEndpoint = supplierId =>
  `${supplierEndpoint(supplierId)}/distribution_centers`
export const distributionCenterEndpoint = (supplierId, distributionCenterId) =>
  `${distributionCenterListEndpoint(supplierId)}${when(distributionCenterId)}`

export const distributionCenterVariantsListEndpoint = (
  supplierId,
  distributionCenterId
) =>
  `${distributionCenterEndpoint(
    supplierId,
    distributionCenterId
  )}/distribution_center_variants`
export const distributionCenterVariantEndpoint = (
  supplierId,
  distributionCenterId,
  variantId
) =>
  `${distributionCenterVariantsListEndpoint(
    supplierId,
    distributionCenterId
  )}${when(variantId)}`

// PLATES
export const plateEndpoint = id => fullPath(`/plates${when(id)}`)
export const plateListEndpoint = () => fullPath('/plates')
export const plateBulkUpdateEndpoint = () => fullPath('/plates/bulk_update')
export const plateBulkDeactivateEndpoint = () =>
  fullPath('/plates/bulk_deactivate')
export const plateTransferEndpoint = id => fullPath(`/plates/${id}/transfer`)

// INVENTORY
export const inventoryHistoryEndpoint = id =>
  fullPath(`/inventorys/${id}/inventory_history`)

// STOREFRONTS
export const storefrontsEndpoint = id => fullPath(`/storefronts${when(id)}`)

// INTERNAL USERS
export const internalUserEndpoint = id => fullPath(`/internal_users${when(id)}`)
