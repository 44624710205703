import React from 'react'
import { Typography, TableRow, TableCell, Link } from '@material-ui/core'

import { useNavigation } from 'hooks'
import { useLocationsContext } from 'context'

import { INTERNAL_USER_ROLE_LABELS } from 'constants/internalUsers'
import { URL } from 'constants/navigation'

const InternalUserItem = ({ internalUser }) => {
  const { handleClick: handleInternalUserClick } = useNavigation({
    url: URL.ADMIN_INTERNAL_USERS
  })

  const { locationId, getLocationAtId } = useLocationsContext()

  const location = getLocationAtId(internalUser.default_location_id)

  return (
    <TableRow onClick={e => handleInternalUserClick(e, internalUser)}>
      <TableCell>
        <Typography variant="body1">{internalUser.id}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {internalUser.first_name} {internalUser.last_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{internalUser.email}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {INTERNAL_USER_ROLE_LABELS[internalUser.role]}
        </Typography>
      </TableCell>
      {!locationId && (
        <TableCell>
          <Typography variant="body1">{location.name}</Typography>
        </TableCell>
      )}
    </TableRow>
  )
}

export default InternalUserItem
