const jssStyles = theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  content: {
    color: theme.palette.grays.dark,
    overflow: 'auto',
    lineHeight: '19px',
    whiteSpace: 'pre-line',
    fontWeight: 400
  },
  contentEdit: {
    flex: 1,
    width: '100%'
  },
  input: {
    padding: 0,

    '&.MuiInputBase-root': {
      height: 'auto',

      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(2)
      }
    }
  },
  contentInfo: {
    fontSize: 11
  },
  charCount: {
    textAlign: 'right',
    color: theme.palette.grays.med
  },
  maxCount: {
    color: theme.palette.warnings.error
  },
  memoContainer: {
    border: `1px solid ${theme.palette.grays.med}`,
    padding: theme.spacing(2)
  },
  errorContainer: {
    borderColor: theme.palette.error.main
  }
})

export default jssStyles
