import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import { SIZE, COLOR, BUTTON_VARIANT, TOOLTIP_VARIANT } from 'constants/enums'
import * as propTypes from 'constants/propTypes'
import { useMediaQuery } from 'hooks'

import Button from 'components/Button'
import Tooltip from 'components/Tooltip'

import styles from './ConfirmStyles'

const Confirm = ({
  classes,
  title,
  description,
  children,
  open,
  onClose,
  onConfirm,
  cancelText,
  confirmText,
  placement
}) => {
  const { isMobileScreen } = useMediaQuery()
  return (
    <Tooltip
      title={title || 'Confirm Action'}
      classes={{ description: classes.tooltipDescription }}
      description={description}
      actions={
        <Box display="flex">
          <Button
            color={COLOR.default}
            size={SIZE.small}
            variant={BUTTON_VARIANT.outlined}
            onClick={onClose}
            dataTest="deny-button"
          >
            {cancelText || 'Nope'}
          </Button>
          <Box width={8} />
          <Button
            color={COLOR.primary}
            size={SIZE.small}
            onClick={onConfirm}
            dataTest="confirm-button"
          >
            {confirmText || 'Yeah'}
          </Button>
        </Box>
      }
      placement={isMobileScreen ? 'bottom' : placement}
      variant={TOOLTIP_VARIANT.light}
      elevated
      open={open}
      onClose={onClose}
      className={classes.root}
    >
      <Box>{children}</Box>
    </Tooltip>
  )
}

Confirm.propTypes = {
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  placement: propTypes.tooltipPlacements
}

export default withStyles(styles)(Confirm)
