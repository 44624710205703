import React, { useEffect, useState, useRef, useMemo } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DayPickerRangeController } from 'react-dates'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import { DAY_STRING_FORMAT } from 'constants/general'

import styles from './DayRangePickerStyles'

const DayRangePicker = ({
  classes,
  serviced,
  indicatedDays,
  onSelectDates
}) => {
  const [startDateValue, setStartDateValue] = useState(null)
  const [endDateValue, setEndDateValue] = useState(null)
  const [focusedInputValue, setFocusedInputValue] = useState('startDate')
  const [pickRange, setPickRange] = useState(false)
  const [daySize, setDaySize] = useState(0)
  const calendarContainer = useRef(null)

  const handleResize = () => {
    setDaySize(parseInt(calendarContainer.current?.offsetWidth / 8, 10))
  }

  useEffect(() => {
    handleResize()
  }, [calendarContainer])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDateValue(startDate)
    setEndDateValue(endDate)
    onSelectDates(
      startDate && startDate.format(DAY_STRING_FORMAT),
      endDate && endDate.format(DAY_STRING_FORMAT)
    )
  }

  const handleStartOffset = day => day.startOf('day')
  const handleEndOffset = day => day.endOf('day')

  const containerHeight = useMemo(() => daySize * 7 + 80, [daySize])

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end">
        <FormControlLabel
          control={
            <Switch
              checked={pickRange}
              onChange={e => {
                setPickRange(!pickRange)
                handleDatesChange({ startDate: null, endDate: null })
              }}
              name="Pick Range"
              color="primary"
              data-test="DayRangePicker-PickRange-switch"
            />
          }
          label="Pick Range"
        />
      </Box>
      <Box ref={calendarContainer} height={containerHeight}>
        <DayPickerRangeController
          startDate={startDateValue}
          endDate={endDateValue}
          onDatesChange={handleDatesChange}
          focusedInput={focusedInputValue}
          onFocusChange={focusedInput =>
            setFocusedInputValue(!focusedInput ? 'startDate' : focusedInput)
          }
          initialVisibleMonth={() => moment().add(0, 'M')}
          renderDayContents={day => (
            <Box
              className={classNames({
                [classes.today]: day.isSame(moment(), 'day'),
                [classes.day]: true,
                'DayRangePicker-day-test': true
              })}
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <Box>{day.format('D')}</Box>
              <Box display="flex" justifyContent="center" height={8}>
                {day.format(DAY_STRING_FORMAT) in indicatedDays ? serviced : ''}
              </Box>
            </Box>
          )}
          startDateOffset={!pickRange && handleStartOffset}
          endDateOffset={!pickRange && handleEndOffset}
          minimumNights={0}
          hideKeyboardShortcutsPanel
          noBorder
          daySize={daySize}
          firstDayOfWeek={1}
        />
      </Box>
    </Box>
  )
}

DayRangePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  indicatedDays: PropTypes.object,
  serviced: PropTypes.object,
  onSelectDates: PropTypes.func
}

export default withStyles(styles)(DayRangePicker)
