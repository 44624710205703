import React, { useMemo, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import MuiSelect from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

import { MdFiberNew, MdAttachMoney } from 'react-icons/md'
import { colorsAF } from 'theme/colors'
import CryptoMerchIcon from 'icons/CryptoMerchIcon'
import CryptoPaymentIcon from 'icons/CryptoPaymentIcon'

import { FIELD_PATHS } from 'constants/queryParams'
import { isMiddleButtonClicked } from 'utils/general'
import {
  SIZE,
  COLOR,
  BUTTON_VARIANT,
  TOOLTIP_PLACEMENT,
  PRINTER_TYPES
} from 'constants/enums'
import {
  useAdminOrders,
  useMediaQuery,
  useLoaders,
  useNavigation,
  useAdminPrinters,
  useLocalStorage
} from 'hooks'
import { useAuthContext } from 'context'
import { orderWritableTransitions } from 'utils/policies'
import { REFRESH_INTERVAL_OPTIONS } from 'constants/general'
import { URL } from 'constants/navigation'

import { TitleElement } from 'components/SiteTitle'
import Block from 'components/Block'
import Button from 'components/Button'
import ChevronDownIcon from 'icons/ChevronDownIcon'
import CustomerDeliveryPhoto from 'components/CustomerDeliveryPhoto'
import CustomerDetails from 'components/CustomerDetails'
import Dialog from 'components/Dialog'
import Header from 'components/Header'
import KebabButton from 'components/KebabButton'
import Layout from 'components/Layout'
import NotesContainer from 'components/NotesContainer'
import OrderItems from 'components/OrderItems'
import OrderMap from 'components/OrderMap'
import OrderStepper from 'components/OrderStepper'
import PaymentDetails from 'components/PaymentDetails'
import RefundedItems from 'components/RefundedItems'
import ReturnProgress from 'components/ReturnProgress'
import Select from 'components/Select'
import ShippingDetails from 'components/ShippingDetails'
import StatusTags from 'components/StatusTags'
import Tooltip from 'components/Tooltip'

import FlagIcon from 'icons/FlagIcon'
import GiftIcon from 'icons/GiftIcon'

import InfoIcon from 'assets/info.webp'

import styles from './OrderShowStyles'

// currently on the backend, the red flag for shared addresses looks like
// https://github.com/darkstoregh/fastaf-rails/blob/staging/app/jobs/order_created_job.rb#L70
// so we split the string array, get the items within, and then split them into an array of
// the order ids, which can then eb mapped over below in the list.
// flags look like FLAG_TYPE: [ORDER_NUMBERS]
const AbuseFlag = ({ flag }) => {
  const flagType = flag.split(':')[0]
  const orderNumbers = flag
    .split('[')
    .pop()
    .split(']')
    .filter(Boolean)[0]
    .split(',')
  return (
    <Box mb={2}>
      <strong>{flagType}</strong> for Orders #s:
      <ul>
        {orderNumbers?.map(num => (
          <li key={num}>#{num}</li>
        ))}
      </ul>
    </Box>
  )
}

AbuseFlag.propTypes = {
  flag: PropTypes.string.isRequired
}

const OrderShow = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const { currentUser } = useAuthContext()
  const history = useHistory()
  const [orderRefreshInterval, setOrderRefreshInterval] = useLocalStorage(
    'FASTAF_OPTS_ORDER_SHOW_REFRESH_INTERVAL',
    300000
  )

  const { go } = useNavigation({
    url: URL.ADMIN_ORDERS
  })

  // TODO: local storage refresh interval
  const {
    order,
    refetchOrder,
    updateOrder,
    printLabel,
    blockAddress
  } = useAdminOrders({ id, refreshInterval: orderRefreshInterval })

  const { printers } = useAdminPrinters(order?.location?.id)
  const labelPrinters = printers?.filter(p => p.type === PRINTER_TYPES.LABEL)

  const [blockAddressDialogOpen, setBlockAddressDialogOpen] = useState(false)
  const handleBlockAddress = () => {
    setBlockAddressDialogOpen(false)
    blockAddress(id)
  }

  const { handleShowClick: handleProductShowClick } = useNavigation({
    url: URL.ADMIN_PRODUCTS
  })

  const { handleClick: handleUserClick } = useNavigation({
    url: URL.ADMIN_USERS
  })

  const { showLoading, hideLoading } = useLoaders()

  // -- begin smart breadcrumbs
  const { state: locationState } = useLocation()
  const userName =
    order?.user?.name ?? `${order?.first_name} ${order?.last_name}`
  const userId = order?.user?.id
  const breadcrumbMap = {
    fromBrandDashboard: [
      {
        title: 'Customers',
        link: `${URL.ADMIN_BRANDS}/${id}?customerTab`
      },
      {
        title: userName,
        link: `${URL.ADMIN_BRAND_CUSTOMERS}/${userId}`
      }
    ],
    fromUserDetails: [
      {
        title: 'Users',
        link: URL.ADMIN_USERS
      },
      {
        title: userName,
        link: `${URL.ADMIN_USERS}/${userId}`
      }
    ],
    default: [{ title: 'Orders', link: URL.ADMIN_ORDERS }]
  }

  // -- endsmart breadcrumbs

  const { isDesktopScreen, isTabletScreen, isMobileScreen } = useMediaQuery()

  const containerClasses = classNames({
    [classes.container]: true,
    [classes.isMobile]: Boolean(isMobileScreen),
    [classes.isTablet]: Boolean(isTabletScreen),
    [classes.isDesktop]: Boolean(isDesktopScreen)
  })

  const handleUpdateStatus = async status => updateOrder({ status }, order)

  const handleUpdateNotes = async delivery_instructions =>
    updateOrder({ delivery_instructions }, order)

  const handleUpdateTrackingLink = async tracking_link =>
    updateOrder({ tracking_link }, order)

  const handleLineItemClick = (e, lineItem) => {
    handleProductShowClick(
      e,
      {
        id: lineItem?.product?.id
      },
      isMiddleButtonClicked(e)
    )
  }

  const orderStateOptions = currentUser
    ? orderWritableTransitions(currentUser)
    : []

  const handleRefresh = async () => {
    showLoading()
    await refetchOrder(id)
    hideLoading()
  }

  const freeReturn = useMemo(() => order?.total_price === '0.00', [order])
  const returnInProgress =
    order?.order_returns?.length &&
    order?.order_returns?.some(orderReturn => !orderReturn.returned)

  const actions = (
    <>
      <KebabButton>
        {labelPrinters?.map(p => (
          <MenuItem key={p.id} onClick={() => printLabel(id, p.id)}>
            Print Label ({p.name} Printer)
          </MenuItem>
        ))}
        <MenuItem onClick={() => setBlockAddressDialogOpen(true)}>
          Block Address
        </MenuItem>
        <MenuItem
          onClick={e => go(e, `${URL.ADMIN_ORDERS}/${order?.id}/history`)}
          data-test="po-history-menu-item"
        >
          History
        </MenuItem>
      </KebabButton>
      <Button
        size={SIZE.medium}
        color={COLOR.secondary}
        variant={BUTTON_VARIANT.contained}
        label="Refund"
        onClick={() => history.push(`${URL.ADMIN_ORDERS}/${id}/refund`)}
        disabled={freeReturn || returnInProgress}
      >
        Refund
      </Button>
      {!isEmpty(order?.returnable_line_items) && (
        <Button
          size={SIZE.medium}
          color={COLOR.secondary}
          variant={BUTTON_VARIANT.contained}
          label="Return"
          onClick={() => history.push(`${URL.ADMIN_ORDERS}/${id}/return`)}
        >
          Return
        </Button>
      )}
    </>
  )

  const orderNumber = `#${order?.number}`

  const paymentAmount = order?.total_price - order?.credit_applied

  const carrierTrackingLink = ({ tracking_code, carrier_account }) =>
    carrier_account.carrier_name === 'UPS'
      ? `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${tracking_code}`
      : 'https://www.gls-us.com/track-and-trace'

  const trackingHref = order?.shipment?.tracking_code
    ? carrierTrackingLink(order.shipment)
    : false

  return (
    <Layout>
      {!isEmpty(order) && (
        <Box className={containerClasses}>
          <TitleElement title={`Order ${orderNumber}`} />
          <Header
            breadcrumbs={breadcrumbMap[locationState || 'default']}
            title={
              <>
                {orderNumber}
                <Box ml={1} component="span" position="relative" top="2px">
                  {order?.user_first_order && <MdFiberNew fontSize={26} />}
                  {order?.crypto_used_as_payment && (
                    <CryptoPaymentIcon size={26} />
                  )}
                  {order?.crypto_merch && <CryptoMerchIcon />}
                  {parseFloat(order?.total_price, 10) > 250 && (
                    <MdAttachMoney fontSize={26} />
                  )}
                  {!isEmpty(order?.gift_detail) && <GiftIcon fontSize={26} />}
                </Box>
              </>
            }
            actions={actions}
            actionBox
          />
          <Box
            display="flex"
            gridGap={12}
            alignItems="center"
            justifyContent="space-between"
            mt={2}
          >
            <Box display="flex" alignItems="center">
              <StatusTags order={order} />
            </Box>
            <Select
              minimal
              name="order_refetch_interval"
              innerLabel="Refresh Interval"
              value={orderRefreshInterval}
              items={REFRESH_INTERVAL_OPTIONS}
              onChange={e => setOrderRefreshInterval(e.target.value)}
              dataTest="order_refetch_interval"
            />
          </Box>
          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid item sm={12} md={8} className={classes.gridItem}>
              <Block
                className={classNames({
                  [classes.firstOrder]: order?.user_first_order
                })}
              >
                <Grid container>
                  <Grid item sm={12} md={6} className={classes.gridMapItem}>
                    <Box
                      width="100%"
                      height="100%"
                      minHeight={['40vh', '30vh']}
                    >
                      <OrderMap order={order} />
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={6} className={classes.gridItem}>
                    <Box className={classes.paddingBlock}>
                      <OrderStepper order={order} />
                      <Box className={classes.divider} my={3} />
                      <ShippingDetails order={order} />
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body1"
                          className={classes.manualUpdateLabel}
                        >
                          Manual Update
                        </Typography>
                        <MuiSelect
                          className={classes.select}
                          value={order?.status}
                          input={<Input disableUnderline />}
                          IconComponent={ChevronDownIcon}
                          data-test="order-detail-select-status"
                        >
                          {orderStateOptions.map((state, idx) => (
                            <MenuItem
                              key={idx}
                              onClick={e => handleUpdateStatus(state.value)}
                              value={state.value}
                              data-test={`order-detail-select-status-${state.value}`}
                            >
                              {state.label}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box className={classes.divider} />
                <Box className={classes.paddingBlock}>
                  <Box display="flex" alignItems="center" position="relative">
                    <Typography data-test="order-details" variant="h5">
                      Order Items
                    </Typography>

                    {paymentAmount && paymentAmount < 0.5 && (
                      <>
                        <Box ml={2} />
                        <Tooltip
                          title={`Due to Stripe limitations, customer was not charged. No refunds can be made. In-app, the customer sees $${paymentAmount}`}
                          placement={TOOLTIP_PLACEMENT.top}
                          className={classes.tooltip}
                        >
                          <span
                            className={classes.infoSpan}
                            data-test="stripe-limitations-icon"
                          >
                            <img src={InfoIcon} alt="info" />
                          </span>
                        </Tooltip>
                      </>
                    )}
                  </Box>

                  <Box className={classes.productsContainer}>
                    {order?.line_items?.map((lineItem, idx) => (
                      <OrderItems
                        key={lineItem.id}
                        item={lineItem}
                        showQuantity
                        showSku
                        onClick={e => handleLineItemClick(e, lineItem)}
                        isOdd={idx % 2 !== 0}
                      />
                    ))}
                  </Box>
                </Box>
                {Boolean(order?.order_returns?.length) &&
                  order?.order_returns?.map(orderReturn => (
                    <Box className={classes.paddingBlock} key={orderReturn.id}>
                      <ReturnProgress
                        order={order}
                        onUpdate={handleRefresh}
                        freeReturn={freeReturn}
                        orderReturn={orderReturn}
                      />
                    </Box>
                  ))}
                {Boolean(order?.refunds?.length) &&
                  order?.refunds?.map(refund => (
                    <Box className={classes.paddingBlock} key={refund.id}>
                      <RefundedItems order={order} refund={refund} />
                      <Box className={classes.divider} mt={2} />
                    </Box>
                  ))}
                <Box>
                  <Typography
                    data-test="Payment"
                    variant="h5"
                    className={classes.paddingBlock}
                  >
                    Payment
                  </Typography>
                  <PaymentDetails order={order} />
                </Box>
              </Block>
            </Grid>
            <Grid item sm={12} md={4} className={classes.gridItem}>
              {!isEmpty(order?.gift_detail) && (
                <Block title="Gift Details" withPadding>
                  {[
                    {
                      title: 'Message',
                      text: order?.gift_detail.message
                    },
                    {
                      title: 'Recipient',
                      text: `${order?.gift_detail.recipient_first_name} ${order?.gift_detail.recipient_last_name}`
                    },
                    {
                      title: 'Phone',
                      text: order?.gift_detail.recipient_phone
                    }
                  ].map(({ title, text }) => (
                    <Box mb={1}>
                      <Box mb={0.25}>
                        <Typography variant="caption">{title}</Typography>
                      </Box>
                      <Typography variant="body1">{text}</Typography>
                    </Box>
                  ))}
                </Block>
              )}
              <Block title="Customer Details" withPadding>
                <CustomerDetails user={order?.user} onClick={handleUserClick} />

                {Boolean(order?.promo_code_red_flags?.length) && (
                  <Box mt={4}>
                    <Box className={classes.divider} mb={3} />
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Box mr={1}>
                        <FlagIcon fill={colorsAF.danger} />
                      </Box>
                      <Typography variant="h5">
                        Potential Promo Code Abuse
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      {order?.promo_code_red_flags?.map(flag => (
                        <Typography variant="body1">
                          <AbuseFlag flag={flag} />
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                )}
              </Block>
              <Block autoHeight>
                <Box className={classes.paddingBlock}>
                  <NotesContainer
                    order={order}
                    onSubmit={handleUpdateNotes}
                    heading="Notes"
                    attr="delivery_instructions"
                    isVisibleToCustomers={true}
                  />
                </Box>
              </Block>
              {order?.shipment && (
                <Block autoHeight title="Shipment">
                  <Box className={classes.paddingBlock}>
                    <Typography className={classes.caption} variant="caption">
                      Carrier
                    </Typography>
                    <Typography variant="body1">
                      {order?.shipment?.carrier_account?.carrier_name}
                    </Typography>
                    <Box mt={1}>
                      <Typography className={classes.caption} variant="caption">
                        Tracking Code
                      </Typography>
                      <Typography variant="body1">
                        <a href={trackingHref}>
                          {order?.shipment?.tracking_code}
                        </a>
                      </Typography>
                    </Box>
                    <Box mt={1}>
                      <Typography className={classes.caption} variant="caption">
                        Status
                      </Typography>
                      <Typography variant="body1">
                        {order.shipment.status}
                      </Typography>
                    </Box>
                    <Box mt={1}>
                      <Typography className={classes.caption} variant="caption">
                        Shipping Label
                      </Typography>
                      <Typography variant="body1">
                        <a href={order.shipment.label_url}>
                          {order.shipment.label_url}
                        </a>
                      </Typography>
                    </Box>
                  </Box>
                </Block>
              )}
              {order?.delivery_photo_url && (
                <Block title="Delivery Photo" withPadding>
                  <CustomerDeliveryPhoto
                    order={order}
                    onClick={handleUserClick}
                  />
                </Block>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      <Dialog
        open={blockAddressDialogOpen}
        onClose={() => setBlockAddressDialogOpen(false)}
        onConfirm={handleBlockAddress}
        confirmText="Yes"
        cancelText="No"
      >
        <Box mt={1}>
          <Typography variant="h5">Block Address</Typography>
        </Box>
        <Box my={3}>
          <Typography variant="body1">
            Are you sure you want to block this address?
          </Typography>
        </Box>
      </Dialog>
    </Layout>
  )
}

OrderShow.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object
}

export default withStyles(styles)(OrderShow)
