import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { trimStringAndAppend } from 'utils/general'
import { DRAG_N_DROP_MODE } from 'constants/enums'
import { FORM_FIELDS } from 'constants/products'

import GrabIcon from 'icons/GrabIcon'

import { useControlledForm } from 'components/ControlledForm'
import Dialog from 'components/Dialog'
import DragNDropGrid from 'components/DragNDropGrid'

const DRAGGABLE_VARIANT_OPTION_SIZES_DIMS = {
  itemWidth: 75,
  itemHeight: 34,
  marginWidth: 4,
  marginHeight: 8
}

const DRAGGABLE_VARIANT_OPTION_COLORS_DIMS = {
  itemWidth: 120,
  itemHeight: 34,
  marginWidth: 4,
  marginHeight: 8
}

const DRAGGABLE_VARIANT_OPTION_ACCESSORIES_DIMS = {
  itemWidth: 90,
  itemHeight: 34,
  marginWidth: 4,
  marginHeight: 8
}

const DraggableTag = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.colorsAF.semiGray}`,
    backgroundColor: theme.palette.colorsAF.white,
    position: 'relative',
    overflow: 'hidden',
    margin: 'auto'
  },
  grab: {
    marginLeft: theme.spacing(-0.5),
    color: theme.palette.grays.dark,
    opacity: 0.4
  }
}))(({ classes, item, width, height }) => (
  <Box width={width} height={height} className={classes.root}>
    <Box display="flex" p={1}>
      <GrabIcon className={classes.grab} size={20} />
      <Box ml={1}>
        <Typography variant="body1">
          {trimStringAndAppend(item.label || item.name, 8, '...')}
        </Typography>
      </Box>
    </Box>
  </Box>
))

const ReorderVariantsDialog = ({ open, onClose, onConfirm }) => {
  const {
    formContext: {
      watch,
      setValue,
      register,
      unregister,
      formState: { dirtyFields }
    }
  } = useControlledForm()

  const { [FORM_FIELDS.VARIANT_OPTION_SIZES_ORDER]: sizesOrder } = watch(
    [FORM_FIELDS.VARIANT_OPTION_SIZES_ORDER].filter(
      n => n && typeof n === 'string'
    )
  )

  const { [FORM_FIELDS.VARIANT_OPTION_COLORS_ORDER]: colorsOrder } = watch(
    [FORM_FIELDS.VARIANT_OPTION_COLORS_ORDER].filter(
      n => n && typeof n === 'string'
    )
  )

  const {
    [FORM_FIELDS.VARIANT_OPTION_ACCESSORIES_ORDER]: accessoryOptionsOrder
  } = watch(
    [FORM_FIELDS.VARIANT_OPTION_ACCESSORIES_ORDER].filter(
      n => n && typeof n === 'string'
    )
  )

  const variantOrderingFieldsDirty = () =>
    Boolean(
      Object.keys(dirtyFields ?? {})?.filter(key =>
        [
          FORM_FIELDS.VARIANT_OPTION_SIZES_ORDER,
          FORM_FIELDS.VARIANT_OPTION_COLORS_ORDER,
          FORM_FIELDS.VARIANT_OPTION_ACCESSORIES_ORDER
        ].includes(key)
      )?.length
    )

  useEffect(() => {
    register(FORM_FIELDS.VARIANT_OPTION_SIZES_ORDER)
    register(FORM_FIELDS.VARIANT_OPTION_COLORS_ORDER)
    register(FORM_FIELDS.VARIANT_OPTION_ACCESSORIES_ORDER)
    return () => {
      unregister(FORM_FIELDS.VARIANT_OPTION_SIZES_ORDER)
      unregister(FORM_FIELDS.VARIANT_OPTION_COLORS_ORDER)
      unregister(FORM_FIELDS.VARIANT_OPTION_ACCESSORIES_ORDER)
    }
  }, [register, unregister])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => {
        onConfirm && onConfirm()
        onClose()
      }}
      confirmText="Commit"
      confirmDisabled={!variantOrderingFieldsDirty()}
      title="Reorder Variants"
      subtitle="Reorder variants or options to change how they appear on FastAF."
    >
      <Box width="100%" mt={3}>
        <Typography variant="body2">Reorder Sizes</Typography>
        {sizesOrder.length ? (
          <DragNDropGrid
            items={sizesOrder}
            setItems={i =>
              setValue(FORM_FIELDS.VARIANT_OPTION_SIZES_ORDER, i, {
                shouldDirty: true
              })
            }
            ItemComponent={DraggableTag}
            mode={DRAG_N_DROP_MODE.ORDERING}
            {...DRAGGABLE_VARIANT_OPTION_SIZES_DIMS}
          />
        ) : (
          <Box mb={1}>
            <Typography variant="body1">No sizes available</Typography>
          </Box>
        )}
      </Box>

      <Box width="100%" mt={3}>
        <Typography variant="body2">Reorder Colors</Typography>
        {colorsOrder.length ? (
          <DragNDropGrid
            items={colorsOrder}
            setItems={i =>
              setValue(FORM_FIELDS.VARIANT_OPTION_COLORS_ORDER, i, {
                shouldDirty: true
              })
            }
            ItemComponent={DraggableTag}
            mode={DRAG_N_DROP_MODE.ORDERING}
            {...DRAGGABLE_VARIANT_OPTION_COLORS_DIMS}
          />
        ) : (
          <Box mb={1}>
            <Typography variant="body1">No colors available</Typography>
          </Box>
        )}
      </Box>

      <Box width="100%" mt={3}>
        <Typography variant="body2">Reorder AccessoryOptions</Typography>
        {accessoryOptionsOrder.length ? (
          <DragNDropGrid
            items={accessoryOptionsOrder}
            setItems={i =>
              setValue(FORM_FIELDS.VARIANT_OPTION_ACCESSORIES_ORDER, i, {
                shouldDirty: true
              })
            }
            ItemComponent={DraggableTag}
            mode={DRAG_N_DROP_MODE.ORDERING}
            {...DRAGGABLE_VARIANT_OPTION_ACCESSORIES_DIMS}
          />
        ) : (
          <Box mb={1}>
            <Typography variant="body1">
              No accessory options available
            </Typography>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

ReorderVariantsDialog.defaultProps = {}

ReorderVariantsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func
}

export default ReorderVariantsDialog
