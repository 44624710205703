import { useResourceList } from 'hooks'

const API_ADMIN_RESTOCK_WATCHES = '/api/v1/admin/restock_watches'

const useAdminRestockWatches = () => {
  const {
    isLoading: isLoadingRestockWatches,
    data: restockWatches,
    list: fetchRestockWatches,
    error: restockWatchesError
  } = useResourceList({ url: API_ADMIN_RESTOCK_WATCHES })

  return {
    isLoadingRestockWatches,
    restockWatches,
    fetchRestockWatches,
    restockWatchesError
  }
}

export default useAdminRestockWatches
