import React from 'react'
import PropTypes from 'prop-types'

const OrdersIcon = ({ fill, className }) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 0H3V2.2H19V0ZM2.2038 3H0L0.129412 5.2L1 20H21L21.8706 5.2L22 3H19.7962H2.2038ZM2.33321 5.2L3.07439 17.8H18.9256L19.6668 5.2H2.33321ZM7 7H15V9.2H7V7Z"
    />
  </svg>
)

OrdersIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}

export default OrdersIcon
