import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import { dollarsToCents } from 'utils/general'
import { getProductImageUrls } from 'utils/products'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import SaveIcon from '@material-ui/icons/Save'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import {
  useAdminProducts,
  useAdminRestockWatches,
  useAdminVariants,
  useBeforeUnload,
  useNavigation,
  useMenu,
  useLoaders,
  useMediaQuery,
  useConditionalEffect
} from 'hooks'
import { useLocationsContext } from 'context'
import { SIZE, COLOR, STATUS_DOT_VARIANTS } from 'constants/enums'

import { URL } from 'constants/navigation'
import {
  EXISTING_VARIANT_SCHEMA,
  FORM_FIELDS,
  LIFE_CYCLE_STATUSES
} from 'constants/variants'

import { AutocompleteSearchAccessoryOptionsControlled } from 'components/AutocompleteSearchAccessoryOptions'
import { AutocompleteSearchColorsControlled } from 'components/AutocompleteSearchColors'
import { AutocompleteSearchSizesControlled } from 'components/AutocompleteSearchSizes'
import { CheckboxField } from 'components/Checkbox'
import { SelectField } from 'components/Select'
import { TextFieldControlled } from 'components/TextField'
import { TitleElement } from 'components/SiteTitle'
import Block from 'components/Block'
import Button from 'components/Button'
import ControlledForm, { useControlledForm } from 'components/ControlledForm'
import Dialog from 'components/Dialog'
import Header from 'components/Header'
import Layout from 'components/Layout'
import MultiImageDropzone from 'components/MultiImageDropzone'
import SelectImagesDialog from 'components/SelectImagesDialog'
import StatusDot from 'components/StatusDot'
import Tabs from 'components/Tabs'
import WaitlistPane from 'components/WaitlistPane'

import StockLevels from './StockLevels'

import styles from './ProductVariantShowStyles'

const getTitle = variant => {
  const parts = [
    variant?.product?.brand,
    variant?.product?.title,
    variant?.color?.label,
    variant?.size,
    variant?.accessory_option
  ]
  return parts.filter(part => !!part).join(' ')
}

const DEFAULT_USER_LOCATION_ID = 1

const VariantStatusDot = ({ status }) => {
  if (!status || status === 'live') return null
  const { label, indicator } = [
    ...[
      {
        value: 'archived',
        label: 'Archived',
        indicator: STATUS_DOT_VARIANTS.red
      }
    ]
  ].find(t => t?.value === status)

  return <StatusDot text={label} variant={indicator} />
}

VariantStatusDot.propTypes = {
  status: PropTypes.string
}

const SaveButton = () => {
  const { readyToSave, handleSubmit } = useControlledForm()
  const { OnBeforeUnloadPrompt } = useBeforeUnload(readyToSave)
  return (
    <>
      <Button
        label="save variant changes button"
        onClick={handleSubmit}
        size={SIZE.medium}
        color={COLOR.primary}
        dataTest="variant-save-button"
        startIcon={<SaveIcon />}
        adaptive
        disabled={!readyToSave}
        type="submit"
      >
        Save
      </Button>
      <OnBeforeUnloadPrompt />
    </>
  )
}

const getDefaultFormValues = (product, variant, variantId) => ({
  [FORM_FIELDS.TITLE]: variant?.title ?? '',
  [FORM_FIELDS.COLOR]: variant?.color ?? {},
  [FORM_FIELDS.SIZE]: variant?.size
    ? { name: variant?.size, value: variant?.size }
    : {},
  [FORM_FIELDS.ACCESSORY]: variant?.accessory_option ?? {},
  [FORM_FIELDS.MSRP]: variant?.price ?? '',
  [FORM_FIELDS.COMPARE_AT_PRICE]: variant?.compare_at_price ?? '',
  [FORM_FIELDS.COST_PER_ITEM]: variant?.cost ?? '',
  [FORM_FIELDS.VENDOR_STYLE_NUMBER]: variant?.vendor_style_number ?? '',
  [FORM_FIELDS.SKU]: variant?.sku ?? '',
  [FORM_FIELDS.UPC]: variant?.barcode ?? '',
  [FORM_FIELDS.DSIN]: variant?.dsin ?? '',
  [FORM_FIELDS.BIN]: variant?.bin ?? '',
  [FORM_FIELDS.PULL_DATE_OFFSET]: variant?.pull_date_offset ?? '',
  [FORM_FIELDS.UNIT_OF_MEASURE]: variant?.unit ?? '',
  [FORM_FIELDS.LENGTH]: variant?.length ?? '',
  [FORM_FIELDS.WIDTH]: variant?.width ?? '',
  [FORM_FIELDS.HEIGHT]: variant?.height ?? '',
  [FORM_FIELDS.WEIGHT]: variant?.weight ?? '',
  [FORM_FIELDS.HOLIDAY]: variant?.holiday ?? '',
  [FORM_FIELDS.DO_NOT_BUY]: variant?.do_not_buy ?? '',
  [FORM_FIELDS.CRYPTO_MERCH]: variant?.crypto_merch ?? '',
  [FORM_FIELDS.SELECT_IMAGES]: getProductImageUrls(product, 'variant', {
    variantId
  }),
  [FORM_FIELDS.SAMPLE]: variant?.sample ?? false,
  [FORM_FIELDS.SAMPLE_APPROVED]: variant?.sample_status ?? null,
  [FORM_FIELDS.LIFE_CYCLE_STATUS]: variant?.life_cycle_status ?? 'npi'
})

const ProductVariantShow = ({
  classes,
  match: {
    params: { id: productId, variantId }
  }
}) => {
  const { isMobileScreen } = useMediaQuery()
  const { showLoading, hideLoading, isLoading } = useLoaders()
  const {
    locationId,
    locationOptions,
    showAllLocationIds
  } = useLocationsContext()

  const { product, readProduct, uploadProductImages } = useAdminProducts({})
  const {
    variant,
    readVariant,
    updateVariant,
    deleteVariant,
    archiveVariant,
    restoreVariant
  } = useAdminVariants({
    id: variantId
  })
  const [defaultFormValues, setDefaultFormValues] = useState(
    getDefaultFormValues({}, {}, variantId)
  )
  const [showStatusApprovalDropdown, setShowStatusApprovalDropdown] = useState(
    false
  )

  const { restockWatches, fetchRestockWatches } = useAdminRestockWatches({})

  const {
    open: historyMenuOpen,
    anchorEl: historyMenuAnchorEl,
    handleToggleMenu: handleHistoryMenuToggle,
    handleClose: handleHistoryMenuClose
  } = useMenu()

  const { go } = useNavigation({
    url: URL.ADMIN_PRODUCTS
  })

  const [showImageSelectionPopup, setShowImageSelectionPopup] = useState(false)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState(false)

  const handleDeleteVariant = async () => {
    showLoading()
    await deleteVariant(variant)
    setShowConfirmDeleteModal(false)
    go({}, `${URL.ADMIN_PRODUCTS}/${productId}`)
    hideLoading()
  }

  const handleArchiveVariant = async () => {
    showLoading()
    await archiveVariant(variantId)
    await readVariant(variantId)
    setShowConfirmArchiveModal(false)
    hideLoading()
  }

  const handleRestoreVariant = async () => {
    showLoading()
    await restoreVariant(variantId)
    await readVariant(variantId)
    setShowConfirmArchiveModal(false)
    hideLoading()
  }

  const readVariantWithLocation = async () => {
    await readVariant(variantId, locationId ? { location_id: locationId } : {})
  }

  const loadProductAndVariant = async () => {
    ;(productId || productId === 0) && (await readProduct(productId))
    ;(variantId || variantId === 0) && (await readVariantWithLocation())
  }

  const handleProductImageUpload = async (files, cb) => {
    showLoading()
    const product = await uploadProductImages(productId, files)
    cb(product, 'variant')
    hideLoading()
  }

  useConditionalEffect(() => {
    window.scrollTo(0, 0)
    showLoading()
    loadProductAndVariant()
    hideLoading()
  }, [locationId])

  useConditionalEffect(() => {
    if (isEmpty(variant)) return

    fetchRestockWatches({
      variant_id: variant?.id,
      location_id: locationId
    })
  }, [variant, locationId])

  useConditionalEffect(() => {
    setDefaultFormValues(getDefaultFormValues(product, variant, variantId))
    setShowStatusApprovalDropdown(!!variant?.sample)
  }, [product, variant])

  const getLocationLabel = (locationLabelId = DEFAULT_USER_LOCATION_ID) =>
    locationOptions?.find(l => l.value === locationLabelId)?.label

  const users = useMemo(() => {
    if (isEmpty(restockWatches)) return []

    const watchesByLocation = showAllLocationIds
      ? restockWatches
      : restockWatches.filter(w => w.location_id === locationId)

    return values(
      watchesByLocation.reduce(
        (acc, { user_id, email, first_name, last_name, location_id }) => ({
          ...acc,
          [user_id]: {
            email,
            name: `${first_name} ${last_name}`,
            locations: (acc[user_id]?.locations || []).concat(
              getLocationLabel(location_id)
            )
          }
        }),
        {}
      )
    )
  }, [restockWatches, locationId])

  const handleSave = async data => {
    showLoading()

    const { msrp, compare_at_price, cost_per_item } = data
    const deletions = [
      FORM_FIELDS.MSRP,
      FORM_FIELDS.COMPARE_AT_PRICE,
      FORM_FIELDS.COST_PER_ITEM,
      FORM_FIELDS.ACCESSORY,
      FORM_FIELDS.COLOR,
      FORM_FIELDS.SIZE,
      !locationId ? FORM_FIELDS.BIN : null
    ].filter(Boolean)
    deletions.forEach(deletion => delete data[deletion])

    await updateVariant(variantId, locationId, {
      ...data,
      select_images: data[FORM_FIELDS.SELECT_IMAGES].map(img => img.src),
      cost: dollarsToCents(cost_per_item),
      compare_at_price: dollarsToCents(compare_at_price),
      price: dollarsToCents(msrp)
    })

    await readProduct(productId)
    await readVariantWithLocation()

    hideLoading()
  }

  return (
    <Layout id={`variant-show-${variant?.id}`}>
      <TitleElement title={variant ? getTitle(variant) : 'Loading...'} />
      <ControlledForm
        handleSubmit={handleSave}
        schemas={EXISTING_VARIANT_SCHEMA}
        defaultValues={defaultFormValues}
        resetOnSubmit={false}
      >
        <Box>
          <Box className={classes.headerBox}>
            <Header
              breadcrumbs={[
                { title: 'Products', link: URL.ADMIN_PRODUCTS },
                {
                  title: product?.title ?? 'Loading',
                  link: `${URL.ADMIN_PRODUCTS}/${product?.id}`
                }
              ]}
              title={variant?.sku ?? 'Loading'}
              actions={
                <>
                  <Box
                    className={classes.historyMenuButton}
                    onClick={e => handleHistoryMenuToggle(e)}
                  >
                    <MoreVertIcon />
                    <Menu
                      open={historyMenuOpen}
                      onClose={handleHistoryMenuClose}
                      anchorEl={historyMenuAnchorEl}
                    >
                      <MenuItem
                        onClick={e =>
                          go(
                            e,
                            `${URL.ADMIN_PRODUCTS}/${product?.id}/variant/${variant?.id}/history`
                          )
                        }
                      >
                        View History
                      </MenuItem>
                      {variant?.can_delete && (
                        <MenuItem
                          onClick={e => setShowConfirmDeleteModal(true)}
                        >
                          Delete
                        </MenuItem>
                      )}
                      {variant?.status === 'live' && (
                        <MenuItem
                          onClick={e => setShowConfirmArchiveModal(true)}
                        >
                          Archive
                        </MenuItem>
                      )}
                      {variant?.status === 'archived' && (
                        <MenuItem
                          onClick={e => setShowConfirmArchiveModal(true)}
                        >
                          Unarchive
                        </MenuItem>
                      )}
                    </Menu>
                  </Box>
                  <SaveButton />
                </>
              }
            />
          </Box>
          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid item sm={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mx={1}
              >
                <Typography variant="body2">
                  Updated {moment(variant?.updated_at).calendar()}
                </Typography>
                <VariantStatusDot status={variant?.status} />
              </Box>
            </Grid>
            <Grid item sm={12} md={6} className={classes.gridItem}>
              <Block withPadding>
                <Typography variant="h5">Title</Typography>
                <TextFieldControlled
                  className={classes.thinInput}
                  fullWidth
                  placeholder="Untitled"
                  name={FORM_FIELDS.TITLE}
                  disabled
                />
              </Block>
              <Block>
                <Box className={classes.paddingBlock}>
                  <Typography variant="h5">Media</Typography>
                </Box>
                <MultiImageDropzone
                  autoUpload
                  handleUploadImages={(files, cb) =>
                    handleProductImageUpload(files, cb)
                  }
                  editName={FORM_FIELDS.SELECT_IMAGES}
                  defaultUrls={[]}
                  emptyImagesPlaceholderText={
                    isLoading
                      ? 'Loading...'
                      : 'Hit the edit button to select variant photos!'
                  }
                  onEditButtonClick={() =>
                    setShowImageSelectionPopup(!showImageSelectionPopup)
                  }
                />
              </Block>
              <Block>
                <Box className={classes.paddingBlock}>
                  <Typography variant="h5">Options</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    pt={2}
                  >
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      className={classes.autocompleteBox}
                      width="100%"
                    >
                      <Box width="100%" mr={1}>
                        <Typography variant="caption">Color</Typography>
                        <AutocompleteSearchColorsControlled
                          name={FORM_FIELDS.COLOR}
                          multiple={false}
                          disabled
                        />
                      </Box>
                      <Box width="100%" mr={1}>
                        <Typography variant="caption">Sizes</Typography>
                        <AutocompleteSearchSizesControlled
                          name={FORM_FIELDS.SIZE}
                          multiple={false}
                          disabled
                        />
                      </Box>
                      <Box width="100%">
                        <Typography variant="caption">
                          Accessory Option
                        </Typography>
                        <AutocompleteSearchAccessoryOptionsControlled
                          name={FORM_FIELDS.ACCESSORY}
                          multiple={false}
                          disabled
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box px={3} pb={3} pt={0}>
                  <Typography variant="h5">Pricing</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    pt={2}
                  >
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      <Typography variant="caption">Price</Typography>
                      <TextFieldControlled
                        type="number"
                        className={classes.smallInput}
                        startAdornment="$"
                        name={FORM_FIELDS.MSRP}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      <Typography variant="caption">
                        Strikethrough Price
                      </Typography>
                      <TextFieldControlled
                        type="number"
                        className={classes.smallInput}
                        startAdornment="$"
                        name={FORM_FIELDS.COMPARE_AT_PRICE}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                    ></Box>
                  </Box>
                  {locationId && (
                    <Box mt={2}>
                      <Box mb={2}>
                        <Typography variant="h5">Stock Levels</Typography>
                      </Box>
                      <StockLevels
                        productId={Number(productId)}
                        variant={variant ?? {}}
                      />
                    </Box>
                  )}
                </Box>
              </Block>
            </Grid>

            <Grid item sm={12} md={6} className={classes.gridItem}>
              <Block>
                <Tabs
                  panes={[
                    {
                      label: 'Details',
                      pane: (
                        <>
                          <Box className={classes.paddingBlock}>
                            <Typography variant="h5">Inventory</Typography>
                            <Box mt={1}>
                              <Typography
                                variant="caption"
                                className={classes.caption}
                              >
                                Vendor style number
                              </Typography>
                              <TextFieldControlled
                                className={classes.thinInput}
                                fullWidth
                                placeholder="AES-2030"
                                name={FORM_FIELDS.VENDOR_STYLE_NUMBER}
                                disabled={
                                  product?.vendor_style_number_is_universal
                                }
                              />
                            </Box>
                            <Box mt={1}>
                              <Typography
                                variant="caption"
                                className={classes.caption}
                              >
                                SKU
                              </Typography>
                              <TextFieldControlled
                                className={classes.thinInput}
                                fullWidth
                                placeholder="SKU"
                                name={FORM_FIELDS.SKU}
                                disabled
                              />
                            </Box>
                            <Box mt={1}>
                              <Typography
                                variant="caption"
                                className={classes.caption}
                              >
                                Barcode
                              </Typography>
                              <TextFieldControlled
                                className={classes.thinInput}
                                fullWidth
                                placeholder="UPC"
                                name={FORM_FIELDS.UPC}
                              />
                            </Box>
                            <Box mt={1}>
                              <Typography
                                variant="caption"
                                className={classes.caption}
                              >
                                DSIN
                              </Typography>
                              <TextFieldControlled
                                className={classes.thinInput}
                                fullWidth
                                placeholder="DSIN"
                                name={FORM_FIELDS.DSIN}
                              />
                            </Box>
                            <Box mt={1}>
                              <Typography
                                variant="caption"
                                className={classes.caption}
                              >
                                Pull Date Offset (Number of days to pull before
                                expiration date)
                              </Typography>
                              <TextFieldControlled
                                className={classes.thinInput}
                                fullWidth
                                placeholder="PULL DATE OFFSET"
                                name={FORM_FIELDS.PULL_DATE_OFFSET}
                                number
                              />
                            </Box>
                          </Box>
                          <Box className={classes.paddingBlock}>
                            <div className={classes.divider} />
                          </Box>
                          <Box className={classes.paddingBlock}>
                            <Typography variant="h5">Dimensions</Typography>
                            <SelectField
                              fullWidth
                              items={[
                                { label: 'None', value: null },
                                { label: 'Feet', value: 'ft' },
                                { label: 'Inches', value: 'in' },
                                { label: 'Centimeters', value: 'cm' }
                              ]}
                              displayEmpty
                              name={FORM_FIELDS.UNIT_OF_MEASURE}
                            />
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              flexDirection={isMobileScreen ? 'column' : 'row'}
                              className={classes.dimensionsContainer}
                            >
                              <TextFieldControlled
                                className={classes.thinInput}
                                fullWidth={Boolean(isMobileScreen)}
                                placeholder="Length"
                                name={FORM_FIELDS.LENGTH}
                              />
                              <TextFieldControlled
                                className={classes.thinInput}
                                fullWidth={Boolean(isMobileScreen)}
                                placeholder="Width"
                                name={FORM_FIELDS.WIDTH}
                              />
                              <TextFieldControlled
                                className={classes.thinInput}
                                fullWidth={Boolean(isMobileScreen)}
                                placeholder="Height"
                                name={FORM_FIELDS.HEIGHT}
                              />
                            </Box>
                            <Box my={2}>
                              <div className={classes.divider} />
                            </Box>
                            <Typography variant="h5">Weight</Typography>
                            <Box mt={1}>
                              <Box display="flex">
                                <TextFieldControlled
                                  className={classes.thinInput}
                                  fullWidth
                                  placeholder="Weight"
                                  name={FORM_FIELDS.WEIGHT}
                                />
                              </Box>
                            </Box>
                            <Box my={2}>
                              <div className={classes.divider} />
                            </Box>
                            <>
                              <Typography variant="h5">Flags</Typography>
                              <Box mt={1}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Box>
                                    <Typography variant="body1">
                                      Holiday Specific
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      This item is only sold during the
                                      holidays. Omits variant from replenishment
                                      analysis.
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <CheckboxField name={FORM_FIELDS.HOLIDAY} />
                                  </Box>
                                </Box>
                              </Box>
                            </>
                            {typeof variant?.do_not_buy !== 'undefined' && (
                              <>
                                <Box mt={1}>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box>
                                      <Typography variant="body1">
                                        Do Not Buy
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                      >
                                        This item is not intended to be bought
                                        anymore at this location and is
                                        discontinued.
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <CheckboxField
                                        name={FORM_FIELDS.DO_NOT_BUY}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            )}
                            <Box mt={1}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box>
                                  <Typography variant="body1">
                                    Crypto Merch
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    This item is crypto merch.
                                  </Typography>
                                </Box>
                                <Box>
                                  <CheckboxField
                                    name={FORM_FIELDS.CRYPTO_MERCH}
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Box mt={1}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box>
                                  <Typography variant="body1">
                                    Sample
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    This item is intended as a sample from
                                    vendors for performance testing and demand
                                    analysis.
                                  </Typography>
                                </Box>
                                <Box>
                                  <CheckboxField name={FORM_FIELDS.SAMPLE} />
                                </Box>
                              </Box>
                            </Box>
                            {showStatusApprovalDropdown && (
                              <Box mt={1}>
                                <Typography variant="body1">
                                  Sample Status
                                </Typography>
                                <SelectField
                                  fullWidth
                                  items={[
                                    { label: '--', value: null },
                                    { label: 'Approved', value: 'approved' },
                                    {
                                      label: 'Not Approved',
                                      value: 'unapproved'
                                    }
                                  ]}
                                  displayEmpty
                                  name={FORM_FIELDS.SAMPLE_APPROVED}
                                />
                              </Box>
                            )}
                            <Box my={2}>
                              <div className={classes.divider} />
                            </Box>
                            {!showAllLocationIds && (
                              <Box mt={1}>
                                <Typography variant="h5">
                                  Life Cycle Status
                                </Typography>
                                <SelectField
                                  dataTest="life-cycle-status-select"
                                  fullWidth
                                  items={LIFE_CYCLE_STATUSES}
                                  name={FORM_FIELDS.LIFE_CYCLE_STATUS}
                                />
                              </Box>
                            )}
                            {showAllLocationIds && (
                              <Box mt={1}>
                                <Typography variant="h5">
                                  Life Cycle Status (Select a location to edit.)
                                </Typography>
                              </Box>
                            )}
                            <Box my={2}>
                              <div className={classes.divider} />
                            </Box>
                          </Box>
                        </>
                      )
                    },
                    {
                      label: 'Waitlist',
                      pane: <WaitlistPane users={users} />
                    }
                  ]}
                />
              </Block>
            </Grid>
          </Grid>
        </Box>
        <SelectImagesDialog
          name={FORM_FIELDS.SELECT_IMAGES}
          title="Select Variant Photos"
          subtitle="Pick photos you've uploaded for this products variant."
          open={showImageSelectionPopup}
          onClose={() => setShowImageSelectionPopup(false)}
          selectableImages={
            Array.isArray(product?.raw_images) ? product?.raw_images : []
          }
        />
      </ControlledForm>
      <Dialog
        open={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleDeleteVariant}
        confirmText="Okay"
        hideCancel
      >
        <Box mt={1}>
          <Typography variant="h5">Confirm Action</Typography>
        </Box>
        <Box my={3}>
          <Typography variant="body1">
            Are you sure you want to delete this variant? This action cannot be
            undone.
          </Typography>
        </Box>
      </Dialog>
      <Dialog
        open={showConfirmArchiveModal}
        onClose={() => setShowConfirmArchiveModal(false)}
        onConfirm={
          variant?.status === 'live'
            ? handleArchiveVariant
            : handleRestoreVariant
        }
        confirmText="Okay"
        hideCancel
      >
        <Box mt={1}>
          <Typography variant="h5">Confirm Action</Typography>
        </Box>
        <Box my={3}>
          <Typography variant="body1">
            Are you sure you want to{' '}
            {variant?.status === 'live' ? 'archive' : 'unarchive'} this variant?
          </Typography>
        </Box>
      </Dialog>
    </Layout>
  )
}

ProductVariantShow.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(ProductVariantShow)
