import React from 'react'
import PropTypes from 'prop-types'

import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { NOTIFICATION_TYPE } from 'constants/enums'
import { notificationTypes } from 'constants/propTypes'
import { NOTIFICATION_AUTO_HIDE_DURATION_MS } from 'constants/general'

const DEFAULT_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right'
}

const Notification = ({ message, open, autoHideDuration, type, onClose }) => {
  const SHARED_PROPS = {
    open,
    autoHideDuration,
    anchorOrigin: DEFAULT_ANCHOR_ORIGIN,
    onClose
  }
  return type === NOTIFICATION_TYPE.general ? (
    <Snackbar
      {...SHARED_PROPS}
      message={message}
      action={
        <IconButton key="close" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    />
  ) : (
    <Snackbar {...SHARED_PROPS}>
      <Alert severity={type}>
        {message}
        <IconButton
          key="close"
          color="inherit"
          onClick={onClose}
          style={{ marginLeft: 16 }}
        >
          <CloseIcon />
        </IconButton>
      </Alert>
    </Snackbar>
  )
}

Notification.defaultProps = {
  type: NOTIFICATION_TYPE.info,
  autoHideDuration: NOTIFICATION_AUTO_HIDE_DURATION_MS
}

Notification.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  autoHideDuration: PropTypes.number,
  type: notificationTypes,
  onClose: PropTypes.func
}

export default Notification
