import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import MuiRadioGroup from '@material-ui/core/RadioGroup'

import Radio from 'components/Radio'

import styles from './RadioGroupStyles'

const RadioGroup = ({
  classes,
  value,
  labels,
  size,
  disabled,
  onChange,
  withBorders
}) => {
  const rootClassNames = classNames({
    [classes.root]: true,
    [classes.disabled]: disabled
  })

  return (
    <MuiRadioGroup
      className={rootClassNames}
      value={value}
      disabled={disabled}
      onChange={onChange}
    >
      {labels.map(label => (
        <Radio
          key={label}
          label={label}
          size={size}
          withBorder={withBorders}
          disabled={disabled}
          dataTest={`radio-${label}`}
        />
      ))}
    </MuiRadioGroup>
  )
}

RadioGroup.defaultProps = {
  labels: [],
  size: 'medium',
  disabled: false
}

RadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  withBorders: PropTypes.bool,
  onChange: PropTypes.func
}

export default withStyles(styles)(RadioGroup)
