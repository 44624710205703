import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

import { PUBLISHED_STATUS_VARIANT, ICON_BUTTON_VARIANT } from 'constants/enums'
import { ALL_LOCATION } from 'constants/general'

import PublishStatusIndicator from 'components/PublishStatusIndicator'
import DisplayCollectionMedia from 'components/DisplayCollectionMedia'
import IconButton from 'components/IconButton'
import RemoveIcon from 'icons/RemoveIcon'
import GrabIcon from 'icons/GrabIcon'

import styles from './DraggableCollectionStyles'

const DraggableCollectionRaw = ({
  classes,
  item: collection,
  width,
  height,
  locationId,
  onRemove,
  draggable,
  showMetaData,
  showPublishStatus
}) => {
  const publishedInLocation =
    locationId === ALL_LOCATION.id
      ? collection.location_ids?.length > 0
      : collection.location_ids?.includes(locationId)

  const isSmall = width < 150

  return (
    <Box
      width={width}
      height={height}
      className={classes.root}
      borderRadius={isSmall ? 2.3 : 5}
    >
      <DisplayCollectionMedia collection={collection} background />
      {!collection.image_url && (
        <Box className={classes.title}>
          <Typography variant="h4">{collection.title}</Typography>
        </Box>
      )}
      <Box
        className={classes.overlay}
        display="flex"
        justifyContent="space-between"
        pt={isSmall ? 0.5 : 1.5}
        px={1}
      >
        <Box>
          {draggable && <GrabIcon className={classes.grab} size={20} />}
        </Box>
        <Box display="flex" flexDirection="row">
          {showPublishStatus && (
            <PublishStatusIndicator
              size={isSmall ? 18.29 : 20}
              variant={
                publishedInLocation
                  ? PUBLISHED_STATUS_VARIANT.active
                  : PUBLISHED_STATUS_VARIANT.inactive
              }
            />
          )}
          {onRemove && (
            <Box ml={1} mt="2px">
              <IconButton
                size={isSmall ? 14 : 16}
                circle
                variant={ICON_BUTTON_VARIANT.black}
                onClick={() => onRemove(collection)}
              >
                <RemoveIcon fontSize={isSmall ? 8 : 13} />
              </IconButton>
            </Box>
          )}
        </Box>
        {showMetaData && (
          <Box position="absolute" bottom="10px" right="10px">
            {collection.is_parent && (
              <Chip label={<Typography>Parent</Typography>} color="secondary" />
            )}
            {collection.parent_collection_id && (
              <Chip
                label={
                  <Typography>
                    Parent collection: {collection.parent_collection_id}
                  </Typography>
                }
                color="secondary"
              />
            )}
            <Chip
              label={<Typography>{collection.id}</Typography>}
              color="secondary"
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

DraggableCollectionRaw.defaultProps = {
  showMetaData: false,
  showPublishStatus: true
}

DraggableCollectionRaw.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  locationId: PropTypes.number,
  onRemove: PropTypes.func,
  draggable: PropTypes.bool,
  showMetaData: PropTypes.bool.isRequired,
  showPublishStatus: PropTypes.bool
}

const DraggableCollection = withStyles(styles)(DraggableCollectionRaw)

export default DraggableCollection
