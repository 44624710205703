const jssStyles = theme => ({
  root: {
    padding: 12,
    pointerEvents: 'all'
  },
  tooltipDescription: {
    color: theme.palette.grays.dark
  }
})

export default jssStyles
