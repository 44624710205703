import { MINIMAL_LABEL } from 'constants/styles'

/* We set to the containers in the first row to the same initial
   height so it looks good. On narrower screens the first container
   should grow while the memo container should not. */
const FIRST_ROW_HEIGHT = 220

const DETAILS_ITEM_WIDTH = 200

const jssStyles = theme => {
  // somehow spacing should be shared with container at somepoint
  const DETAILS_ITEM_BREAK = DETAILS_ITEM_WIDTH * 2 + theme.spacing(4) * 2

  return {
    root: {
      padding: theme.spacing(1, 0),

      '& h6': {
        ...MINIMAL_LABEL(theme)
      }
    },
    paddingBlock: {
      padding: theme.spacing(3)
    },
    productButton: {
      fontWeight: 500,
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    memoContainer: {
      maxHeight: FIRST_ROW_HEIGHT,
      overflowY: 'auto'
    },
    exportButton: {
      '& .MuiButton-startIcon': {
        marginRight: 0
      }
    },
    menuButton: {
      cursor: 'pointer',
      width: 42,
      height: 42,
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:hover': {
        backgroundColor: '#e5e5e5'
      }
    },
    detailsContainer: {
      '& > *': {
        width: '100%',
        [`@media (min-width:${DETAILS_ITEM_BREAK}px)`]: {
          maxWidth: 200,
          minWidth: 200
        },
        [`@media (max-width:${DETAILS_ITEM_BREAK}px)`]: {
          maxWidth: '100%',
          minWidth: '100%'
        }
      },
      '& .MuiFormControl-root': {
        width: '100%',
        marginBottom: 0
      }
    }
  }
}

export default jssStyles
