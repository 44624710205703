import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import HttpsRedirect from 'react-https-redirect'
import { QueryClient, QueryClientProvider } from 'react-query'

import { StylesProvider } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import AppProvider from 'context/AppProvider'
import App from 'App'

import browserHistory from 'browserHistory'

const queryClient = new QueryClient()

ReactDOM.render(
  <HttpsRedirect>
    <Suspense
      fallback={
        <CircularProgress variant="indeterminate" size={70} thickness={5} />
      }
    >
      <QueryClientProvider client={queryClient}>
        <Router history={browserHistory}>
          <AppProvider>
            <StylesProvider injectFirst>
              <App />
            </StylesProvider>
          </AppProvider>
        </Router>
      </QueryClientProvider>
    </Suspense>
  </HttpsRedirect>,
  document.getElementById('root')
)
