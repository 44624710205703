const jssStyles = theme => ({
  text: {
    color: theme.palette.grays.dark,
    marginTop: 24,
    maxHeight: 240,
    overflow: 'auto',
    lineHeight: '19px',
    whiteSpace: 'pre-line'
  },
  deliveryImage: {
    width: "100%",
    height: "100%",
    display: 'block',
    margin: 'auto'
  },
})

export default jssStyles
