import { useResourceRQ } from 'hooks'
import { locationEndpoint } from 'api/endpoints'

const useAdminLocation = ({ id } = {}) => {
  const {
    create: createLocation,
    update: updateLocation,
    data: location
  } = useResourceRQ({
    name: 'location',
    endpoint: locationEndpoint,
    args: [id],
    dependents: [{ func: locationEndpoint }],
    fetchingEnabled: !!id
  })

  return {
    location,
    createLocation,
    updateLocation
  }
}

export default useAdminLocation
