import {
  STICKY_TOP,
  STICKY_BOTTOM,
  HEADER_INNER_COLUMN
} from 'constants/styles'

const jssStyles = theme => ({
  iLoveTightBottoms: {
    marginBottom: 0,
    paddingBottom: 0
  },
  stickyRoot: {
    position: 'relative',
    minHeight: 60
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 48,
    background: theme.palette.background.default,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    transition: 'left 0.1s ease-in,border-bottom-color 0.5s ease'
  },
  searchBox: {
    '& .MuiFormControl-root': {
      marginBottom: 0
    },
    '& .MuiButtonBase-root': {
      marginLeft: theme.spacing(1)
    }
  },
  actionBox: {
    '& .MuiButtonBase-root': {
      marginLeft: theme.spacing(1)
    }
  },
  drawerButton: {
    borderRadius: '50%',
    padding: 10,
    width: 48,
    height: 48,
    minWidth: 28, // override root styles
    minHeight: 28, // override root styles

    '&:not(first-of-type)': {
      marginLeft: 0
    }
  },
  drawerInner: {
    ...HEADER_INNER_COLUMN
  },
  drawerInnerBox: {
    ...HEADER_INNER_COLUMN,

    '&:first-of-type': {
      flexDirection: 'column-reverse'
    },
    '& > *': {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      width: '100%',
      maxWidth: 'auto' // overrride root styles,
    }
  },
  summarizersContainer: {
    margin: theme.spacing(2, 0)
  },
  stickyTop: {
    ...STICKY_TOP,
    left: theme.layout.drawerWidth,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: 16,
    borderBottomColor: theme.palette.grays.light
  },
  stickyTopTablet: {
    ...STICKY_TOP,
    top: 20,
    right: 20
  },
  stickyMobileActions: {
    ...STICKY_BOTTOM
  },
  isMobile: {
    '& .MuiTypography-h1': {
      fontSize: 'clamp(18px, 5vw, 26px)',
      lineHeight: 'clamp(18px, 6.5vw, 26px)'
    }
  },
  isDesktopOrSmaller: {
    maxWidth: 'calc(100vw - 88px)',
    marginTop: 17,
    marginLeft: 48,

    '& .MuiInputAdornment-root': {
      display: 'none'
    }
  },
  isDesktopOrLarger: {
    padding: theme.spacing(3, 0, 1, 0),
    '& $iLoveTightBottoms': {
      paddingBottom: '0 !important'
    }
  },
  hideHeader: {
    paddingTop: 0,
    '& $topContainer': {
      display: 'none'
    },
    '& > .MuiBox-root ': {
      paddingTop: 0
    }
  }
})

export default jssStyles
