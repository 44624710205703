import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useForm, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

import Grid from '@material-ui/core/Grid'

import { LIST_PATHS } from 'constants/queryParams'
import { useAdminCategories } from 'hooks'

import Header from 'components/Header'
import DataTable from 'components/DataTable'
import Select, { SelectField } from 'components/Select'
import GlanceTile from 'components/GlanceTile'
import Dialog from 'components/Dialog'
import TextField from 'components/TextField'

import ParentCategoriesItem from './ParentCategoriesItem'
import styles from './CategoriesStyles'

const TEST_ID = 'parentcategories'

const columns = [
  {
    title: 'Category',
    sortKeys: [LIST_PATHS.CATEGORIES_NAME]
  },
  {
    title: 'Created On',
    sortKeys: [LIST_PATHS.CATEGORIES_CREATED_AT]
  },
  {
    title: 'Status'
  },
  { title: '' }
]

const FIELDS = {
  name: 'name',
  active: 'active'
}

const parentCategoriesSchema = {
  [FIELDS.name]: yup.string().required(),
  [FIELDS.active]: yup.bool().required()
}

const parentCategoriesDefaults = {
  [FIELDS.name]: '',
  [FIELDS.active]: true
}

const ParentCategoriesPane = ({
  classes,
  openCreateDialog,
  onCloseDialog,
  categories = [],
  isLoadingList,
  hasListNext,
  listNext,
  query,
  updateQuery,
  handleQueryChange,
  hasCategories,
  categoriesCount
}) => {
  const {
    createCategory,
    updateCategory,
    activeCategoriesCount
  } = useAdminCategories()

  const [itemToEdit, setItemToEdit] = useState(null)
  const [openEditDialog, setOpenEditDialog] = useState(null)

  const formContext = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: parentCategoriesDefaults,
    resolver: yupResolver(yup.object().shape(parentCategoriesSchema))
  })

  const { register, handleSubmit, errors, formState, reset } = formContext

  const filters = (
    <Select
      minimal
      name={LIST_PATHS.CATEGORIES_STATUS}
      innerLabel="Status"
      value={query?.[LIST_PATHS.CATEGORIES_STATUS] || ''}
      items={[
        { label: 'All', value: '' },
        { label: 'Active', value: 'true' },
        { label: 'Inactive', value: 'false' }
      ]}
      onChange={handleQueryChange}
      dataTest={`${TEST_ID}-select-${LIST_PATHS.CATEGORIES_STATUS}`}
    />
  )

  const summarizers = (
    <>
      <GlanceTile minimal label="Total Categories" value={categoriesCount} />
      <GlanceTile
        minimal
        label="Active Categories"
        value={activeCategoriesCount}
      />
    </>
  )

  const handleSubmitForm = async payload => {
    await (itemToEdit
      ? updateCategory({ id: itemToEdit.id, payload })
      : createCategory(payload))
    onCloseDialog()
    setOpenEditDialog(false)
  }

  const handleSetActive = async (id, active) => {
    await updateCategory({ id, payload: { active } })
  }

  const handleEdit = item => {
    reset(item)
    setItemToEdit(item)
    setOpenEditDialog(true)
  }

  useEffect(() => {
    setItemToEdit(null)
    reset(parentCategoriesDefaults)
  }, [openCreateDialog])

  return (
    <>
      <Header filters={filters} summarizers={summarizers} hideHeader />
      <DataTable
        classes={{ TableContainer: classes.TableContainer }}
        columns={columns}
        message={categories?.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingList}
        hasListNext={hasListNext}
        listNext={listNext}
        query={query}
        updateQuery={updateQuery}
        id={TEST_ID}
      >
        {hasCategories &&
          categories.map(category => (
            <ParentCategoriesItem
              category={category}
              key={category.id}
              onSetActive={active => handleSetActive(category.id, active)}
              onClickEdit={handleEdit}
              dataTest={TEST_ID}
            />
          ))}
      </DataTable>
      <FormProvider {...formContext}>
        {(openEditDialog || openCreateDialog) && (
          <Dialog
            classes={{
              subtitle: classes.subtitle
            }}
            title={
              itemToEdit ? 'Edit Parent Category.' : 'Create Parent Category.'
            }
            subtitle={
              itemToEdit
                ? 'Update parent category name and status'
                : 'Give the parent category a name, and choose the active status.'
            }
            open={openEditDialog || openCreateDialog}
            onCancel={() => {
              if (openEditDialog) setOpenEditDialog(false)
              onCloseDialog && onCloseDialog()
            }}
            onConfirm={handleSubmit(handleSubmitForm)}
            confirmText={itemToEdit ? 'Update' : 'Create'}
            confirmDisabled={!formState.isDirty || !formState.isValid}
            dataTest={TEST_ID}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  name={FIELDS.name}
                  placeholder="Option name"
                  inputRef={register}
                  fullWidth
                  error={Boolean(errors[FIELDS.name]?.message)}
                  helperText={errors[FIELDS.name]?.message}
                  dataTest={`${TEST_ID}-${FIELDS.name}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField
                  name={FIELDS.active}
                  items={[
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false }
                  ]}
                  fullWidth
                  error={Boolean(errors[FIELDS.active]?.message)}
                  helperText={errors[FIELDS.active]?.message}
                />
              </Grid>
            </Grid>
          </Dialog>
        )}
      </FormProvider>
    </>
  )
}

ParentCategoriesPane.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.object,
  openCreateDialog: PropTypes.func,
  onCloseDialog: PropTypes.func,
  isLoadingList: PropTypes.bool,
  hasListNext: PropTypes.bool,
  listNext: PropTypes.func,
  query: PropTypes.object,
  updateQuery: PropTypes.func,
  handleQueryChange: PropTypes.func,
  hasCategories: PropTypes.bool,
  categoriesCount: PropTypes.number
}

export default withStyles(styles)(ParentCategoriesPane)
