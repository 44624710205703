const jssStyles = theme => ({
  actions: {
    '& > *': {
      margin: theme.spacing(0, 1)
    }
  },
  grayButton: {
    border: `1px solid ${theme.palette.grays.med}`,
    color: theme.palette.text.secondary,

    '&:hover': {
      border: `1px solid ${theme.palette.text.primary}`
    }
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4
  }
})

export default jssStyles
