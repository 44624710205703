import { useTheme } from '@material-ui/core/styles'

import muiUseMediaQuery from '@material-ui/core/useMediaQuery'

const useMediaQuery = () => {
  const theme = useTheme()

  const isMobileScreen = muiUseMediaQuery(theme.breakpoints.down('xs'))
  const isTabletScreen = muiUseMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const isDesktopScreen = muiUseMediaQuery(theme.breakpoints.down('md')) // TODO: https://darkstore.atlassian.net/browse/DAB-347
  const isDesktopLargeScreen = !isDesktopScreen

  return {
    isMobileScreen,
    isTabletScreen,
    isDesktopScreen,
    isDesktopLargeScreen
  }
}

export default useMediaQuery
