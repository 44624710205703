const jssStyles = theme => ({
  orderNumber: {
    fontWeight: 400
  },
  oneLine: {
    whiteSpace: 'nowrap'
  },
  underline: {
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '16px',
    color: theme.palette.colorsAF.lightGray
  },
  underlineLight: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '16px',
    color: theme.palette.colorsAF.lightGray
  }
})

export default jssStyles
