const jssStyles = theme => ({
  buttonBase: {
    width: '100%',
    textAlign: 'left'
  },
  root: {
    minHeight: 56,
    padding: theme.spacing(0, 1)
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '5px',
    backgroundColor: theme.palette.grays.light
  },
  odd: {
    backgroundColor: theme.palette.grays.ultralight
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1, 0)
  },
  quantityInput: {
    minWidth: 'auto',
    '& input': {
      width: '35px'
    }
  },
  select: {
    width: '100%'
  }
})

export default jssStyles
