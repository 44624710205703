import set from 'lodash/set'
import {
  orderEndpoint,
  orderMarkAsReturnedEndpoint,
  ordersEndpoint
} from 'api/endpoints'

import { api } from 'api'
import { useAlerts, useLoaders, useResourceRQ } from 'hooks'

const API_ORDERS = '/api/v1/admin/orders'

const useAdminOrders = ({
  id: orderId,
  returnId,
  refetchInterval,
  fetchingEnabled = true
}) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    data: order,
    update,
    destroy: destroyOrder,
    refetch: refetchOrder
  } = useResourceRQ({
    endpoint: orderEndpoint,
    args: [orderId],
    name: 'order',
    dependents: [orderEndpoint, ordersEndpoint],
    fetchingEnabled: !!orderId && fetchingEnabled,
    options: {
      refetchInterval
    }
  })

  const updateOrder = async (leUpdate, leOrder) => {
    try {
      showLoading()
      await update(leUpdate)
      showAlertSuccess(
        `Updated ${Object.keys(leUpdate)
          .map(key => key)
          .join(', ')} for Order # ${leOrder?.number}`
      )
    } catch (e) {
      console.error(e)
      hideLoading()
      if (e.status === 400) {
        showAlertError(
          (e.data.data?.errors?.length && e.data.data.errors) ||
            'There was an error updating the order'
        )
        return
      }
      showAlertError('There was an error updating the order')
    } finally {
      hideLoading()
    }
  }

  const orderReturn = async (orderId, data) => {
    try {
      showLoading()
      await api.post(`${API_ORDERS}/${orderId}/order_returns`, {
        order_return: data
      })
      hideLoading()
      showAlertSuccess('Order return successfully processed')
      return true
    } catch (e) {
      console.error(e)
      hideLoading()
      showAlertError('There was an error')
      return false
    }
  }

  const readOrderReturn = async (orderId, returnId) => {
    try {
      showLoading()
      const response = await api.get(
        `${API_ORDERS}/${orderId}/order_returns/${returnId}`
      )
      return response.data
    } catch (e) {
      console.error(e)
      return false
    } finally {
      hideLoading()
    }
  }

  const orderRefund = async (orderId, data) => {
    try {
      showLoading()
      await api.post(`${API_ORDERS}/${orderId}/refunds`, {
        refund: data
      })
      hideLoading()
      showAlertSuccess('Order return successfully processed')
      return true
    } catch (e) {
      console.error(e)
      hideLoading()
      showAlertError('There was an error')
      return false
    }
  }

  const refundTaxes = async (orderId, data) => {
    try {
      showLoading()
      const response = await api.put(`${API_ORDERS}/${orderId}/refunds/taxes`, {
        line_items: data.map(item => ({
          id: item.line_item.id,
          quantity: item.quantity
        }))
      })
      return response.data
    } catch (e) {
      console.error(e)
      return false
    } finally {
      hideLoading()
    }
  }

  const cancelReturn = async (orderId, orderReturnId) => {
    try {
      showLoading()
      const response = await api.delete(
        `${API_ORDERS}/${orderId}/order_returns/${orderReturnId}`
      )
      return response.data
    } catch (e) {
      console.error(e)
      return false
    } finally {
      hideLoading()
    }
  }

  // ORDER MARK AS RETURNED
  const { update: markAsReturned } = useResourceRQ({
    endpoint: orderMarkAsReturnedEndpoint,
    args: [orderId, returnId],
    name: '',
    dependents: [orderEndpoint],
    fetchingEnabled: false
  })

  const printLabel = async (orderId, printerId) => {
    try {
      showLoading()
      const response = await api.post(
        `${API_ORDERS}/${orderId}/print?printer_id=${printerId}`
      )
      if (response.data.print_id) {
        showAlertSuccess('Printing label')
        return { success: true }
      }
      return { success: false, response }
    } catch (e) {
      console.error(e)
      const errorMessage = e.data?.data?.message ?? 'Unknown'
      showAlertError(`Printing Error: ${errorMessage}`)
      return { success: false, response: e }
    } finally {
      hideLoading()
    }
  }

  const blockAddress = async orderId => {
    try {
      showLoading()
      await api.post(`${API_ORDERS}/${orderId}/block_address`)
      showAlertSuccess('Address now Blocked')
    } catch (e) {
      console.error(e)
      showAlertError('There was an error blocking this address')
      return false
    } finally {
      hideLoading()
    }
  }

  return {
    updateOrder,
    order,
    refetchOrder,
    deleteOrder: destroyOrder,
    orderReturn,
    readOrderReturn,
    orderRefund,
    refundTaxes,
    printLabel,
    cancelReturn,
    markAsReturned,
    blockAddress
  }
}

export default useAdminOrders
