import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useForm, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

import Grid from '@material-ui/core/Grid'

import { LIST_PATHS } from 'constants/queryParams'
import { useAdminSubcategories } from 'hooks'

import Header from 'components/Header'
import DataTable from 'components/DataTable'
import Select, { SelectField } from 'components/Select'
import GlanceTile from 'components/GlanceTile'
import Dialog from 'components/Dialog'
import TextField from 'components/TextField'
import { AutocompleteSearchParentCategoriesControlled } from 'components/AutocompleteSearchParentCategories'

import SubcategoriesItem from './SubcategoriesItem'
import styles from './CategoriesStyles'

const TEST_ID = 'subcategories'

const columns = [
  {
    title: 'Category',
    sortKeys: [LIST_PATHS.SUBCATEGORIES_NAME]
  },
  {
    title: 'Parent'
  },
  {
    title: 'Created On',
    sortKeys: [LIST_PATHS.SUBCATEGORIES_CREATED_AT]
  },
  {
    title: 'Status'
  },
  { title: '' }
]

const FIELDS = {
  name: 'name',
  active: 'active',
  parent: 'category_id'
}

const subcategoriesSchema = {
  [FIELDS.name]: yup.string().required(),
  [FIELDS.active]: yup.bool().required(),
  [FIELDS.parent]: yup
    .number()
    .transform((value, rawValue) => rawValue.id)
    .required()
}

const subcategoriesDefaults = {
  [FIELDS.name]: '',
  [FIELDS.active]: true,
  [FIELDS.parent]: false
}

const SubcategoriesPane = ({
  classes,
  openCreateDialog,
  onCloseDialog,
  subcategories = [],
  isLoadingList,
  hasListNext,
  listNext,
  query,
  updateQuery,
  handleQueryChange,
  hasSubcategories,
  subcategoriesCount
}) => {
  const {
    createSubcategory,
    updateSubcategory,
    activeSubcategoriesCount
  } = useAdminSubcategories()

  const [itemToEdit, setItemToEdit] = useState(null)
  const [openEditDialog, setOpenEditDialog] = useState(null)

  const formContext = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: subcategoriesDefaults,
    resolver: yupResolver(yup.object().shape(subcategoriesSchema))
  })

  const { register, handleSubmit, errors, formState, reset } = formContext

  const filters = (
    <Select
      minimal
      name={LIST_PATHS.SUBCATEGORIES_STATUS}
      innerLabel="Status"
      value={query?.[LIST_PATHS.SUBCATEGORIES_STATUS] || ''}
      items={[
        { label: 'All', value: '' },
        { label: 'Active', value: 'true' },
        { label: 'Inactive', value: 'false' }
      ]}
      onChange={handleQueryChange}
      dataTest={`${TEST_ID}-select-${LIST_PATHS.SUBCATEGORIES_STATUS}`}
    />
  )

  const summarizers = (
    <>
      <GlanceTile minimal label="Total Categories" value={subcategoriesCount} />
      <GlanceTile
        minimal
        label="Active Categories"
        value={activeSubcategoriesCount}
      />
    </>
  )

  const handleSubmitForm = async payload => {
    await (itemToEdit
      ? updateSubcategory({ id: itemToEdit.id, payload })
      : createSubcategory(payload))
    onCloseDialog()
    setOpenEditDialog(false)
  }

  const handleSetActive = async (id, active) => {
    await updateSubcategory({ id, payload: { active } })
  }

  const handleEdit = item => {
    reset({
      ...item,
      [FIELDS.parent]: item.category
    })
    setItemToEdit(item)
    setOpenEditDialog(true)
  }

  useEffect(() => {
    setItemToEdit(null)
    reset(subcategoriesDefaults)
  }, [openCreateDialog])

  return (
    <>
      <Header filters={filters} summarizers={summarizers} hideHeader />
      <DataTable
        classes={{ TableContainer: classes.TableContainer }}
        columns={columns}
        message={subcategories?.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingList}
        hasListNext={hasListNext}
        listNext={listNext}
        query={query}
        updateQuery={updateQuery}
        id={TEST_ID}
      >
        {hasSubcategories &&
          subcategories.map(subcategory => (
            <SubcategoriesItem
              subcategory={subcategory}
              key={subcategory.id}
              onSetActive={active => handleSetActive(subcategory.id, active)}
              onClickEdit={handleEdit}
              dataTest={TEST_ID}
            />
          ))}
      </DataTable>
      <FormProvider {...formContext}>
        {(openEditDialog || openCreateDialog) && (
          <Dialog
            classes={{
              subtitle: classes.subtitle
            }}
            title={
              itemToEdit
                ? 'Edit Parent Subcategory.'
                : 'Create Parent Subcategory.'
            }
            subtitle={
              itemToEdit
                ? 'Update sub category name and status'
                : 'Give the sub category a name, and choose the active status.'
            }
            open={openEditDialog || openCreateDialog}
            onCancel={() => {
              if (openEditDialog) setOpenEditDialog(false)
              onCloseDialog && onCloseDialog()
            }}
            onConfirm={handleSubmit(handleSubmitForm)}
            confirmText={itemToEdit ? 'Update' : 'Create'}
            confirmDisabled={!formState.isDirty || !formState.isValid}
            dataTest={TEST_ID}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  name={FIELDS.name}
                  placeholder="Option name"
                  inputRef={register}
                  fullWidth
                  error={Boolean(errors[FIELDS.name]?.message)}
                  helperText={errors[FIELDS.name]?.message}
                  dataTest={`${TEST_ID}-${FIELDS.name}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AutocompleteSearchParentCategoriesControlled
                  name={FIELDS.parent}
                  multiple={false}
                  dataTest={`${TEST_ID}-${FIELDS.parent}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField
                  name={FIELDS.active}
                  items={[
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false }
                  ]}
                  fullWidth
                  error={Boolean(errors[FIELDS.active]?.message)}
                  helperText={errors[FIELDS.active]?.message}
                />
              </Grid>
            </Grid>
          </Dialog>
        )}
      </FormProvider>
    </>
  )
}

SubcategoriesPane.propTypes = {
  classes: PropTypes.object.isRequired,
  subcategories: PropTypes.object,
  query: PropTypes.object,
  openCreateDialog: PropTypes.func,
  onCloseDialog: PropTypes.func,
  isLoadingList: PropTypes.bool,
  hasListNext: PropTypes.bool,
  listNext: PropTypes.func,
  updateQuery: PropTypes.func,
  handleQueryChange: PropTypes.func,
  hasSubcategories: PropTypes.bool,
  subcategoriesCount: PropTypes.number
}

export default withStyles(styles)(SubcategoriesPane)
