const jssStyles = theme => ({
  isMobile: {
    '&$content': {
      marginTop: theme.spacing(1)
    }
  },
  isDesktop: {
    '&$content': {
      marginTop: theme.spacing(2)
    }
  },
  isDesktopLarge: {
    '&$content': {
      marginTop: theme.spacing(3)
    }
  },
  content: {}
})

export default jssStyles
