import React, { useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import InputAdornment from '@material-ui/core/InputAdornment'
import ListItem from '@material-ui/core/ListItem'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { useConditionalEffect } from 'hooks'

import { ZIPCODE_SERVICED_OPTIONS } from 'constants/general'
import { SIZE, COLOR, BUTTON_VARIANT, TOOLTIP_PLACEMENT } from 'constants/enums'

import { formatCurrency, dollarsToCents, centsToDollars } from 'utils/general'

import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import Confirm from 'components/Confirm'
import TextField from 'components/TextField'
import ServiceSelect from 'components/ServiceSelect'

import styles from './ZipCodePanelStyles'

const TIP_INPUT_WIDTH = 120

const ZIPCODE_FIELDS = {
  serviced: 'serviced',
  tipCents: 'tip_cents'
}

const ZipCodePanel = ({
  classes,
  zipcodes,
  onZipCodeUpdate,
  onDelete,
  highlightedId,
  setHighlightedId
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [openPopover, setOpenPopover] = useState(false)
  const [isEditablePanel, setIsEditablePanel] = useState(false)
  const [codeActiveIds, setCodeActiveIds] = useState({})
  const [modifiedZipcodes, setModifiedZipcodes] = useState([])
  const highlightedRef = useRef(null)

  const handleMenuClick = e => {
    setMenuAnchorEl(e.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const toggleEditMenu = () => {
    setCodeActiveIds({})
    setIsEditablePanel(!isEditablePanel)
    setOpenPopover(false)
    handleMenuClose()
  }

  const handleCheckboxCodeChange = (codeId, value) => {
    setCodeActiveIds({ ...codeActiveIds, [codeId]: value })
  }

  const handleDeleteCodes = () => {
    setOpenPopover(false)

    const idsToDelete = Object.keys(codeActiveIds).filter(
      codeKey => codeActiveIds[codeKey]
    )

    if (isEmpty(idsToDelete)) return

    onDelete && onDelete(idsToDelete)
  }

  const showDeleteButton = useMemo(
    () => Object.values(codeActiveIds).find(val => val),
    [codeActiveIds]
  )

  const registerChange = zipcode => {
    setModifiedZipcodes([...modifiedZipcodes, zipcode])
  }

  const handleDoneClick = async () => {
    await Promise.all(
      modifiedZipcodes.map(({ id, field, value }) => {
        onZipCodeUpdate(id, field, value)
      })
    )
    setModifiedZipcodes([])
    toggleEditMenu()
  }

  useConditionalEffect(() => {
    highlightedId && highlightedRef.current.scrollIntoView({ block: 'center' })
  }, [highlightedId])

  return (
    <Box className={classes.root}>
      <Box className={classes.header} display="flex" alignItems="center">
        <Typography variant="h5">Zipcodes</Typography>
        <Box flex={1} />
        <Box display="flex">
          {showDeleteButton && (
            <Confirm
              description="Are you sure you want to delete the selected zipcodes?"
              onConfirm={handleDeleteCodes}
              onClose={() => setOpenPopover(false)}
              placement={TOOLTIP_PLACEMENT.left}
              open={openPopover}
              classes={{ root: classes.popover }}
            >
              <Box marginRight={1}>
                <Button
                  color={COLOR.default}
                  size={SIZE.small}
                  variant={BUTTON_VARIANT.outlined}
                  onClick={() => setOpenPopover(true)}
                >
                  Delete
                </Button>
              </Box>
            </Confirm>
          )}
          {isEditablePanel && (
            <Button
              color={COLOR.default}
              size={SIZE.small}
              variant={BUTTON_VARIANT.outlined}
              onClick={handleDoneClick}
              disabled={isEmpty(modifiedZipcodes)}
            >
              Done
            </Button>
          )}
        </Box>
        <IconButton
          className={classes.menuIconButton}
          edge="end"
          onClick={handleMenuClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          className={classes.menu}
          classes={{ paper: classes.menuPaper }}
          open={Boolean(menuAnchorEl)}
          keepMounted
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handleMenuClose}
        >
          <MenuItem className={classes.menuItem} onClick={toggleEditMenu}>
            {isEditablePanel ? 'VIEW' : 'EDIT'}
          </MenuItem>
        </Menu>
      </Box>
      <List className={classes.content} data-test="zip-code-panel">
        {zipcodes?.map(code => (
          <ListItem
            className={classNames({
              [classes.listItem]: true,
              [classes.editableListItem]: isEditablePanel
            })}
            disableGutters
            key={code.id}
          >
            {isEditablePanel && (
              <Checkbox
                onChange={value => handleCheckboxCodeChange(code.id, value)}
              />
            )}
            <Typography
              variant="body2"
              className={
                highlightedId === code.id ? classes.bold : classes.clickable
              }
              onClick={() =>
                setHighlightedId(highlightedId === code.id ? null : code.id)
              }
              data-test="zip-code-panel-label"
              ref={highlightedId === code.id ? highlightedRef : null}
            >
              {code.zip}
            </Typography>
            <Box marginLeft={3}>
              {isEditablePanel ? (
                <TextField
                  placeholder="Tip"
                  size={SIZE.small}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    onBlur: e =>
                      registerChange({
                        id: code.id,
                        field: ZIPCODE_FIELDS.tipCents,
                        value: dollarsToCents(e.target.value)
                      }),
                    style: {
                      minWidth: TIP_INPUT_WIDTH,
                      width: TIP_INPUT_WIDTH
                    },
                    defaultValue:
                      (code.tip_cents || null) && centsToDollars(code.tip_cents)
                  }}
                />
              ) : (
                <Typography>
                  {(code.tip_cents &&
                    ` ${formatCurrency(centsToDollars(code.tip_cents))}`) ||
                    ''}
                </Typography>
              )}
            </Box>
            <Box flex={1} />
            <ServiceSelect
              value={code.serviced}
              id={code.zip}
              options={ZIPCODE_SERVICED_OPTIONS}
              onChange={option =>
                onZipCodeUpdate(code.id, ZIPCODE_FIELDS.serviced, option.value)
              }
            />
          </ListItem>
        ))}
        {zipcodes.length === 0 && (
          <Box>Search at least the first two digits to see zipcodes.</Box>
        )}
      </List>
    </Box>
  )
}

ZipCodePanel.propTypes = {
  classes: PropTypes.object.isRequired,
  zipcodes: PropTypes.arrayOf(PropTypes.object),
  onServicedUpdate: PropTypes.func,
  onZipCodeUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  highlightedId: PropTypes.number,
  setHighlightedId: PropTypes.func
}

export default withStyles(styles)(ZipCodePanel)
