const jssStyles = theme => ({
  root: {
    position: 'relative'
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1, 0)
  },
  negativeTag: {
    '&:before': {
      content: '"-"',
      position: 'relative'
    }
  },
  totals: {
    '& p': {
      fontWeight: 700
    }
  }
})

export default jssStyles
