const jssStyles = theme => ({
  root: {},
  linkButton: {
    fontWeight: 400
  },
  brandSearch: {
    '& .MuiFormControl-root': {
      width: 180,
      marginBottom: theme.spacing(1),

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      }
    }
  }
})

export default jssStyles
