import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import { useAdminProducts } from 'hooks'
import { URL } from 'constants/navigation'

import Header from 'components/Header'
import Layout from 'components/Layout'
import ProductHistoryTable from 'components/ProductHistoryTable'

import styles from './ProductHistoryStyles'

const ProductHistory = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const {
    product,
    readProduct,
    historyData,
    fetchHistory,
    hasHistory,
    hasHistoryNext,
    fetchHistoryNext,
    isLoadingHistory
  } = useAdminProducts({ id })

  useEffect(() => {
    ;(async () => {
      ;(id || id === 0) && readProduct(id)

      fetchHistory(id)
    })()
  }, [])

  const listHistoryNext = () => fetchHistoryNext(id)

  return (
    <Layout>
      {product?.id && (
        <Box>
          <Box className={classes.headerBox}>
            <Header
              breadcrumbs={[
                { title: 'Products', link: URL.ADMIN_PRODUCTS },
                {
                  title: product.title,
                  link: `${URL.ADMIN_PRODUCTS}/${product?.id}`
                }
              ]}
              title="History"
              actions={<></>}
            />
          </Box>
        </Box>
      )}

      <ProductHistoryTable
        hasHistory={hasHistory}
        hasHistoryNext={hasHistoryNext}
        listHistoryNext={listHistoryNext}
        isLoadingHistory={isLoadingHistory}
        history={historyData}
      />
    </Layout>
  )
}

ProductHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(ProductHistory)
