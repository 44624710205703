import { MINIMAL_LABEL } from 'constants/styles'

const jssStyles = theme => ({
  profile: {
    maxHeight: 420,
    position: 'relative',
    overflowY: 'scroll'
  },
  avatar: { margin: theme.spacing(2, 0) },
  stat: {
    margin: theme.spacing(1, 0),
    '& .MuiTypography-caption': {
      ...MINIMAL_LABEL(theme)
    },
    '& .MuiTypography-body2': {
      fontWeight: 400
    }
  },

  largeTile: { border: 'none', width: '100%' },
  orderStatsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',

    '& > *': {
      margin: theme.spacing(0, 1.5),
      flexGrow: 1,

      '&:first-of-type': {
        marginLeft: 0
      },
      '&:last-of-type': {
        marginRight: 0
      }
    }
  }
})
export default jssStyles
