import React from 'react'

import Box from '@material-ui/core/Box'

import { TOOLTIP_PLACEMENT } from 'constants/enums'

import Tooltip from 'components/Tooltip'

const AmazonIcon = () => (
  <Tooltip
    title="Provided by AWS Recommendation Engine"
    placement={TOOLTIP_PLACEMENT.bottom}
  >
    <Box mt="-1px">
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.875 12a9.375 9.375 0 11-18.75 0 9.375 9.375 0 1118.75 0z"
          fill="#fff"
        />
        <path
          d="M12.459 11.186c-.572.167-1.026.525-1.026 1.29 0 .596.31 1.002.835 1.002.072 0 .144-.023.191-.023.31-.073.598-.289.765-.622.286-.478.262-.907.262-1.456v-.287c-.358 0-.716.024-1.027.096z"
          fill="#F49740"
        />
        <path
          d="M12.876 3c-5.17 0-9.375 4.206-9.375 9.375 0 5.17 4.206 9.375 9.375 9.375 5.17 0 9.375-4.206 9.375-9.375 0-5.17-4.206-9.375-9.375-9.375zm.61 6.993V9.8c0-.334.024-.717-.167-1.003-.167-.239-.477-.359-.763-.359h-.096c-.478.048-.907.311-1.027.837-.024.118-.095.238-.239.261l-1.337-.166c-.096-.024-.24-.095-.191-.286.286-1.505 1.576-2.03 2.793-2.101h.287c.669 0 1.529.191 2.077.692.669.62.597 1.456.597 2.364v2.149c0 .644.263.931.525 1.266.072.119.097.262-.023.358-.263.238-.764.668-1.051.907-.096.071-.215.071-.334.024-.454-.382-.526-.55-.788-.908-.454.479-.836.741-1.29.884a3.943 3.943 0 01-.978.12c-1.147 0-2.054-.717-2.054-2.126 0-1.122.597-1.886 1.48-2.244.454-.215 1.003-.31 1.552-.383.359-.048.717-.071 1.027-.094zm3.45 6.327a3.84 3.84 0 01-.436.3l-.006.005c-1.128.71-2.583 1.143-3.868 1.172-.04.002-.08.002-.12.002-2.02 0-3.524-1.107-4.895-2.297a.29.29 0 01-.114-.223c0-.068.029-.135.078-.182a.27.27 0 01.182-.07c.052 0 .103.014.154.04 1.462.812 2.91 1.635 4.684 1.634l.123-.001c1.129-.024 2.349-.3 3.51-.771v-.002l.107-.042.082-.034.173-.074a.374.374 0 01.16-.036c.17 0 .328.125.33.31a.344.344 0 01-.144.27zm.663.491a.324.324 0 01-.198.075H17.4a.23.23 0 01-.159-.062.228.228 0 01-.07-.167c0-.039.008-.073.02-.105.054-.139.133-.347.197-.554.067-.207.116-.42.115-.534a.215.215 0 00-.006-.06l-.009-.005a.303.303 0 00-.065-.02 1.197 1.197 0 00-.244-.02c-.136 0-.295.013-.443.029-.174.025-.322.039-.424.06l-.008.002h-.01a.25.25 0 01-.194-.052.219.219 0 01-.079-.168c.003-.11.064-.182.136-.237a1.87 1.87 0 01.38-.212v-.005l.117-.039.004-.001.208-.07v.014c.207-.045.413-.063.6-.063.26 0 .475.03.623.088.05.022.095.041.143.094l.006.007.005.007a.51.51 0 01.075.27l.002.068c-.008.427-.192 1.185-.72 1.66z"
          fill="#F49740"
        />
      </svg>
    </Box>
  </Tooltip>
)

export default AmazonIcon
