import { alpha } from '@material-ui/core/styles'

const jssStyles = theme => ({
  delButton: {
    padding: 4,
    margin: 4,
    width: 32,
    height: 32,
    backgroundColor: alpha(theme.palette.common.black, 0.4),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.7)
    }
  },
  buttonContainer: {
    position: 'absolute',
    top: 24,
    right: 10
  },
  subtitle: {
    color: theme.palette.grays.dark,
    maxWidth: 354,
    fontSize: 10
  }
})

export default jssStyles
