const INDICATOR_HEIGHT = 2
const SMALL_INDICATOR_HEIGHT = 1

const sharedTab = theme => ({
  color: theme.palette.text.ultradark,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '20px',
  textTransform: 'none',
  marginRight: 0
})

const jssStyles = theme => ({
  root: {},
  smallTabs: {
    minHeight: 22
  },
  unselected: {
    opacity: 0.4
  },
  tab: {
    ...sharedTab(theme),
    padding: theme.spacing(2, 4),
    borderBottom: `${INDICATOR_HEIGHT}px solid ${theme.palette.grays.ultradark}`
  },
  smallTab: {
    ...sharedTab(theme),
    padding: 0,
    height: 22,
    minHeight: 22,
    minWidth: 0,
    borderBottom: `${SMALL_INDICATOR_HEIGHT}px solid ${theme.palette.grays.ultradark}`
  },
  indicator: {
    height: INDICATOR_HEIGHT,
    backgroundColor: theme.palette.primary.main
  },
  smallIndicator: {
    height: SMALL_INDICATOR_HEIGHT,
    backgroundColor: theme.palette.primary.main
  },
  tabPanel: {
    height: '100%',
    width: '100%',
    color: theme.palette.text.primary
  }
})

export default jssStyles
