import mapValues from 'lodash/mapValues'
import muizIndex from '@material-ui/core/styles/zIndex'

const maxLayers = 2000
const elevatedZIndices = mapValues(muizIndex, z => z + maxLayers)

export const zIndex = {
  layer50: maxLayers + 50,
  layer100: maxLayers + 100,
  layer200: maxLayers + 200,
  layer300: maxLayers + 300,
  layer400: maxLayers + 400,
  layer500: maxLayers + 500,
  layer600: maxLayers + 600,
  layer700: maxLayers + 700,
  layer800: maxLayers + 800,
  layer900: maxLayers + 900,
  ...elevatedZIndices
}
