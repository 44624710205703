const jssStyles = theme => ({
  returnProgress: {
    display: 'flex',
    alignItems: 'center',
    gridGap: 4,
    marginBottom: theme.spacing(3),
    '& p': {
      fontWeight: 600
    }
  },
  inProgress: {
    color: theme.palette.colorsAF.fastRed
  },
  returned: {
    color: theme.palette.warnings.success
  },
  iconButton: {
    minWidth: 'auto',
    width: 44,
    '& .MuiButton-startIcon': {
      margin: 0
    }
  },
  divider: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grays.med}`
  },
  paddingBlock: {
    padding: theme.spacing(3, 0)
  }
})

export default jssStyles
