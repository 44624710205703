import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'

const DefaultSizePx = 24

const CheckIndeterminateIcon = ({ fill, fontSize }) => {
  const theme = useTheme()

  return (
    <svg
      width={fontSize || DefaultSizePx}
      height={fontSize || DefaultSizePx}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3333 0H2.66667C1.18667 0 0 1.2 0 2.66667V21.3333C0 22.8 1.18667 24 2.66667 24H21.3333C22.8133 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8133 0 21.3333 0ZM17 13H7V11H17V13Z"
        fill={fill || theme.palette.primary.main}
      />
    </svg>
  )
}

CheckIndeterminateIcon.propTypes = {
  fill: PropTypes.string
}

export default CheckIndeterminateIcon
