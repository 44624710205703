import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import styles from './FluidStyles'

const Fluid = ({ classes, children }) => (
  <Box className={classes.root}>{children}</Box>
)

Fluid.defaultProps = {}

Fluid.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
}

export default withStyles(styles)(Fluid)
