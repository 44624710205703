import { useEffect } from 'react'
import PropTypes from 'prop-types'

import useLatestCallback from './useLatestCallback'

/**
NOTE: prop shouldEngage was created for the DataTable component
in some instances (ie BrandCustomerList residing in a Pane),
we need to use the window useOnScrollBottom to engage infinite scrolling.
this is admittedly hacky, but with how <TableContainer /> handles
scrolling, I am not sure how to extend it.
*/

const useOnScrollBottom = ({ listNext, isLoading, shouldEngage }) => {
  const callback = useLatestCallback(listNext)

  const handleScroll = () => {
    const scrollArea = window.innerHeight + document.documentElement.scrollTop
    const { scrollHeight } = document.documentElement
    const shouldTrigger = scrollArea >= scrollHeight

    if (shouldTrigger && !isLoading) {
      callback()
    }
  }

  useEffect(() => {
    if (!shouldEngage) return
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
}

useOnScrollBottom.defaultProps = {
  shouldEngage: false
}

useOnScrollBottom.propTypes = {
  listNext: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  shouldEngage: PropTypes.bool
}

export default useOnScrollBottom
