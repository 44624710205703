import React from 'react'
import { TableRow, TableCell, IconButton, makeStyles } from '@material-ui/core'
import { FiTrash } from 'react-icons/fi'
import { colorsAF } from 'theme/colors'

import { useNavigation } from 'hooks'

import Confirm from 'components/Confirm'

const tableRowStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    fontWeight: 500,

    '&:hover': {
      color: colorsAF.fast
    },
    fontSize: 16
  },
  underline: {
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '16px',
    color: colorsAF.lightGray
  },
  linkBox: {
    display: 'flex',
    alignItems: 'flex-start',

    '&:hover': {
      pointer: 'pointer'
    }
  }
}))

interface setStateFunc {
  (val: any): any
}

interface ConfirmDeleteTableCellProps {
  open: boolean
  setOpen: setStateFunc
  onConfirm: setStateFunc
  description: string
}

const ConfirmDeleteTableCell = ({
  open,
  setOpen,
  onConfirm,
  description
}: ConfirmDeleteTableCellProps) => (
  <TableCell data-test="table-row-delete-button">
    <Confirm
      open={open}
      onClose={() => setOpen(false)}
      onConfirm={onConfirm}
      description={description}
    >
      <IconButton edge="end" onClick={() => setOpen(true)}>
        <FiTrash />
      </IconButton>
    </Confirm>
  </TableCell>
)

interface useTableRowProps {
  itemUrl: string
}

function useTableRow({ itemUrl }: useTableRowProps) {
  const { handleClick: navigateToItem } = useNavigation({
    url: itemUrl
  })

  return {
    ConfirmDeleteDialogCell: (props: ConfirmDeleteTableCellProps) => (
      <ConfirmDeleteTableCell {...props} />
    ),
    classes: tableRowStyles(),
    navigateToItem,
    TableRow,
    TableCell
  }
}

export default useTableRow
