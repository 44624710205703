import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { useForm, FormProvider } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'

import { COLOR, SIZE } from 'constants/enums'
import {
  DROPZONE_IMAGE_TYPES,
  COLLECTION_IMAGE_WIDTH_PX,
  COLLECTION_IMAGE_HEIGHT_PX
} from 'constants/collections'
import {
  makeObjectResolver,
  makeImageSchemaWithResolution
} from 'constants/yupSchemas'
import { TERM_POSSIBILITIES_WITH_NONE } from 'constants/general'
import { useAdminBrands, useMediaQuery } from 'hooks'
import { getBrandName } from 'utils/general'

import Block from 'components/Block'
import Button from 'components/Button'
import CollectionBlock from 'components/CollectionBlock'
import TextField from 'components/TextField'
import ImageDropzone from 'components/ImageDropzone'
import ProgressBar from 'components/ProgressBar'
import Checkbox from 'components/Checkbox'
import DatePicker from 'components/DatePicker'
import Select from 'components/Select'

import styles from './BrandDetailsStyles'

const FIELDS = {
  coverPhoto: 'cover_photo'
}

const yupUserSchemas = {
  [FIELDS.coverPhoto]: makeImageSchemaWithResolution(
    COLLECTION_IMAGE_WIDTH_PX,
    COLLECTION_IMAGE_HEIGHT_PX
  )
}

const BrandDetails = ({ classes, brand, brandUpdates, handleBrandUpdates }) => {
  const { isMobileScreen: isMobile } = useMediaQuery()
  const { updateBrandWithImage } = useAdminBrands()

  const formMethods = useForm({
    mode: 'onSubmit',
    resolver: makeObjectResolver(yupUserSchemas)
  })
  const { handleSubmit } = formMethods

  const [loading, setLoading] = useState(false)
  const [activeImageDropzone, setActiveImageDropzone] = useState(false)
  const [coverPhoto, setCoverPhoto] = useState(null)

  useEffect(() => {
    setCoverPhoto(brand?.cover_photo_url)
  }, [brand])

  const updateImage = async brandObject => {
    setLoading(true)
    const coverPhoto = brandObject[FIELDS.coverPhoto]

    if (!coverPhoto) return

    const imageObject = { [FIELDS.coverPhoto]: coverPhoto }

    const response = await updateBrandWithImage({
      id: brand.id,
      ...imageObject
    })

    setLoading(false)
    setCoverPhoto(response?.cover_photo_url)
    setActiveImageDropzone(false)
  }

  const getFormValue = key => {
    if (key in (brandUpdates ?? {})) {
      return brandUpdates[key]
    }

    if (key in (brand ?? {})) {
      return brand[key]
    }

    return ''
  }

  return (
    <Box className={classes.root}>
      <FormProvider {...formMethods}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Block>
              <Box
                className={classes.paddingBlock}
                display="flex"
                flexDirection="column"
              >
                <Typography variant="h5">Brand Name</Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection={isMobile ? 'column' : 'row'}
                >
                  <TextField
                    className={classes.brandNameField}
                    fullWidth
                    inputProps={{ 'data-test': 'brand-name' }}
                    value={getFormValue('name')}
                    onChange={e =>
                      handleBrandUpdates({
                        name: e.target.value
                      })
                    }
                  />
                </Box>
              </Box>
            </Block>
            <Block>
              <Box className={classes.paddingBlock} display="flex">
                <Typography variant="h5">Brand Card</Typography>
                <Box flex={1} />
                {activeImageDropzone && (
                  <Button
                    color={COLOR.default}
                    variant="outlined"
                    size={SIZE.small}
                    onClick={handleSubmit(updateImage)}
                  >
                    Save
                  </Button>
                )}
              </Box>
            </Block>
            <Block>
              <Box position="relative">
                {loading && <ProgressBar className={classes.imageLoader} />}
                {!activeImageDropzone && (
                  <IconButton
                    classes={{ root: classes.imageEditButton }}
                    size={SIZE.small}
                    onClick={() => setActiveImageDropzone(true)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
                {activeImageDropzone && (
                  <IconButton
                    classes={{ root: classes.imageEditButton }}
                    size={SIZE.small}
                    onClick={() => setActiveImageDropzone(false)}
                  >
                    <CloseIcon fontSize={SIZE.small} />
                  </IconButton>
                )}
                {activeImageDropzone && (
                  <ImageDropzone
                    className={classes.imageDropzone}
                    classes={{
                      placeholder: classes.imageDropzonePlaceholder
                    }}
                    name={FIELDS.coverPhoto}
                    accept={DROPZONE_IMAGE_TYPES}
                    hideClearButton
                    imageCover
                  />
                )}
                <CollectionBlock
                  item={brand}
                  title={getBrandName(brand)}
                  imageUrl={coverPhoto}
                  disableActions
                  className={classes.brandImage}
                />
              </Box>
            </Block>
          </Grid>
          <Grid item xs={12} md={6}>
            <Block>
              <Box className={classes.paddingBlock}>
                <Typography variant="body2">Stripe ID</Typography>
                <Box display="flex" alignItems="center">
                  <TextField
                    classes={{ input: classes.stripeIdInput }}
                    name="stripeConnectAccountId"
                    inputProps={{ 'data-test': 'brand-stripeid' }}
                    value={getFormValue('stripe_connect_account_id')}
                    onChange={e =>
                      handleBrandUpdates({
                        stripe_connect_account_id: e.target.value
                      })
                    }
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={3}
                >
                  <Box>
                    <Typography variant="body2">Brand Partner</Typography>
                    <Typography variant="body1">
                      This brand is an official FASTAF partner?
                    </Typography>
                    {(brand?.partner || brandUpdates?.partner) && (
                      <Box mt={1}>
                        <DatePicker
                          name="sign-date"
                          label="Brand Signing Date"
                          onChange={signed_at =>
                            handleBrandUpdates({
                              signed_at
                            })
                          }
                          value={getFormValue('signed_at')}
                          showEndAdornment
                          dataTest="brand-partner-signing-date"
                          showClearButton={false}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box mt={1}>
                    <Checkbox
                      onChange={partner =>
                        handleBrandUpdates({
                          partner
                        })
                      }
                      checked={brandUpdates?.partner ?? brand?.partner}
                      dataTest="brand-partner"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={3}
                >
                  <Box>
                    <Typography variant="body2">Contract Exclusive</Typography>
                    <Typography variant="body1">
                      Are we exclusive with this brand?
                    </Typography>
                    {(brand?.contract_exclusive ||
                      brandUpdates?.contract_exclusive) && (
                      <Box mt={1}>
                        <DatePicker
                          name="sign-date"
                          label="Contract Exclusivity Expires At"
                          onChange={contract_exclusivity_expires_at =>
                            handleBrandUpdates({
                              contract_exclusivity_expires_at
                            })
                          }
                          value={getFormValue(
                            'contract_exclusivity_expires_at'
                          )}
                          showEndAdornment
                          dataTest="brand-contract-exclusive-date"
                          showClearButton={false}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box mt={1}>
                    <Checkbox
                      onChange={contract_exclusive =>
                        handleBrandUpdates({
                          contract_exclusive
                        })
                      }
                      checked={
                        brandUpdates?.contract_exclusive ??
                        brand?.contract_exclusive
                      }
                      dataTest="brand-contract-exclusive"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={3}
                >
                  <Box>
                    <Typography variant="body2">
                      Not with Other qComm?
                    </Typography>
                    <Typography variant="body1">
                      Is this brand NOT on any other qCommerce stores?
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Checkbox
                      onChange={not_on_other_qcomm =>
                        handleBrandUpdates({
                          not_on_other_qcomm
                        })
                      }
                      checked={
                        brandUpdates?.not_on_other_qcomm ??
                        brand?.not_on_other_qcomm
                      }
                      dataTest="brand-not-on-other-qcomm"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={3}
                >
                  <Box>
                    <Typography variant="body2">Not on Amazon</Typography>
                    <Typography variant="body1">
                      Is this brand NOT on Amazon?
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Checkbox
                      onChange={not_on_amazon =>
                        handleBrandUpdates({
                          not_on_amazon
                        })
                      }
                      checked={
                        brandUpdates?.not_on_amazon ?? brand?.not_on_amazon
                      }
                      dataTest="brand-not-on-amazon"
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Box>
                    <Typography variant="body2">Brand Terms</Typography>
                    <Typography variant="body1">
                      What is the brand relationship type?
                    </Typography>
                  </Box>
                  <Select
                    name="brand-relationship"
                    value={getFormValue('payment_terms')}
                    items={TERM_POSSIBILITIES_WITH_NONE}
                    dataTest="brand-relationship-type"
                    onChange={e =>
                      handleBrandUpdates({
                        payment_terms: e.target.value
                      })
                    }
                    displayEmpty
                  />
                </Box>
              </Box>
            </Block>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  )
}

BrandDetails.defaultProps = {
  brand: {},
  brandUpdates: {}
}

BrandDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  brandUpdates: PropTypes.object.isRequired,
  handleBrandUpdates: PropTypes.func.isRequired,
  brand: PropTypes.object
}

export default withStyles(styles)(BrandDetails)
