import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Input from '@material-ui/core/Input'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import styles from './ServiceSelectStyles'

const ServiceSelect = ({ classes, value, options, onChange, id }) => (
  <MuiSelect
    value={value}
    input={<Input disableUnderline />}
    className={classNames({
      [classes.root]: true,
      [classes.isServiced]: Boolean(value)
    })}
    data-test={`select-online-offline-${id}`}
  >
    {options?.map(option => (
      <MenuItem
        key={option.label}
        value={option.value}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onChange && onChange(option)
        }}
        data-test={`serviceSelect-${option.value}`}
      >
        {option.label}
      </MenuItem>
    ))}
  </MuiSelect>
)

ServiceSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  id: PropTypes.string
}

export default withStyles(styles)(ServiceSelect)
