import React from 'react'
import PropTypes from 'prop-types'

const StatusCodesIcon = ({ className }) => (
  <svg
    className={className}
    width="12"
    height="17"
    viewBox="0 0 12 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.7063 1.75C2.7063 1.19772 2.25858 0.75 1.7063 0.75C1.15401 0.75 0.706299 1.19772 0.706299 1.75V10C0.706299 10.5523 1.15401 11 1.7063 11C2.25858 11 2.7063 10.5523 2.7063 10V1.75ZM2.5 14.5C2.5 13.9477 2.05228 13.5 1.5 13.5C0.947715 13.5 0.5 13.9477 0.5 14.5V15.25C0.5 15.8023 0.947715 16.25 1.5 16.25C2.05228 16.25 2.5 15.8023 2.5 15.25V14.5ZM7.2063 1.75C7.2063 1.19772 6.75858 0.75 6.2063 0.75C5.65401 0.75 5.2063 1.19772 5.2063 1.75V10C5.2063 10.5523 5.65401 11 6.2063 11C6.75858 11 7.2063 10.5523 7.2063 10V1.75ZM7 14.5C7 13.9477 6.55228 13.5 6 13.5C5.44772 13.5 5 13.9477 5 14.5V15.25C5 15.8023 5.44772 16.25 6 16.25C6.55228 16.25 7 15.8023 7 15.25V14.5ZM10.7063 0.75C11.2586 0.75 11.7063 1.19772 11.7063 1.75V10C11.7063 10.5523 11.2586 11 10.7063 11C10.154 11 9.7063 10.5523 9.7063 10V1.75C9.7063 1.19772 10.154 0.75 10.7063 0.75ZM10.5 13.5C11.0523 13.5 11.5 13.9477 11.5 14.5V15.25C11.5 15.8023 11.0523 16.25 10.5 16.25C9.94772 16.25 9.5 15.8023 9.5 15.25V14.5C9.5 13.9477 9.94772 13.5 10.5 13.5Z"
      fill="#8D8D8D"
    />
  </svg>
)

StatusCodesIcon.propTypes = {
  className: PropTypes.string
}

export default StatusCodesIcon
