import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'

const DefaultSizePx = 24

const RadioBlankIcon = ({ fill, stroke, fontSize }) => {
  const theme = useTheme()

  return (
    <svg
      width={fontSize || DefaultSizePx}
      height={fontSize || DefaultSizePx}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        fill={fill || theme.palette.grays.light}
        stroke={stroke || theme.palette.grays.med}
      />
    </svg>
  )
}

RadioBlankIcon.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string
}

export default RadioBlankIcon
