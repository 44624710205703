import { useEffect, useRef } from 'react'

import { useLatestCallback } from 'hooks'

const useConditionalInterval = (fn, active, interval) => {
  const currentFn = useLatestCallback(fn)
  const intervalRef = useRef(null)

  useEffect(() => {
    intervalRef.current && clearInterval(intervalRef.current)
    if (active) {
      intervalRef.current = setInterval(currentFn, interval)
    }
    return () => {
      intervalRef.current && clearInterval(intervalRef.current)
    }
  }, [active, interval])
}

export default useConditionalInterval
