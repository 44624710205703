import React from 'react'
import PropTypes from 'prop-types'

const ChevronDownIcon = ({ fill, className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99976 10.0344C5.00391 10.0949 5.00341 10.1103 5.01371 10.1702C5.03696 10.3049 5.08801 10.4344 5.16301 10.5487C5.20091 10.6065 5.24502 10.657 5.29207 10.7074L11.2923 16.7076C11.3367 16.749 11.3468 16.7606 11.3952 16.7973C11.4496 16.8386 11.5083 16.8743 11.57 16.9036C11.8395 17.0318 12.1594 17.0318 12.429 16.9036C12.4907 16.8743 12.5493 16.8386 12.6037 16.7973C12.6521 16.7606 12.6623 16.749 12.7066 16.7076L18.7068 10.7074C18.7482 10.663 18.7598 10.6529 18.7965 10.6045C18.893 10.4774 18.9581 10.3274 18.9853 10.1702C19.0046 10.0579 19.0046 9.94255 18.9853 9.8303C18.9698 9.74054 18.942 9.65299 18.9029 9.57074C18.7543 9.25832 18.4461 9.04081 18.102 9.00541C17.966 8.99146 17.8275 9.00571 17.6972 9.04707C17.5886 9.08152 17.4862 9.13457 17.3955 9.20337C17.347 9.24012 17.3369 9.25167 17.2926 9.29308L11.9995 14.5862L6.70633 9.29308L6.65643 9.24647C6.60942 9.20822 6.59847 9.19732 6.54767 9.16402C6.45247 9.10157 6.34661 9.05557 6.23601 9.02861C6.1475 9.00706 6.0561 8.99766 5.9651 9.00076C5.61933 9.01256 5.29707 9.20857 5.12751 9.51009C5.07171 9.60934 5.03306 9.71809 5.01371 9.8303C5.00341 9.8902 5.00391 9.9056 4.99976 9.9661C4.99976 9.98886 4.99976 10.0116 4.99976 10.0344Z"
    />
  </svg>
)

ChevronDownIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}

export default ChevronDownIcon
