import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withStyles, useTheme } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import {
  ORDER_STATES_TYPES,
  DELIVERY_STATUS_ICON_NAMES
} from 'constants/general'

import OrderStepperSvg from './OrderStepperSvg'

import styles from './OrderStepperStyles'

const OrderStepper = ({ classes, order }) => {
  const theme = useTheme()
  const DefaultColor = theme.palette.grays.med
  const BlueColor = theme.palette.blues.fast
  const RedColor = theme.palette.warnings.caution

  const DefaultData = {
    iconColor: DefaultColor,
    step1Color: DefaultColor,
    step2Color: DefaultColor,
    step3Color: DefaultColor,
    iconName: DELIVERY_STATUS_ICON_NAMES.house
  }

  const [currentData, setCurrentData] = useState(DefaultData)

  const formatOrderSubtitles = () => {
    const orderedAt = order.created_at && moment(order.created_at).format('L')

    const deliveryStartsAtText =
      order.delivery_starts_at && moment(order?.delivery_starts_at).format('h')
    const deliveryEndsAtText =
      order.delivery_ends_at && moment(order?.delivery_ends_at).format('ha')

    const orderedMessage = orderedAt ? `Ordered for ${orderedAt}` : ''
    const deliveryMessage =
      deliveryStartsAtText && deliveryEndsAtText
        ? `Window ${deliveryStartsAtText}-${deliveryEndsAtText}`
        : ''

    return {
      subtitle1: orderedMessage,
      subtitle2: deliveryMessage
    }
  }

  const getStepData = step => {
    const orderSubtitles = formatOrderSubtitles()

    switch (step) {
      case ORDER_STATES_TYPES.pending: {
        return {
          ...DefaultData,
          title: 'Order is pending',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      case ORDER_STATES_TYPES.paid: {
        return {
          iconColor: BlueColor,
          step1Color: BlueColor,
          step2Color: DefaultColor,
          step3Color: DefaultColor,
          iconName: DELIVERY_STATUS_ICON_NAMES.house,
          title: 'Order is being prepared',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      case ORDER_STATES_TYPES.picking: {
        return {
          iconColor: BlueColor,
          step1Color: BlueColor,
          step2Color: DefaultColor,
          step3Color: DefaultColor,
          iconName: DELIVERY_STATUS_ICON_NAMES.house,
          title: 'Order is being prepared',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      case ORDER_STATES_TYPES.packed: {
        return {
          iconColor: BlueColor,
          step1Color: BlueColor,
          step2Color: DefaultColor,
          step3Color: DefaultColor,
          iconName: DELIVERY_STATUS_ICON_NAMES.house,
          title: 'Order is being prepared',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      case ORDER_STATES_TYPES.dispatched: {
        return {
          iconColor: BlueColor,
          step1Color: BlueColor,
          step2Color: DefaultColor,
          step3Color: DefaultColor,
          iconName: DELIVERY_STATUS_ICON_NAMES.house,
          title: 'Order awaiting pickup',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      case ORDER_STATES_TYPES.shipped: {
        return {
          iconColor: BlueColor,
          step1Color: BlueColor,
          step2Color: BlueColor,
          step3Color: DefaultColor,
          iconName: DELIVERY_STATUS_ICON_NAMES.fastaf,
          title: 'Order is being delivered',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      case ORDER_STATES_TYPES.completed: {
        return {
          iconColor: BlueColor,
          step1Color: BlueColor,
          step2Color: BlueColor,
          step3Color: BlueColor,
          iconName: DELIVERY_STATUS_ICON_NAMES.door,
          title: 'Order was delivered',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      case ORDER_STATES_TYPES.returned: {
        return {
          iconColor: RedColor,
          step1Color: RedColor,
          step2Color: RedColor,
          step3Color: RedColor,
          iconName: DELIVERY_STATUS_ICON_NAMES.door,
          title: 'Order was returned',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      case ORDER_STATES_TYPES.canceled: {
        return {
          iconColor: RedColor,
          step1Color: RedColor,
          step2Color: DefaultColor,
          step3Color: DefaultColor,
          iconName: DELIVERY_STATUS_ICON_NAMES.house,
          title: 'Order was canceled',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      case ORDER_STATES_TYPES.declined: {
        return {
          iconColor: RedColor,
          step1Color: DefaultColor,
          step2Color: DefaultColor,
          step3Color: DefaultColor,
          iconName: DELIVERY_STATUS_ICON_NAMES.house,
          title: 'Order was declined',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      case ORDER_STATES_TYPES.refunded: {
        return {
          ...DefaultData,
          title: 'Order was refunded',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      case ORDER_STATES_TYPES.partially_refunded: {
        return {
          ...DefaultData,
          title: 'Order was partially refunded',
          subtitle1: orderSubtitles?.subtitle1,
          subtitle2: orderSubtitles?.subtitle2
        }
      }
      default: {
        return DefaultData
      }
    }
  }

  useEffect(() => {
    setCurrentData(getStepData(order?.status))
  }, [order])

  return (
    <Box className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        {currentData.title}
      </Typography>
      <OrderStepperSvg
        iconColor={currentData.iconColor}
        step1Color={currentData.step1Color}
        step2Color={currentData.step2Color}
        step3Color={currentData.step3Color}
        iconName={currentData.iconName}
      />
    </Box>
  )
}

OrderStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object
}

export default withStyles(styles)(OrderStepper)
