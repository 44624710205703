import { useEffect } from 'react'
import { usePrevious } from 'hooks'

// sets scrollY position of window based on a setting condition, i.e. when api calls are done
// also sets the scroll position when unmounting, i.e. a user navigates to a different page
export default function useRestoreScroll(element, key, setCondition = true) {
  const item = window.localStorage.getItem(key)
  const scrollYStorage = item ? JSON.parse(item) : 0
  const prev = usePrevious(element?.current)

  useEffect(() => {
    const handler = () => {
      window.localStorage.setItem(
        key,
        JSON.stringify(element?.current?.scrollTop)
      )
    }
    if (key && element?.current) {
      element.current.addEventListener('scroll', handler)
    }
    return () => element?.current?.removeEventListener('scroll', handler)
  }, [element])

  useEffect(() => {
    if (key && prev !== element?.current) {
      element.current.scrollTop = scrollYStorage
    }
  }, [prev, element])
}
