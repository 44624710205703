import { alpha } from '@material-ui/core/styles'
import { ABSOLUTE_FILL_FULL } from 'constants/styles'

const jssStyles = theme => ({
  root: {},
  cropContainer: {
    height: '472px',
    marginTop: theme.spacing(2),
    border: 'solid 1px',
    borderColor: theme.palette.grays.med
  },
  subtitle: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: 0,
    color: theme.palette.grays.dark,
    maxWidth: 354
  },
  header: {
    margin: theme.spacing(2, 2, 0, 2)
  },
  content: {
    padding: theme.spacing(0, 2, 2, 2)
  },
  textField: {
    margin: 0,
    '& .MuiSelect-selectMenu': {
      padding: theme.spacing(1.5, 1.5)
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.5, 1.5)
    }
  },
  cropperContainer: {
    height: 400,
    position: 'relative'
  },
  croppedImagePreview: {
    position: 'relative',
    width: '100%',
    height: 304,
    border: 'solid 1px',
    borderColor: theme.palette.grays.med,
    color: theme.palette.grays.dark,
    backgroundColor: theme.palette.grays.light,
    overflow: 'hidden'
  },
  round: {
    ...ABSOLUTE_FILL_FULL,
    borderRadius: '100%',
    margin: 'auto',
    maxWidth: 300,
    maxHeight: 300
  },
  deletePreview: {
    position: 'absolute',
    top: 4,
    right: 4
  },
  iconButton: {
    backgroundColor: alpha(theme.palette.common.black, 0.4),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.7)
    }
  }
})

export default jssStyles
