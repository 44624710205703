import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useLocationsContext } from 'context'
import { useControlledFormController } from 'hooks'
import AutocompleteSelect from 'components/AutocompleteSelect'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'

import styles from './CollectionDetailsViewStyles'
import { FORM_FIELDS } from './CollectionFormFields'

const CollectionAvailability = ({ classes, collection }) => {
  const {
    field: { onChange: setLocationIds }
  } = useControlledFormController({
    name: FORM_FIELDS.LOCATION_IDS
  })
  const {
    field: { onChange: setPublishedLocations, value: publishedLocations }
  } = useControlledFormController({
    name: FORM_FIELDS.LOCATIONS,
    onChange: locs => {
      setLocationIds(locs.map(l => l.id))
    }
  })

  const { locations, location, showAllLocationIds } = useLocationsContext()

  const isPublishedAndSaved = loc => collection.location_ids.includes(loc.id)
  const isPublished = loc => publishedLocations?.includes(loc)

  const onChange = isChecked => {
    if (isChecked && !isPublished(location)) {
      setPublishedLocations([...publishedLocations, location])
    }

    if (!isChecked && isPublished(location)) {
      setPublishedLocations(publishedLocations.filter(loc => loc !== location))
    }
  }

  if (!locations || !collection) {
    return <></>
  }

  return showAllLocationIds ? (
    <Box>
      <Typography variant="caption" color="textSecondary">
        Publish To
      </Typography>
      <AutocompleteSelect
        multiple
        placeholder="Select locations"
        options={locations?.filter(loc => loc.active) ?? []}
        value={publishedLocations ?? []}
        onChange={setPublishedLocations}
        getOptionLabel={loc => loc.display_name}
        getTagLabel={loc => loc.city}
        dataTest="collection-location"
        groupBy={loc =>
          isPublishedAndSaved(loc) ? 'Published' : 'Unpublished'
        }
        actions={[
          <Button
            variant="outlined"
            size="small"
            color="default"
            onClick={() => setPublishedLocations(locations)}
          >
            All Locations
          </Button>,
          <Button
            variant="outlined"
            size="small"
            color="default"
            onClick={() => setPublishedLocations([])}
          >
            Clear
          </Button>
        ]}
      />
    </Box>
  ) : (
    <Box className={classes.checkBoxItem}>
      <Box>
        <Typography variant="body1">Publish collection?</Typography>
        <Typography variant="body1" color="textSecondary">
          This collection will show in {location.city}?
        </Typography>
      </Box>
      <Checkbox checked={isPublished(location)} onChange={onChange} />
    </Box>
  )
}

CollectionAvailability.propTypes = {
  classes: PropTypes.object,
  collection: PropTypes.object
}

export default withStyles(styles)(CollectionAvailability)
