import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { ALL_LOCATION } from 'constants/general'

import Checkbox from 'components/Checkbox'
import ProductSummary from 'components/ProductSummary'

import styles from './VariantItemStyles'

const VariantItem = ({
  classes,
  variant,
  handleSelect,
  selected,
  locationId,
  onProductSummaryClick
}) => {
  const length = variant.length || ''
  const width = variant.width || ''
  const height = variant.height || ''
  const unit = variant.unit || ''
  const dimensions = `${length}${unit} / ${width}${unit} / ${height}${unit}`

  const inventory = variant.inventory.find(
    inv => inv.location_id === locationId
  )

  return (
    <TableRow>
      <TableCell className={classes.productCell}>
        <Checkbox
          onChange={state => handleSelect(variant, state)}
          checked={selected}
        />
        <ProductSummary
          product={variant}
          onClick={onProductSummaryClick}
          productVariantListItem
        />
      </TableCell>
      <TableCell>{variant.sku}</TableCell>
      <TableCell>{variant.barcode}</TableCell>
      <TableCell>{dimensions}</TableCell>
      <TableCell>{variant.size}</TableCell>
      <TableCell>{variant?.status === 'archived' ? 'Yes' : 'No'}</TableCell>
      <TableCell>{variant?.holiday ? 'Yes' : 'No'}</TableCell>
      {locationId !== ALL_LOCATION.id && (
        <>
          <TableCell data-test="do-not-buy">{variant?.do_not_buy ? 'Yes' : 'No'}</TableCell>
          <TableCell>{inventory?.bin}</TableCell>
          <TableCell>{inventory?.quantity}</TableCell>
        </>
      )}
    </TableRow>
  )
}

VariantItem.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
  handleSelect: PropTypes.func,
  selected: PropTypes.bool,
  locationId: PropTypes.number,
  onProductSummaryClick: PropTypes.func
}

export default withStyles(styles)(VariantItem)
