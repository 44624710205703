import { isAdmin, isSuper } from 'utils/user'
import { ORDER_WRITABLE_TRANSITIONS } from 'constants/general'

import {
  INTERNAL_USER_ROLE_OPTIONS,
  INTERNAL_USER_ROLE_OPTIONS_WITHOUT_SUPER
} from 'constants/internalUsers'

// ----------------------
// Internal User Policies
// ----------------------
export const canManageInternalUsers = user => isAdmin(user)
export const canManageSuperAdmin = user => isSuper(user)
export const canEditInternalUser = (user, userToEdit) => {
  if (isSuper(userToEdit)) {
    return canManageInternalUsers(user) && canManageSuperAdmin(user)
  } else {
    return canManageInternalUsers(user)
  }
}
export const internalUserRoleOptions = (user, userToEdit) => {
  if (!canManageSuperAdmin(user) && !isSuper(userToEdit)) {
    return INTERNAL_USER_ROLE_OPTIONS_WITHOUT_SUPER
  } else {
    return INTERNAL_USER_ROLE_OPTIONS
  }
}

// -------------
// User Policies
// -------------
export const canBlockUser = user => isAdmin(user)

// --------------
// Order Policies
// --------------
export const orderWritableTransitions = user => {
  return ORDER_WRITABLE_TRANSITIONS.filter(option => {
    if (isSuper(user)) {
      return true
    } else {
      return option.label != 'Canceled'
    }
  })
}
