import { alpha } from '@material-ui/core/styles'

const jssStyles = theme => ({
  root: {
    position: 'relative'
  },
  bottomOverlay: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: theme.spacing(0, 2.5, 1, 1.5),
    backgroundColor: alpha(theme.palette.colorsAF.white, 0.6)
  }
})

export default jssStyles
