import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'

import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeGrid } from 'react-window'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { SCROLL_WIDTH_PX } from 'constants/styles'
import { COLLECTIONS_BLOCK_ASPECT_RATIO } from 'constants/collections'
import { COLLECTION_LAYOUT_VARIANT } from 'constants/enums'

import CollectionVirtualizedCell from 'components/CollectionVirtualizedCell'
import Checkbox from 'components/Checkbox'
import DateRangePicker from 'components/DateRangePickerNew'
import Dialog from 'components/Dialog'

import styles from './BulkExportModalStyles'

const BulkExportModal = ({
  classes,
  items,
  open,
  onClose,
  onExport,
  handleRemoveBrandFromBulkExports
}) => {
  const [customerReport, setCustomerReport] = useState(false)
  const [inventoryReport, setInventoryReport] = useState(false)
  const [paymentReport, setPaymentReport] = useState(false)
  const [dateRange, setDateRange] = useState([
    moment().startOf('day'),
    moment().endOf('day')
  ])

  useEffect(() => {
    setCustomerReport(false)
    setInventoryReport(false)
    setPaymentReport(false)
  }, [open])

  useEffect(() => {
    if (items?.length < 1 && open) {
      onClose()
    }
  }, [items])

  const handleExport = () =>
    onExport({
      brandIds: items?.map(i => i.id),
      dateRange,
      customerReport,
      inventoryReport,
      paymentReport
    })

  const SizedGrid = ({ width, height }) => {
    const columnCount = items.length > 2 ? 2 : 1
    const rowCount = Math.ceil(items.length / columnCount)

    const columnWidth = Math.floor((width - SCROLL_WIDTH_PX) / columnCount)
    const rowHeight = Math.floor(
      width / columnCount / COLLECTIONS_BLOCK_ASPECT_RATIO
    )

    return (
      <FixedSizeGrid
        columnCount={columnCount}
        rowCount={rowCount}
        height={height}
        width={width}
        columnWidth={columnWidth}
        rowHeight={rowHeight}
        itemData={{
          items,
          columnCount,
          onSelect: handleRemoveBrandFromBulkExports,
          withRemove: true,
          showPublish: false,
          variant: COLLECTION_LAYOUT_VARIANT.brand
        }}
      >
        {CollectionVirtualizedCell}
      </FixedSizeGrid>
    )
  }

  return (
    <Dialog
      className={classes.root}
      title="Export Brand Data"
      open={open}
      onConfirm={handleExport}
      onCancel={onClose}
    >
      <Box mb={1}>
        <Box mb={1}>
          <Typography variant="body2">Date Range</Typography>
        </Box>
        <DateRangePicker
          name="dateRange"
          value={dateRange}
          onChange={newDateRange => setDateRange(newDateRange)}
        />
      </Box>

      <Box mt={2}>
        <Box mb={1}>
          <Typography variant="body2">I want to export</Typography>
        </Box>

        <Box display="flex" flexDirection="column">
          <Checkbox
            label="Customer Report"
            boundingBox
            onChange={checked => setCustomerReport(checked)}
          />
          <Checkbox
            label="Inventory Report"
            boundingBox
            onChange={checked => setInventoryReport(checked)}
          />
          <Checkbox
            label="Payment Report"
            boundingBox
            onChange={checked => setPaymentReport(checked)}
          />
        </Box>
      </Box>
      <Box mb={1} mt={1}>
        <Typography variant="body2">BRANDS</Typography>
      </Box>
      <Box flex={1} mx={-2} height={200} borderRadius={5}>
        <AutoSizer>{SizedGrid}</AutoSizer>
      </Box>
    </Dialog>
  )
}

BulkExportModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  handleRemoveBrandFromBulkExports: PropTypes.func.isRequired
}

export default withStyles(styles)(BulkExportModal)
