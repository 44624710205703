import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { withStyles } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'

import Dialog from 'components/Dialog'
import ImageDropzone from 'components/ImageDropzone'
import VideoPlayer from 'components/VideoPlayer'
import ClearIcon from '@material-ui/icons/Clear'

import styles from './MediaDialogStyles'

const MediaDialog = ({
  classes,
  open,
  onClose,
  name,
  urlName,
  accept,
  video,
  isVideo,
  placeholder,
  subtitle,
  onCancel,
  defaultUrl,
  onClear
}) => {
  const [localVideo, setLocalVideo] = useState()

  useEffect(() => {
    setLocalVideo(video)
  }, [video])

  const { watch, setValue } = useFormContext()

  const shouldCreateObjectUrl = localVideo && typeof localVideo !== 'string'

  const handleDelete = () => {
    setLocalVideo(null)
    setValue(name, null, { shouldValidate: false })
    setValue(urlName, null, { shouldValidate: false })
    onClear && onClear()
  }

  const { [urlName]: currentImageUrl } = watch(
    [name, urlName].filter(n => n && typeof n === 'string')
  )

  return (
    <Dialog
      classes={{ subtitle: classes.subtitle }}
      open={open}
      onClose={onClose}
      title="Upload media"
      subtitle={subtitle}
      onCancel={onCancel}
    >
      <Box
        maxWidth={568}
        height={!isVideo ? 304 : 'auto'}
        m="auto"
        py={2}
        position="relative"
      >
        {!isVideo ? (
          <ImageDropzone
            name={name}
            urlName={urlName}
            accept={accept}
            placeholder={placeholder}
            imageCover
            noUnregister
            defaultUrl={currentImageUrl && defaultUrl}
          />
        ) : (
          <>
            <VideoPlayer
              url={
                shouldCreateObjectUrl
                  ? URL.createObjectURL(localVideo)
                  : localVideo
              }
            />
            <Box className={classes.buttonContainer}>
              <IconButton
                classes={{ root: classes.delButton }}
                onClick={handleDelete}
                color="secondary"
              >
                <ClearIcon />
              </IconButton>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  )
}

MediaDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  name: PropTypes.string,
  urlName: PropTypes.string,
  subtitle: PropTypes.string,
  placeholder: PropTypes.string,
  accept: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  video: PropTypes.object,
  isVideo: PropTypes.bool,
  onDelete: PropTypes.func,
  defaultUrl: PropTypes.string
}

export default withStyles(styles)(MediaDialog)
