const jssStyles = theme => ({
  TableContainer: {
    height: 'calc(100vh - 220px)'
  },
  statusLabel: {
    maxWidth: '80px',
    height: '28px',
    paddingTop: '3px',
    textAlign: 'center',
    padding: '10px',
    borderRadius: '5px',
    textTransform: 'capitalize'
  },
  statusLabelSent: {
    color: 'green',
    backgroundColor: 'rgba(0, 255, 0, 0.1)',
  },
  statusLabelSending: {
    color: 'orange',
    backgroundColor: 'rgba(255, 140, 0, 0.1)',
  },
  statusLabelPending: {
    color: 'purple',
    backgroundColor: 'rgba(255, 0, 255, 0.1)',
  },
  statusLabelError: {
    color: 'red',
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
  },
  row: {
    height: '70px'
  }
})

export default jssStyles