const jssStyles = theme => ({
  root: {
    width: theme.layout.drawerWidth
  },
  drawerPaper: {
    direction: 'rtl',
    width: theme.layout.drawerWidth,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'flex-start',
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.colorsAF.ultraLightGray,
      width: 3
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.colorsAF.ultraLightGray
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.colorsAF.lightGray
    },
    '& > *': {
      direction: 'ltr',
      width: theme.layout.drawerWidth - 3
    }
  },
  logoutButton: {
    borderColor: theme.palette.common.black
  },
  list: {
    marginTop: theme.spacing(2)
  },
  locationListItem: {
    height: 60,
    paddingLeft: theme.spacing(3),
    paddingRight: 0
  },
  locationListText: {
    marginLeft: 13,
    marginRight: 23
  },
  locationListSecondary: {
    right: 15,
    height: 16
  },
  listItem: {
    position: 'relative',
    height: 40,
    minHeight: 40,
    marginTop: theme.spacing(2),
    padding: 0,
    paddingLeft: theme.spacing(3),
    color: theme.palette.grays.dark,
    opacity: 0.7,

    '& > *:first-child': {
      width: 36
    }
  },
  subListItem: {
    paddingLeft: 68
  },
  listItemActive: {
    color: theme.palette.text.primary,
    opacity: 1,

    '& $navIcon': {
      fill: theme.palette.text.primary
    }
  },
  listItemSelected: {
    color: theme.palette.text.primary,
    opacity: 1,

    '& $navIcon': {
      fill: theme.palette.primary.main
    },

    '&:after': {
      content: '""',
      top: 0,
      right: 0,
      width: 4,
      height: '100%',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute'
    }
  },
  userListItem: {
    paddingLeft: theme.spacing(3)
  },
  navIcon: {
    fill: theme.palette.grays.dark
  }
})

export default jssStyles
