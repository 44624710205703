import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'

import { useAdminOrders } from 'hooks'

import Checkbox from 'components/Checkbox'
import RefundDetails from 'components/RefundDetails'

import styles from './RefundSummaryStyles'

const MIN_INPUT_VALUE = '0'
const STEP_INPUT_VALUE = '0.01'

const RefundSummary = ({
  classes,
  order,
  returnData,
  onChangeNotifyClient,
  onChangeStoreCreditRefund,
  onChangePaymentMethodRefund,
  notifyClient
}) => {
  const { line_item_returns: returnlineItems } = returnData
  const {
    credit_refunded: creditRefunded,
    credit_applied: storeCredit,
    delivery_method: deliveryMethod,
    delivery_fee: shipping,
    id: orderId,
    total_discounts: discount,
    driver_benefits: driverBenefits
  } = order
  const { refundTaxes } = useAdminOrders({})

  const [inputStoreCredit, setInputStoreCredit] = useState(0)
  const [inputPaymentMethod, setInputPaymentMethod] = useState(0)
  const [tax, setTax] = useState(0)
  const [totalRefund, setTotalRefund] = useState(0)

  const subtotal = !isEmpty(returnlineItems)
    ? returnlineItems?.reduce((a, b) => a + b.quantity * b.line_item.price, 0)
    : order?.total_price

  const itemsCount = returnlineItems?.reduce((a, b) => a + b.quantity, 0)
  const totalItems = order.line_items?.length || 0

  const maxPaymentMethod = useMemo(
    () =>
      Math.max(
        Number(order.total_price) - Number(order.stripe_amount_refunded),
        0
      ),
    [order]
  )

  const maxOrderCredit = useMemo(
    () =>
      Math.max(Number(order.credit_applied) - Number(order.credit_refunded), 0),
    [order]
  )

  const refundedShipping = totalItems === itemsCount ? Number(shipping) : 0
  const summaryTotal = useMemo(
    () => Number(subtotal) + Number(tax) + refundedShipping + Number(discount),
    [order, tax, subtotal]
  )

  const storeCreditRefund = useMemo(
    () => Math.max(Math.min(maxOrderCredit, summaryTotal), 0),
    [order, tax, subtotal]
  )

  const paymentMethodRefund = useMemo(
    () => Math.max(summaryTotal - storeCreditRefund, 0),
    [order, tax, subtotal]
  )

  useEffect(() => {
    setInputStoreCredit(storeCreditRefund.toFixed(2))
  }, [storeCreditRefund])

  useEffect(() => {
    setInputPaymentMethod(paymentMethodRefund.toFixed(2))
  }, [paymentMethodRefund])

  useEffect(() => {
    ;(async () => {
      if (isEmpty(returnlineItems)) {
        setTax(order?.total_tax)
        return
      }
      const response = await refundTaxes(orderId, returnlineItems)
      setTax(response)
    })()
  }, [order, returnData])

  useEffect(() => {
    setTotalRefund(storeCreditRefund + paymentMethodRefund)
  }, [storeCreditRefund, paymentMethodRefund])

  useEffect(() => {
    onChangePaymentMethodRefund(paymentMethodRefund)
  }, [paymentMethodRefund])

  useEffect(() => {
    onChangeStoreCreditRefund(storeCreditRefund)
  }, [storeCreditRefund])

  const handleInputPaymentMethod = e => {
    const { value } = e.target
    const parsedValue = Number(value).toFixed(2)

    if (value === MIN_INPUT_VALUE) {
      setInputPaymentMethod(0)
      onChangePaymentMethodRefund(paymentMethodRefund)
      return
    }

    if (!value) {
      setInputPaymentMethod(null)
      onChangePaymentMethodRefund(paymentMethodRefund)
      return
    }

    if (Number(value) > maxPaymentMethod.toFixed(2)) {
      return
    }

    setInputPaymentMethod(parsedValue)
    onChangePaymentMethodRefund(parsedValue)
  }

  const handleInputStoreCredit = e => {
    const { value } = e.target
    const parsedValue = Number(value).toFixed(2)

    if (value === MIN_INPUT_VALUE) {
      setInputStoreCredit(0)
      onChangeStoreCreditRefund(storeCreditRefund)
      return
    }

    if (!value) {
      setInputStoreCredit(null)
      onChangeStoreCreditRefund(storeCreditRefund)
      return
    }

    if (Number(value) > maxOrderCredit.toFixed(2)) {
      return
    }

    setInputStoreCredit(parsedValue)
    onChangeStoreCreditRefund(parsedValue)
  }

  return (
    <Box>
      <Box pb={3}>
        <Typography variant="h5">Summary</Typography>
      </Box>
      <RefundDetails
        details={{
          subtotal,
          tax,
          storeCredit,
          creditRefunded,
          shipping: refundedShipping,
          totalRefund,
          deliveryMethod,
          itemsCount,
          storeCreditRefund,
          paymentMethodRefund,
          discount,
          inputPaymentMethod,
          inputStoreCredit,
          driverBenefits
        }}
      />
      <Box pb={2} pt={4}>
        <Typography variant="h5">Manual Refund</Typography>
      </Box>
      <Box display="flex" flexDirection="column" pb={4}>
        <Typography variant="caption" className={classes.inputCaption}>
          Store credit
        </Typography>
        <OutlinedInput
          id="store-credit"
          value={parseFloat(inputStoreCredit)}
          onChange={handleInputStoreCredit}
          onFocus={() =>
            Number(inputStoreCredit) === 0 && setInputStoreCredit(null)
          }
          onBlur={() => inputStoreCredit === null && setInputStoreCredit(0)}
          type="number"
          startAdornment={
            <Box pr={0.5}>
              <InputAdornment>$</InputAdornment>
            </Box>
          }
          inputProps={{
            min: 0,
            max: maxOrderCredit.toFixed(2),
            step: STEP_INPUT_VALUE,
            placeholder: `Max: ${maxOrderCredit.toFixed(2)}`
          }}
        />
        <FormHelperText>
          ${parseFloat(maxOrderCredit).toFixed(2)} available for refund
        </FormHelperText>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="caption" className={classes.inputCaption}>
          Payment method
        </Typography>
        <OutlinedInput
          id="payment-method-refund"
          value={parseFloat(inputPaymentMethod)}
          onChange={handleInputPaymentMethod}
          onFocus={() =>
            Number(inputPaymentMethod) === 0 && setInputPaymentMethod(null)
          }
          onBlur={() => inputPaymentMethod === null && setInputPaymentMethod(0)}
          type="number"
          startAdornment={
            <Box pr={0.5}>
              <InputAdornment>$</InputAdornment>
            </Box>
          }
          inputProps={{
            min: 0,
            max: maxPaymentMethod.toFixed(2),
            step: STEP_INPUT_VALUE,
            placeholder: `Max: ${maxPaymentMethod.toFixed(2)}`
          }}
        />
        <FormHelperText>
          ${parseFloat(maxPaymentMethod).toFixed(2)} available for refund
        </FormHelperText>
      </Box>
      <Box pb={2} pt={4}>
        <Typography variant="h5">Notifications</Typography>
      </Box>
      <Box>
        <Checkbox
          className={classes.checkbox}
          checked={notifyClient}
          onChange={onChangeNotifyClient}
          disabled={order.crypto_used_as_payment}
          label="Notify customer of refund."
        />
      </Box>
    </Box>
  )
}

RefundSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object,
  returnData: PropTypes.object,
  onChangeNotifyClient: PropTypes.func,
  onChangeStoreCreditRefund: PropTypes.func,
  onChangePaymentMethodRefund: PropTypes.func,
  notifyClient: PropTypes.bool
}

export default withStyles(styles)(RefundSummary)
