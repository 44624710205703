import { ME } from 'constants/general'
import { isProductionInstance } from 'utils/general'

import { FaChartPie } from 'react-icons/fa'

import BOHIcon from 'icons/BOHIcon'
import OrdersIcon from 'icons/OrdersIcon'
import ProductsIcon from 'icons/ProductsIcon'
import CollectionsIcon from 'icons/CollectionsIcon'
import BrandsIcon from 'icons/BrandsIcon'
import ShopIcon from 'icons/ShopIcon'
import ProfileIcon from 'icons/ProfileIcon'

import { MdInventory } from 'react-icons/md'

export const URL = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot_password',
  RESET_PASSWORD: '/reset_password',

  // ADMIN
  ADMIN_USERS: '/admin/users',
  ADMIN_USERS_EDIT: '/admin/users/edit',
  ADMIN_DELIVERED_ORDERS: '/admin/delivered_orders',
  ADMIN_ORDERS: '/admin/orders',
  ADMIN_PRODUCTS: '/admin/products',
  ADMIN_SUPPLIERS: '/admin/suppliers',
  ADMIN_LICENSE_PLATES: '/admin/inventory',
  ADMIN_COLLECTIONS: '/admin/collections',
  ADMIN_COLLECTION_GROUPS: '/admin/collection_groups',
  ADMIN_BRANDS: '/admin/brands',
  ADMIN_BRAND_CUSTOMERS: '/admin/brands/customers',
  ADMIN_ANALYTICS: '/admin/analytics',

  // ADMIN BOH
  ADMIN_ACCESSORY_OPTIONS: '/admin/accessory_options',
  ADMIN_AREAS: '/admin/areas',
  ADMIN_BADGES: '/admin/badges',
  ADMIN_BOH: '/admin/boh',
  ADMIN_CATEGORIES: '/admin/categories',
  ADMIN_COLORS: '/admin/colors',
  ADMIN_GMV: '/admin/gmv',
  ADMIN_HOURS_OF_OPERATION: '/admin/hours_of_operation',
  ADMIN_NOTIFICATION_CENTER: '/admin/notification_center',
  ADMIN_INTERNAL_USERS: '/admin/internal_users',

  // ADMIN BOH PROMOTIONS
  ADMIN_PROMOTIONS: '/admin/promotions',
  ADMIN_PROMOTIONS_NEW: '/admin/promotions/new',

  // ADMIN BOH DISCOUNT CODES
  ADMIN_PROMO_CODES_HISTORY: '/admin/promo_codes/history',
  ADMIN_PROMO_CODES: '/admin/promo_codes',

  ADMIN_PURCHASE_ORDERS: '/admin/po',
  ADMIN_SIZES: '/admin/sizes',
  ADMIN_STATUS_CODES: '/admin/status_codes',
  ADMIN_STOREFRONT: '/admin/storefronts',
  ADMIN_TRANSFER_ORDERS: '/admin/to'
}

export const NAV_ROUTES = {
  adminUserMe: {
    value: 'adminUserMe',
    text: 'My Account',
    path: `${URL.ADMIN_USERS}/${ME}`
  },
  adminOrders: {
    value: 'adminOrders',
    path: URL.ADMIN_ORDERS,
    text: 'Orders',
    icon: OrdersIcon
  },
  adminProducts: {
    value: 'adminProducts',
    path: URL.ADMIN_PRODUCTS,
    text: 'Products',
    icon: ProductsIcon
  },
  adminLicensePlates: {
    value: 'adminLicensePlates',
    path: URL.ADMIN_LICENSE_PLATES,
    text: 'Inventory',
    icon: MdInventory
  },
  adminStorefront: {
    value: 'adminStorefront',
    path: URL.ADMIN_STOREFRONT,
    text: 'Storefronts',
    icon: ShopIcon,
    disableForSpecificLocation: true
  },
  login: {
    value: 'login',
    path: URL.LOGIN,
    text: 'Sign in'
  },
  logout: {
    value: 'logout',
    path: URL.LOGOUT,
    text: 'Sign out'
  },
  adminCollections: {
    value: 'adminCollections',
    path: URL.ADMIN_COLLECTIONS,
    text: 'Collections',
    icon: CollectionsIcon,
    disableForSpecificLocation: true
  },
  adminCollectionGroups: {
    value: 'adminCollectionGroups',
    path: URL.ADMIN_COLLECTION_GROUPS,
    text: 'Collection Groups'
  },
  adminBrands: {
    value: 'adminBrands',
    path: URL.ADMIN_BRANDS,
    text: 'Brands',
    icon: BrandsIcon,
    disableForSpecificLocation: true
  },
  adminUsers: {
    value: 'adminUsers',
    path: URL.ADMIN_USERS,
    text: 'Users',
    icon: ProfileIcon,
    disableForSpecificLocation: true
  },
  boh: {
    value: 'boh',
    path: URL.ADMIN_BOH,
    text: 'BOH',
    icon: BOHIcon
  },
  analytics: {
    value: 'analytics',
    path: URL.ADMIN_ANALYTICS,
    text: 'Analytics',
    icon: FaChartPie
  }
}

const hiddenRoutes = new Set([])

const allRoutes = [
  NAV_ROUTES.adminOrders,
  NAV_ROUTES.adminBrands,
  NAV_ROUTES.adminProducts,
  NAV_ROUTES.adminCollections,
  NAV_ROUTES.adminStorefront,
  NAV_ROUTES.adminLicensePlates,
  NAV_ROUTES.adminUsers,
  NAV_ROUTES.boh,
  NAV_ROUTES.analytics
]

export const ADMIN_NAV_ROUTES = isProductionInstance()
  ? allRoutes.filter(route => !hiddenRoutes.has(route))
  : allRoutes
