const jssStyles = theme => ({
  root: {
    overflow: 'auto'
  },
  header: {
    position: 'sticky',
    backgroundColor: theme.palette.background.default,
    zIndex: 100,
    top: 0,
    padding: theme.spacing(3, 3, 0, 3)
  },
  content: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 3, 0, 3)
  },
  menuIconButton: {
    color: theme.palette.text.primary
  },
  menuPaper: {
    background: theme.palette.background.default,
    minWidth: 92
  },
  popover: {
    top: 68
  },
  tooltipDescription: {
    color: theme.palette.grays.dark
  },
  editableListItem: {
    padding: 0
  },
  servicedLabel: {
    color: theme.palette.warnings.danger
  },
  isServicedLabel: {
    color: theme.palette.warnings.success
  },
  bold: {
    cursor: 'pointer',
    fontWeight: 900,
    backgroundColor: theme.palette.warnings.success
  },
  clickable: {
    cursor: 'pointer'
  },
  tip: {
    marginLeft: theme.spacing(3)
  }
})

export default jssStyles
