import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { useForm, FormProvider } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { Box, Typography, Grid } from '@material-ui/core'

import { makeObjectResolver } from 'constants/yupSchemas'
import { centsToDollars, dollarsToCents } from 'utils/general'
import { useAdminLocation, useConditionalEffect } from 'hooks'

import { TextFieldControlled } from 'components/TextField'
import Dialog from 'components/Dialog'
import PrinterConfig from 'components/PrinterConfigForm'

import styles from './DarkstoreModalStyles'

const TEST_ID = 'darkstores'

const FIELDS = {
  name: 'name',
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  state: 'state',
  zipcode: 'zipcode',
  return_instructions: 'return_instructions',
  pickup_instructions: 'pickup_instructions',
  freight_instructions: 'freight_delivery_instructions',
  shipping_tier1_price: 'shipping_tier1_price',
  shipping_tier1_threshold: 'shipping_tier1_threshold',
  shipping_tier2_price: 'shipping_tier2_price',
  delivery_window_cutoff_minutes: 'delivery_window_cutoff_minutes',
  delivery_window_order_limit: 'delivery_window_order_limit'
}

const yupUserSchemas = {
  [FIELDS.name]: yup.string().required('Name is required'),
  [FIELDS.address1]: yup.string().required('Street Address is required'),
  [FIELDS.address2]: yup.string(),
  [FIELDS.city]: yup.string().required('City is required'),
  [FIELDS.state]: yup.string().required('State is required'),
  [FIELDS.zipcode]: yup.string().required('Zip is required'),
  [FIELDS.return_instructions]: yup.string(),
  [FIELDS.pickup_instructions]: yup.string(),
  [FIELDS.freight_instructions]: yup.string(),
  [FIELDS.shipping_tier1_price]: yup
    .number()
    .min(0, '$ amount greater than 0 required.'),
  [FIELDS.shipping_tier1_threshold]: yup
    .number()
    .min(0, '$ amount greater than 0 required.'),
  [FIELDS.shipping_tier2_price]: yup
    .number()
    .min(0, '$ amount greater than 0 required.'),
  [FIELDS.delivery_window_cutoff_minutes]: yup
    .number()
    .min(0, 'time in minutes greater than 0'),
  [FIELDS.delivery_window_order_limit]: yup
    .number()
    .min(0, 'quantity greater than 0 required')
}

const DarkstoreModal = ({
  classes,
  open,
  activeDarkstoreId,
  onClose,
  onSubmit
}) => {
  const { createLocation, updateLocation, location } = useAdminLocation({
    id: activeDarkstoreId
  })

  const getWarehouseDefaults = wh => ({
    [FIELDS.name]: wh?.name ?? '',
    [FIELDS.address1]: wh?.address1 ?? '',
    [FIELDS.address2]: wh?.address2 ?? '',
    [FIELDS.city]: wh?.city ?? '',
    [FIELDS.state]: wh?.state ?? '',
    [FIELDS.zipcode]: wh?.zipcode ?? '',
    [FIELDS.return_instructions]: wh?.return_instructions ?? '',
    [FIELDS.pickup_instructions]: wh?.pickup_instructions ?? '',
    [FIELDS.freight_instructions]: wh?.freight_delivery_instructions ?? '',
    [FIELDS.shipping_tier1_price]:
      centsToDollars(wh?.shipping_tier1_price) ?? 0,
    [FIELDS.shipping_tier1_threshold]:
      centsToDollars(wh?.shipping_tier1_threshold) ?? 0,
    [FIELDS.shipping_tier2_price]:
      centsToDollars(wh?.shipping_tier2_price) ?? 0,
    [FIELDS.delivery_window_cutoff_minutes]:
      wh?.delivery_window_cutoff_minutes ?? 0,
    [FIELDS.delivery_window_order_limit]: wh?.delivery_window_order_limit ?? 0
  })

  const formContext = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: makeObjectResolver(yupUserSchemas),
    defaultValues: getWarehouseDefaults(location)
  })

  const { handleSubmit, reset } = formContext

  const handleClose = () => {
    onClose && onClose()
  }

  const submit = async ({
    shipping_tier1_price,
    shipping_tier1_threshold,
    shipping_tier2_price,
    ...darkstoreProps
  }) => {
    const execute = !isEmpty(location) ? updateLocation : createLocation
    await execute({
      ...darkstoreProps,
      shipping_tier1_price: dollarsToCents(shipping_tier1_price),
      shipping_tier1_threshold: dollarsToCents(shipping_tier1_threshold),
      shipping_tier2_price: dollarsToCents(shipping_tier2_price),
      country: 'United States'
    })
    onSubmit && onSubmit()
  }

  useConditionalEffect(() => {
    reset(getWarehouseDefaults(location))
  }, [activeDarkstoreId, location])

  return (
    <Dialog
      title={`${location ? 'Edit' : 'Add'} Darkstore`}
      open={open}
      onCancel={handleClose}
      onConfirm={handleSubmit(submit)}
      dataTest={`${TEST_ID}Modal`}
    >
      <FormProvider {...formContext}>
        <Box>
          <Typography variant="body2">Darkstore ID</Typography>
          <Typography className={classes.darkstoreSubtitle}>
            Set a name for this location example: DS_LA1
          </Typography>
        </Box>
        <Box mt={3}>
          <TextFieldControlled
            name={FIELDS.name}
            placeholder="Enter a name"
            fullWidth
            className={classes.textField}
            centeredInput
            disabled={Boolean(activeDarkstoreId)}
            dataTest={`${TEST_ID}-${FIELDS.name}`}
          />
        </Box>
        <Box mt={3}>
          <Typography variant="body2">Darkstore Address</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextFieldControlled
                name={FIELDS.address1}
                placeholder="Street Address"
                fullWidth
                className={classes.textField}
                centeredInput
                dataTest={`${TEST_ID}-${FIELDS.address1}`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldControlled
                name={FIELDS.address2}
                placeholder="Unit Number"
                fullWidth
                className={classes.textField}
                centeredInput
                dataTest={`${TEST_ID}-${FIELDS.address2}`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldControlled
                name={FIELDS.city}
                placeholder="City"
                fullWidth
                className={classes.textField}
                centeredInput
                dataTest={`${TEST_ID}-${FIELDS.city}`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldControlled
                name={FIELDS.state}
                placeholder="State"
                fullWidth
                className={classes.textField}
                centeredInput
                dataTest={`${TEST_ID}-${FIELDS.state}`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldControlled
                name={FIELDS.zipcode}
                placeholder="Zip"
                fullWidth
                className={classes.textField}
                centeredInput
                dataTest={`${TEST_ID}-${FIELDS.zipcode}`}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={3}>
          <Typography variant="body2">Dropoff Instructions</Typography>
          <TextFieldControlled
            name={FIELDS.return_instructions}
            placeholder="Add instructions..."
            fullWidth
            className={classes.textField}
            centeredInput
            dataTest={`${TEST_ID}-${FIELDS.return_instructions}`}
          />
        </Box>

        <Box mt={3}>
          <Typography variant="body2">Pickup Instructions</Typography>
          <TextFieldControlled
            name={FIELDS.pickup_instructions}
            placeholder="Add instructions..."
            fullWidth
            className={classes.textField}
            centeredInput
            dataTest={`${TEST_ID}-${FIELDS.pickup_instructions}`}
          />
        </Box>

        <Box mt={3}>
          <Typography variant="body2">Freight Delivery Instructions</Typography>
          <TextFieldControlled
            name={FIELDS.freight_instructions}
            placeholder="Add instructions..."
            fullWidth
            className={classes.textField}
            centeredInput
            dataTest={`${TEST_ID}-${FIELDS.freight_instructions}`}
          />
        </Box>

        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Shipping Tier 1 Price</Typography>
              <TextFieldControlled
                startAdornment="$"
                name={FIELDS.shipping_tier1_price}
                number
                fullWidth
                className={classes.textField}
                centeredInput
                dataTest={`${TEST_ID}-${FIELDS.shipping_tier1_price}`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Shipping Tier 1 Threshold</Typography>
              <TextFieldControlled
                startAdornment="$"
                min={0}
                number
                name={FIELDS.shipping_tier1_threshold}
                fullWidth
                className={classes.textField}
                centeredInput
                dataTest={`${TEST_ID}-${FIELDS.shipping_tier1_threshold}`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">Shipping Tier 2 Price</Typography>
              <TextFieldControlled
                startAdornment="$"
                min={0}
                number
                name={FIELDS.shipping_tier2_price}
                fullWidth
                className={classes.textField}
                centeredInput
                dataTest={`${TEST_ID}-${FIELDS.shipping_tier2_price}`}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography variant="body2">
              Delivery Window Cutoff Minutes
            </Typography>
            <TextFieldControlled
              number
              min={0}
              name={FIELDS.delivery_window_cutoff_minutes}
              fullWidth
              className={classes.textField}
              centeredInput
              dataTest={`${TEST_ID}-${FIELDS.delivery_window_cutoff_minutes}`}
            />
          </Box>
          <Box mt={2}>
            <Typography variant="body2">Delivery Window Order Limit</Typography>
            <TextFieldControlled
              number
              min={0}
              name={FIELDS.delivery_window_order_limit}
              fullWidth
              className={classes.textField}
              centeredInput
              dataTest={`${TEST_ID}-${FIELDS.delivery_window_order_limit}`}
            />
          </Box>
        </Box>
      </FormProvider>
      {activeDarkstoreId ? (
        <PrinterConfig locationId={activeDarkstoreId} />
      ) : (
        <Box mt={3}>
          <Typography variant="body2">
            Select a location on the menu below to access the printer options
          </Typography>
        </Box>
      )}
    </Dialog>
  )
}

DarkstoreModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  activeDarkstoreId: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default withStyles(styles)(DarkstoreModal)
