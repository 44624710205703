import React from 'react'
import PropTypes from 'prop-types'
import { colorsAF } from 'theme/colors'

const DownloadIcon = ({ color = colorsAF.darkGray, fontSize = 24 }) => (
  <svg
    width={fontSize}
    height={fontSize}
    viewBox={`0 0 ${fontSize} ${fontSize}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M6.279 10.481a1 1 0 00.024 1.414l5.001 4.83a1 1 0 001.39 0l5-4.83a1 1 0 10-1.39-1.438l-3.274 3.162V4a1 1 0 10-2 0v9.679l-3.337-3.222a1 1 0 00-1.414.024zM4 10a1 1 0 00-2 0v5a6 6 0 006 6h8a6 6 0 006-6v-5a1 1 0 10-2 0v5a4 4 0 01-4 4H8a4 4 0 01-4-4v-5z"
      clipRule="evenodd"
    />
  </svg>
)

DownloadIcon.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.number
}

export default DownloadIcon
