const jssStyles = theme => ({
  root: {
    overflow: 'scroll'
  },
  deliveryWindowPipelineAccordion: {
    marginBottom: 16
  },
  summarizersClasses: {
    marginBottom: 0
  },
  // search input modifications for button to search
  accountForButtonWidth: {
    minWidth: 300,
    marginBottom: 0
  }
})

export default jssStyles
