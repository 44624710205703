import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import { formatCurrency } from 'utils/general'
import { SIZE } from 'constants/enums'

import { withStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import UserAvatar from 'components/UserAvatar'

import styles from './BrandCustomerItemStyles'

const DEFAULT_TOTAL_ORDERS = 0
const DEFAULT_CREDIT = 0

const BrandCustomerItem = ({ classes, brandCustomer, onClick }) => {
  const { user = {} } = brandCustomer
  return (
    <TableRow>
      <TableCell className={classes.imageCell}>
        <IconButton
          size={SIZE.medium}
          onClick={e => onClick(e, brandCustomer)}
          onAuxClick={e => onClick(e, brandCustomer, true)}
        >
          <UserAvatar
            className={classes.avatar}
            size={SIZE.medium}
            src={user?.image_url}
          />
        </IconButton>
      </TableCell>
      <TableCell className={classes.nameCell}>
        <Typography variant="body2">{user?.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {user?.email ?? user?.checkout_email}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {brandCustomer?.total_orders ?? DEFAULT_TOTAL_ORDERS} orders
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {moment(brandCustomer.last_order_at).fromNow()}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {formatCurrency(brandCustomer.lifetime_value ?? DEFAULT_CREDIT)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

BrandCustomerItem.propTypes = {
  classes: PropTypes.object.isRequired,
  brandCustomer: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

export default withStyles(styles)(BrandCustomerItem)
