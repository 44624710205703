import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import Box from '@material-ui/core/Box'

import ChevronDownIcon from 'icons/ChevronDownIcon'
import { SIZE } from 'constants/enums'
import { SORT, SORT_DEFAULT, API_PARAMS } from 'constants/queryParams'

import * as propTypes from 'constants/propTypes'

import styles from './SortableCellStyles'

const SortableCell = ({ classes, children, sortKeys, query, updateQuery }) => {
  const active = sortKeys.some(k => k === query[API_PARAMS.SORT])
  const direction = active
    ? query[API_PARAMS.SORT_DIRECTION] || SORT_DEFAULT
    : SORT_DEFAULT

  const handleClick = () => {
    if (!active) {
      updateQuery({
        [API_PARAMS.SORT]: sortKeys[0],
        [API_PARAMS.SORT_DIRECTION]: direction
      })
    } else {
      updateQuery({
        [API_PARAMS.SORT_DIRECTION]:
          direction === SORT.ASC ? SORT.DESC : SORT.ASC
      })
    }
  }

  return (
    <TableCell>
      <Box className={classes.root}>
        {children}
        <IconButton
          aria-label="sort direction"
          size={SIZE.small}
          onClick={handleClick}
          className={classes.button}
        >
          <ChevronDownIcon
            fill="white"
            className={classNames({
              [classes.flipped]: active && direction === SORT.DESC,
              [classes.active]: active,
              [classes.inactive]: !active
            })}
          />
        </IconButton>
      </Box>
    </TableCell>
  )
}

SortableCell.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  sortKeys: propTypes.sortKeys.isRequired,
  query: PropTypes.object.isRequired,
  updateQuery: PropTypes.func.isRequired
}

export default withStyles(styles)(SortableCell)
