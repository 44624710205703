import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import uniqBy from 'lodash/uniqBy'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminDistributionCenterList } from 'hooks'
import AutocompleteSearch from 'components/AutocompleteSearch'

const AutocompleteSearchDistributionCentersRaw = ({
  classes,
  onChange,
  value,
  defaultOpen,
  getOptionLabel,
  placeholder,
  queryParams,
  name,
  multiple = false,
  ...rest
}) => {
  const [query, setQuery] = useState('')
  const {
    isLoadingDistributionCenters,
    distributionCenters,
    listDistributionCentersNext
  } = useAdminDistributionCenterList({ search: query, ...queryParams })

  const options = useMemo(
    () => uniqBy((distributionCenters ?? []).concat(value), v => v?.id),
    [distributionCenters]
  )

  return (
    <AutocompleteSearch
      name={name}
      defaultOpen={defaultOpen}
      value={value}
      loading={isLoadingDistributionCenters}
      setQuery={setQuery}
      onChange={onChange}
      onScrollBottom={listDistributionCentersNext}
      options={options}
      placeholder={placeholder}
      multiple={multiple}
      getOptionLabel={getOptionLabel}
      renderOption={(option, { selected }) => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          className={selected ? classes.selectedItem : null}
          data-test={`option-${option?.name}`}
        >
          <Typography
            variant="body1"
            className={classes.distributionCenterName}
          >
            {option?.name}
          </Typography>
        </Box>
      )}
      {...rest}
    />
  )
}

AutocompleteSearchDistributionCentersRaw.defaultProps = {
  value: [],
  defaultOpen: false,
  getOptionLabel: opt => opt?.name,
  placeholder: 'Search Distribution Centers'
}

AutocompleteSearchDistributionCentersRaw.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string
  ]),
  defaultOpen: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  placeholder: PropTypes.string,
  queryParams: PropTypes.object,
  name: PropTypes.string.isRequired
}

const AutocompleteSearchDistributionCenters = withStyles(() => ({
  userEmail: {
    fontSize: 10
  },
  userName: {},
  selectedItem: {
    '& $userName': {
      fontWeight: 700
    }
  }
}))(AutocompleteSearchDistributionCentersRaw)

const AutocompleteSearchDistributionCentersControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchDistributionCenters
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchDistributionCentersControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchDistributionCenters as default,
  AutocompleteSearchDistributionCentersControlled
}
