import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import MuiTab from '@material-ui/core/Tab'
import MuiTabs from '@material-ui/core/Tabs'

import { tabPanes } from 'constants/propTypes'
import { TAB_VARIANT_TYPES } from 'constants/enums'
import { usePrevious, useConditionalEffect } from 'hooks'

import styles from './TabsStyles'

const TabPanel = ({ className, children, value, index, prerender }) => (
  <div
    className={className}
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
  >
    {(prerender || value === index) && children}
  </div>
)

TabPanel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.number,
  index: PropTypes.number,
  prerender: PropTypes.bool
}

const Tabs = ({
  classes,
  panes,
  onChange,
  variant,
  prerender,
  defaultTabIndex,
  noPaneMargin,
  boxProps
}) => {
  const prevPanes = usePrevious(panes)
  const [tabIndex, setTabIndex] = useState(defaultTabIndex)

  const handleChange = (_, newIndex) => {
    setTabIndex(newIndex)
    onChange && onChange(panes[newIndex]?.id ?? newIndex)
  }

  useConditionalEffect(() => {
    if (panes?.length !== prevPanes?.length) {
      setTabIndex(defaultTabIndex)
    }
  }, [panes])

  return (
    <Box {...boxProps} className={classes.root}>
      <Box mb={noPaneMargin ? 0 : 2}>
        <MuiTabs
          classes={{
            indicator: classes.indicator
          }}
          value={tabIndex}
          onChange={handleChange}
          variant={variant}
        >
          {panes.map(({ label, disabled }, index) => (
            <MuiTab
              key={index}
              className={classes.tab}
              classes={{ textColorInherit: classes.unselected }}
              label={label}
              disabled={disabled}
              value={index}
              wrapped
              data-test={
                typeof label === 'string'
                  ? `${label.replace(' ', '').toLowerCase()}-tab`
                  : `${label
                      .toString()
                      .replace(' ', '')
                      .toLowerCase()}-tab`
              }
            />
          ))}
        </MuiTabs>
      </Box>
      {panes.map(({ pane }, index) => (
        <TabPanel
          key={index}
          className={classes.tabPanel}
          value={tabIndex}
          index={index}
          prerender={prerender}
        >
          {pane}
        </TabPanel>
      ))}
    </Box>
  )
}

Tabs.defaultProps = {
  panes: [],
  variant: TAB_VARIANT_TYPES.standard,
  prerender: true,
  defaultTabIndex: 0,
  noPaneMargin: false,
  boxProps: {}
}

Tabs.propTypes = {
  classes: PropTypes.object.isRequired,
  panes: tabPanes,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(TAB_VARIANT_TYPES)),
  prerender: PropTypes.bool,
  defaultTabIndex: PropTypes.number,
  noPaneMargin: PropTypes.bool,
  boxProps: PropTypes.object
}

export default withStyles(styles)(Tabs)
