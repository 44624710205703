import { useResourceList } from 'hooks'

const API_DISCOUNT_CODES = '/api/v1/admin/discount_codes'

const useAdminDiscountCodes = () => {
  const {
    isLoading: isLoadingDiscountCodes,
    data: discountCodes,
    hasData: hasDiscountCodes,
    hasNext: hasDiscountCodesNext,
    list: fetchDiscountCodes,
    listNext: fetchDiscountCodesNext,
    error: discountCodesError
  } = useResourceList({ url: API_DISCOUNT_CODES })

  return {
    isLoadingDiscountCodes,
    discountCodes,
    hasDiscountCodes,
    hasDiscountCodesNext,
    fetchDiscountCodes,
    fetchDiscountCodesNext,
    discountCodesError
  }
}

export default useAdminDiscountCodes
