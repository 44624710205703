import React from 'react'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import parse from 'html-react-parser';
import Dialog from 'components/Dialog'
import Typography from '@material-ui/core/Typography'
import { FIELDS } from 'components/CreateNotificationModal';
import DefaultIcon from 'assets/default_notif_icon.png';

import styles from './NotificationPreviewModalStyles';

const NOTIFICATION_WIDTH_PX = "350px";
const NOTIFICATION_HEIGHT_PX = "80px";

const NotificationPreviewModal = ({ classes, onClose, onSubmit, isActive, notification, hasPublishError = false }) => {
  const imageUrl = !!notification[FIELDS.iconImage] ? URL.createObjectURL(notification[FIELDS.iconImage]) : DefaultIcon;
  return (
    <Dialog
      title="Notification Preview"
      open={isActive}
      onCancel={onClose}
      cancelText="Back"
      onConfirm={onSubmit}
      dataTest="notification-preview-modal"
      confirmText="Publish"
    >
      <Typography>Here's what <b>{notification[FIELDS.sendTo]}</b> will see:</Typography>
      <Box width={NOTIFICATION_WIDTH_PX} height={NOTIFICATION_HEIGHT_PX} className={classes.previewContainer}>
        <Box flex={.20} className={classes.previewImageContainer}>
          {!!imageUrl && <img className={classes.previewImage} src={imageUrl} />}
        </Box>
        <Box flex={.78} >
          <Typography className={classes.title} data-test="notification-preview-title">{notification[FIELDS.title]}</Typography>
          <Typography className={classes.description} data-test="notification-preview-message">{parse(notification[FIELDS.message] || '')}</Typography>
        </Box>
        <Box flex={.02} />
      </Box>
      {
        !!hasPublishError && (
          <Typography className={classes.error}>There was a problem publishing your notification, please try again.</Typography>
        )
      }
    </Dialog>
  )
}

export default withStyles(styles)(NotificationPreviewModal)