import React, { useCallback, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import ReplayIcon from '@material-ui/icons/Replay'

import { useAlerts } from 'hooks'

import ImageIcon from 'icons/ImageIcon'
import EditIcon from 'icons/EditIcon'
import RemoveIcon from 'icons/RemoveIcon'

import styles from './ImageDropzoneStyles'

const DEFAULT_IMAGE_ACCEPTS = 'image/png, image/jpg, image/jpeg, image/gif'

/* A wrapper that has react-dropzone compatible with react-hook-form.
 *
 * Props:
 *    name: Field name used with the react-hook-form
 *    urlName: Optional field name in the case where there is an existing image
 */
const ImageDropzone = ({
  classes,
  className,
  name,
  urlName,
  accept,
  hideClearButton,
  defaultUrl,
  imageCover,
  onDelete,
  round,
  disabled,
  noUnregister,
  placeholder,
  dataTest,
  onClear
}) => {
  const { showAlertError } = useAlerts()
  const {
    register,
    unregister,
    setValue,
    watch,
    errors,
    clearErrors
  } = useFormContext()

  const { [name]: currentImage, [urlName]: currentImageUrl } = watch(
    [name, urlName].filter(n => n && typeof n === 'string')
  )

  // The currentImageUrl will be unused if urlName is undefined
  const previewUrl =
    currentImage && !currentImageUrl
      ? URL.createObjectURL(currentImage)
      : currentImageUrl ?? defaultUrl

  const untouched = currentImageUrl === defaultUrl

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length === 1) {
        const acceptedFile = acceptedFiles?.[0]
        if (urlName) {
          const objectUrl = URL.createObjectURL(acceptedFile)
          setValue(urlName, objectUrl)
        }
        setValue(name, acceptedFile, {
          shouldValidate: true,
          shouldDirty: true
        })
      }
      if (!isEmpty(fileRejections)) {
        showAlertError(
          fileRejections.flatMap(f => f.errors.map(e => e.message)).join(', ')
        )
      }
    },
    [setValue, name, urlName]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple: false
  })

  const rootProps = getRootProps()

  useEffect(() => {
    register(name)
    urlName && register(urlName)
    return () => {
      if (noUnregister) {
        return
      }

      unregister(name)
      urlName && unregister(urlName)
    }
  }, [register, unregister, name, urlName])

  const handleClear = e => {
    setValue(name, null, { shouldValidate: false })
    clearErrors(name)
    if (urlName) {
      setValue(urlName, defaultUrl, { shouldValidate: false })
      clearErrors(urlName)
    }
    if (onClear) {
      onClear(e)
    }
    e.stopPropagation()
  }

  const handleEdit = e => {
    rootProps.onClick(e)
    e.stopPropagation()
  }

  const handleDelete = e => {
    onDelete(e)

    e.stopPropagation()
  }

  const rootClassNames = classNames({
    [classes.root]: true,
    [className]: Boolean(className),
    [classes.error]: name in errors,
    [classes.untouched]: untouched
  })

  const rightTopButton =
    currentImageUrl === defaultUrl ? (
      <IconButton
        classes={{ root: classes.iconButton }}
        onClick={handleEdit}
        color="secondary"
      >
        <EditIcon />
      </IconButton>
    ) : (
      <IconButton
        classes={{ root: classes.iconButton }}
        onClick={handleClear}
        color="secondary"
      >
        <ClearIcon />
      </IconButton>
    )

  const deleteImgButton = (
    <IconButton
      classes={{ root: classes.iconButton }}
      onClick={handleDelete}
      color="secondary"
    >
      <RemoveIcon />
    </IconButton>
  )

  return (
    <Box
      {...(!currentImageUrl || hideClearButton ? rootProps : {})}
      className={rootClassNames}
    >
      <Box className={classes.placeholder}>
        <Box flexGrow={1} />
        <input
          id={name}
          {...getInputProps()}
          disabled={disabled}
          data-test={dataTest}
        />
        <ImageIcon className={classes.icon} />
        <Typography variant="body1">
          {!disabled &&
            (placeholder ||
              'Click to upload or drag and drop a cover image here.')}
        </Typography>
        <Box flexGrow={1} display="flex" alignItems="flex-end">
          <Typography variant="body1">{currentImage?.name}</Typography>
        </Box>
      </Box>
      {previewUrl && (
        <>
          <Box className={classes.previewBackdrop} />
          <img
            className={classNames({
              [classes.imageCover]: Boolean(imageCover),
              [classes.imageContain]: Boolean(!imageCover),
              [classes.round]: round
            })}
            src={previewUrl}
            alt={name}
          />
        </>
      )}

      {errors[name] && (
        <Box className={classes.errorBox}>
          <IconButton onClick={handleClear}>
            <ReplayIcon />
          </IconButton>
          <Typography variant="body1">{errors[name].message}</Typography>
        </Box>
      )}

      <Box classes={{ root: classes.rightTopButtons }}>
        {!hideClearButton && rightTopButton}

        {defaultUrl && onDelete && deleteImgButton}
      </Box>
    </Box>
  )
}

ImageDropzone.defaultProps = {
  accept: DEFAULT_IMAGE_ACCEPTS,
  defaultUrl: null
}

ImageDropzone.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  urlName: PropTypes.string,
  accept: PropTypes.string,
  hideClearButton: PropTypes.bool,
  defaultUrl: PropTypes.string,
  imageCover: PropTypes.bool,
  round: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  noUnregister: PropTypes.bool,
  onDelete: PropTypes.func,
  dataTest: PropTypes.string,
  onClear: PropTypes.func
}

export default withStyles(styles)(ImageDropzone)
