import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import FastAFLogo from 'components/FastAFLogo'

import styles from './LoginStyles'

const LoginLayout = ({ classes, children, title, subtitle }) => (
  <Box
    height="100%"
    width="100%"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="space-between"
  >
    <Box />
    <Box maxWidth="548px" width="100%" py={3} px={5}>
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body2" className={classes.subtitle}>
        {subtitle}
      </Typography>
      {children}
    </Box>
    <Box ml="35px" my="34px" alignSelf="flex-start">
      <FastAFLogo width={110} fill="black" />
    </Box>
  </Box>
)

LoginLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}

export default withStyles(styles)(LoginLayout)
