import { downloadApi } from 'api'
import { useResourceListRQ, useResourceRQ } from 'hooks'
import {
  plateListEndpoint,
  plateBulkUpdateEndpoint,
  plateBulkDeactivateEndpoint
} from 'api/endpoints'

const useAdminPlateList = ({ params } = {}) => {
  const {
    isFetching: isFetchingPlates,
    data: plates,
    hasData: hasPlates,
    hasNextPage: hasPlatesNext,
    fetchNextPage: fetchPlatesNext,
    meta
  } = useResourceListRQ({ endpoint: plateListEndpoint, params })

  const { update: bulkUpdatePlates } = useResourceRQ({
    endpoint: plateBulkUpdateEndpoint,
    args: [],
    fetchingEnabled: false,
    name: 'plates'
  })

  const { update: bulkDeactivatePlates } = useResourceRQ({
    endpoint: plateBulkDeactivateEndpoint,
    args: [],
    fetchingEnabled: false,
    name: 'plates'
  })

  return {
    isFetchingPlates,
    plates: plates || [],
    hasPlates,
    hasPlatesNext,
    fetchPlatesNext,
    summary: meta?.summary,

    bulkUpdatePlates,
    bulkDeactivatePlates
  }
}

export default useAdminPlateList
