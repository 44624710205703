import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { useLocationsContext } from 'context'

import AutocompleteSelect from 'components/AutocompleteSelect'
import Button from 'components/Button'

const AutocompleteSelectLocationsRaw = ({
  name,
  onChange,
  value,
  multiple,
  helperText,
  error,
  groupBy,
  placeholder = 'Select Locations',
  showActions,
  getOptionLabel = o => o.name ?? '',
  getTagLabel = o => o.name,
  ...rest
}) => {
  const { locations = [] } = useLocationsContext()

  return (
    <AutocompleteSelect
      name={name}
      helperText={helperText}
      error={error}
      multiple={multiple}
      placeholder={!multiple ? 'Select Location' : placeholder}
      options={locations?.filter(location => location.active) ?? []}
      value={value}
      loading={Boolean(!locations?.length)}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      getTagLabel={getTagLabel}
      groupBy={groupBy}
      actions={
        showActions
          ? [
              <Button
                variant="outlined"
                size="small"
                color="default"
                onClick={() => onChange(locations)}
              >
                All Locations
              </Button>,
              <Button
                variant="outlined"
                size="small"
                color="default"
                onClick={() => onChange([])}
              >
                Clear
              </Button>
            ]
          : []
      }
      {...rest}
    />
  )
}

AutocompleteSelectLocationsRaw.defaultProps = {
  value: [],
  defaultOpen: false,
  multiple: true,
  groupBy: () => {},
  showActions: true
}

AutocompleteSelectLocationsRaw.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.number,
    PropTypes.string,
    PropTypes.object
  ]),
  defaultOpen: PropTypes.bool,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  groupBy: PropTypes.func.isRequired
}

const AutocompleteSelectLocationsControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSelectLocationsRaw
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSelectLocationsControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSelectLocationsRaw as default,
  AutocompleteSelectLocationsControlled
}
