const jssStyles = theme => ({
  typeButton: {
    marginRight: theme.spacing(1)
  },
  memoContainer: {
    border: `1px solid ${theme.palette.grays.med}`,
    padding: theme.spacing(2)
  },
  errorContainer: {
    borderColor: theme.palette.error.main
  }
})
export default jssStyles
