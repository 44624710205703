import React from 'react'
import PropTypes from 'prop-types'

import MuiButton from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import { useMediaQuery } from 'hooks'

import { BUTTON_VARIANT, SIZE, COLOR } from 'constants/enums'

const Button = ({
  variant,
  color,
  size,
  href,
  disabled,
  onClick,
  children,
  className,
  fullWidth,
  startIcon,
  endIcon,
  type,
  target,
  adaptive,
  label,
  dataTest,
  component
}) => {
  const { isDesktopScreen } = useMediaQuery()
  if (adaptive && !startIcon && !endIcon) {
    console.warn(
      'When prop adaptive is provided to Button a startIcon or endIcon must be provided'
    )
  }

  // TODO: Add these as custom propTypes checks
  if (adaptive && !label) {
    console.warn(
      'When prop adaptive is provided label must be specified (for aria-label)'
    )
  }

  if (adaptive && isDesktopScreen) {
    return (
      <IconButton
        variant={variant}
        color={color}
        size={SIZE.medium}
        href={href}
        disabled={disabled}
        onClick={onClick}
        className={className}
        type={type}
        target={target}
        aria-label={label}
        data-test={dataTest}
        component={component}
      >
        {startIcon || endIcon}
      </IconButton>
    )
  }

  return (
    <MuiButton
      variant={variant}
      color={color}
      size={size}
      href={href}
      disabled={disabled}
      onClick={onClick}
      disableElevation
      className={className}
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      target={target}
      aria-label={label}
      data-test={dataTest}
      component={component}
    >
      {children}
    </MuiButton>
  )
}

Button.defaultProps = {
  variant: BUTTON_VARIANT.contained,
  color: COLOR.primary,
  size: SIZE.large,
  disabled: false,
  adaptive: false,
  component: 'button'
}

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)),
  color: PropTypes.oneOf(Object.values(COLOR)),
  size: PropTypes.oneOf(Object.values(SIZE)),
  href: PropTypes.string,
  disabled: PropTypes.bool,
  adaptive: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  target: PropTypes.string,
  startIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  endIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  dataTest: PropTypes.string,
  component: PropTypes.string
}

export default Button
