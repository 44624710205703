import { useHistory } from 'react-router-dom'

import { api } from 'api'
import { useAlerts, useLoaders } from 'hooks'
import { useAuthContext, useLocalStorageContext } from 'context'
import { URL } from 'constants/navigation'
import { isSwitchLocationsEnabled } from 'utils/user'
import { isProductionInstance } from 'utils/general'

const INTERNAL_USER_AUTH_ENDPOINTS = {
  me: 'api/v1/admin/me',
  login: 'api/v1/admin/login',
  resetPassword: 'api/v1/admin/reset_password',
  forgotPassword: 'api/v1/admin/forgot_password'
}

const useAuth = () => {
  const authEndpoints = INTERNAL_USER_AUTH_ENDPOINTS

  const {
    setAuthToken,
    setLocationId,
    needsUserDefaultLocation,
    setNeedsUserDefaultLocation,
    clearStorage
  } = useLocalStorageContext()
  const history = useHistory()

  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError } = useAlerts()
  const { currentUser, setCurrentUser } = useAuthContext()

  const setCurrentUserAndToken = user => {
    if (user.token) {
      setAuthToken(user.token)
    }
    setCurrentUser(user)
    if (needsUserDefaultLocation || !isSwitchLocationsEnabled(user)) {
      setLocationId(user.default_location_id)
      setNeedsUserDefaultLocation(false)
    }
  }

  const fetchMe = async () => {
    try {
      const resp = await api.get(authEndpoints.me)
      setCurrentUserAndToken(resp.data)
      hideLoading()
      return resp.data
    } catch (e) {
      console.error(e)
    }
  }

  const loginUser = async user => {
    try {
      showLoading()
      const resp = await api.post(authEndpoints.login, { user })
      setCurrentUserAndToken(resp.data)
      hideLoading()
      return resp.data
    } catch (e) {
      hideLoading()
      showAlertError('Email or password is incorrect')
      console.error(e)
    }
  }

  const resetPassword = async (password, changeToken) => {
    try {
      showLoading()
      const resp = await api.post(authEndpoints.resetPassword, {
        password,
        change_password_token: changeToken
      })
      hideLoading()
      return resp.data
    } catch (e) {
      hideLoading()
      console.error(e)
    }
  }

  const forgotPassword = async email => {
    try {
      showLoading()
      await api.post(authEndpoints.forgotPassword, { email })
      hideLoading()
    } catch (e) {
      hideLoading()
      console.error(e)
    }
  }

  const logoutUser = () => {
    setCurrentUser(null)
    clearStorage()
    history.push(URL.LOGIN)
  }

  return {
    currentUser,
    fetchMe,
    loginUser,
    logoutUser,
    resetPassword,
    forgotPassword
  }
}

export default useAuth
