const jssStyles = theme => ({
  root: {
    border: `1px solid ${theme.palette.grays.med}`,
    padding: 0,
    width: '100%',
    height: 'auto',
    margin: '0 auto',
    marginBottom: theme.spacing(2),
    zIndex: 999,
    position: 'relative',

    '&:last-child': {
      marginBottom: 0
    }
  },
  title: {
    padding: theme.spacing(3),
    paddingBottom: 0
  },
  withPadding: {
    padding: theme.spacing(3),

    '& .MuiTypography-root': {
      padding: 0
    }
  },
  autoHeight: {
    height: 'auto'
  },
  container: {}
})

export default jssStyles
