const jssStyles = theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    outline: 'none'
  },
  divider: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grays.med}`
  },
  errorBox: {
    backgroundColor: theme.palette.colorsAF.fastRed,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    color: 'white',
    margin: theme.spacing(3),

    '& .MuiIconButton-root': {
      color: 'white'
    }
  },
  imageLarge: {
    height: 200,
    width: 200
  },
  imageSmall: {
    height: 92,
    width: 92
  },
  imageShared: {
    padding: theme.spacing(1)
  },
  blackButton: {
    background: theme.palette.colorsAF.black,
    color: 'white',
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),

    '&:hover': {
      background: theme.palette.colorsAF.mediumGray
    }
  },
  editButton: {
    background: theme.palette.colorsAF.semiGray,
    marginRight: theme.spacing(2),
    minWidth: 30,

    '&:hover': {
      background: theme.palette.colorsAF.mediumGray
    }
  },
  scrollablePreview: {
    overflowY: 'scroll',
    height: 193
  },
  muted: {
    fontSize: 12,
    color: theme.palette.colorsAF.mediumGray
  },
  mobileImageContainer: {
    overflowY: 'scroll',
    '& $imageLarge': {
      height: '100%',
      width: '100%'
    },
    '& $imageSmall': {
      height: 'auto',
      width: '50%'
    }
  },
  imageContainer: {}
})

export default jssStyles
