import React from 'react'
import PropTypes from 'prop-types'

const DEFAULT_HEIGHT = 53
const ASPECT_RATIO = 69 / DEFAULT_HEIGHT

const ImageIcon = ({ fill, className, height }) => (
  <svg
    width={height * ASPECT_RATIO}
    height={height}
    viewBox="0 0 69 53"
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6094 52.0921H62.391C65.639 52.0921 68.3222 49.4116 68.3222 46.1669V6.38323C68.3222 3.13846 65.639 0.458008 62.391 0.458008H6.6094C3.36136 0.458008 0.678223 3.13846 0.678223 6.38323V46.1669C0.678223 49.4116 3.36136 52.0921 6.6094 52.0921ZM4.49111 6.24214C4.49111 5.11353 5.33846 4.26708 6.46822 4.26708H62.2498C63.3795 4.26708 64.2269 5.11353 64.2269 6.24214V35.7559L54.3413 27.6855L44.0886 36.0311L22.4258 15.6938L4.49111 32.2965V6.24214ZM46.8817 38.6533L56.8385 48.0009H62.2498C63.3795 48.0009 64.2269 47.1544 64.2269 46.0258V40.4339L54.3413 32.4821L46.8817 38.6533ZM6.46822 48.0009H51.44L22.4258 20.7726L4.49111 37.3318V46.0258C4.49111 47.1544 5.33846 48.0009 6.46822 48.0009ZM36.6891 16.2592C36.6891 20.4915 40.0784 24.0184 44.4562 24.0184C48.6928 24.0184 52.2232 20.4915 52.2232 16.2592C52.2232 12.0269 48.6928 8.49994 44.4562 8.49994C40.2196 8.49994 36.6891 12.0269 36.6891 16.2592ZM40.3608 16.1181C40.3608 13.8608 42.1967 12.0269 44.4562 12.0269C46.7157 12.0269 48.5515 13.8608 48.5515 16.1181C48.5515 18.3753 46.7157 20.2093 44.4562 20.2093C42.1967 20.2093 40.3608 18.3753 40.3608 16.1181Z"
    />
  </svg>
)

ImageIcon.defaultProps = {
  height: DEFAULT_HEIGHT
}

ImageIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number
}

export default ImageIcon
