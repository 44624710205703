import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'

import {
  useAdminZipCodes,
  useAdminLocations,
  useConditionalEffect
} from 'hooks'
import { useLocationsContext } from 'context'
import { URL } from 'constants/navigation'

import Button from 'components/Button'
import Header from 'components/Header'
import Layout from 'components/Layout'
import ZipCodesPane from 'components/ZipCodesPane'
import DarkstoresPane from 'components/DarkstoresPane'
import SearchInput from 'components/SearchInput'

import styles from './ServiceAreasStyles'

const ZIP_CODES_INDEX = 0
const DARKSTORE_INDEX = 1

const TEST_ID = 'serviceAreas'

const ZIP_CODES_OPTION = {
  label: 'Zip Codes',
  value: ZIP_CODES_INDEX
}

const DARKSTORE_OPTION = {
  label: 'Darkstores',
  value: DARKSTORE_INDEX
}

const DEFAULT_TAB_OPTIONS = [ZIP_CODES_OPTION, DARKSTORE_OPTION]

const SEARCH_INPUT_PARAMS = [
  {
    name: 'darkstore-location',
    placeholder: 'Search by Darkstore Name',
    tabIndex: DARKSTORE_INDEX
  },
  {
    name: 'zip-code',
    placeholder: 'Search by Zip Code',
    tabIndex: ZIP_CODES_INDEX
  }
]

const ServiceAreas = ({ classes }) => {
  const { locationId, showAllLocationIds } = useLocationsContext()
  const { zipcodes, listZipCodes } = useAdminZipCodes()
  const { locations } = useAdminLocations()

  const [searchValue, setSearchValue] = useState(null)

  const [tabIndexActive, setTabIndexActive] = useState(
    showAllLocationIds ? DARKSTORE_INDEX : ZIP_CODES_INDEX
  )
  const [zipCodesByLocation, setZipCodesByLocation] = useState([])
  const [openModalId, setOpenModalId] = useState(false)

  const activeOption = useMemo(
    () => DEFAULT_TAB_OPTIONS.find(o => o.value === tabIndexActive),
    [tabIndexActive]
  )

  // TODO: replace with useOnce hook pending creation
  useEffect(() => {
    listZipCodes()
  }, [])

  useConditionalEffect(() => {
    setTabIndexActive(showAllLocationIds ? DARKSTORE_INDEX : ZIP_CODES_INDEX)
  }, [locationId])

  useConditionalEffect(() => {
    if (!zipcodes) {
      setZipCodesByLocation([])
      return
    }

    setZipCodesByLocation(
      zipcodes.filter(({ location_id }) => location_id === locationId)
    )
  }, [zipcodes, locationId])

  const darkstoresByLocation = showAllLocationIds
    ? locations
    : locations?.filter(({ id }) => id === locationId)

  const actions = (
    <Button
      adaptive
      startIcon={<AddIcon />}
      color="primary"
      variant="contained"
      size="medium"
      onClick={() => setOpenModalId(activeOption.value)}
      dataTest={`${TEST_ID}-new-item`}
    >
      Add
    </Button>
  )

  const foldableActions = (
    <>
      {SEARCH_INPUT_PARAMS.map(
        input =>
          input.tabIndex === tabIndexActive && (
            <SearchInput
              key={input.name}
              name={input.name}
              placeholder={input.placeholder}
              value={searchValue}
              onChange={e => {
                setSearchValue(e.target.value)
              }}
              dataTest={`${TEST_ID}-search`}
            />
          )
      )}
    </>
  )

  const zipcodesMemoized = useMemo(
    () =>
      searchValue?.length >= 2
        ? zipCodesByLocation?.filter(
            zipCode => !zipCode.zip.search(searchValue)
          )
        : [],
    [searchValue, zipCodesByLocation]
  )

  return (
    <Layout>
      <Box className={classes.root}>
        <Header
          title="Service Areas"
          foldableActions={foldableActions}
          breadcrumbs={[
            {
              title: 'Back Of House',
              link: URL.ADMIN_BOH
            }
          ]}
          actions={actions}
        />

        {showAllLocationIds ? (
          <DarkstoresPane
            darkstores={
              searchValue
                ? darkstoresByLocation?.filter(
                    darkstore =>
                      !darkstore.name
                        .toLowerCase()
                        .search(searchValue.toLowerCase())
                  )
                : darkstoresByLocation
            }
            showAll={showAllLocationIds}
            openModal={openModalId === DARKSTORE_INDEX}
            onCloseModal={() => setOpenModalId(null)}
          />
        ) : (
          <ZipCodesPane
            zipcodes={zipcodesMemoized}
            onChange={() => listZipCodes()}
            openModal={openModalId === ZIP_CODES_INDEX}
            onCloseModal={() => setOpenModalId(null)}
          />
        )}
      </Box>
    </Layout>
  )
}

ServiceAreas.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ServiceAreas)
