import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminBrandsRQ } from 'hooks'
import AutocompleteSearch from 'components/AutocompleteSearch'

import styles from './AutocompleteSearchBrandsStyles'

const AutocompleteSearchBrandsRaw = ({
  name,
  classes,
  onChange,
  value,
  defaultOpen,
  multiple,
  helperText,
  ...rest
}) => {
  const { isLoadingBrands, brands: allBrands } = useAdminBrandsRQ()
  const [query, setQuery] = useState()

  const searchByQuery = brand =>
    !query || brand.name?.search(new RegExp(query, 'i')) > -1

  const options = useMemo(
    () =>
      uniqBy(
        (allBrands?.filter(brand => searchByQuery(brand)) ?? []).concat(value),
        v => v?.id
      ),
    [allBrands]
  )

  return (
    <Box mt={1} width="100%">
      <AutocompleteSearch
        name={name}
        helperText={helperText}
        defaultOpen={defaultOpen}
        value={value}
        loading={isLoadingBrands}
        setQuery={val => {
          setQuery(val)
        }}
        onChange={onChange}
        options={options}
        placeholder="Brand"
        multiple={multiple}
        getOptionLabel={opt => opt?.name ?? ''}
        renderOption={(option, { selected }) => (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="100%"
            className={selected ? classes.selectedItem : null}
            data-test={`option-${option?.name}`}
          >
            <Typography variant="body1" className={classes.name}>
              {option?.name ?? ''}
            </Typography>
          </Box>
        )}
        {...rest}
      />
    </Box>
  )
}

AutocompleteSearchBrandsRaw.defaultProps = {
  value: {},
  defaultOpen: false,
  multiple: false
}

AutocompleteSearchBrandsRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  defaultOpen: PropTypes.bool,
  multiple: PropTypes.bool
}

const AutocompleteSearchBrands = withStyles(styles)(AutocompleteSearchBrandsRaw)

const AutocompleteSearchBrandsControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchBrands
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          autocompleteRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchBrandsControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchBrands as default,
  AutocompleteSearchBrandsControlled
}
