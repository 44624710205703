import React, { useState } from 'react'

import AddIcon from '@material-ui/icons/Add'

import { useQuery, useNavigation, useAdminUsers } from 'hooks'
import { sortPossibilitiesFromColumns } from 'utils/query'
import { API_PARAMS, LIST_PATHS } from 'constants/queryParams'
import { URL } from 'constants/navigation'

import Layout from 'components/Layout'
import Header from 'components/Header'
import DataTable from 'components/DataTable'
import Select from 'components/Select'
import SearchInput from 'components/SearchInput'
import Button from 'components/Button'
import GlanceTile from 'components/GlanceTile'

import UserItem from './UserItem'

const columns = [
  { title: '' },
  {
    title: 'User Name',
    sortKeys: [LIST_PATHS.USERS_FIRST_NAME, LIST_PATHS.USERS_LAST_NAME]
  },
  { title: 'Email', sortKeys: [LIST_PATHS.USERS_EMAIL] },
  {
    title: 'Role',
    sortKeys: [LIST_PATHS.USERS_ROLE]
  },
  { title: 'Credit', sortKeys: [LIST_PATHS.USERS_CREDIT_BALANCE] },
  { title: 'User Code' },
  { title: 'User Tier', sortKeys: [LIST_PATHS.USERS_REFERRAL_TIER] }
]

const sortPossibilities = sortPossibilitiesFromColumns(columns)

const USER_EMPLOYEE = 'employee'
const USER_INVESTOR = 'investor'

const USER_TIERS_OPTIONS = [
  { label: 'Blue', value: 'blue' },
  { label: 'Bronze', value: 'bronze' },
  { label: 'Silver', value: 'silver' },
  { label: 'Gold', value: 'gold' },
  { label: 'Platinum', value: 'platinum' }
]

const USER_TYPE_OPTIONS = [
  { label: 'Employee', value: 'employee' },
  { label: 'Investor', value: 'investor' }
]

const GROUPED_BADGE_STATUS_FILTER = [
  {
    group: 'User Tiers',
    items: USER_TIERS_OPTIONS
  },
  {
    group: 'User Type',
    items: USER_TYPE_OPTIONS
  }
]

const BLOCKED_TYPE_OPTIONS = [
  { label: 'Blocked', value: 'blocked' },
  { label: 'Unblocked', value: 'unblocked' },
  { label: 'All', value: '' }
]

const BLOCKED_STATUS_OPTIONS = {
  true: 'Blocked',
  false: 'Not Blocked'
}

const UserList = () => {
  const {
    isLoadingUsers,
    users,
    usersCount,
    hasUsersNext,
    listUsers,
    listUsersNext
  } = useAdminUsers()
  const { query, handleQueryChange, updateQuery } = useQuery(listUsers, {
    initFallback: {
      [API_PARAMS.SORT]: LIST_PATHS.USERS_FIRST_NAME
    }
  })
  const { handleClick: viewUserDetails } = useNavigation({
    url: URL.ADMIN_USERS
  })
  const { handleAddClick: createUser } = useNavigation({
    url: URL.ADMIN_USERS_EDIT
  })

  const [badgeFilter, setBadgeFilter] = useState(null)
  const handleSetBadgeFilter = e => {
    const localFilterValues = [...e.target.value.filter(Boolean)].flat()
    if (localFilterValues === null) {
      setBadgeFilter(
        [
          query[LIST_PATHS.USERS_TIER] &&
            query[LIST_PATHS.USERS_TIER].split(','),
          query[LIST_PATHS.USERS_EMPLOYEE] && USER_EMPLOYEE,
          query[LIST_PATHS.USERS_INVESTOR] && USER_INVESTOR
        ]
          .flat()
          .filter(Boolean)
      )
      return
    } else {
      setBadgeFilter(localFilterValues)
    }

    const tierValues = localFilterValues?.filter(value =>
      Object.values(USER_TIERS_OPTIONS)
        .map(option => option.value)
        .includes(value)
    )

    const typeValues = localFilterValues?.filter(value =>
      Object.values(USER_TYPE_OPTIONS)
        .map(option => option.value)
        .includes(value)
    )
    const userTierRel = `${LIST_PATHS.USERS_TIER}.rel`

    const USER_TYPE_FILTER = {
      [LIST_PATHS.USERS_EMPLOYEE]: typeValues?.includes(USER_EMPLOYEE) || '',
      [LIST_PATHS.USERS_INVESTOR]: typeValues?.includes(USER_INVESTOR) || ''
    }

    const USER_TIER_FILTER = {
      [LIST_PATHS.USERS_TIER]: tierValues?.length ? tierValues.join(',') : '',
      [userTierRel]: tierValues?.length ? 'in' : ''
    }

    updateQuery({ ...USER_TIER_FILTER, ...USER_TYPE_FILTER })
  }

  const handleSetFilterByBlockedStatus = ({ target: { value } }) =>
    updateQuery({
      ...query,
      [LIST_PATHS.USERS_BLOCKED]:
        value === '' ? '' : value === 'blocked' ? 'true' : 'false'
    })

  const foldableActions = (
    <>
      <SearchInput
        name="search"
        placeholder="Search Users..."
        value={query.search}
        onChange={handleQueryChange}
        dataTest="users-search"
      />
    </>
  )

  const actions = (
    <Button
      startIcon={<AddIcon />}
      adaptive
      color="primary"
      variant="contained"
      size="medium"
      label="create user button"
      onClick={e => createUser(e)}
      dataTest="users-create-button"
    >
      Create User
    </Button>
  )

  const filters = (
    <>
      <Select
        minimal
        name={API_PARAMS.SORT}
        innerLabel="Sort By"
        value={query[API_PARAMS.SORT] || ''}
        items={sortPossibilities}
        onChange={handleQueryChange}
        withNone
        dataTest="user-sort"
      />
      <Select
        minimal
        name="badge_status"
        innerLabel="Badge Status"
        value={badgeFilter?.length ? badgeFilter : []}
        groupedItems={GROUPED_BADGE_STATUS_FILTER}
        onChange={handleSetBadgeFilter}
        multiple
      />
      <Select
        minimal
        name={LIST_PATHS.USERS_BLOCKED}
        innerLabel="Blocked"
        value={BLOCKED_STATUS_OPTIONS?.[query[LIST_PATHS.USERS_BLOCKED] || '']}
        items={BLOCKED_TYPE_OPTIONS}
        onChange={handleSetFilterByBlockedStatus}
      />
    </>
  )

  const summarizers = (
    <>
      <GlanceTile
        minimal
        name="total_users"
        label="Total Users"
        value={usersCount}
      />
    </>
  )

  return (
    <Layout>
      <Header
        title="Users"
        foldableActions={foldableActions}
        filters={filters}
        summarizers={summarizers}
        actions={actions}
      />

      <DataTable
        columns={columns}
        message={users?.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingUsers}
        hasListNext={hasUsersNext}
        listNext={listUsersNext}
        query={query}
        updateQuery={updateQuery}
      >
        {users?.map(user => (
          <UserItem key={user.id} user={user} onClick={viewUserDetails} />
        ))}
      </DataTable>
    </Layout>
  )
}

export default UserList
