import React from 'react'
import PropTypes from 'prop-types'

import sortBy from 'lodash/sortBy'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import FormHelperText from '@material-ui/core/FormHelperText'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import Popper from '@material-ui/core/Popper'
import ListSubheader from '@material-ui/core/ListSubheader'

import { MARGINS } from 'constants/enums'
import TextField from 'components/TextField'

import AutocompleteListBox from './AutocompleteListBox'
import styles from './AutocompleteStyles'

const DefaultPopper = ({ children, ...props }) => (
  <Popper {...props} placement="bottom">
    <Box>{children}</Box>
  </Popper>
)

DefaultPopper.propTypes = {
  children: PropTypes.node
}

const defaultRenderOption = o => o

const defaultRenderGroup = ({ key, group, children }) => [
  <ListSubheader key={key}>{group}</ListSubheader>,
  children
]

const Autocomplete = ({
  classes,
  // Directly passed to MuiAutocomplete
  value,
  options,
  noOptionsText,
  getOptionLabel,
  open,
  groupBy,
  autocompleteRef,
  // Used indirectly
  className,
  placeholder,
  endAdornment,
  startAdornment,
  onScrollBottom,
  itemHeight,
  headerHeight,
  // Render functions
  renderGroup,
  renderOption,
  renderInput,
  PopperComponent,
  helperText,
  ...props
}) => {
  const localRenderInput =
    renderInput ??
    (({ InputProps, ...params }) => (
      <TextField
        {...params}
        placeholder={placeholder}
        className={classes.inputBox}
        margin={MARGINS.dense}
        InputProps={{
          ...InputProps,
          startAdornment,
          endAdornment
        }}
      />
    ))

  const listboxProps = {
    ...(itemHeight ? { itemHeight } : null),
    ...(headerHeight ? { headerHeight } : null),
    ...(onScrollBottom
      ? {
          onScroll: ({ currentTarget: el }) => {
            if (el.scrollTop + el.clientHeight === el.scrollHeight) {
              onScrollBottom()
            }
          }
        }
      : null)
  }

  return (
    <>
      <MuiAutocomplete
        value={value}
        options={groupBy ? sortBy(options, groupBy) : options}
        open={open}
        groupBy={groupBy}
        fullWidth
        noOptionsText={noOptionsText}
        getOptionLabel={getOptionLabel}
        ref={autocompleteRef}
        classes={{
          root: className,
          paper: classes.autocompletePaper,
          option: classes.autocompleteOption,
          listbox: classes.autocompleteListbox,
          groupUl: classes.autocompleteGroupUl,
          inputRoot: classes.autocompleteInputRoot,
          popper: classes.popperContainer
        }}
        ListboxComponent={AutocompleteListBox}
        ListboxProps={listboxProps}
        renderGroup={renderGroup}
        renderOption={renderOption}
        renderInput={localRenderInput}
        PopperComponent={PopperComponent}
        {...props}
      />
      {helperText && (
        <FormHelperText className={classes.errorText}>
          {helperText}
        </FormHelperText>
      )}
    </>
  )
}

Autocomplete.defaultProps = {
  renderGroup: defaultRenderGroup,
  renderOption: defaultRenderOption,
  PopperComponent: DefaultPopper
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ]),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  noOptionsText: PropTypes.node,
  getOptionLabel: PropTypes.func,
  open: PropTypes.bool,
  groupBy: PropTypes.func,
  autocompleteRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  onScrollBottom: PropTypes.func,
  itemHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  renderGroup: PropTypes.func,
  renderOption: PropTypes.func,
  renderInput: PropTypes.func,
  PopperComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  helperText: PropTypes.string
}

export default withStyles(styles)(Autocomplete)
