import { useResource, useResourceList } from 'hooks'
import { notificationsEndpoint } from 'api/endpoints'

const RESOURCE_NAME = 'notification';
const NOTIF_ENDPOINT = notificationsEndpoint();

const useAdminNotifications = () => {

  const {
    createMultipartFormData: createNotification,
  } = useResource({
    url: NOTIF_ENDPOINT,
    name: RESOURCE_NAME
  })

  const {
    isLoading: isLoadingNotifs,
    data: notifs,
    hasData: hasNotifs,
    hasNext: hasNotifsNext,
    list: fetchNotifs,
    listNext: fetchNotifsNext,
    relist: refetchNotifs,
    updateItemsLocally: updateNotifsLocally,
    count: notifCount,
  } = useResourceList({ url: `${NOTIF_ENDPOINT}?notifications.type=Custom` })

  return {
    createNotification,
    notifs,
    notifCount,
    fetchNotifs,
    isLoadingNotifs,
    hasNotifs,
    hasNotifsNext,
    fetchNotifsNext,
    refetchNotifs,
    updateNotifsLocally
  }
}

export default useAdminNotifications
