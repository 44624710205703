import { useResourceRQ } from 'hooks'

import {
  distributionCenterListEndpoint,
  distributionCenterEndpoint,
  supplierEndpoint,
  distributionCenterVariantsListEndpoint
} from 'api/endpoints'

const useAdminDistributionCenter = ({
  supplierId,
  distributionCenterId,
  fetchingEnabled
}) => {
  const {
    create: createDistributionCenter,
    data: distributionCenter,
    update: updateDistributionCenter,
    isLoading: isLoadingDistributionCenter,
    isError: isDistributionCenterError,
    error: distributionCenterError
  } = useResourceRQ({
    endpoint: distributionCenterEndpoint,
    args: [supplierId, distributionCenterId],
    name: 'distribution_center',
    fetchingEnabled: fetchingEnabled
      ? Boolean(distributionCenterId) && Boolean(supplierId)
      : fetchingEnabled,
    dependents: [
      distributionCenterListEndpoint,
      supplierEndpoint,
      distributionCenterVariantsListEndpoint
    ]
  })

  const { destroy: destroyDistributionCenter } = useResourceRQ({
    endpoint: distributionCenterEndpoint,
    args: [supplierId, distributionCenterId],
    fetchingEnabled: false,
    dependents: [distributionCenterListEndpoint, supplierEndpoint]
  })

  return {
    distributionCenter,
    updateDistributionCenter,
    createDistributionCenter,
    isLoadingDistributionCenter,
    isDistributionCenterError,
    distributionCenterError,
    destroyDistributionCenter
  }
}

export default useAdminDistributionCenter
