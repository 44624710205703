const jssStyles = theme => ({
  label: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1)
  },
  labelError: {
    color: theme.palette.warnings.danger
  },
  menuPaper: {
    background: theme.palette.background.paper,
    borderRadius: 5
  },
  disabled: {
    opacity: 0.65
  },
  input: {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,

    '&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main
    }
  },
  centeredInput: {
    padding: '20px 12px 20px'
  },
  centeredInputDense: {
    padding: '10px 12px 10px !important'
  },
  formControl: {
    marginBottom: theme.spacing(3)
  },
  formControlNoMarginBottom: {
    marginBottom: theme.spacing(1)
  },
  multiline: {
    padding: 0
  }
})

export default jssStyles
