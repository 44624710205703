import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CreateIcon from '@material-ui/icons/Create'
import IconButton from '@material-ui/core/IconButton'

import { useMediaQuery } from 'hooks'
import { TOOLTIP_PLACEMENT } from 'constants/enums'

import Tooltip from 'components/Tooltip'
import Button from 'components/Button'

import styles from './GlanceTileStyles'

const GlanceTile = ({
  classes,
  className,
  name,
  catKey,
  label,
  tooltip,
  value,
  isActive,
  onClick,
  onEdit,
  large,
  minimal,
  dataTest,
  ...props
}) => {
  const { isMobileScreen } = useMediaQuery()

  const rootClassNames = classNames({
    [classes.root]: true,
    [className]: Boolean(className),
    [classes.isActionable]: Boolean(onClick),
    [classes.isMobile]: Boolean(isMobileScreen),
    [classes.isActive]: Boolean(onClick && isActive),
    [classes.large]: Boolean(large),
    [classes.minimal]: Boolean(minimal),
    [classes.editable]: Boolean(onEdit)
  })

  const showIconButton = isMobileScreen || !large

  return (
    <Tooltip title={tooltip} placement={TOOLTIP_PLACEMENT.top} {...props}>
      <Box
        className={rootClassNames}
        onClick={() => onClick && onClick(name, catKey)}
        data-test={dataTest}
      >
        <Typography variant="h3" className={classes.value}>
          {value}
        </Typography>
        <Typography variant="body1" className={classes.label}>
          {label}
        </Typography>
        {onEdit &&
          (showIconButton ? (
            <IconButton className={classes.updateButton} onClick={onEdit}>
              <CreateIcon />
            </IconButton>
          ) : (
            <Button
              className={classes.updateButton}
              onClick={onEdit}
              variant="outlined"
            >
              Update
            </Button>
          ))}
      </Box>
    </Tooltip>
  )
}

GlanceTile.defaultProps = {
  value: '---',
  large: false,
  minimal: false
}

GlanceTile.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  catKey: PropTypes.string,
  label: PropTypes.node,
  tooltip: PropTypes.string,
  value: PropTypes.node,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  large: PropTypes.bool,
  minimal: PropTypes.bool,
  onEdit: PropTypes.func,
  dataTest: PropTypes.string
}

export default withStyles(styles)(GlanceTile)
