import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, groupBy } from 'lodash'
import intersection from 'lodash/intersection'
import moment from 'moment-timezone'
import { Box, Typography, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { MdPedalBike } from 'react-icons/md'
import SearchIcon from 'icons/SearchIcon'

import { syntheticEvent } from 'utils/general'
import { getOrderStatesByCategoryKey } from 'utils/orders'
import {
  dateRangeToQueryParams,
  sortPossibilitiesFromColumns
} from 'utils/query'
import {
  ORDER_WRITABLE_TRANSITIONS,
  ORDER_PAYMENT_STATES,
  ORDER_REFUND_STATES,
  ORDER_SEARCH_TYPES,
  REFRESH_INTERVAL_OPTIONS
} from 'constants/general'
import { URL } from 'constants/navigation'
import { API_PARAMS, LIST_PATHS, SORT } from 'constants/queryParams'

import { deliveryWindowsEndpoint } from 'api/endpoints'

import {
  useNavigation,
  useAdminOrdersList,
  useQuery,
  useResourceRQ,
  useAppVersion,
  useLocalStorage,
  useMediaQuery,
  useRestoreScroll
} from 'hooks'
import { useLocationsContext } from 'context'

import Accordion from 'components/Accordion'
import DataTable from 'components/DataTable'
import DateRangePicker from 'components/DateRangePickerNew'
import GlanceTile from 'components/GlanceTile'
import Header from 'components/Header'
import Layout from 'components/Layout'
import OrderItem from 'containers/admin/OrderItem'
import SearchInput from 'components/SearchInput'
import Select from 'components/Select'

import FlagIcon from 'icons/FlagIcon'

import styles from './OrderListStyles'

const LOCATION_TITLE = 'Location'

const columns = [
  {
    title: 'Order #',
    sortKeys: [LIST_PATHS.ORDERS_NUMBER]
  },
  {
    title: 'Customer',
    sortKeys: [LIST_PATHS.ORDERS_FIRST_NAME]
  },
  { title: 'Total' },
  {
    title: 'Address',
    sortKeys: [LIST_PATHS.ORDERS_ADDRESS]
  },
  { title: 'Payment' },
  {
    title: 'Date',
    sortKeys: [LIST_PATHS.ORDERS_DELIVERY_STARTS]
  },
  { title: LOCATION_TITLE },
  { title: 'Method' },
  {
    title: 'Placed At',
    sortKeys: [LIST_PATHS.ORDERS_CREATED_AT]
  },
  { title: 'Return' },
  { title: 'Status' },
  { title: '' }
]

const columnsWithoutLocation = columns.filter(c => c.title !== LOCATION_TITLE)
const sortPossibilities = sortPossibilitiesFromColumns(columns)

const defaultSortDirection = {
  [LIST_PATHS.ORDERS_NUMBER]: SORT.DESC
}

const DELIVERY_WINDOWS_OPTIONS = [
  {
    group: 'Windows',
    items: [
      { label: '9:00 AM - 1:00 PM', value: '9-13' },
      { label: '1:00 PM - 5:00 PM', value: '13-17' },
      { label: '5:00 PM - 9:00 PM', value: '17-21' }
    ]
  },
  {
    items: [{ label: 'All Windows', value: 'all' }]
  }
]

const GROUPED_ORDER_STATES = [
  {
    group: 'Delivery',
    items: ORDER_WRITABLE_TRANSITIONS
  },
  {
    group: 'Payment',
    items: ORDER_PAYMENT_STATES
  },
  {
    group: 'Returns',
    items: ORDER_REFUND_STATES
  }
]

const USER_TYPE_LABELS = [
  {
    label: 'VIP',
    value: 'vip'
  },
  // {
  //   label: (
  //     <>
  //       <Box mr={1}>
  //         <MdFiberNew fontSize={26} />
  //       </Box>{' '}
  //       New
  //     </>
  //   ),
  //   value: 'new'
  // },
  // {
  //   label: (
  //     <>
  //       <Box mr={1}>
  //         <GiSpermWhale fontSize={26} color={LIGHT_BLUE} />
  //       </Box>{' '}
  //       Whale
  //     </>
  //   ),
  //   value: 'whale'
  // },
  {
    label: '$250+',
    value: '250+'
  }
]

const GROUPED_USER_TYPE_OPTIONS = [
  {
    group: 'User Types',
    items: USER_TYPE_LABELS
  }
]

const getKeys = (keys, opts) =>
  keys.length === opts.length ? '' : keys.join(',').trim()

const PressEnterToSearchInput = ({
  classes,
  searchBy,
  query,
  clearDateRange,
  handleQueryChange
}) => {
  const name =
    searchBy === API_PARAMS.SEARCH_BY_NUMBER
      ? API_PARAMS.SEARCH_BY_NUMBER
      : API_PARAMS.SEARCH

  const [localValue, setLocalValue] = useState('')
  const executeSearch = e => handleQueryChange(e)
  const executeSearchWithEnter = e => {
    if (e.key === 'Enter') {
      executeSearch(e)
    }
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <SearchInput
        dataTest="orders-search"
        className={classes.accountForButtonWidth}
        hideStartAdornment
        name={name}
        placeholder="Search Orders..."
        value={query[name]}
        onChange={e => {
          clearDateRange(LIST_PATHS.ORDERS_DELIVERY_STARTS)
          setLocalValue(e.target.value)
          !e.target.value && executeSearch(e)
        }}
        onKeyPress={executeSearchWithEnter}
      />
      <IconButton
        color="primary"
        onClick={e => executeSearch(syntheticEvent(localValue, name))}
      >
        <SearchIcon />
      </IconButton>
    </Box>
  )
}

const OrderList = ({ classes }) => {
  const { isDesktopScreen: isMobile } = useMediaQuery()
  const [
    orderListRefreshInterval,
    setOrderListRefreshInterval
  ] = useLocalStorage('FASTAF_OPTS_ORDER_LIST_REFRESH_INTERVAL', 300000)

  // APP WIDE UPDATE FETCHER
  const { updatePending } = useAppVersion()
  if (updatePending) {
    window.location.reload(true) // force refetch from server
  }

  const { showAllLocationIds, locationId, location } = useLocationsContext()

  const { query, handleQueryChange, setQuery, updateQuery } = useQuery(
    () => {},
    {
      initFallback: {
        [API_PARAMS.SORT]: LIST_PATHS.ORDERS_NUMBER,
        [API_PARAMS.SORT_DIRECTION]:
          defaultSortDirection[LIST_PATHS.ORDERS_NUMBER]
      },
      locationFilter: LIST_PATHS.ORDERS_LOCATION_ID,
      clearParamsOnLocationChange: [
        `${LIST_PATHS.ORDERS_DELIVERY_STARTS}.rel`,
        LIST_PATHS.ORDERS_DELIVERY_STARTS
      ]
    }
  )

  const {
    isLoadingOrders,
    orders,
    hasOrdersNext,
    fetchOrdersNext,
    ordersMeta
  } = useAdminOrdersList({
    refetchInterval: orderListRefreshInterval,
    params: query
  })

  const listRef = useRef(null)
  useRestoreScroll(
    listRef,
    'order-list',
    Array.isArray(orders) && orders.length > 0
  )

  const [searchBy, setSearchBy] = useState('')
  const [accordionExpanded, setAccordionExpanded] = useState(false)

  const { data: deliveryWindowsPipeline = [] } = useResourceRQ({
    endpoint: () => deliveryWindowsEndpoint(locationId),
    fetchingEnabled: !!location?.timezone,
    options: {
      refetchInterval: accordionExpanded ? 5000 : 300000
    }
  })

  const thresholds = Object.entries(
    groupBy(deliveryWindowsPipeline || [], 'title')
  ).sort(
    ([aWindow, aWindows], [bWindow, bWindows]) =>
      moment(aWindows[0].start) - moment(bWindows[0].start)
  )

  const deliveryWindowsValue = () =>
    query[LIST_PATHS.ORDERS_DELIVERY_WINDOWS]
      ? query[LIST_PATHS.ORDERS_DELIVERY_WINDOWS].split(',')
      : []

  const statusFilterValue = () => {
    const orderStatusArr = query[LIST_PATHS.ORDERS_STATUS]?.split(',') || []
    const orderPaymentArr =
      query[LIST_PATHS.ORDERS_PAYMENT_STATUS]?.split(',') || []
    const returnPaymentArr =
      query[LIST_PATHS.ORDERS_RETURN_STATUS]?.split(',') || []

    const statusFilterArray = [
      ...orderStatusArr,
      ...orderPaymentArr,
      ...returnPaymentArr
    ]

    return !isEmpty(statusFilterArray) ? statusFilterArray : []
  }

  const [statusFilter, setStatusFilter] = useState(statusFilterValue())
  const [userTypeFilter, setUserTypeFilter] = useState(
    query[LIST_PATHS.ORDERS_USER_TYPE]?.split(',') || []
  )

  const [deliveryWindowsFilter, setDeliveryWindowsFilter] = useState(
    deliveryWindowsValue()
  )

  const handleSetStatusFilter = e => {
    const val = [...e.target.value.filter(Boolean)].flat()
    setStatusFilter(val)

    const statusOpts = ORDER_WRITABLE_TRANSITIONS.map(({ value }) => value)
    const paymentOpts = ORDER_PAYMENT_STATES.map(({ value }) => value)
    const refundOpts = ORDER_REFUND_STATES.map(({ value }) => value)

    const statusKeys = intersection(val, statusOpts)
    const paymentKeys = intersection(val, paymentOpts)
    const refundKeys = intersection(val, refundOpts)

    updateQuery({
      [LIST_PATHS.ORDERS_STATUS]: getKeys(statusKeys, statusOpts),
      [LIST_PATHS.ORDERS_PAYMENT_STATUS]: getKeys(paymentKeys, paymentOpts),
      [LIST_PATHS.ORDERS_RETURN_STATUS]: refundKeys.join(',').trim()
    })
  }

  const handleSetUserTypeFilter = e => {
    const user_type_values = [...e.target.value.filter(Boolean)].flat()
    setUserTypeFilter(user_type_values)

    const userTypeOpts = USER_TYPE_LABELS.map(({ value }) => value)
    const userTypeKeys = intersection(user_type_values, userTypeOpts)

    updateQuery({
      [LIST_PATHS.ORDERS_USER_TYPE]: userTypeKeys.join(',').trim()
    })
  }

  const handleSortSelect = ({ target: { value } }) => {
    const additionalParams =
      value in defaultSortDirection
        ? { [API_PARAMS.SORT_DIRECTION]: defaultSortDirection[value] }
        : { [API_PARAMS.SORT_DIRECTION]: '' }
    updateQuery({ [API_PARAMS.SORT]: value, ...additionalParams })
  }

  const { handleClick } = useNavigation({ url: URL.ADMIN_ORDERS })

  const handleGlanceClick = (name, key) => {
    const val = getOrderStatesByCategoryKey(key).state
    if (query[LIST_PATHS.ORDERS_STATUS] === val) {
      delete query[LIST_PATHS.ORDERS_STATUS]
      setQuery({ ...query })
    } else {
      handleQueryChange(syntheticEvent(val, name))
    }
  }

  const dateRangeValue = () =>
    query[LIST_PATHS.ORDERS_DELIVERY_STARTS]
      ? query[LIST_PATHS.ORDERS_DELIVERY_STARTS]
          .split(',')
          .map(s =>
            !!location?.timezone ? moment(s).tz(location.timezone) : moment(s)
          )
      : [null, null]

  const clearDateRange = listPath => {
    delete query[listPath]
    delete query[`${listPath}.rel`]
  }

  const handleDateRangeChange = ([start, end]) => {
    if (start === null && end === null) {
      clearDateRange(LIST_PATHS.ORDERS_DELIVERY_STARTS)
      setQuery({ ...query })
      return
    }

    if (start && end) {
      const params = dateRangeToQueryParams(
        LIST_PATHS.ORDERS_DELIVERY_STARTS,
        [start, end],
        location
      )
      setQuery({ ...query, ...params })
    }
  }

  const orderSummaries = [
    {
      key: 'total',
      label: 'Orders Total',
      value: ordersMeta?.total_count
    },
    {
      key: 'picking',
      label: 'Orders Picking',
      value: ordersMeta?.summary?.picking
    },
    {
      key: 'dispatched',
      label: 'Orders Dispatched',
      value: ordersMeta?.summary?.dispatched
    },
    {
      key: 'shipped',
      label: 'Orders En Route',
      value: ordersMeta?.summary?.shipped
    }
  ]

  const summarizers = orderSummaries.map(sumOrder => (
    <GlanceTile
      minimal
      name={LIST_PATHS.ORDERS_STATUS}
      key={sumOrder.key}
      catKey={sumOrder.key}
      label={sumOrder.label}
      value={sumOrder.value}
      onClick={handleGlanceClick}
      dataTest={`order-glance-${sumOrder.key}`}
    />
  ))

  const foldableActions = (
    <Box
      display="flex"
      alignItems={isMobile ? 'flex-start' : 'center'}
      justifyContent="flex-start"
      flexDirection={isMobile ? 'column' : 'row'}
    >
      <Box
        mb={isMobile ? 1 : 0}
        display="flex"
        alignItems="flex-start"
        justifyItems="flex-start"
      >
        <Select
          inp
          minimal
          name="order_list_refetch_interval"
          innerLabel="Refresh Interval"
          value={orderListRefreshInterval}
          items={REFRESH_INTERVAL_OPTIONS}
          onChange={e => setOrderListRefreshInterval(e.target.value)}
          dataTest="order_list_refetch_interval"
        />
        <Select
          minimal
          name="search_by_toggle"
          innerLabel="Search By"
          value={searchBy}
          items={Object.entries(ORDER_SEARCH_TYPES).map(([key, value]) => ({
            key,
            label: value,
            value: key
          }))}
          onChange={e => {
            updateQuery({
              [API_PARAMS.SEARCH]: '',
              [API_PARAMS.SEARCH_BY_NUMBER]: ''
            })
            setSearchBy(
              e.target.value === 'search_by_number'
                ? API_PARAMS.SEARCH_BY_NUMBER
                : API_PARAMS.SEARCH
            )
          }}
          withAll
          dataTest="order-search-by"
        />
      </Box>
      <PressEnterToSearchInput
        classes={classes}
        searchBy={searchBy}
        query={query}
        clearDateRange={clearDateRange}
        handleQueryChange={handleQueryChange}
      />
    </Box>
  )

  const filters = (
    <>
      <DateRangePicker
        name={LIST_PATHS.ORDERS_DELIVERY_STARTS}
        onChange={handleDateRangeChange}
        value={dateRangeValue()}
        onClearDates={() => handleDateRangeChange([null, null])}
        noDatesOutsideRange
      />
      <Select
        minimal
        name={LIST_PATHS.ORDERS_DELIVERY_WINDOWS}
        innerLabel="Delivery Windows"
        value={deliveryWindowsFilter}
        groupedItems={DELIVERY_WINDOWS_OPTIONS}
        onChange={e => {
          const delivery_windows = [...e.target.value.filter(Boolean)].flat()
          const val = delivery_windows.includes('all') ? [] : delivery_windows
          setDeliveryWindowsFilter(val)
          updateQuery({
            [LIST_PATHS.ORDERS_DELIVERY_WINDOWS]: val.join(',').trim()
          })
        }}
        multiple
      />
      <Select
        minimal
        name={API_PARAMS.SORT}
        innerLabel="Sort By"
        value={query[API_PARAMS.SORT] || ''}
        items={sortPossibilities}
        onChange={handleSortSelect}
        withNone
        dataTest="order-sort"
      />
      <Select
        minimal
        name={`search-${LIST_PATHS.ORDER_STATUS},${LIST_PATHS.ORDERS_PAYMENT_STATUS},${LIST_PATHS.ORDERS_RETURN_STATUS}`}
        innerLabel="Order Status"
        value={statusFilter}
        groupedItems={GROUPED_ORDER_STATES}
        onChange={handleSetStatusFilter}
        multiple
        dataTest="order-status-select"
      />
      <Select
        minimal
        name={LIST_PATHS.ORDERS_USER_TYPE}
        innerLabel="User Type"
        value={userTypeFilter}
        groupedItems={GROUPED_USER_TYPE_OPTIONS}
        onChange={handleSetUserTypeFilter}
        multiple
      />
    </>
  )

  const getOrdersMessage = () => {
    if (orders.length > 0) return null

    if (!query[LIST_PATHS.ORDERS_DELIVERY_STARTS]) {
      return 'No deliveries found.'
    }

    const [start, end] = query[LIST_PATHS.ORDERS_DELIVERY_STARTS]
      .split(',')
      .map(s =>
        !!location?.timezone ? moment(s).tz(location.timezone) : moment(s)
      )

    if (!!start || !!end) {
      return 'No deliveries found.'
    }

    if (!start?.isSame(end, 'day'))
      return `No deliveries between ${start?.format('MMM D')} and ${end.format(
        'MMM D'
      )}.`

    return end?.isSame(moment(), 'day')
      ? 'No deliveries today yet!'
      : `No deliveries on ${start?.format('MMM D')}`
  }

  const currentTimeInTimezone = !!location?.timezone
    ? moment().tz(location?.timezone)
    : moment()

  return (
    <Layout fullWidth>
      <Header
        title="Orders"
        foldableActions={foldableActions}
        filters={filters}
        summarizers={summarizers}
        iLoveTightBottoms={!!location?.timezone}
      />
      {locationId && (
        <Accordion
          id="pipeline-accordion"
          leftIcon={<MdPedalBike fontSize={24} style={{ marginLeft: 16 }} />}
          title="Delivery Pipeline"
          minimal
          className={classes.deliveryWindowPipelineAccordion}
          onChange={() => setAccordionExpanded(!accordionExpanded)}
          expanded={accordionExpanded}
        >
          <Box
            display="flex"
            alignItems="flex"
            flexDirection="column"
            width="100%"
          >
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-start"
              width="100%"
              flexWrap="wrap"
            >
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                width="100%"
                flexWrap="wrap"
              >
                {thresholds?.map(([title, windows], i) => (
                  <Box
                    marginRight={8}
                    key={title}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Typography variant="h3">{title.split(',')[0]}</Typography>
                    <Box height="16px" />
                    {windows?.map(
                      ({ orders_count, orders_limit, label, start, end }) => {
                        const isWindowInPast = !!location?.timezone
                          ? moment(end)
                              .tz(location.timezone)
                              .isSameOrBefore(currentTimeInTimezone)
                          : moment(end).isSameOrBefore(currentTimeInTimezone)

                        const isCurrentWindow = !!location?.timezone
                          ? moment(currentTimeInTimezone)
                              .tz(location.timezone)
                              .isBetween(
                                moment(start).tz(location.timezone),
                                moment(end).tz(location.timezone)
                              )
                          : moment(currentTimeInTimezone).isBetween(
                              moment(start),
                              moment(end)
                            )

                        const closed = orders_limit === 0

                        let backgroundColor =
                          i === 0 || isWindowInPast || closed ? 'gray' : 'green'

                        if (!closed) {
                          let threshold = 0
                          threshold = orders_count / orders_limit

                          if (threshold > 0.9) {
                            backgroundColor = 'red'
                          }

                          if (threshold < 0.9 && threshold > 0.8) {
                            backgroundColor = 'orange'
                          }
                        }

                        return (
                          <Box
                            display="flex"
                            flexDirection="column"
                            key={window.label}
                          >
                            <Typography variant="caption">{label}</Typography>
                            <Box
                              padding="8px"
                              margin="8px 0"
                              color="white"
                              textAlign="center"
                              borderRadius="4px"
                              style={{
                                backgroundColor,
                                border:
                                  isCurrentWindow > 0
                                    ? '4px solid gold'
                                    : `4px solid ${backgroundColor}`
                              }}
                            >
                              <Typography variant="body1">
                                {orders_count} /{' '}
                                {orders_limit === 999 ? (
                                  <span
                                    styles={{
                                      fontSize: 32
                                    }}
                                  >
                                    ∞
                                  </span>
                                ) : (
                                  orders_limit
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        )
                      }
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Accordion>
      )}
      <DataTable
        columns={showAllLocationIds ? columns : columnsWithoutLocation}
        message={getOrdersMessage()}
        isLoadingList={isLoadingOrders}
        hasListNext={hasOrdersNext}
        listNext={fetchOrdersNext}
        query={query}
        updateQuery={updateQuery}
        ref={listRef}
      >
        {orders?.length > 0 &&
          orders.map(order => (
            <OrderItem
              key={order.id}
              order={order}
              showAllLocationIds={showAllLocationIds}
              onClick={handleClick}
            />
          ))}
      </DataTable>
    </Layout>
  )
}

OrderList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(OrderList)
