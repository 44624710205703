import { alpha } from '@material-ui/core/styles'
import { FLEX_CENTER, ABSOLUTE_FILL_FULL } from 'constants/styles'

const ICON_WIDTH = 35

const jssStyles = theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    border: 'solid 1px',
    borderColor: theme.palette.grays.med,
    color: theme.palette.grays.dark,
    backgroundColor: theme.palette.grays.light,
    overflow: 'hidden'
  },
  previewBackdrop: {
    ...ABSOLUTE_FILL_FULL,
    backgroundColor: theme.palette.grays.light
  },
  imageContain: {
    ...ABSOLUTE_FILL_FULL,
    objectFit: 'contain'
  },
  imageCover: {
    ...ABSOLUTE_FILL_FULL,
    objectFit: 'cover'
  },
  rightTopButtons: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 4,
    margin: 4
  },
  iconButton: {
    padding: 4,
    margin: 4,
    width: ICON_WIDTH,
    height: ICON_WIDTH,
    backgroundColor: alpha(theme.palette.common.black, 0.4),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.7)
    }
  },
  errorBox: {
    ...FLEX_CENTER,
    ...ABSOLUTE_FILL_FULL,
    textAlign: 'center',
    backgroundColor: theme.palette.grays.light,
    flexDirection: 'column'
  },
  placeholder: {
    ...FLEX_CENTER,
    ...ABSOLUTE_FILL_FULL,
    padding: theme.spacing(2, 5),
    flexDirection: 'column',
    textAlign: 'center'
  },
  icon: {
    marginBottom: theme.spacing(2),
    fill: theme.palette.grays.med
  },
  error: {
    borderColor: theme.palette.warnings.error
  },
  untouched: {
    cursor: 'pointer'
  },
  round: {
    ...ABSOLUTE_FILL_FULL,
    borderRadius: '100%',
    margin: 'auto',
    maxWidth: 300,
    maxHeight: 300
  }
})

export default jssStyles
