import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

import { DRAG_N_DROP_MODE } from 'constants/enums'
import { DRAGGABLE_IMAGE_DIMS } from 'constants/styles'

import Dialog from 'components/Dialog'
import DragNDropGrid from 'components/DragNDropGrid'
import DraggableImage from 'components/DraggableImage'
import { useControlledForm } from 'components/ControlledForm'

const SelectImagesDialog = ({
  name,
  title,
  subtitle,
  open,
  selectableImages,
  onClose,
  onConfirm
}) => {
  const {
    formContext: { register, unregister, setValue, watch, clearErrors }
  } = useControlledForm()

  useEffect(() => {
    register(name)
    return () => {
      unregister(name)
    }
  }, [register, unregister, name])

  const { [name]: selectedImages } = watch(
    [name].filter(n => n && typeof n === 'string')
  )

  const [localSelections, setLocalSelections] = useState([])
  const [selectionsChanged, setSelectionsChanged] = useState(false)

  useEffect(() => {
    if (Array.isArray(selectedImages)) {
      setLocalSelections(selectedImages)
    }
  }, [selectedImages])

  const handleSelectClick = thisImg => {
    if (localSelections.filter(img => img.src === thisImg.src).length) {
      setLocalSelections([
        ...localSelections.filter(img => img.src !== thisImg.src)
      ])
    } else {
      setLocalSelections([...localSelections, thisImg])
    }

    if (!selectionsChanged) {
      setSelectionsChanged(true)
    }
  }

  const handleClose = () => {
    setSelectionsChanged(false)
    clearErrors(name)
    setLocalSelections([])
    onClose()
  }

  const handleConfirm = () => {
    setValue(name, localSelections, { shouldDirty: true })
    onConfirm && onConfirm()
    setLocalSelections([])
    onClose()
  }

  const getItemProps = useCallback(
    thisImg => ({
      selected: Boolean(
        localSelections.filter(localImg => localImg.src === thisImg.src).length
      )
    }),
    [localSelections]
  )

  return (
    <Dialog
      title={title}
      subtitle={subtitle}
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirmText="Commit"
      confirmDisabled={!selectionsChanged}
    >
      <Box my={2}>
        <DragNDropGrid
          items={selectableImages}
          ItemComponent={DraggableImage}
          onSelectClick={handleSelectClick}
          mode={DRAG_N_DROP_MODE.SELECTING}
          getItemProps={getItemProps}
          {...DRAGGABLE_IMAGE_DIMS}
        />
      </Box>
    </Dialog>
  )
}

SelectImagesDialog.defaultProps = {
  selectableImages: []
}

SelectImagesDialog.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  selectableImages: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func
}

export default SelectImagesDialog
