import { ALL_LOCATION } from 'constants/general'
import { sumBy } from 'lodash'

export const getProductImageUrls = (product, type, params = {}) => {
  const hasImages = Array.isArray(product?.raw_images)
  switch (type) {
    case 'product': {
      return hasImages ? product?.raw_images : []
    }
    case 'variant': {
      return hasImages
        ? product?.raw_images?.filter(img =>
            img?.variant_ids?.includes(Number(params.variantId))
          )
        : []
    }
    default:
      return []
  }
}

export const priceLabel = product => {
  if (!product?.variants) return `$${product?.price}`

  const range = product?.variants.reduce(
    ({ min, max }, { price }) => ({
      min: min > parseFloat(price) ? parseFloat(price) : min,
      max: max < parseFloat(price) ? parseFloat(price) : max
    }),
    {
      min: Number.MAX_SAFE_INTEGER,
      max: Number.MIN_SAFE_INTEGER
    }
  )

  return range.min === range.max
    ? `$${range.min}`
    : `$${range.min} - $${range.max}`
}

export const stockSummary = (product, locationId) => {
  if (!product?.variants?.some(v => v?.inventory?.length > 0)) {
    if (product?.variants?.length > 0) {
      return product.variants.reduce(
        ({ quantity, inStock }, { available_quantity }) => ({
          quantity: quantity + available_quantity,
          inStock: inStock + available_quantity
        }),
        { quantity: 0, inStock: 0 }
      )
    }
    return {}
  }

  return product.variants.reduce(
    ({ quantity, inStock }, { inventory }) => {
      const locationInventories = inventory.filter(
        inv => inv.location.id === locationId || locationId === ALL_LOCATION.id
      )

      if (!locationInventories.length) {
        return { quantity, inStock }
      }

      return {
        quantity: quantity + sumBy(locationInventories, 'quantity'),
        inStock:
          inStock + (locationInventories.find(inv => inv.quantity) ? 1 : 0)
      }
    },
    { quantity: 0, inStock: 0 }
  )
}

export const calcPriceByQuantity = (product, quantity) =>
  `$${(product.price * (quantity >= 0 ? quantity : product.quantity)).toFixed(
    2
  )}`

const getExistingImage = (arrayOfImages = []) =>
  arrayOfImages.filter(img => !!img?.thumbnail_url)

export const getProductImage = (product = {}) =>
  product?.product?.thumbnail_url ??
  getExistingImage(product?.images)[0]?.thumbnail_url ??
  product?.thumbnail_url ??
  getExistingImage(product?.product?.images)[0]?.thumbnail_url ??
  getExistingImage(product?.variant?.images)[0]?.thumbnail_url
