const jssStyles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 'auto',
    position: 'relative',

    '& > *': {
      marginLeft: theme.spacing(2)
    }
  },
  filterText: {
    display: 'inline-block',
    fontWeight: 400
  },
  summaries: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  iLoveTightBottoms: {
    marginBottom: 0,
    paddingBottom: 0
  }
})
export default jssStyles
