const jssStyles = theme => ({
  root: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1, 0)
    },
    '& .MuiButtonBase-root': {
      margin: theme.spacing(2, 0)
    },
    '& .MuiFormControlLabel-root': {
      marginRight: '0px !important'
    }
  },
  container: {
    marginTop: theme.spacing(5)
  },
  flexRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    '& > *': {
      flexGrow: 1
    },
    gridColumnGap: theme.spacing(3),
    width: '66%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  errorMessage: {
    color: theme.palette.warnings.danger
  },
  formField: {
    marginLeft: '0px !important',
    alignItems: 'start',
    '& .MuiInputBase-root': {
      width: '100%',
      height: 40
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 600
    },
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5, 2.5)
    }
  },
  block: {
    marginBottom: 0,
    padding: theme.spacing(3)
  },
  middleBlock: {
    borderTop: 0,
    borderBottom: 0
  },
  selectRoot: {
    width: '100%'
  },
  sectionTitle: {
    fontWeight: 600
  },
  checkboxGroup: {
    display: 'flex',
    height: 44,
    alignSelf: 'flex-end',
    padding: theme.spacing(0, 2),
    gridColumnGap: theme.spacing(4),
    marginBottom: theme.spacing(3),
    '& .MuiFormControlLabel-root': {
      marginRight: `${theme.spacing(1)}px !important`
    }
  }
})

export default jssStyles
