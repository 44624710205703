import { ABSOLUTE_FILL_FULL } from 'constants/styles'
import MobilePreviewCursor from 'assets/mobile_preview_cursor.png'

const BASE_HEIGHT_PX = 1618
const BASE_WIDTH_PX = 800

const HIDE_SCROLL_BARS = {
  MsOverflowStyle: 'none', // IE 10+
  scrollbarWidth: 'none', // Firefox
  '&::-webkit-scrollbar': {
    display: 'none' // Safari and Chrome:
  }
}

const SLIDER = {
  display: 'flex',
  overflowX: 'auto',
  marginLeft: -37,
  '& > *:first-child': {
    marginLeft: 37
  },
  ...HIDE_SCROLL_BARS
}

const jssStyles = theme => ({
  root: {
    width: '100%',
    height: '95vh',
    minWidth: 200,
    minHeight: 400
  },
  scaledView: {
    width: BASE_WIDTH_PX,
    height: BASE_HEIGHT_PX,
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    transformOrigin: 'center center'
  },

  frame: {
    ...ABSOLUTE_FILL_FULL,
    pointerEvents: 'none'
  },
  contentPadder: {
    ...ABSOLUTE_FILL_FULL,
    padding: '212px 44px 218px'
  },

  content: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    paddingLeft: 37,
    userSelect: 'none',
    cursor: `url(${MobilePreviewCursor}), auto`,
    ...HIDE_SCROLL_BARS
  },
  textHeader: {
    fontSize: 22,
    fontWeight: 700
  },
  textOverline: {
    color: theme.palette.colorsAF.lightGray,
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'capitalize'
  },
  textPrice: {
    fontSize: 14,
    fontWeight: 400
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '32px',
    alignItems: 'baseline'
  },
  sectionCollections: {
    ...SLIDER,
    '& > *': {
      flexShrink: 0,
      width: 180,
      height: 180,
      borderRadius: 90,
      overflow: 'hidden'
    },
    '& > *:not(:first-child)': {
      marginLeft: 36
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  sectionProducts: {
    ...SLIDER,
    '& > *': {
      width: 315,
      flexShrink: 0,
      borderRadius: 9,
      overflow: 'hidden'
    },
    '& > *:not(:first-child)': {
      marginLeft: 16
    }
  },
  sectionProduct: {
    '& img': {
      objectFit: 'cover',
      width: 315,
      height: 315
    },
    '& > *:not(:last-child)': {
      marginBottom: 15
    }
  },
  sectionReferralCode: {
    height: 150,
    backgroundColor: theme.palette.colorsAF.lightFast,
    borderRadius: 15,
    marginRight: 36,
    marginTop: 70,
    '& > *': {
      lineHeight: '150px',
      textAlign: 'center'
    }
  }
})

export { jssStyles as default, BASE_HEIGHT_PX, BASE_WIDTH_PX }
