import { ABSOLUTE_FILL_FULL } from 'constants/styles'

const jssStyles = theme => ({
  root: {
    padding: theme.spacing(3, 0)
  },
  paddingBlock: {
    padding: theme.spacing(3)
  },
  description: {
    marginTop: theme.spacing(3),
    fontSize: 16
  },
  stripeIdInput: {
    height: 44
  },
  button: {
    marginRight: theme.spacing(1)
  },
  brandImage: {
    borderRadius: 0
  },
  imageDropzone: {
    ...ABSOLUTE_FILL_FULL,
    backgroundColor: 'transparent',
    zIndex: 999
  },
  imageDropzonePlaceholder: {
    backgroundColor: 'black',
    opacity: 0.6
  },
  imageEditButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1000,
    color: 'white',
    backgroundColor: 'rgba(0,0,0,.15)',
    padding: 8
  },
  imageLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1000
  },
  brandNameField: {
    marginBottom: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      height: 40
    }
  },
  saveBrandNameButtonContainer: {
    '& .MuiButton-root': {
      height: 40
    },
    '& .MuiButton-root:first-of-type': {
      margin: theme.spacing(0, 1)
    }
  },
  mobileSaveBrandNameButtonContainer: {
    width: '100%',
    '& .MuiButton-root:first-of-type': {
      marginLeft: 0
    }
  }
})

export default jssStyles
