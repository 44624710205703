import React from 'react'
import PropTypes from 'prop-types'
import { colorsAF } from 'theme/colors'

import { Box, Typography } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'

import CollectionGroupPreview from 'components/CollectionGroupPreview.jsx'

const CollectionGroupCard = ({ collectionGroup, onItemClick }) => {
  const handleClick = e => {
    onItemClick(e, collectionGroup)
  }

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colorsAF.semiGray}`,
    borderRadius: 5,
    bgcolor: colorsAF.white,
    position: 'relative',
    overflow: 'hidden',
    padding: '8px',
    height: '100%'
  }

  const containerCSSStyle = {
    cursor: 'pointer'
  }

  return (
    <Box {...containerStyle} style={containerCSSStyle} onClick={handleClick}>
      <Typography data-test="collection-group-title">
        {collectionGroup.title}
      </Typography>
      <CollectionGroupPreview collectionGroup={collectionGroup} />
      <Box position="absolute" right="10px" bottom="10px">
        <Chip
          label={<Typography>{collectionGroup.id}</Typography>}
          color="secondary"
        />
      </Box>
    </Box>
  )
}

CollectionGroupCard.propTypes = {
  collectionGroup: PropTypes.object,
  onItemClick: PropTypes.func
}

export default CollectionGroupCard
