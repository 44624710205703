import { useResourceRQ, useResourceListRQ } from 'hooks'
import {
  fileInputProcessor,
  multiPartFormParams
} from 'hooks/common/useResourceRQ'
import { downloadApi, api } from 'api'

import {
  transferOrderEndpoint,
  transferOrderHistoryEndpoint,
  transferOrderListEndpoint,
  transferOrderSummaryEndpoint,
  transferOrderExportEndpoint,
  transferOrderSubmitEndpoint,
  transferOrderCloseEndpoint,
  transferOrderShipmentItemsEndpoint,
  transferOrderCsvImportEndpoint
} from 'api/endpoints'

const useAdminTransferOrder = (transferOrderId, onImportCSVSuccess) => {
  const {
    create: createTransferOrder,
    data: transferOrder,
    update: updateTransferOrder,
    isLoading: isLoadingTransferOrder,
    isError: isTransferOrderError,
    error: transferOrderError,
    refetch: refetchTransferOrder
  } = useResourceRQ({
    endpoint: transferOrderEndpoint,
    args: [transferOrderId],
    name: 'transfer_order',
    fetchingEnabled: Boolean(transferOrderId),
    dependents: [transferOrderListEndpoint, transferOrderSummaryEndpoint]
  })

  const { update: updateTransferOrderShipmentItems } = useResourceRQ({
    endpoint: transferOrderShipmentItemsEndpoint,
    args: [transferOrderId],
    name: 'transfer_order',
    fetchingEnabled: false,
    dependents: [transferOrderEndpoint]
  })

  const { destroy: destroyTransferOrder } = useResourceRQ({
    endpoint: transferOrderEndpoint,
    args: [transferOrderId],
    fetchingEnabled: false
  })

  const { operate: importCSV } = useResourceRQ({
    endpoint: transferOrderCsvImportEndpoint,
    args: [transferOrderId],
    fetchingEnabled: false,
    inputProcessor: fileInputProcessor('transfer_order[csv]'),
    params: multiPartFormParams,
    resetQueries: [
      () => [transferOrderEndpoint(), { transfer_order_id: transferOrderId }]
    ],
    onSuccess: onImportCSVSuccess
  })

  const getExport = async id => {
    await downloadApi.get(transferOrderExportEndpoint(id))
  }

  const submitTransferOrder = async id => {
    await api.put(transferOrderSubmitEndpoint(id))
    refetchTransferOrder()
  }

  const closeTransferOrder = async id => {
    await api.put(transferOrderCloseEndpoint(id))
    refetchTransferOrder()
  }

  const {
    isFetching: isFetchingHistory,
    data: history,
    hasData: hasHistory,
    hasNextPage: hasHistoryNext,
    fetchNextPage: fetchHistoryNext
  } = useResourceListRQ({
    endpoint: transferOrderHistoryEndpoint,
    args: [transferOrderId],
    params: {},
    fetchingEnabled: false
  })

  return {
    transferOrder,
    updateTransferOrder,
    createTransferOrder,
    isLoadingTransferOrder,
    isTransferOrderError,
    transferOrderError,
    refetchTransferOrder,
    submitTransferOrder,
    closeTransferOrder,
    updateTransferOrderShipmentItems,
    importCSV,
    getExport,
    destroyTransferOrder,
    isFetchingHistory,
    history,
    hasHistory,
    hasHistoryNext,
    fetchHistoryNext
  }
}

export default useAdminTransferOrder
