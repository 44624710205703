const jssStyles = theme => ({
  refunded: {
    display: 'flex',
    alignItems: 'center',
    gridGap: 4,
    marginBottom: theme.spacing(3),
    color: theme.palette.warnings.success,
    '& p': {
      fontWeight: 600
    }
  },
  divider: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grays.med}`
  },
  paddingBlock: {
    padding: theme.spacing(3, 0)
  }
})

export default jssStyles
