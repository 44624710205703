import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { isEmpty } from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { Button, Box, TableCell, TableRow, Typography } from '@material-ui/core'

import { useNavigation } from 'hooks'
import { URL } from 'constants/navigation'

import PurchaseOrderStatus from 'components/PurchaseOrderStatus'

import styles from './InventoryOrdersStyles'
import { BrandingWatermark } from '@material-ui/icons'
import { TERM_POSSIBILITIES } from 'constants/general'

const DATE_NOT_SET = 'Not set'

const InventoryOrderItem = ({ classes, purchaseOrder }) => {
  const {
    id,
    supplier,
    location,
    terms,
    order_date: orderDate,
    estimated_arrival_at: eta,
    status,
    brand
  } = purchaseOrder

  const { handleClick } = useNavigation({ url: URL.ADMIN_PURCHASE_ORDERS })

  return (
    <TableRow data-test="po-row">
      <TableCell>
        <Button
          color="primary"
          onClick={e => handleClick(e, purchaseOrder)}
          onAuxClick={e => handleClick(e, purchaseOrder, true)}
          data-test="po-item"
          className={classes.linkButton}
        >
          #{id}
        </Button>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {!isEmpty(supplier) ? supplier?.name : !!brand && brand}
        </Typography>
      </TableCell>
      <TableCell>{location}</TableCell>
      <TableCell>
        {
          Object.values(TERM_POSSIBILITIES).find(({ value }) => value === terms)
            ?.label
        }
      </TableCell>
      <TableCell>
        {orderDate ? moment(orderDate).format('L') : DATE_NOT_SET}
      </TableCell>
      <TableCell>{eta ? moment(eta).format('L') : DATE_NOT_SET}</TableCell>
      <TableCell>
        <PurchaseOrderStatus status={status} />
      </TableCell>
    </TableRow>
  )
}

InventoryOrderItem.propTypes = {
  classes: PropTypes.object.isRequired,
  purchaseOrder: PropTypes.object.isRequired
}

export default withStyles(styles)(InventoryOrderItem)
