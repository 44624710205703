const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  gridContainer: {
    flex: 1,
    marginTop: theme.spacing(1)
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    height: 'calc(100% - 24px)',
    width: '100%'
  },
  gridMapItem: {
    width: '100%'
  },
  manualUpdateLabel: {
    fontWeight: 600,
    marginRight: theme.spacing(4),
    paddingBottom: 3
  },
  block: {
    minHeight: 800,
    height: '100%'
  },
  paddingBlock: {
    padding: theme.spacing(3)
  },
  divider: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grays.med}`
  },
  productsContainer: {
    marginTop: theme.spacing(3)
  },
  select: {
    '& > svg.MuiSelect-icon': {
      width: 16
    }
  },
  isMobile: {
    '& $gridContainer': {
      marginTop: 0
    }
  },
  isDesktop: {
    '& $gridItem': {
      height: 'calc(100% - 100px)'
    }
  },
  isTablet: {
    '& $gridItem': {
      height: 'auto'
    }
  },
  infoSpan: {
    cursor: 'pointer'
  },
  tooltip: {
    textAlign: 'left',
    paddingBottom: theme.spacing(2),
    ...theme.typography.overline,
    lineHeight: '16px',
    textTransform: 'capitalize'
  },
  firstOrder: {
    borderColor: theme.palette.tierBadge.blue,
    borderWidth: 2,
    borderStyle: 'solid'
  }
})

export default jssStyles
