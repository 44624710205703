const jssStyles = theme => ({
  dropZone: {
    touchAction: 'none',
    flex: 1
  },
  container: {
    touchAction: 'none',
    display: 'flex',
  },
})
export default jssStyles
