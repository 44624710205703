import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Typography
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { withStyles } from '@material-ui/core/styles'

import styles from './AccordionStyles'

const Accordion = ({
  id,
  classes,
  leftIcon,
  title,
  children,
  minimal,
  className,
  onChange,
  expanded
}) => (
  <MuiAccordion
    {...(typeof onChange === 'function' &&
      typeof expanded !== 'undefined' && {
        onChange,
        expanded
      })}
    className={classNames({
      [classes.root]: true,
      [classes.minimal]: Boolean(minimal),
      [className]: !!className
    })}
  >
    <MuiAccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`${id}-content`}
      id={`${id}-header`}
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        {leftIcon && <Box mr={2}>{leftIcon}</Box>}
        {typeof title === 'string' ? (
          <Typography className={classNames({ [classes.header]: true })}>
            {title}
          </Typography>
        ) : (
          title
        )}
      </Box>
    </MuiAccordionSummary>
    <MuiAccordionDetails className={classNames({ [classes.content]: true })}>
      {children}
    </MuiAccordionDetails>
  </MuiAccordion>
)

Accordion.defaultProps = {}

Accordion.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  leftIcon: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.element,
  minimal: PropTypes.bool
}

export default withStyles(styles)(Accordion)
