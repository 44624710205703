import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { LIST_PATHS } from 'constants/queryParams'
import { URL } from 'constants/navigation'

import { useAdminBrandCustomers, useQuery, useNavigation } from 'hooks'

import { withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import GlanceTile from 'components/GlanceTile'
import DataTable from 'components/DataTable'
import FilterBar from 'components/FilterBar'

import BrandCustomerItem from './BrandCustomerItem'

import styles from './BrandCustomerListStyles'

const customerListColumns = [
  { title: '' },
  {
    title: 'Name',
    sortKeys: [LIST_PATHS.USERS_FIRST_NAME, LIST_PATHS.USERS_LAST_NAME]
  },
  { title: 'Email', sortKeys: [LIST_PATHS.USERS_EMAIL] },
  {
    title: 'Total Orders'
  },
  { title: 'Last Order', sortKeys: [LIST_PATHS.BRAND_CUSTOMERS_LAST_ORDER_AT] },
  { title: 'CLV', sortKeys: [LIST_PATHS.BRAND_CUSTOMERS_LIFETIME_VALUE] }
]

const BrandCustomerList = ({ classes, brandId }) => {
  const {
    isLoadingBrandCustomers,
    brandCustomers,
    hasBrandCustomersNext,
    listBrandCustomers,
    listBrandCustomersNext,
    brandCustomersCount,
    getRepeatCustomersCount,
    getNewTodayCustomersCount
  } = useAdminBrandCustomers({})

  const [repeatCustomerCount, setRepeatCustomerCount] = useState(0)
  const [newTodayCount, setNewTodayCount] = useState(0)

  useEffect(() => {
    ;(async function getCountData() {
      setRepeatCustomerCount(await getRepeatCustomersCount(brandId))
      setNewTodayCount(await getNewTodayCustomersCount(brandId))
    })()
  }, [brandId])

  const { query, updateQuery } = useQuery(listBrandCustomers, {
    initFallback: {
      [LIST_PATHS.BRANDS_ID]: brandId
    }
  })

  const { handleClick: navigateToCustomer } = useNavigation({
    url: URL.ADMIN_BRAND_CUSTOMERS
  })

  const summarizers = (
    <>
      <GlanceTile
        minimal
        label="Total Customers"
        value={brandCustomersCount ?? 0}
      />
      <GlanceTile
        minimal
        label="Repeat Customers"
        value={repeatCustomerCount ?? 0}
      />
      <GlanceTile minimal label="New Today" value={newTodayCount ?? 0} />
    </>
  )

  return (
    <Box className={classes.root}>
      <FilterBar summarizers={summarizers} />
      <DataTable
        columns={customerListColumns}
        message={brandCustomers?.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingBrandCustomers}
        hasListNext={hasBrandCustomersNext}
        listNext={listBrandCustomersNext}
        query={query}
        updateQuery={updateQuery}
        useWindowScrollListener
      >
        {brandCustomers?.map(brandCustomer => (
          <BrandCustomerItem
            key={brandCustomer.id}
            brandCustomer={brandCustomer}
            onClick={navigateToCustomer}
          />
        ))}
      </DataTable>
    </Box>
  )
}

BrandCustomerList.propTypes = {
  classes: PropTypes.object.isRequired,
  brandId: PropTypes.string
}

export default withStyles(styles)(BrandCustomerList)
