import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import MuiTooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import * as propTypes from 'constants/propTypes'
import { TOOLTIP_VARIANT, TOOLTIP_PLACEMENT } from 'constants/enums'
import { capitalize } from 'utils/general'

import styles from './TooltipStyles'

const suffixClasses = {
  withDescr: 'WithDescr',
  withShadow: 'WithShadow',
  onTop: 'OnTop'
}

const Tooltip = ({
  classes,
  title,
  open,
  description,
  placement,
  elevated,
  variant,
  className,
  actions,
  children
}) => {
  if (!title) {
    return children
  }

  const isArrowOnTop =
    Boolean(description) &&
    Boolean(
      placement === TOOLTIP_PLACEMENT.right ||
        placement === TOOLTIP_PLACEMENT.left
    )

  const muiClasses = {
    tooltip: classNames({
      [classes[
        `tooltip${capitalize(variant)}${
          description ? suffixClasses.withDescr : ''
        }`
      ]]: true,
      [classes.elevated]: elevated,
      [className]: Boolean(className)
    }),
    arrow: classNames({
      [classes[
        `arrow${capitalize(variant)}${isArrowOnTop ? suffixClasses.onTop : ''}`
      ]]: true,
      [classes.arrowElevated]: elevated
    })
  }

  const tooltipTitle = description ? (
    <>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.description}>
        {description}
      </Typography>
      {actions && <Box className={classes.actionBox}>{actions}</Box>}
    </>
  ) : (
    title
  )

  return (
    <MuiTooltip
      classes={muiClasses}
      title={tooltipTitle}
      placement={placement}
      arrow
      open={open}
    >
      {children}
    </MuiTooltip>
  )
}

Tooltip.defaultProps = {
  placement: TOOLTIP_PLACEMENT.bottom,
  variant: TOOLTIP_VARIANT.dark
}

Tooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  elevated: PropTypes.bool,
  className: PropTypes.string,
  actions: PropTypes.node,
  variant: propTypes.tooltipVariants,
  placement: propTypes.tooltipPlacements,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
}

export default withStyles(styles)(Tooltip)
