import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'
import { RETURN_REASONS } from 'constants/general'

import { useAdminOrders } from 'hooks'

import ReturnItems from 'components/ReturnItems'
import Button from 'components/Button'
import KebabButton from 'components/KebabButton'

import styles from './ReturnProgressStyles'

const ReturnProgress = ({
  classes,
  order,
  onUpdate,
  freeReturn,
  orderReturn
}) => {
  const history = useHistory()

  const inProgress = !orderReturn.returned
  const { returned } = orderReturn

  const { cancelReturn, markAsReturned } = useAdminOrders({
    id: order?.id,
    returnId: orderReturn?.id
  })

  const handleCancelReturn = async () => {
    await cancelReturn(order?.id, orderReturn?.id)
    onUpdate && onUpdate()
  }

  const ReturnStatus = () => (
    <Box
      className={classNames({
        [classes.returnProgress]: true,
        [classes.inProgress]: inProgress,
        [classes.returned]: !inProgress
      })}
    >
      {inProgress ? (
        <>
          <ErrorIcon />
          <Typography variant="body1">
            Return in progress {orderReturn?.number}
          </Typography>
        </>
      ) : (
        <>
          <CheckCircleIcon />
          <Typography variant="body1">
            Returned {orderReturn?.number}
          </Typography>
        </>
      )}
    </Box>
  )

  const ReturnActions = () => {
    if (orderReturn.refund === null)
      return (
        <Button
          size={SIZE.medium}
          onClick={() =>
            history.push(
              `${URL.ADMIN_ORDERS}/${order?.id}/refund?return=${orderReturn.id}`
            )
          }
        >
          Refund
        </Button>
      )
    if (freeReturn)
      return (
        <Button
          size={SIZE.medium}
          onClick={async () => await markAsReturned()}
          disabled={returned}
        >
          Mark Returned
        </Button>
      )
    return null
  }

  return (
    <>
      <ReturnStatus />
      {orderReturn?.line_item_returns.map((lineItem, idx) =>
        lineItem.quantity > 0 ? (
          <ReturnItems
            key={lineItem.id}
            product={
              order?.line_items.filter(
                item => item.id === lineItem.line_item.id
              )[0]
            }
            showQuantity
            showSku
            showVariantTitle
            isOdd={idx % 2 !== 0}
            inProgress
            returnQuantity={lineItem.quantity}
          />
        ) : null
      )}
      <Typography variant="h5" className={classes.paddingBlock}>
        Reason for return
      </Typography>
      <Typography variant="body1">
        {RETURN_REASONS[orderReturn?.return_reason] || 'No reason specified'}
      </Typography>
      {orderReturn?.details && (
        <>
          <Typography variant="h5" className={classes.paddingBlock}>
            Additional Details
          </Typography>
          <Typography variant="body1">{orderReturn?.details}</Typography>
        </>
      )}
      <Box className={classes.divider} mt={2} />
      <Box display="flex" justifyContent="flex-end" mt={2} gridGap={8}>
        {(!orderReturn.returned || orderReturn.refund === null) && (
          <KebabButton>
            <MenuItem
              onClick={async () => await markAsReturned()}
              disabled={returned}
            >
              Mark as returned
            </MenuItem>
            <MenuItem onClick={() => handleCancelReturn()}>
              Cancel return
            </MenuItem>
          </KebabButton>
        )}
        <ReturnActions />
      </Box>
    </>
  )
}

ReturnProgress.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.object.isRequired,
  orderReturn: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  freeReturn: PropTypes.bool
}

export default withStyles(styles)(ReturnProgress)
