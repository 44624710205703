import React from 'react'

const LockedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.65227 5.65129C9.27227 6.12074 9.00437 7.07204 9.00005 9.12641C9.85769 9.03569 10.853 9 12 9C13.147 9 14.1423 9.03569 14.9999 9.12641C14.9956 7.07204 14.7277 6.12074 14.3477 5.65129C14.0271 5.25519 13.4461 5 12 5C10.5539 5 9.9729 5.25519 9.65227 5.65129ZM8.31375 11.2458C8.25526 11.2638 8.19443 11.2766 8.13179 11.2837C7.51295 11.4209 7.11581 11.6006 6.85 11.8C6.28073 12.227 6 12.989 6 15C6 17.011 6.28073 17.773 6.85 18.2C7.17009 18.4401 7.68062 18.6517 8.5394 18.7949C9.3953 18.9375 10.518 19 12 19C13.482 19 14.6047 18.9375 15.4606 18.7949C16.3194 18.6517 16.8299 18.4401 17.15 18.2C17.7193 17.773 18 17.011 18 15C18 12.989 17.7193 12.227 17.15 11.8C16.8842 11.6006 16.4871 11.4209 15.8682 11.2837C15.8056 11.2766 15.7447 11.2638 15.6862 11.2458C15.6138 11.2317 15.5387 11.2182 15.4606 11.2051C14.6047 11.0625 13.482 11 12 11C10.518 11 9.3953 11.0625 8.5394 11.2051C8.46135 11.2182 8.38617 11.2317 8.31375 11.2458ZM7 9.177C7 9.29368 7.00075 9.408 7.00205 9.51987C6.49433 9.68615 6.04156 9.90633 5.65 10.2C4.21927 11.273 4 13.011 4 15C4 16.989 4.21927 18.727 5.65 19.8C6.32991 20.3099 7.19438 20.5983 8.2106 20.7676C9.2297 20.9375 10.482 21 12 21C13.518 21 14.7703 20.9375 15.7894 20.7676C16.8056 20.5983 17.6701 20.3099 18.35 19.8C19.7807 18.727 20 16.989 20 15C20 13.011 19.7807 11.273 18.35 10.2C17.9584 9.90633 17.5057 9.68616 16.998 9.51987C16.9993 9.40833 17 9.29407 17 9.177C17 7.08937 16.7692 5.46392 15.9023 4.39296C14.9729 3.24481 13.5539 3 12 3C10.4461 3 9.0271 3.24481 8.09773 4.39296C7.23083 5.46392 7 7.08937 7 9.177ZM13 14C13 13.4477 12.5523 13 12 13C11.4477 13 11 13.4477 11 14V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V14Z"
      fill="black"
    />
  </svg>
)

export default LockedIcon
