import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import * as propTypes from 'constants/propTypes'

import styles from './GoogleMapMarkerStyles'

const GoogleMapMarker = ({
  classes,
  className,
  src,
  alt,
  size,
  onClick,
  variant,
  srcSvg
}) => {
  const sizeStyle = size
    ? {
        width: size[0],
        height: size[1]
      }
    : null
  if (srcSvg)
    return (
      <div
        className={classNames({
          [className]: Boolean(className),
          [classes.clickable]: Boolean(onClick)
        })}
        onClick={onClick}
      >
        {srcSvg}
      </div>
    )
  return src ? (
    <img
      src={src}
      alt={alt}
      style={sizeStyle}
      className={classNames({
        [className]: Boolean(className),
        [classes.centered]: true,
        [classes.clickable]: Boolean(onClick)
      })}
      onClick={onClick}
    />
  ) : (
    <div
      style={sizeStyle}
      className={classNames({
        [className]: Boolean(className),
        [classes.centered]: true,
        [classes.dot]: true,
        [classes.clickable]: Boolean(onClick),
        [classes[variant]]: true
      })}
      onClick={onClick}
    />
  )
}

GoogleMapMarker.defaultProps = {
  alt: 'Map Marker'
}

GoogleMapMarker.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  size: propTypes.numberPair,
  variant: propTypes.mapMarkerVariants,
  onClick: PropTypes.func,
  srcSvg: PropTypes.node
}

export default withStyles(styles)(GoogleMapMarker)
