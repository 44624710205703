import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import MuiLink from '@material-ui/core/Link'

const Link = ({ children, to, dataTest }) => (
  <MuiLink component={RouterLink} to={to} data-test={dataTest}>
    {children}
  </MuiLink>
)

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string
}

export default Link
