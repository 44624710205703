import React from 'react'
import PropTypes from 'prop-types'

const GrabIcon = ({ fill, size, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.25 20H17.25V16H13.25V20ZM7.25 20H11.25V16H7.25V20ZM13.25 14H17.25V10H13.25V14ZM7.25 14H11.25V10H7.25V14ZM13.25 8H17.25V4H13.25V8ZM7.25 8H11.25V4H7.25V8Z"
      fill={fill}
    />
  </svg>
)

GrabIcon.defaultProps = {
  size: 24,
  fill: 'currentColor'
}

GrabIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
}

export default GrabIcon
