import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

import OrderStatus from 'components/OrderStatus'
import PaymentStatus from 'components/PaymentStatus'
import ReturnStatus from 'components/ReturnStatus'

const StatusTags = ({ order }) => {
  const [showStatus, setShowStatus] = useState(true)
  const orderStatusRef = useRef(null)
  const paymentStatusRef = useRef(null)

  useEffect(() => {
    setShowStatus(
      orderStatusRef.current?.innerText !== paymentStatusRef.current?.innerText
    )
  }, [orderStatusRef, paymentStatusRef])

  return (
    <>
      {order?.return_status && <ReturnStatus order={order} filledBackground />}
      <Box
        ref={orderStatusRef}
        ml={order?.return_status ? 1 : 0}
        mr={showStatus ? 1 : 0}
      >
        <OrderStatus order={order} filledBackground />
      </Box>
      {showStatus && (
        <Box ref={paymentStatusRef}>
          <PaymentStatus order={order} filledBackground />
        </Box>
      )}
    </>
  )
}

StatusTags.propTypes = {
  order: PropTypes.object.isRequired
}

export default StatusTags
