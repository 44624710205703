import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { uniq } from 'lodash'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import { useAdminDiscountCodes } from 'hooks'
import Drawer from 'components/Drawer'
import Button from 'components/Button'
import AutocompleteInputBox from 'components/AutocompleteInputBox'
import TileLabel from 'components/TileLabel'

import styles from './AddAccessCodesDrawerStyles'

const WAIT_THEN_BLUR_MS = 500

const AddAccessCodesDrawer = ({
  classes,
  open,
  onClose,
  onSubmit,
  preexistingCodes
}) => {
  const [searchString, setSearchString] = useState('')
  const [selectedCodes, setSelectedCodes] = useState([])
  const [allCodes, setAllCodes] = useState([])

  const {
    isLoadingDiscountCodes,
    discountCodes,
    fetchDiscountCodes
  } = useAdminDiscountCodes()

  useEffect(() => {
    fetchDiscountCodes()
  }, [])

  useEffect(() => {
    fetchDiscountCodes({ search: searchString })
  }, [searchString])

  useEffect(() => {
    if (discountCodes && preexistingCodes) {
      setAllCodes(uniq((discountCodes ?? []).concat(preexistingCodes ?? [])))
    }
  }, [discountCodes, preexistingCodes, setAllCodes])

  const handleClose = () => {
    setSelectedCodes([])
    onClose && onClose()
  }

  const handleSearchChange = v => {
    setSearchString(v.toUpperCase())
  }

  const handleSubmit = () => {
    handleClose()
    onSubmit(selectedCodes)
  }

  const handleAddCode = val => {
    if (
      searchString &&
      !selectedCodes.includes(searchString) &&
      !preexistingCodes.includes(searchString)
    ) {
      setSelectedCodes(selectedCodes.concat([searchString]))
    }
    setSearchString('')
    setTimeout(() => document.activeElement.blur(), WAIT_THEN_BLUR_MS)
  }

  const handleRemoveCode = val => {
    setSelectedCodes(selectedCodes.filter(code => code !== val))
  }

  const drawerActions = (
    <Button
      fullWidth
      disabled={selectedCodes.length === 0}
      onClick={handleSubmit}
    >
      Add Codes
    </Button>
  )

  const addCodeButton = (
    <Button onClick={handleAddCode} className={classes.addButton}>
      Add
    </Button>
  )

  return (
    <Drawer
      title="Add Access Codes"
      open={open}
      onClose={handleClose}
      actions={drawerActions}
    >
      <Box className={classes.container}>
        <Box mb={2}>
          <Typography variant="h6">Search and Add Access Codes</Typography>
        </Box>
        <AutocompleteInputBox
          placeholder="Add Access Code"
          value={searchString}
          loading={isLoadingDiscountCodes}
          options={allCodes?.map(c => c?.code || c) || []}
          highlightedLabel="added"
          highlightedOptions={preexistingCodes}
          onChange={handleSearchChange}
          endAdornment={addCodeButton}
        />
        <Box mt={4} className={classes.selectedCodes}>
          {selectedCodes.map(code => (
            <TileLabel
              key={code}
              label={code}
              endAdornment={
                <IconButton edge="end" onClick={() => handleRemoveCode(code)}>
                  <RemoveCircleIcon />
                </IconButton>
              }
            />
          ))}
        </Box>
      </Box>
    </Drawer>
  )
}

AddAccessCodesDrawer.defaultProps = {
  preexistingCodes: []
}

AddAccessCodesDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  preexistingCodes: PropTypes.arrayOf(PropTypes.string)
}

export default withStyles(styles)(AddAccessCodesDrawer)
