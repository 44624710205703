const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',

    '& > *': {
      margin: 0,
      height: 'fit-content',
      width: 'fit-content'
    },
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  modeButton: {
    width: 200
  },
  numberInput: {
    minWidth: 100,
    width: 100
  },
  windowTitle: {
    fontWeight: 600
  },
  unserviced: {
    border: `2px solid ${theme.palette.grays.dark}`,
    height: 8,
    width: 8,
    borderRadius: '100%'
  },
  serviced: {
    backgroundColor: theme.palette.blues.fast,
    height: 8,
    width: 8,
    borderRadius: '100%'
  }
})

export default jssStyles
