import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import { useAdminHistory } from 'hooks'
import { inventoryHistoryEndpoint } from 'api/endpoints'
import { URL } from 'constants/navigation'
import { isNumber } from 'utils/general.js'

import Header from 'components/Header'
import Layout from 'components/Layout'
import ProductHistoryTable from 'components/ProductHistoryTable'

import { useLocationsContext } from 'context'

import styles from './InventoryHistoryStyles'

const InventoryHistory = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const { locationId: location_id } = useLocationsContext()
  const {
    history,
    hasHistory,
    hasHistoryNext,
    fetchHistoryNext,
    isFetchingHistory
  } = useAdminHistory({
    endpoint: inventoryHistoryEndpoint,
    id,
    params: { location_id },
    fetchingEnabled: isNumber(location_id)
  })

  return (
    <Layout>
      {id && (
        <Box>
          <Box className={classes.headerBox}>
            <Header
              breadcrumbs={[
                { title: 'Inventory', link: URL.ADMIN_LICENSE_PLATES }
              ]}
              title={`History for Variant ${id}`}
              actions={<></>}
            />
          </Box>
        </Box>
      )}

      <ProductHistoryTable
        hasHistory={hasHistory}
        hasHistoryNext={hasHistoryNext}
        listHistoryNext={fetchHistoryNext}
        isLoadingHistory={isFetchingHistory}
        history={history}
      />
    </Layout>
  )
}

InventoryHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(InventoryHistory)
