import React, { useEffect } from 'react'
import { Route } from 'react-router'
import { Switch, Redirect } from 'react-router-dom'

import { URL } from 'constants/navigation'

import AdminAccessoryOptions from 'containers/admin/AccessoryOptions'
import AdminAnalytics from 'containers/admin/Analytics'
import AdminBackOfHouse from 'containers/admin/BackOfHouse'
import AdminBadges from 'containers/admin/Badges'
import AdminBrandCustomerDetails from 'containers/admin/BrandCustomerDetails'
import AdminBrandList from 'containers/admin/BrandList'
import AdminBrandShow from 'containers/admin/BrandShow'
import AdminCategories from 'containers/admin/Categories'
import AdminCollectionDetails from 'containers/admin/CollectionDetails'
import AdminCollectionGroupDetails from 'containers/admin/CollectionGroupDetails'
import AdminCollectionTabs from 'containers/admin/CollectionTabs.jsx'
import AdminColorList from 'containers/admin/ColorList'
import AdminDistributionCenterForm from 'containers/admin/DistributionCenterForm'
import AdminGMV from 'containers/admin/GMV'
import AdminHoursOfOperation from 'containers/admin/HoursOfOperation'
import AdminInternalUserList from 'containers/admin/InternalUserList'
import AdminInternalUserForm from 'containers/admin/InternalUserForm'
import AdminNotificationCenter from 'containers/admin/NotificationCenter'
import AdminDeliveredOrderList from 'containers/admin/DeliveredOrderList'
import AdminOrderHistory from 'containers/admin/OrderHistory'
import AdminOrderList from 'containers/admin/OrderList'
import AdminOrderRefund from 'containers/admin/OrderRefund'
import AdminOrderReturn from 'containers/admin/OrderReturn'
import AdminOrderShow from 'containers/admin/OrderShow'
import AdminPlateList from 'containers/admin/PlateList'
import AdminInventoryHistory from 'containers/admin/InventoryHistory'
import AdminProductCreate from 'containers/admin/ProductCreate'
import AdminProductHistory from 'containers/admin/ProductHistory'
import AdminProductList from 'containers/admin/ProductList'
import AdminProductShow from 'containers/admin/ProductShow'
import AdminProductVariantCreate from 'containers/admin/ProductVariantCreate'
import AdminProductVariantHistory from 'containers/admin/ProductVariantHistory'
import AdminProductVariantShow from 'containers/admin/ProductVariantShow'
import AdminPromoCodeHistory from 'containers/admin/PromoCodeHistory'
import AdminPromoCodesForm from 'containers/admin/PromoCodeForm'
import AdminPromoCodesList from 'containers/admin/PromoCodesList'
import AdminPromotionForm from 'containers/admin/PromotionForm'
import AdminPromotionsList from 'containers/admin/PromotionsList'
import AdminPurchaseOrderDetails from 'containers/admin/PurchaseOrderDetails'
import AdminPurchaseOrderHistory from 'containers/admin/PurchaseOrderHistory'
import AdminPurchaseOrders from 'containers/admin/PurchaseOrders'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import AdminServiceAreas from 'containers/admin/ServiceAreas'
import AdminSizes from 'containers/admin/Sizes'
import AdminStatusCodes from 'containers/admin/StatusCodes'
import AdminStorefront from 'containers/admin/Storefront'
import AdminStorefrontList from 'containers/admin/StorefrontList'
import AdminSupplierForm from 'containers/admin/SupplierForm'
import AdminSuppliersList from 'containers/admin/SuppliersList'
import AdminTransferOrderDetails from 'containers/admin/TransferOrderDetails'
import AdminTransferOrderHistory from 'containers/admin/TransferOrderHistory'
import AdminTransferOrders from 'containers/admin/TransferOrders'
import AdminUserDetails from 'containers/admin/UserDetails'
import AdminUserEdit from 'containers/admin/UserEdit'
import AdminUserList from 'containers/admin/UserList'
import ForgotPassword from 'containers/auth/ForgotPassword'
import Login from 'containers/auth/Login'
import ResetPassword from 'containers/auth/ResetPassword'

import { useAuth } from 'hooks'
import { canManageInternalUsers } from 'utils/policies'

const Logout = () => {
  const { logoutUser } = useAuth()
  useEffect(() => {
    logoutUser()
  }, [])

  return null
}

const Routes = () => (
  <Switch>
    <Redirect path="/" to={URL.ADMIN_ORDERS} exact />
    <Route path={URL.LOGOUT} component={Logout} />
    <Route path={URL.LOGIN} component={Login} />
    <Route path={URL.FORGOT_PASSWORD} component={ForgotPassword} exact />
    <Route
      path={`${URL.RESET_PASSWORD}/:token`}
      component={ResetPassword}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_ANALYTICS}
      component={AdminAnalytics}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_DELIVERED_ORDERS}
      component={AdminDeliveredOrderList}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_ORDERS}
      component={AdminOrderList}
      exact
    />
    <AuthenticatedRoute
      path={`${URL.ADMIN_ORDERS}/:id`}
      component={AdminOrderShow}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_ORDERS}/:id/return`}
      component={AdminOrderReturn}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_ORDERS}/:id/refund`}
      component={AdminOrderRefund}
      exact
    />
    <AuthenticatedRoute
      path={`${URL.ADMIN_ORDERS}/:id/history`}
      component={AdminOrderHistory}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_PRODUCTS}
      component={AdminProductList}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_PRODUCTS}/new`}
      component={AdminProductCreate}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_PRODUCTS}/:id`}
      component={AdminProductShow}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_PRODUCTS}/:id/history`}
      component={AdminProductHistory}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_PRODUCTS}/:id/variant/:variantId`}
      component={AdminProductVariantShow}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_PRODUCTS}/:id/create_variant`}
      component={AdminProductVariantCreate}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_PRODUCTS}/:id/variant/:variantId/history`}
      component={AdminProductVariantHistory}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_LICENSE_PLATES}
      component={AdminPlateList}
      exact
    />

    <AuthenticatedRoute
      path={`/admin/inventory/:id/history`}
      component={AdminInventoryHistory}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_USERS}
      component={AdminUserList}
      exact
    />
    <AuthenticatedRoute
      path={`${URL.ADMIN_USERS}/:id`}
      component={AdminUserDetails}
      exact
    />
    <AuthenticatedRoute
      path={`${URL.ADMIN_USERS_EDIT}/:id`}
      component={AdminUserEdit}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_BOH}
      component={AdminBackOfHouse}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_AREAS}
      component={AdminServiceAreas}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_PURCHASE_ORDERS}
      component={AdminPurchaseOrders}
      exact
    />
    <AuthenticatedRoute
      path={`${URL.ADMIN_PURCHASE_ORDERS}/:id/history`}
      component={AdminPurchaseOrderHistory}
      exact
    />
    <AuthenticatedRoute
      path={`${URL.ADMIN_PURCHASE_ORDERS}/:id`}
      component={AdminPurchaseOrderDetails}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_TRANSFER_ORDERS}
      component={AdminTransferOrders}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_TRANSFER_ORDERS}/:id/history`}
      component={AdminTransferOrderHistory}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_TRANSFER_ORDERS}/new`}
      component={AdminTransferOrderDetails}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_TRANSFER_ORDERS}/:id`}
      component={AdminTransferOrderDetails}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_COLORS}
      component={AdminColorList}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_BRAND_CUSTOMERS}/:id`}
      component={AdminBrandCustomerDetails}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_COLLECTIONS}
      component={AdminCollectionTabs}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_COLLECTIONS}/:id`}
      component={AdminCollectionDetails}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_COLLECTION_GROUPS}/:id`}
      component={AdminCollectionGroupDetails}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_BRANDS}
      component={AdminBrandList}
      exact
    />
    <AuthenticatedRoute
      path={`${URL.ADMIN_BRANDS}/:id`}
      component={AdminBrandShow}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_HOURS_OF_OPERATION}
      component={AdminHoursOfOperation}
      exact
    />

    <AuthenticatedRoute path={URL.ADMIN_GMV} component={AdminGMV} exact />

    <AuthenticatedRoute
      path={URL.ADMIN_SUPPLIERS}
      component={AdminSuppliersList}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_SUPPLIERS}/:supplierId/distribution_centers/new`}
      component={AdminDistributionCenterForm}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_SUPPLIERS}/:supplierId/distribution_centers/:distributionCenterId`}
      component={AdminDistributionCenterForm}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_SUPPLIERS}/:id`}
      component={AdminSupplierForm}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_SUPPLIERS}/new`}
      component={AdminSupplierForm}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_PROMO_CODES}
      component={AdminPromoCodesList}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_PROMO_CODES}/new`}
      component={AdminPromoCodesForm}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_PROMO_CODES}/:id`}
      component={AdminPromoCodesForm}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_PROMOTIONS}
      component={AdminPromotionsList}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_PROMOTIONS_NEW}
      component={AdminPromotionForm}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_PROMOTIONS}/:id`}
      component={AdminPromotionForm}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_PROMO_CODES_HISTORY}/:id`}
      component={AdminPromoCodeHistory}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_STOREFRONT}
      component={AdminStorefrontList}
      exact
    />
    <AuthenticatedRoute
      path={`${URL.ADMIN_STOREFRONT}/:id`}
      component={AdminStorefront}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_STATUS_CODES}
      component={AdminStatusCodes}
      exact
    />

    <AuthenticatedRoute path={URL.ADMIN_BADGES} component={AdminBadges} exact />

    <AuthenticatedRoute path={URL.ADMIN_SIZES} component={AdminSizes} exact />

    <AuthenticatedRoute
      path={URL.ADMIN_ACCESSORY_OPTIONS}
      component={AdminAccessoryOptions}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_CATEGORIES}
      component={AdminCategories}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_NOTIFICATION_CENTER}
      component={AdminNotificationCenter}
      exact
    />

    <AuthenticatedRoute
      path={URL.ADMIN_INTERNAL_USERS}
      component={AdminInternalUserList}
      policy={canManageInternalUsers}
      exact
    />

    <AuthenticatedRoute
      path={`${URL.ADMIN_INTERNAL_USERS}/:id`}
      component={AdminInternalUserForm}
      policy={canManageInternalUsers}
      exact
    />
    <Route component={Login} />
  </Switch>
)

export default Routes
