import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import { FixedSizeList as VirtualList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

import { useLocationsContext } from 'context'
import { zipcodeLookupByQuery, dollarsToCents } from 'utils/general'
import { SIZE } from 'constants/enums'

import Dialog from 'components/Dialog'
import Checkbox from 'components/Checkbox'
import TextField from 'components/TextField'

import styles from './ZipCodeModalStyles'

const TIP_INPUT_WIDTH = 100
const DEBOUNCE_QUERY_MS = 500
const VIRTUAL_LIST_ITEM_SIZE_PX = 50
const TEST_ID = 'zipCodes'

const ZipCodeModal = ({ classes, open, onClose, onSubmit }) => {
  const [queryResults, setQueryResults] = useState([])
  const [zipcodeQuery, setZipcodeQuery] = useState('')
  const [selectedZipIds, setSelectedZipIds] = useState({})
  const [tips, setTips] = useState({})

  const { location, locationId } = useLocationsContext()

  const fetchQueryResults = query => {
    const opts = zipcodeLookupByQuery(query)
    const options = Object.keys(opts).map(key => opts[key])
    setQueryResults(options)
  }

  useEffect(() => {
    debounce(() => fetchQueryResults(zipcodeQuery), DEBOUNCE_QUERY_MS)()
  }, [zipcodeQuery])

  const handleZipcodeQueryChange = e => {
    setZipcodeQuery(e.target.value)
  }

  const handleZipSelectChange = (codeId, value) => {
    const combineZipCodes = { ...selectedZipIds, [codeId]: value }

    setSelectedZipIds(
      Object.keys(combineZipCodes)
        .filter(zipId => combineZipCodes[zipId])
        .reduce((acc, curr) => ({ [curr]: true, ...acc }), {})
    )
  }

  const handleZipTipChange = (codeId, value) => {
    setTips({ ...tips, [codeId]: value ? `${parseFloat(value)}` : '' })
  }

  const handleClose = () => {
    setQueryResults([])
    setSelectedZipIds({})
    setZipcodeQuery('')
    onClose && onClose()
  }

  const handleZipCodesSubmit = () => {
    handleClose()
    onSubmit({
      location_id: locationId,
      zipcodes: Object.keys(selectedZipIds)
        .filter(codeId => selectedZipIds[codeId])
        .map(zip => ({
          zip,
          tip_cents: tips[zip] ? dollarsToCents(tips[zip]) : null
        }))
    })
  }

  return (
    <Dialog
      title="Add Zip Code"
      open={open}
      onClose={handleClose}
      onConfirm={handleZipCodesSubmit}
      dataTest={`${TEST_ID}Modal`}
      subtitle={`Adding For ${location?.name || 'Unknown'}`}
    >
      <Box mt={1} display="flex" flexDirection="column" height="100%">
        <TextField
          fullWidth
          value={zipcodeQuery}
          placeholder="Enter a zipcode or city"
          onChange={handleZipcodeQueryChange}
          dataTest="zipcode-name-or-city"
        />
        <List
          className={
            !isEmpty(queryResults) ? classes.queryList : classes.queryListEmpty
          }
        >
          {queryResults && (
            <AutoSizer defaultHeight={200}>
              {({ height, width }) => (
                <VirtualList
                  width={width}
                  height={height}
                  itemSize={VIRTUAL_LIST_ITEM_SIZE_PX}
                  itemCount={queryResults.length}
                  className={classes.list}
                >
                  {({ index, style }) => (
                    <ListItem
                      className={classes.queryListItem}
                      key={index}
                      style={style}
                    >
                      <Checkbox
                        checked={selectedZipIds[queryResults[index]?.zip]}
                        onChange={
                          value =>
                            handleZipSelectChange(
                              queryResults[index]?.zip,
                              value
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                        dataTest={`zipcode-check-${queryResults[index]?.zip}`}
                      />
                      <Typography variant="body2">
                        {queryResults[index]?.zip}
                      </Typography>
                      <Box ml={1} mr={1}>
                        <TextField
                          placeholder="Tip"
                          size={SIZE.small}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            style: {
                              minWidth: TIP_INPUT_WIDTH,
                              width: TIP_INPUT_WIDTH
                            },
                            onBlur: e =>
                              handleZipTipChange(
                                queryResults[index]?.zip,
                                e.target.value
                              ),
                            defaultValue: tips[queryResults[index]?.zip]
                          }}
                        />
                      </Box>
                      <Box flex={1} />
                      <Typography variant="body2">
                        {queryResults[index]?.city}
                      </Typography>
                    </ListItem>
                  )}
                </VirtualList>
              )}
            </AutoSizer>
          )}
        </List>
      </Box>
    </Dialog>
  )
}

ZipCodeModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default withStyles(styles)(ZipCodeModal)
