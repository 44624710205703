import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { MdMoneyOff, MdOutlineDeliveryDining, MdPeople } from 'react-icons/md'

import { useAuth } from 'hooks'
import { URL } from 'constants/navigation'
import { isSwitchLocationsEnabled } from 'utils/user'
import { canManageInternalUsers } from 'utils/policies'

import Header from 'components/Header'
import Layout from 'components/Layout'

import CollectionsIcon from 'icons/CollectionsIcon'
import ProductsIcon from 'icons/ProductsIcon'
import BadgeIcon from 'icons/BadgeIcon'
import ServiceAreaIcon from 'icons/ServiceAreaIcon'
import HoursIcon from 'icons/HoursIcon'
import PromoCodesIcon from 'icons/PromoCodesIcon'
import StatusCodesIcon from 'icons/StatusCodesIcon'
import TaxesIcon from 'icons/TaxesIcon'
import BrandsIcon from 'icons/BrandsIcon'

import styles from './BackOfHouseStyles'

const BackOfHouse = ({ classes }) => {
  const history = useHistory()
  const { currentUser } = useAuth()

  const displayItem = item => {
    switch (item.path) {
      case URL.ADMIN_PROMO_CODES:
        return isSwitchLocationsEnabled(currentUser) && item
      default:
        return item
    }
  }

  const sections = useMemo(
    () => [
      {
        name: 'Operations',
        active: isSwitchLocationsEnabled(currentUser),
        items: [
          {
            name: 'Service Areas',
            description: 'Manage Darkstores, and ZIP codes.',
            path: URL.ADMIN_AREAS,
            icon: ServiceAreaIcon,
            filled: false,
            active: true
          },
          {
            name: 'Hours Of Operation',
            description: 'Manage delivery windows, holidays, and order limits.',
            path: URL.ADMIN_HOURS_OF_OPERATION,
            icon: HoursIcon,
            filled: false,
            active: true
          },
          {
            name: 'Delivered Orders',
            description: 'Verify Delivery Photo Quality',
            path: URL.ADMIN_DELIVERED_ORDERS,
            icon: MdOutlineDeliveryDining,
            filled: true,
            active: true
          }
        ]
      },
      {
        name: 'Finance',
        active: true,
        items: [
          {
            name: 'Taxes',
            description: 'Manage the Tax Codes that apply to products.',
            path: 'taxes',
            icon: TaxesIcon,
            filled: true,
            active: false
          },
          {
            name: 'GMV',
            description: 'Manage the GMV',
            path: 'gmv',
            icon: CollectionsIcon,
            filled: true,
            active: true
          }
        ].map(displayItem)
      },
      {
        name: 'Options Management',
        active: isSwitchLocationsEnabled(currentUser),
        items: [
          {
            name: 'Categories',
            description: 'Manage all parent categories and subcategories.',
            path: URL.ADMIN_CATEGORIES,
            icon: CollectionsIcon,
            filled: true,
            active: true
          },
          {
            name: 'Accessory Options',
            description:
              'Manage options that don’t fit into colorways or sizes.',
            path: URL.ADMIN_ACCESSORY_OPTIONS,
            icon: ProductsIcon,
            filled: true,
            active: true
          },
          {
            name: 'Colorways',
            description: 'Manage and create product colorways.',
            path: URL.ADMIN_COLORS,
            icon: ProductsIcon,
            filled: true,
            active: true
          },
          {
            name: 'Sizing',
            description: 'Manage and create product sizes.',
            path: URL.ADMIN_SIZES,
            icon: BadgeIcon,
            filled: true,
            active: true
          },
          {
            name: 'Status Codes',
            description: 'Manage available product status codes.',
            path: URL.ADMIN_STATUS_CODES,
            icon: StatusCodesIcon,
            filled: true,
            active: true
          },
          {
            name: 'Badges',
            description: 'Manage all FastAF product badges.',
            path: URL.ADMIN_BADGES,
            icon: BadgeIcon,
            filled: true,
            active: true
          }
        ]
      },
      {
        name: 'Inventory Management',
        active: isSwitchLocationsEnabled(currentUser),
        items: [
          {
            name: 'Purchase Orders',
            description: 'Create and manage vendor purchase orders.',
            path: URL.ADMIN_PURCHASE_ORDERS,
            icon: PromoCodesIcon,
            filled: true,
            active: true
          },
          {
            name: 'Transfer Orders',
            description: 'Create and manage darkstore transfer orders.',
            path: URL.ADMIN_TRANSFER_ORDERS,
            icon: PromoCodesIcon,
            filled: true,
            active: true
          },
          {
            name: 'Suppliers',
            description:
              'Manage our product suppliers and distribution centers',
            path: URL.ADMIN_SUPPLIERS,
            icon: BrandsIcon,
            filled: true,
            active: true
          }
        ]
      },
      {
        name: 'Marketing',
        active: isSwitchLocationsEnabled(currentUser),
        items: [
          {
            name: 'Notification Center',
            description: 'Send in-app notifications to FastAF users.',
            path: URL.ADMIN_NOTIFICATION_CENTER,
            icon: BadgeIcon,
            filled: true,
            active: true
          },
          {
            name: 'Discount Codes',
            description: 'Manage and update promo codes and discounts.',
            path: URL.ADMIN_PROMO_CODES,
            icon: PromoCodesIcon,
            filled: true,
            active: true
          },
          {
            name: 'Promotions',
            description: 'Create BOGO Offers on Collections and Products',
            path: URL.ADMIN_PROMOTIONS,
            icon: MdMoneyOff,
            filled: true,
            active: true
          }
        ]
      },
      {
        name: 'Development Requests',
        active: true,
        items: [
          {
            name: 'Bulk Import',
            description: 'Request bulk import jobs from the Development Team',
            url: 'https://forms.gle/nDVwe2iPTHbdg4gd6',
            icon: MdMoneyOff,
            filled: true,
            active: true
          }
        ]
      },
      {
        name: 'Administration',
        active: canManageInternalUsers(currentUser),
        items: [
          {
            name: 'Manage Internal Users',
            description: 'Add and modify internal FASTAF users.',
            path: URL.ADMIN_INTERNAL_USERS,
            icon: MdPeople,
            filled: true,
            active: true
          }
        ]
      }
    ],
    [currentUser]
  )

  const SectionCard = ({ item }) => (
    <ButtonBase
      className={classes.buttonBase}
      onClick={() =>
        item.path
          ? history.push(item.path)
          : item.url
          ? window.open(item.url, '_blank')
          : console.log('item.path or item.url are required')
      }
    >
      <Box>
        <Box className={classes.iconContainer}>
          <item.icon className={item.filled ? classes.filled : ''} />
        </Box>
      </Box>
      <Box className={classes.descriptionContainer} flexGrow={1}>
        <Typography className={classes.name}>{item?.name}</Typography>
        <Typography className={classes.description}>
          {item?.description}
        </Typography>
      </Box>
      <Box>
        <ArrowForwardIosIcon className={classes.forwardArrow} />
      </Box>
    </ButtonBase>
  )

  SectionCard.propTypes = {
    item: PropTypes.object
  }

  return (
    <Layout>
      <Header title="Back Of House" />
      <Box pb={8}>
        {sections.map(
          section =>
            section.active && (
              <Box pt={5} key={section.name}>
                <Box pb={2}>
                  <Typography variant="body2">{section.name}</Typography>
                </Box>
                <Grid container spacing={2}>
                  {section.items.map(
                    item =>
                      item?.active && (
                        <Grid item key={item.path}>
                          <SectionCard item={item} />
                        </Grid>
                      )
                  )}
                </Grid>
              </Box>
            )
        )}
      </Box>
    </Layout>
  )
}

BackOfHouse.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object
}

export default withStyles(styles)(BackOfHouse)
