import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { SIZE, COLOR } from 'constants/enums'

import Button from 'components/Button'
import TextField from 'components/TextField'

import styles from './NotesContainerStyles'

const DATA_TEST_ID = 'notes'

const NotesContainer = ({
  classes,
  order,
  onSubmit,
  heading,
  attr,
  isVisibleToCustomers
}) => {
  const [text, setText] = useState(null)
  const [temporaryText, setTemporaryText] = useState(null)
  const [isInputActive, setIsInputActive] = useState(false)

  useEffect(() => {
    setText(order?.[attr])
  }, [order])

  useEffect(() => {
    setTemporaryText(text)
  }, [text])

  const handleTextChange = e => setTemporaryText(e.target.value)

  const handleCancel = () => {
    setTemporaryText(text)
    setIsInputActive(false)
  }

  const handleSubmit = () => {
    setIsInputActive(false)
    setText(temporaryText)
    onSubmit && onSubmit(temporaryText)
  }

  return (
    <Box>
      <Box className={classes.header}>
        <Box>
          <Typography variant="h5">{heading}</Typography>
          {isVisibleToCustomers && (
            <Typography variant="caption">
              Visible to customers and delivery drivers.
            </Typography>
          )}
        </Box>
        <Box flex={1} />
        {isInputActive ? (
          <>
            <Button
              color={COLOR.default}
              variant="outlined"
              size={SIZE.small}
              onClick={handleCancel}
              dataTest={`${DATA_TEST_ID}-cancel`}
            >
              Cancel
            </Button>
            <Box width={12} />
            <Button
              color={COLOR.primary}
              variant="outlined"
              size={SIZE.small}
              onClick={handleSubmit}
              dataTest={`${DATA_TEST_ID}-submit`}
            >
              Submit
            </Button>
          </>
        ) : (
          <Button
            color={COLOR.default}
            variant="outlined"
            size={SIZE.small}
            onClick={() => setIsInputActive(true)}
            dataTest={`${DATA_TEST_ID}-add`}
          >
            {text ? 'Edit' : '+ Add'}
          </Button>
        )}
      </Box>

      {!isInputActive && text && (
        <Typography variant="body1" component="div" className={classes.text}>
          {text}
        </Typography>
      )}

      {isInputActive && (
        <TextField
          value={temporaryText}
          classes={{ input: classes.input }}
          className={classes.textField}
          multiline
          autoFocus
          onChange={handleTextChange}
          rowsMax={10}
          dataTest={`${DATA_TEST_ID}-input`}
        />
      )}
    </Box>
  )
}

NotesContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object,
  onSubmit: PropTypes.func
}

export default withStyles(styles)(NotesContainer)
