const jssStyles = theme => ({
  printerCard: {
    backgroundColor: theme.palette.grays.med,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    cursor: 'pointer',
    borderRadius: 4
  },
  printerForm: {
    position: 'relative',
    backgroundColor: theme.palette.grays.med,
    padding: theme.spacing(4, 2, 2),
    margin: theme.spacing(1),
    borderRadius: 4
  },
  add: {
    cursor: 'pointer',
    backgroundColor: theme.palette.blues.fast,
    padding: theme.spacing(1),
    borderRadius: 4,
    color: 'white'
  },
  delButton: {
    position: 'absolute',
    top: 8,
    right: 8
  },
  testButton: {
    position: 'absolute',
    top: 8,
    right: 58
  }
})

export default jssStyles
