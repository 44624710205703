import * as yup from 'yup'
import moment from 'moment-timezone'

import { TERM_POSSIBILITIES } from './general'
import { LIST_PATHS } from './queryParams'
import { STATUS_DOT_VARIANTS } from './enums'
import { TRANSFER_ORDER_STATE_TYPES } from './transferOrders'

const {
  BRANDS_NAME,
  PURCHASE_ORDERS_ID,
  PURCHASE_ORDERS_ORDER_DATE,
  PURCHASE_ORDERS_TERMS,
  PURCHASE_ORDERS_ETA,
  PRODUCTS_VARIANTS_TITLE,
  VARIANTS_PRICE,
  VARIANTS_PO_QUANTITY
} = LIST_PATHS

export const PURCHASE_ORDER_STATE_TYPES = {
  draft: 'draft',
  open: 'is_open',
  submitted: 'submitted',
  receivingInProgress: 'receiving_in_progress',
  partiallyReceived: 'partially_received',
  fullyReceived: 'fully_received',
  overReceived: 'over_received',
  closed: 'closed'
}

export const PURCHASE_ORDER_STATE_LABELS = [
  {
    value: PURCHASE_ORDER_STATE_TYPES.draft,
    label: 'Draft',
    indicator: STATUS_DOT_VARIANTS.gray
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.open,
    label: 'Open',
    indicator: STATUS_DOT_VARIANTS.blue
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.submitted,
    label: 'Submitted',
    indicator: STATUS_DOT_VARIANTS.green
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.receivingInProgress,
    label: 'Receiving in Progress',
    indicator: STATUS_DOT_VARIANTS.blue
  },
  {
    value: TRANSFER_ORDER_STATE_TYPES.receiving,
    label: 'Receiving in Progress',
    indicator: STATUS_DOT_VARIANTS.blue
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.partiallyReceived,
    label: 'Complete: Partially Received',
    indicator: STATUS_DOT_VARIANTS.blue
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.fullyReceived,
    label: 'Complete: Fully Received',
    indicator: STATUS_DOT_VARIANTS.green
  },
  {
    value: TRANSFER_ORDER_STATE_TYPES.receiving_complete,
    label: 'Complete: Fully Received',
    indicator: STATUS_DOT_VARIANTS.green
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.overReceived,
    label: 'Complete: Over Received',
    indicator: STATUS_DOT_VARIANTS.orange
  },
  {
    value: PURCHASE_ORDER_STATE_TYPES.closed,
    label: 'Closed',
    indicator: STATUS_DOT_VARIANTS.gray
  }
]

export const TERM_POSSIBILITIES_ALL = [
  {
    label: 'All',
    value: ''
  },
  ...TERM_POSSIBILITIES
]

export const PO_LIST_COLUMNS = [
  {
    title: 'PO #',
    sortKeys: [PURCHASE_ORDERS_ID]
  },
  {
    title: 'Supplier',
    sortKeys: [BRANDS_NAME]
  },
  { title: 'Location' },
  {
    title: 'Terms',
    sortKeys: [PURCHASE_ORDERS_TERMS]
  },
  { title: 'Order Date', sortKeys: [PURCHASE_ORDERS_ORDER_DATE] },
  {
    title: 'Estimated Arrival',
    sortKeys: [PURCHASE_ORDERS_ETA]
  },
  {
    title: 'Status'
  }
]

export const STATUS_FILTER_ITEMS = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  },
  {
    label: 'All',
    value: ''
  }
]

export const PO_FIELDS = {
  brand: 'brand_id',
  supplier: 'supplier',
  distributionCenter: 'distributionCenter',
  location: 'location_id',
  paymentTerms: 'terms',
  orderDate: 'order_date',
  estimatedArrival: 'estimated_arrival_at',
  memo: 'memo',
  shipDate: 'ship_at',
  cancelDate: 'cancel_at',
  notesForSupplier: 'notes',
  purpose: 'purpose'
}

export const PO_SUBMIT_EDITABLE_SCHEMAS = {
  [PO_FIELDS.estimatedArrival]: yup
    .string()
    .ensure()
    .required('Estimated Arrival is a required field'),
  [PO_FIELDS.shipDate]: yup
    .string()
    .ensure()
    .required('Start Ship Date is a required field'),
  [PO_FIELDS.cancelDate]: yup
    .string()
    .ensure()
    .required('Cancel Date is a required field')
}

export const PO_SCHEMAS = {
  [PO_FIELDS.brand]: yup.object(),
  [PO_FIELDS.supplier]: yup.object().required('Supplier is a required field'),
  [PO_FIELDS.location]: yup.string().required(),
  [PO_FIELDS.paymentTerms]: yup
    .string()
    .required('Payment Terms is a required field'),
  [PO_FIELDS.purpose]: yup.string().required('Purpose is a required field'),
  [PO_FIELDS.orderDate]: yup
    .string()
    .ensure()
    .required('Order Date is a required field'),
  [PO_FIELDS.notesForSupplier]: yup.string(),
  [PO_FIELDS.memo]: yup.string(),
  ...PO_SUBMIT_EDITABLE_SCHEMAS
}

export const PO_FORM_DEFAULTS = {
  [PO_FIELDS.brand]: {},
  [PO_FIELDS.supplier]: {},
  [PO_FIELDS.location]: 1,
  [PO_FIELDS.paymentTerms]: '',
  [PO_FIELDS.purpose]: '',
  [PO_FIELDS.orderDate]: null,
  [PO_FIELDS.estimatedArrival]: null,
  [PO_FIELDS.shipDate]: null,
  [PO_FIELDS.cancelDate]: null,
  [PO_FIELDS.notesForSupplier]: '',
  [PO_FIELDS.memo]: ''
}

export const VARIANT_COLUMNS = [
  { title: 'ID' },
  {
    title: 'Vendor Style #'
  },
  { title: 'Product Name', sortKeys: [PRODUCTS_VARIANTS_TITLE] },
  { title: 'SKU' },
  { title: 'Color' },
  { title: 'Size' },
  { title: 'MSRP', sortKeys: [VARIANTS_PRICE] },
  { title: 'Cost' },
  { title: 'Ordered', sortKeys: [VARIANTS_PO_QUANTITY] },
  { title: 'Received' },
  { title: 'Damaged' },
  { title: '' }
]

export const PURPOSE_POSSIBILITIES = [
  {
    label: 'Replenishment',
    value: 'replenishment'
  },
  {
    label: 'New Item',
    value: 'new_item'
  },
  {
    label: 'New Store',
    value: 'new_store'
  },
  {
    label: 'Marketing',
    value: 'marketing'
  }
]

export const PURPOSE_POSSIBILITIES_ALL = [
  {
    label: 'All',
    value: ''
  },
  ...PURPOSE_POSSIBILITIES
]
