import axios from 'axios'
import { useInfiniteQuery } from 'react-query'

import { api } from 'api'
import { useApiCancel } from 'hooks'
import { getPerPageDefault } from 'utils/api'

/* Provides a React Query based interface to the FastAF paginated list
   endpoints for fetching pages and combining them into an array. */

/* Arguments:
 *
 * endpoint: a function that is invoked by useResourceListRQ applying args.
 * args: arguments for endpoints functions, like a resource ID, can be
   left empty or left out if not applicable to the 'list' endpoint
 * params: an object representing the query parameters, compatible
           with useQuery
 * options: Additional options for the useInfiniteQuery.  */

/* Example usage:
 * const useAdminPurchaseOrderList = (queryParams) => {
 *   const {
 *     isFetching: isFetchingPurchaseOrders,
 *     data: purchaseOrders,
 *     hasData: hasPurchaseOrders,
 *     hasNextPage: hasPurchaseOrdersNext,
 *     fetchNextPage: fetchPurchaseOrdersNext,
 *   } = useResourceListRQ({ endpoint: purchaseOrderListEndpoint, params: queryParams })
 *   ...
 * } */

const useResourceListRQ = ({
  endpoint,
  args = [],
  params = {},
  options = {},
  fetchingEnabled = true
}) => {
  const [listCancel, getListCancel] = useApiCancel()

  const url = endpoint(...args)
  const queryKey = [url, params]

  const {
    data: rawData,
    error,
    fetchNextPage,
    hasNextPage,
    fetchPreviousPage,
    isLoading,
    isFetching,
    isIdle,
    isError,
    refetch
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => {
      listCancel()
      return api.get(url, {
        params: { page: pageParam, per_page: getPerPageDefault(), ...params },
        cancelToken: new axios.CancelToken(getListCancel)
      })
    },
    {
      enabled: fetchingEnabled,
      getNextPageParam: lastPage => lastPage?.meta?.next_page,
      ...options
    }
  )

  /* We have to massage this a bit since our list endpoints don't put
     the array at top-level. */
  const data = rawData?.pages.map(x => x.data).flat()
  const hasData = Boolean(
    rawData?.pages.length > 0 && rawData.pages[0].data.length > 0
  )

  const meta =
    rawData?.pages && rawData.pages.length > 0 && rawData.pages[0].meta

  return {
    data,
    hasData,
    isError,
    error,
    isFetching,
    isLoading,
    isIdle,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    meta,
    count: meta?.total_count,
    refetch
  }
}

export default useResourceListRQ
