import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { uniqBy } from 'lodash'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import { PRODUCT_RESULTS_POPOVER_TYPES } from 'constants/enums'
import { useAdminVariantsRQ } from 'hooks'

import Button from 'components/Button'
import Drawer from 'components/Drawer'
import ProductSummary from 'components/ProductSummary'
import ProductResultsPopover from 'components/ProductResultsPopover'

import styles from './DistributionCenterVariantsDrawerStyles'

const DistributionCenterVariantsDrawer = ({
  classes,
  open,
  onClose,
  onSubmit,
  centerVariants
}) => {
  const [searchString, setSearchString] = useState('')
  const [selectedVariants, setSelectedVariants] = useState([])

  const { variants } = useAdminVariantsRQ({
    params: { search: searchString },
    fetchingEnabled: open
  })

  const handleClose = () => {
    setSelectedVariants([])
    setSearchString('')
    onClose && onClose()
  }

  const handleSearchChange = e => setSearchString(e.target.value)

  const handleSubmit = () => {
    handleClose()
    onSubmit(selectedVariants.map(v => ({ ...v, id: v.id })))
  }

  const handleAddVariant = item =>
    setSelectedVariants(uniqBy([...selectedVariants, item], v => v.id))

  const handleRemoveVariant = currVariant =>
    setSelectedVariants(
      selectedVariants.filter(variant => variant.id !== currVariant.id)
    )

  const drawerActions = (
    <Button
      fullWidth
      disabled={selectedVariants.length === 0}
      onClick={handleSubmit}
      dataTest="add-to-center-button"
    >
      Add to Center
    </Button>
  )

  return (
    <Drawer
      title="Add Variants"
      open={open}
      onClose={handleClose}
      actions={drawerActions}
    >
      <Box className={classes.container}>
        <Box mb={2}>
          <Typography variant="h6">Search Variants</Typography>
        </Box>

        <ProductResultsPopover
          options={variants}
          selectedOptions={selectedVariants}
          onTextChange={handleSearchChange}
          onAddItem={handleAddVariant}
          onRemoveItem={handleRemoveVariant}
          activeProducts={centerVariants.map(({ variant_id }) => ({
            id: variant_id
          }))}
          type={PRODUCT_RESULTS_POPOVER_TYPES.distribution_center}
        />

        <Box>
          {selectedVariants.map((variant, i) => (
            <Box key={variant.id} className={classes.selectedItem}>
              <Box className={classes.productSummary}>
                <ProductSummary product={variant} />
              </Box>
              <Box className={classes.itemActions}>
                <IconButton
                  edge="end"
                  onClick={() => handleRemoveVariant(variant)}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  )
}

DistributionCenterVariantsDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  centerVariants: PropTypes.arrayOf(PropTypes.object)
}

export default withStyles(styles)(DistributionCenterVariantsDrawer)
