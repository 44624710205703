import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import { useAdminProducts, useAdminVariants } from 'hooks'

import { URL } from 'constants/navigation'

import Header from 'components/Header'
import Layout from 'components/Layout'
import ProductHistoryTable from 'components/ProductHistoryTable'

import styles from './ProductVariantHistoryStyles'

const ProductVariantHistory = ({ classes, match }) => {
  const { id, variantId } = match.params

  const { product, readProduct } = useAdminProducts({})

  const {
    historyData,
    fetchHistory,
    hasHistory,
    hasHistoryNext,
    fetchHistoryNext,
    isLoadingHistory
  } = useAdminVariants({ id: variantId })

  useEffect(() => {
    ;(async () => {
      ;(id || id === 0) && readProduct(id)

      fetchHistory(variantId)
    })()
  }, [])

  const variant = useMemo(
    () => product?.variants.find(v => Number(v.id) === Number(variantId)),
    [product, variantId]
  )

  const listHistoryNext = () => fetchHistoryNext(variantId)

  return (
    <Layout>
      {variant?.id && (
        <Box>
          <Box className={classes.headerBox}>
            <Header
              breadcrumbs={[
                { title: 'Products', link: URL.ADMIN_PRODUCTS },
                {
                  title: product.title,
                  link: `${URL.ADMIN_PRODUCTS}/${product?.id}`
                },
                {
                  title: variant?.title,
                  link: `${URL.ADMIN_PRODUCTS}/${product?.id}/variant/${variant?.id}`
                }
              ]}
              title="History"
              actions={<></>}
            />
          </Box>
        </Box>
      )}
      <ProductHistoryTable
        history={historyData}
        hasHistory={hasHistory}
        hasHistoryNext={hasHistoryNext}
        listHistoryNext={listHistoryNext}
        isLoadingHistory={isLoadingHistory}
      />
    </Layout>
  )
}

ProductVariantHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(ProductVariantHistory)
