import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Box from '@material-ui/core/Box'
import MuiIconButton from '@material-ui/core/IconButton'

import { ICON_BUTTON_VARIANT } from 'constants/enums'
import * as propTypes from 'constants/propTypes'

import styles from './IconButtonStyles'

const capitalizeFirst = str => str.charAt(0).toUpperCase() + str.slice(1)
const getClassName = (classes, prefix, type) =>
  classes[`${prefix}${capitalizeFirst(type)}`]

const IconButton = ({
  classes,
  className,
  onClick,
  children,
  circle,
  size,
  variant,
  ...rest
}) => (
  <Box width={size} height={size} lineHeight="1px" className={className}>
    <MuiIconButton
      classes={{
        root: classNames({
          [classes.muiIconButton]: true,
          [getClassName(classes, 'variant', variant)]: true,
          [classes.circle]: circle
        })
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </MuiIconButton>
  </Box>
)

IconButton.defaultProps = {
  variant: ICON_BUTTON_VARIANT.light,
  size: 32
}

IconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClick: PropTypes.func,
  circle: PropTypes.bool,
  size: PropTypes.number,
  variant: propTypes.iconButtonVariants
}

export default withStyles(styles)(IconButton)
