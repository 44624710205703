import React from 'react'
import { Helmet } from 'react-helmet'
import { DEFAULT_TITLE, SITE_NAME } from 'constants/titles'

const TitleElement = ({ title, useSiteName = true }) => (
  <Helmet
    defaultTitle={DEFAULT_TITLE}
    titleTemplate={useSiteName ? `${SITE_NAME} - %s` : '%s'}
  >
    <title>{title}</title>
  </Helmet>
)

export default TitleElement
