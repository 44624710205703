import React from 'react'
import PropTypes from 'prop-types'

import { PURCHASE_ORDER_STATE_LABELS } from 'constants/purchaseOrders'

import StatusDot from 'components/StatusDot'

const PurchaseOrderStatus = ({ className, status }) => {
  if (!status) return null
  const { label, indicator } = [
    ...PURCHASE_ORDER_STATE_LABELS,
    { value: status, label: 'Unknown', indicator: 'gray' }
  ].find(t => t.value === status)

  return <StatusDot className={className} text={label} variant={indicator} />
}

PurchaseOrderStatus.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string
}

export default PurchaseOrderStatus
