import React from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@material-ui/core'

import DatePicker from './DatePicker'

const DatePickerField = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Box>
      <Controller
        name={name}
        render={methods => (
          <DatePicker
            name={name}
            error={errors[name]}
            helperText={errors[name] && errors[name].message}
            {...rest}
            {...methods}
          />
        )}
      />
    </Box>
  )
}

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired
}

export default DatePickerField
