import { LIST_PATHS } from 'constants/queryParams'
import { useResourceRQ, useResourceListRQ } from 'hooks'
import { sizesEndpoint, sizesActiveEndpoint } from 'api/endpoints'

const API_RESOURCE_NAME = 'size'
const ACTIVE_STATUS_PATH = `${LIST_PATHS.SIZES_STATUS}=true`

const useAdminSizes = params => {
  const {
    isFetching: isLoadingSizes,
    data: sizes,
    hasData: hasSizes,
    hasNextPage: hasSizesNext,
    count: sizesCount,
    fetchNextPage: listSizesNext
  } = useResourceListRQ({ endpoint: sizesEndpoint, params })

  const { count: activeSizesCount } = useResourceListRQ({
    endpoint: sizesActiveEndpoint,
    args: [ACTIVE_STATUS_PATH]
  })

  const { create: createSize, update: updateSize } = useResourceRQ({
    endpoint: sizesEndpoint,
    name: API_RESOURCE_NAME,
    activeLoaders: true,
    showServerError: true,
    onSuccess: {
      updateMsg: () => 'Size successfully updated',
      createMsg: () => 'Size successfully created'
    },
    dependents: [() => sizesEndpoint()]
  })

  return {
    isLoadingSizes,
    sizes,
    hasSizes,
    hasSizesNext,
    sizesCount,
    listSizesNext,
    createSize,
    updateSize,
    activeSizesCount
  }
}

export default useAdminSizes
