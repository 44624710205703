const jssStyles = theme => ({
  buttonBase: {
    border: `1px solid ${theme.palette.colorsAF.semiGray}`,
    width: 268,
    height: 83,
    borderRadius: 4,
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
  iconContainer: {
    height: 40,
    width: 40,
    background: theme.palette.colorsAF.ultraLightGray,
    border: `1px solid ${theme.palette.colorsAF.semiGray}`,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      height: 16,
      width: 16
    }
  },
  descriptionContainer: {
    padding: `0 ${theme.spacing(2)}px`,
    textAlign: 'left',
    height: '100%'
  },
  name: {
    color: theme.palette.colorsAF.darkGray,
    fontWeight: 500
  },
  description: {
    fontSize: 10,
    lineHeight: '12px',
    color: theme.palette.colorsAF.lightGray
  },
  forwardArrow: {
    height: theme.spacing(2),
    color: theme.palette.grays.dark
  }
})
export default jssStyles
