import { useResourceList, useResource } from 'hooks'
import { api } from 'api'

const API_BRAND_CUSTOMERS = '/api/v1/admin/brand_customers'

const useAdminBrandCustomers = ({ id = 0 }) => {
  const {
    isLoading: isLoadingBrandCustomers,
    data: brandCustomers,
    hasData: hasBrandCustomers,
    hasNext: hasBrandCustomersNext,
    list: listBrandCustomers,
    listNext: listBrandCustomersNext,
    count: brandCustomersCount
  } = useResourceList({ url: API_BRAND_CUSTOMERS })

  const {
    isLoading: isLoadingBrandCustomerSales,
    data: brandCustomerSales,
    hasData: hasBrandCustomerSales,
    hasNext: hasBrandCustomersSalesNext,
    list: listBrandCustomerSales,
    listNext: listBrandCustomerSalesNext,
    count: brandCustomerSalesCount
  } = useResourceList({ url: `${API_BRAND_CUSTOMERS}/${id}/brand_sales` })

  const {
    data: brandCustomer,
    read: readBrandCustomer,
    isLoading: isBrandCustomerLoading,
    hasData: hasBrandCustomer
  } = useResource({
    url: API_BRAND_CUSTOMERS,
    name: 'brandCustomerById'
  })

  const getRepeatCustomersCount = async id => {
    try {
      const res = await api.get(
        `${API_BRAND_CUSTOMERS}/repeat_customers_count?brands.id=${id}`
      )
      return res.data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const getNewTodayCustomersCount = async id => {
    try {
      const res = await api.get(
        `${API_BRAND_CUSTOMERS}/new_today_count?brands.id=${id}`
      )
      return res.data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  return {
    // brand customers
    isLoadingBrandCustomers,
    brandCustomers,
    hasBrandCustomers,
    hasBrandCustomersNext,
    listBrandCustomers,
    listBrandCustomersNext,
    brandCustomersCount,
    brandCustomer,
    readBrandCustomer,
    isBrandCustomerLoading,
    hasBrandCustomer,
    getRepeatCustomersCount,
    getNewTodayCustomersCount,

    // brand customer sales
    isLoadingBrandCustomerSales,
    brandCustomerSales,
    hasBrandCustomerSales,
    hasBrandCustomersSalesNext,
    listBrandCustomerSales,
    listBrandCustomerSalesNext,
    brandCustomerSalesCount
  }
}

export default useAdminBrandCustomers
