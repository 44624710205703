import React, { useState, useEffect } from 'react'

import { URL as SITE_URLS } from 'constants/navigation'
import {
  DEFAULT_TITLE_SUFFIX,
  PAGE_TITLES,
  DEFAULT_TITLE
} from 'constants/titles'

/**
 *  hooks to update the site title
 * setTitle will update the title with what you supply, or will use the current page
 * @return {{setPageTitle: setPageTitle, title: string}}
 */
export default function useTitle() {
  const [title, setTitle] = useState(DEFAULT_TITLE)

  /**
   * intended to be called on react router location update updates;
   * sets the page title based on the route.  Can be overridden with setTitle hook
   * @param {string} page - location.pathname of the new react route
   */
  const updateTitleByPage = page => {
    // cover "nested" pages by looking for URIs that begin with the same URI prefix to prevent pages with partial matches from having no title
    const pageTitle =
      PAGE_TITLES[page] ||
      PAGE_TITLES[Object.values(SITE_URLS).find(uri => page.startsWith(uri))]

    const result = pageTitle || DEFAULT_TITLE_SUFFIX

    setTitle(result)
  }

  /**
   *
   * @param page - the location from react router used to look up a default title
   * @param title - a user supplied value for the page's title; overrides default & lookups
   */
  const setPageTitle = ({ page, title: pageTitle }) => {
    if (pageTitle) {
      setTitle(pageTitle)
    } else if (page) {
      updateTitleByPage(page)
    } else {
      setTitle(DEFAULT_TITLE)
    }
  }

  return { title, setPageTitle }
}
