import { useResourceListRQ } from 'hooks'
import { distributionCenterVariantsListEndpoint } from 'api/endpoints'

const useAdminDistributionCenterVariantsList = ({
  supplierId,
  distributionCenterId,
  options,
  params
}) => {
  const {
    isFetching: isFetchingDistributionCenterVariants,
    data: distributionCenterVariants,
    hasData: hasDistributionCenterVariants,
    hasNextPage: hasDistributionCenterVariantsNext,
    fetchNextPage: fetchDistributionCenterVariantsNext,
    meta
  } = useResourceListRQ({
    endpoint: distributionCenterVariantsListEndpoint,
    args: [supplierId, distributionCenterId],
    ...options,
    params
  })

  return {
    isFetchingDistributionCenterVariants,
    distributionCenterVariants: distributionCenterVariants || [],
    hasDistributionCenterVariants,
    hasDistributionCenterVariantsNext,
    fetchDistributionCenterVariantsNext,
    summary: meta?.summary
  }
}

export default useAdminDistributionCenterVariantsList
