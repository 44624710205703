import { useResourceList, useResource } from 'hooks'
import { api } from 'api'

const API_ADMIN_LOCATIONS = '/api/v1/admin/locations'
const API_RESOURCE_NAME = 'blocked_windows'

const useAdminBlockedWindows = ({ locationId }) => {
  const url = `${API_ADMIN_LOCATIONS}/${locationId}/${API_RESOURCE_NAME}`

  const {
    isLoading: isLoadingBlockedWindows,
    data: blockedWindows,
    hasData: hasBlockedWindows,
    list: fetchBlockedWindows
  } = useResourceList({
    url
  })

  const {
    create: createBlockedWindow,
    destroy: destroyBlockedWindow
  } = useResource({
    url,
    name: API_RESOURCE_NAME
  })

  const bulkUpdateBlockedWindows = async data => {
    try {
      const response = await api.post(`${url}/bulk_update`, data)
      return response.data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  return {
    isLoadingBlockedWindows,
    blockedWindows,
    hasBlockedWindows,
    fetchBlockedWindows,
    createBlockedWindow,
    destroyBlockedWindow,
    bulkUpdateBlockedWindows
  }
}

export default useAdminBlockedWindows
