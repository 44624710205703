import React from 'react'

import { DELIVERY_STATUS_ICON_NAMES } from 'constants/general'

function OrderStepperSvgIcon({ name, color }) {
  switch (name) {
    case DELIVERY_STATUS_ICON_NAMES.door: {
      return (
        <>
          <path
            d="M25.9071 21.8235V13.5882C25.9071 12.6786 25.1697 11.9412 24.26 11.9412H16.0247C15.1151 11.9412 14.3777 12.6786 14.3777 13.5882V28.4118C14.3777 29.3214 15.1151 30.0588 16.0247 30.0588H24.26C25.1697 30.0588 25.9071 29.3214 25.9071 28.4118V28.4118"
            stroke={color}
            strokeWidth="1.23529"
          />
          <circle cx="17.26" cy="21" r="0.823529" fill={color} />
          <path
            d="M24.0639 22.8388C24.0639 22.2777 24.5188 21.8234 25.0798 21.8234H28.0677C28.6287 21.8234 29.0836 22.2777 29.0836 22.8388C29.0836 23.6281 29.0836 24.6628 29.0836 25.1175C29.0836 25.7696 29.5172 27.6141 29.6635 28.2178C29.6875 28.317 29.6121 28.4117 29.5101 28.4117H23.6374C23.5353 28.4117 23.46 28.317 23.484 28.2178C23.6303 27.6141 24.0639 25.7696 24.0639 25.1175C24.0639 24.6628 24.0639 23.6281 24.0639 22.8388Z"
            stroke={color}
            strokeWidth="0.634118"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.8661 25.8732V25.8732H25.5878L24.4247 27.5883H25.0989L25.2268 27.3909H25.8899L25.893 27.5883L26.5238 27.5883L26.4014 25.8732H25.8661ZM25.5132 26.9486L25.8743 26.3914L25.883 26.9486H25.5132Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.5932 27.5883H27.2397L27.3695 27.0957H28.0701L28.1964 26.6153H27.4936L27.5562 26.3745H28.4065L28.5424 25.8732L27.0376 25.8732L26.5932 27.5883Z"
            fill={color}
          />
          <rect
            x="25.3188"
            y="23.0784"
            width="2.5098"
            height="0.627451"
            rx="0.313725"
            fill={color}
          />
          <path
            d="M29.2404 22.7646H23.9071L24.3777 21.9803H28.7698L29.2404 22.7646Z"
            fill={color}
          />
        </>
      )
    }
    case DELIVERY_STATUS_ICON_NAMES.fastaf: {
      return (
        <>
          <path
            d="M14.4117 15.692C14.4117 14.0748 15.7227 12.7646 17.3398 12.7646L24.6601 12.7646C26.2772 12.7646 27.5882 14.0748 27.5882 15.692C27.5882 17.7255 27.5882 20.2678 27.5882 21.4117C27.5882 23.1055 28.7029 27.8647 29.0982 29.4993C29.1674 29.7852 28.9503 30.0588 28.6562 30.0588L13.3437 30.0588C13.0496 30.0588 12.8325 29.7852 12.9016 29.4993C13.297 27.8647 14.4117 23.1055 14.4117 21.4117C14.4117 20.2678 14.4117 17.7255 14.4117 15.692Z"
            stroke={color}
            strokeWidth="1.3564"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2326 26.9323C20.3341 26.9525 20.467 26.9788 20.6236 27.0382C20.8857 27.1376 21.0305 27.3864 20.9616 27.6787C20.872 28.0586 20.6157 28.288 19.9706 28.2622C19.6649 28.25 19.3154 28.1407 19.1622 28.013L19.3147 27.4268C19.3147 27.4268 19.6221 27.6707 19.9977 27.6973C20.316 27.7198 20.3241 27.6293 20.3267 27.5956C20.3287 27.569 20.2709 27.5266 19.9537 27.4514C19.404 27.3213 19.355 27.0103 19.3756 26.7867C19.3987 26.5363 19.5667 26.1346 20.3422 26.1685C20.6196 26.1806 20.8962 26.2755 21.0009 26.328L20.8517 26.9033C20.8517 26.9033 20.6334 26.7897 20.3786 26.7475C20.1628 26.7117 20.0103 26.7513 20.0116 26.8153C20.0131 26.8887 20.0933 26.9046 20.2326 26.9323ZM15.9558 28.1867L15.2236 28.1867L15.727 26.2442L17.4312 26.2443L17.2773 26.812L16.3143 26.812L16.2434 27.0847L17.0393 27.0847L16.8963 27.6287L16.1028 27.6287L15.9558 28.1867ZM18.0262 26.2443H18.3414V26.2442L18.9477 26.2442L19.0863 28.1867L18.3719 28.1867L18.3684 27.9631L17.6174 27.9631L17.4725 28.1867H16.7089L18.0262 26.2443ZM18.3506 26.8315L17.9417 27.4626H18.3605L18.3506 26.8315ZM23.7337 26.2443L23.4185 26.2443L22.1013 28.1867L22.8648 28.1867L23.0097 27.9631L23.7607 27.9631L23.7642 28.1867L24.4786 28.1867L24.3401 26.2442H23.7337V26.2443ZM23.3339 27.4626L23.7428 26.8315L23.7527 27.4626H23.3339ZM25.2896 28.1867L24.5574 28.1867L25.0607 26.2442L26.7649 26.2443L26.6111 26.812L25.648 26.812L25.5771 27.0847L26.3731 27.0847L26.23 27.6287L25.4366 27.6287L25.2896 28.1867ZM22.8024 26.812L22.9509 26.2442L21.1433 26.2442L20.9948 26.812H21.5443L21.1889 28.1867L21.9273 28.1867L22.2827 26.812L22.8024 26.812Z"
            fill={color}
          />
          <rect
            x="17.7061"
            y="16.0591"
            width="6.58824"
            height="1.64706"
            rx="0.823529"
            fill={color}
          />
          <path
            d="M28 15.2356H14L15.2353 13.1768H26.7647L28 15.2356Z"
            fill={color}
          />
        </>
      )
    }
    default: {
      return (
        <>
          <path
            d="M19.3529 16.6227V16.1455C19.3529 15.7565 19.6683 15.4412 20.0573 15.4412H22.0113C22.4003 15.4412 22.7157 15.7565 22.7157 16.1455V18.0087C22.7157 18.3977 22.4003 18.713 22.0113 18.713H20.0346"
            stroke={color}
            strokeWidth="0.863407"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.2353 24.0294C15.2353 22.9248 16.1307 22.0294 17.2353 22.0294H24.7647C25.8693 22.0294 26.7647 22.9248 26.7647 24.0294V29.4412H15.2353V24.0294Z"
            stroke={color}
            strokeWidth="1.23529"
          />
          <path
            d="M15.2353 24.5H26.7647"
            stroke={color}
            strokeWidth="1.23529"
          />
          <path
            d="M15.2353 26.9706H26.7647"
            stroke={color}
            strokeWidth="1.23529"
          />
          <path
            d="M11.9412 18.8035C11.9412 17.2205 12.7398 15.7428 14.0985 14.9306C16.3337 13.5945 19.6809 11.7353 21 11.7353C22.3191 11.7353 25.6663 13.5945 27.9015 14.9306C29.2602 15.7428 30.0588 17.2205 30.0588 18.8035V28.2059C30.0588 28.8881 29.5058 29.4412 28.8235 29.4412H13.1765C12.4942 29.4412 11.9412 28.8881 11.9412 28.2059V18.8035Z"
            stroke={color}
            strokeWidth="1.23529"
          />
        </>
      )
    }
  }
}

export default OrderStepperSvgIcon
