export default theme => ({
  root: {
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '10px!important'
    }
  },
  saveTag: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grays.light
  },
  autocompletePaper: {
    backgroundColor: theme.palette.background.default,
    margin: 0
  },
  autocompleteInputRoot: {
    backgroundColor: theme.palette.background.default
  }
})
