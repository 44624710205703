import { useContext } from 'react'

import { LocalStorageContext } from './LocalStorageContext'
import { UIContext } from './UIContext'
import { AuthContext } from './AuthContext'
import { LocationsContext } from './LocationsContext'
import { AppConfigContext } from './AppConfigContext'

export { LocalStorageContext }
export const useLocalStorageContext = () => useContext(LocalStorageContext)

export { UIContext }
export const useUIContext = () => useContext(UIContext)

export { AuthContext }
export const useAuthContext = () => useContext(AuthContext)

export { LocationsContext }
export const useLocationsContext = () => useContext(LocationsContext)

export { AppConfigContext }
export const useAppConfigContext = () => useContext(AppConfigContext)
