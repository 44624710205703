import { thinInput } from 'constants/styles'

const jssStyles = theme => ({
  thinInput: thinInput(theme),
  root: {
    border: `1px solid ${theme.palette.colorsAF.semiGray}`,
    width: '100%',
    padding: theme.spacing(0, 2, 3)
  },
  subheader: {
    margin: theme.spacing(4, 0, 4),
    display: 'flex',
    justifyContent: 'space-between'
  },
  addButton: {
    height: 34,
    marginRight: theme.spacing(2)
  },
  closeButton: {
    minWidth: 0,
    '& .MuiButton-endIcon': {
      margin: 0
    }
  },
  dialogSubtitle: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '12px'
  }
})
export default jssStyles
