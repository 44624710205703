import { fontFamily } from 'theme/typography'

export default {
  '@global': {
    '@font-face': fontFamily,
    html: { height: '100%' },
    body: { height: '100%' },
    '#root': {
      height: '100%',
      width: '100%',
      display: 'flex'
    }
  }
}
