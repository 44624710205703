import React, { useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

// Inspired by https://github.com/qiaolb/react-dragscroll
const DragScroll = ({ className, children, scale, ...props }) => {
  const containerRef = useRef(null)
  const lastClientX = useRef()
  const lastClientY = useRef()
  const scaleRef = useRef(scale)
  const dragging = useRef(false)

  const handleMouseUp = useCallback(() => {
    if (dragging.current) {
      dragging.current = false
    }
  })

  const handleMouseDown = useCallback(e => {
    if (!dragging.current) {
      dragging.current = true
      lastClientX.current = e.clientX
      lastClientY.current = e.clientY
    }
  })

  const handleMouseMove = useCallback(e => {
    if (dragging.current) {
      containerRef.current.scrollLeft -=
        (-lastClientX.current + (lastClientX.current = e.clientX)) /
        scaleRef.current
      containerRef.current.scrollTop -=
        (-lastClientY.current + (lastClientY.current = e.clientY)) /
        scaleRef.current
    }
  })

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      window.removeEventListener('mouseup', handleMouseUp)
      window.addEventListener('mousemove', handleMouseMove)
    }
  }, [handleMouseUp])

  useEffect(() => {
    scaleRef.current = scale
  }, [scale])

  return (
    <Box
      className={className}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      {...props}
    >
      {children}
    </Box>
  )
}

DragScroll.defaultProps = {
  scale: 1.0
}

DragScroll.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  scale: PropTypes.number
}

export default DragScroll
