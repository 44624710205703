import { grays, blues, warnings } from 'theme/colors'

export default {
  root: {
    minWidth: 130,
    background: grays.light,
    borderRadius: 5,
    '&.Mui-error': {
      color: warnings.error
    },
    // All the notchedOutline borderColors need to be set from here.
    // The first wasn't working from the normal overried, the rest need
    // the context of the root class.
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: grays.med
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: blues.fast
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: warnings.danger
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: blues.fast
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: grays.med
    },
    '&:hover.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: grays.med
    }
  },
  input: {
    lineHeight: '20px',
    padding: '14px 12px 14px'
  },
  inputMarginDense: {
    padding: '16px 12px 4px !important'
  }
}
