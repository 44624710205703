const jssStyles = theme => ({
  updatedText: {
    marginBottom: theme.spacing(2),
    color: theme.palette.grays.dark
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  noBorderRadius: {
    borderRadius: 0
  },
  checkBoxItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiFormControlLabel-root': {
      margin: 0
    }
  },
  editButton: {
    background: theme.palette.colorsAF.black,
    color: 'white',
    padding: theme.spacing(1, 2),
    marginLeft: theme.spacing(1),

    '&:hover': {
      background: theme.palette.colorsAF.mediumGray
    }
  },
  smallInput: {
    '& .MuiOutlinedInput-input': {
      padding: 12
    }
  },
  displayBox: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row'
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '14px'
    }
  },
  displayDescLabel: {
    fontSize: '10px'
  }
})
export default jssStyles
