import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { partition, isEmpty } from 'lodash'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { ALL_LOCATION } from 'constants/general'
import { MAP_MARKER_VARIANTS } from 'constants/enums'
import DarkstoreIcon from 'assets/darkstore_dot.png'
import { useLocationsContext } from 'context'
import { useAlerts } from 'hooks'

import GoogleMap from 'components/GoogleMap'
import GoogleMapMarker from 'components/GoogleMapMarker'

import styles from './ZipCodeMapStyles'

const LARGE_SIZE = [25, 25]
const SMALL_SIZE = [15, 15]
const USA_CENTER_LATITUDE = 39.8283
const USA_CENTER_LONGITUDE = -98.5795
const ZOOM_COUNTRY = 3
const ZOOM_CITY = 12

const ZipCodeMap = ({
  classes,
  zipcodes,
  location,
  setHighlightedId,
  highlightedId
}) => {
  const [zipcodesWithGeo, setZipcodesWithGeo] = useState(null)
  const { showAlertError } = useAlerts()
  const { locations } = useLocationsContext()
  const showAllLocationIds = location?.id === ALL_LOCATION.id

  // TODO: Simplify error handling when data is cleaned up
  useEffect(() => {
    const [available, missing] = partition(zipcodes, zip =>
      Boolean(zip?.geocode_info?.geometry?.location)
    )
    setZipcodesWithGeo(available)
    if (!isEmpty(missing)) {
      showAlertError(
        `Missing geocodes for ${missing
          .map(z => z.zip)
          .join(', ')}. Can't place on map.`
      )
    }
  }, [zipcodes])

  if (!location) {
    return (
      <Box className={classes.mapPlaceholder}>
        <Typography variant="h5">...</Typography>
      </Box>
    )
  }

  return (
    <GoogleMap
      lat={showAllLocationIds ? USA_CENTER_LATITUDE : location.latitude}
      lng={showAllLocationIds ? USA_CENTER_LONGITUDE : location.longitude}
      zoom={showAllLocationIds ? ZOOM_COUNTRY : ZOOM_CITY}
    >
      {showAllLocationIds ? (
        locations?.map(l => (
          <GoogleMapMarker
            key={l.id}
            src={DarkstoreIcon}
            size={SMALL_SIZE}
            lat={l.latitude}
            lng={l.longitude}
          />
        ))
      ) : (
        <GoogleMapMarker
          key={location.id}
          src={DarkstoreIcon}
          size={LARGE_SIZE}
          lat={location.latitude}
          lng={location.longitude}
        />
      )}
      {zipcodesWithGeo?.map(zip => (
        <GoogleMapMarker
          key={zip.zip}
          src={null}
          size={SMALL_SIZE}
          lat={zip?.geocode_info?.geometry?.location?.lat}
          lng={zip?.geocode_info?.geometry?.location?.lng}
          onClick={() =>
            setHighlightedId(highlightedId === zip.id ? null : zip.id)
          }
          className={
            highlightedId === zip.id
              ? classes.highlightedDot
              : classes.zipcodeDot
          }
          variant={
            zip.serviced ? MAP_MARKER_VARIANTS.green : MAP_MARKER_VARIANTS.red
          }
        />
      ))}
    </GoogleMap>
  )
}

ZipCodeMap.propTypes = {
  classes: PropTypes.object,
  zipcodes: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object,
  highlightedId: PropTypes.number,
  setHighlightedId: PropTypes.func
}

export default withStyles(styles)(ZipCodeMap)
