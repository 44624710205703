import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import Menu from '@material-ui/core/Menu'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import PublicIcon from '@material-ui/icons/Public'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import {
  MdFiberNew,
  MdOutlineCardGiftcard,
  MdAttachMoney
} from 'react-icons/md'
import { GiSpermWhale } from 'react-icons/gi'
import CryptoMerchIcon from 'icons/CryptoMerchIcon'
import CryptoPaymentIcon from 'icons/CryptoPaymentIcon'

import { useLocationsContext } from 'context'
import { useMenu, useNavigation, useAdminOrders } from 'hooks'

import { URL } from 'constants/navigation'
import { SIZE, DELIVERY_METHOD_KEYS } from 'constants/enums'
import { LIGHT_BLUE } from 'constants/styles'
import {
  DELIVERY_METHOD_OPTIONS,
  ORDER_WRITABLE_TRANSITIONS
} from 'constants/general'
import { WHALES } from 'constants/important_users.js'
import {
  formatAddress,
  formatCurrency,
  formatDateToLocalTimezone
} from 'utils/general'

import OrderStatus from 'components/OrderStatus'
import PaymentStatus from 'components/PaymentStatus'
import ReturnStatus from 'components/ReturnStatus'
import UserAvatar from 'components/UserAvatar'

import { colorsAF } from 'theme/colors'

import FlagIcon from 'icons/FlagIcon'
import Diamond from 'icons/DiamondIcon'

import styles from './OrderItemStyles'

const OrderItem = ({ classes, order, onClick }) => {
  const { updateOrder } = useAdminOrders({
    id: order?.id,
    fetchingEnabled: false
  })

  const onUpdateStatus = async status => {
    await updateOrder({ status }, order)
  }

  const onUpdateDeliveryMethod = async delivery_method => {
    await updateOrder({ delivery_method }, order)
  }

  const { getLocationAtId, showAllLocationIds } = useLocationsContext()

  const { open, anchorEl, handleToggleMenu, handleClose } = useMenu()

  const deliveryDate =
    order.delivery_starts_at &&
    formatDateToLocalTimezone(order.delivery_starts_at, order.timezone, 'L')

  const deliveryStartsAtText =
    order.delivery_starts_at &&
    formatDateToLocalTimezone(order.delivery_starts_at, order.timezone, 'h')

  const deliveryEndsAtText =
    order.delivery_ends_at &&
    formatDateToLocalTimezone(order.delivery_ends_at, order.timezone, 'ha')

  const createdAtDate =
    order.created_at &&
    formatDateToLocalTimezone(order.created_at, order.timezone, 'L')

  const createdAtTime =
    order.created_at &&
    formatDateToLocalTimezone(order.created_at, order.timezone, 'LT')

  const addressMap = formatAddress(order)

  const { handleClick: handleUserClick } = useNavigation({
    url: URL.ADMIN_USERS
  })

  return (
    <TableRow data-test="order-row" key={order?.id}>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Button
            color="primary"
            onClick={e => onClick(e, order)}
            onAuxClick={e => onClick(e, order, true)}
            data-test="order-item"
            className={classes.orderNumber}
          >
            {`#${order?.number}`}
          </Button>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="row"
            flexWrap="wrap"
          >
            {!isEmpty(order?.gift_detail) && (
              <MdOutlineCardGiftcard fontSize={26} />
            )}
            {order?.user_first_order && <MdFiberNew fontSize={26} />}
            {parseFloat(order?.total_price, 10) > 250 && (
              <MdAttachMoney fontSize={26} />
            )}
            {order?.user_is_vip && <Diamond fill={LIGHT_BLUE} fontSize={26} />}
            {WHALES.includes(order?.user_id) && (
              <GiSpermWhale color={LIGHT_BLUE} fontSize={26} />
            )}
            {Boolean(order?.promo_code_red_flags?.length) && (
              <FlagIcon fill={colorsAF.danger} fontSize={26} />
            )}
            {order?.crypto_merch && <CryptoMerchIcon />}
            {order?.crypto_used_as_payment && <CryptoPaymentIcon size={26} />}
            {order?.delivery_method === DELIVERY_METHOD_KEYS.global && (
              <PublicIcon style={{ color: 'green' }} />
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <IconButton
            size={SIZE.medium}
            onClick={e => handleUserClick(e, { id: order?.user_id })}
            onAuxClick={e => handleUserClick(e, { id: order?.user_id }, true)}
          >
            <UserAvatar
              size={SIZE.medium}
              className={classes.avatar}
              src={order?.user_image_url}
            />
          </IconButton>
          <Box ml={1}>
            <Typography variant="body1" data-test="order-user-name">
              {` ${order?.first_name} ${order?.last_name}`}
            </Typography>
            <Typography variant="body1" className={classes.underline}>
              {order?.email}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {formatCurrency(order.total_price)}
        </Typography>
        {order.discount_code && (
          <Typography variant="body1" className={classes.underline}>
            → {order.discount_code}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {addressMap[0]} {addressMap.length === 3 && addressMap[1]}
        </Typography>
        <Typography variant="body1" className={classes.underline}>
          {addressMap[addressMap.length === 3 ? 2 : 1]}
        </Typography>
        {order?.stripe_fingerprint && (
          <Typography variant="body1" className={classes.underlineLight}>
            Stripe Fingerprint: {order?.stripe_fingerprint}
          </Typography>
        )}
        {order?.device_id && (
          <Typography variant="body1" className={classes.underlineLight}>
            Device ID : {order?.device_id}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <PaymentStatus order={order} />
      </TableCell>
      <TableCell>
        <Typography variant="body1">{deliveryDate}</Typography>
        <Typography variant="body1" className={classes.underline}>
          {deliveryStartsAtText} - {deliveryEndsAtText}
        </Typography>
      </TableCell>
      {showAllLocationIds && (
        <TableCell>{getLocationAtId(order.location_id)?.name}</TableCell>
      )}
      <TableCell>
        <MuiSelect
          value={order?.delivery_method || 'Unset'}
          input={<Input disableUnderline />}
          data-test="order-item-select-delivery-method"
        >
          {DELIVERY_METHOD_OPTIONS.map(option => (
            <MenuItem
              key={option.label}
              value={option.key}
              onClick={e => onUpdateDeliveryMethod(option.key)}
              data-test={`order-item-select-delivery-method-${option.key}`}
              disabled={
                option.key !== DELIVERY_METHOD_KEYS.onfleet &&
                option.key !== DELIVERY_METHOD_KEYS.fastaf &&
                option.key !== DELIVERY_METHOD_KEYS.global
              }
            >
              {option.label}
            </MenuItem>
          ))}
          {!order?.delivery_method && <MenuItem value="Unset">Unset</MenuItem>}
        </MuiSelect>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{createdAtDate}</Typography>
        <Typography variant="body1" className={classes.underline}>
          {createdAtTime}
        </Typography>
      </TableCell>
      <TableCell>
        <ReturnStatus order={order} />
      </TableCell>
      <TableCell data-test="order-status">
        <OrderStatus order={order} />
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={e => handleToggleMenu(e)}
          data-test="order-status-button"
        >
          <MoreVertIcon />
          <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
            {ORDER_WRITABLE_TRANSITIONS.map((state, idx) => (
              <MenuItem
                key={idx}
                onClick={e => onUpdateStatus(state.value)}
                data-test={`order-item-menu-status-${state.value}`}
              >
                {state.label}
              </MenuItem>
            ))}
          </Menu>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

OrderItem.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  onClick: PropTypes.func
}

export default withStyles(styles)(OrderItem)
