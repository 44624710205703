const jssStyles = theme => ({
  gridActionBar: {
    backgroundColor: 'black',
    color: 'white',
    height: '64px',
    paddingLeft: 8,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    top: 0,
    zIndex: 100,

    '& .MuiButton-root': {
      '&:first-of-type': {
        marginLeft: theme.spacing(2)
      },
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 'none'
    }
  }
})

export default jssStyles
