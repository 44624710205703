import React, { useState, useCallback } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { uniqBy, isEmpty } from 'lodash'

import { FiTrash2 } from 'react-icons/fi'

import Box from '@material-ui/core/Box'
import CreateIcon from '@material-ui/icons/Create'
import RedoIcon from '@material-ui/icons/Redo'

import { ICON_BUTTON_VARIANT, DRAG_N_DROP_MODE } from 'constants/enums'
import { PRODUCT_SORT_OPTIONS, PRODUCT_SORT_KEYS } from 'constants/collections'
import { DRAGGABLE_PRODUCT_DIMS } from 'constants/styles'
import { useLocationsContext } from 'context'
import {
  useAlerts,
  useControlledFormController,
  useAdminLocationCollection
} from 'hooks'
import { useControlledForm } from 'components/ControlledForm'
import { SelectField } from 'components/Select'
import AddCollectionProductDrawer from 'components/AddCollectionProductDrawer'
import AutocompleteSelect from 'components/AutocompleteSelect'
import Button from 'components/Button'
import CheckIcon from 'icons/CheckIcon'
import Dialog from 'components/Dialog'
import DraggableProduct from 'components/DraggableProduct'
import DragNDropGrid from 'components/DragNDropGrid'
import IconButton from 'components/IconButton'
import SyncIcon from 'icons/SyncIcon'

import { FORM_FIELDS } from './CollectionFormFields'
import styles from './CollectionProductsStyles'

const CopyDialog = ({ open, onClose, onConfirm }) => {
  const [selectedLocations, setSelectedLocations] = useState([])
  const { locations, location } = useLocationsContext()

  const options = (locations ?? []).filter(loc => loc !== location)

  const handleConfirm = () => {
    onConfirm(selectedLocations)
    setSelectedLocations([])
  }

  return (
    <Dialog
      title="Copy products to"
      subtitle="Select locations to overwrite with the current locations' products"
      open={open}
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmText="Copy"
      confirmDisabled={selectedLocations.length === 0}
    >
      <Box height={200}>
        <AutocompleteSelect
          multiple
          placeholder="Select locations"
          options={options}
          value={selectedLocations}
          onChange={setSelectedLocations}
          getOptionLabel={loc => loc.display_name}
          getTagLabel={loc => loc.city}
          actions={[
            <Button
              variant="outlined"
              size="small"
              color="default"
              onClick={() => setSelectedLocations(options)}
            >
              All Locations
            </Button>,
            <Button
              variant="outlined"
              size="small"
              color="default"
              onClick={() => setSelectedLocations([])}
            >
              Clear
            </Button>
          ]}
        />
      </Box>
    </Dialog>
  )
}

CopyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

const CollectionProducts = ({
  classes,
  collection,
  disableAdd,
  disableRemove
}) => {
  const { showAlertError, showAlertSuccess } = useAlerts()
  const { locationId } = useLocationsContext()
  const {
    setBlockSave,
    defaults: { [FORM_FIELDS.PRODUCT_IDS]: savedProductIds },
    formContext: { watch, setValue }
  } = useControlledForm()

  const [selections, setSelections] = useState([])
  const selectionsMap = new Set(selections)

  const [dndMode, setDndMode] = useState(DRAG_N_DROP_MODE.ORDERING)
  const [addProductDrawerOpen, setAddProductDrawerOpen] = useState(false)
  const [copyDialogOpen, setCopyDialogOpen] = useState(false)
  const [
    showConfirmRemoveAllProductsModal,
    setShowConfirmRemoveAllProductsModal
  ] = useState(false)

  const {
    fetchLocationCollectionAlt,
    updateLocationCollectionAlt
  } = useAdminLocationCollection(undefined, undefined, undefined, {
    onSuccess: showAlertSuccess,
    onError: showAlertError
  })

  const onSortChange = async (sort, { setValue: setVal }) => {
    if (sort !== PRODUCT_SORT_KEYS.DEFAULT) {
      const {
        data: { products: replacementProducts }
      } = await fetchLocationCollectionAlt(collection.id, locationId, { sort })
      setVal(FORM_FIELDS.PRODUCTS, replacementProducts)
    }
  }

  const {
    field: { onChange: setProductIds }
  } = useControlledFormController({
    name: FORM_FIELDS.PRODUCT_IDS
  })

  const {
    field: { onChange: setFeaturedIds }
  } = useControlledFormController({
    name: FORM_FIELDS.FEATURED_PRODUCT_IDS
  })

  const {
    field: { onChange: setProducts, value: products }
  } = useControlledFormController({
    name: FORM_FIELDS.PRODUCTS,
    onChange: (ps, { setValue: setVal }) => {
      setVal(FORM_FIELDS.PRODUCT_SORT, PRODUCT_SORT_KEYS.DEFAULT)
      setProductIds(ps.map(p => p.id))
    }
  })

  const children = watch(FORM_FIELDS.CHILDREN)
  const featured = watch(FORM_FIELDS.FEATURED_PRODUCT_IDS)
  const featuredMap = new Set(featured)

  const isParent = collection?.is_parent
  const savedProductIdSet = new Set(savedProductIds)
  const localProductIdSet = new Set(products?.map(p => p.id))
  const hasAddRemoveProducts =
    products?.some(p => !savedProductIdSet.has(p.id)) ||
    savedProductIds?.some(pid => !localProductIdSet.has(pid))

  const handleFeaturedClick = id => {
    if (featuredMap.has(id)) {
      setFeaturedIds(featured.filter(fid => fid !== id))
    } else {
      setFeaturedIds([...featured, id])
    }
  }

  const handleSelectClick = id => {
    if (selectionsMap.has(id)) {
      setSelections(selections.filter(sid => sid !== id))
    } else {
      setSelections([...selections, id])
    }
  }

  const handleEditClick = () => {
    setDndMode(
      dndMode === DRAG_N_DROP_MODE.ORDERING
        ? DRAG_N_DROP_MODE.REMOVE
        : DRAG_N_DROP_MODE.ORDERING
    )
    if (dndMode === DRAG_N_DROP_MODE.SELECTING) {
      setProducts(products.filter(p => selectionsMap.has(p.id)))
      setSelections([])
      setBlockSave(false)
    }
  }

  const handleProductCollectionSubmit = productCollection =>
    setProducts(uniqBy(products.concat(productCollection), p => p.id))

  const handleSyncClick = async () => {
    setValue(FORM_FIELDS.PRODUCT_SORT, PRODUCT_SORT_KEYS.DEFAULT)
    let fromChildren = uniqBy(
      children.flatMap(c => c.products?.filter(p => p.featured) ?? []),
      c => c.id
    )
    if (fromChildren?.length === 0) {
      fromChildren = uniqBy(
        children.flatMap(c => (c.products ?? []).slice(0, 10) ?? []),
        c => c.id
      )
    }
    const fromChildrenIdSet = new Set(fromChildren.map(p => p.id))
    setSelections(
      products.map(p => p.id).filter(pid => fromChildrenIdSet.has(pid))
    )
    setProducts(fromChildren)
    setDndMode(DRAG_N_DROP_MODE.SELECTING)
    setBlockSave(true)
  }

  const onCopyConfirm = async destinations => {
    await Promise.all(
      destinations.map(destination =>
        updateLocationCollectionAlt([
          collection.id,
          destination.id,
          {
            [FORM_FIELDS.PRODUCT_IDS]: products.map(p => p.id)
          }
        ])
      )
    )
    showAlertSuccess(`Copied to ${destinations.map(d => d.code).join(', ')}`)
  }

  const handleRemoveAllProducts = () => setProducts([])

  const getItemProps = useCallback(
    p => ({
      locationId,
      selected: selectionsMap.has(p.id),
      featured: featuredMap.has(p.id)
    }),
    [locationId, selections, featured]
  )

  // This button is used to complete selection or removal.
  const editButton = disableRemove ? (
    <IconButton
      disabled={dndMode !== DRAG_N_DROP_MODE.SELECTING}
      size={44}
      variant={
        dndMode === DRAG_N_DROP_MODE.SELECTING
          ? ICON_BUTTON_VARIANT.blue
          : ICON_BUTTON_VARIANT.outlined
      }
      onClick={handleEditClick}
    >
      {dndMode === DRAG_N_DROP_MODE.SELECTING && <CheckIcon fontSize={12} />}
    </IconButton>
  ) : (
    <IconButton
      size={44}
      variant={
        dndMode === DRAG_N_DROP_MODE.ORDERING
          ? ICON_BUTTON_VARIANT.outlined
          : ICON_BUTTON_VARIANT.blue
      }
      onClick={handleEditClick}
    >
      {dndMode === DRAG_N_DROP_MODE.ORDERING ? (
        <CreateIcon fontSize="small" />
      ) : (
        <CheckIcon fontSize={12} />
      )}
    </IconButton>
  )

  return (
    <Box mt={6}>
      <Box mt={3} alignItems="flex-end" flexDirection="row">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexDirection="row"
          flexWrap="wrap"
          className={classes.actions}
        >
          <Typography variant="body2">Sort</Typography>
          <SelectField
            name={FORM_FIELDS.PRODUCT_SORT}
            minimal
            innerLabel="Sort Order"
            margin="none"
            items={PRODUCT_SORT_OPTIONS}
            onChange={onSortChange}
            disabled={
              dndMode !== DRAG_N_DROP_MODE.ORDERING || hasAddRemoveProducts
            }
          />
          {isParent && (
            <Button
              className={classes.grayButton}
              variant="outlined"
              size="medium"
              startIcon={<SyncIcon />}
              onClick={handleSyncClick}
              disabled={dndMode !== DRAG_N_DROP_MODE.ORDERING}
            >
              Sync
            </Button>
          )}
          <Button
            className={classes.grayButton}
            variant="outlined"
            size="medium"
            startIcon={<RedoIcon />}
            onClick={() => setCopyDialogOpen(true)}
            disabled={products?.length === 0}
          >
            Copy To
          </Button>

          {!disableAdd && (
            <Button
              className={classes.grayButton}
              variant="outlined"
              size="medium"
              onClick={() => setAddProductDrawerOpen(true)}
            >
              + Products
            </Button>
          )}
          {products?.length > 0 && !isParent && (
            <>
              <IconButton
                size={44}
                variant={ICON_BUTTON_VARIANT.outlined}
                onClick={() => setShowConfirmRemoveAllProductsModal(true)}
              >
                {<FiTrash2 fontSize={12} />}
              </IconButton>
              <Dialog
                open={showConfirmRemoveAllProductsModal}
                onClose={() => setShowConfirmRemoveAllProductsModal(false)}
                onConfirm={handleRemoveAllProducts}
                confirmText="Yes"
                hideCancel
              >
                <Box my={3}>
                  <Typography variant="body1">
                    Are you sure you wanna delete all the products in this
                    collection?
                  </Typography>
                </Box>
              </Dialog>
            </>
          )}
          {(isParent || !disableRemove) && editButton}
        </Box>
      </Box>

      <Box width="100%" mt={3}>
        <DragNDropGrid
          items={products}
          setItems={setProducts}
          ItemComponent={DraggableProduct}
          getItemProps={getItemProps}
          mode={dndMode}
          dragAndRemoveMode={dndMode === DRAG_N_DROP_MODE.REMOVE}
          onFeatureClick={!isParent ? handleFeaturedClick : null}
          onSelectClick={handleSelectClick}
          {...DRAGGABLE_PRODUCT_DIMS}
        />
      </Box>

      <CopyDialog
        open={copyDialogOpen}
        onClose={() => setCopyDialogOpen(false)}
        onConfirm={onCopyConfirm}
      />

      {!isParent && (
        <AddCollectionProductDrawer
          open={addProductDrawerOpen}
          activeProducts={products}
          onClose={() => setAddProductDrawerOpen(false)}
          onSubmit={handleProductCollectionSubmit}
        />
      )}
    </Box>
  )
}

CollectionProducts.propTypes = {
  classes: PropTypes.object.isRequired,
  collection: PropTypes.object,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.bool
}

export default withStyles(styles)(CollectionProducts)
