import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { useLocationsContext } from 'context'

import { isNumber } from 'utils/general.js'

import ProductSummary from 'components/ProductSummary'

import styles from './PlateItemStyles'

const PlateItem = ({ classes, plate, openPlate }) => {
  const { showAllLocationIds } = useLocationsContext()

  const variant = plate?.variant

  return (
    <TableRow data-test="inventory-item" onClick={openPlate}>
      <TableCell className={classes.productCell}>
        {variant && <ProductSummary product={variant} showVariantTitle />}
      </TableCell>
      <TableCell data-test="variant-sku">
        {variant ? variant.sku : ''}
      </TableCell>
      <TableCell data-test="variant-bin">
        {!showAllLocationIds && plate?.item_location?.label}
      </TableCell>
      <TableCell>
        {plate?.expiration_date
          ? moment(plate?.expiration_date).format('MM/DD/YY')
          : null}
      </TableCell>
      <TableCell>
        {plate?.pull_date ? moment(plate?.pull_date).format('MM/DD/YY') : null}
      </TableCell>
      <TableCell data-test="plate-quantity">
        {isNumber(plate?.quantity) && plate.quantity}
      </TableCell>
      <TableCell>{plate?.variant?.total_physical_quantity}</TableCell>
      <TableCell>
        {(plate?.variant?.total_physical_quantity || 0) -
          (plate?.variant?.available_quantity || 0)}
      </TableCell>
      <TableCell>{plate?.variant?.available_quantity}</TableCell>
    </TableRow>
  )
}

PlateItem.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.object,
  onSelect: PropTypes.func,
  selected: PropTypes.bool
}

export default withStyles(styles)(PlateItem)
