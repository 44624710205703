import get from 'lodash/get'
import { ORDER_STATES_CATEGORIES } from 'constants/general'
import { PAYMENT_METHODS } from 'constants/enums'

export const renderPaymentMethod = order =>
  PAYMENT_METHODS[order.payment_method_name] || 'Unknown'

export const getOrderStatesByCategoryKey = (key, optParams = undefined) => {
  switch (key) {
    case ORDER_STATES_CATEGORIES.total.key: {
      return {
        key: ORDER_STATES_CATEGORIES.total.key,
        label: ORDER_STATES_CATEGORIES.total.label,
        state: ORDER_STATES_CATEGORIES.total.state,
        value: get(optParams, 'totalCount')
      }
    }
    case ORDER_STATES_CATEGORIES.picking.key: {
      return {
        key: ORDER_STATES_CATEGORIES.picking.key,
        label: ORDER_STATES_CATEGORIES.picking.label,
        state: ORDER_STATES_CATEGORIES.picking.state,
        value: get(
          optParams,
          `statusCounts.${ORDER_STATES_CATEGORIES.picking.state}`
        )
      }
    }
    case ORDER_STATES_CATEGORIES.dispatched.key: {
      return {
        key: ORDER_STATES_CATEGORIES.dispatched.key,
        label: ORDER_STATES_CATEGORIES.dispatched.label,
        state: ORDER_STATES_CATEGORIES.dispatched.state,
        value: get(
          optParams,
          `statusCounts.${ORDER_STATES_CATEGORIES.dispatched.state}`
        )
      }
    }
    case ORDER_STATES_CATEGORIES.inTransit.key: {
      return {
        key: ORDER_STATES_CATEGORIES.inTransit.key,
        label: ORDER_STATES_CATEGORIES.inTransit.label,
        state: ORDER_STATES_CATEGORIES.inTransit.state,
        value: get(
          optParams,
          `statusCounts.${ORDER_STATES_CATEGORIES.inTransit.state}`
        )
      }
    }
    default:
      return null
  }
}
