import React, { createContext, useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ALL_LOCATION } from 'constants/general'

export const STORAGE_AUTH_TOKEN = 'AUTH_TOKEN'
export const STORAGE_LOCATION_ID = 'LOCATION_ID'

const storageAvailable = type => {
  try {
    const storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}

if (!storageAvailable('localStorage')) {
  console.error('This website requires localStorage enabled')
}

// Could also use sessionStorage for more ephemeral data
const { localStorage } = window

const initialAuthToken = localStorage.getItem(STORAGE_AUTH_TOKEN)

const rawInitialLocation = localStorage.getItem(STORAGE_LOCATION_ID)
// If it is null but 'null' isn't explicitly stored, this will get overwritten
let initialLocationId = null
try {
  initialLocationId = JSON.parse(rawInitialLocation)
  // eslint-disable-next-line no-empty
} catch {}
const hasInitialLocation =
  rawInitialLocation === JSON.stringify(ALL_LOCATION.id) ||
  Number.isInteger(initialLocationId)

export const LocalStorageContext = createContext()

export const LocalStorageProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(initialAuthToken)
  const [locationId, setLocationId] = useState(initialLocationId)
  const [needsUserDefaultLocation, setNeedsUserDefaultLocation] = useState(
    !hasInitialLocation
  )

  const clearStorage = () => {
    localStorage.clear()
    setAuthToken(null)
    setLocationId(null)
    setNeedsUserDefaultLocation(true)
  }

  useEffect(() => {
    localStorage.setItem(STORAGE_LOCATION_ID, locationId)
  }, [locationId])

  useEffect(() => {
    if (authToken !== null) {
      localStorage.setItem(STORAGE_AUTH_TOKEN, authToken)
    } else {
      localStorage.removeItem(STORAGE_AUTH_TOKEN)
    }
  }, [authToken])

  const value = useMemo(
    () => ({
      authToken,
      setAuthToken,
      locationId,
      setLocationId,
      needsUserDefaultLocation,
      setNeedsUserDefaultLocation,
      clearStorage
    }),
    [authToken, locationId]
  )

  return (
    <LocalStorageContext.Provider value={value}>
      {children}
    </LocalStorageContext.Provider>
  )
}

LocalStorageProvider.propTypes = {
  children: PropTypes.node
}
