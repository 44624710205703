import { NOWRAP } from 'constants/styles'

const getTableHeadStyles = theme => ({
  ...theme.typography.body2,
  backgroundColor: 'black',
  color: 'white',
  padding: '22px 16px',
  position: 'sticky',
  top: 0,
  zIndex: 100,
  fontWeight: 400,
  verticalAlign: 'middle',
  ...NOWRAP
})

const DataTableStyles = theme => ({
  TableContainer: {},
  TableHead: {
    '& .MuiTableCell-head': {
      ...getTableHeadStyles(theme)
    }
  },
  TableHeadSlim: {
    '& .MuiTableCell-head': {
      ...getTableHeadStyles(theme),
      padding: '22px 8px'
    }
  },
  TableBody: {
    '& .MuiTableRow-root:nth-child(even)': {
      backgroundColor: theme.palette.grays.light
    },
    '& .MuiTableCell-root': {
      ...NOWRAP
    },
    '& .MuiTableCell-root:first-of-type': {
      ...NOWRAP,
      paddingLeft: 8
    },
    '& .MuiTableCell-body': {
      border: 'none'
    }
  },
  TableBodySlim: {
    '& .MuiTableRow-root:nth-child(even)': {
      backgroundColor: theme.palette.grays.light
    },
    '& .MuiTableCell-root': {
      border: 'none',
      ...NOWRAP
    },
    '& .MuiTableCell-body': {
      border: 'none',
      verticalAlign: 'middle'
    },
    '& .MuiTableCell-body': {
      padding: '8px'
    }
  },
  actionHeader: {
    backgroundColor: theme.palette.common.black,
    color: 'white',
    padding: '18px 12px 18px 12px !important',

    '& .MuiButton-root': {
      marginLeft: '40px',
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 'solid 1px',
      borderColor: theme.palette.grays.dark,

      '&:hover': {
        backgroundColor: theme.palette.common.black
      }
    }
  },
  emptyColumn: {
    padding: '0 0 0 auto !important',
    width: '64px !important'
  }
})

export default DataTableStyles
