const jssStyles = theme => ({
  customerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    textAlign: 'left'
  },
  customerAvatarInfo: {
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(2)
  },
  customerAvatar: {
    width: 42,
    height: 42
  },
  item: {
    margin: `${theme.spacing(3)}px 0`
  },
  caption: {
    color: theme.palette.grays.dark
  },
  orderCount: {
    color: theme.palette.grays.darkest,
    fontSize: 11,
    marginTop: 4
  },
  divider: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grays.med}`
  },
  forwardArrow: {
    height: 12,
    color: theme.palette.grays.dark
  }
})

export default jssStyles
