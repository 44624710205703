import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import MuiDrawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'

import { ICON_BUTTON_VARIANT } from 'constants/enums'
import CloseIcon from 'icons/CloseIcon'
import IconButton from 'components/IconButton'
import ControlledForm from 'components/ControlledForm'

import styles from './DrawerStyles'

const FormComp = ({ form, children }) =>
  form ? <ControlledForm {...form}>{children}</ControlledForm> : <>{children}</>

FormComp.propTypes = {
  form: PropTypes.object,
  children: PropTypes.node
}

const Drawer = ({
  classes,
  title,
  open,
  disableEnforceFocus,
  onClose,
  actions,
  children,
  form
}) => (
  <MuiDrawer
    anchor="right"
    open={open}
    onClose={onClose}
    classes={{ root: classes.root, paper: classes.paper }}
    ModalProps={{ disableEnforceFocus }}
  >
    <FormComp form={form}>
      <Box width={371} height="100%" display="flex" flexDirection="column">
        <Box
          width="100%"
          height={72}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl={3}
          pr="12px"
          mb={3}
          flexGrow={0}
        >
          <Typography variant="h2">{title}</Typography>
          <IconButton
            onClick={onClose}
            variant={ICON_BUTTON_VARIANT.light}
            size={32}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          px={3}
          flexGrow={1}
          overflow="auto"
          flex={1}
        >
          {children}
        </Box>
        {actions && (
          <Box display="flex" flexGrow={0} className={classes.actions} p={3}>
            {actions}
          </Box>
        )}
      </Box>
    </FormComp>
  </MuiDrawer>
)

Drawer.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  actions: PropTypes.node,
  open: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  form: PropTypes.object
}

export default withStyles(styles)(Drawer)
