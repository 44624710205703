import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useForm, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import { SIZE } from 'constants/enums'
import { LIST_PATHS } from 'constants/queryParams'
import { URL } from 'constants/navigation'
import { useQuery, useAdminStatusCodes } from 'hooks'

import Header from 'components/Header'
import Layout from 'components/Layout'
import DataTable from 'components/DataTable'
import SearchInput from 'components/SearchInput'
import Select, { SelectField } from 'components/Select'
import GlanceTile from 'components/GlanceTile'
import Dialog from 'components/Dialog'
import TextField from 'components/TextField'

import StatusCodesItem from './StatusCodesItem'
import styles from './StatusCodesStyles'

const columns = [
  {
    title: 'Status Code',
    sortKeys: [LIST_PATHS.STATUS_CODES_CODE]
  },
  {
    title: 'Usage',
    sortKeys: [LIST_PATHS.STATUS_CODES_USAGE]
  },
  {
    title: 'Created On',
    sortKeys: [LIST_PATHS.STATUS_CODES_CREATED_AT]
  },
  {
    title: 'Status'
  },
  { title: '' }
]

const FIELDS = {
  name: 'name',
  active: 'active'
}

const statusCodesSchema = {
  [FIELDS.name]: yup.string().required(),
  [FIELDS.active]: yup.bool().required()
}

const statusCodesDefaults = {
  [FIELDS.name]: '',
  [FIELDS.active]: true
}

const StatusCodes = ({ classes }) => {
  const { query, handleQueryChange, updateQuery } = useQuery(false)
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [itemToEdit, setItemToEdit] = useState(null)

  const {
    statusCodes,
    hasStatusCodes,
    hasStatusCodesNext,
    isLoadingStatusCodes,
    statusCodesCount,
    listStatusCodesNext,
    createStatusCode,
    updateStatusCode,
    activeStatusCodesCount
  } = useAdminStatusCodes(query)

  const formContext = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: statusCodesDefaults,
    resolver: yupResolver(yup.object().shape(statusCodesSchema))
  })

  const { register, handleSubmit, errors, formState, reset } = formContext

  const foldableActions = (
    <SearchInput
      name="search"
      placeholder="Search..."
      value={query.search}
      onChange={handleQueryChange}
    />
  )

  const actions = (
    <Button
      color="primary"
      variant="contained"
      size={SIZE.medium}
      onClick={() => setOpenCreateDialog(true)}
      adaptive
      label="new Code"
      startIcon={<AddIcon />}
    >
      New Code
    </Button>
  )

  const filters = (
    <Select
      minimal
      name={LIST_PATHS.STATUS_CODES_STATUS}
      innerLabel="Status"
      value={query[LIST_PATHS.STATUS_CODES_STATUS] || ''}
      items={[
        { label: 'All', value: '' },
        { label: 'Active', value: 'true' },
        { label: 'Inactive', value: 'false' }
      ]}
      onChange={handleQueryChange}
    />
  )

  const summarizers = (
    <>
      <GlanceTile minimal label="Total Codes" value={statusCodesCount} />
      <GlanceTile minimal label="Active Codes" value={activeStatusCodesCount} />
    </>
  )

  const handleSubmitForm = async payload => {
    await (itemToEdit
      ? updateStatusCode({ id: itemToEdit.id, payload })
      : createStatusCode(payload))
    setOpenCreateDialog(false)
    updateQuery(query)
  }

  const handleSetActive = async (id, active) => {
    await updateStatusCode({ id, payload: { active } })
    updateQuery(query)
  }

  const handleEdit = item => {
    reset(item)
    setItemToEdit(item)
    setOpenCreateDialog(true)
  }

  useEffect(() => {
    if (openCreateDialog) return
    setItemToEdit(null)
    reset(statusCodesDefaults)
  }, [openCreateDialog])

  return (
    <Layout>
      <Header
        title="Status Codes"
        breadcrumbs={[
          {
            title: 'Back Of House',
            link: URL.ADMIN_BOH
          }
        ]}
        foldableActions={foldableActions}
        actions={actions}
        filters={filters}
        summarizers={summarizers}
      />
      <Box>
        <DataTable
          columns={columns}
          message={statusCodes?.length === 0 ? 'No results found.' : null}
          isLoadingList={isLoadingStatusCodes}
          hasListNext={hasStatusCodesNext}
          listNext={listStatusCodesNext}
          query={query}
          updateQuery={updateQuery}
        >
          {hasStatusCodes &&
            statusCodes.map(statusCode => (
              <StatusCodesItem
                statusCode={statusCode}
                key={statusCode.id}
                onSetActive={active => handleSetActive(statusCode.id, active)}
                onClickEdit={handleEdit}
              />
            ))}
        </DataTable>
      </Box>
      <FormProvider {...formContext}>
        {openCreateDialog && (
          <Dialog
            classes={{
              subtitle: classes.subtitle
            }}
            title={itemToEdit ? 'Edit Status Code.' : 'Create Status Code.'}
            subtitle={
              itemToEdit
                ? 'Update status code name and status'
                : 'Give the status code a name and choose the active status.'
            }
            open={openCreateDialog}
            onCancel={() => setOpenCreateDialog(false)}
            onConfirm={handleSubmit(handleSubmitForm)}
            confirmText={itemToEdit ? 'Update' : 'Create'}
            confirmDisabled={!formState.isDirty || !formState.isValid}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  name={FIELDS.name}
                  placeholder="Status Name"
                  inputRef={register}
                  fullWidth
                  error={Boolean(errors[FIELDS.name]?.message)}
                  helperText={errors[FIELDS.name]?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField
                  name={FIELDS.active}
                  items={[
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false }
                  ]}
                  fullWidth
                  error={Boolean(errors[FIELDS.active]?.message)}
                  helperText={errors[FIELDS.active]?.message}
                />
              </Grid>
            </Grid>
          </Dialog>
        )}
      </FormProvider>
    </Layout>
  )
}

StatusCodes.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(StatusCodes)
