import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import { formatCurrency } from 'utils/general'

import { Typography, TableRow, TableCell, Button } from '@material-ui/core'

import OrderStatus from 'components/OrderStatus'
import PaymentStatus from 'components/PaymentStatus'

const UserOrderItem = ({ order, onClick }) => {
  const deliveryDate =
    order.delivery_starts_at && moment(order.delivery_starts_at).format('L')
  const deliveryStartsAtText =
    order.delivery_starts_at && moment(order.delivery_starts_at).format('h')
  const deliveryEndsAtText =
    order.delivery_ends_at && moment(order.delivery_ends_at).format('ha')

  return (
    <TableRow>
      <TableCell>
        <Button
          color="primary"
          onClick={e => onClick(e, order, false, 'fromUserDetails')}
          onAuxClick={e => onClick(e, order, true, 'fromUserDetails')}
        >
          Order {`#${order?.number}`}
        </Button>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {formatCurrency(order.total_price)}
        </Typography>
      </TableCell>
      <TableCell>
        <PaymentStatus order={order} />
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {`${order?.total_line_items_quantity || 0} Items`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{deliveryDate}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {deliveryStartsAtText}-{deliveryEndsAtText}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {order?.delivery_method || 'N/A'}
        </Typography>
      </TableCell>
      <TableCell>
        <OrderStatus order={order} />
      </TableCell>
    </TableRow>
  )
}

UserOrderItem.propTypes = {
  order: PropTypes.object.isRequired,
  onClick: PropTypes.func
}

export default UserOrderItem
