import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { ALL_LOCATION } from 'constants/general'
import {
  useLocationsContext,
  useLocalStorageContext,
  useUIContext
} from 'context'
import { useAlerts } from 'hooks'
import RadioGroup from 'components/RadioGroup'
import Dialog from 'components/Dialog'

const SwitchDarkstoreModal = ({ open }) => {
  const { showAlertGeneral } = useAlerts()
  const { setShowSwitchDarkstoreModal } = useUIContext()
  const {
    location,
    locationOptionsWithAll,
    showAllLocationIds,
    getLocationAtId
  } = useLocationsContext()
  const { locationId, setLocationId } = useLocalStorageContext()
  const [selectedId, setSelectedId] = useState(locationId)

  const onClose = () => setShowSwitchDarkstoreModal(false)

  const getValueFromLabel = v =>
    locationOptionsWithAll?.[
      locationOptionsWithAll?.findIndex(opt => opt.label === v)
    ]?.value
  const getLabelFromId = id =>
    locationOptionsWithAll?.[
      locationOptionsWithAll?.findIndex(opt => opt.value === id)
    ]?.label

  const onRadioChange = ({ target: { value } }) =>
    setSelectedId(getValueFromLabel(value))

  const switchDarkstore = () => {
    setLocationId(selectedId)
    setShowSwitchDarkstoreModal(false)
    if (selectedId === ALL_LOCATION.id) {
      showAlertGeneral('Location changed to show all.')
      return
    }

    const locationName = getLocationAtId(selectedId).name
    showAlertGeneral(
      `Location changed to ${locationName}. All actions will affect this location only.`
    )
  }

  return (
    <Dialog
      title="Switch Darkstore"
      subtitle={`Current Location ${
        showAllLocationIds ? 'All Locations' : location?.name
      }`}
      open={open}
      onCancel={onClose}
      onConfirm={switchDarkstore}
      dataTest="switchDarkstore"
    >
      <Box mt={2}>
        <Typography variant="body2">Switch To</Typography>
        <RadioGroup
          value={getLabelFromId(selectedId) || 'Loading'}
          labels={locationOptionsWithAll?.map(opt => opt.label) || ['Loading']}
          onChange={e => onRadioChange(e)}
          withBorders
        />
      </Box>
    </Dialog>
  )
}

SwitchDarkstoreModal.propTypes = {
  open: PropTypes.bool
}

export default SwitchDarkstoreModal
