import { collectionEndpoint } from 'api/endpoints'
import { useResourceListRQ } from 'hooks'
import { STALE_TIME_MEDIUM } from 'constants/reactQuery'

const useAdminCollectionsList = params => {
  const {
    isFetching: isLoadingCollections,
    data: collections,
    hasData: hasCollections,
    hasNextPage: hasCollectionsNext,
    fetchNextPage: fetchCollectionsNext,
    meta: collectionsMeta
  } = useResourceListRQ({
    endpoint: collectionEndpoint,
    params,
    options: { staleTime: STALE_TIME_MEDIUM }
  })

  return {
    isLoadingCollections,
    collections,
    hasCollections,
    hasCollectionsNext,
    fetchCollectionsNext,
    collectionsMeta
  }
}

export default useAdminCollectionsList
