import { useResourceListRQ } from 'hooks'
import { ordersEndpoint } from 'api/endpoints'

const useAdminOrdersList = ({ refetchInterval, params }) => {
  const {
    isFetching: isLoadingOrders,
    data: orders,
    hasData: hasOrders,
    hasNextPage: hasOrdersNext,
    fetchNextPage: fetchOrdersNext,
    meta: ordersMeta
  } = useResourceListRQ({
    endpoint: ordersEndpoint,
    params,
    options: { refetchInterval }
  })

  return {
    isLoadingOrders,
    orders: orders || [],
    hasOrders,
    hasOrdersNext,
    fetchOrdersNext,
    ordersMeta
  }
}

export default useAdminOrdersList
