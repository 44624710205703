export default theme => ({
  root: {
    height: 56,
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.grays.med,
    width: '100%',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 2, 0, 0),

    '& .MuiFormControlLabel-root': {
      marginLeft: theme.spacing(2)
    }
  }
})
