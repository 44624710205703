import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'

const ActionsContainer = ({ actions }) => {
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
      {actions}
    </Box>
  )
}

ActionsContainer.propTypes = {
  actions: PropTypes.node.isRequired
}

export default ActionsContainer
