import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import uniqBy from 'lodash/uniqBy'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminSupplierList } from 'hooks'
import AutocompleteSearch from 'components/AutocompleteSearch'

const AutocompleteSearchSuppliersRaw = ({
  classes,
  onChange,
  value,
  defaultOpen,
  getOptionLabel,
  placeholder,
  optWithEmail,
  queryParams,
  name,
  multiple = false,
  ...rest
}) => {
  const [query, setQuery] = useState('')
  const {
    isLoadingSuppliers,
    suppliers,
    listSuppliersNext
  } = useAdminSupplierList({ search: query, ...queryParams })

  const options = useMemo(
    () => uniqBy((suppliers ?? []).concat(value), v => v?.id),
    [suppliers]
  )

  return (
    <AutocompleteSearch
      name={name}
      defaultOpen={defaultOpen}
      value={value}
      loading={isLoadingSuppliers}
      setQuery={setQuery}
      onChange={onChange}
      onScrollBottom={listSuppliersNext}
      options={options}
      placeholder={placeholder}
      multiple={multiple}
      getOptionLabel={getOptionLabel}
      renderOption={(option, { selected }) => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          className={selected ? classes.selectedItem : null}
          data-test={`option-${option?.name}`}
        >
          <Typography variant="body1" className={classes.supplierName}>
            {option?.name}
          </Typography>
        </Box>
      )}
      {...rest}
    />
  )
}

AutocompleteSearchSuppliersRaw.defaultProps = {
  value: [],
  defaultOpen: false,
  getOptionLabel: opt => opt?.email ?? opt?.name,
  placeholder: 'Search Suppliers',
  optWithEmail: true
}

AutocompleteSearchSuppliersRaw.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string
  ]),
  defaultOpen: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  placeholder: PropTypes.string,
  optWithEmail: PropTypes.bool,
  queryParams: PropTypes.object,
  name: PropTypes.string.isRequired
}

const AutocompleteSearchSuppliers = withStyles(() => ({
  name: {},
  selectedItem: {
    '& $name': {
      fontWeight: 700
    }
  }
}))(AutocompleteSearchSuppliersRaw)

const AutocompleteSearchSuppliersControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchSuppliers
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchSuppliersControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchSuppliers as default,
  AutocompleteSearchSuppliersControlled
}
