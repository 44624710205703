import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { fromPairs } from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import SaveIcon from '@material-ui/icons/Save'

import { URL } from 'constants/navigation'
import { PRODUCT_SORT_KEYS, DESTROY_COMMAND } from 'constants/collections'
import { useLocationsContext } from 'context'
import {
  useLoaders,
  useAdminCollections,
  useAdminLocationCollection,
  useBeforeUnload,
  useAlerts
} from 'hooks'

import Button from 'components/Button'
import ControlledForm, { useControlledForm } from 'components/ControlledForm'
import Header from 'components/Header'
import Layout from 'components/Layout'

import CollectionDetailsView from './CollectionDetailsView'
import {
  FORM_FIELDS,
  COLLECTION_FIELDS,
  LOCOLLECTION_FIELDS,
  SCHEMAS
} from './CollectionFormFields'

const getChanges = (fieldNames, changedNames, data) =>
  fromPairs(
    fieldNames
      .filter(name => changedNames.has(name))
      .map(name => [name, data[name]])
  )

const HeaderActions = ({ collectionId }) => {
  const { handleSubmit, readyToSave } = useControlledForm()
  const { OnBeforeUnloadPrompt } = useBeforeUnload(readyToSave)
  const { showAlertError, showAlertSuccess } = useAlerts()

  const { cloneCollection, cloneCollectionIsLoading } = useAdminCollections(
    collectionId,
    {
      onSuccess: showAlertSuccess,
      onError: showAlertError
    }
  )

  return (
    <>
      <Button
        label="clone collection button"
        size="medium"
        onClick={cloneCollection}
        disabled={cloneCollectionIsLoading}
        dataTest="collection-clone-button"
      >
        Clone
      </Button>
      <Button
        adaptive
        label="save collection button"
        startIcon={<SaveIcon />}
        size="medium"
        onClick={handleSubmit}
        disabled={!readyToSave}
        dataTest="collection-save-button"
      >
        Save
      </Button>
      <OnBeforeUnloadPrompt />
    </>
  )
}

const ControlledFormLoader = ({ placeholder, children }) => {
  const { loading } = useControlledForm()
  return loading ? placeholder : children
}

ControlledFormLoader.propTypes = {
  children: PropTypes.node,
  placeholder: PropTypes.node
}

const CollectionDetailsPlaceholder = () => (
  <Grid spacing={3} container>
    <Grid item xs={12} md={6}>
      <Skeleton variant="rect" height={400} />
    </Grid>
    <Grid item xs={12} md={6}>
      <Skeleton variant="rect" height={600} />
    </Grid>
  </Grid>
)

const CollectionDetails = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const { showAlertError, showAlertSuccess } = useAlerts()
  const { showLoading, hideLoading } = useLoaders()
  const {
    locationId,
    showAllLocationIds,
    getLocationAtId,
    locations
  } = useLocationsContext()
  const collectionId = Number.parseInt(id, 10)

  const { collection, updateCollection, updateChildren } = useAdminCollections(
    collectionId,
    {
      onSuccess: showAlertSuccess,
      onError: showAlertError
    }
  )

  const { collection: parentCollection } = useAdminCollections(
    collection?.parent_collection_id
  )

  const {
    locationCollection,
    updateLocationCollection
  } = useAdminLocationCollection(collectionId, locationId, undefined, {
    onSuccess: showAlertSuccess,
    onError: showAlertError
  })

  const handleFormErrors = errors =>
    Object.entries(errors).forEach(([name, err]) =>
      showAlertError(`${name}: ${err.message}`)
    )

  const { loading, onSubmit, defaultValues } = useMemo(() => {
    if (!(locations && collection)) {
      return {
        loading: true,
        onSubmit: () => undefined,
        defaultValues: {}
      }
    }

    const nullifyIfAll = val => (showAllLocationIds ? null : val)
    const getLocations = ids => ids.map(getLocationAtId)
    const getIds = items => (items ? items.map(item => item.id) : null)

    const defaultLocationIds = collection?.location_ids ?? []
    const defaultParentCollectionId = collection?.parent_collection_id
    const defaultProducts = nullifyIfAll(locationCollection?.products)
    const featuredProducts = nullifyIfAll(
      locationCollection?.products?.filter(({ featured }) => featured)
    )
    const defaultChildren = nullifyIfAll(locationCollection?.children)
    const defaultChildrenIds = getIds(defaultChildren)

    const defaultValuesInner = {
      [FORM_FIELDS.TITLE]: collection?.title ?? '',
      [FORM_FIELDS.SUBHEADER]: collection?.subheader ?? '',
      [FORM_FIELDS.DESCRIPTION]: collection?.description ?? '',
      [FORM_FIELDS.IMAGE_URL]: collection?.image_url ?? '',
      [FORM_FIELDS.EDITORIAL_MEDIA_URL]: collection?.editorial_media_url ?? '',
      [FORM_FIELDS.IMAGE]: null,
      [FORM_FIELDS.EDITORIAL_MEDIA]: null,
      [FORM_FIELDS.COLLECTION_TYPE]: collection?.collection_type,
      [FORM_FIELDS.PARENT_COLLECTION_ID]: defaultParentCollectionId,
      [FORM_FIELDS.PARENT_COLLECTION]: Number.isInteger(
        defaultParentCollectionId
      )
        ? parentCollection ?? {
            id: defaultParentCollectionId,
            title: `Collection #${defaultParentCollectionId}`
          }
        : null,
      [FORM_FIELDS.HAS_SEE_ALL_LINK]: collection?.has_see_all_link ?? false,
      [FORM_FIELDS.LOCATIONS]: getLocations(defaultLocationIds),
      [FORM_FIELDS.LOCATION_IDS]: defaultLocationIds,
      [FORM_FIELDS.PRODUCTS]: defaultProducts,
      [FORM_FIELDS.PRODUCT_IDS]: getIds(defaultProducts),
      [FORM_FIELDS.FEATURED_PRODUCT_IDS]: getIds(featuredProducts),
      [FORM_FIELDS.CHILDREN]: defaultChildren,
      [FORM_FIELDS.CHILDREN_IDS]: defaultChildrenIds,
      [FORM_FIELDS.PRODUCT_SORT]: nullifyIfAll(
        locationCollection?.product_sort || PRODUCT_SORT_KEYS.DEFAULT
      )
    }

    const onSubmitInner = async (data, event, changedNames) => {
      const collectionChanges = getChanges(
        COLLECTION_FIELDS,
        changedNames,
        data
      )
      const locollectionChanges = getChanges(
        LOCOLLECTION_FIELDS,
        changedNames,
        data
      )

      try {
        const changedKeys = Object.keys(collectionChanges)
        showLoading()

        if (changedKeys?.length) {
          // DAB-984: submit the image deletion request if image url was altered / removed
          if (
            changedKeys.includes(FORM_FIELDS.IMAGE) &&
            collectionChanges[FORM_FIELDS.IMAGE] === undefined
          ) {
            collectionChanges[FORM_FIELDS.IMAGE] = DESTROY_COMMAND
          }

          if (
            changedKeys.includes(FORM_FIELDS.EDITORIAL_MEDIA) &&
            collectionChanges[FORM_FIELDS.EDITORIAL_MEDIA] === undefined
          ) {
            collectionChanges[FORM_FIELDS.EDITORIAL_MEDIA] = DESTROY_COMMAND
          }

          await updateCollection(collectionChanges)
        }

        if (
          !showAllLocationIds &&
          Object.values(locollectionChanges).length > 0
        ) {
          if (changedNames.has(FORM_FIELDS.CHILDREN_IDS)) {
            await updateChildren(
              collectionId,
              data[FORM_FIELDS.CHILDREN_IDS],
              defaultChildrenIds
            )
          }

          if (data[FORM_FIELDS.PRODUCT_SORT] !== PRODUCT_SORT_KEYS.DEFAULT) {
            delete locollectionChanges[FORM_FIELDS.PRODUCT_IDS]
          }
          await updateLocationCollection(locollectionChanges)
        }
        showAlertSuccess('Collection updated successfully')
      } catch (e) {
        showAlertError('Collection not updated, please try again')

        console.error('error updating collection', e)
      } finally {
        hideLoading()
      }
    }

    return {
      loading: false,
      onSubmit: onSubmitInner,
      defaultValues: defaultValuesInner
    }
  }, [locations, collection, locationCollection, parentCollection])

  return (
    <ControlledForm
      handleSubmit={onSubmit}
      schemas={SCHEMAS}
      defaultValues={defaultValues}
      boxProps={{ flex: 1 }}
      loading={loading}
      resetOnSubmit={false}
      onError={handleFormErrors}
    >
      <Layout
        id="collection-details"
        layoutClassName={classes.collectionLayoutContainer}
      >
        <Header
          sticky
          breadcrumbs={[{ title: 'Collections', link: URL.ADMIN_COLLECTIONS }]}
          title={collection?.title}
          actions={<HeaderActions collectionId={collectionId} />}
        />
        <ControlledFormLoader placeholder={<CollectionDetailsPlaceholder />}>
          <CollectionDetailsView
            collection={collection}
            loadingLocationCollection={!locationCollection}
          />
        </ControlledFormLoader>
      </Layout>
    </ControlledForm>
  )
}

CollectionDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired
  }).isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(theme => ({
  collectionLayoutContainer: {
    width: '100%'
  }
}))(CollectionDetails)
