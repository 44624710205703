import { api } from 'api'
import { locationPrintersEndpoint } from 'api/endpoints'
import { useResourceRQ } from 'hooks'
import { useQuery } from 'react-query'

const useAdminPrinters = (locationId, printerId) => {
  const { create, update, destroy } = useResourceRQ({
    endpoint: locationPrintersEndpoint,
    args: [locationId, printerId],
    name: 'printer',
    fetchingEnabled: false,
    dependents: [() => locationPrintersEndpoint(locationId)]
  })

  const shouldDataLoad = Boolean(locationId || (locationId && !printerId))

  const { isLoading, isFetching, data: response } = useQuery(
    [locationPrintersEndpoint(locationId)],
    () => api.get(locationPrintersEndpoint(locationId)),
    {
      enabled: shouldDataLoad
    }
  )

  return {
    isLoading,
    isFetching,
    createPrinter: create,
    printers: response?.data ?? [],
    updatePrinter: update,
    destroyPrinter: destroy
  }
}

export default useAdminPrinters
