import React from 'react'
import PropTypes from 'prop-types'

const ServiceAreaIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="9.5" r="8.25" stroke="#8D8D8D" strokeWidth="2" />
    <path
      d="M10 1.25V5"
      stroke="#8D8D8D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 14V17.75"
      stroke="#8D8D8D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.75 9.5H5.5"
      stroke="#8D8D8D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 9.5H18.25"
      stroke="#8D8D8D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ServiceAreaIcon.propTypes = {
  className: PropTypes.string
}

export default ServiceAreaIcon
