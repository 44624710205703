import * as yup from 'yup'
import fromPairs from 'lodash/fromPairs'

export const PRODUCT_IMAGE_WIDTH_PX = 1125
export const PRODUCT_IMAGE_HEIGHT_PX = 1320

export const emptyStringToNull = (value, originalValue) =>
  typeof originalValue === 'string' && originalValue === '' ? null : value

const PRODUCT_NUTRI_FACTS_FORM_FIELDS_FULL = {
  INGREDIENTS: {
    name: 'ingredients',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.string()
  },
  ALLERGENS: {
    name: 'allergens',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.string()
  },
  SERVING_SIZE: {
    name: 'serving_size',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.string()
  },
  SERVINGS_PER_CONTAINER: {
    name: 'servings_per_container',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.string()
  },
  CALORIES_PER_SERVING: {
    name: 'calories_per_serving',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.string()
  },
  FAT_CALORIES_PER_SERVING: {
    name: 'fat_calories_per_serving',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.string()
  },
  NUTRITION_FACTS: {
    name: 'nutrition_facts',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.object().nullable()
  }
}

export const PRODUCT_FORM_FIELDS_FULL = {
  TITLE: {
    name: 'title',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup
      .string()
      .required()
      .typeError('Title is a required field')
  },
  HANDLE: {
    name: 'handle',
    showFieldOnNewProduct: false,
    showFieldOnExistingProduct: true,
    schema: yup.string().required()
  },
  DESCRIPTION: {
    name: 'description',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.string()
  },
  USAGE_INSTRUCTIONS: {
    name: 'usage_instructions',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup
        .string()
        .nullable()
  },
  MSRP: {
    name: 'msrp',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: false,
    schema: yup
      .number()
      .required()
      .typeError('MSRP is a required field')
  },
  COMPARE_AT_PRICE: {
    name: 'compare_at_price',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: false,
    schema: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
  },
  COST_PER_ITEM: {
    name: 'cost_per_item',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: false,
    schema: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
  },
  MAX_ORDER_QUANTITY: {
    name: 'max_purchase_quantity',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup
      .number()
      .min(0, 'Must be greater than or equal to 0')
      .transform(emptyStringToNull)
      .nullable()
  },
  CONSIGNMENT: {
    name: 'consignment',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.bool()
  },
  LOCKED: {
    name: 'locked',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.bool()
  },
  AGES_21_AND_OVER: {
    name: 'ages_21_and_over',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.bool()
  },
  RESTOCK_NOTIFICATIONS_ENABLED: {
    name: 'restock_notifications_enabled',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.bool()
  },
  LOCATION_IDS: {
    name: 'location_ids',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.array()
  },
  VENDOR_STYLE_NUMBER: {
    name: 'vendor_style_number',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.string()
  },
  VENDOR_STYLE_NUMBER_IS_UNIVERSAL: {
    name: 'vendor_style_number_is_universal',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.bool()
  },
  TAX_CATEGORY_ID: {
    name: 'tax_category_id',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.object().nullable()
  },
  SHOW_ON_PDP: {
    name: 'show_on_pdp',
    showFieldOnNewProduct: false,
    showFieldOnExistingProduct: true,
    schema: yup.bool()
  },
  BLACKLISTED_PRODUCT_RECOMMENDATION_IDS: {
    name: 'black_listed_recommened_product_ids',
    showFieldOnNewProduct: false,
    showFieldOnExistingProduct: true,
    schema: yup.array()
  },
  BRAND: {
    name: 'brand',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup
      .object()
      .required()
      .typeError('Brand is a required field')
  },
  PARENT_CATEGORY: {
    name: 'category',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.object().nullable()
  },
  SUB_CATEGORY: {
    name: 'subcategory',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.object().nullable()
  },
  BADGES: {
    name: 'badges',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.array()
  },
  TAGS: {
    name: 'tags',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.array()
  },
  EXCLUDE_FROM_PROMOS: {
    name: 'exclude_from_all_promo_codes',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.bool()
  },
  STATUS_CODE: {
    name: 'status_code',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.object().nullable()
  },
  VARIANT_COLORS: {
    name: 'variant_colors',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: false,
    schema: yup.array()
  },
  VARIANT_SIZES: {
    name: 'variant_sizes',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: false,
    schema: yup.array()
  },
  VARIANT_ACCESSORIES: {
    name: 'variant_accessories',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: false,
    schema: yup.array()
  },
  VARIANT_OPTION_SIZES_ORDER: {
    name: 'variant_option_sizes_order',
    showFieldOnNewProduct: false,
    showFieldOnExistingProduct: true,
    schema: yup.array()
  },
  VARIANT_OPTION_COLORS_ORDER: {
    name: 'variant_option_colors_order',
    showFieldOnNewProduct: false,
    showFieldOnExistingProduct: true,
    schema: yup.array()
  },
  VARIANT_OPTION_ACCESSORIES_ORDER: {
    name: 'variant_option_ACCESSORIES_order',
    showFieldOnNewProduct: false,
    showFieldOnExistingProduct: true,
    schema: yup.array()
  },
  IMAGES: {
    name: 'raw_images',
    showFieldOnNewProduct: false,
    showFieldOnExistingProduct: true,
    schema: yup.array().nullable()
  },
  IMAGE_FILES: {
    name: 'upload_images_files',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: false,
    schema: yup.array().nullable()
  },
  ALLOW_STORE_CREDIT: {
    name: 'allow_store_credit',
    showFieldOnNewProduct: true,
    showFieldOnExistingProduct: true,
    schema: yup.boolean().nullable()
  },
  ...PRODUCT_NUTRI_FACTS_FORM_FIELDS_FULL
}

export const FORM_FIELDS = fromPairs(
  Object.entries(PRODUCT_FORM_FIELDS_FULL).map(([key, { name }]) => [key, name])
)
export const NEW_PRODUCT_FIELDS = Object.values(PRODUCT_FORM_FIELDS_FULL)
  .filter(v => v.showFieldOnNewProduct)
  .map(v => v.name)
export const EXISTING_PRODUCT_FIELDS = Object.values(PRODUCT_FORM_FIELDS_FULL)
  .filter(v => v.showFieldOnExistingProduct)
  .map(v => v.name)
export const NEW_PRODUCT_SCHEMA = fromPairs(
  Object.values(PRODUCT_FORM_FIELDS_FULL)
    .filter(v => v.showFieldOnNewProduct)
    .map(({ name, schema }) => [name, schema])
)
export const EXISTING_PRODUCT_SCHEMA = fromPairs(
  Object.values(PRODUCT_FORM_FIELDS_FULL)
    .filter(v => v.showFieldOnExistingProduct)
    .map(({ name, schema }) => [name, schema])
)
