import React, { useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import keyBy from 'lodash/keyBy'
import isEmpty from 'lodash/isEmpty'
import { withStyles, useTheme } from '@material-ui/core/styles'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeGrid } from 'react-window'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CreateIcon from '@material-ui/icons/Create'

import { getValueAtBreakpoint } from 'utils/general'
import { SIZE } from 'constants/enums'
import { COLLECTIONS_BLOCK_ASPECT_RATIO } from 'constants/collections'
import { SCROLL_WIDTH_PX } from 'constants/styles'
import * as propTypes from 'constants/propTypes'

import ManageCollectionModal from 'components/ManageCollectionModal'
import CollectionVirtualizedCell from 'components/CollectionVirtualizedCell'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import ProgressBar from 'components/ProgressBar'

import ExportIcon from 'icons/ExportIcon'

import styles from './CollectionLayoutStyles'

const CollectionLayout = forwardRef(
  (
    {
      classes,
      items,
      variant,
      hasItems,
      hasItemsNext,
      listItemsNext,
      currentPage,
      isLoading,
      onItemClick,
      onManageSubmit,
      locationId,
      onExportClick,
      id,
      selectable,
      wide
    },
    ref
  ) => {
    const theme = useTheme()
    const [selections, setSelections] = useState({})
    const [manageDrawerOpen, setManageDrawerOpen] = useState(false)

    const clearSelections = () => {
      setSelections({})
    }

    useImperativeHandle(ref, () => ({
      clearSelections
    }))

    const handleSelect = (item, isSelected) => {
      const clone = { ...selections }
      if (isSelected) {
        clone[item.id] = item
      } else {
        delete clone[item.id]
      }
      setSelections(clone)
    }

    const handleSelectAll = isChecked => {
      if (isChecked) {
        setSelections(keyBy(items, i => i.id))
      } else {
        setSelections({})
      }
    }

    const handleManageSubmit = (...args) => {
      setSelections({})
      onManageSubmit(...args)
    }

    const menu = !onManageSubmit
      ? null
      : [
          {
            label: 'Manage',
            handleClick: i => {
              setSelections({ [i.id]: i })
              setManageDrawerOpen(true)
            }
          }
        ]

    const numSelected = Object.keys(selections).length
    const allSelected = items?.length > 0 && numSelected === items.length
    const columnConfig = wide
      ? { xs: 1, sm: 2, md: 3, lg: 3 }
      : { xs: 1, sm: 2, md: 4, lg: 5 }

    const SizedGrid = ({ width, height }) => {
      const columnCount = getValueAtBreakpoint(
        theme.breakpoints.values,
        width,
        columnConfig
      )
      const rowCount = Math.ceil(items.length / columnCount)

      const columnWidth = Math.floor((width - SCROLL_WIDTH_PX) / columnCount)
      const rowHeight = Math.floor(
        width / columnCount / COLLECTIONS_BLOCK_ASPECT_RATIO
      )

      if (height > rowCount * rowHeight) {
        hasItemsNext && listItemsNext()
      }

      return (
        <FixedSizeGrid
          columnCount={columnCount}
          rowCount={rowCount}
          height={height}
          width={width}
          columnWidth={columnWidth}
          rowHeight={rowHeight}
          onScroll={({ scrollTop, verticalScrollDirection }) =>
            // TODO: investigate alt react-window-infinite-loader to replace hack
            verticalScrollDirection === 'forward' &&
            scrollTop + height > height * currentPage &&
            !isLoading &&
            hasItemsNext &&
            listItemsNext &&
            listItemsNext()
          }
          itemData={{
            items,
            variant,
            selections,
            columnCount,
            onSelect: handleSelect,
            onItemClick,
            menu,
            locationId,
            showPublish: true
          }}
        >
          {CollectionVirtualizedCell}
        </FixedSizeGrid>
      )
    }

    return (
      <>
        <Box display="flex" flexDirection="column" height="100%" pb={1}>
          {selectable && (
            <Box className={classes.gridActionBar}>
              <Checkbox
                size={SIZE.small}
                onChange={handleSelectAll}
                indeterminate={numSelected > 0 && !allSelected}
                checked={allSelected}
              />
              <Typography variant="body1">Select All</Typography>
              {!isEmpty(selections) && (
                <>
                  <Button
                    size={SIZE.medium}
                    onClick={() => setManageDrawerOpen(true)}
                    startIcon={<CreateIcon />}
                    dataTest={`collection-manage-button`}
                  >
                    <Typography variant="body1">Manage</Typography>
                  </Button>
                  {onExportClick && (
                    <Button
                      size={SIZE.medium}
                      onClick={() =>
                        onExportClick(
                          Object.values(selections)?.map(
                            selection => selection?.id
                          )
                        )
                      }
                      startIcon={<ExportIcon color="white" size="24" />}
                    >
                      <Typography variant="body1">Export</Typography>
                    </Button>
                  )}
                </>
              )}
            </Box>
          )}

          <Box flexGrow={1} mt={1} mx={-1} data-test="collection-list">
            {hasItems && <AutoSizer>{SizedGrid}</AutoSizer>}
          </Box>

          {isLoading && hasItemsNext && (
            <Box my={2}>
              <ProgressBar />
            </Box>
          )}
          {!isLoading && hasItemsNext && !items && (
            <Box my={2}>
              <ProgressBar />
            </Box>
          )}
        </Box>
        {handleManageSubmit && (
          <ManageCollectionModal
            items={Object.values(selections)}
            open={manageDrawerOpen}
            onSelect={handleSelect}
            onSubmit={handleManageSubmit}
            onClose={() => setManageDrawerOpen(false)}
            variant={variant}
          />
        )}
      </>
    )
  }
)

CollectionLayout.defaultProps = {
  selectable: true
}

CollectionLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  hasItems: PropTypes.bool,
  hasItemsNext: PropTypes.bool,
  isLoading: PropTypes.bool,
  onItemClick: PropTypes.func,
  onManageSubmit: PropTypes.func,
  variant: propTypes.collectionLayoutVariant,
  locationId: PropTypes.number,
  listItemsNext: PropTypes.func,
  currentPage: PropTypes.number,
  onExportClick: PropTypes.func,
  selectable: PropTypes.bool
}

export default withStyles(styles)(CollectionLayout)
