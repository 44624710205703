import { downloadApi } from 'api'
import { useResourceListRQ } from 'hooks'
import {
  purchaseOrderListEndpoint,
  purchaseOrderExportEndpoint
} from 'api/endpoints'

const useAdminPurchaseOrderList = params => {
  const {
    isFetching: isFetchingPurchaseOrders,
    data: purchaseOrders,
    hasData: hasPurchaseOrders,
    hasNextPage: hasPurchaseOrdersNext,
    fetchNextPage: fetchPurchaseOrdersNext,
    meta
  } = useResourceListRQ({ endpoint: purchaseOrderListEndpoint, params })

  const getExport = async purchaseOrderId => {
    await downloadApi.get(purchaseOrderExportEndpoint(purchaseOrderId))
  }

  return {
    isFetchingPurchaseOrders,
    purchaseOrders: purchaseOrders || [],
    hasPurchaseOrders,
    hasPurchaseOrdersNext,
    fetchPurchaseOrdersNext,
    summary: meta?.summary,
    getExport
  }
}

export default useAdminPurchaseOrderList
