import { useResourceListRQ } from 'hooks'
import { isNumber } from 'utils/general.js'

const useAdminHistory = ({
  endpoint,
  id,
  params = {},
  fetchingEnabled = true
}) => {
  const {
    isFetching: isFetchingHistory,
    data: history,
    hasData: hasHistory,
    hasNextPage: hasHistoryNext,
    fetchNextPage: fetchHistoryNext
  } = useResourceListRQ({
    endpoint,
    args: [id],
    params,
    fetchingEnabled: endpoint && isNumber(id) && fetchingEnabled
  })

  return {
    isFetchingHistory,
    history,
    hasHistory,
    hasHistoryNext,
    fetchHistoryNext
  }
}

export default useAdminHistory
