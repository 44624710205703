import { zIndex } from 'theme/general'
import { colorsAF } from 'theme/colors'

export const thinInput = theme => ({
  height: 40,
  '& .MuiOutlinedInput-input': {
    padding: theme.spacing(1.5)
  },
  '& .MuiSelect-selectMenu': {
    height: 0
  },
  '& .MuiInputBase-input': {
    color: 'black'
  }
})

export const LIGHT_BLUE = '#00daff'

export const BoxShadow =
  '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'

export const BoxShadowFaint = '2px 8px 24px 0px rgba(0, 0, 0, 0.12)'

export const MINIMAL_LABEL = theme => ({
  fontWeight: 200,
  marginBottom: theme.spacing(1),
  fontSize: 10,
  lineHeight: '13px',
  textTransform: 'uppercase'
})

export const ABSOLUTE_FILL = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
}

export const ABSOLUTE_FILL_FULL = {
  ...ABSOLUTE_FILL,
  width: '100%',
  height: '100%'
}

export const FLEX_CENTER = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const SCROLL_WIDTH_PX = 15

export const PRODUCT_DRAWER_STYLES = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiInputBase-root': {
      height: 60
    }
  },
  addButton: {
    height: 33,
    margin: 12
  },
  selectedItem: {
    display: 'flex'
  },
  productSummary: {
    width: '80%',
    marginRight: '2%'
  },
  itemActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.grays.dark,
    width: '18%',

    '& .MuiButtonBase-root': {
      padding: 0,
      marginRight: 'unset'
    }
  },
  quantity: {
    display: 'flex',
    alignItems: 'baseline',

    '& .MuiInputBase-root': {
      width: 38,
      height: 30,
      minWidth: 'unset',
      fontSize: 12,

      '& .MuiInputBase-input': {
        textAlign: 'center',
        padding: '3px 5px 3px !important'
      }
    }
  },
  price: {
    display: 'flex',
    alignItems: 'baseline',

    '& .MuiInputBase-root': {
      width: 68,
      height: 30,
      minWidth: 'unset',
      fontSize: 12,

      '& .MuiInputBase-input': {
        textAlign: 'left',
        padding: '3px 5px !important'
      }
    }
  }
})

export const HEADER_INNER_COLUMN = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%'
}

export const STICKY_SHARED = {
  position: 'fixed',
  top: 16,
  right: 8,
  zIndex: zIndex.layer50
}

export const STICKY_TOP = {
  ...STICKY_SHARED,
  top: 0,
  bottom: 'auto'
}

export const STICKY_BOTTOM = {
  ...STICKY_SHARED
}

export const DRAGGABLE_PRODUCT_DIMS = {
  itemWidth: 180,
  itemHeight: 308,
  marginWidth: 25,
  marginHeight: 16
}

export const DRAGGABLE_IMAGE_DIMS = {
  itemWidth: 100,
  itemHeight: 100,
  marginWidth: 0,
  marginHeight: 0
}

export const DRAGGABLE_COLLECTION_DIMS = {
  itemWidth: 280,
  itemHeight: 161,
  marginWidth: 16,
  marginHeight: 16
}

export const DRAGGABLE_COLLECTION_SMALL_DIMS = {
  itemWidth: 128,
  itemHeight: 76,
  marginWidth: 14,
  marginHeight: 16
}

export const DRAGGABLE_SECTION_DIMS = {
  itemWidth: 310,
  itemHeight: 260,
  marginWidth: 10,
  marginHeight: 16
}

export const NOWRAP = { whiteSpace: 'nowrap' }
export const WRAP_WORD = {
  whiteSpace: 'normal',
  wordBreak: 'normal',
  overflowWrap: 'anywhere'
}
