import { useResourceRQ } from 'hooks'

import {
  plateEndpoint,
  plateListEndpoint,
  plateTransferEndpoint
} from 'api/endpoints'

const useAdminPlate = plateId => {
  const {
    create: createPlate,
    data: plate,
    update: updatePlate,
    isLoading: isLoadingPlate,
    isError: isPlateError,
    error: plateError
  } = useResourceRQ({
    endpoint: plateEndpoint,
    args: [plateId],
    name: 'plate',
    fetchingEnabled: false,
    dependents: [plateListEndpoint]
  })

  const { destroy: destroyPlate } = useResourceRQ({
    endpoint: plateEndpoint,
    args: [plateId],
    fetchingEnabled: false
  })

  const { operate: transfer } = useResourceRQ({
    endpoint: plateTransferEndpoint,
    args: [plateId],
    fetchingEnabled: false,
    dependents: [plateListEndpoint],
    name: 'plate'
  })

  return {
    plate,
    updatePlate,
    createPlate,
    isLoadingPlate,
    isPlateError,
    plateError,
    destroyPlate,

    transfer
  }
}

export default useAdminPlate
