import React from 'react'
import PropTypes from 'prop-types'
import OrderStepperSvgIcon from './OrderStepperSvgIcon'

const OrderStepperSvg = ({
  className,
  step1Color,
  step2Color,
  step3Color,
  iconColor,
  iconName
}) => {
  return (
    <svg
      className={className}
      width="159"
      height="42"
      viewBox="0 0 159 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41 23L80 23"
        stroke={step1Color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80 23L115 23"
        stroke={step2Color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115 23L155 23"
        stroke={step3Color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="77.4815"
        cy="23"
        rx="3.5"
        ry="3.48148"
        transform="rotate(-90 77.4815 23)"
        fill={step1Color}
      />
      <ellipse
        cx="116.442"
        cy="23"
        rx="3.5"
        ry="3.48148"
        transform="rotate(-90 116.442 23)"
        fill={step2Color}
      />
      <path
        d="M155.401 20C157.046 20 158.383 21.3406 158.383 23C158.383 24.6594 157.046 26 155.401 26C153.757 26 152.42 24.6594 152.42 23C152.42 21.3406 153.757 20 155.401 20Z"
        fill={step3Color}
        stroke={step3Color}
      />
      <circle
        cx="20.9999"
        cy="21.0001"
        r="19.7647"
        transform="rotate(90 20.9999 21.0001)"
        stroke={iconColor}
        strokeWidth="1.85294"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <OrderStepperSvgIcon name={iconName} color={iconColor} />
    </svg>
  )
}

OrderStepperSvg.propTypes = {
  className: PropTypes.string,
  step1Color: PropTypes.string,
  step2Color: PropTypes.string,
  step3Color: PropTypes.string,
  iconColor: PropTypes.string,
  iconName: PropTypes.string
}

export default OrderStepperSvg
