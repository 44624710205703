import { LIST_PATHS } from 'constants/queryParams'
import { useResourceRQ, useResourceListRQ } from 'hooks'
import { categoriesEndpoint, categoriesActiveEndpoint } from 'api/endpoints'

const API_RESOURCE_NAME = 'category'
const ACTIVE_STATUS_PATH = `${LIST_PATHS.CATEGORIES_STATUS}=true`

const useAdminCategories = params => {
  const {
    isFetching: isLoadingCategories,
    data: categories,
    hasData: hasCategories,
    hasNextPage: hasCategoriesNext,
    count: categoriesCount,
    fetchNextPage: listCategoriesNext
  } = useResourceListRQ({ endpoint: categoriesEndpoint, params })

  const { count: activeCategoriesCount } = useResourceListRQ({
    endpoint: categoriesActiveEndpoint,
    args: [ACTIVE_STATUS_PATH]
  })

  const { create: createCategory, update: updateCategory } = useResourceRQ({
    endpoint: categoriesEndpoint,
    name: API_RESOURCE_NAME,
    activeLoaders: true,
    showServerError: true,
    onSuccess: {
      updateMsg: () => 'Category successfully updated',
      createMsg: () => 'Category successfully created'
    },
    dependents: [
      () => categoriesEndpoint(),
      () => categoriesActiveEndpoint(ACTIVE_STATUS_PATH)
    ],
    fetchingEnabled: false
  })

  return {
    isLoadingCategories,
    categories,
    hasCategories,
    hasCategoriesNext,
    categoriesCount,
    listCategoriesNext,
    createCategory,
    updateCategory,
    activeCategoriesCount
  }
}

export default useAdminCategories
