import { useResourceListRQ, useResourceRQ } from 'hooks'
import { LIST_PATHS } from 'constants/queryParams'
import { statusCodesActiveEndpoint, statusCodesEndpoint } from 'api/endpoints'

const API_RESOURCE_NAME = 'status_code'

const useAdminStatusCodes = params => {
  const STATUS_CODES_ACTIVE = `${LIST_PATHS.STATUS_CODES_STATUS}=true`

  const {
    isLoading: isLoadingStatusCodes,
    data: statusCodes,
    hasData: hasStatusCodes,
    hasNextPage: hasStatusCodesNext,
    count: statusCodesCount,
    fetchNextPage: listStatusCodesNext
  } = useResourceListRQ({
    endpoint: statusCodesEndpoint,
    params
  })

  const {
    count: activeStatusCodesCount,
    list: listActiveStatusCodes
  } = useResourceListRQ({
    endpoint: () => statusCodesActiveEndpoint(STATUS_CODES_ACTIVE)
  })

  const { create: createStatusCode, update: updateStatusCode } = useResourceRQ({
    endpoint: statusCodesEndpoint,
    name: API_RESOURCE_NAME,
    dependents: [
      () => statusCodesEndpoint(),
      () => statusCodesActiveEndpoint(STATUS_CODES_ACTIVE)
    ],
    fetchingEnabled: false,
    activeLoaders: true,
    showServerError: true,
    onSuccess: {
      updateMsg: () => 'Status Code successfully updated',
      createMsg: () => 'Status Code successfully created'
    }
  })

  return {
    isLoadingStatusCodes,
    statusCodes,
    hasStatusCodes,
    hasStatusCodesNext,
    statusCodesCount,
    listStatusCodesNext,
    createStatusCode,
    updateStatusCode,
    listActiveStatusCodes,
    activeStatusCodesCount
  }
}

export default useAdminStatusCodes
