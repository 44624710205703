import { useResourceRQ } from 'hooks'

import { supplierEndpoint, supplierListEndpoint } from 'api/endpoints'

const useAdminSupplier = ({ id, fetchingEnabled = true }) => {
  const {
    create: createSupplier,
    data: supplier,
    update: updateSupplier,
    isLoading: isLoadingSupplier,
    isError: isSupplierError,
    error: supplierError
  } = useResourceRQ({
    endpoint: supplierEndpoint,
    args: [id],
    name: 'supplier',
    fetchingEnabled: fetchingEnabled ? Boolean(id) : false,
    dependents: [supplierListEndpoint]
  })

  const { destroy: destroySupplier } = useResourceRQ({
    endpoint: supplierEndpoint,
    args: [id],
    fetchingEnabled: false,
    dependents: [supplierListEndpoint]
  })

  return {
    supplier,
    updateSupplier,
    createSupplier,
    isLoadingSupplier,
    isSupplierError,
    supplierError,
    destroySupplier
  }
}

export default useAdminSupplier
