import { centsToDollars } from 'utils/general'

export const getPromoCodeCaption = code => {
  const {
    amount,
    code_type: codeType,

    eligible_users_ids: eligibleUsers,
    eligible_products_ids: eligibleProducts,
    eligible_collection_ids: eligibleCollections,

    first_time_only: firstTimeOnly,
    once_per_customer: onePerCustomer,

    min_purchase_amount: minPurchaseAmount,

    max_uses
  } = code

  const usageLimits = {
    onePerCustomer,
    firstTimeOnly
  }

  const codeTypeLabel =
    codeType &&
    {
      percentage: `${amount}% Off`,
      free_shipping: 'Free Shipping',
      fixed: `$${centsToDollars(amount)} Off`
    }[codeType]

  const usageLimitLabel = Object.keys(usageLimits)
    .map(
      key =>
        Boolean(usageLimits[key]) &&
        {
          firstTimeOnly: 'New Customers',
          onePerCustomer: 'One Time'
        }[key]
    )
    .filter(Boolean)
    .join(', ')
    .trim()

  const minPurchaseAmountLabel =
    minPurchaseAmount && `Min Req: $${centsToDollars(minPurchaseAmount)}`

  const eligibleUsersLabel = eligibleUsers?.length && 'Specific Users'

  const eligibleProductsLabel = eligibleProducts?.length && 'Specific Products'

  const eligibleCollectionsLabel =
    eligibleCollections?.length && 'Specific Products'

  const maxUsesLabel = Boolean(max_uses) && `${max_uses} max uses`

  return [
    codeTypeLabel,
    usageLimitLabel,
    minPurchaseAmountLabel,
    maxUsesLabel,
    eligibleUsersLabel,
    eligibleProductsLabel,
    eligibleCollectionsLabel
  ]
    .filter(Boolean)
    .join(', ')
    .trim()
}
