const jssStyles = theme => ({
  active: {
    fill: theme.palette.blues.fast,
    '& path:first-child': {
      fill: theme.palette.common.white
    }
  },
  inactive: {
    opacity: '40%',
    fill: theme.palette.common.black,
    '& path:first-child': {
      fill: theme.palette.common.white
    }
  },
  clickable: {
    cursor: 'pointer'
  }
})

export default jssStyles
