import axios from 'axios'
import mime from 'mime-types'

import { URL } from 'constants/navigation'
import { SERVER_URL } from 'lib/config'
import browserHistory from 'browserHistory'

const DEFAULT_TIMEOUT_MS = 200000

const api = axios.create({
  baseURL: SERVER_URL,
  headers: { 'Content-Type': 'application/json' },
  timeout: DEFAULT_TIMEOUT_MS
})

api.interceptors.response.use(
  resp => Promise.resolve(resp.data),
  error => {
    if (error?.response?.status === 401) {
      browserHistory.push(URL.LOGOUT)
    }
    return Promise.reject(error.response || error)
  }
)

const downloadApi = axios.create({
  baseURL: SERVER_URL,
  responseType: 'blob',
  timeout: DEFAULT_TIMEOUT_MS
})

downloadApi.interceptors.response.use(
  resp => {
    const url = window.URL.createObjectURL(new Blob([resp.data]))
    try {
      const filenameMatcher = resp.headers['content-disposition']?.match(
        /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/
      )
      // use file name from content-disposition if it exists; otherwise, figure out extension from content-type
      const filename = filenameMatcher
        ? filenameMatcher[1]
        : `download.${mime.extensions(resp.headers['content-type'])}`
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      link.click()
    } finally {
      window.URL.revokeObjectURL(url)
    }
  },
  error => {
    if (error?.response?.status === 401) {
      browserHistory.push(URL.LOGOUT)
      return
    }
    console.error(`Download failed with status ${error?.response?.status}`)
  }
)

export { api, downloadApi }
