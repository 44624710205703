import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core'

import Drawer from 'components/Drawer'
import Button from 'components/Button'

import MemoList from 'components/MemoList'

import styles from './CreditHistoryDrawerStyles'

const EditStoreCreditDrawer = ({ classes, open, onClose, memos }) => (
  <Drawer
    title="Credit Update History"
    open={open}
    onClose={onClose}
    actions={
      <Button fullWidth onClick={onClose}>
        Done
      </Button>
    }
  >
    <Box className={classes.container}>
      <MemoList memos={memos} className={classes.memos} />
    </Box>
  </Drawer>
)

EditStoreCreditDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  memos: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default withStyles(styles)(EditStoreCreditDrawer)
