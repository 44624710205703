import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { formatPhoneNumber, renderAddress } from 'utils/general'
import UserAvatar from 'components/UserAvatar'

import styles from './CustomerDetailsStyles'

const CustomerDetails = ({ classes, user, onClick }) => (
  <Box className={classes.root}>
    <ButtonBase
      className={classNames({
        [classes.customerContainer]: true,
        [classes.item]: true
      })}
      onClick={e => onClick && onClick(e, user)}
      onAuxClick={e => onClick && onClick(e, user, true)}
    >
      <Box className={classes.customerAvatarInfo}>
        <UserAvatar className={classes.customerAvatar} src={user?.image_url} />
        <Box>
          <Typography variant="body1">{user?.name}</Typography>
          <Typography variant="subtitle1" className={classes.orderCount}>
            {user?.total_orders}
          </Typography>
        </Box>
      </Box>
      <ArrowForwardIosIcon className={classes.forwardArrow} />
    </ButtonBase>
    <Box className={classes.divider} />
    <Box className={classes.item}>
      <Typography className={classes.caption} variant="caption">
        Email
      </Typography>
      <Typography variant="body1">{user?.email}</Typography>
    </Box>
    <Box className={classes.item}>
      <Typography className={classes.caption} variant="caption">
        Phone
      </Typography>
      <Typography variant="body1">{formatPhoneNumber(user?.phone)}</Typography>
    </Box>
    <Box className={classes.item}>
      <Typography className={classes.caption} variant="caption">
        Address
      </Typography>
      <Typography variant="body1">{renderAddress(user)}</Typography>
    </Box>
  </Box>
)

CustomerDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  onClick: PropTypes.func
}

export default withStyles(styles)(CustomerDetails)
