const jssStyles = theme => ({
  blue: {
    color: `${theme.palette.tierBadge.blue}!important`
  },
  bronze: {
    color: `${theme.palette.tierBadge.bronze}!important`
  },
  silver: {
    color: `${theme.palette.tierBadge.silver}!important`
  },
  gold: {
    color: `${theme.palette.tierBadge.gold}!important`
  },
  platinum: {
    color: `${theme.palette.tierBadge.platinum}!important`
  },
  employee: {
    color: `${theme.palette.tierBadge.employee}!important`
  },
  investor: {
    color: `${theme.palette.tierBadge.investor}!important`
  }
})

export default jssStyles
