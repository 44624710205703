import { URL as SITE_URLS } from './navigation'

const {
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ADMIN_USERS,
  ADMIN_USERS_EDIT,
  ADMIN_ORDERS,
  ADMIN_PRODUCTS,
  ADMIN_INVENTORY,
  ADMIN_COLLECTIONS,
  ADMIN_BRANDS,
  ADMIN_BRAND_CUSTOMERS,

  // BOH,
  ADMIN_BOH,
  ADMIN_COLORS,
  ADMIN_AREAS,
  ADMIN_PURCHASE_ORDERS,
  ADMIN_STOREFRONT,
  ADMIN_HOURS_OF_OPERATION,
  ADMIN_DELIVERED_ORDERS,
  ADMIN_PROMO_CODES,
  ADMIN_PROMO_CODES_FORM,
  ADMIN_PROMO_CODES_HISTORY,
  ADMIN_BADGES,
  ADMIN_STATUS_CODES,
  ADMIN_SIZES,
  ADMIN_ACCESSORY_OPTIONS,
  ADMIN_CATEGORIES
} = SITE_URLS

export const SITE_NAME = 'FastAF'
export const DEFAULT_TITLE_SUFFIX =
  'Shop the top brands with the fastest delivery'
export const DEFAULT_TITLE = `${SITE_NAME} - ${DEFAULT_TITLE_SUFFIX}`

export const PAGE_TITLES = {
  [LOGIN]: 'Login',
  [LOGOUT]: 'Logout of FastAF Dashboard',
  [FORGOT_PASSWORD]: 'Forgot Password',
  [RESET_PASSWORD]: 'Reset your Password',
  [ADMIN_USERS]: 'Search & Manage Users',
  [ADMIN_USERS_EDIT]: 'Edit User',
  [ADMIN_ORDERS]: 'Orders',
  [ADMIN_PRODUCTS]: 'Products',
  [ADMIN_INVENTORY]: 'Inventory',
  [ADMIN_COLLECTIONS]: 'Collections',
  [ADMIN_BRANDS]: 'Brand Dashboard',
  [ADMIN_BRAND_CUSTOMERS]: 'View Customers by Brand',

  // BOH
  [ADMIN_BOH]: 'Back of House',
  [ADMIN_COLORS]: 'Colorways',
  [ADMIN_AREAS]: 'Service Areas',
  [ADMIN_PURCHASE_ORDERS]: 'Purchase Orders',
  [ADMIN_STOREFRONT]: 'Storefront',
  [ADMIN_HOURS_OF_OPERATION]: 'Hours of Operation',
  [ADMIN_DELIVERED_ORDERS]: 'Delivered Orders',
  [ADMIN_PROMO_CODES]: 'Promo Codes',
  [ADMIN_PROMO_CODES_FORM]: 'Edit Promo Codes',
  [ADMIN_PROMO_CODES_HISTORY]: 'Audit Promo Code History',
  [ADMIN_BADGES]: 'Badges',
  [ADMIN_STATUS_CODES]: 'Status Codes',
  [ADMIN_SIZES]: 'Sizes',
  [ADMIN_ACCESSORY_OPTIONS]: 'Accessory Options',
  [ADMIN_CATEGORIES]: 'Categories'
}
