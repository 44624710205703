import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import Header from 'components/Header'
import Button from 'components/Button'
import Tabs from 'components/Tabs'
import GlanceTile from 'components/GlanceTile'
import CreateNotificationModal, { FIELDS } from 'components/CreateNotificationModal';
import NotificationPreviewModal from 'components/NotificationPreviewModal';
import { useLoaders, useAdminNotifications } from 'hooks'
import NotificationsPane from './panes/NotificationsPane';
import AnalyticsPane from './panes/AnalyticsPane';

import AddIcon from '@material-ui/icons/Add'
import { SIZE } from 'constants/enums'
import styles from './NotificationCenterStyles'

const NotificationCenter = ({ classes }) => {

  const [createModalIsActive, setCreateModalIsActive] = useState(false);
  const [previewModalIsActive, setPreviewModalIsActive] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(false);
  const [hasPublishError, setHasPublishError] = useState(false);

  const { showLoading, hideLoading } = useLoaders()

  const {
    createNotification,
    fetchNotifs,
    notifs,
    notifCount,
    isLoadingNotifs,
    hasNotifs,
    hasNotifsNext,
    fetchNotifsNext
  } = useAdminNotifications();

  const dataTablePaginationUtils = {
    notifCount,
    isLoadingNotifs,
    hasNotifs,
    hasNotifsNext,
    fetchNotifsNext,
  }

  const refreshNotificationData = async () => {
    showLoading()
    await fetchNotifs()
    hideLoading()
  }

  useEffect(() => {
    (async () => {
      await refreshNotificationData()
    })()
  }, [])

  const onNotificationCreate = (notification) => {
    if (notification) {
      setCurrentNotification(notification)
      setPreviewModalIsActive(true)
    }
  }

  const onNotificationPublish = async () => {
    setHasPublishError(false);
    const isScheduled = !currentNotification[FIELDS.deliverImmediately]

    let payload = {
      [FIELDS.title]: currentNotification[FIELDS.title],
      [FIELDS.message]: currentNotification[FIELDS.message],
      [FIELDS.pathDest]: currentNotification[FIELDS.pathDest],
      [FIELDS.deliverImmediately]: currentNotification[FIELDS.deliverImmediately],
      ...!!currentNotification[FIELDS.iconImage] && {[FIELDS.iconImage]: currentNotification[FIELDS.iconImage]},
      ...isScheduled && {
        [FIELDS.deliveryDate]: currentNotification[FIELDS.deliveryDate],
        [FIELDS.deliveryTime]: currentNotification[FIELDS.deliveryTime],
        [FIELDS.deliveryTimezone]: currentNotification[FIELDS.deliveryTimezone],
      }
    }

    const res = await createNotification(payload);

    if (!!res && !!res?.id) {
      await refreshNotificationData()
      setCreateModalIsActive(false)
      setPreviewModalIsActive(false)
    } else {
      setHasPublishError(true)
    }
  }

  const headerActions = (
    <Button
      adaptive
      label="new notification button"
      startIcon={<AddIcon />}
      color="primary"
      variant="contained"
      size={SIZE.medium}
      onClick={() => setCreateModalIsActive(true)}
      className={classes.headerButton}
      dataTest="create-notification-button"
    >
      New Notification
    </Button>
  )

  // "Scheduled" value is hardcoded to 0
  // b/c we don't support scheduling notifs yet
  const headerSummarizers = (
    <>
      <GlanceTile
        minimal
        label="Sent"
        value={notifCount}
      />
      <GlanceTile
        minimal
        label="Scheduled"
        value={0}
      />
    </>
  )

  const getTabPanes = () => [
    {
      id: 0,
      label: "Notifications",
      pane: (
        <NotificationsPane
          headerSummarizers={headerSummarizers}
          notifications={notifs}
          {...dataTablePaginationUtils}
        />
      )
    },
    {
      id: 1,
      label: "Analytics",
      pane: (
        <AnalyticsPane
          headerSummarizers={headerSummarizers}
          notifications={notifs}
          {...dataTablePaginationUtils}
        />
      )
    }
  ]

  return (
    <Box width="100%" height="100%">
      <Header
        title="Notification Center"
        actions={headerActions}
      />
      <Tabs
        defaultTabIndex={0}
        panes={getTabPanes()}
      />
      <CreateNotificationModal
        onClose={() => setCreateModalIsActive(false)}
        onSubmit={onNotificationCreate}
        isActive={createModalIsActive}
        hideBody={previewModalIsActive}
      />
      <NotificationPreviewModal
        onClose={() => setPreviewModalIsActive(false)}
        onSubmit={onNotificationPublish}
        notification={currentNotification}
        isActive={previewModalIsActive}
        hasPublishError={hasPublishError}
      />
    </Box>
  )
}

export default withStyles(styles)(NotificationCenter)
