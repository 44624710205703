import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import classNames from 'classnames'
import moment from 'moment-timezone'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import Typography from '@material-ui/core/Typography'

import { useAuthContext } from 'context'

import { SIZE, COLOR } from 'constants/enums'
import { MEMO_MAX_LENGTH } from 'constants/general'

import Button from 'components/Button'
import TextField from 'components/TextField'

import styles from './MemoContainerStyles'

const MemoContainer = ({
  classes,
  className,
  memo,
  inputProps,
  defaultActive,
  placeholder,
  name,
  disabled,
  selfContainedStyles
}) => {
  const { errors, formState } = useFormContext()
  const formValue = useWatch({ name })
  const { currentUser } = useAuthContext()

  const [body, setBody] = useState('')
  const [isInputActive, setIsInputActive] = useState(defaultActive)

  const handleTextChange = e => setBody(e.target.value)
  const handleCancel = () => {
    setBody(memo?.body)
    setIsInputActive(false)
  }

  const memoBody = memo?.body || body

  // Clear the memo if the form value was cleared
  useEffect(() => {
    if (formState.submitCount !== 0 && formValue === '' && body !== '') {
      setBody('')
    }
  }, [formValue, body, formState])

  return (
    <Controller
      name={name}
      render={({ onChange }) => (
        <Box
          className={classNames({
            [className]: Boolean(className),
            [classes.memoContainer]: selfContainedStyles, // includes the border
            [classes.errorContainer]: errors[name]?.message
          })}
        >
          <Box className={classes.header}>
            <Typography variant="body2">Memo</Typography>
            <Box flex={1} />
            {isInputActive ? (
              <>
                <Button
                  color={COLOR.default}
                  variant="outlined"
                  size={SIZE.small}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Box width={12} />
                <Button
                  dataTest="memo-done-button"
                  color={COLOR.primary}
                  variant="outlined"
                  size={SIZE.small}
                  onClick={() => {
                    setIsInputActive(false)
                    onChange(body)
                  }}
                >
                  Done
                </Button>
              </>
            ) : (
              <Button
                dataTest="memo-add-button"
                disabled={disabled}
                color={COLOR.default}
                variant="outlined"
                size={SIZE.small}
                startIcon={
                  !memoBody ? (
                    <AddIcon fontSize={SIZE.small} />
                  ) : (
                    <EditIcon fontSize={SIZE.small} />
                  )
                }
                onClick={() => setIsInputActive(true)}
              >
                {!memoBody ? 'Add' : 'Edit'}
              </Button>
            )}
          </Box>

          {!isInputActive && memoBody && (
            <Box>
              <Typography
                variant="body1"
                component="div"
                className={classes.content}
              >
                {memoBody}
              </Typography>
              <Typography variant="body1" className={classes.contentInfo}>
                {memo?.author ?? currentUser?.name},{' '}
                {moment(memo?.created_at).calendar()}
              </Typography>
            </Box>
          )}

          {isInputActive && (
            <TextField
              dataTest="memo-text-field"
              value={body}
              classes={{ input: classes.input }}
              className={classes.contentEdit}
              multiline
              autoFocus={!defaultActive}
              onChange={handleTextChange}
              inputProps={inputProps}
              placeholder={placeholder}
              error={Boolean(errors[name])}
              helperText={errors[name] && errors[name].message}
              disabled={disabled}
            />
          )}

          <Typography
            className={classNames({
              [classes.charCount]: true,
              [classes.maxCount]: body?.length === MEMO_MAX_LENGTH
            })}
            variant="body1"
          >
            {body?.length ?? 0} / {MEMO_MAX_LENGTH}
          </Typography>
        </Box>
      )}
    />
  )
}

MemoContainer.defaultProps = {
  memo: {
    body: '',
    created_at: Date.now()
  },
  defaultActive: false,
  placeholder: 'Add notes here',
  disabled: false,
  selfContainedStyles: true
}

MemoContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  memo: PropTypes.object,
  onSubmit: PropTypes.func,
  inputProps: PropTypes.object,
  defaultActive: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selfContainedStyles: PropTypes.bool
}

export default withStyles(styles)(MemoContainer)
