const jssStyles = theme => ({
  submit: {
    marginTop: theme.spacing(4)
  },
  subtitle: {
    color: theme.palette.grays.dark,
    marginTop: 4,
    marginBottom: theme.spacing(2)
  },
  requirements: {
    fontWeight: 700
  },
  requirementsDescription: {
    color: theme.palette.grays.dark
  }
})

export default jssStyles
