import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import uniqBy from 'lodash/uniqBy'
import { useFormContext, Controller } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

import { useAdminCollectionsList, useAdminCollectionGroupsList } from 'hooks'

import AutocompleteSearch from 'components/AutocompleteSearch'
import UnknownProduct from 'assets/unknown_product.jpg'

import styles from './AutocompleteSearchCollectionsStyles'

const getOptionLabel = opt => opt?.title

const CollectionSearch = ({ queryProps, ...rest }) => {
  const [query, setQuery] = useState('')

  const {
    isLoadingCollections,
    collections,
    hasCollectionsNext,
    fetchCollectionsNext
  } = useAdminCollectionsList({ search: query, ...queryProps })

  return (
    <AutocompleteSearchCollectionsRaw
      setQuery={setQuery}
      isLoading={isLoadingCollections}
      items={collections}
      hasNext={hasCollectionsNext}
      fetchNext={fetchCollectionsNext}
      placeholder="Search Collections"
      {...rest}
    />
  )
}

CollectionSearch.defaultProps = {
  queryProps: {}
}

CollectionSearch.propTypes = {
  queryProps: PropTypes.object
}

const CollectionGroupSearch = ({ queryProps, ...rest }) => {
  const [query, setQuery] = useState('')

  const {
    isLoadingCollectionGroups,
    collectionGroups,
    hasCollectionGroupsNext,
    fetchCollectionGroupsNext
  } = useAdminCollectionGroupsList({ search: query, ...queryProps })

  return (
    <AutocompleteSearchCollectionsRaw
      setQuery={setQuery}
      isLoading={isLoadingCollectionGroups}
      items={collectionGroups}
      hasNext={hasCollectionGroupsNext}
      fetchNext={fetchCollectionGroupsNext}
      placeholder="Search Collection Groups"
      {...rest}
    />
  )
}

CollectionGroupSearch.defaultProps = {
  queryProps: {}
}

CollectionGroupSearch.propTypes = {
  queryProps: PropTypes.object
}

const AutocompleteSearchCollectionsRaw = ({
  name,
  classes,
  onChange,
  value,
  hiddenItems,
  multiple,
  setQuery,
  isLoading,
  hasNext,
  fetchNext,
  placeholder,
  items
}) => {
  const hiddenIdsSet = useMemo(
    () => new Set((hiddenItems ?? []).map(c => c.id)),
    [hiddenItems]
  )

  const options = useMemo(
    () =>
      uniqBy(
        (items ?? []).filter(c => !hiddenIdsSet.has(c.id)),
        v => v.id
      ),
    [items, hiddenIdsSet, value]
  )

  return (
    <AutocompleteSearch
      name={name}
      options={options}
      value={value}
      loading={isLoading}
      setQuery={setQuery}
      onChange={onChange}
      onScrollBottom={hasNext ? fetchNext : undefined}
      placeholder={placeholder}
      multiple={multiple}
      getOptionLabel={getOptionLabel}
      itemHeight={92}
      renderOption={(option, { selected }) => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          className={selected ? classes.selectedItem : null}
        >
          <Box
            display="flex"
            flexDirection="row"
            height={1}
            alignItems="center"
          >
            <Box height={52} width={88}>
              <img
                src={option.image_url ?? UnknownProduct}
                alt={option.title}
                className={classes.image}
              />
            </Box>
            <Box ml={2}>
              <Chip
                label={<Typography>{option.id}</Typography>}
                color="secondary"
              />
              {option.is_parent && (
                <Chip
                  label={<Typography>Parent</Typography>}
                  color="secondary"
                />
              )}
              {option.parent_collection_id && (
                <Chip
                  label={
                    <Typography>
                      Parent collection: {option.parent_collection_id}
                    </Typography>
                  }
                  color="secondary"
                />
              )}
            </Box>
          </Box>

          <Typography variant="body1" className={classes.userName}>
            {option.title}
          </Typography>
        </Box>
      )}
    />
  )
}

AutocompleteSearchCollectionsRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ]),
  hiddenItems: PropTypes.arrayOf(PropTypes.object),
  multiple: PropTypes.bool,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  isLoading: PropTypes.bool,
  hasNext: PropTypes.bool,
  fetchNext: PropTypes.func,
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object)
}

const AutocompleteSearchCollections = withStyles(styles)(CollectionSearch)

const AutocompleteSearchCollectionGroups = withStyles(styles)(
  CollectionGroupSearch
)

const AutocompleteSearchCollectionsControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchCollections
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchCollectionsControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchCollections as default,
  AutocompleteSearchCollectionsControlled,
  AutocompleteSearchCollectionGroups
}
