import React from 'react'
import PropTypes from 'prop-types'

const ReorderIcon = ({ color, size, className }) => (
  <svg
    width={size}
    height={size}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 13.25h7.5M7.5 5.75H15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="12.75" cy="13.25" r="2" stroke={color} strokeWidth="2" />
    <circle cx="4.5" cy="5.75" r="2" stroke={color} strokeWidth="2" />
  </svg>
)

ReorderIcon.defaultProps = {
  size: 18,
  color: 'currentColor'
}

ReorderIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
}

export default ReorderIcon
