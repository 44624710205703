const jssStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiInputBase-root': {
      height: 60
    }
  },
  addButton: {
    height: 33,
    margin: theme.spacing(0, 1, 0, 0)
  },
  selectedCodes: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  }
})
export default jssStyles
