import * as yup from 'yup'
import fromPairs from 'lodash/fromPairs'
import { emptyStringToNull } from './products'

export const LIFE_CYCLE_STATUSES = [
  { label: 'New', value: 'new' },
  { label: 'NPI', value: 'npi' },
  { label: 'Active', value: 'active' },
  { label: 'Discontinued', value: 'discontinued' },
  { label: 'Inactive', value: 'inactive' }
]

export const VARIANT_FORM_FIELDS_FULL = {
  TITLE: {
    name: 'title',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup.string().required()
  },
  SELECT_IMAGES: {
    name: 'select_images',
    showFieldOnNewVariant: false,
    showFieldOnExistingVariant: true,
    schema: yup.array()
  },
  COLOR: {
    name: 'color_id',
    showFieldOnNewVariant: false,
    showFieldOnExistingVariant: true,
    schema: yup.object()
  },
  SIZE: {
    name: 'size_id',
    showFieldOnNewVariant: false,
    showFieldOnExistingVariant: true,
    schema: yup.object()
  },
  ACCESSORY: {
    name: 'accessory_option_id',
    showFieldOnNewVariant: false,
    showFieldOnExistingVariant: true,
    schema: yup.object()
  },
  MSRP: {
    name: 'msrp',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup
      .number()
      .required()
      .typeError('Please include an MSRP')
  },
  COMPARE_AT_PRICE: {
    name: 'compare_at_price',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
  },
  COST_PER_ITEM: {
    name: 'cost_per_item',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
  },
  VENDOR_STYLE_NUMBER: {
    name: 'vendor_style_number',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup.string()
  },
  SKU: {
    name: 'sku',
    showFieldOnNewVariant: false,
    showFieldOnExistingVariant: true,
    schema: yup.string().required()
  },
  UPC: {
    name: 'barcode',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup.string()
  },
  DSIN: {
    name: 'dsin',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup.string()
  },
  BIN: {
    name: 'bin',
    showFieldOnNewVariant: false,
    showFieldOnExistingVariant: true,
    schema: yup.string()
  },
  UNIT_OF_MEASURE: {
    name: 'unit',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup.string()
  },
  LENGTH: {
    name: 'length',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
  },
  WIDTH: {
    name: 'width',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
  },
  HEIGHT: {
    name: 'height',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
  },
  WEIGHT: {
    name: 'weight',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
  },
  HOLIDAY: {
    name: 'holiday',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup.bool()
  },
  DO_NOT_BUY: {
    name: 'do_not_buy',
    showFieldOnNewVariant: false,
    showFieldOnExistingVariant: true,
    schema: yup.bool()
  },
  CRYPTO_MERCH: {
    name: 'crypto_merch',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup.bool()
  },
  SAMPLE: {
    name: 'sample',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup.bool()
  },
  SAMPLE_APPROVED: {
    name: 'sample_status',
    showFieldOnNewVariant: false,
    showFieldOnExistingVariant: true,
    schema: yup.string()
  },
  PULL_DATE_OFFSET: {
    name: 'pull_date_offset',
    showFieldOnNewVariant: true,
    showFieldOnExistingVariant: true,
    schema: yup
      .number()
      .transform(emptyStringToNull)
      .nullable()
  },
  LIFE_CYCLE_STATUS: {
    name: 'life_cycle_status',
    showFieldOnNewVariant: false,
    showFieldOnExistingVariant: true,
    schema: yup
      .string()
      .matches(LIFE_CYCLE_STATUSES.map(status => status.value).join('|'))
  }
}

export const FORM_FIELDS = fromPairs(
  Object.entries(VARIANT_FORM_FIELDS_FULL).map(([key, { name }]) => [key, name])
)
export const NEW_VARIANT_FIELDS = Object.values(VARIANT_FORM_FIELDS_FULL)
  .filter(v => v.showFieldOnNewVariant)
  .map(v => v.name)
export const EXISTING_VARIANT_FIELDS = Object.values(VARIANT_FORM_FIELDS_FULL)
  .filter(v => v.showFieldOnExistingVariant)
  .map(v => v.name)
export const NEW_VARIANT_SCHEMA = fromPairs(
  Object.values(VARIANT_FORM_FIELDS_FULL)
    .filter(v => v.showFieldOnNewVariant)
    .map(({ name, schema }) => [name, schema])
)
export const EXISTING_VARIANT_SCHEMA = fromPairs(
  Object.values(VARIANT_FORM_FIELDS_FULL)
    .filter(v => v.showFieldOnExistingVariant)
    .map(({ name, schema }) => [name, schema])
)
