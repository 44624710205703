const jssStyles = theme => ({
  bordered: {
    border: 'solid 2px'
  },
  mapPlaceholder: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grays.light,
    color: theme.palette.grays.dark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  highlightedDot: {
    opacity: 1,
  },
  zipcodeDot: {
    opacity: .5
  }
})

export default jssStyles
