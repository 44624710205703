import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { thinInput } from 'constants/styles'

import { Box, Typography } from '@material-ui/core'

import SaveIcon from '@material-ui/icons/Save'
import AddIcon from '@material-ui/icons/Add'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { SIZE, COLOR } from 'constants/enums'
import { URL } from 'constants/navigation'
import { countriesWithCodes, phones } from 'constants/localization'
import {
  SUPPLIER_SCHEMA,
  SUPPLIER_FORM_DEFAULTS,
  SUPPLIER_FIELDS,
  SUPPLIER_PAYMENT_TERMS_OPTIONS
} from 'constants/suppliers'
import {
  useBeforeUnload,
  useLoaders,
  useAdminSupplier,
  useConditionalEffect,
  useNavigation,
  useMediaQuery,
  usePrevious
} from 'hooks'

import { TextFieldControlled } from 'components/TextField'
import { SelectField } from 'components/Select'
import { TitleElement } from 'components/SiteTitle'
import ControlledForm, { useControlledForm } from 'components/ControlledForm'
import { AutocompleteSearchStateControlled } from 'components/AutocompleteSearchState'
import { AutocompleteSearchCountryControlled } from 'components/AutocompleteSearchCountry'
import Button from 'components/Button'
import Header from 'components/Header'
import Layout from 'components/Layout'

import DistributionCentersList from './DistributionCentersList'

const SupplierFormFields = ({ classes }) => {
  const { isMobileScreen } = useMediaQuery()
  const {
    formContext: { getValues, setValue }
  } = useControlledForm()

  const country = getValues(SUPPLIER_FIELDS.country)
  const prevCountry = usePrevious(country)
  useConditionalEffect(() => {
    if (!!prevCountry && country !== prevCountry) {
      Object.values(countriesWithCodes).every(thisCountry => {
        if (thisCountry === country) {
          setValue(SUPPLIER_FIELDS.country_code, phones[country])
          return false
        } else return true
      })
    }
  }, [country])

  const inlineFieldContainerStyles = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    flexDirection: isMobileScreen ? 'column' : 'row'
  }

  const inlineFieldStyles = {
    width: '100%',
    mr: isMobileScreen ? 0 : 2
  }

  const textFieldProps = {
    className: classes.thinInput,
    fullWidth: true
  }

  return (
    <>
      <Box>
        <Typography variant="h3">Name</Typography>
        <TextFieldControlled name={SUPPLIER_FIELDS.name} {...textFieldProps} />
      </Box>
      <Box mb={2}>
        <Typography variant="h3">Payment Terms</Typography>
        <SelectField
          multiple={false}
          name={SUPPLIER_FIELDS.payment_terms}
          items={SUPPLIER_PAYMENT_TERMS_OPTIONS}
          {...textFieldProps}
        />
      </Box>
      <Box {...inlineFieldContainerStyles}>
        <Box {...inlineFieldStyles}>
          <Typography variant="h3">Address Line 1</Typography>
          <TextFieldControlled
            name={SUPPLIER_FIELDS.address1}
            {...textFieldProps}
          />
        </Box>
        <Box width="100%">
          <Typography variant="h3">Address Line 2</Typography>
          <TextFieldControlled
            name={SUPPLIER_FIELDS.address2}
            {...textFieldProps}
          />
        </Box>
      </Box>
      <Box {...inlineFieldContainerStyles}>
        <Box {...inlineFieldStyles}>
          <Typography variant="h3">City</Typography>
          <TextFieldControlled
            name={SUPPLIER_FIELDS.city}
            {...textFieldProps}
          />
        </Box>
        <Box {...inlineFieldStyles}>
          <Typography variant="h3">State</Typography>
          <Box mt={1} />
          <AutocompleteSearchStateControlled
            name={SUPPLIER_FIELDS.state}
            {...textFieldProps}
          />
        </Box>
        <Box {...inlineFieldStyles}>
          <Typography variant="h3">Zip</Typography>
          <TextFieldControlled
            name={SUPPLIER_FIELDS.zipcode}
            {...textFieldProps}
          />
        </Box>
        <Box {...inlineFieldStyles}>
          <Typography variant="h3">Country</Typography>
          <Box mt={1} />
          <AutocompleteSearchCountryControlled
            name={SUPPLIER_FIELDS.country}
            {...textFieldProps}
          />
        </Box>
      </Box>
      <Box {...inlineFieldContainerStyles}>
        <Box {...inlineFieldStyles}>
          <Typography variant="h3">Point of Contact</Typography>
          <TextFieldControlled
            name={SUPPLIER_FIELDS.point_of_contact}
            {...textFieldProps}
          />
        </Box>
        <Box {...inlineFieldStyles} maxWidth={124}>
          <Typography variant="h3">Country Code</Typography>
          <TextFieldControlled
            startAdornment="+"
            name={SUPPLIER_FIELDS.country_code}
            {...textFieldProps}
          />
        </Box>
        <Box width="100%">
          <Typography variant="h3">Phone</Typography>
          <TextFieldControlled
            name={SUPPLIER_FIELDS.phone}
            {...textFieldProps}
          />
        </Box>
      </Box>
    </>
  )
}

const SupplierForm = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const FORM_TYPE = id === 'new' ? 'new' : 'update'
  const SUPPLIER_ID = FORM_TYPE === 'new' ? null : id

  const { go } = useNavigation({})
  const { showLoading, hideLoading } = useLoaders()
  const { supplier, updateSupplier, createSupplier } = useAdminSupplier({
    id: SUPPLIER_ID
  })

  const [submitting, setSubmitting] = useState(false)
  const [formDefaults, setFormDefaults] = useState(SUPPLIER_FORM_DEFAULTS)

  const constructFormDefaultsFromSupplier = props => props

  useConditionalEffect(
    () =>
      !isEmpty(supplier) &&
      setFormDefaults(constructFormDefaultsFromSupplier(supplier)),
    [supplier]
  )

  const handleSubmit = async ({
    name,
    point_of_contact,
    address1,
    address2,
    city,
    country,
    country_code,
    phone,
    state,
    zipcode,
    payment_terms
  }) => {
    setSubmitting(true)
    showLoading()

    const execute = FORM_TYPE === 'new' ? createSupplier : updateSupplier

    const { data: localSupplier } = await execute({
      name,
      point_of_contact,
      address1,
      address2,
      city,
      country,
      country_code,
      phone,
      state,
      zipcode,
      payment_terms
    })

    if (FORM_TYPE === 'new') {
      go({}, `${URL.ADMIN_SUPPLIERS}/${localSupplier.id}`, false)
    }

    constructFormDefaultsFromSupplier(localSupplier)
    setSubmitting(false)
    hideLoading()
  }

  const SaveButton = ({ submitting }) => {
    const {
      handleSubmit: localHandleSubmit,
      formContext: {
        formState: { dirtyFields }
      }
    } = useControlledForm()

    const { OnBeforeUnloadPrompt } = useBeforeUnload(!isEmpty(dirtyFields))

    return (
      <>
        <Button
          label="save supplier button"
          onClick={localHandleSubmit}
          size={SIZE.medium}
          color={COLOR.primary}
          dataTest="supplier-save-button"
          startIcon={id ? <SaveIcon /> : <CheckCircleOutlineIcon />}
          adaptive
          disabled={isEmpty(dirtyFields) || submitting}
          type="submit"
        >
          {FORM_TYPE === 'new' ? 'Create' : 'Save'}
        </Button>
        <OnBeforeUnloadPrompt />
      </>
    )
  }

  const actions = <SaveButton id={id} submitting={submitting} />

  const pageTitle = (supplier?.name && supplier?.name) ?? 'New'

  return (
    <Layout id="SupplierForm">
      <TitleElement title={pageTitle} />
      <ControlledForm
        handleSubmit={handleSubmit}
        schemas={SUPPLIER_SCHEMA}
        defaultValues={formDefaults}
        resetOnSubmit={!id}
        shouldUnregister={false}
      >
        <Header
          sticky
          breadcrumbs={[{ title: 'Suppliers', link: URL.ADMIN_SUPPLIERS }]}
          title={pageTitle}
          actions={actions}
        />

        <Box px={1}>
          <Box mb={2}>
            <Typography variant="h2">
              {supplier?.name ? 'Update Supplier' : 'Create Supplier'}
            </Typography>
          </Box>
          <SupplierFormFields classes={classes} />
        </Box>
      </ControlledForm>
      {FORM_TYPE === 'update' && (
        <Box mx={1}>
          <Box
            mb={2}
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Typography variant="h2">Service Location</Typography>
            <Box>
              <Button
                label="add distribution center"
                onClick={() =>
                  go(
                    {},
                    `${URL.ADMIN_SUPPLIERS}/${supplier.id}/distribution_centers/new`,
                    false
                  )
                }
                size={SIZE.medium}
                color={COLOR.primary}
                dataTest="add-distribution-center"
                startIcon={<AddIcon />}
                adaptive
                type="button"
              >
                Locations
              </Button>
            </Box>
          </Box>
          <DistributionCentersList supplierId={SUPPLIER_ID} />
        </Box>
      )}
    </Layout>
  )
}

SupplierForm.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(theme => ({
  thinInput: thinInput(theme)
}))(SupplierForm)
