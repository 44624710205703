import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import { useMediaQuery } from 'hooks'
import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'

import { BOGO_LABELS, BOGO_TYPES } from 'constants/general'

import UnknownProduct from 'assets/unknown_product.jpg'

import styles from './OrderItemsStyles'

const OrderItems = ({
  classes,
  item,
  showQuantity,
  showSku,
  onClick,
  className,
  isOdd
}) => {
  const image =
    item.images?.[0]?.thumbnail_url ??
    item.product?.images?.[0]?.thumbnail_url ??
    UnknownProduct
  const title = item.product?.title ?? item.variant?.title

  const { isDesktopScreen: isMobile } = useMediaQuery()

  const handleRenderPriceTag = () => {
    if (!isEmpty(item.applied_discount)) {
      if (BOGO_TYPES.includes(item.applied_discount.type)) {
        return (
          <Box
            display="flex"
            marginLeft="auto"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography variant="body2" color="#305BF2">
              {BOGO_LABELS[item.applied_discount.type]}
            </Typography>
          </Box>
        )
      }
    } else {
      return (
        <Box
          display="flex"
          marginLeft="auto"
          alignItems={isMobile ? 'flex-end' : 'center'}
          justifyContent="flex-end"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Box textAlign="center">
            <Typography variant="subtitle1">
              ${item.price} {showQuantity && `x ${item.quantity}`}
            </Typography>
          </Box>
          <Box
            textAlign="right"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            ml={2}
          >
            <Typography variant="subtitle1">
              ${(item.price * item.quantity).toFixed(2)}
            </Typography>
          </Box>
        </Box>
      )
    }
  }

  return (
    <ButtonBase className={classes.buttonBase}>
      <Box
        className={classNames({
          [className]: Boolean(className),
          [classes.root]: true,
          [classes.odd]: Boolean(isOdd)
        })}
        width="100%"
        onClick={onClick}
        onAuxClick={onClick}
        display="flex"
      >
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Box height={40} width={40} mr={3}>
            <img src={image} alt={title} className={classes.image} />
          </Box>
          <Box flexGrow={1}>
            <Typography variant="subtitle1">{title}</Typography>
            {showSku && <Typography variant="body1">{item.sku}</Typography>}
          </Box>
        </Box>
        {handleRenderPriceTag()}
      </Box>
    </ButtonBase>
  )
}

OrderItems.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  showQuantity: PropTypes.bool,
  showSku: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isOdd: PropTypes.bool
}

export default withStyles(styles)(OrderItems)
