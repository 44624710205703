import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'

import DataTable from 'components/DataTable'

import styles from './ProductHistoryTableStyles'

const ProductHistoryTable = ({
  classes,
  history,
  hasHistoryNext,
  listHistoryNext,
  isLoadingHistory
}) => (
  <DataTable
    columns={[
      { title: 'Date' },
      { title: 'User' },
      { title: 'Before' },
      { title: 'After' },
      { title: 'Type' },
      { title: 'Comment' }
    ]}
    message={history?.length ? null : 'No results found.'}
    isLoadingList={isLoadingHistory}
    hasListNext={hasHistoryNext}
    listNext={listHistoryNext}
  >
    {history?.map(
      (
        {
          id: historyId,
          created_at,
          user_full_name: user,
          attribute,
          before,
          after,
          comment
        },
        idx
      ) => (
        <TableRow key={`${historyId}-${idx}`}>
          <TableCell>
            {created_at && moment(created_at).format('LLL')}
          </TableCell>
          <TableCell>
            <Typography variant="body2">{user}</Typography>
          </TableCell>
          <TableCell
            className={classnames({
              [classes.wrap]: true,
              [classes.alignTop]: true
            })}
          >
            <Typography variant="body2">{String(before)}</Typography>
          </TableCell>
          <TableCell
            className={classnames({
              [classes.wrap]: true,
              [classes.alignTop]: true
            })}
          >
            <Typography variant="body2">{String(after)}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{attribute}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{comment}</Typography>
          </TableCell>
        </TableRow>
      )
    )}
  </DataTable>
)

ProductHistoryTable.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.arrayOf(PropTypes.object),
  hasHistory: PropTypes.bool,
  hasHistoryNext: PropTypes.bool,
  listHistoryNext: PropTypes.func,
  isLoadingHistory: PropTypes.bool
}

export default withStyles(styles)(ProductHistoryTable)
