import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment-timezone'

import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import { getDOMElementsPathToRoot } from 'utils/general'
import { useEventListener } from 'hooks'

import {
  DatePicker as MuiDatePicker,
  DateTimePicker as MuiDateTimePicker,
  TimePicker as MuiTimePicker
} from '@material-ui/pickers'
import {
  OutlinedInput,
  FormHelperText,
  Typography,
  Box
} from '@material-ui/core'

import CalendarIcon from 'icons/CalendarIcon'

import styles from './DatePickerStyles'

/*
 * TODO(@exrhizo): Current mocks have the popover with an arrow
 * Here is an example of how to do it:
 * https://github.com/mui-org/material-ui/issues/10772#issuecomment-404271348
 */

const DatePicker = ({
  value,
  label,
  format,
  onChange,
  error,
  name,
  onBlur,
  classes,
  disabled,
  className,
  helperText,
  fullWidth,
  withTime,
  onlyTime,
  dataTest,
  disablePortal,
  showClearButton,
  showEndAdornment,
  placeholder,
  disablePast = false
}) => {
  const [dateTimeSelected, setDateTimeSelected] = useState(false)
  const [open, setOpen] = useState(false)
  const pickerRef = useRef(null)
  const popperRef = useRef(null)

  const labelClassNames = classNames({
    [classes.label]: true,
    [classes.labelError]: Boolean(error),
    [classes.disabled]: disabled
  })

  const formControlClassNames = classNames({
    [classes.formControl]: true,
    [classes.disabled]: disabled,
    [className]: Boolean(className)
  })

  const BaseComponent = onlyTime
    ? MuiTimePicker
    : withTime
    ? MuiDateTimePicker
    : MuiDatePicker

  // For detecting when to open and close the autocomplete.
  useEventListener(document, 'mouseup', e => {
    const path = e.path ?? getDOMElementsPathToRoot(e.target)
    if (
      path.some(el => el === pickerRef?.current || el === popperRef?.current) &&
      !dateTimeSelected
    ) {
      !disabled && setOpen(true)
      return
    }

    open && setOpen(false)
    dateTimeSelected && setDateTimeSelected(false)
  })

  const pickerProps = {
    ref: pickerRef,
    className: classes.datePicker,
    format,
    value,
    onChange,
    open,
    onOpen: () => {
      !disabled && setOpen(true)
      setDateTimeSelected(false)
    },
    onClose: () => setOpen(false),
    onAccept: () => setDateTimeSelected(true),
    disabled,
    allowSameDateSelection: true,
    disablePast,
    PopperProps: { disablePortal, ref: popperRef },
    PopoverProps: { PaperProps: { elevation: 0 } },
    endAdornment: <CalendarIcon />,
    // eslint-disable-next-line react/prop-types
    renderInput: ({ inputProps, InputProps, inputRef }) => (
      <Box ref={inputRef} width="100%">
        <OutlinedInput
          inputProps={{
            ...inputProps,
            name,
            onBlur,
            readOnly: true,
            value: value
              ? moment(value).format(
                  onlyTime ? 'h:mm a' : withTime ? 'MMMM Do, h:mm a' : format
                )
              : '',
            ...(!!placeholder && { placeholder })
          }}
          onClick={e => !disabled && setOpen(true)}
          {...InputProps}
          className={classNames({
            [classes.input]: true
          })}
          error={Boolean(error)}
          notched={false}
          fullWidth={fullWidth}
          endAdornment={(withTime || showEndAdornment) && <CalendarIcon />}
        />
        {Boolean(error) && helperText ? (
          <FormHelperText
            className={classNames({ [classes.disabled]: disabled })}
            error={Boolean(error)}
          >
            {helperText}
          </FormHelperText>
        ) : null}
      </Box>
    )
  }

  const hasLabel = Boolean(label)
  return (
    <>
      {hasLabel ? (
        <Typography variant="caption" className={labelClassNames}>
          {label}
        </Typography>
      ) : null}
      <Box
        className={formControlClassNames}
        data-test={dataTest}
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
      >
        <BaseComponent {...pickerProps} />
        {showClearButton ? (
          <Button edge="end" disabled={!value} onClick={() => onChange(null)}>
            RESET
          </Button>
        ) : null}
      </Box>
    </>
  )
}

DatePicker.defaultProps = {
  disableToolbar: true,
  format: 'MM/DD/yyyy',
  withTime: false,
  onlyTime: false,
  disablePortal: true,
  showClearButton: false,
  showEndAdornment: false
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  error: PropTypes.object,
  className: PropTypes.string,
  helperText: PropTypes.string,
  format: PropTypes.string,
  // value: PropTypes.instanceOf(Moment), TODO: need to resolve with use w/ moment/Moment
  onChange: PropTypes.func,
  onError: PropTypes.func,
  disableToolbar: PropTypes.bool,
  fullWidth: PropTypes.bool,
  dataTest: PropTypes.string,
  withTime: PropTypes.bool,
  onlyTime: PropTypes.bool,
  disabled: PropTypes.bool,
  disablePortal: PropTypes.bool,
  showClearButton: PropTypes.bool,
  showEndAdornment: PropTypes.bool
}

export default withStyles(styles)(DatePicker)
