import React from 'react'
import PropTypes from 'prop-types'

const ProfileIcon = ({ fill, className }) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 9.7825C6.29994 9.7825 4.11111 7.64126 4.11111 4.99989C4.11111 2.35853 6.29994 0.217285 9 0.217285C11.7001 0.217285 13.8889 2.35853 13.8889 4.99989C13.8889 7.64126 11.7001 9.7825 9 9.7825ZM15.6699 19.7825C16.4059 19.7825 17 19.1948 17 18.4782C17 14.1559 13.4183 10.6521 9 10.6521C4.58172 10.6521 1 14.1559 1 18.4782C1 19.197 1.59746 19.7825 2.33013 19.7825H15.6699ZM2.79341 18.0434C3.02129 14.8846 5.713 12.3912 9 12.3912C12.287 12.3912 14.9787 14.8846 15.2066 18.0434H2.79341ZM12.1111 4.99989C12.1111 6.68076 10.7182 8.04337 9 8.04337C7.28178 8.04337 5.88889 6.68076 5.88889 4.99989C5.88889 3.31903 7.28178 1.95642 9 1.95642C10.7182 1.95642 12.1111 3.31903 12.1111 4.99989Z"
    />
    <path d="M2.79341 18.0434L2.59392 18.029L2.57846 18.2434H2.79341V18.0434ZM15.2066 18.0434V18.2434H15.4215L15.4061 18.029L15.2066 18.0434ZM3.91111 4.99989C3.91111 7.75583 6.19365 9.9825 9 9.9825V9.5825C6.40623 9.5825 4.31111 7.52668 4.31111 4.99989H3.91111ZM9 0.0172852C6.19365 0.0172852 3.91111 2.24395 3.91111 4.99989H4.31111C4.31111 2.47311 6.40623 0.417285 9 0.417285V0.0172852ZM14.0889 4.99989C14.0889 2.24395 11.8063 0.0172852 9 0.0172852V0.417285C11.5938 0.417285 13.6889 2.47311 13.6889 4.99989H14.0889ZM9 9.9825C11.8063 9.9825 14.0889 7.75583 14.0889 4.99989H13.6889C13.6889 7.52668 11.5938 9.5825 9 9.5825V9.9825ZM16.8 18.4782C16.8 19.0812 16.2986 19.5825 15.6699 19.5825V19.9825C16.5131 19.9825 17.2 19.3085 17.2 18.4782H16.8ZM9 10.8521C13.312 10.8521 16.8 14.2705 16.8 18.4782H17.2C17.2 14.0413 13.5246 10.4521 9 10.4521V10.8521ZM1.2 18.4782C1.2 14.2705 4.68801 10.8521 9 10.8521V10.4521C4.47543 10.4521 0.8 14.0413 0.8 18.4782H1.2ZM2.33013 19.5825C1.70417 19.5825 1.2 19.0828 1.2 18.4782H0.8C0.8 19.3112 1.49074 19.9825 2.33013 19.9825V19.5825ZM15.6699 19.5825H2.33013V19.9825H15.6699V19.5825ZM9 12.1912C5.6111 12.1912 2.82957 14.7626 2.59392 18.029L2.99289 18.0578C3.21302 15.0065 5.8149 12.5912 9 12.5912V12.1912ZM15.4061 18.029C15.1704 14.7626 12.3889 12.1912 9 12.1912V12.5912C12.1851 12.5912 14.787 15.0065 15.0071 18.0578L15.4061 18.029ZM2.79341 18.2434H15.2066V17.8434H2.79341V18.2434ZM9 8.24337C10.8245 8.24337 12.3111 6.79534 12.3111 4.99989H11.9111C11.9111 6.56618 10.6119 7.84337 9 7.84337V8.24337ZM5.68889 4.99989C5.68889 6.79534 7.17549 8.24337 9 8.24337V7.84337C7.38807 7.84337 6.08889 6.56618 6.08889 4.99989H5.68889ZM9 1.75642C7.17549 1.75642 5.68889 3.20445 5.68889 4.99989H6.08889C6.08889 3.43361 7.38807 2.15642 9 2.15642V1.75642ZM12.3111 4.99989C12.3111 3.20445 10.8245 1.75642 9 1.75642V2.15642C10.6119 2.15642 11.9111 3.43361 11.9111 4.99989H12.3111Z" />
  </svg>
)

ProfileIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}

export default ProfileIcon
