const breadCrumbTextStyles = theme => ({
  display: 'inline',
  paddingRight: theme.spacing(3),
  position: 'relative',
  whiteSpace: 'nowrap',
  fontWeight: 600,
  fontSize: 26,
  lineHeight: '32px'
})

const jssStyles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  breadcrumb: {
    ...breadCrumbTextStyles(theme),
    color: theme.palette.text.link,

    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.link,

      '&:visited': {
        textDecoration: 'none',
        color: theme.palette.text.link
      }
    },
    '&:after': {
      content: '"/"',
      color: theme.palette.text.link,
      position: 'absolute',
      right: 8
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.75
    }
  },
  last: {
    '&:after': {
      color: theme.palette.text.primary
    }
  },
  title: {
    ...breadCrumbTextStyles(theme)
  }
})
export default jssStyles
