import { useEffect } from 'react'

import useLatestCallback from './useLatestCallback'

const useEventListener = (elem, eventName, fn) => {
  const callback = useLatestCallback(fn)

  useEffect(() => {
    if (!elem) {
      return
    }
    elem.addEventListener(eventName, callback)
    return () => {
      elem.removeEventListener(eventName, callback)
    }
  }, [elem, eventName])
}

export default useEventListener
