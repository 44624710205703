import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import Box from '@material-ui/core/Box'

import Checkbox from './Checkbox'

const CheckboxField = ({ name, ...rest }) => {
  return (
    <Box>
      <Controller
        name={name}
        render={({ onChange, value }) => (
          <Checkbox {...rest} onChange={onChange} checked={Boolean(value)} />
        )}
      />
    </Box>
  )
}

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired
}

export default CheckboxField
