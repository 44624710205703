import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { DELIVERY_METHOD_KEYS } from '../../constants/enums'

import styles from './ReturnSummaryStyles'

const ReturnSummary = ({ shipping, onChange, itemsCount, disabled }) => {
  const handleRadioChange = e => {
    onChange && onChange(e.target.value)
  }

  return (
    <Box>
      <Typography variant="h5">Summary</Typography>
      <Box display="flex" justifyContent="space-between" py={3}>
        <Typography variant="subtitle1">Returning</Typography>
        <Typography variant="subtitle1">{itemsCount} items</Typography>
      </Box>
      <Typography variant="h5">Return Shipping Options</Typography>
      <Box mt={2} ml={2}>
        <RadioGroup
          aria-label="shipping"
          name="shipping"
          value={shipping}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={DELIVERY_METHOD_KEYS.onfleet}
            control={<Radio color="primary" />}
            label="FastAF"
            disabled={disabled}
          />
          <FormControlLabel
            value={DELIVERY_METHOD_KEYS.roadie}
            control={<Radio color="primary" />}
            label="Roadie"
            disabled={disabled}
          />
        </RadioGroup>
      </Box>
    </Box>
  )
}

ReturnSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  itemsCount: PropTypes.number,
  shipping: PropTypes.string,
  disabled: PropTypes.bool
}

export default withStyles(styles)(ReturnSummary)
