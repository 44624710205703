import React, { useEffect, useState } from 'react'
import pickBy from 'lodash/pickBy'

import AddIcon from '@material-ui/icons/Add'

import { SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'
import { LIST_PATHS } from 'constants/queryParams'
import {
  useAdminColorways,
  useAdminMasterColors,
  useMediaQuery,
  useQuery
} from 'hooks'

import Tabs from 'components/Tabs'
import Button from 'components/Button'
import Header from 'components/Header'
import Layout from 'components/Layout'
import Select from 'components/Select'
import ColorModal from 'components/ColorModal'
import SearchInput from 'components/SearchInput'

import AllColorsPane from './AllColorsPane'
import MasterColorsPane from './MasterColorsPane'

const COLORS_INDEX = 0

const MASTER_COLORS_INDEX = 1

const COLORS_OPTION = {
  label: 'All Colors',
  value: COLORS_INDEX
}

const MASTER_COLORS_OPTION = {
  label: 'Master Colors',
  value: MASTER_COLORS_INDEX
}

const ColorList = () => {
  const [tabIndexActive, setTabIndexActive] = useState(COLORS_INDEX)
  const [openModal, setOpenModal] = useState(false)
  const [selectedColor, setSelectedColor] = useState({})

  const { query, setQuery, handleQueryChange, updateQuery } = useQuery(false)

  const {
    masterColors,
    hasMasterColors,
    masterColorsCount,
    hasMasterColorsNext,
    isLoadingMasterColors,
    listMasterColorsNext
  } = useAdminMasterColors(query)

  const {
    colorways,
    hasColorways,
    colorwaysCount,
    hasColorwaysNext,
    isLoadingColorways,
    listColorwaysNext,
    createColorway,
    updateColorway,
    destroyColorway
  } = useAdminColorways(query, selectedColor?.id)

  const onEditClick = color => {
    setSelectedColor(color)
    setOpenModal(true)
  }

  useEffect(() => {
    setQuery('')
  }, [tabIndexActive])

  const foldableActions = (
    <SearchInput
      dataTest="colors-search"
      name="search"
      placeholder="Search Colors..."
      value={query.search}
      onChange={handleQueryChange}
    />
  )

  const actions = (
    <Button
      adaptive
      label="new color button"
      startIcon={<AddIcon />}
      color="primary"
      variant="contained"
      size={SIZE.medium}
      onClick={() => setOpenModal(true)}
      disabled={!tabIndexActive === COLORS_INDEX}
    >
      New Color
    </Button>
  )

  const filters = (
    <>
      <Select
        minimal
        name={LIST_PATHS.COLOR_STATUS}
        innerLabel="Status"
        value={query[LIST_PATHS.COLOR_STATUS] || ''}
        items={[
          { label: 'All', value: '' },
          { label: 'Active', value: 'true' },
          { label: 'Inactive', value: 'false' }
        ]}
        onChange={handleQueryChange}
      />
    </>
  )

  const handleCreate = async newColor => {
    const color = newColor
    delete color.id
    await createColorway(color)
  }

  const handleClose = () => {
    setOpenModal(false)
    setSelectedColor()
  }

  const handleUpdate = async color => {
    // don't update image if it hasn't been changed.
    const object = pickBy(color, c => c !== undefined)
    delete object.id

    await updateColorway(object)
  }

  const handleDelete = async color => {
    await destroyColorway(color)
  }

  const { isDesktopLargeScreen: isDesktopOrLarger } = useMediaQuery()

  const getTabsPanes = () => {
    const colorsPane = {
      id: COLORS_INDEX,
      label: COLORS_OPTION.label,
      pane: (
        <AllColorsPane
          query={query}
          colors={colorways || []}
          hasColors={hasColorways}
          colorsCount={colorwaysCount}
          hasListNext={hasColorwaysNext}
          isLoadingList={isLoadingColorways}
          updateQuery={updateQuery}
          listNext={listColorwaysNext}
          handleQueryChange={handleQueryChange}
          updateColorway={updateColorway}
          onEditClick={onEditClick}
        />
      )
    }
    const masterColorsPane = {
      id: MASTER_COLORS_INDEX,
      label: MASTER_COLORS_OPTION.label,
      pane: (
        <MasterColorsPane
          query={query}
          colors={masterColors || []}
          hasColors={hasMasterColors}
          colorsCount={masterColorsCount}
          hasListNext={hasMasterColorsNext}
          isLoadingList={isLoadingMasterColors}
          updateQuery={updateQuery}
          listNext={listMasterColorsNext}
          handleQueryChange={handleQueryChange}
        />
      )
    }
    return [colorsPane, masterColorsPane]
  }

  return (
    <Layout id="colors-list">
      <Header
        title="Colorways"
        breadcrumbs={[
          {
            title: 'Back Of House',
            link: URL.ADMIN_BOH
          }
        ]}
        actions={actions}
        foldableActions={foldableActions}
        filters={!isDesktopOrLarger && filters}
      />
      <Tabs panes={getTabsPanes()} onChange={idx => setTabIndexActive(idx)} />

      {colorways && masterColors && (
        <ColorModal
          open={openModal}
          selectedColor={selectedColor}
          masterColors={masterColors}
          onClose={handleClose}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      )}
    </Layout>
  )
}

export default ColorList
