import React from 'react'
import PropTypes from 'prop-types'

import { STATUS_DOT_VARIANTS } from 'constants/enums'
import { ORDER_STATES_TYPES } from 'constants/general'

import StatusDot from 'components/StatusDot'

const PaymentStatus = ({ className, order, filledBackground }) => {
  if (!order?.payment_status) return null
  const getStatusDotProps = () => {
    switch (order?.payment_status) {
      case ORDER_STATES_TYPES.paid: {
        return {
          variant: STATUS_DOT_VARIANTS.green,
          text: 'Paid'
        }
      }
      case ORDER_STATES_TYPES.refunded: {
        return {
          variant: STATUS_DOT_VARIANTS.green,
          text: 'Refunded',
          filled: false
        }
      }
      case ORDER_STATES_TYPES.partially_refunded: {
        return {
          variant: STATUS_DOT_VARIANTS.orange,
          text: 'Partially Refunded',
          filled: false
        }
      }
      default:
        return null
    }
  }

  return (
    <StatusDot
      className={className}
      {...getStatusDotProps()}
      filledBackground={filledBackground}
    />
  )
}

PaymentStatus.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object,
  filledBackground: PropTypes.bool
}

export default PaymentStatus
