import { useResourceRQ } from 'hooks'
import { gmvEndpoint } from 'api/endpoints'

const useAdminGMV = params => {
  const { data, isLoading, error } = useResourceRQ({
    endpoint: gmvEndpoint,
    name: 'gmv',
    params,
    activeLoaders: true,
    showServerError: true
  })

  return { data, isLoading, error }
}

export default useAdminGMV
