import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { withStyles } from '@material-ui/core/styles'

import { useAlerts, useAuth } from 'hooks'
import { makeObjectResolver, emailSchema } from 'constants/yupSchemas'
import { URL } from 'constants/navigation'
import { SIZE, COLOR } from 'constants/enums'
import Link from 'components/Link'
import Button from 'components/Button'
import TextField from 'components/TextField'
import LoginLayout from 'containers/auth/LoginLayout'

import styles from './LoginStyles'

const ForgotPassword = ({ classes }) => {
  const history = useHistory()

  const { forgotPassword } = useAuth()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const { register, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    resolver: makeObjectResolver({
      email: emailSchema
    })
  })

  const onSubmit = async ({ email }) => {
    try {
      await forgotPassword(email)
      showAlertSuccess('Check your email for password reset link')
      history.push(URL.LOGIN)
    } catch (e) {
      console.error(e)
      showAlertError('Email not found. Please try again.')
    }
  }

  return (
    <LoginLayout
      title="Forgot password"
      subtitle="Reset password to access the dashboard."
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="email"
          placeholder="Account Email"
          fullWidth
          inputRef={register}
          error={Boolean(errors.email?.message)}
          helperText={errors.email?.message}
          dataTest="email"
        />
        <Link to={URL.LOGIN}>Nevermind log in.</Link>
        <Button
          size={SIZE.large}
          color={COLOR.primary}
          fullWidth
          type="submit"
          className={classes.submit}
          dataTest="submit-reset"
        >
          Reset password
        </Button>
      </form>
    </LoginLayout>
  )
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ForgotPassword)
