import React from 'react'
import * as yup from 'yup'
import moment from 'moment'

import { TextFieldControlled } from 'components/TextField'

export const APPLIES_TO_LABELS = [
  {
    label: 'Everything',
    value: 'all',
    renderInput: false
  },
  {
    label: 'Select Products',
    value: 'select_products',
    renderInput: true
  },
  {
    label: 'Select Collections',
    value: 'select_collections',
    renderInput: true
  }
]

export const MINIMUM_REQUIREMENT_LABELS = [
  {
    label: 'None',
    value: 'no_minimum',
    renderInput: false
  },
  {
    label: 'Purchase Amount',
    value: 'purchase_amount',
    renderInput: props => <TextFieldControlled {...props} />
  }
]

export const ELIGIBLE_CUSTOMER_LABELS = [
  {
    label: 'Everyone',
    value: 'all',
    renderInput: false
  },
  {
    label: 'Select Customers',
    value: 'select_customers',
    renderInput: true
  }
]

export const USAGE_LIMIT_LABELS = [
  {
    label: 'One-time Use',
    value: 'one_per'
  },
  {
    label: 'Number of Uses',
    value: 'max_uses'
  },
  {
    label: 'First Time Only',
    value: 'first_time'
  }
]

export const BULK_KEY = 'bulk'
export const AUTO_KEY = 'auto'
export const MANUAL_KEY = 'manual'

export const METHOD_TYPE_LABELS = [
  {
    label: 'Bulk',
    value: BULK_KEY
  },
  {
    label: 'Referral',
    value: AUTO_KEY
  },
  {
    label: 'Manual',
    value: MANUAL_KEY
  }
]

export const SCHEDULED_KEY = 'scheduled'
export const ACTIVE_KEY = 'active'
export const PAST_KEY = 'past'
export const INACTIVE_KEY = 'inactive'

export const STATUS_TYPE_LABELS = [
  {
    label: 'Scheduled',
    value: SCHEDULED_KEY
  },
  {
    label: 'Active',
    value: ACTIVE_KEY
  },
  {
    label: 'Expired',
    value: PAST_KEY
  },
  {
    label: 'Inactive',
    value: INACTIVE_KEY
  }
]

export const DISCOUNT_TYPE_LABELS = [
  {
    label: 'Percentage',
    value: 'percentage',
    renderInput: props => <TextFieldControlled {...props} />
  },
  {
    label: 'Fixed Amount',
    value: 'fixed',
    renderInput: props => <TextFieldControlled {...props} />
  },
  {
    label: 'Free Shipping',
    value: 'free_shipping',
    renderInput: false
  }
]

export const PROMO_CODE_FIELDS = {
  prefix: 'prefix',
  quantity: 'quantity',
  discount_type: 'discount_type',
  label: 'label',
  eligible_product_ids: 'eligible_product_ids',
  eligible_collection_ids: 'eligible_collection_ids',
  excluded_product_ids: 'excluded_product_ids',
  excluded_collection_ids: 'excluded_collection_ids',
  minimum_requirement: 'minimum_requirement',
  eligible_user_ids: 'eligible_user_ids',
  usage_limits: 'usage_limits',
  start_time: 'start_time',
  end_time: 'end_time',
  amount: 'amount',
  // form helpers
  application_selection: 'application_selection',
  min_req_selection: 'min_req_selection',
  eligible_users_selection: 'eligible_users_selection',
  max_uses: 'max_uses',
  active: 'active',
  locations: 'locations'
}

export const DISCOUNT_TYPE_NAME_OPTIONS = {
  PERCENTAGE: 'percentage',
  FIXED: 'fixed',
  FREE_SHIPPING: 'free_shipping'
}

const PROMO_CODE_SCHEMAS_SHARED = {
  [PROMO_CODE_FIELDS.discount_type]: yup
    .mixed()
    .oneOf([
      DISCOUNT_TYPE_NAME_OPTIONS.PERCENTAGE,
      DISCOUNT_TYPE_NAME_OPTIONS.FIXED,
      DISCOUNT_TYPE_NAME_OPTIONS.FREE_SHIPPING
    ])
    .required(),
  [PROMO_CODE_FIELDS.amount]: yup
    .number()
    .typeError(
      'Amount Off must be a Positive Number, Percentages must be between 1 and 100.'
    )
    .nullable(),
  [PROMO_CODE_FIELDS.eligible_product_ids]: yup.array().nullable(),
  [PROMO_CODE_FIELDS.eligible_collection_ids]: yup.array().nullable(),
  [PROMO_CODE_FIELDS.excluded_product_ids]: yup.array().nullable(),
  [PROMO_CODE_FIELDS.excluded_collection_ids]: yup.array().nullable(),
  [PROMO_CODE_FIELDS.eligible_user_ids]: yup.array().nullable(),
  [PROMO_CODE_FIELDS.minimum_requirement]: yup.number().min(0),
  [PROMO_CODE_FIELDS.usage_limits]: yup
    .object({
      one_per: yup.boolean().required(),
      first_time: yup.boolean().required(),
      max_uses: yup.boolean().required()
    })
    .required(),
  [PROMO_CODE_FIELDS.max_uses]: yup.number().nullable(),
  [PROMO_CODE_FIELDS.start_time]: yup.date().nullable(),
  [PROMO_CODE_FIELDS.end_time]: yup
    .date()
    .min(yup.ref('start_time'), 'End time cannot be before the start time')
    .nullable(),
  [PROMO_CODE_FIELDS.active]: yup.boolean().required(),
  [PROMO_CODE_FIELDS.locations]: yup.array().nullable()
}

export const PROMO_CODE_SCHEMAS = {
  [PROMO_CODE_FIELDS.label]: yup.string().required(),
  ...PROMO_CODE_SCHEMAS_SHARED
}

export const PROMO_CODE_BULK_SCHEMAS = {
  [PROMO_CODE_FIELDS.prefix]: yup.string().required(),
  [PROMO_CODE_FIELDS.quantity]: yup.number().required(),
  ...PROMO_CODE_SCHEMAS_SHARED
}

export const PROMO_CODE_FORM_DEFAULTS = {
  [PROMO_CODE_FIELDS.prefix]: '',
  [PROMO_CODE_FIELDS.quantity]: 0,
  [PROMO_CODE_FIELDS.discount_type]: DISCOUNT_TYPE_NAME_OPTIONS.PERCENTAGE,
  [PROMO_CODE_FIELDS.amount]: 0,
  [PROMO_CODE_FIELDS.label]: '',
  [PROMO_CODE_FIELDS.eligible_product_ids]: [],
  [PROMO_CODE_FIELDS.eligible_collection_ids]: [],
  [PROMO_CODE_FIELDS.excluded_product_ids]: [],
  [PROMO_CODE_FIELDS.excluded_collection_ids]: [],
  [PROMO_CODE_FIELDS.minimum_requirement]: 0,
  [PROMO_CODE_FIELDS.eligible_user_ids]: [],
  [PROMO_CODE_FIELDS.usage_limits]: {
    one_per: true,
    max_uses: false,
    first_time: true
  },
  [PROMO_CODE_FIELDS.max_uses]: null,
  [PROMO_CODE_FIELDS.start_time]: moment(),
  [PROMO_CODE_FIELDS.end_time]: null,
  [PROMO_CODE_FIELDS.application_selection]: APPLIES_TO_LABELS[0].value,
  [PROMO_CODE_FIELDS.min_req_selection]: MINIMUM_REQUIREMENT_LABELS[0].value,
  [PROMO_CODE_FIELDS.eligible_users_selection]:
    ELIGIBLE_CUSTOMER_LABELS[0].value,
  [PROMO_CODE_FIELDS.active]: true,
  [PROMO_CODE_FIELDS.locations]: []
}

export const RADIO_INPUT_TYPES = {
  number: 'number',
  text: 'text',
  users: 'users',
  products: 'products'
}

export const ZERO = 0

export const PROMO_CODE_SERVER_FILTERS = {
  STATUS: 'discount_codes.status',
  CODE_TYPE: 'discount_codes.code_type',
  START_TIME: 'discount_codes.start_time',
  END_TIME: 'discount_codes.end_time',
  CREATION_SOURCE: 'discount_codes.creation_sources',
  AUTHOR: 'discount_codes.author_ids'
}
