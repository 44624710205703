import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import {
  DateRangePicker as DateRangePickerNew,
  isInclusivelyBeforeDay
} from 'react-dates'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'

import { Box, Button } from '@material-ui/core'

import CalendarIcon from 'icons/CalendarIcon'

import { BUTTON_VARIANT } from 'constants/enums'

import styles from './DateRangePickerNewStyles'

const DateRangePicker = ({
  classes,
  className,
  onChange,
  value,
  onClearDates,
  name,
  noDatesOutsideRange,
  noBorder,
  displayFormat,
  clearable
}) => {
  const [startDateValue, setStartDateValue] = useState(null)
  const [endDateValue, setEndDateValue] = useState(null)
  const [focusedInputValue, setFocusedInputValue] = useState(null)

  useEffect(() => {
    setStartDateValue(value[0])
    setEndDateValue(value[1])
  }, [value])

  const handleClearDates = () => {
    setStartDateValue(null)
    setEndDateValue(null)
    onClearDates && onClearDates()
  }

  const outsideRangeProps = noDatesOutsideRange
    ? { isOutsideRange: () => false }
    : { isOutsideRange: day => !isInclusivelyBeforeDay(day, moment()) }

  const handlSetYesterday = () => {
    const yesterday = moment().subtract(1, 'day')
    const start = yesterday.startOf('day')
    const end = yesterday.endOf('day')
    setStartDateValue(start)
    setEndDateValue(end)
    onChange([start, end])
  }

  const handleSetToday = () => {
    const today = moment()
    const start = today.startOf('day')
    const end = today.endOf('day')
    setStartDateValue(start)
    setEndDateValue(end)
    onChange([start, end])
  }

  const handleSetTomorrow = () => {
    const tomorrow = moment().add(1, 'day')
    const start = tomorrow.startOf('day')
    const end = tomorrow.endOf('day')
    setStartDateValue(start)
    setEndDateValue(end)
    onChange([start, end])
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gridGap={10}
      className={classNames({
        [classes.root]: true,
        [className]: !!className
      })}
    >
      <DateRangePickerNew
        startDatePlaceholderText="Start"
        endDatePlaceholderText="End"
        small
        noBorder={noBorder}
        displayFormat={displayFormat}
        showDefaultInputIcon
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        customInputIcon={<CalendarIcon />}
        inputIconPosition="after"
        startDate={startDateValue}
        startDateId={`${name || 'name'}_start_date`}
        endDate={endDateValue}
        endDateId={`${name || 'name'}_end_date`}
        minimumNights={0}
        onDatesChange={({ startDate, endDate }) => {
          setStartDateValue(startDate)
          setEndDateValue(endDate)
          onChange && onChange([startDate, endDate])
        }}
        focusedInput={focusedInputValue}
        onFocusChange={focusedInput => {
          setFocusedInputValue(focusedInput)
        }}
        renderCalendarInfo={props => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={2}
            mt={-2}
          >
            <Button variant="outlined" size="sm" onClick={handlSetYesterday}>
              Yesterday
            </Button>
            <Button variant="outlined" size="sm" onClick={handleSetToday}>
              Today
            </Button>
            <Button variant="outlined" size="sm" onClick={handleSetTomorrow}>
              Tomorrow
            </Button>
          </Box>
        )}
        {...outsideRangeProps}
      />
      {clearable && (
        <Button
          className={classes.resetButton}
          variant={BUTTON_VARIANT.secondary}
          onClick={handleClearDates}
          disabled={!startDateValue && !endDateValue}
        >
          Clear
        </Button>
      )}
    </Box>
  )
}

DateRangePicker.defaultProps = {
  noBorder: true,
  displayFormat: 'MMM D',
  label: 'Date Range',
  clearable: true
}

DateRangePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
  onChange: PropTypes.func,
  onClearDates: PropTypes.func,
  noDatesOutsideRange: PropTypes.bool,
  noBorder: PropTypes.bool,
  displayFormat: PropTypes.string,
  label: PropTypes.string,
  clearable: PropTypes.bool
}

export default withStyles(styles)(DateRangePicker)
