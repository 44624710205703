import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, uniqBy, isEqual } from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { Box, Typography } from '@material-ui/core'

import SaveIcon from '@material-ui/icons/Save'
import AddIcon from '@material-ui/icons/Add'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { centsToDollars, dollarsToCents } from 'utils/general'
import { SIZE, COLOR } from 'constants/enums'
import { URL } from 'constants/navigation'
import {
  DISTRIBUTION_CENTER_SCHEMA,
  DISTRIBUTION_CENTER_FORM_DEFAULTS,
  DISTRIBUTION_CENTER_FIELDS,
  DISTRIBUTION_CENTER_VARIANT_FIELD_SCHEMAS,
  DISTRIBUTION_CENTER_VARIANT_FIELDS,
  getVariantFieldFrontendConversion,
  getVariantFieldServerConversion
} from 'constants/suppliers'
import {
  useBeforeUnload,
  useLoaders,
  useAdminDistributionCenter,
  useAdminDistributionCenterVariantsList,
  useConditionalEffect,
  useNavigation,
  useAdminSupplier,
  useMediaQuery,
  usePrevious,
  useQuery
} from 'hooks'

import { AutocompleteSelectLocationsControlled } from 'components/AutocompleteSelectLocations'
import { TextFieldControlled } from 'components/TextField'
import { TitleElement } from 'components/SiteTitle'
import { useControlledForm } from 'components/ControlledForm'
import Button from 'components/Button'
import ControlledForm from 'components/ControlledForm/ControlledForm'
import DataTable from 'components/DataTable'
import DistributionCenterVariantsDrawer from 'components/DistributionCenterVariantsDrawer'
import Header from 'components/Header'
import Layout from 'components/Layout'
import SearchInput from 'components/SearchInput'

import DistributionCenterVariantItem from './DistributionCenterVariantItem'
import { thinInput } from 'constants/styles'

export const variantFieldName = (fieldName, id) =>
  `variantField-${DISTRIBUTION_CENTER_VARIANT_FIELDS[fieldName]}-${id}`

const DISTRIBUTION_CENTER_VARIANT_COLUMNS = [
  { title: 'Title' },
  { title: 'SKU' },
  { title: 'Unit Cost' },
  { title: 'Min Order Qty' },
  { title: 'Min Order Val' },
  { title: 'Min Casepack Qty' },
  { title: 'Case Cost' },
  { title: 'Case Length' },
  { title: 'Case Width' },
  { title: 'Case Height' },
  { title: 'Case Weight' },
  { title: 'Case UPC' },
  { title: 'Case Pack Qty' },
  { title: 'Lead Time' },
  { title: 'Shelf Life Guarantee' },
  { title: 'Total Shelf Life' },
  { title: '' }
]

const DistributionCenterForm = ({
  classes,
  match: {
    params: { supplierId, distributionCenterId }
  }
}) => {
  const { isMobileScreen } = useMediaQuery()
  const { go } = useNavigation({})
  const { showLoading, hideLoading } = useLoaders()

  const {
    query: variantsQuery,
    handleQueryChange: handleVariantsQueryChange,
    updateQuery: updateVariantsQuery
  } = useQuery(false)

  const { supplier } = useAdminSupplier({ id: supplierId })
  const {
    distributionCenter,
    updateDistributionCenter,
    createDistributionCenter
  } = useAdminDistributionCenter({
    supplierId,
    distributionCenterId,
    fetchingEnabled: !!distributionCenterId
  })

  const {
    isFetchingDistributionCenterVariants,
    hasDistributionCenterVariantsNext,
    fetchDistributionCenterVariantsNext,
    distributionCenterVariants
  } = useAdminDistributionCenterVariantsList({
    supplierId,
    distributionCenterId,
    options: {
      fetchingEnabled:
        Number.isInteger(Number(supplierId)) &&
        Number.isInteger(Number(distributionCenterId))
    },
    params: variantsQuery
  })

  const [submitting, setSubmitting] = useState(false)
  const [formDefaults, setFormDefaults] = useState(
    DISTRIBUTION_CENTER_FORM_DEFAULTS
  )

  const [formModified, setFormModified] = useState(false)
  const [variantsToDelete, setVariantsToDelete] = useState([])
  const [addVariantsDrawerOpen, setAddVariantsDrawerOpen] = useState(false)
  const [distributionCenterSchema, setDistributionCenterSchema] = useState(
    DISTRIBUTION_CENTER_SCHEMA
  )

  const constructFormDefaultsFromDistributionCenter = ({
    distribution_center_variants,
    distribution_center_locations,
    minimum_order_value
  }) => {
    let variantsSchema = {}
    let variantDefaults = {}

    if (!isEmpty(distribution_center_variants)) {
      distribution_center_variants.forEach(v => {
        Object.keys(DISTRIBUTION_CENTER_VARIANT_FIELDS).forEach(field => {
          // add distribution center variant to schema for validation
          variantsSchema[variantFieldName(field, v.id)] =
            DISTRIBUTION_CENTER_VARIANT_FIELD_SCHEMAS[field]

          // add default values for the form
          variantDefaults[
            variantFieldName(field, v.id)
          ] = getVariantFieldFrontendConversion(field, v[field])
        })
      })
    }

    if (!isEmpty(variantsSchema)) {
      setDistributionCenterSchema({
        ...DISTRIBUTION_CENTER_SCHEMA,
        ...variantsSchema
      })
    }

    return {
      [DISTRIBUTION_CENTER_FIELDS.warehouses]: distribution_center_locations?.map(
        ({ location }) => location
      )?.[0], // distribution_center_locations is still an array, but we only use 1 value for now.
      minimum_order_value: centsToDollars(minimum_order_value ?? 0),
      ...(!isEmpty(variantDefaults) ? { ...variantDefaults } : {})
    }
  }

  const prevDistributionCenter = usePrevious(distributionCenter)
  const prevDistributionCenterVariants = usePrevious(distributionCenterVariants)
  useConditionalEffect(() => {
    const distributionCenterLoaded = !isEmpty(distributionCenter)
    const distributionCenterHasChanges = !isEqual(
      // isEqual can be performance expensive check here
      prevDistributionCenter,
      distributionCenter
    )

    const hasListLength = distributionCenterVariants.length > 0
    const listsDifferent = !isEqual(
      // isEqual can be performance expensive check here
      prevDistributionCenterVariants,
      distributionCenterVariants
    )

    const dcShouldTriggerEffect =
      distributionCenterLoaded && distributionCenterHasChanges
    const dcvsShouldTriggerEffect = hasListLength && listsDifferent

    if (dcShouldTriggerEffect || dcvsShouldTriggerEffect) {
      setFormDefaults(
        constructFormDefaultsFromDistributionCenter({
          ...distributionCenter,
          distribution_center_variants: distributionCenterVariants
        })
      )
    }
  }, [distributionCenter, distributionCenterVariants])

  const handleAddVariantsToDistroCenter = async variants => {
    showLoading()
    await updateDistributionCenter({
      distribution_center_variants_attributes: [
        ...variants.map(v => ({
          variant_id: v.id
        }))
      ]
    })
    hideLoading()
  }

  const handleDeleteVariants = variantsSelectedToDelete => {
    if (!formModified) {
      setFormModified(true)
    }
    setVariantsToDelete(
      uniqBy([...variantsToDelete, ...variantsSelectedToDelete], 'id')
    )
  }

  const handleSubmit = async ({
    warehouses,
    minimum_order_value,
    ...submittedDistributionCenter
  }) => {
    setSubmitting(true)
    showLoading()

    const deleteTheseVariants = variantsToDelete.map(({ id }) => {
      Object.keys(distributionCenter).forEach(key => {
        if (key.includes('variantField') && key.includes(id)) {
          delete distributionCenter[key]
        }
      })
      return {
        id,
        _destroy: true
      }
    })

    let variantUpdateMap = {}

    Object.entries(submittedDistributionCenter)
      .filter(([key]) => key.includes('variantField'))
      .forEach(([key, value]) => {
        delete submittedDistributionCenter[key]
        const split = key.split('-')
        // split = [vairiantField, fieldname, variantId]
        const fieldName = split[1]
        const id = split[2]

        const prevUpdates = variantUpdateMap[id] ?? {}

        variantUpdateMap = {
          ...variantUpdateMap,
          [id]: {
            ...prevUpdates,
            [fieldName]: getVariantFieldServerConversion(fieldName, value)
          }
        }
      })

    const variantUpdates = Object.entries(variantUpdateMap).map(
      ([key, values]) => ({
        id: key,
        ...values
      })
    )
    const prevWarehouseSelections =
      distributionCenter?.distribution_center_locations?.map(
        ({ location_id: id }) => id
      ) ?? []
    const currentWarehouseSelection = warehouses.id

    const finalWarehouses = [
      ...[...prevWarehouseSelections]
        ?.filter(id => currentWarehouseSelection !== id)
        ?.map(id => ({
          location_id: id,
          _destroy: true
        })),
      ...[currentWarehouseSelection]
        ?.filter(id => !prevWarehouseSelections.includes(id))
        ?.map(id => ({
          location_id: id
        }))
    ]

    const execute = !!distributionCenterId
      ? updateDistributionCenter
      : createDistributionCenter

    const { data: localDistributionCenter } = await execute({
      ...submittedDistributionCenter,
      ...(!!distributionCenterId
        ? {
            distribution_center_variants_attributes: [
              ...deleteTheseVariants,
              ...variantUpdates
            ]
          }
        : {}),
      ...(finalWarehouses.length > 0
        ? {
            distribution_center_locations_attributes: [...finalWarehouses]
          }
        : {}),
      minimum_order_value: dollarsToCents(minimum_order_value ?? 0)
    })

    if (!distributionCenterId && localDistributionCenter.id) {
      go(
        {},
        `${URL.ADMIN_SUPPLIERS}/${supplierId}/distribution_centers/${localDistributionCenter.id}`,
        false
      )
    }

    setFormModified(false)
    setSubmitting(false)
    hideLoading()
  }

  const SaveButton = ({ submitting, formModified }) => {
    const {
      handleSubmit: localHandleSubmit,
      formContext: {
        formState: { dirtyFields }
      }
    } = useControlledForm()
    const { OnBeforeUnloadPrompt } = useBeforeUnload(
      !isEmpty(dirtyFields) || formModified
    )
    const saveDisabled = isEmpty(dirtyFields) || submitting

    return (
      <>
        <Button
          label="save distributionCenter button"
          onClick={localHandleSubmit}
          size={SIZE.medium}
          color={COLOR.primary}
          dataTest="distributionCenter-save-button"
          startIcon={
            distributionCenterId ? <SaveIcon /> : <CheckCircleOutlineIcon />
          }
          adaptive
          disabled={formModified ? false : saveDisabled}
          type="submit"
        >
          {distributionCenterId ? 'Save' : 'Create'}
        </Button>
        <OnBeforeUnloadPrompt />
      </>
    )
  }

  const actions = (
    <>
      <SaveButton
        id={distributionCenterId}
        submitting={submitting}
        formModified={formModified}
      />
    </>
  )

  const pageTitle =
    distributionCenter?.distribution_center_locations?.[0]?.name ??
    distributionCenter?.name ??
    'New'

  const toDeleteIds = variantsToDelete.map(v => v.id)
  const activeVariants = [...(distributionCenterVariants ?? [])].filter(
    v => !toDeleteIds.includes(v.id)
  )

  const inputStyles = {
    fullWidth: true,
    className: classes.thinInput
  }

  return (
    <Layout
      className={classes.root}
      id="DistributionCenterForm"
      forceMobileRootStyles
    >
      <TitleElement title={pageTitle} />
      <ControlledForm
        handleSubmit={handleSubmit}
        schemas={distributionCenterSchema}
        defaultValues={formDefaults}
        resetOnSubmit={!distributionCenterId}
        shouldUnregister={false}
        boxProps={{ className: classes.innerScrollClass }}
      >
        <Header
          breadcrumbs={[
            {
              title: 'Suppliers',
              link: URL.ADMIN_SUPPLIERS
            },
            {
              title: supplier?.name ?? 'Loading...',
              link: `${URL.ADMIN_SUPPLIERS}/${supplierId}`
            }
          ]}
          title={pageTitle}
          actions={actions}
        />

        <Box>
          <Box mb={2}>
            <Typography variant="h2">
              {distributionCenter?.name
                ? 'Update Service Location Specifications'
                : 'Create Service Location Specifications'}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            width="100%"
            flexDirection={isMobileScreen ? 'column' : 'row'}
            mb={1}
          >
            <Box width="100%" mr={isMobileScreen ? 0 : 1}>
              <Typography variant="h3">Service Location</Typography>
              <Box mb={1} />
              <AutocompleteSelectLocationsControlled
                limitTags={10}
                getOptionLabel={opt => opt?.display_name ?? opt?.name}
                getTagLabel={opt => opt?.display_name ?? opt?.name}
                multiple={false}
                name={DISTRIBUTION_CENTER_FIELDS.warehouses}
                disabled={
                  !isEmpty(distributionCenter) &&
                  !isEmpty(distributionCenter?.distribution_center_locations)
                }
                {...inputStyles}
              />
            </Box>

            <Box width="100%">
              <Typography variant="h3">Minimum Order Value</Typography>
              <TextFieldControlled
                startAdornment="$"
                number
                min={0}
                name={DISTRIBUTION_CENTER_FIELDS.minimum_order_value}
                {...inputStyles}
              />
            </Box>
          </Box>
        </Box>
        {!isEmpty(distributionCenter) && (
          <>
            <DistributionCenterVariantsDrawer
              open={addVariantsDrawerOpen}
              onClose={() => setAddVariantsDrawerOpen(false)}
              onSubmit={handleAddVariantsToDistroCenter}
              centerVariants={activeVariants}
            />
            <Box
              my={2}
              mx={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <SearchInput
                dataTest="search-variants"
                name="search"
                placeholder="Search by variant ID or SKU"
                value={variantsQuery.search}
                onChange={handleVariantsQueryChange}
              />
              <Box>
                <Button
                  label="add variants center"
                  onClick={() => setAddVariantsDrawerOpen(true)}
                  size={SIZE.medium}
                  color={COLOR.primary}
                  dataTest="add-variants"
                  startIcon={<AddIcon />}
                  adaptive
                  type="button"
                >
                  Add Variants
                </Button>
              </Box>
            </Box>
            {(activeVariants.length > 0 || !isEmpty(variantsToDelete)) && (
              <DataTable
                slimTable
                columns={DISTRIBUTION_CENTER_VARIANT_COLUMNS}
                message={
                  activeVariants?.length === 0 ? 'No results found.' : null
                }
                isLoadingList={isFetchingDistributionCenterVariants}
                hasListNext={hasDistributionCenterVariantsNext}
                listNext={fetchDistributionCenterVariantsNext}
                id="distribution-center-variants-table"
                stickyColumnWidth={isMobileScreen ? 0 : 500}
              >
                {activeVariants?.map((variant, i) => (
                  <DistributionCenterVariantItem
                    sticky={!isMobileScreen}
                    evenOrOdd={i % 2 == 0}
                    variant={variant}
                    key={variant.id}
                    dataTest="distribution-center-variants-table"
                    deleteVariant={handleDeleteVariants}
                  />
                ))}
              </DataTable>
            )}
          </>
        )}
      </ControlledForm>
    </Layout>
  )
}

DistributionCenterForm.defaultProps = {}

DistributionCenterForm.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(theme => ({
  thinInput: thinInput(theme),
  innerScrollClass: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}))(DistributionCenterForm)
