import React from 'react'
import PropTypes from 'prop-types'

import * as yup from 'yup'
import { Controller, useFormContext } from 'react-hook-form'

import { withStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'

import { BUTTON_VARIANT, SIZE } from 'constants/enums'
import { STORE_CREDIT_UPDATE_TYPES } from 'utils/user'

import Drawer from 'components/Drawer'
import Button from 'components/Button'
import { TextFieldControlled } from 'components/TextField'
import MemoContainer from 'components/MemoContainer'

import styles from './EditStoreCreditDrawerStyles'

const editCreditFields = {
  type: 'type',
  amount: 'amount',
  memo: 'memo'
}

export const storeCreditUpdateTypes = [
  { name: 'set', label: 'Set to' },
  { name: 'add', label: 'Increase by' },
  { name: 'sub', label: 'Reduce by' }
]

const yupUserSchemas = {
  [editCreditFields.type]: yup
    .string()
    .oneOf(
      Object.keys(STORE_CREDIT_UPDATE_TYPES).map(
        type => STORE_CREDIT_UPDATE_TYPES[type].name
      )
    )
    .required(),
  [editCreditFields.amount]: yup
    .string()
    .matches('^[0-9]+(?:.[0-9]+)?$', 'Must be a non-negative number')
    .required(),
  [editCreditFields.memo]: yup.string().required()
}

const formDefaults = {
  [editCreditFields.type]: 'set',
  [editCreditFields.amount]: '',
  [editCreditFields.memo]: ''
}

const EditTypeButtons = ({ classes }) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={editCreditFields.type}
      control={control}
      defaultValue={STORE_CREDIT_UPDATE_TYPES.SET.name}
      render={({ onChange, value }) =>
        Object.keys(STORE_CREDIT_UPDATE_TYPES).map(type => (
          <Button
            key={STORE_CREDIT_UPDATE_TYPES[type].name}
            variant={
              value === STORE_CREDIT_UPDATE_TYPES[type].name
                ? BUTTON_VARIANT.contained
                : BUTTON_VARIANT.outlined
            }
            onClick={() => onChange(STORE_CREDIT_UPDATE_TYPES[type].name)}
            size={SIZE.medium}
            className={classes.typeButton}
          >
            {STORE_CREDIT_UPDATE_TYPES[type].label}
          </Button>
        ))
      }
    />
  )
}

const EditStoreCreditDrawer = ({
  classes,
  open,
  onClose,
  updateStoreCredit
}) => (
  <Drawer
    form={{
      defaultValues: formDefaults,
      schemas: yupUserSchemas,
      handleSubmit: args => {
        updateStoreCredit(args)
        onClose()
      }
    }}
    title="Update Store Credit"
    open={open}
    onClose={onClose}
    actions={
      <Button fullWidth type="submit">
        Update
      </Button>
    }
  >
    <Box mb={2} display="flex" alignItems="center" justifyContent="flex-start">
      <EditTypeButtons classes={classes} />
    </Box>
    <TextFieldControlled
      name={editCreditFields.amount}
      placeholder="Enter credit amount in dollars"
      fullWidth
    />
    <MemoContainer
      name={editCreditFields.memo}
      defaultActive
      placeholder="Add reason for update"
    />
  </Drawer>
)
EditStoreCreditDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  updateStoreCredit: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export default withStyles(styles)(EditStoreCreditDrawer)
