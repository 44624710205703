import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

import Dialog from 'components/Dialog'
import AutocompleteSearchCollections from 'components/AutocompleteSearchCollections'

const AddCollectionDialog = ({
  title,
  subtitle,
  open,
  existingCollections,
  onClose,
  onConfirm
}) => {
  const [newCollections, setNewCollections] = useState([])
  const handleConfirm = () => {
    onConfirm(newCollections)
    setNewCollections([])
  }

  return (
    <Dialog
      title={title}
      subtitle={subtitle}
      open={open}
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmText="Add"
      confirmDisabled={newCollections.length === 0}
    >
      <Box height={200}>
        <AutocompleteSearchCollections
          value={newCollections}
          onChange={setNewCollections}
          hiddenItems={existingCollections}
          multiple
        />
      </Box>
    </Dialog>
  )
}

AddCollectionDialog.defaultProps = {
  title: 'Add Collection'
}

AddCollectionDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  existingCollections: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

export default AddCollectionDialog
