import { colorsEndpointV2, colorsEndpoint } from 'api/endpoints'
import { useResourceRQ, useResourceListRQ } from 'hooks'
import { multiFieldFileInputProcessor } from 'hooks/common/useResourceRQ'

const useAdminColorways = (params, colorId) => {
  const {
    create: createColorway,
    update: updateColorway,
    destroy: destroyColorway,
    data: colorway
  } = useResourceRQ({
    endpoint: colorsEndpoint,
    args: [colorId],
    name: 'color',
    dependents: [() => colorsEndpointV2()],
    inputProcessor: multiFieldFileInputProcessor('color'),
    activeLoaders: true,
    showServerError: true,
    onSuccess: {
      updateMsg: () => 'Color successfully updated',
      createMsg: () => 'Color successfully created'
    }
  })

  const {
    data: colorways,
    count: colorwaysCount,
    hasData: hasColorways,
    hasNextPage: hasColorwaysNext,
    isLoading: isLoadingColorways,
    fetchNextPage: listColorwaysNext
  } = useResourceListRQ({ endpoint: colorsEndpointV2, params })

  return {
    createColorway,
    colorway,
    updateColorway,
    destroyColorway,
    isLoadingColorways,
    colorways,
    hasColorways,
    colorwaysCount,
    hasColorwaysNext,
    listColorwaysNext
  }
}

export default useAdminColorways
