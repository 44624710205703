import React, { useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import { useMediaQuery } from 'hooks'
import { PROMO_CODE_FIELDS, USAGE_LIMIT_LABELS } from 'constants/promoCodes'

import { TextFieldControlled } from 'components/TextField'
import { CheckboxGroupField } from 'components/CheckboxGroup'
import { useControlledForm } from 'components/ControlledForm'

const UsageLimitsCheckboxes = ({ classes }) => {
  const {
    formContext: { getValues, setValue }
  } = useControlledForm()

  const { isDesktopScreen } = useMediaQuery()

  const maxUsesChecked = getValues(PROMO_CODE_FIELDS.usage_limits)?.max_uses

  useEffect(() => {
    if (!maxUsesChecked) {
      const currentValue = getValues(PROMO_CODE_FIELDS.max_uses)
      const reset = null
      if (currentValue !== reset) {
        setValue(PROMO_CODE_FIELDS.max_uses, reset, {
          shouldDirty: true
        })
      }
    }
  }, [maxUsesChecked])

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      flexDirection="column"
      width="100%"
    >
      <CheckboxGroupField
        name={PROMO_CODE_FIELDS.usage_limits}
        options={USAGE_LIMIT_LABELS}
        className={classNames({
          [classes.controlContainer]: true,
          [classes.checkboxesDesktop]: !isDesktopScreen
        })}
      />
      {maxUsesChecked ? (
        <>
          <Typography variant="caption">Max Uses</Typography>
          <TextFieldControlled
            name={PROMO_CODE_FIELDS.max_uses}
            number
            startAdornment="Qty"
            fullWidth
          />
        </>
      ) : null}
    </Box>
  )
}

UsageLimitsCheckboxes.propTypes = {
  classes: PropTypes.object.isRequired
}

export default UsageLimitsCheckboxes
