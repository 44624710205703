import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { BreadCrumbs as BreadCrumbsPropTypes } from 'constants/propTypes'

import { withStyles } from '@material-ui/core/styles'
import { Typography, Box } from '@material-ui/core'

import styles from './BreadCrumbsStyles'

const BreadCrumbs = ({ classes, crumbs = [], title = '' }) => (
  <Box display="flex" className={classes.container}>
    {crumbs.map((crumb, i) => (
      <Typography
        key={i}
        className={classNames({
          [classes.breadcrumb]: true,
          [classes.last]: crumbs.length - 1 === i
        })}
        variant="h1"
        data-test={`crumb-${crumb.title}`}
      >
        {crumb.link ? <Link to={crumb.link}>{crumb.title}</Link> : crumb.title}
      </Typography>
    ))}
    <Typography
      className={classes.title}
      variant="h1"
      data-test={`crumb-${title}`}
    >
      {title}
    </Typography>
  </Box>
)

BreadCrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  crumbs: BreadCrumbsPropTypes,
  title: PropTypes.string
}

export default withStyles(styles)(BreadCrumbs)
