import React from 'react'
import PropTypes from 'prop-types'

const ImportIcon = ({ color = '#151515', fontSize = 20 }) => (
  <svg
    width={fontSize}
    height={fontSize}
    viewBox={`0 0 ${fontSize} ${fontSize}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.3335 11.3728L3.3335 8.0278C3.3335 6.63216 4.52269 5.5 5.98935 5.5L14.011 5.5C15.4776 5.5 16.6668 6.63216 16.6668 8.0278V11.3797"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0005 10.0108V15.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.8999 11.623L10.0008 9.61398L12.1024 11.623"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ImportIcon.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.number
}

export default ImportIcon
