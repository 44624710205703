const jssStyles = theme => ({
  productCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  highStock: {
    color: theme.palette.warnings.success
  },
  lowStock: {
    color: theme.palette.warnings.danger
  },
  stockCell: {
    whiteSpace: 'nowrap'
  }
})

export default jssStyles
