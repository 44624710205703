import * as yup from 'yup'
import { STATUS_DOT_VARIANTS } from './enums'

export const TRANSFER_ORDER_STATE_TYPES = {
  draft: 'draft',
  submitted: 'submitted',
  in_progress: 'in_progress',
  receiving: 'receiving',
  receiving_complete: 'receiving_complete',
  closed: 'closed'
}

export const TRANSFER_ORDER_STATE_LABELS = [
  {
    value: TRANSFER_ORDER_STATE_TYPES.draft,
    label: 'Open',
    indicator: STATUS_DOT_VARIANTS.blue
  },
  {
    value: TRANSFER_ORDER_STATE_TYPES.submitted,
    label: 'Submitted',
    indicator: STATUS_DOT_VARIANTS.green
  },
  {
    value: TRANSFER_ORDER_STATE_TYPES.in_progress,
    label: 'Receiving',
    indicator: STATUS_DOT_VARIANTS.blue
  },
  {
    value: TRANSFER_ORDER_STATE_TYPES.closed,
    label: 'Closed',
    indicator: STATUS_DOT_VARIANTS.gray
  }
]

export const TO_LIST_COLUMNS = [
  {
    title: 'Transfer #'
  },
  { title: 'From Location' },
  { title: 'To Location' },
  { title: 'Order Date' },
  {
    title: 'ETA'
  },
  {
    title: 'Status'
  }
]

export const STATUS_FILTER_ITEMS = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  },
  {
    label: 'All',
    value: ''
  }
]

export const TO_FIELDS = {
  origin_location_id: 'origin_location_id',
  destination_location_id: 'destination_location_id',
  order_date: 'order_date',
  estimated_arrival_at: 'estimated_arrival_at',
  memo: 'memo'
}

export const TO_SUBMIT_EDITABLE_SCHEMAS = {
  [TO_FIELDS.estimated_arrival_at]: yup
    .string()
    .ensure()
    .required('Estimated Arrival is a required field')
}

export const TO_SCHEMAS = {
  [TO_FIELDS.origin_location_id]: yup.string().required(),
  [TO_FIELDS.destination_location_id]: yup.string().required(),
  [TO_FIELDS.order_date]: yup
    .string()
    .ensure()
    .required('Order Date is a required field'),
  [TO_FIELDS.memo]: yup.string(),
  ...TO_SUBMIT_EDITABLE_SCHEMAS
}

export const TO_FORM_DEFAULTS = {
  [TO_FIELDS.origin_location_id]: '',
  [TO_FIELDS.destination_location_id]: '',
  [TO_FIELDS.order_date]: null,
  [TO_FIELDS.estimated_arrival_at]: null,
  [TO_FIELDS.memo]: ''
}

export const SHIPMENT_ITEM_COLUMNS = [
  {
    title: 'Vendor Style #'
  },
  { title: 'Product Name' },
  { title: 'SKU' },
  { title: 'Color' },
  { title: 'Size' },
  { title: 'MSRP' },
  { title: 'Ordered' },
  { title: 'Received' },
  { title: 'Damaged' },
  { title: '' }
]
