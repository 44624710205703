import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import ColorCircle from 'components/ColorCircle'

import styles from './ColorListStyles'

const MasterColorsItem = ({ classes, color }) => (
  <TableRow data-test="master-color-item">
    <TableCell>
      <Box display="flex">
        <ColorCircle hex={color?.hex} image={color?.multi_color_url} />
        <Box mr={1} />
        {color.label}
      </Box>
    </TableCell>

    <TableCell>{color.colors_count || '...'}</TableCell>
    <TableCell>
      {color.created_at ? moment(color.created_at).format('L') : '...'}
    </TableCell>
    <TableCell className={classes.statusCell}>
      <Box
        className={classNames({
          [classes.statusTag]: true,
          [classes.active]: true
        })}
      >
        Active
      </Box>
    </TableCell>
    <TableCell />
  </TableRow>
)

MasterColorsItem.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.object,
  onClickItem: PropTypes.func,
  dataTest: PropTypes.string
}

export default withStyles(styles)(MasterColorsItem)
