import React from 'react'
import PropTypes from 'prop-types'

const PromoCodesIcon = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.77778 5.875H17M1 11.125H15.2222M14.3333 1.5L9 15.5M9 1.5L3.66667 15.5"
      stroke="#8D8D8D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

PromoCodesIcon.propTypes = {
  className: PropTypes.string
}

export default PromoCodesIcon
