import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { useMenu } from 'hooks'

import styles from './BadgesStyles'

const BadgesItem = ({ classes, badge, onSetActive, onClickEdit }) => {
  const { open, anchorEl, handleToggleMenu, handleClose } = useMenu()

  return (
    <TableRow data-test="badges-item">
      <TableCell>{badge.name}</TableCell>
      <TableCell>{badge.products_count} Products</TableCell>
      <TableCell>{moment(badge.created_at).format('L')}</TableCell>
      <TableCell className={classes.statusCell}>
        <Box
          className={classNames({
            [classes.statusTag]: true,
            [classes.active]: badge.active,
            [classes.inactive]: !badge.active
          })}
          data-test="badges-item-status-tag"
        >
          {badge.active ? 'Active' : 'Inactive'}
        </Box>
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={e => handleToggleMenu(e)}
          data-test="badges-item-menu"
        >
          <MoreVertIcon />
          <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
            {badge.active ? (
              <MenuItem
                onClick={() => onSetActive(false)}
                data-test="badges-item-status"
              >
                Inactive
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => onSetActive(true)}
                data-test="badges-item-status"
              >
                Active
              </MenuItem>
            )}
            <MenuItem
              onClick={() => onClickEdit(badge)}
              data-test="badges-item-edit"
            >
              Edit
            </MenuItem>
          </Menu>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

BadgesItem.propTypes = {
  classes: PropTypes.object.isRequired,
  badge: PropTypes.object,
  onSetActive: PropTypes.func,
  onClickEdit: PropTypes.func
}

export default withStyles(styles)(BadgesItem)
