const jssStyles = theme => ({
  root: {
    minWidth: 328
  },
  mobileScreen: {
    minWidth: 200
  },
  input: {
    height: 44
  },
  iconButton: {
    marginRight: -8,
    padding: 4
  },
  clearIcon: {
    width: 16,
    height: 16
  }
})

export default jssStyles
