import { BoxShadow } from 'constants/styles'

const tooltipMixin = (color, backgroundColor) => ({
  color,
  backgroundColor,
  fontWeight: 'normal',
  width: 188,
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.0001)',
  borderRadius: 5,
  fontSize: 14,
  textAlign: 'center',
  minHeight: 16,
  padding: '8px 12px'
})

const jssStyles = theme => ({
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  description: {},
  tooltipDark: tooltipMixin(
    theme.palette.common.white,
    theme.palette.common.black
  ),
  tooltipLight: tooltipMixin(
    theme.palette.common.black,
    theme.palette.common.white
  ),
  tooltipBlue: tooltipMixin(
    theme.palette.primary.contrastText,
    theme.palette.primary.main
  ),
  tooltipDarkWithDescr: {
    ...tooltipMixin(theme.palette.common.white, theme.palette.common.black),
    textAlign: 'left'
  },
  tooltipLightWithDescr: {
    ...tooltipMixin(theme.palette.common.black, theme.palette.common.white),
    textAlign: 'left'
  },
  tooltipBlueWithDescr: {
    ...tooltipMixin(
      theme.palette.primary.contrastText,
      theme.palette.primary.main
    ),
    textAlign: 'left'
  },
  elevated: {
    boxShadow: BoxShadow
  },
  arrowElevated: {
    '&:before': {
      boxShadow: BoxShadow
    }
  },
  arrowDark: {
    color: theme.palette.common.black
  },
  arrowDarkOnTop: {
    color: theme.palette.common.black,
    top: '0 !important'
  },
  arrowLight: {
    color: theme.palette.common.white
  },
  arrowLightOnTop: {
    color: theme.palette.common.white,
    top: '0 !important'
  },
  arrowBlue: {
    color: theme.palette.primary.main
  },
  arrowBlueOnTop: {
    color: theme.palette.primary.main,
    top: '0 !important'
  },
  actionBox: {
    marginTop: 12
  }
})

export default jssStyles
