import React from 'react'

import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import VideoPlayer from 'components/VideoPlayer'

import { CATEGORY_TYPES, MEDIA_RESOURCE_TYPES } from 'constants/enums'
import UnknownProduct from 'assets/unknown_product.jpg'
import styles from './DisplayCollectionMediaStyles'

const CollectionBackgroundImage = ({ url }) => {
  const boxStyle = {
    backgroundColor: '#D1D1D1',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundImage: `url(${url})`
  }

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      width="100%"
      height="100%"
      style={boxStyle}
    />
  )
}

CollectionBackgroundImage.propTypes = {
  url: PropTypes.string.isRequired
}

const CollectionImage = ({ alt, url, expanded }) => {
  if (expanded) {
    return <img width="100%" height="100%" src={url} alt={alt} />
  }

  return (
    <img
      width="110"
      height="110"
      src={url}
      alt={alt}
      style={{
        borderRadius: 55,
        pointerEvents: 'none',
        objectFit: 'cover'
      }}
    />
  )
}

CollectionImage.propTypes = {
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired
}

const isVideo = ({ collection }) => {
  return (
    collection?.editorial_media_resource_type === MEDIA_RESOURCE_TYPES.video
  )
}

const DisplayCollectionMedia = ({
  classes,
  collection,
  background,
  expanded
}) => {
  if (collection.collection_type === CATEGORY_TYPES.editorial) {
    if (isVideo({ collection })) {
      if (background) {
        return (
          <Box className={classes.nonPlayableVideo}>
            <VideoPlayer url={collection.editorial_media_url} fluid />
          </Box>
        )
      }

      const expandedStyle = { flexGrow: 1 }
      const nonExpandedStyle = {
        width: 110,
        height: 110,
        display: 'flex',
        alignItems: 'center'
      }

      const videoStyle = expanded ? expandedStyle : nonExpandedStyle

      return (
        <Box {...videoStyle}>
          <VideoPlayer url={collection.editorial_media_url} fluid />
        </Box>
      )
    }
  }

  const imageUrl =
    collection?.editorial_media_url || collection.image_url || UnknownProduct

  if (background) {
    return <CollectionBackgroundImage url={imageUrl} />
  }

  return (
    <CollectionImage
      alt={collection.title}
      url={imageUrl}
      expanded={expanded}
    />
  )
}

DisplayCollectionMedia.defaultProps = {
  background: false,
  expanded: false
}

DisplayCollectionMedia.propTypes = {
  classes: PropTypes.object.isRequired,
  collection: PropTypes.object,
  background: PropTypes.bool,
  expanded: PropTypes.bool
}

export default withStyles(styles)(DisplayCollectionMedia)
