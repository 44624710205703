import React, { useEffect } from 'react'
import { Prompt } from 'react-router-dom'

import { BEFORE_UNLOAD_MESSAGE } from 'constants/general'

// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event

const useBeforeUnload = condition => {
  useEffect(() => {
    if (!condition) return

    const callback = e => {
      e.preventDefault()
      e.returnValue = ''
      return BEFORE_UNLOAD_MESSAGE
    }

    window.addEventListener('beforeunload', callback)
    return () => window.removeEventListener('beforeunload', callback)
  }, [condition])

  return {
    OnBeforeUnloadPrompt: () =>
      condition ? <Prompt message={BEFORE_UNLOAD_MESSAGE} /> : null
  }
}

export default useBeforeUnload
