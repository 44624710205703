/*

RadioTwo was created because Radio was built using label for value.
RadioTwo uses value for value.
Radio is heavily tied to RadioGroup, not advisable to use otherwise.

*/
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import MuiRadio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import * as propTypes from 'constants/propTypes'
import * as enums from 'constants/enums'

import RadioIcon from 'icons/RadioIcon'
import RadioBlankIcon from 'icons/RadioBlankIcon'

import styles from './RadioStyles'

const RadioTwo = ({
  classes,
  name,
  label,
  value,
  size,
  disabled,
  withBorder,
  onChange
}) => {
  const rootClassNames = classNames({
    [classes.root]: true,
    [classes.disabled]: disabled,
    [classes.border]: withBorder,
    [classes.mediumAndBorder]: withBorder && size === enums.SIZE.medium
  })

  const sizeInPx = size && enums.SIZE_PX[size]

  return (
    <FormControlLabel
      control={
        <MuiRadio
          name={name}
          value={value}
          size={size}
          disabled={disabled}
          onChange={onChange}
          icon={<RadioBlankIcon fontSize={sizeInPx} />}
          checkedIcon={<RadioIcon fontSize={sizeInPx} />}
        />
      }
      label={label}
      className={rootClassNames}
      disabled={disabled}
    />
  )
}

RadioTwo.defaultProps = {
  label: false,
  size: enums.SIZE.medium,
  disabled: false
}

RadioTwo.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.node,
  size: propTypes.size,
  disabled: PropTypes.bool,
  withBorder: PropTypes.bool,
  onChange: PropTypes.func
}

export default withStyles(styles)(RadioTwo)
