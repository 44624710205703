import { useMutation, useQueryClient } from 'react-query'

import { api } from 'api'

import { useResourceRQ, useResourceListRQ } from 'hooks'
import {
  fileInputProcessor,
  multiPartFormParams
} from 'hooks/common/useResourceRQ'

import {
  purchaseOrderVariantsUpdateEndpoint,
  purchaseOrderVariantsEndpoint,
  purchaseOrderCsvImportEndpoint,
  variantPurchaseOrderListEndpoint,
  purchaseOrderVariantsReceiveEndpoint
} from 'api/endpoints'

const useAdminPurchaseOrderVariantList = (
  purchaseOrderId,
  params,
  { onImportCSVSuccess, onCreateSuccess, type } = {}
) => {
  const queryClient = useQueryClient()

  const {
    isFetching,
    data: variants,
    hasData: hasVariants,
    meta: variantsMeta
  } = useResourceListRQ({
    endpoint: variantPurchaseOrderListEndpoint,
    params,
    args: [],
    fetchingEnabled: Boolean(purchaseOrderId)
  })

  const { operate: createPurchaseOrderVariants } = useResourceRQ({
    endpoint: purchaseOrderVariantsEndpoint,
    args: [purchaseOrderId],
    fetchingEnabled: false,
    inputProcessor: res => {
      return {
        purchase_order: {
          variant_quantities: res
        }
      }
    },
    resetQueries: [
      () => [
        variantPurchaseOrderListEndpoint(),
        { purchase_order_id: purchaseOrderId }
      ]
    ],
    onSuccess: onCreateSuccess
  })

  const { operate: importCSV } = useResourceRQ({
    endpoint: purchaseOrderCsvImportEndpoint,
    args: [purchaseOrderId],
    fetchingEnabled: false,
    inputProcessor: fileInputProcessor('purchase_order[csv]'),
    params: multiPartFormParams,
    resetQueries: [
      () => [
        variantPurchaseOrderListEndpoint(),
        { purchase_order_id: purchaseOrderId }
      ]
    ],
    onSuccess: onImportCSVSuccess
  })

  const { mutate: updateVariant } = useMutation(
    ({ variant, payload }) =>
      api.put(
        purchaseOrderVariantsUpdateEndpoint(purchaseOrderId, variant, type),
        {
          purchase_order_variant: payload
        },
        { params }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(variantPurchaseOrderListEndpoint(), {
          purchase_order_id: purchaseOrderId
        })
      }
    }
  )

  const updatePurchaseOrderVariants = async resources => {
    resources.forEach(variant => {
      const payload = {
        quantity: variant?.quantity,
        received: variant?.received,
        damaged: variant?.damaged,
        receiving_complete: variant?.receiving_complete,
        quantity_in_bins: variant?.quantity_in_bins,
        receiving_started: variant?.receiving_started,
        backstock_quantity: variant?.backstock_quantity,
        new_bins: variant?.new_bins
      }
      // only update things that have been specified
      Object.keys(payload).forEach(
        key => payload[key] === undefined && delete payload[key]
      )
      updateVariant({ variant, payload })
    })
  }

  const { operate: receivePurchaseOrderVariant } = useMutation(
    ({ variant }) =>
      api.put(purchaseOrderVariantsReceiveEndpoint(purchaseOrderId, variant)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(variantPurchaseOrderListEndpoint(), {
          purchase_order_id: purchaseOrderId
        })
      }
    }
  )

  const { mutate: deletePurchaseOrderVariant } = useMutation(
    variant =>
      api.delete(purchaseOrderVariantsUpdateEndpoint(purchaseOrderId, variant)),
    {
      onSuccess: () => {
        queryClient.resetQueries(variantPurchaseOrderListEndpoint(), {
          purchase_order_id: purchaseOrderId
        })
      }
    }
  )

  return {
    createPurchaseOrderVariants,
    updatePurchaseOrderVariants,
    receivePurchaseOrderVariant,
    deletePurchaseOrderVariant,
    importCSV,
    isFetching,
    variants: variants || [],
    hasVariants,
    variantsMeta
  }
}

export default useAdminPurchaseOrderVariantList
