const jssStyles = theme => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      flexGrow: 1
    },
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1)
    },
    '& .MuiInputBase-root': {
      minWidth: 'unset'
    }
  }
})
export default jssStyles
