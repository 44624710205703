import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import DeliveryIcon from 'assets/carrier@2x.png'
import CustomerIcon from 'icons/CustomerIcon'
import DarkstoreIcon from 'assets/darkstore_dot.png'
import GoogleMap from 'components/GoogleMap'
import GoogleMapMarker from 'components/GoogleMapMarker'

import styles from './OrderMapStyles'

const SQUARE_FORTY = [40, 40]
const SQUARE_TWENTY = [20, 20]
const SQUARE_THIRTY = [30, 30]

const OrderMap = ({ classes, order }) => {
  return order?.latitude && order?.longitude ? (
    <GoogleMap lat={order.latitude} lng={order.longitude}>
      {order.deliveryLatitude && (
        <GoogleMapMarker
          src={DeliveryIcon}
          size={SQUARE_FORTY}
          lat={order.deliveryLatitude}
          lng={order.deliveryLongitude}
        />
      )}

      <GoogleMapMarker
        srcSvg={<CustomerIcon />}
        size={SQUARE_TWENTY}
        lat={order.latitude}
        lng={order.longitude}
      />

      {order.location?.latitude && order.location?.longitude && (
        <GoogleMapMarker
          src={DarkstoreIcon}
          size={SQUARE_THIRTY}
          lat={order.location.latitude}
          lng={order.location.longitude}
        />
      )}
    </GoogleMap>
  ) : (
    <Box className={classes.mapPlaceholder} py={3} px={2}>
      <Typography variant="h5">Unknown Order Lat/Long</Typography>
    </Box>
  )
}

OrderMap.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object
}

export default withStyles(styles)(OrderMap)
