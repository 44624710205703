import { LIST_PATHS } from './queryParams'

export const COLLECTION_TYPES = {
  brand: 'brand',
  category: 'category',
  editorial: 'editorial'
}

export const COLLECTION_DISPLAY_TYPES = {
  defaultSlider: 'defaultSlider',
  editorial: 'editorial'
}

export const DROPZONE_IMAGE_TYPES = 'image/jpg, image/jpeg, image/png'
export const DROPZONE_EDITORIAL_MEDIA_TYPES =
  'image/jpg, image/jpeg, image/png, video/mp4'

export const DESTROY_COMMAND = 'DESTROY'

export const COLLECTION_IMAGE_WIDTH_PX = 1125

export const COLLECTION_IMAGE_HEIGHT_PX = 975

export const EDITORIAL_COVER_WIDTH_PX = 1029
export const EDITORIAL_COVER_HEIGHT_PX = 636

export const COLLECTION_DESCRIPTION_MAX_CHARACTERS = 200

export const DROPZONE_IMAGE_PREVIEW_HEIGHT_PX = Math.ceil(
  (321 * COLLECTION_IMAGE_HEIGHT_PX) / COLLECTION_IMAGE_WIDTH_PX
)

export const COLLECTIONS_BLOCK_ASPECT_RATIO = 281 / 161

export const COLLECTIONS_TYPE_OPTIONS = [
  { value: COLLECTION_TYPES.brand, label: 'Brand Collection' },
  { value: COLLECTION_TYPES.category, label: 'Category' },
  { value: COLLECTION_TYPES.editorial, label: 'Editorial Collection' }
]

export const COLLECTIONS_DISPLAY_TYPE_OPTIONS = [
  { value: COLLECTION_DISPLAY_TYPES.defaultSlider, label: 'Default Slider' },
  { value: COLLECTION_DISPLAY_TYPES.editorial, label: 'Editorial' }
]

export const COLLECTION_TYPE_BY_LABEL = Object.fromEntries(
  COLLECTIONS_TYPE_OPTIONS.map(t => [t.label, t.value])
)

export const COLLECTION_TYPE_BY_VALUE = Object.fromEntries(
  COLLECTIONS_TYPE_OPTIONS.map(t => [t.value, t.label])
)

export const PRODUCT_SORT_KEYS = {
  LEAST_POPULAR: 'most_popular,asc',
  MOST_POPULAR: 'most_popular,desc',
  LOWEST_PRICE: 'price,asc',
  HIGHEST_PRICE: 'price,desc',
  TITLE_ALPHABETICAL: 'product_title,asc',
  TITLE_REVERSE: 'product_title,desc',
  OLDEST: 'created_at,asc',
  NEWEST: 'created_at,desc',
  DEFAULT: 'default'
}

export const PRODUCT_SORT_LABELS = {
  MOST_POPULAR: 'Most popular',
  LOWEST_PRICE: 'Lowest price',
  HIGHEST_PRICE: 'Highest price',
  TITLE_ALPHABETICAL: 'Alphabetical',
  TITLE_REVERSE: 'Reverse Alphabetical',
  OLDEST: 'Oldest',
  NEWEST: 'Newest',
  DEFAULT: 'Default'
}

export const PRODUCT_SORT_OPTIONS = Object.entries(
  PRODUCT_SORT_KEYS
).map(([key, value]) => ({ label: PRODUCT_SORT_LABELS[key], value }))

export const IS_PARENT = LIST_PATHS.COLLECTIONS_PARENT
export const IS_CHILD = LIST_PATHS.COLLECTIONS_CHILD

export const COLLECTION_RELATIONSHIP_VALUES = {
  PARENT: 'isParent',
  CHILD: 'isChild',
  ALL: ''
}
export const COLLECTION_RELATIONSHIP = {
  [COLLECTION_RELATIONSHIP_VALUES.PARENT]: {
    [IS_PARENT]: true,
    [IS_CHILD]: ''
  },
  [COLLECTION_RELATIONSHIP_VALUES.CHILD]: { [IS_PARENT]: '', [IS_CHILD]: true },
  [COLLECTION_RELATIONSHIP_VALUES.ALL]: { [IS_PARENT]: '', [IS_CHILD]: '' }
}

export const COLLECTION_RELATIONSHIP_OPTIONS = [
  { label: 'Parent', value: COLLECTION_RELATIONSHIP_VALUES.PARENT },
  { label: 'Child', value: COLLECTION_RELATIONSHIP_VALUES.CHILD },
  { label: 'All', value: COLLECTION_RELATIONSHIP_VALUES.ALL }
]

export const COLLECTION_CAN_BE_PARENT = {
  [IS_CHILD]: false,
  [LIST_PATHS.COLLECTIONS_COLLECTION_TYPE]: [
    COLLECTION_TYPES.category,
    COLLECTION_TYPES.editorial
  ]
    .join(',')
    .trim(),
  'collections.collection_type.rel': 'in'
}

export const COLLECTION_CAN_BE_CHILD = {
  // A collection can only have one parent
  [IS_PARENT]: false,
  [LIST_PATHS.COLLECTIONS_COLLECTION_TYPE]: COLLECTION_TYPES.category
}

export const COLLECTION_CAN_BE_EDITORIAL = {
  [LIST_PATHS.COLLECTIONS_COLLECTION_TYPE]: COLLECTION_TYPES.editorial
}
