import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

import { useAdminSupplierList, useNavigation, useQuery } from 'hooks'
import { SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'
import { LIST_PATHS } from 'constants/queryParams'

import { TitleElement } from 'components/SiteTitle'
import Button from 'components/Button'
import DataTable from 'components/DataTable'
import Header from 'components/Header'
import Layout from 'components/Layout'
import SearchInput from 'components/SearchInput'

import SupplierItem from './SupplierItem'

import styles from './SuppliersListStyles'

const columns = [
  {
    title: 'Name',
    sortKeys: [LIST_PATHS.SUPPLIER_NAME]
  },
  { title: 'Contact' },
  { title: '' }
]

const SuppliersList = ({ classes }) => {
  const { query, handleQueryChange, updateQuery } = useQuery(false, {})
  const {
    suppliers,
    hasSuppliersNext,
    isFetchingSuppliers,
    fetchSuppliersNext
  } = useAdminSupplierList(query)

  const { handleAddClick: createNewSupplierClick } = useNavigation({
    url: URL.ADMIN_SUPPLIERS
  })

  const foldableActions = (
    <>
      <SearchInput
        name="search"
        placeholder={`Search Suppliers's...`}
        value={query.search}
        onChange={handleQueryChange}
        dataTest="suppliers-search"
      />
    </>
  )

  const actions = (
    <>
      <Button
        adaptive
        startIcon={<AddIcon />}
        color="primary"
        variant="contained"
        label="new supplier button"
        size={SIZE.medium}
        onClick={createNewSupplierClick}
        dataTest="new-supplier"
      >
        Supplier
      </Button>
    </>
  )
  const pageTitle = 'Suppliers'

  return (
    <Layout className={classes.root} id="SuppliersList">
      <TitleElement title={pageTitle} />
      <Header
        title={pageTitle}
        actions={actions}
        foldableActions={foldableActions}
      />
      <DataTable
        columns={columns}
        message={
          isFetchingSuppliers || suppliers?.length > 0
            ? null
            : 'No Suppliers Found'
        }
        isLoadingList={isFetchingSuppliers}
        hasListNext={hasSuppliersNext}
        listNext={fetchSuppliersNext}
        query={query}
        updateQuery={updateQuery}
      >
        {suppliers?.map(supplier => (
          <SupplierItem supplier={supplier} key={supplier.id} />
        ))}
      </DataTable>
    </Layout>
  )
}

SuppliersList.defaultProps = {}

SuppliersList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SuppliersList)
