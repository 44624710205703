import { api, downloadApi } from 'api'
import { promoCodesCsvImportEndpoint } from 'api/endpoints'
import { useResourceList, useResource, useResourceRQ, useAlerts } from 'hooks'
import {
  multiPartFormParams,
  fileInputProcessor
} from 'hooks/common/useResourceRQ'

const API_PROMO_CODES = '/api/v1/admin/discount_codes'
const API_RESOURCE_NAME = 'discount_code'
const API_HISTORY = 'discount_code_history'

const useAdminPromoCodes = (
  id = null,
  { locationId } = {},
  { onImportCSVSuccess } = {}
) => {
  const { showAlertError } = useAlerts()

  const {
    isLoading: isLoadingPromoCodes,
    data: promoCodes,
    hasData: hasPromoCodes,
    hasNext: hasPromoCodesNext,
    list: listPromoCodes,
    listNext: listPromoCodesNext,
    error: promoCodesError,
    count: promoCodesCount,
    read: readPromoCodes,
    relist: relistPromoCodes,
    updateItemsLocally: updatePromoCodesLocally,
    meta: promosMeta
  } = useResourceList({ url: API_PROMO_CODES })

  const {
    isLoading: isLoadingPromoCodesHistory,
    data: promoCodesHistory,
    hasNext: hasPromoCodesHistoryNext,
    list: listPromoCodesHistory,
    listNext: listPromoCodesHistoryNext,
    error: promoCodesHistoryError,
    count: promoCodesHistoryCount,
    read: readPromoCodesHistory
  } = useResourceList({ url: `${API_PROMO_CODES}/${id}/${API_HISTORY}` })

  const {
    data: promoCode,
    read: readPromoCode,
    create: createPromoCode,
    update: updatePromoCode,
    hasData: hasPromoCode,
    isLoading: isLoadingPromoCode,
    errors: promoCodeError,
    destroy: deletePromoCode
  } = useResource({
    url: API_PROMO_CODES,
    name: API_RESOURCE_NAME
  })

  const { operate: importCSV } = useResourceRQ({
    endpoint: promoCodesCsvImportEndpoint,
    args: [id],
    fetchingEnabled: false,
    inputProcessor: fileInputProcessor('eligible_users[csv]'),
    params: multiPartFormParams,
    onSuccess: onImportCSVSuccess
  })

  const exportPromosToCsv = promoCodeIds =>
    locationId
      ? downloadApi.get(
          `${API_PROMO_CODES}/export_csv?discount_code_ids=${promoCodeIds
            .join(',')
            .trim()}&location_id=${locationId}`
        )
      : showAlertError('Please select a location to export promo codes to CSV')

  const exportEligibleUsers = () =>
    downloadApi.get(`${API_PROMO_CODES}/${id}/export_eligible_users_csv`)

  const bulkCreate = async data => {
    try {
      const res = await api.post(`${API_PROMO_CODES}/bulk_create`, data)
      return res.data
    } catch (e) {
      showAlertError(e.message)
      console.error(e)
    }
  }

  const generatePromoCode = async () => {
    try {
      const res = await api.get(`${API_PROMO_CODES}/generate_code`)
      return res.data
    } catch (e) {
      showAlertError(e.message)
      console.error(e)
    }
  }

  return {
    readPromoCodes,
    isLoadingPromoCodes,
    promoCodes,
    promoCodesError,
    promoCodesCount,
    hasPromoCodes,
    hasPromoCodesNext,
    relistPromoCodes,
    listPromoCodes,
    listPromoCodesNext,
    promoCode,
    hasPromoCode,
    isLoadingPromoCode,
    readPromoCode,
    createPromoCode,
    updatePromoCode,
    promoCodeError,
    deletePromoCode,

    updatePromoCodesLocally,
    generatePromoCode,
    bulkCreate,
    isLoadingPromoCodesHistory,
    promoCodesHistory,
    hasPromoCodesHistoryNext,
    listPromoCodesHistory,
    listPromoCodesHistoryNext,
    promoCodesHistoryError,
    promoCodesHistoryCount,
    readPromoCodesHistory,
    exportPromosToCsv,
    exportEligibleUsers,
    importCSV,
    promosMeta
  }
}

export default useAdminPromoCodes
