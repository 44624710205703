import React from 'react'
import { Player } from 'video-react'
import PropTypes from 'prop-types'
import 'video-react/dist/video-react.css'

const VideoPlayer = ({ url, ...options }) =>
  url ? (
    <Player {...options}>
      <source src={url} />
    </Player>
  ) : (
    'Error loading video'
  )

VideoPlayer.propTypes = {
  url: PropTypes.string
}

export default VideoPlayer
