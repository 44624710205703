import { LIST_PATHS } from 'constants/queryParams'
import { useResourceRQ, useResourceListRQ } from 'hooks'
import {
  accesoryOptionsEndpoint,
  accesoryOptionsActiveEndpoint
} from 'api/endpoints'

const API_RESOURCE_NAME = 'accessory_option'
const ACTIVE_STATUS_PATH = `${LIST_PATHS.ACCESSORY_OPTIONS_STATUS}=true`

const useAdminAccessoryOptions = params => {
  const {
    isFetching: isLoadingAccessoryOptions,
    data: accessoryOptions,
    hasData: hasAccessoryOptions,
    hasNextPage: hasAccessoryOptionsNext,
    count: accessoryOptionsCount,
    fetchNextPage: listAccessoryOptionsNext
  } = useResourceListRQ({ endpoint: accesoryOptionsEndpoint, params })

  const { count: activeAccessoryOptionsCount } = useResourceListRQ({
    endpoint: accesoryOptionsActiveEndpoint,
    args: [ACTIVE_STATUS_PATH]
  })

  const {
    create: createAccessoryOption,
    update: updateAccessoryOption
  } = useResourceRQ({
    endpoint: accesoryOptionsEndpoint,
    name: API_RESOURCE_NAME,
    activeLoaders: true,
    showServerError: true,
    onSuccess: {
      updateMsg: () => 'Accessory option successfully updated',
      createMsg: () => 'Accessory option successfully created'
    },
    dependents: [
      () => accesoryOptionsEndpoint(),
      () => accesoryOptionsActiveEndpoint(ACTIVE_STATUS_PATH)
    ],
    fetchingEnabled: false
  })

  return {
    isLoadingAccessoryOptions,
    accessoryOptions,
    hasAccessoryOptions,
    hasAccessoryOptionsNext,
    accessoryOptionsCount,
    listAccessoryOptionsNext,
    createAccessoryOption,
    updateAccessoryOption,
    activeAccessoryOptionsCount
  }
}

export default useAdminAccessoryOptions
