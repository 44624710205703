import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { priceLabel, getProductImage } from 'utils/products'
import { DEFAULT_VARIANT_TITLE } from 'constants/general'

import UnknownProduct from 'assets/unknown_product.jpg'

import styles from './ProductSummaryStyles'

// product could be a variant object
const ProductSummary = ({
  classes,
  product,
  showQuantity,
  showSku,
  showVariantTitle,
  endAdornment,
  onClick,
  className,
  productVariantListItem
}) => {
  const image = productVariantListItem
    ? product?.dashboard_images?.[0]?.thumbnail_url
    : getProductImage(product)

  const brand = product.brand || product.product?.brand

  let title =
    product.title === DEFAULT_VARIANT_TITLE || showVariantTitle
      ? product.product?.title
      : product.title || product.product_title

  const optionsList = [
    product?.size,
    product?.color?.label,
    product?.accessory_option?.name
  ]
    .filter(Boolean)
    .join(', ')

  if (productVariantListItem && !showVariantTitle) {
    title = optionsList
  }

  return (
    <Box
      className={classNames({
        [className]: Boolean(className),
        [classes.clickable]: Boolean(onClick)
      })}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      onClick={onClick}
      onAuxClick={onClick}
      data-test="product-item"
    >
      <Box flexShrink={0} height={80} width={80} mr="24px">
        <img
          src={image || UnknownProduct}
          alt={title}
          className={classes.image}
        />
      </Box>
      <Box flexGrow={1}>
        <Typography
          variant="overline"
          className={classes.brand}
          data-test="product-item-brand"
        >
          {brand}
        </Typography>
        <Typography className={classes.title} data-test="product-item-title">
          {title}
        </Typography>
        {showVariantTitle && (
          <Typography variant="body1">{optionsList}</Typography>
        )}
        {showSku && <Typography variant="body1">{product.sku}</Typography>}
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle2">{priceLabel(product)}</Typography>
          {showQuantity && !endAdornment ? (
            <Typography variant="subtitle2">
              Qty {product.quantity || product.inventory_quantity}
            </Typography>
          ) : null}
          {endAdornment}
        </Box>
      </Box>
    </Box>
  )
}

ProductSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  showQuantity: PropTypes.bool,
  showSku: PropTypes.bool,
  showVariantTitle: PropTypes.bool,
  endAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  productVariantListItem: PropTypes.bool
}

export default withStyles(styles)(ProductSummary)
