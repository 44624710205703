import { SIZE_PX } from 'constants/enums'

const alignMiddle = {
  display: 'inline-block',
  verticalAlign: ' middle'
}

const jssStyles = theme => ({
  root: {
    position: 'relative',
    borderRadius: 5,
    borderColor: theme.palette.grays.med,
    border: 'solid 1px',
    padding: theme.spacing(1),
    margin: theme.spacing(0, 0.5),
    textAlign: 'center',
    ...alignMiddle
  },
  value: {
    color: theme.palette.text.primary,
    ...alignMiddle
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    lineHeight: '12px',
    maxWidth: 65,
    textTransform: 'none',
    marginLeft: theme.spacing(1),
    textAlign: 'left',
    ...alignMiddle
  },
  isActionable: {
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.grays.med,
      backgroundColor: theme.palette.blues.dark
    },
    '&:hover $value': {
      color: theme.palette.common.white
    },
    '&:hover $label': {
      color: theme.palette.blues.light
    }
  },
  isActive: {
    borderColor: theme.palette.common.black
  },
  large: {
    padding: theme.spacing(4),
    borderColor: theme.palette.grays.med,
    borderRadius: 0,

    '& $value': {
      margin: 8,
      fontSize: SIZE_PX.xlarge,
      lineHeight: `${SIZE_PX.xlarge}px`,
      fontWeight: 600,
      textAlign: 'center',
      display: 'block'
    },
    '& $label': {
      fontSize: 14,
      lineHeight: '21px',
      color: theme.palette.text.secondary,
      maxWidth: '100%',
      textAlign: 'center',
      marginLeft: 0,
      fontWeight: 100,
      margin: theme.spacing(1),
      display: 'block'
    }
  },
  minimal: {
    border: 'none',

    '& $label': {
      textTransform: 'uppercase',
      color: theme.palette.text.secondary,
      fontSize: 10,
      fontWeight: 300,
      letterSpacing: 1
    }
  },
  updateButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(1, 2),
    color: theme.palette.grays.dark,
    borderColor: theme.palette.grays.dark,
    height: 'auto',

    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  isMobile: {
    '& $label': {},

    '&$large': {
      padding: theme.spacing(2),

      '& $value': {
        fontSize: 36,
        lineHeight: '40px'
      },
      '& $label': {
        marginLeft: '8px !important'
      }
    },

    '& $updateButton': {
      padding: theme.spacing(1),

      '& .MuiSvgIcon-root': {
        height: '.85em',
        width: '.85em'
      }
    }
  }
})

export default jssStyles
