import { useResourceListRQ, useResourceRQ } from 'hooks'
import { storefrontsEndpoint } from 'api/endpoints'

const useAdminStorefronts = ({ params, fetchingEnabled }) => {
  const {
    isLoading: isLoadingStorefronts,
    data: storefronts
  } = useResourceListRQ({
    endpoint: storefrontsEndpoint,
    params,
    options: {
      fetchingEnabled
    }
  })

  const { create: createStorefront } = useResourceRQ({
    name: 'storefront',
    endpoint: storefrontsEndpoint,
    fetchingEnabled: false
  })

  return {
    isLoadingStorefronts,
    storefronts,
    createStorefront
  }
}

export default useAdminStorefronts
