import { useResource, useResourceList } from 'hooks'
import { api } from 'api'

const API_ZIPCODES = '/api/v1/zipcodes'
const API_ADMIN_ZIPCODES = '/api/v1/admin/zipcodes'
const API_RESOURCE_NAME = 'zipcode'

const useAdminZipCodes = () => {
  const {
    isLoading: isLoadingZipCodes,
    data: zipcodes,
    hasData: hasZipCodes,
    hasNext: hasZipCodesNext,
    list: listZipCodes,
    listNext: listZipCodesNext,
    error: zipcodesError
  } = useResourceList({ url: API_ZIPCODES })

  const {
    bulkUpdate: updateBulkZipCodes,
    destroy: deleteZipCode,
    update: updateZipCodes
  } = useResource({
    url: API_ADMIN_ZIPCODES,
    name: API_RESOURCE_NAME
  })

  return {
    isLoadingZipCodes,
    zipcodes,
    hasZipCodes,
    hasZipCodesNext,
    listZipCodes,
    listZipCodesNext,
    zipcodesError,
    updateZipCodes,
    updateBulkZipCodes,
    deleteZipCode
  }
}

export default useAdminZipCodes
