import React from 'react'

const CryptoPaymentIcon = ({ size, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 198 198"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M177.018 109.84C177.018 109.84 177.018 109.87 177.018 109.88H98.2581L102.478 83.13L196.618 83.38C196.618 83.38 196.028 78.38 193.028 68.38C190.938 61.41 188.248 56.38 188.248 56.38C172.388 23.2 138.488 0.219971 99.3281 0.219971C45.0181 0.219971 0.828125 44.41 0.828125 98.72C0.828125 153.03 45.0181 197.22 99.3281 197.22C149.888 197.22 191.658 158.93 197.198 109.83H177.028L177.018 109.84ZM20.8181 98.73C20.8181 55.44 56.0281 20.23 99.3181 20.23C127.018 20.23 151.388 34.66 165.368 56.39H75.7281L56.8381 164.69C35.1881 150.7 20.8181 126.37 20.8181 98.73ZM99.3181 177.23C95.1581 177.23 91.0681 176.9 87.0781 176.27L94.0281 138.04L167.058 138.31C153.418 161.57 128.178 177.23 99.3181 177.23Z"
      fill="url(#paint0_linear_1756_20501)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1756_20501"
        x1="0.818125"
        y1="98.73"
        x2="197.188"
        y2="98.73"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF50DD" />
        <stop offset="0.18" stop-color="#AD6BDA" />
        <stop offset="0.37" stop-color="#6085D8" />
        <stop offset="0.51" stop-color="#3095D7" />
        <stop offset="0.57" stop-color="#1E9CD7" />
        <stop offset="0.95" stop-color="#42D6BE" />
      </linearGradient>
    </defs>
  </svg>
)

export default CryptoPaymentIcon
