const jssStyles = theme => ({
  caption: {
    color: theme.palette.grays.dark,
    fontSize: '10px',
    fontWeight: 400,
    marginTop: '-10px',
    marginBottom: '10px'
  },
  textCounter: {
    color: theme.palette.grays.dark,
    fontWeight: '400',
    textAlign: 'right',
    marginTop: '-20px',
    paddingRight: '10px'
  },
  textField: {
    "& input": {
      padding: 0,
      paddingLeft: '12px',
      height: '44px',
    }
  },
  textArea: {
    "& textarea": {
      padding: "15px 12px",
      height: '44px !important',
    }
  },
  imageFieldContainer: {
    height: "180px",
    width: "200px",
    margin: "auto",
    marginTop: "10px",
    marginBottom: "20px",
  },
  selectContainer: {
    height: '44px',
    margin: '10px 0px'
  }
})

export default jssStyles
