import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import { AiFillStar, AiOutlineStar } from 'react-icons/ai'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { trimStringAndAppend } from 'utils/general'
import { priceLabel, stockSummary } from 'utils/products'
import { PUBLISHED_STATUS_VARIANT, ICON_BUTTON_VARIANT } from 'constants/enums'

import PublishStatusIndicator from 'components/PublishStatusIndicator'
import IconButton from 'components/IconButton'
import RemoveIcon from 'icons/RemoveIcon'
import CheckIcon from 'icons/CheckIcon'
import GrabIcon from 'icons/GrabIcon'
import AmazonIcon from 'components/AmazonIcon'
import UnknownProduct from 'assets/unknown_product.jpg'

import styles from './DraggableProductStyles'

const DraggableProduct = ({
  classes,
  item: product,
  width,
  height,
  locationId,
  onRemove,
  selected,
  featured,
  onSelectClick,
  onFeatureClick,
  draggable,
  showAmazonIcon
}) => {
  // Quantity can be missing from if the variant inventory isn't included.
  const quantity = stockSummary(product, locationId)?.quantity
  return (
    <Box
      width={width}
      height={height}
      className={classNames({
        [classes.root]: true,
        [classes.faded]: onSelectClick && !selected,
        [classes.featured]: onFeatureClick && featured
      })}
    >
      <Box display="flex" flexDirection="column" className={classes.details}>
        <Box className={classes.image}>
          <img
            src={product.images?.[0]?.thumbnail_url || UnknownProduct}
            alt={product.title}
          />
        </Box>
        <Box flexGrow={1} mt={1}>
          <Typography variant="overline" color="textSecondary">
            {product.brand}
          </Typography>
          <Typography variant="body1">
            {trimStringAndAppend(product.title, 30, '...')}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography>{priceLabel(product)}</Typography>
          <Typography color="textSecondary">
            QTY {Number.isInteger(quantity) ? quantity : '??'}
          </Typography>
        </Box>
      </Box>
      <Box
        className={classes.overlay}
        display="flex"
        justifyContent="space-between"
      >
        <Box>{draggable && <GrabIcon className={classes.grab} />}</Box>
        <Box display="flex" flexDirection="row" height={20}>
          {showAmazonIcon && <AmazonIcon />}

          <Box ml={0.75}>
            <PublishStatusIndicator
              size={23}
              variant={
                product?.location_ids.includes(locationId)
                  ? PUBLISHED_STATUS_VARIANT.active
                  : PUBLISHED_STATUS_VARIANT.inactive
              }
            />
          </Box>

          {onRemove && (
            <Box ml={1} mt="3px">
              <IconButton
                size={18}
                circle
                variant={ICON_BUTTON_VARIANT.black}
                onClick={() => onRemove(product)}
              >
                <RemoveIcon fontSize={12} />
              </IconButton>
            </Box>
          )}

          {onFeatureClick && (
            <Box ml={1} mt="3px">
              <IconButton
                size={18}
                circle
                variant=""
                onClick={() => onFeatureClick(product.id)}
              >
                {featured ? (
                  <AiFillStar fontSize={18} color="#FFD700" />
                ) : (
                  <AiOutlineStar fontSize={18} />
                )}
              </IconButton>
            </Box>
          )}

          {onSelectClick && (
            <Box ml={1} mt="3px">
              <IconButton
                size={18}
                circle
                variant={
                  selected
                    ? ICON_BUTTON_VARIANT.blue
                    : ICON_BUTTON_VARIANT.light
                }
                onClick={() => onSelectClick(product.id)}
              >
                <CheckIcon fontSize={8} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

DraggableProduct.defaultProps = {}

DraggableProduct.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  locationId: PropTypes.number,
  selected: PropTypes.bool,
  onRemove: PropTypes.func,
  onSelectClick: PropTypes.func,
  draggable: PropTypes.bool,
  showAmazonIcon: PropTypes.bool
}

export default withStyles(styles)(DraggableProduct)
