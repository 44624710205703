import React from 'react'

import Tabs from 'components/Tabs'

import CollectionList from 'containers/admin/CollectionList'
import CollectionGroupList from 'containers/admin/CollectionGroupList.jsx'

import { useUIContext } from 'context'

export const TAB_ORDER = ['collections', 'collectionGroups']

const CollectionTabs = () => {
  const { collectionTab, setCollectionTab } = useUIContext()

  const onChange = index => {
    setCollectionTab(index)
  }

  return (
    <Tabs
      boxProps={{ width: '100%', m: 4 }}
      defaultTabIndex={collectionTab}
      onChange={onChange}
      panes={[
        {
          label: 'Collections',
          pane: <CollectionList />
        },
        {
          label: 'Collection Groups',
          pane: <CollectionGroupList />
        }
      ]}
    />
  )
}

export default CollectionTabs
