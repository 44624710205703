import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import FilterListIcon from '@material-ui/icons/FilterList'

import { useMediaQuery, useScrollPosition } from 'hooks'
import { BreadCrumbs as BreadCrumbsPropTypes } from 'constants/propTypes'

import FilterBar from 'components/FilterBar'
import Button from 'components/Button'
import BreadCrumbs from 'components/BreadCrumbs'

import ActionDrawer from './ActionDrawer'
import ActionsContainer from './ActionsContainer'

import styles from './HeaderStyles'

const Header = ({
  classes,
  title,
  breadcrumbs,
  foldableActions,
  actions,
  summarizers,
  filters,
  actionDrawerTitle,
  sticky,
  actionBox,
  hideHeader,
  iLoveTightBottoms = false
}) => {
  const [drawerOpen, setDrawer] = useState(false)
  const showActions = actions && <ActionsContainer actions={actions} />

  const {
    isMobileScreen,
    isDesktopScreen: isDesktopOrSmaller,
    isDesktopLargeScreen: isDesktopOrLarger
  } = useMediaQuery()

  const showActionDrawer =
    (isMobileScreen || isDesktopOrSmaller) &&
    Boolean(filters || foldableActions)
  const { hasScrolled } = useScrollPosition()

  const stickyActionsClasses = classNames({
    [classes.stickyTopTablet]:
      !isMobileScreen && isDesktopOrSmaller && hasScrolled,
    [classes.stickyMobileActions]: isMobileScreen
  })

  return (
    <>
      <Box
        className={classNames({
          [classes.stickyRoot]: hasScrolled && isDesktopOrLarger && sticky,
          [classes.isMobile]: isMobileScreen,
          [classes.isDesktopOrSmaller]: isDesktopOrSmaller,
          [classes.isDesktopOrLarger]: isDesktopOrLarger,
          [classes.hideHeader]: hideHeader,
          [classes.iLoveTightBottoms]: !!iLoveTightBottoms
        })}
      >
        <Box
          className={classNames({
            [classes.topContainer]: true,
            [classes.stickyTop]: hasScrolled && isDesktopOrLarger && sticky
          })}
        >
          <BreadCrumbs crumbs={breadcrumbs} title={title} />
          <Box
            className={classNames({
              [classes.searchBox]: !actionBox,
              [classes.actionBox]: actionBox
            })}
          >
            {showActionDrawer ? (
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-end"
                className={stickyActionsClasses}
              >
                {showActions}
                <Button
                  className={classes.drawerButton}
                  color="primary"
                  variant="contained"
                  onClick={() => setDrawer(true)}
                >
                  <FilterListIcon />
                </Button>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" justifyContent="center">
                {foldableActions}
                <Box className={stickyActionsClasses}>{showActions}</Box>
              </Box>
            )}
          </Box>
        </Box>
        {showActionDrawer ? (
          <ActionDrawer
            classes={classes}
            filters={filters}
            actions={foldableActions}
            open={drawerOpen}
            setDrawer={setDrawer}
            title={actionDrawerTitle ?? `Filter ${title}`}
          />
        ) : (
          <FilterBar
            filters={filters}
            summarizers={summarizers}
            iLoveTightBottoms
          />
        )}
      </Box>
      {showActionDrawer && summarizers && (
        <Box
          className={classNames({
            [classes.summarizersContainer]: true,
            [classes.iLoveTightBottoms]: !!iLoveTightBottoms
          })}
        >
          {summarizers}
        </Box>
      )}
    </>
  )
}

Header.defaultProps = {
  actions: false,
  sticky: false
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  foldableActions: PropTypes.node,
  actions: PropTypes.node,
  actionDrawerTitle: PropTypes.string,
  summarizers: PropTypes.node,
  filters: PropTypes.node,
  breadcrumbs: BreadCrumbsPropTypes,
  sticky: PropTypes.bool,
  actionBox: PropTypes.bool,
  hideHeader: PropTypes.bool
}

export default withStyles(styles)(Header)
