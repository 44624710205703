import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'

import { calcPriceByQuantity } from 'utils/products'
import { DEFAULT_VARIANT_TITLE } from 'constants/general'

import UnknownProduct from 'assets/unknown_product.jpg'

import styles from './ReturnItemsStyles'

const ReturnItems = ({
  classes,
  product,
  showSku,
  showVariantTitle,
  onChange,
  className,
  isOdd,
  inProgress,
  returnQuantity
}) => {
  const image =
    product.images?.[0]?.thumbnail_url ||
    product.product?.images?.[0]?.thumbnail_url ||
    product.variant?.images?.[0]?.thumbnail_url

  const title =
    product.title === DEFAULT_VARIANT_TITLE || showVariantTitle
      ? product.product?.title
      : product.title || product.product_title

  const subtitle = product.title === DEFAULT_VARIANT_TITLE ? '' : product.title
  const quantity = product.quantity || product.inventory_quantity

  const [localQuantity, setLocalQuantity] = useState(returnQuantity || 0)

  const handleInputChange = e => {
    const { value } = e.target
    if (value >= quantity) {
      setLocalQuantity(quantity)
      onChange && onChange(product.id, quantity)
      return
    }
    setLocalQuantity(value)
    onChange && onChange(product.id, value)
  }

  return (
    <Box className={classes.buttonBase}>
      <Grid
        className={classNames({
          [className]: Boolean(className),
          [classes.root]: true,
          [classes.odd]: Boolean(isOdd)
        })}
        container
      >
        <Grid item sm={12} md={8} className={classes.gridItem}>
          <Box flexShrink={0} height={40} width={40} mr={3}>
            <img
              src={image || UnknownProduct}
              alt={title}
              className={classes.image}
            />
          </Box>
          <Box flexGrow={1}>
            <Typography variant="subtitle1">{title}</Typography>
            {showVariantTitle && (
              <Typography variant="body1">{subtitle}</Typography>
            )}
            {showSku && <Typography variant="body1">{product.sku}</Typography>}
          </Box>
        </Grid>
        <Grid item sm={12} md={4} className={classes.gridItem}>
          <Grid container>
            <Grid item sm={12} md={12} className={classes.gridItem}>
              <Box flexGrow={0.5} textAlign="center">
                <OutlinedInput
                  className={classes.quantityInput}
                  id="quantity"
                  value={localQuantity}
                  onChange={handleInputChange}
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">/ {quantity}</InputAdornment>
                  }
                  inputProps={{ min: 0, max: quantity }}
                  disabled={inProgress}
                />
              </Box>
              <Box flexGrow={0.5} width={100} textAlign="center">
                <Typography variant="subtitle1">
                  {calcPriceByQuantity(product, localQuantity)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

ReturnItems.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  showQuantity: PropTypes.bool,
  showSku: PropTypes.bool,
  showVariantTitle: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  isOdd: PropTypes.bool,
  checked: PropTypes.bool,
  inProgress: PropTypes.bool,
  returnQuantity: PropTypes.number
}

export default withStyles(styles)(ReturnItems)
