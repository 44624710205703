import { useLocation } from 'react-router-dom'

import { useResourceListRQ } from 'hooks'

const API_LOCATIONS = '/api/v1/admin/locations'

const useAdminLocations = () => {
  const browserLocation = useLocation()

  const {
    isLoading: isLoadingLocations,
    data: locations,
    hasData: hasLocations,
    refetch
  } = useResourceListRQ({
    endpoint: () => API_LOCATIONS,
    fetchingEnabled: browserLocation.pathname.startsWith('/admin')
  })

  return {
    isLoadingLocations,
    locations,
    hasLocations,
    fetchLocations: refetch
  }
}

export default useAdminLocations
