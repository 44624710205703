import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@material-ui/core'

import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import { FiMapPin } from 'react-icons/fi'

import { URL } from 'constants/navigation'
import { useAdminSupplier } from 'hooks'

import { useTableRow } from 'templates'

const SupplierItem = ({ classes, supplier }) => {
  const supplierId = supplier.id
  const { destroySupplier } = useAdminSupplier({
    id: supplierId,
    fetchingEnabled: false
  })

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const confirmationDialogCellProps = {
    open: confirmDeleteOpen,
    setOpen: setConfirmDeleteOpen,
    onConfirm: async () => {
      setConfirmDeleteOpen(false)
      await destroySupplier()
    },
    description:
      'Are you sure? This will automatcailly delete its Distribution Centers and Variants as well. This action CANNOT be undone.'
  }

  const {
    ConfirmDeleteDialogCell,
    classes: tableRowClasses,
    navigateToItem,
    TableRow,
    TableCell
  } = useTableRow({
    itemUrl: URL.ADMIN_SUPPLIERS
  })

  const hasAddress = () => {
    // TODO: actual address awareness
    return Boolean(supplier.address) // TODO: is always false until we fix getAddress()
  }

  const getAddress = () => {
    // TODO: build address from supplier address form and match to Google Maps
    return supplier.address
  }

  return (
    <TableRow data-test="supplier-row">
      <TableCell>
        <Box onClick={e => navigateToItem(e, { id: supplierId })}>
          <Typography
            className={tableRowClasses.link}
            variant="body1"
            data-test="supplier-name"
          >
            {supplier?.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell display="flex" flexWrap="wrap">
        {supplier?.email && (
          <Box
            className={tableRowClasses.linkBox}
            onClick={() => window.open(`mailto:${supplier.email}`, '_self')}
          >
            <Box mr={1} fontSize={18}>
              <AiOutlineMail />
            </Box>
            <Typography className={tableRowClasses.link} variant="body1">
              {supplier?.email}
            </Typography>
          </Box>
        )}
        {supplier?.phone && (
          <Box
            className={tableRowClasses.linkBox}
            onClick={() => window.open(`tel:${supplier.phone}`, '_self')}
          >
            <Box mr={1} fontSize={18}>
              <AiOutlinePhone />
            </Box>
            <Typography className={tableRowClasses.link} variant="body1">
              {supplier?.phone}
            </Typography>
          </Box>
        )}
        {hasAddress() && (
          <Box
            className={tableRowClasses.linkBox}
            onClick={() =>
              window.open(
                `https://maps.google.com/?q=${getAddress()}`,
                '_blank'
              )
            }
          >
            <Box mr={1} fontSize={18}>
              <FiMapPin />
            </Box>
            <Typography className={tableRowClasses.link} variant="body1">
              {getAddress()}
            </Typography>
          </Box>
        )}
      </TableCell>
      <ConfirmDeleteDialogCell {...confirmationDialogCellProps} />
    </TableRow>
  )
}

SupplierItem.propTypes = {
  supplier: PropTypes.object.isRequired
}

export default SupplierItem
