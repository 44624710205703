export const INTERNAL_USER_FIELDS = {
  first_name: 'first_name',
  last_name: 'last_name',
  email: 'email',
  password: 'password',
  default_location_id: 'default_location_id',
  role: 'role',
  blocked: 'blocked'
}

export const INTERNAL_USER_FORM_DEFAULTS = {
  [INTERNAL_USER_FIELDS.first_name]: '',
  [INTERNAL_USER_FIELDS.last_name]: '',
  [INTERNAL_USER_FIELDS.email]: '',
  [INTERNAL_USER_FIELDS.password]: '',
  [INTERNAL_USER_FIELDS.default_location_id]: '',
  [INTERNAL_USER_FIELDS.role]: 'standard',
  [INTERNAL_USER_FIELDS.blocked]: false
}

export const INTERNAL_USER_ROLES = {
  superAdmin: 'super_admin',
  admin: 'admin',
  standard: 'standard'
}

export const INTERNAL_USER_ROLE_LABELS = {
  [INTERNAL_USER_ROLES.superAdmin]: 'Super Admin',
  [INTERNAL_USER_ROLES.admin]: 'Admin',
  [INTERNAL_USER_ROLES.standard]: 'Standard'
}

export const INTERNAL_USER_ROLE_OPTIONS = Object.keys(INTERNAL_USER_ROLES).map(
  role => {
    const value = INTERNAL_USER_ROLES[role]
    const label = INTERNAL_USER_ROLE_LABELS[value]
    return { value, label }
  }
)

export const INTERNAL_USER_ROLE_OPTIONS_WITHOUT_SUPER = INTERNAL_USER_ROLE_OPTIONS.filter(
  role => role.value != 'super_admin'
)
