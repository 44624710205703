import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useLocationsContext } from 'context'
import { PRODUCT_BLOCK_STATES, PRODUCT_PUBLISH_STATES } from 'constants/general'
import { dollarsToCents } from 'utils/general'

import { useConditionalEffect } from 'hooks'

import AutocompleteSearchBadges from 'components/AutocompleteSearchBadges/AutocompleteSearchBadges'
import AutocompleteSearchBrands from 'components/AutocompleteSearchBrands'
import AutocompleteSearchParentCategories from 'components/AutocompleteSearchParentCategories'
import AutocompleteSearchProductTags from 'components/AutocompleteSearchProductTags/AutocompleteSearchProductTags'
import AutocompleteSearchSubCategories from 'components/AutocompleteSearchSubCategories'
import Dialog from 'components/Dialog'
import ProductSummary from 'components/ProductSummary'
import Select from 'components/Select'
import TextField from 'components/TextField'

import styles from './UpdateProductModalStyles'
import AutocompleteSearchStatusCodes from 'components/AutocompleteSearchStatusCodes/AutocompleteSearchStatusCodes'

// The update drawer supports only updating products' publish status, only block status,
// or both at the same time.  A '' value is submitted to indicate not to update the respective field.
// &nbsp; to maintain the row height for the <Select /> option.
const PUBLISH_OPTIONS = [
  { label: <span>&nbsp;</span>, value: '' },
  ...PRODUCT_PUBLISH_STATES
]

const PDP_BLOCKED_OPTIONS = [
  { label: <span>&nbsp;</span>, value: '' },
  ...PRODUCT_BLOCK_STATES
]

export const AutocompleteSelectCategories = ({
  productFields,
  handleSetProductFields
}) => {
  const parentCategory = productFields.parentCategory
  const parentCategoryId = parentCategory?.id

  useConditionalEffect(() => {
    if (!parentCategoryId) {
      handleSetProductFields({
        target: {
          name: 'subCategory',
          value: {}
        }
      })
    }
  }, [parentCategoryId])

  return (
    <>
      <Box mx={0} mb={0.5}>
        <Typography variant="caption" color="textSecondary">
          Parent Category
        </Typography>
        <AutocompleteSearchParentCategories
          name="parentCategory"
          value={productFields.parentCategory}
          onChange={value =>
            handleSetProductFields({
              target: {
                name: 'parentCategory',
                value
              }
            })
          }
        />
      </Box>
      {parentCategoryId && (
        <Box mx={0} mb={0.5}>
          <Typography variant="caption" color="textSecondary">
            Subcategory
          </Typography>
          <AutocompleteSearchSubCategories
            name="subCategory"
            value={productFields.subCategory}
            onChange={value =>
              handleSetProductFields({
                target: {
                  name: 'subCategory',
                  value
                }
              })
            }
            parentCategoryId={parentCategoryId}
            clearOnParentCategoryIdClear
          />
        </Box>
      )}
    </>
  )
}

const UpdateProductDrawer = ({
  classes,
  open,
  products,
  onSubmit,
  onClose
}) => {
  const { location, showAllLocationIds, locationId } = useLocationsContext()
  const [publishStatus, setPublishStatus] = useState('')
  const [pdpBlocked, setPDPBlocked] = useState('')

  const [productFields, setProductFields] = useState({})
  const [variantFields, setVariantFields] = useState({})

  const handleSetVariantFields = e =>
    setVariantFields({
      ...variantFields,
      [e.target.name]: e.target.value
    })

  const handleSetProductFields = e =>
    setProductFields({
      ...productFields,
      [e.target.name]: e.target.value
    })

  const handleUpdate = () => {
    onSubmit(
      products,
      location,
      publishStatus,
      pdpBlocked,
      {
        ...(productFields.title && { title: productFields.title }),
        ...(!isEmpty(productFields.brand) && {
          brand_id: productFields.brand.id
        }),
        ...(!isEmpty(productFields.statusCode) && {
          status_code_id: productFields.statusCode.id
        }),
        ...(productFields.badges?.length && {
          badge_ids: productFields.badges.map(({ id }) => id)
        }),
        ...(productFields.tags?.length && {
          tag_ids: productFields.tags.map(({ id }) => id)
        }),
        ...(!isEmpty(productFields.parentCategory) && {
          category_id: productFields.parentCategory.id
        }),
        ...(!isEmpty(productFields.subCategory) && {
          subcategory_id: productFields.subCategory.id
        })
      },
      {
        ...(variantFields.cost && { cost: dollarsToCents(variantFields.cost) }),
        ...(variantFields.compare_at_price && {
          compare_at_price: dollarsToCents(variantFields.compare_at_price)
        }),
        ...(variantFields.price && {
          price: dollarsToCents(variantFields.price)
        })
      }
    )
    setPublishStatus('')
    setPDPBlocked('')
    onClose()
  }

  return (
    <Dialog
      title="Bulk Edit"
      subtitle={`Current Location ${
        showAllLocationIds ? 'All Locations' : location?.name
      }`}
      open={open}
      onCancel={onClose}
      onConfirm={handleUpdate}
    >
      <Typography variant="body1">
        Note: any fields that you leave blank will not update for products and
        variants.
      </Typography>
      <Box my={2}>
        <Typography variant="h2">Products</Typography>
        <Box my={1} />
        <Typography variant="body1">
          Editing these values will apply changes to the product level, which
          indirectly may affect eachs products variant.
        </Typography>
        <Box mb={2} />
        <Typography variant="caption">Title</Typography>
        <TextField
          name="title"
          onChange={handleSetProductFields}
          value={productFields.title}
          fullWidth
          className={classes.smallInput}
        />
        <Typography variant="caption">Publish Status</Typography>
        <Box>
          <Select
            fullWidth
            value={publishStatus}
            items={PUBLISH_OPTIONS}
            onChange={e => setPublishStatus(e.target.value)}
          />
        </Box>
        <Typography variant="caption">Access Type</Typography>
        <Box>
          <Select
            fullWidth
            value={pdpBlocked}
            items={PDP_BLOCKED_OPTIONS}
            onChange={e => setPDPBlocked(e.target.value)}
          />
        </Box>
        <Typography variant="caption">Brand</Typography>
        <AutocompleteSearchBrands
          name="brand"
          className={classes.brandSearch}
          onChange={value =>
            handleSetProductFields({
              target: {
                name: 'brand',
                value
              }
            })
          }
          value={productFields.brand}
          withStartAdornment={false}
        />
        <Typography variant="caption">Status Code</Typography>
        <AutocompleteSearchStatusCodes
          name="statusCode"
          className={classes.brandSearch}
          onChange={value =>
            handleSetProductFields({
              target: {
                name: 'statusCode',
                value
              }
            })
          }
          value={productFields.statusCode}
        />
        <Typography variant="caption">Badges</Typography>
        <AutocompleteSearchBadges
          name="badges"
          className={classes.brandSearch}
          onChange={value =>
            handleSetProductFields({
              target: {
                name: 'badges',
                value
              }
            })
          }
          value={productFields.badges}
          withStartAdornment={false}
        />
        <Typography variant="caption">Tags</Typography>
        <AutocompleteSearchProductTags
          name="brand"
          className={classes.brandSearch}
          onChange={value =>
            handleSetProductFields({
              target: {
                name: 'productTags',
                value
              }
            })
          }
          value={productFields.productTags}
          withStartAdornment={false}
        />
        <Box mb={2} />
        <AutocompleteSelectCategories
          productFields={productFields}
          handleSetProductFields={handleSetProductFields}
        />
      </Box>
      {!!locationId && (
        <Box my={4}>
          <Typography variant="h2">Products Variants</Typography>
          <Box my={1} />
          <Typography variant="body1">
            Editing these values will directly affect Product Variants, but not
            have any effect on the product level.
          </Typography>
          <Box my={2} />
          <Box mb={2} />
          <Typography variant="body2">Pricing</Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap" pt={2}>
            <Box display="flex" alignItems="flex-start" flexDirection="column">
              <Typography variant="caption">Price</Typography>
              <TextField
                type="number"
                className={classes.smallInput}
                startAdornment="$"
                name="price"
                onChange={handleSetVariantFields}
              />
            </Box>
            <Box display="flex" alignItems="flex-start" flexDirection="column">
              <Typography variant="caption">Compare At Price</Typography>
              <TextField
                type="number"
                className={classes.smallInput}
                startAdornment="$"
                name="compare_at_price"
                onChange={handleSetVariantFields}
              />
            </Box>
            <Box display="flex" alignItems="flex-start" flexDirection="column">
              <Typography variant="caption">Cost Per item</Typography>
              <TextField
                type="number"
                className={classes.smallInput}
                startAdornment="$"
                name="cost"
                onChange={handleSetVariantFields}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box mt={2}>
        <Typography variant="h2">Selected Products</Typography>
        <Box my={2} />
        {products.map((product, idx) => (
          <ProductSummary
            key={idx}
            product={product}
            showQuantity
            className={classes.product}
          />
        ))}
      </Box>
    </Dialog>
  )
}

UpdateProductDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  products: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func
}

export default withStyles(styles)(UpdateProductDrawer)
