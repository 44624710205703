import typography from 'theme/typography'
import { black } from 'theme/colors'

export default {
  // Used for Radio Button
  label: {
    ...typography.subtitle1,
    color: black,
    fontWeight: 400
  }
}
