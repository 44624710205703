import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import { ICON_BUTTON_VARIANT } from 'constants/enums'

import IconButton from 'components/IconButton'

import RemoveIcon from 'icons/RemoveIcon'
import GrabIcon from 'icons/GrabIcon'
import CheckIcon from 'icons/CheckIcon'

import styles from './DraggableImageStyles'

const DraggableImage = ({
  classes,
  item,
  width,
  height,
  onRemove,
  draggable,
  onSelectClick,
  selected
}) => (
  <Box
    width={width}
    height={height}
    className={classNames({
      [classes.root]: true
    })}
    key={item.id}
  >
    <Box display="flex" flexDirection="column" className={classes.details}>
      <Box className={classes.image}>
        <img
          src={item.url ?? item.src}
          alt={item.url ?? item.src}
          height={100}
          width={100}
        />
      </Box>
    </Box>
    <Box
      className={classes.overlay}
      display="flex"
      justifyContent="space-between"
    >
      <Box>{draggable && <GrabIcon className={classes.grab} />}</Box>
      <Box display="flex" flexDirection="row" height={20}>
        {onRemove && (
          <Box ml={1} mt="3px">
            <IconButton
              size={18}
              circle
              variant={ICON_BUTTON_VARIANT.black}
              onClick={() => onRemove(item)}
            >
              <RemoveIcon fontSize={12} />
            </IconButton>
          </Box>
        )}
        {onSelectClick && (
          <Box ml={1} mt="3px">
            <IconButton
              size={18}
              circle
              variant={
                selected ? ICON_BUTTON_VARIANT.blue : ICON_BUTTON_VARIANT.light
              }
              onClick={() => onSelectClick(item)}
            >
              <CheckIcon fontSize={8} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  </Box>
)

DraggableImage.defaultProps = {}

DraggableImage.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onRemove: PropTypes.func
}

export default withStyles(styles)(DraggableImage)
