import { useUIContext } from 'context'
import { NOTIFICATION_TYPE } from 'constants/enums'
import { NOTIFICATION_AUTO_HIDE_DURATION_MS } from 'constants/general'

const useAlerts = () => {
  const { setAlert } = useUIContext()

  const showAlertError = (
    message,
    autoHideDuration = NOTIFICATION_AUTO_HIDE_DURATION_MS
  ) =>
    setAlert({
      message,
      open: true,
      type: NOTIFICATION_TYPE.error,
      autoHideDuration
    })

  const showAlertWarning = (
    message,
    autoHideDuration = NOTIFICATION_AUTO_HIDE_DURATION_MS
  ) =>
    setAlert({
      message,
      open: true,
      type: NOTIFICATION_TYPE.warning,
      autoHideDuration
    })

  const showAlertSuccess = (
    message,
    autoHideDuration = NOTIFICATION_AUTO_HIDE_DURATION_MS
  ) =>
    setAlert({
      message,
      open: true,
      type: NOTIFICATION_TYPE.success,
      autoHideDuration
    })

  const showAlertInfo = (
    message,
    autoHideDuration = NOTIFICATION_AUTO_HIDE_DURATION_MS
  ) =>
    setAlert({
      message,
      open: true,
      type: NOTIFICATION_TYPE.info,
      autoHideDuration
    })

  const showAlertGeneral = (
    message,
    autoHideDuration = NOTIFICATION_AUTO_HIDE_DURATION_MS
  ) =>
    setAlert({
      message,
      open: true,
      type: NOTIFICATION_TYPE.general,
      autoHideDuration
    })

  return {
    showAlertError,
    showAlertWarning,
    showAlertSuccess,
    showAlertInfo,
    showAlertGeneral
  }
}

export default useAlerts
