import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useForm, FormProvider } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from 'components/TextField'

import { COLLECTION_CAN_BE_EDITORIAL } from 'constants/collections'
import {
  NEW_COLLECTION_GROUP_SCHEMA,
  FORM_FIELDS
} from 'constants/collectionGroups'
import { makeObjectResolver } from 'constants/yupSchemas'
import Dialog from 'components/Dialog'
import AutocompleteSearchCollections from 'components/AutocompleteSearchCollections'

const TEST_ID = 'create-collection-group'

const CreateCollectionGroupModal = ({
  open,
  createCollectionGroup,
  onClose
}) => {
  const [newCollections, setNewCollections] = useState([])

  const formMethods = useForm({
    mode: 'onSubmit',
    resolver: makeObjectResolver(NEW_COLLECTION_GROUP_SCHEMA)
  })
  const { register, handleSubmit, errors } = formMethods

  const createAndClose = collectionGroup => {
    const newCollectionIds = newCollections.map(collection => collection.id)

    createCollectionGroup({
      [FORM_FIELDS.TITLE]: collectionGroup[FORM_FIELDS.TITLE],
      [FORM_FIELDS.COLLECTION_IDS]: newCollectionIds
    })

    setNewCollections([])
    onClose()
  }

  return (
    <Dialog
      title="Create Collection Group"
      open={open}
      onCancel={onClose}
      onConfirm={handleSubmit(createAndClose)}
      dataTest={TEST_ID}
      confirmText="Create"
      confirmDisabled={newCollections.length === 0}
    >
      <FormProvider {...formMethods}>
        <Box>
          <Typography variant="h6">Collection Group Name</Typography>
          <TextField
            name={FORM_FIELDS.TITLE}
            placeholder="Enter Name"
            fullWidth
            inputRef={register}
            error={Boolean(errors[FORM_FIELDS.TITLE]?.message)}
            helperText={errors[FORM_FIELDS.TITLE]?.message}
            dataTest={`${TEST_ID}-create-name`}
          />
        </Box>
        <Box>
          <AutocompleteSearchCollections
            name={FORM_FIELDS.COLLECTIONS}
            value={newCollections}
            onChange={setNewCollections}
            queryProps={COLLECTION_CAN_BE_EDITORIAL}
            multiple
          />
        </Box>
      </FormProvider>
    </Dialog>
  )
}

CreateCollectionGroupModal.propTypes = {
  open: PropTypes.bool,
  createCollectionGroup: PropTypes.func.isRequired,
  onClose: PropTypes.func
}

export default CreateCollectionGroupModal
