import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles, useTheme } from '@material-ui/core/styles'
import startCase from 'lodash/startCase'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import BadgeIcon from 'icons/BadgeIcon'

import { USER_TIERS } from 'constants/enums'

import styles from './TierBadgeStyles'

const TierBadge = ({ classes, user }) => {
  const theme = useTheme()
  const { referral_tier: referralTier, employee, investor } = user
  const [tier, setTier] = useState(null)

  useEffect(
    () =>
      setTier(
        (investor && USER_TIERS.investor) ||
          (employee && USER_TIERS.employee) ||
          referralTier
      ),
    [user]
  )

  if (!user) return null

  const getBadgeProps = () => {
    switch (tier) {
      case USER_TIERS.blue: {
        return {
          color: theme.palette.tierBadge.blue
        }
      }
      case USER_TIERS.bronze: {
        return {
          color: theme.palette.tierBadge.bronze
        }
      }
      case USER_TIERS.silver: {
        return {
          color: theme.palette.tierBadge.silver
        }
      }
      case USER_TIERS.gold: {
        return {
          color: theme.palette.tierBadge.gold
        }
      }
      case USER_TIERS.platinum: {
        return {
          color: theme.palette.tierBadge.platinum
        }
      }
      case USER_TIERS.employee: {
        return {
          outlined: true
        }
      }
      case USER_TIERS.investor: {
        return {
          outlined: true
        }
      }
      default:
        return null
    }
  }

  return (
    <Box display="flex" gridGap={3}>
      <BadgeIcon {...getBadgeProps()} />
      <Typography className={classes[tier]}>{startCase(tier)}</Typography>
    </Box>
  )
}

TierBadge.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  user: PropTypes.object.isRequired
}

export default withStyles(styles)(TierBadge)
