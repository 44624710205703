import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { TextFieldControlled } from 'components/TextField'
import { variantFieldName } from './PurchaseOrderDetails'

import { useMenu } from 'hooks'

import { SIZE } from 'constants/enums'

import styles from './PurchaseOrderVariantStyles'

const PurchaseOrderVariantItem = ({ variant, editable, deleteVariant }) => {
  const { open, anchorEl, handleToggleMenu, handleClose } = useMenu()

  const Ordered = () => {
    if (editable) {
      return (
        <TextFieldControlled
          name={variantFieldName(variant.id)}
          number
          size={SIZE.small}
          skeletonUntilDefault
          dataTest="variant-ordered"
        />
      )
    }
    return <>{variant.purchase_order_variant_quantity}</>
  }

  return (
    <TableRow>
      <TableCell>{variant.id}</TableCell>
      <TableCell>{variant.product.vendor_style_number || ''}</TableCell>
      <TableCell>{variant.product.title}</TableCell>
      <TableCell data-test="variant-sku">{variant.sku}</TableCell>
      <TableCell>{variant?.color?.label}</TableCell>
      <TableCell>{variant.size}</TableCell>
      <TableCell>{variant.price}</TableCell>
      <TableCell>
        {parseInt(variant.purchase_order_variant_cost) === 0
          ? variant.distribution_center_variant_cost
          : variant.purchase_order_variant_cost}
      </TableCell>
      <TableCell>
        <Ordered />
      </TableCell>
      <TableCell>
        {variant.purchase_order_variant_past_received ||
          variant.purchase_order_variant_received}
      </TableCell>
      <TableCell>
        {variant.purchase_order_variant_past_damaged ||
          variant.purchase_order_variant_damaged}
      </TableCell>
      <TableCell align="right">
        {editable && (
          <IconButton onClick={e => handleToggleMenu(e)}>
            <MoreVertIcon />
            <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
              <MenuItem onClick={() => deleteVariant(variant)}>Delete</MenuItem>
            </Menu>
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

PurchaseOrderVariantItem.propTypes = {
  variant: PropTypes.object.isRequired,
  handleSelect: PropTypes.func,
  selected: PropTypes.bool,
  locationId: PropTypes.number,
  onProductSummaryClick: PropTypes.func,
  editable: PropTypes.bool.isRequired,
  deleteVariant: PropTypes.func.isRequired
}

export default withStyles(styles)(PurchaseOrderVariantItem)
