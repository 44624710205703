import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, useTheme } from '@material-ui/core/styles'

import MuiCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import * as propTypes from 'constants/propTypes'
import { SIZE, SIZE_PX, CHECKBOX_VARIANT } from 'constants/enums'
import CheckBoxIcon from 'icons/CheckBoxIcon'
import CheckBlankIcon from 'icons/CheckBlankIcon'
import CheckIndeterminateIcon from 'icons/CheckIndeterminateIcon'

import styles from './CheckboxStyles'

const getCheckboxColors = ({ palette }, variant) =>
  ({
    [CHECKBOX_VARIANT.default]: [palette.grays.med, palette.blues.fast],
    [CHECKBOX_VARIANT.light]: [palette.grays.light, palette.common.white]
  }[variant])

const Checkbox = ({
  className,
  classes,
  checked,
  label,
  size,
  indeterminate,
  disabled,
  boundingBox,
  onChange,
  variant,
  dataTest
}) => {
  const theme = useTheme()
  const [isChecked, setIsChecked] = useState(checked)
  const [color, checkedColor] = useMemo(
    () => getCheckboxColors(theme, variant),
    [theme, variant]
  )

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const rootClassNames = classNames({
    [classes.root]: true,
    [classes.disabled]: disabled,
    [classes.boundingBox]: boundingBox,
    [className]: Boolean(className)
  })

  const sizeInPx = size && SIZE_PX[size]

  const handleChange = event => {
    const { checked: value } = event.target
    setIsChecked(value)
    onChange && onChange(value)
  }

  return (
    <FormControlLabel
      data-test={dataTest}
      control={
        <MuiCheckbox
          className={classes[size]}
          value={label}
          checked={isChecked}
          disabled={disabled}
          onChange={handleChange}
          icon={<CheckBlankIcon fontSize={sizeInPx} fill={color} />}
          checkedIcon={<CheckBoxIcon fontSize={sizeInPx} fill={checkedColor} />}
          indeterminateIcon={
            <CheckIndeterminateIcon fontSize={sizeInPx} fill={color} />
          }
          indeterminate={indeterminate}
        />
      }
      label={label}
      className={rootClassNames}
      disabled={disabled}
    />
  )
}

Checkbox.defaultProps = {
  checked: false,
  label: false,
  size: SIZE.small,
  indeterminate: false,
  disabled: false,
  boundingBox: false,
  variant: CHECKBOX_VARIANT.default
}

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.node,
  size: PropTypes.string,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  boundingBox: PropTypes.bool,
  onChange: PropTypes.func,
  variant: propTypes.checkboxVariants,
  dataTest: PropTypes.string
}

export default withStyles(styles)(Checkbox)
