import React, { useState, useEffect } from 'react'
import { useConditionalEffect } from 'hooks'
import { withStyles } from '@material-ui/core/styles'
import * as yup from 'yup'
import moment from 'moment-timezone'
import Box from '@material-ui/core/Box'
import { useForm, FormProvider } from 'react-hook-form'
import Dialog from 'components/Dialog'
import Typography from '@material-ui/core/Typography'
import { RHFTextField } from 'components/TextField'
import { SelectField } from 'components/Select'
import ImageDropzone from 'components/ImageDropzone'
import { DROPZONE_IMAGE_TYPES } from 'constants/collections'
import {
  makeObjectResolver,
  makeImageSchemaWithMinMaxSquareResolution
} from 'constants/yupSchemas'
import { useDeeplinks } from 'hooks'
import { DatePickerField } from 'components/DatePicker'


import styles from './CreateNotificationModalStyles'

const getDeeplinkOptions = deepLinksData => {
  const defaultOption = { label: 'Path Destination', value: 0, disabled: true }
  let formattedDeepLinkOptions = []
  if (deepLinksData) {
    formattedDeepLinkOptions = deepLinksData.map((dl, i) => {
      return {
        label: dl.description,
        value: i + 1,
        deepLink: dl.deep_link,
        parameter: dl.parameter,
        disabled: false
      }
    })
  }
  return [defaultOption, ...formattedDeepLinkOptions]
}

const sendToOptions = [
  { label: 'Send To', value: 0, disabled: true },
  { label: 'All Users', value: 1, disabled: false }
]

const DELIVERY_IMMEDIATELY_OPTION_VALUE = 1;
const SCHEDULED_OPTION_VALUE = 2;
const timeOptions = [
  { label: 'Time', value: 0, disabled: true },
  { label: 'Immediately', value: DELIVERY_IMMEDIATELY_OPTION_VALUE, disabled: false },
  { label: 'Scheduled', value: SCHEDULED_OPTION_VALUE, disabled: false }
]


const timeZoneOptions = [
  { label: 'Timezone', value: 0, disabled: true },
  { label: 'Local', value: 'local', disabled: false },
  { label: 'America/Los Angeles', value: 'America/Los_Angeles', disabled: false },
  { label: 'America/New York', value: 'America/New_York', disabled: false }
]

const TITLE_MAX_LENGTH = 31
const MESSAGE_MAX_LENGTH = 84
const MIN_IMAGE_DIMENSION_PX = 144
const MAX_IMAGE_DIMENSION_PX = 240

// What HTML Tags are ignored in text fields
// ie: b == <b></b>
const IGNORED_HTML_SYMBOLS = ['b']

export const FIELDS = {
  title: 'title',
  message: 'description',
  iconImage: 'image',
  pathDest: 'path_link',
  pathDestDetail: 'pathDestDetail',
  sendTo: 'sendTo',
  deliverImmediately: 'deliver_immediately',
  deliveryDate: 'deliver_at_date',
  deliveryTime: 'deliver_at_time',
  deliveryTimezone: 'deliver_at_timezone'
}

const yupNotificationSchemas = {
  [FIELDS.title]: yup.string().required('Title is required'),
  [FIELDS.message]: yup.string().required('Message is required'),
  [FIELDS.iconImage]: makeImageSchemaWithMinMaxSquareResolution(
    MIN_IMAGE_DIMENSION_PX,
    MAX_IMAGE_DIMENSION_PX
  ),
  [FIELDS.pathDest]: yup
    .number()
    .positive('Path Destination is required')
    .required('Path Destination is required'),
  [FIELDS.pathDestDetail]: yup.number(),
  [FIELDS.sendTo]: yup.string().required('Send To is required'),
  [FIELDS.deliverImmediately]: yup.number()
  .oneOf([DELIVERY_IMMEDIATELY_OPTION_VALUE, SCHEDULED_OPTION_VALUE], 'Must Select an Delivery Option'),
  [FIELDS.deliveryDate]: yup.string().ensure().when(FIELDS.deliverImmediately, {
    is: SCHEDULED_OPTION_VALUE,
    then: yup.string().required('Delivery Date Required')
  }),
  [FIELDS.deliveryTime]: yup.string().ensure().when(FIELDS.deliverImmediately, {
    is: SCHEDULED_OPTION_VALUE,
    then: yup.string().required('Delivery Time Required')
  }),
  [FIELDS.deliveryTimezone]: yup.string().ensure().when(FIELDS.deliverImmediately, {
    is: SCHEDULED_OPTION_VALUE,
    then: yup.string().notOneOf(['0'], 'Timezone Required')
  }),
}

const findLengthOfHTMLSymbolsInString = (ogString, symbols = []) => {
  let numCharsIgnored = 0
  symbols.forEach(symbol => {
    const openSymbol = `<${symbol}>`
    const closedSymbol = `</${symbol}>`
    if (ogString.includes(openSymbol) || ogString.includes(closedSymbol)) {
      let strippedString = ogString
      strippedString = strippedString.replaceAll(openSymbol, '')
      strippedString = strippedString.replaceAll(closedSymbol, '')
      numCharsIgnored = ogString.length - strippedString.length
    }
  })
  return numCharsIgnored
}

const CreateNotificationModal = ({ classes, onClose, onSubmit, isActive, hideBody }) => {
  const formMethods = useForm({
    mode: 'onSubmit',
    resolver: makeObjectResolver(yupNotificationSchemas)
  })

  const { data: deepLinksData, isFetching: deepLinksLoading } = useDeeplinks()

  const { handleSubmit, errors } = formMethods

  const pathDestHasError = !!errors[FIELDS.pathDest]?.message
  const [titleValueLength, setTitleValueLength] = useState(0)
  const [messageValueLength, setMessageValueLength] = useState(0)
  const [messageIgnoredCharsLength, setMessageIgnoredCharsLength] = useState(0)
  const [deeplinkOptions, setDeeplinkOptions] = useState([])
  const [selectedDeeplinkParam, setSelectedDeeplinkParam] = useState(0)
  const [selectedDeliveryTimeParam, setSelectedDeliveryTimeParam] = useState(0)

  useEffect(() => {
    if (!deepLinksLoading) {
      setDeeplinkOptions(getDeeplinkOptions(deepLinksData))
    }
  }, [deepLinksData, deepLinksLoading])

  const handleDeepLinkOptionSelect = indexSelected => {
    const deepLinkParam = deeplinkOptions?.[indexSelected]?.parameter
    setSelectedDeeplinkParam(!!deepLinkParam ? deepLinkParam : false)
  }
  
  const getFormattedDeepLinkForPayload = notification => {
    const deeplinkRootIndex = notification[FIELDS.pathDest]
    const deeplinkRoot = deeplinkOptions[deeplinkRootIndex]?.deepLink
    const deeplinkParam = notification[FIELDS.pathDestDetail]
    let deeplink = null
    if (!!deeplinkRoot) {
      deeplink = deeplinkRoot
      if (!!deeplinkParam) {
        deeplink = deeplink.replace(':id', deeplinkParam)
      }
    }
    return deeplink
  }

  const onCreateNotificationSubmit = notification => {
    const deliveryDate = notification[FIELDS.deliveryDate];
    const deliveryTime = notification[FIELDS.deliveryTime];

    const formattedNotification = {
      [FIELDS.title]: notification[FIELDS.title],
      [FIELDS.message]: notification[FIELDS.message],
      [FIELDS.iconImage]: notification[FIELDS.iconImage],
      [FIELDS.pathDest]: getFormattedDeepLinkForPayload(notification),
      [FIELDS.sendTo]: sendToOptions[notification[FIELDS.sendTo]]?.label,
      [FIELDS.deliverImmediately]: notification[FIELDS.deliverImmediately] === DELIVERY_IMMEDIATELY_OPTION_VALUE,
      [FIELDS.deliveryDate]: !!deliveryDate ? moment(deliveryDate).format('YYYYMMDD') : null,
      [FIELDS.deliveryTime]: !!deliveryTime ? moment(deliveryTime).format('HHmm') : null,
      [FIELDS.deliveryTimezone]: notification[FIELDS.deliveryTimezone]
    }
    onSubmit(formattedNotification)
  }

  const handleHTMLTextChange = (e, setFieldLength, setFieldIgnored) => {
    const ogText = e.target.value
    const numCharsIgnored = findLengthOfHTMLSymbolsInString(
      ogText,
      IGNORED_HTML_SYMBOLS
    )
    setFieldLength(ogText.length - numCharsIgnored)
    setFieldIgnored(numCharsIgnored)
  }

  const resetFormFieldState = () => {
    setSelectedDeliveryTimeParam(0)
  }

  useConditionalEffect(() => {
    if (!isActive) {
      resetFormFieldState()
    }
  }, [isActive])

  return (
    <Dialog
      title="Create a Notification"
      open={isActive}
      onCancel={onClose}
      onConfirm={handleSubmit(onCreateNotificationSubmit)}
      dataTest="create-notification-modal"
      confirmText="Create"
    >
      <Box height={!!hideBody ? '0px' : 'inherit'}>
        <Typography className={classes.caption}>
          Give the notification a title, message, path destination, and image.
        </Typography>
        <FormProvider {...formMethods}>
          <RHFTextField
            className={classes.textField}
            name={FIELDS.title}
            placeholder="Title"
            inputProps={{ maxLength: TITLE_MAX_LENGTH }}
            onChange={e => setTitleValueLength(e.target.value.length)}
            fullWidth
            dataTest="create-notification-modal-title"
            {...formMethods}
          />
          <Box
            className={classes.textCounter}
          >{`${titleValueLength} / ${TITLE_MAX_LENGTH}`}</Box>
          <RHFTextField
            className={classes.textArea}
            name={FIELDS.message}
            placeholder="Message"
            inputProps={{
              maxLength: MESSAGE_MAX_LENGTH + messageIgnoredCharsLength
            }}
            onChange={e =>
              handleHTMLTextChange(
                e,
                setMessageValueLength,
                setMessageIgnoredCharsLength
              )
            }
            fullWidth
            multiline
            dataTest="create-notification-modal-message"
            {...formMethods}
          />
          <Box
            className={classes.textCounter}
          >{`${messageValueLength} / ${MESSAGE_MAX_LENGTH}`}</Box>
          <Box className={classes.imageFieldContainer}>
            <ImageDropzone
              name={FIELDS.iconImage}
              accept={DROPZONE_IMAGE_TYPES}
              placeholder={'Notification Icon: 144 x 144'}
            />
          </Box>
          <Box display="flex" className={classes.selectContainer}>
            <Box flex={4} pr={2}>
              <SelectField
                fullWidth
                name={FIELDS.pathDest}
                items={deeplinkOptions}
                defaultValueIndex={0}
                onChange={handleDeepLinkOptionSelect}
                dataTest="create-notification-modal-deeplink"
                {...formMethods}
              />
            </Box>
            <Box flex={1} pr={8}>
              {!!selectedDeeplinkParam && (
                <RHFTextField
                  className={classes.textField}
                  name={FIELDS.pathDestDetail}
                  placeholder={selectedDeeplinkParam}
                  fullWidth
                  {...formMethods}
                />
              )}
            </Box>
          </Box>
          <Box pl={1} pt={1}>
            {!pathDestHasError && (
              <Typography mb={0} className={classes.caption}>
                /collection/ID, /product/ID, /main
              </Typography>
            )}
          </Box>
          <Box className={classes.selectContainer} mt="-30px">
            <SelectField
              name={FIELDS.sendTo}
              items={sendToOptions}
              defaultValueIndex={1}
              fullWidth
              {...formMethods}
              />
          </Box>
          <Box className={classes.selectContainer}>
            <SelectField
              name={FIELDS.deliverImmediately}
              items={timeOptions}
              onChange={setSelectedDeliveryTimeParam}
              defaultValueIndex={0}
              dataTest="create-notification-modal-delivery"
              fullWidth
              {...formMethods}
            />
          </Box>
          { selectedDeliveryTimeParam === SCHEDULED_OPTION_VALUE && (
            <Box display="flex">
              <Box flex="0.3" mr="10px" >
                <DatePickerField
                  placeholder="Day"
                  name={FIELDS.deliveryDate}
                  dataTest="create-notification-modal-delivery-day-picker"
                />
              </Box>
              <Box flex="0.3" mr="10px">
                <DatePickerField
                  name={FIELDS.deliveryTime}
                  placeholder="Time"
                  onlyTime
                  dataTest="create-notification-modal-delivery-time-picker"
                />
              </Box>
              <Box flex="0.3">
                <SelectField
                  name={FIELDS.deliveryTimezone}
                  items={timeZoneOptions}
                  defaultValueIndex={0}
                  fullWidth
                  dataTest="create-notification-modal-delivery-timezone-picker"
                  {...formMethods}
                />
              </Box>
            </Box>
          )}
        </FormProvider>
      </Box>
    </Dialog>
  )
}

export default withStyles(styles)(CreateNotificationModal)
