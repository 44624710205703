import React, { useState, useRef } from 'react'
import keyBy from 'lodash/keyBy'
import PropTypes from 'prop-types'
import saveCsv from 'save-csv'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useLocationsContext } from 'context'
import {
  useAdminProducts,
  useAdminVariants,
  useAdminVariantsRQ,
  useAlerts,
  useLoaders,
  useQuery,
  useNavigation,
  useConditionalEffect
} from 'hooks'
import { SIZE, CHECKBOX_VARIANT } from 'constants/enums'
import {
  API_PARAMS,
  LIST_PATHS,
  SOLD_OUT_INVENTORY
} from 'constants/queryParams'
import { URL } from 'constants/navigation'
import { sortPossibilitiesFromColumns } from 'utils/query'

import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import DataTable from 'components/DataTable'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Select from 'components/Select'
import GlanceTile from 'components/GlanceTile'
import SearchInput from 'components/SearchInput'
import EditTags from 'components/EditTags'
import SearchByTag from 'components/SearchByTag'
import AutocompleteSearchBrands from 'components/AutocompleteSearchBrands'
import Dialog from 'components/Dialog'

import ProductItem from 'containers/admin/ProductItem'

import AddIcon from '@material-ui/icons/Add'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import EditIcon from 'icons/EditIcon'
import ImportIcon from 'icons/ImportIcon'

import UpdateProductModal from './UpdateProductModal'
import { isEmpty } from 'lodash'

const columns = [
  {
    title: 'Product',
    sortKeys: [LIST_PATHS.PRODUCTS_TITLE, LIST_PATHS.PRODUCTS_BRAND_AND_TITLE]
  },
  { title: 'Inventory' },
  { title: 'Status' },
  { title: 'Gate' }
]

const sortPossibilities = sortPossibilitiesFromColumns(columns)

const DO_NOT_BUY_KEY = 'do_not_buy'
const BUY_KEY = 'buy'

const PUBLISHED_KEY = 'published'
const UNPUBLISHED_KEY = 'unpublished'

const HAS_IMAGES_KEY = 'has-images'
const NO_IMAGES_KEY = 'no-images'

const BLOCKED_KEY = 'blocked'
const UNBLOCKED_KEY = 'unblocked'

const DELETED_KEY = 'deleted'
const ARCHIVED_KEY = 'archived'

const BUYABLE_OPTIONS = [
  { label: 'Do Not Buy', value: DO_NOT_BUY_KEY },
  { label: 'Buy', value: BUY_KEY }
]

const PUBLISHED_OPTIONS = [
  { label: 'Published', value: PUBLISHED_KEY },
  { label: 'Unpublished', value: UNPUBLISHED_KEY }
]

const IMAGES_OPTIONS = [
  { label: 'Has Images', value: HAS_IMAGES_KEY },
  { label: 'No Images', value: NO_IMAGES_KEY }
]

const DELETION_OPTIONS = [
  {
    label: 'Archived',
    value: ARCHIVED_KEY
  },
  {
    label: 'Deleted',
    value: DELETED_KEY
  }
]

const BLOCKED_OPTIONS = [
  { label: 'PDP Blocked', value: BLOCKED_KEY },
  { label: 'PDP Unblocked', value: UNBLOCKED_KEY }
]

const GROUPED_STATUS_FILTER_ITEMS = [
  {
    group: 'Buyable',
    items: BUYABLE_OPTIONS
  },
  {
    group: 'Published',
    items: PUBLISHED_OPTIONS
  },
  {
    group: 'Images',
    items: IMAGES_OPTIONS
  },
  {
    group: 'PDP Blocked',
    items: BLOCKED_OPTIONS
  },
  {
    group: 'Status',
    items: DELETION_OPTIONS
  }
]

const ALL_LOCATION_PUBLISHING_MSG =
  'To publish or unpublish products, please first select a specific Darkstore location.'

const ProductList = ({ classes }) => {
  const { locationId, showAllLocationIds } = useLocationsContext()
  const { showAlertGeneral, showAlertError } = useAlerts()
  const { showLoading, hideLoading } = useLoaders()
  const { handleAddClick: createNewProductClick } = useNavigation({
    url: URL.ADMIN_PRODUCTS
  })
  const csvName = useRef()

  const {
    isLoadingProducts,
    products,
    hasProducts,
    hasProductsNext,
    listProducts,
    listProductsNext,
    productsWithSoldOutVariantsCount,
    fetchProductsWithSoldOutVariantsCount,
    bulkUpdateProduct,
    updateProductsLocally,
    bulkApplyTags,
    updateProduct
  } = useAdminProducts({})

  const { updateVariant } = useAdminVariants({})

  const [uploadingState, setUploadingState] = useState('none')
  const onImportCSVSuccess = () => {
    setUploadingState('done')
  }

  const [
    openBulkUpdateVariantLocations,
    setOpenBulkUpdateVariantLocations
  ] = useState(false)
  const [
    bulkUpdateVariantLocationsState,
    setBulkUpdateVariantLocationsState
  ] = useState('none')
  const onBulkUpdateVariantLocationsSuccess = () => {
    setBulkUpdateVariantLocationsState('done')
  }

  const {
    soldOutVariantsCount,
    importCSV,
    bulkUpdateVariantLocations
  } = useAdminVariantsRQ({
    onImportCSVSuccess,
    onBulkUpdateVariantLocationsSuccess
  })

  const initialFetch = async () => {
    await fetchProductsWithSoldOutVariantsCount()
  }

  const { query, handleQueryChange, setQuery, updateQuery } = useQuery(
    listProducts,
    {
      initFallback: {
        [API_PARAMS.SORT]: LIST_PATHS.PRODUCTS_TITLE
      },
      locationFilter: LIST_PATHS.LOCATION_ID
    }
  )

  const [filteredBrand, setFilteredBrand] = useState({})

  const getQueryTagsId = () => {
    const idArray = query[LIST_PATHS.PRODUCTS_TAGS_ID]?.split(',')
    const nameArray = query[LIST_PATHS.PRODUCTS_TAGS_NAME]?.split(',')

    return idArray?.map((id, idx) => ({ id, name: nameArray[idx] }))
  }
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [selections, setSelections] = useState({})
  const [openTagsDialog, setOpenTagsDialog] = useState(false)
  const [tags, setTags] = useState(getQueryTagsId() || [])
  const [openBulkUpdatePrices, setOpenBulkUpdatePrices] = useState(false)

  useConditionalEffect(() => {
    setSelections({})
  }, [query])

  useConditionalEffect(() => {
    if (showAllLocationIds) {
      showAlertGeneral(ALL_LOCATION_PUBLISHING_MSG)
    }

    setSelections({})
  }, [locationId])

  const isQueryingSoldOut = Object.entries(SOLD_OUT_INVENTORY).every(
    ([k, v]) => query[k] === v
  )

  const handleSelect = (product, isSelected) => {
    const clone = { ...selections }
    if (isSelected) {
      clone[product.id] = product
    } else {
      delete clone[product.id]
    }

    setSelections(clone)
  }

  const handleSelectAll = isChecked => {
    if (isChecked) {
      setSelections(keyBy(products, v => v.id))
    } else {
      setSelections({})
    }
  }

  const handleUpdate = async (
    productsToUpdate,
    location,
    isPublished,
    isPDPBlocked,
    productFields,
    variantFields
  ) => {
    showLoading()

    const bulkProductEndpointResponse = await bulkUpdateProduct(
      productsToUpdate.map(p => p.id),
      [location.id],
      typeof isPublished === 'number' ? Boolean(isPublished) : null,
      typeof isPDPBlocked === 'number' ? Boolean(isPDPBlocked) : null
    )

    if (!isEmpty(productFields)) {
      const handleProductUpdate = async productId =>
        await updateProduct(productId, productFields)

      const unresolvedProductUpdates = productsToUpdate.map(p =>
        handleProductUpdate(p.id)
      )
      Promise.all(unresolvedProductUpdates)
    }

    if (!isEmpty(variantFields)) {
      const handleVariantUpdate = async variantId =>
        await updateVariant(variantId, locationId, variantFields)

      const unresolvedVariantUpdates = productsToUpdate.map(p =>
        p.variants.map(v => handleVariantUpdate(v.id))
      )
      Promise.all(unresolvedVariantUpdates)
    }

    if (bulkProductEndpointResponse instanceof Error) {
      showAlertError('Error: ', bulkProductEndpointResponse.message)
    } else if (bulkProductEndpointResponse) {
      setSelections({})
      // trigger refresh with current query
      setQuery({ ...query })

      showAlertGeneral(
        `${
          productsToUpdate.length > 1
            ? `${productsToUpdate.length} products were`
            : '1 product was'
        } updated.`
      )
    }

    hideLoading()
  }

  const summarizers = (
    <>
      <GlanceTile
        minimal
        label="Sold Out Products"
        tooltip="Products with at least one sold out variant"
        value={productsWithSoldOutVariantsCount}
        isActive={isQueryingSoldOut}
      />
      <GlanceTile
        minimal
        label="Sold Out Variants"
        value={soldOutVariantsCount}
      />
    </>
  )

  const getStatusFiltersFromQuery = () => {
    const buy = query[LIST_PATHS.PRODUCTS_INVENTORY_DO_NOT_BUY] === 'false'
    const doNotBuy = query[LIST_PATHS.PRODUCTS_INVENTORY_DO_NOT_BUY] === 'true'
    const published = query[LIST_PATHS.PRODUCT_PUBLISHED] === 'true'
    const unpublished = query[LIST_PATHS.PRODUCT_PUBLISHED] === 'false'
    const hasImages = query[LIST_PATHS.PRODUCT_IMAGES] === 'true'
    const noImages = query[LIST_PATHS.PRODUCT_IMAGES] === 'false'
    const blocked = query[LIST_PATHS.PRODUCT_PDP_BLOCKED] === 'true'
    const unblocked = query[LIST_PATHS.PRODUCT_PDP_BLOCKED] === 'false'
    const archived = query[LIST_PATHS.PRODUCT_STATUS] === ARCHIVED_KEY
    const deleted = query[LIST_PATHS.PRODUCT_STATUS] === DELETED_KEY

    const statusArr = []

    if (buy && !doNotBuy) {
      statusArr.push(BUY_KEY)
    } else if (!buy && doNotBuy) {
      statusArr.push(DO_NOT_BUY_KEY)
    }

    if (published && !unpublished) {
      statusArr.push(PUBLISHED_KEY)
    } else if (!published && unpublished) {
      statusArr.push(UNPUBLISHED_KEY)
    }

    if (hasImages && !noImages) {
      statusArr.push(HAS_IMAGES_KEY)
    } else if (!hasImages && noImages) {
      statusArr.push(NO_IMAGES_KEY)
    }

    if (blocked && !unblocked) {
      statusArr.push(BLOCKED_KEY)
    } else if (!blocked && unblocked) {
      statusArr.push(UNBLOCKED_KEY)
    }

    if (archived && !deleted) {
      statusArr.push(ARCHIVED_KEY)
    } else if (!archived && deleted) {
      statusArr.push(DELETED_KEY)
    }

    return statusArr
  }

  const [statusFilter, setStatusFilter] = useState(getStatusFiltersFromQuery())
  const handleSetStatusFilter = e => {
    const filteredValues = [...e.target.value.filter(Boolean)].flat()
    setStatusFilter(filteredValues)

    const CLEAR = [
      LIST_PATHS.PRODUCTS_INVENTORY_DO_NOT_BUY,
      LIST_PATHS.PRODUCT_PUBLISHED,
      LIST_PATHS.PRODUCT_IMAGES,
      LIST_PATHS.PRODUCT_PDP_BLOCKED,
      LIST_PATHS.PRODUCT_STATUS
    ].reduce(
      (acc, key) => ({
        ...acc,
        [key]: ''
      }),
      {}
    )

    if (filteredValues.length) {
      const localQuery = {}
      const buy = filteredValues.includes(BUY_KEY)
      const doNotBuy = filteredValues.includes(DO_NOT_BUY_KEY)
      const published = filteredValues.includes(PUBLISHED_KEY)
      const unpublished = filteredValues.includes(UNPUBLISHED_KEY)
      const hasImages = filteredValues.includes(HAS_IMAGES_KEY)
      const noImages = filteredValues.includes(NO_IMAGES_KEY)
      const blocked = filteredValues.includes(BLOCKED_KEY)
      const unblocked = filteredValues.includes(UNBLOCKED_KEY)
      const deleted = filteredValues.includes(DELETED_KEY)
      const archived = filteredValues.includes(ARCHIVED_KEY)

      if (!buy && doNotBuy) {
        localQuery[LIST_PATHS.PRODUCTS_INVENTORY_DO_NOT_BUY] = true
      }

      if (buy && !doNotBuy) {
        localQuery[LIST_PATHS.PRODUCTS_INVENTORY_DO_NOT_BUY] = false
      }

      if (published && !unpublished) {
        localQuery[LIST_PATHS.PRODUCT_PUBLISHED] = true
      }

      if (!published && unpublished) {
        localQuery[LIST_PATHS.PRODUCT_PUBLISHED] = false
      }

      if ((published && unpublished) || (!published && !unpublished)) {
        localQuery[LIST_PATHS.PRODUCT_PUBLISHED] = ''
      }

      if (hasImages && !noImages) {
        localQuery[LIST_PATHS.PRODUCT_IMAGES] = true
      }

      if (!hasImages && noImages) {
        localQuery[LIST_PATHS.PRODUCT_IMAGES] = false
      }

      if ((hasImages && noImages) || (!hasImages && !noImages)) {
        localQuery[LIST_PATHS.PRODUCT_IMAGES] = ''
      }

      if (blocked && !unblocked) {
        localQuery[LIST_PATHS.PRODUCT_PDP_BLOCKED] = true
      }

      if (!blocked && unblocked) {
        localQuery[LIST_PATHS.PRODUCT_PDP_BLOCKED] = false
      }

      if ((blocked && unblocked) || (!blocked && !unblocked)) {
        localQuery[LIST_PATHS.PRODUCT_PDP_BLOCKED] = ''
      }

      if (deleted && !archived) {
        localQuery[LIST_PATHS.PRODUCT_STATUS] = DELETED_KEY
      }

      if (!deleted && archived) {
        localQuery[LIST_PATHS.PRODUCT_STATUS] = ARCHIVED_KEY
      }

      if ((deleted && archived) || (!deleted && !archived)) {
        localQuery[LIST_PATHS.PRODUCT_STATUS] = ''
      }

      updateQuery({ ...CLEAR, ...localQuery })
    } else {
      updateQuery({ ...CLEAR })
    }
  }

  const handleTagsChange = values => {
    setTags(values)

    updateQuery({
      [LIST_PATHS.PRODUCTS_TAGS_ID]: values.map(value => value.id).join(','),
      [LIST_PATHS.PRODUCTS_TAGS_NAME]: values.map(value => value.name).join(',')
    })
  }

  const filters = (
    <>
      <Select
        minimal
        name={API_PARAMS.SORT}
        innerLabel="Sort By"
        value={query[API_PARAMS.SORT] || ''}
        items={sortPossibilities}
        onChange={handleQueryChange}
        withNone
        dataTest="product-sort"
      />
      <Select
        minimal
        name="statuses"
        innerLabel="All Statuses"
        value={statusFilter}
        groupedItems={GROUPED_STATUS_FILTER_ITEMS}
        onChange={handleSetStatusFilter}
        multiple
      />
      <AutocompleteSearchBrands
        name={LIST_PATHS.PRODUCTS_BRAND_ID}
        className={classes.brandSearch}
        onChange={brand => {
          updateQuery({
            [LIST_PATHS.PRODUCTS_BRAND_ID]: brand?.id ?? '',
            [LIST_PATHS.PRODUCTS_BRAND_NAMES]: brand?.name ?? ''
          })
          setFilteredBrand(brand)
        }}
        value={filteredBrand}
        withStartAdornment={false}
        data-test="product-search-brands"
      />
      <SearchByTag onChange={handleTagsChange} tags={tags} />
    </>
  )

  const allSelected =
    products && Object.keys(selections).length === products.length

  const handleClickManage = e => {
    if (showAllLocationIds) {
      // stop propagation is necessary to not trigger alert's Snackbar's ClickAway handler
      e.stopPropagation()
      showAlertGeneral(ALL_LOCATION_PUBLISHING_MSG)
      return
    }

    setUpdateModalOpen(true)
  }

  const headerAction = (
    <Box display="flex" alignItems="center">
      <Checkbox
        variant={CHECKBOX_VARIANT.light}
        onChange={handleSelectAll}
        indeterminate={!allSelected}
        value={allSelected}
      />
      <Typography variant="body2">Select All</Typography>
      {Object.keys(selections).length !== 0 && (
        <>
          <Button
            size={SIZE.small}
            startIcon={<EditIcon />}
            onClick={handleClickManage}
          >
            Manage
          </Button>
          <Button
            size={SIZE.small}
            startIcon={<LocalOfferOutlinedIcon />}
            onClick={() => setOpenTagsDialog(true)}
          >
            Edit Tags
          </Button>
        </>
      )}
    </Box>
  )

  const handleImportClick = async e => {
    if (e.target.files.length > 0) {
      // need the name for handling the result but the BE doesn't give it to us
      csvName.current = e.target.files[0].name
      importCSV(e.target)
      setUploadingState('uploading')
    } else {
      showAlertError('No files specified for upload')
    }
  }

  const handleBulkUpdateVariantLocationsClick = async e => {
    if (e.target.files.length > 0) {
      bulkUpdateVariantLocations(e.target)
      setBulkUpdateVariantLocationsState('uploading')
    } else {
      showAlertError('No files specified for upload')
    }
  }

  return (
    <>
      <Dialog
        title="Bulk Update Variant Statuses"
        open={openBulkUpdateVariantLocations}
        onConfirm={() => {
          setOpenBulkUpdateVariantLocations(false)
          setBulkUpdateVariantLocationsState('none')
        }}
        hideCancel
        confirmText="Close"
      >
        <>
          <Typography variant="h5">
            Upload a CSV of Variant life cycle statuses per location.
          </Typography>
          <Typography>
            Expects columns:
            <ul>
              <li>
                <b>variant_id</b>
              </li>
              <li>
                <b>location_id</b>
              </li>
              <li>
                <b>status</b> (npi, new, active, discontinued, inactive)
              </li>
            </ul>
          </Typography>
          <Box mt={1} mb={1}>
            Example:
            <table border="1px solid black">
              <tr>
                <th>variant_id</th>
                <th>location_id</th>
                <th>status</th>
              </tr>
              <tr>
                <td>1234</td>
                <td>1</td>
                <td>active</td>
              </tr>
              <tr>
                <td>5678</td>
                <td>2</td>
                <td>discontinued</td>
              </tr>
            </table>
          </Box>
          <Box mt={2}>
            {bulkUpdateVariantLocationsState === 'none' && (
              <label htmlFor="import-csv">
                <input
                  data-test="bulk-update-variant-locations-file"
                  accept=".csv"
                  style={{ display: 'none' }}
                  id="import-csv"
                  name="import-csv"
                  type="file"
                  onChange={handleBulkUpdateVariantLocationsClick}
                />
                <Button
                  className={classes.productButton}
                  variant="outlined"
                  size={SIZE.medium}
                  startIcon={<ImportIcon />}
                  component="span"
                >
                  Click to Upload
                </Button>
              </label>
            )}
            {bulkUpdateVariantLocationsState === 'uploading' && (
              <Typography data-test="bulk-update-variant-locations-status">
                Uploading...
              </Typography>
            )}
            {bulkUpdateVariantLocationsState === 'done' && (
              <Typography data-test="bulk-update-variant-locations-status">
                Done!
              </Typography>
            )}
          </Box>
        </>
      </Dialog>
      <Dialog
        title="Bulk Update Prices"
        open={openBulkUpdatePrices}
        onConfirm={() => {
          setOpenBulkUpdatePrices(false)
          setUploadingState('none')
        }}
        hideCancel
        confirmText="Close"
      >
        <>
          <Typography variant="h5">
            Upload a CSV of bulk price changes.
          </Typography>
          <Typography>
            Expects columns:
            <ul>
              <li>
                <b>variant_id</b>
              </li>
              <li>
                <b>price</b>: price is a <i>float</i> in <i>dollars</i>, so{' '}
                <i>$13.45</i> should be <i>13.45</i>
              </li>
            </ul>
          </Typography>
          <Box mt={1} mb={1}>
            Example:
            <table border="1px solid black">
              <tr>
                <th>variant_id</th>
                <th>price</th>
              </tr>
              <tr>
                <td>89</td>
                <td>11.00</td>
              </tr>
            </table>
          </Box>
          <Typography>
            After processing is complete results will be <b>emailed</b> to you.
            Processing may take a few minutes.
          </Typography>
          <Box mt={2} />
          {uploadingState === 'none' && (
            <label htmlFor="import-csv">
              <input
                accept=".csv"
                style={{ display: 'none' }}
                id="import-csv"
                name="import-csv"
                type="file"
                onChange={handleImportClick}
              />
              <Button
                className={classes.productButton}
                variant="outlined"
                size={SIZE.medium}
                startIcon={<ImportIcon />}
                component="span"
              >
                Click to Upload
              </Button>
            </label>
          )}
          {uploadingState === 'uploading' && (
            <Typography>Uploading...</Typography>
          )}
          {uploadingState === 'done' && (
            <Typography>
              Processing, check email in a few minutes for results.
            </Typography>
          )}
        </>
      </Dialog>
      <Layout id="products-list">
        <Header
          title="Products"
          actions={
            <>
              <Button
                adaptive
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                label="new product button"
                size={SIZE.medium}
                onClick={createNewProductClick}
                dataTest="new-product"
              >
                New Product
              </Button>
              <Button
                onClick={() => setOpenBulkUpdatePrices(true)}
                className={classes.productButton}
                variant="outlined"
                size={SIZE.medium}
                startIcon={<ImportIcon />}
              >
                Upload Prices
              </Button>
              <Button
                dataTest="bulk-update-variant-locations-button"
                onClick={() => setOpenBulkUpdateVariantLocations(true)}
                className={classes.productButton}
                variant="outlined"
                size={SIZE.medium}
                startIcon={<ImportIcon />}
              >
                Upload Variant Statuses
              </Button>
            </>
          }
          foldableActions={
            <SearchInput
              dataTest="product-search"
              name="search"
              placeholder="Search Products..."
              value={query.search}
              onChange={handleQueryChange}
            />
          }
          filters={filters}
          summarizers={summarizers}
        />
        <DataTable
          columns={columns}
          message={products?.length === 0 ? 'No results found.' : null}
          isLoadingList={isLoadingProducts}
          hasListNext={hasProductsNext}
          listNext={listProductsNext}
          query={query}
          updateQuery={updateQuery}
          headerAction={headerAction}
          headerActionOpen
        >
          {hasProducts &&
            products.map((product, idx) => (
              <ProductItem
                key={idx}
                product={product}
                handleSelect={handleSelect}
                selected={product.id in selections}
                locationId={locationId}
              />
            ))}
        </DataTable>

        <UpdateProductModal
          open={updateModalOpen}
          products={Object.values(selections)}
          onSubmit={handleUpdate}
          onClose={() => setUpdateModalOpen(false)}
        />
        <EditTags
          bulkApplyTags={bulkApplyTags}
          open={openTagsDialog}
          onClose={() => {
            setOpenTagsDialog(false)
          }}
          selectedProducts={selections}
          onUpdate={async (updates, cb) => {
            updateProductsLocally(updates)
            await initialFetch()
            const updatedSelections = { ...selections }
            updates.forEach(update => {
              delete updatedSelections[update.id]
              updatedSelections[update.id] = update
            })
            setSelections({ ...updatedSelections })
            cb()
          }}
        />
      </Layout>
    </>
  )
}

ProductList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(theme => ({
  brandSearch: {
    '& .MuiFormControl-root': {
      width: 180,
      marginBottom: theme.spacing(1),

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      }
    }
  }
}))(ProductList)
