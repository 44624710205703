import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import uniqueId from 'lodash/uniqueId'
import { withStyles } from '@material-ui/core/styles'
import { Controller, useFormContext } from 'react-hook-form'

import { SELECT_NONE_OPTION, SELECT_ALL_OPTION } from 'constants/general'
import { MARGINS } from 'constants/enums'
import * as propTypes from 'constants/propTypes'
import { syntheticEvent, trimStringAndAppend } from 'utils/general'

import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'

import ChevronDownIcon from 'icons/ChevronDownIcon'
import Checkbox from 'components/Checkbox'

import styles from './SelectStyles'

const SelectRaw = ({
  style,
  classes,
  className,
  name,
  groupedItems,
  items,
  margin,
  fullWidth,
  onChange,
  multiple,
  value,
  innerLabel,
  label,
  disabled,
  error,
  helperText,
  minimal,
  inverted,
  skeleton,
  isLoaded,
  dataTest: dt,
  withNone,
  withAll,
  displayEmpty,
  children,
  open,
  onOpen,
  onClose
}) => {
  const dataTest = dt ?? name ?? ''

  if (inverted && !minimal) {
    console.warn(
      'prop minimal required with inverted, or u extend it to support non minimal :)'
    )
  }

  const [labelId] = useState(uniqueId('select-'))

  const handleChange = event => {
    const val = event.target.value
    onChange && onChange(syntheticEvent(val, name))
  }

  const hasInnerLabel = Boolean(innerLabel)
  const hasLabel = Boolean(label)

  const labelClassNames = classNames({
    [classes.label]: true,
    [classes.labelError]: error,
    [classes.disabled]: disabled
  })

  const formControlClassNames = classNames({
    [classes.disabled]: disabled,
    [className]: Boolean(className)
  })

  const multipleNotGrouped = multiple && !groupedItems

  const menuProps = {
    elevation: 0,
    classes: {
      paper: multipleNotGrouped ? classes.menuPaper : classes.minimalPaper,
      ...(multipleNotGrouped ? { list: classes.multipleMenuList } : {})
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  }

  const menuItemClasses = multipleNotGrouped
    ? {
        root: classes.multipleMenuItemRoot,
        selected: classes.multipleMenuItemSelected
      }
    : {}

  const inputClasses =
    !innerLabel && !minimal
      ? {
          root: classes.input,
          input: classes.centeredInput,
          inputMarginDense: classes.centeredInputDense
        }
      : {
          root: classes.input
        }

  const renderOptions = () => {
    if (children) return children
    if (groupedItems) {
      return groupedItems.map((group, i) =>
        [
          // you can now leave out "group" key from items to do a "multiple" select without the ugly multiple styles
          // otherwise using the multiple view is uglyAF and does not properly handle rendering checkboxes in the input
          group.group ? (
            <Box ml={2} mt={i > 0 ? 1 : 'auto'}>
              <Typography variant="caption">{group.group}</Typography>
            </Box>
          ) : null,
          group.items.map(({ label: lbl, value: v }, j) => (
            <MenuItem
              key={j}
              value={v}
              classes={menuItemClasses}
              data-test={`${dataTest}-select-${lbl}`}
            >
              {lbl}
              {minimal && (
                <div className={classes.selectIconContainer}>
                  <Checkbox
                    className={classes.checkMarkIcon}
                    checked={value.includes(v)}
                  />
                </div>
              )}
            </MenuItem>
          ))
        ].filter(Boolean)
      )
    }

    return (withNone
      ? [...items, ...SELECT_NONE_OPTION]
      : withAll
      ? [...SELECT_ALL_OPTION, ...items]
      : items
    ).map(i => (
      <MenuItem
        key={i.value}
        value={i.value}
        classes={menuItemClasses}
        disabled={i.disabled}
        data-test={`${dataTest}-option-${i.label}`}
      >
        {i.label}
        {minimal && (!multiple || groupedItems) && i.value === value ? (
          <div className={classes.selectIconContainer}>
            <Checkbox className={classes.checkMarkIcon} checked />
          </div>
        ) : null}
      </MenuItem>
    ))
  }

  const groupedItemsProps = groupedItems
    ? {
        renderValue: renderVal =>
          trimStringAndAppend(
            renderVal
              .reduce((labels, current) => {
                let currentLabel = ''
                groupedItems.map(group =>
                  group.items.forEach(item => {
                    if (item.value === current) {
                      currentLabel = item.label
                    }
                  })
                )
                return currentLabel && [...labels, currentLabel]
              }, [])
              .join(', '),
            18,
            '...'
          )
      }
    : {}

  return (
    <div
      className={classNames({
        [classes.minimal]: minimal,
        [classes.inverted]: inverted,
        [classes.selectRoot]: true
      })}
      style={style}
    >
      {hasLabel && !minimal ? (
        <Typography variant="subtitle1" className={labelClassNames}>
          {label}
        </Typography>
      ) : null}
      <FormControl
        margin={minimal ? MARGINS.dense : margin}
        fullWidth={fullWidth}
        variant="outlined"
        className={formControlClassNames}
        disabled={disabled}
        error={error}
      >
        {isLoaded && (hasInnerLabel || minimal) ? (
          <InputLabel id={labelId}>
            {minimal && hasLabel ? label : innerLabel}
          </InputLabel>
        ) : null}
        {!isLoaded ? (
          <Skeleton classes={{ text: classes.skeletonText }} {...skeleton} />
        ) : (
          <MuiSelect
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            labelId={labelId}
            value={value}
            onChange={handleChange}
            classes={{ icon: classes.icon }}
            input={
              <OutlinedInput
                name={name}
                classes={inputClasses}
                error={Boolean(error)}
              />
            }
            IconComponent={ChevronDownIcon}
            MenuProps={menuProps}
            multiple={multiple}
            data-test={dataTest}
            displayEmpty={displayEmpty}
            {...groupedItemsProps}
          >
            {renderOptions()}
          </MuiSelect>
        )}
        {helperText ? (
          <FormHelperText
            className={classNames({ [classes.disabled]: disabled })}
          >
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  )
}

const selectDefaultProps = {
  margin: MARGINS.normal,
  fullWidth: false,
  multiple: false,
  minimal: false,
  inverted: false,
  skeleton: {
    height: 70,
    width: 200
  },
  isLoaded: true,
  withNone: false,
  displayEmpty: false,
  withAll: false
}

SelectRaw.defaultProps = selectDefaultProps

const selectPropTypes = {
  style: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object), // items is required if groupedItems is not used
  groupedItems: PropTypes.arrayOf(PropTypes.object), // groupedItems is required if items not used
  margin: PropTypes.oneOf(Object.keys(MARGINS)),
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  value: propTypes.selectValue,
  label: PropTypes.string,
  innerLabel: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  minimal: PropTypes.bool,
  inverted: PropTypes.bool,
  skeleton: PropTypes.object,
  isLoaded: PropTypes.bool,
  dataTest: PropTypes.string,
  displayEmpty: PropTypes.bool,
  withNone: PropTypes.bool,
  withAll: PropTypes.bool
}
SelectRaw.propTypes = {
  ...selectPropTypes,
  classes: PropTypes.object.isRequired
}

const Select = withStyles(styles)(SelectRaw)

const SelectField = ({ name, onChange, defaultValueIndex, ...rest }) => {
  const formContext = useFormContext()
  const { errors } = formContext
  return (
    <Box>
      <Controller
        name={name}
        defaultValue={defaultValueIndex}
        render={({ ref, ...methods }) => (
          <Select
            name={name}
            error={Boolean(errors[name])}
            helperText={errors[name] && errors[name].message}
            inputRef={ref}
            {...rest}
            {...methods}
            onChange={({ target: { value } }) => {
              onChange && onChange(value, formContext)
              methods.onChange(value)
            }}
          />
        )}
      />
    </Box>
  )
}

SelectField.defaultProps = selectDefaultProps
SelectField.propTypes = selectPropTypes

export { Select as default, SelectField }
