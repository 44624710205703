const jssStyles = theme => ({
  centered: {
    transform: 'translate(-50%, -50%)'
  },
  dot: {
    borderRadius: '50%',
  },
  clickable: {
    cursor: 'pointer'
  },
  black: {
    backgroundColor: theme.palette.primary.black
  },
  green: {
    backgroundColor: theme.palette.warnings.success
  },
  red: {
    backgroundColor: theme.palette.warnings.danger
  },
})

export default jssStyles
