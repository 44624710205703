import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import uniqBy from 'lodash/uniqBy'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminProducts } from 'hooks'
import AutocompleteSearch from 'components/AutocompleteSearch'

import styles from './AutocompleteSearchProductStyles'

const AutocompleteSearchProductRaw = ({
  name,
  classes,
  onChange,
  value,
  defaultOpen,
  excludedProducts,
  multiple
}) => {
  const {
    isLoadingProducts,
    products,
    listProducts,
    listProductsNext
  } = useAdminProducts({})
  const [query, setQuery] = useState('')

  useEffect(() => {
    listProducts({ search: query })
  }, [query])

  const excludedIds = useMemo(
    () => new Set((excludedProducts ?? []).map(p => p.id)),
    [excludedProducts]
  )

  const options = useMemo(
    () =>
      uniqBy(
        (products ?? []).filter(p => !excludedIds?.has(p.id)).concat(value),
        v => v.id
      ),
    [products, excludedIds]
  )

  return (
    <AutocompleteSearch
      name={name}
      defaultOpen={defaultOpen}
      value={value}
      loading={isLoadingProducts}
      setQuery={setQuery}
      onChange={onChange}
      onScrollBottom={listProductsNext}
      options={options}
      placeholder="Search Products"
      multiple={multiple}
      getOptionLabel={opt => opt?.title}
      renderOption={(option, { selected }) => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          className={selected ? classes.selectedItem : null}
        >
          <Typography variant="body1" className={classes.productTitle}>
            {option.title}
          </Typography>
          <Typography variant="body1" className={classes.productBrand}>
            {option.brand}
          </Typography>
        </Box>
      )}
    />
  )
}

AutocompleteSearchProductRaw.defaultProps = {
  value: [],
  defaultOpen: false,
  multiple: false
}

AutocompleteSearchProductRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ]),
  defaultOpen: PropTypes.bool,
  excludedProducts: PropTypes.arrayOf(PropTypes.object),
  multiple: PropTypes.bool
}

const AutocompleteSearchProduct = withStyles(styles)(
  AutocompleteSearchProductRaw
)

const AutocompleteSearchProductControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchProduct
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchProductControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchProduct as default,
  AutocompleteSearchProductControlled
}
