import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { PRINTER_TYPE_LABEL } from 'constants/enums'

import styles from './PrinterConfigStyles'

const PrinterShow = ({ classes, printers, onEditClick }) => (
  <Box>
    {printers?.map((printer, idx) => (
      <Box
        key={idx}
        className={classes.printerCard}
        onClick={() => onEditClick(printer, idx)}
      >
        <Typography>{printer.name}</Typography>
        <Typography style={{ fontStyle: 'italic' }}>
          {PRINTER_TYPE_LABEL[printer.type]} Printer
        </Typography>
        {/* TODO(@cristobalchao): Uncomment when BE is ready
            <Typography>{printer.enabled ? 'Enabled' : 'Disabled'}</Typography> */}
      </Box>
    ))}
  </Box>
)

PrinterShow.propTypes = {
  classes: PropTypes.object.isRequired,
  printers: PropTypes.array.isRequired,
  onEditClick: PropTypes.func.isRequired
}

export default withStyles(styles)(PrinterShow)
