import React from 'react'
import PropTypes from 'prop-types'

const ChevronRightIcon = ({ fill, width, className }) => (
  <svg
    width={width}
    height={1.75 * width}
    viewBox="0 0 8 14"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.03411 14C1.09462 13.9958 1.11002 13.9964 1.16992 13.986C1.30462 13.9628 1.43418 13.9117 1.54849 13.8367C1.60629 13.7988 1.65674 13.7547 1.70714 13.7077L7.7074 7.70743C7.7488 7.66308 7.76035 7.65298 7.7971 7.60453C7.83835 7.55012 7.87401 7.49147 7.90336 7.42977C8.03156 7.16021 8.03156 6.84034 7.90336 6.57078C7.87401 6.50908 7.83835 6.45043 7.7971 6.39602C7.76035 6.34762 7.7488 6.33747 7.7074 6.29317L1.70714 0.292913C1.66279 0.251511 1.65264 0.23991 1.60424 0.203209C1.47713 0.106755 1.32713 0.0416518 1.16992 0.0145006C1.05766 -0.00480021 0.942309 -0.00480021 0.830054 0.0145006C0.740301 0.0300013 0.652747 0.0578025 0.570493 0.0969042C0.25808 0.245461 0.0405703 0.553624 0.00516891 0.897738C-0.00878143 1.03374 0.00546932 1.17225 0.0468211 1.30256C0.0812726 1.41111 0.134325 1.51356 0.203127 1.60427C0.239879 1.65272 0.25143 1.66282 0.292831 1.70717L5.58596 7.0003L0.292831 12.2934L0.24623 12.3433C0.207978 12.3903 0.197077 12.4013 0.163776 12.4521C0.101323 12.5473 0.0553212 12.6531 0.0283699 12.7637C0.00681877 12.8523 -0.0025816 12.9437 0.000518799 13.0347C0.0123191 13.3804 0.208328 13.7027 0.509841 13.8722C0.609095 13.928 0.717849 13.9667 0.830054 13.986C0.889957 13.9964 0.905358 13.9958 0.96586 14C0.988611 14 1.01136 14 1.03411 14Z"
      fill={fill}
    />
  </svg>
)

ChevronRightIcon.defaultProps = {
  fill: 'black',
  width: 8
}

ChevronRightIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  className: PropTypes.string
}

export default ChevronRightIcon
