import { MINIMAL_LABEL } from 'constants/styles'

const jssStyles = theme => ({
  memo: {
    margin: theme.spacing(2, 0),
    '&:first-of-type': {
      marginTop: 0
    },
    '& .MuiTypography-body1': {
      fontWeight: 700
    },
    '& .MuiTypography-body2': {
      fontWeight: 400,
      color: theme.palette.text.primary
    },
    '& .MuiTypography-caption': {
      ...MINIMAL_LABEL(theme),
      textTransform: 'none',
      letterSpacing: 0,
      color: theme.palette.grays.dark
    }
  }
})
export default jssStyles
