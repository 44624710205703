import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useMediaQuery } from 'hooks'

import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

const Layout = ({ classes, layoutClassName, children, fullWidth, id }) => {
  const { isDesktopScreen } = useMediaQuery()

  return (
    <Box
      data-test={`${id}-page`}
      className={classNames({
        [classes.root]: !isDesktopScreen,
        [classes.mobileRoot]: isDesktopScreen,
        [layoutClassName]: Boolean(layoutClassName),
        [classes.fullWidth]: fullWidth
      })}
    >
      {children}
    </Box>
  )
}

Layout.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  layoutClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool
}

const ROOT = {
  maxWidth: '100vw',
  display: 'flex',
  maxHeight: '100vh',
  flexDirection: 'column',
  width: 'calc(100% - 224px)',
  height: '100%'
  // marginLeft: '224px'
}

export default withStyles(() => ({
  root: {
    ...ROOT,
    paddingRight: 16,
    paddingLeft: 16
  },
  mobileRoot: {
    ...ROOT,
    width: '100%',
    marginLeft: 0,
    paddingLeft: 8,
    paddingRight: 8
  }
}))(Layout)
