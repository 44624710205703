import { startCase, camelCase } from 'lodash'

export const toTitleCase = (str: string) => startCase(camelCase(str))

// https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-123.php
export const toKebabCase = (str: string = '') =>
  str
    ?.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    )
    ?.map(x => x.toLowerCase())
    ?.join('-') ?? ''
