export default theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  tabs: {
    height: 'calc(100% - 86px)'
  },
  tabPanel: {
    height: 'calc(100% - 164px)'
  }
})
