import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'

import { URL } from 'constants/navigation'
import { SIZE, STATUS_DOT_VARIANTS } from 'constants/enums'
import { getPromoCodeCaption } from 'utils/promoCodes'

import { useMenu, useNavigation } from 'hooks'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'

import UserAvatar from 'components/UserAvatar'
import StatusDot from 'components/StatusDot'

const PromoCodeStatusDot = ({ status }) => {
  const { label, indicator } = [
    ...[
      {
        value: 'active',
        label: 'Active',
        indicator: STATUS_DOT_VARIANTS.green
      },
      {
        value: 'inactive',
        label: 'Inactive',
        indicator: STATUS_DOT_VARIANTS.red
      },
      {
        value: 'scheduled',
        label: 'Scheduled',
        indicator: STATUS_DOT_VARIANTS.gray
      },
      {
        value: 'past',
        label: 'Expired',
        indicator: STATUS_DOT_VARIANTS.gray
      },
      {
        value: 'n/a',
        label: 'Not Available',
        indicator: STATUS_DOT_VARIANTS.gray
      }
    ]
  ].find(t => t?.value === status)

  return <StatusDot text={label} variant={indicator} />
}

PromoCodeStatusDot.propTypes = {
  status: PropTypes.string
}

const PromoCodeItem = ({
  item,
  toggleCodeActivation,
  deleteItem,
  handleEditClick,
  handleDuplicateClick,
  classes
}) => {
  const { open, anchorEl, handleToggleMenu, handleClose } = useMenu()

  const {
    code,
    status,
    start_time: startTime,
    end_time: endTime,
    author,
    user,
    use_count: useCount,
    active
  } = item

  const createdBy = author ?? user
  const createdByUrl = author ? URL.ADMIN_INTERNAL_USERS : URL.ADMIN_USERS

  const { handleClick: handleUserClick } = useNavigation({
    url: createdByUrl
  })

  return (
    <TableRow data-test="promo-code-row">
      <TableCell>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Button
            color="primary"
            onClick={() => handleEditClick(item)}
            onAuxClick={() => handleEditClick(item)}
            data-test="promo-item"
            className={classes.plainButton}
          >
            {code}
          </Button>
          <Typography className={classes.underLine} variant="caption">
            {getPromoCodeCaption(item)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <IconButton
            size={SIZE.small}
            onClick={e => handleUserClick(e, createdBy)}
            onAuxClick={e => handleUserClick(e, createdBy, true)}
          >
            <UserAvatar size={SIZE.medium} src={createdBy?.image_url} />
          </IconButton>
          <Box ml={1}>
            <Typography variant="body1" data-test="created-by-user-name">
              {createdBy?.name}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{useCount}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {startTime ? moment(startTime).format('L') : 'N/A'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {endTime ? moment(endTime).format('L') : 'N/A'}
        </Typography>
      </TableCell>
      <TableCell>
        <PromoCodeStatusDot status={status} />
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={e => handleToggleMenu(e)}>
          <MoreVertIcon />
          <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
            <MenuItem onClick={e => handleEditClick(item)}>Edit</MenuItem>
            <MenuItem onClick={() => handleDuplicateClick(item)}>
              Duplicate
            </MenuItem>
            <MenuItem onClick={e => toggleCodeActivation(item)}>
              {active ? 'Deactivate' : 'Activate'}
            </MenuItem>
            {item?.can_delete && (
              <MenuItem onClick={() => deleteItem(item)}>Delete</MenuItem>
            )}
          </Menu>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

PromoCodeItem.propTypes = {
  item: PropTypes.object.isRequired,
  setItemActive: PropTypes.func.isRequired,
  setItemInactive: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleDuplicateClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(theme => ({
  plainButton: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    color: '#111111',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    height: 'auto'
  },
  underLine: {
    paddingLeft: theme.spacing(1)
  }
}))(PromoCodeItem)
