import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import { SIZE } from 'constants/enums'
import { LIST_PATHS } from 'constants/queryParams'
import { URL } from 'constants/navigation'
import {
  useQuery,
  useAdminCategories,
  useAdminSubcategories,
  useMediaQuery
} from 'hooks'

import Header from 'components/Header'
import Layout from 'components/Layout'
import SearchInput from 'components/SearchInput'
import Select from 'components/Select'
import Tabs from 'components/Tabs'

import SubcategoriesPane from './SubcategoriesPane'
import ParentCategoriesPane from './ParentCategoriesPane'

import styles from './CategoriesStyles'

const TEST_ID = 'categories'

const SUBCATEGORIES_INDEX = 0
const PARENT_CATEGORIES_INDEX = 1

const SUBCATEGORIES_OPTION = {
  label: 'Subcategories',
  value: SUBCATEGORIES_INDEX
}

const PARENT_CATEGORIES_OPTION = {
  label: 'Parent Categories',
  value: PARENT_CATEGORIES_INDEX
}

const Categories = ({ classes }) => {
  const [tabIndexActive, setTabIndexActive] = useState(SUBCATEGORIES_INDEX)

  const { query, setQuery, handleQueryChange, updateQuery } = useQuery()
  const {
    subcategories,
    hasSubcategories,
    hasSubcategoriesNext,
    isLoadingSubcategories,
    subcategoriesCount,
    listSubcategoriesNext
  } = useAdminSubcategories(tabIndexActive === SUBCATEGORIES_INDEX && query)

  const {
    categories,
    hasCategories,
    hasCategoriesNext,
    isLoadingCategories,
    categoriesCount,
    listCategoriesNext
  } = useAdminCategories(tabIndexActive === PARENT_CATEGORIES_INDEX && query)

  const [openDialogIndex, setOpenDialogIndex] = useState(false)

  useEffect(() => {
    setQuery('')
  }, [tabIndexActive])

  const foldableActions = (
    <SearchInput
      name="search"
      placeholder="Search..."
      value={query.search}
      onChange={handleQueryChange}
      dataTest={`${TEST_ID}-search`}
    />
  )

  const actions = (
    <Button
      color="primary"
      variant="contained"
      size={SIZE.medium}
      onClick={() => setOpenDialogIndex(tabIndexActive)}
      adaptive
      label="New Category"
      startIcon={<AddIcon />}
      data-test={`${TEST_ID}-new-item`}
    >
      New Category
    </Button>
  )

  const filters = (
    <Select
      minimal
      name={
        tabIndexActive === SUBCATEGORIES_INDEX
          ? LIST_PATHS.SUBCATEGORIES_STATUS
          : LIST_PATHS.CATEGORIES_STATUS
      }
      innerLabel="Status"
      value={
        tabIndexActive === SUBCATEGORIES_INDEX
          ? query?.[LIST_PATHS.SUBCATEGORIES_STATUS] || ''
          : query?.[LIST_PATHS.CATEGORIES_STATUS] || ''
      }
      items={[
        { label: 'All', value: '' },
        { label: 'Active', value: 'true' },
        { label: 'Inactive', value: 'false' }
      ]}
      onChange={handleQueryChange}
      dataTest={`${TEST_ID}-select-${
        tabIndexActive === SUBCATEGORIES_INDEX
          ? LIST_PATHS.SUBCATEGORIES_STATUS
          : LIST_PATHS.CATEGORIES_STATUS
      }`}
    />
  )
  const { isDesktopLargeScreen: isDesktopOrLarger } = useMediaQuery()

  const getTabsPanes = () => {
    const subcategoriesPane = {
      id: SUBCATEGORIES_INDEX,
      label: SUBCATEGORIES_OPTION.label,
      pane: (
        <SubcategoriesPane
          openCreateDialog={openDialogIndex === SUBCATEGORIES_INDEX}
          onCloseDialog={() => setOpenDialogIndex(false)}
          subcategories={subcategories || []}
          hasSubcategories={hasSubcategories}
          subcategoriesCount={subcategoriesCount}
          isLoadingList={isLoadingSubcategories}
          hasListNext={hasSubcategoriesNext}
          listNext={listSubcategoriesNext}
          query={query}
          updateQuery={updateQuery}
          handleQueryChange={handleQueryChange}
        />
      )
    }
    const parentCategoriesPane = {
      id: PARENT_CATEGORIES_INDEX,
      label: PARENT_CATEGORIES_OPTION.label,
      pane: (
        <ParentCategoriesPane
          openCreateDialog={openDialogIndex === PARENT_CATEGORIES_INDEX}
          onCloseDialog={() => setOpenDialogIndex(false)}
          categories={categories || []}
          hasCategories={hasCategories}
          categoriesCount={categoriesCount}
          isLoadingList={isLoadingCategories}
          hasListNext={hasCategoriesNext}
          listNext={listCategoriesNext}
          query={query}
          updateQuery={updateQuery}
          handleQueryChange={handleQueryChange}
        />
      )
    }

    return [subcategoriesPane, parentCategoriesPane]
  }

  return (
    <Layout id={TEST_ID}>
      <Header
        title="Categories"
        breadcrumbs={[
          {
            title: 'Back Of House',
            link: URL.ADMIN_BOH
          }
        ]}
        foldableActions={foldableActions}
        actions={actions}
        filters={!isDesktopOrLarger && filters}
      />
      <Tabs
        panes={getTabsPanes()}
        classes={{ root: classes.tabs, tabPanel: classes.tabPanel }}
        onChange={idx => setTabIndexActive(idx)}
      />
    </Layout>
  )
}

Categories.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Categories)
