import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeGrid } from 'react-window'

import { useLocationsContext } from 'context'

import * as propTypes from 'constants/propTypes'
import { SCROLL_WIDTH_PX } from 'constants/styles'
import { COLLECTION_LAYOUT_VARIANT } from 'constants/enums'
import { COLLECTIONS_BLOCK_ASPECT_RATIO } from 'constants/collections'

import CollectionVirtualizedCell from 'components/CollectionVirtualizedCell'
import Button from 'components/Button'
import Confirm from 'components/Confirm'
import Dialog from 'components/Dialog'

import styles from './ManageCollectionModalStyles'

const ManageCollectionModal = ({
  open,
  items,
  onSelect,
  onSubmit,
  onClose,
  variant,
  classes
}) => {
  const [confirmDeactivateOpen, setConfirmDeactivateOpen] = useState(false)
  const [confirmActivateOpen, setConfirmActivateOpen] = useState(false)

  const { location, showAllLocationIds, locations } = useLocationsContext()

  const isBrandVariant = variant === COLLECTION_LAYOUT_VARIANT.brand

  const labelType = isBrandVariant ? 'Brands' : 'Collections'
  const actionLabels = isBrandVariant
    ? {
        activate: 'Activate',
        deactivate: 'Deactivate'
      }
    : {
        activate: 'Publish',
        deactivate: 'Unpublish'
      }

  useEffect(() => {
    if (open) return

    setConfirmDeactivateOpen(false)
    setConfirmActivateOpen(false)
  }, [open])

  useEffect(() => {
    if (items?.length < 1 && open) {
      onClose()
    }
  }, [items])

  const confirmActivate = () => {
    onSubmit(
      items,
      true,
      showAllLocationIds ? locations.map(l => l.id) : [location.id]
    )
    setConfirmDeactivateOpen(false)
    onClose()
  }

  const confirmDeactivate = () => {
    onSubmit(
      items,
      false,
      showAllLocationIds ? locations.map(l => l.id) : [location.id]
    )
    setConfirmDeactivateOpen(false)
    onClose()
  }

  const modalActions = (
    <>
      <Confirm
        open={confirmDeactivateOpen}
        description={`Are you sure you want to ${actionLabels.deactivate.toLowerCase()} these ${labelType.toLowerCase()}?`}
        onClose={() => setConfirmDeactivateOpen(false)}
        onConfirm={confirmDeactivate}
      >
        <Button
          variant="outlined"
          fullWidth
          onClick={() => setConfirmDeactivateOpen(true)}
          dataTest={`manage-${actionLabels.deactivate}`}
        >
          {actionLabels.deactivate}
        </Button>
      </Confirm>
      <Box ml={2} />
      <Confirm
        open={confirmActivateOpen}
        description={`Are you sure you want to ${actionLabels.activate.toLowerCase()} these ${labelType.toLowerCase()}?`}
        onClose={() => setConfirmActivateOpen(false)}
        onConfirm={confirmActivate}
      >
        <Button
          fullWidth
          onClick={() => setConfirmActivateOpen(true)}
          dataTest={`manage-${actionLabels.activate}`}
        >
          {actionLabels.activate}
        </Button>
      </Confirm>
    </>
  )

  const SizedGrid = ({ width, height }) => {
    const columnCount = items.length > 2 ? 2 : 1
    const rowCount = Math.ceil(items.length / columnCount)

    const columnWidth = Math.floor((width - SCROLL_WIDTH_PX) / columnCount)
    const rowHeight = Math.floor(
      width / columnCount / COLLECTIONS_BLOCK_ASPECT_RATIO
    )

    return (
      <FixedSizeGrid
        columnCount={columnCount}
        rowCount={rowCount}
        height={height}
        width={width}
        columnWidth={columnWidth}
        rowHeight={rowHeight}
        itemData={{
          items,
          variant,
          columnCount,
          onSelect,
          withRemove: true,
          showPublish: false
        }}
      >
        {CollectionVirtualizedCell}
      </FixedSizeGrid>
    )
  }

  return (
    <Dialog
      title={`Manage ${labelType}`}
      subtitle={
        !isBrandVariant &&
        `Publishing To  ${
          showAllLocationIds ? 'All Locations' : location?.name
        }`
      }
      open={open}
      onClose={onClose}
      hideActions
    >
      <Box flex={1} height={400} mx={-2}>
        <AutoSizer>{SizedGrid}</AutoSizer>
      </Box>
      <Box className={classes.modalActions}>{modalActions}</Box>
    </Dialog>
  )
}

ManageCollectionModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: propTypes.collectionLayoutVariant
}

export default withStyles(styles)(ManageCollectionModal)
