import * as yup from 'yup'
import fromPairs from 'lodash/fromPairs'

export const FORM_FIELDS_FULL = {
  TITLE: {
    name: 'title',
    schema: yup.string().required('Title is required'),
    forEdit: true
  },
  COLLECTION_IDS: {
    name: 'collection_ids',
    schema: yup.array().of(yup.number()),
    forEdit: true
  },
  COLLECTIONS: {
    name: 'collections',
    schema: yup.array().of(yup.object()),
    forEdit: false
  }
}

export const FORM_FIELDS = fromPairs(
  Object.entries(FORM_FIELDS_FULL).map(([key, { name }]) => [key, name])
)

export const NEW_COLLECTION_GROUP_SCHEMA = fromPairs(
  Object.values(FORM_FIELDS_FULL).map(({ name, schema }) => [name, schema])
)

export const EDIT_COLLECTION_GROUP_SCHEMA = fromPairs(
  Object.values(FORM_FIELDS_FULL)
    .filter(v => v.forEdit)
    .map(({ name, schema }) => [name, schema])
)

export const EDIT_COLLECTION_GROUP_FIELDS = Object.values(FORM_FIELDS_FULL)
  .filter(v => v.forEdit)
  .map(v => v.name)
