import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import uniqBy from 'lodash/uniqBy'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminProducts } from 'hooks'
import AutocompleteSearch from 'components/AutocompleteSearch'

import styles from './AutocompleteSearchBadgesStyles'

const AutocompleteSearchBadgesRaw = ({
  name,
  classes,
  onChange,
  value,
  defaultOpen,
  multiple,
  helperText,
  error
}) => {
  const {
    isLoadingBadges,
    badges,
    listBadges,
    listBadgesNext
  } = useAdminProducts({})
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (query.length) {
      listBadges({ search: query })
    } else {
      listBadges()
    }
  }, [query])

  const options = useMemo(
    () =>
      uniqBy((badges ?? []).concat(value), v => v?.id).filter(
        badge => badge?.active
      ),
    [badges]
  )

  return (
    <Box mt={1}>
      <AutocompleteSearch
        name={name}
        helperText={helperText}
        error={error}
        defaultOpen={defaultOpen}
        value={value}
        loading={isLoadingBadges}
        setQuery={setQuery}
        onChange={onChange}
        onScrollBottom={listBadgesNext}
        options={options}
        placeholder="Badge"
        multiple={multiple}
        getOptionLabel={opt => opt?.name ?? ''}
        renderOption={(option, { selected }) => (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="100%"
            className={selected ? classes.selectedItem : null}
          >
            <Typography variant="body1" className={classes.badgeName}>
              {option?.name ?? ''}
            </Typography>
          </Box>
        )}
      />
    </Box>
  )
}

AutocompleteSearchBadgesRaw.defaultProps = {
  value: [],
  defaultOpen: false,
  multiple: true
}

AutocompleteSearchBadgesRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string
  ]),
  defaultOpen: PropTypes.bool,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool
}

const AutocompleteSearchBadges = withStyles(styles)(AutocompleteSearchBadgesRaw)

const AutocompleteSearchBadgesControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchBadges
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchBadgesControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchBadges as default,
  AutocompleteSearchBadgesControlled
}
