const jssStyles = theme => ({
  headerButtons: {
    paddingLeft: theme.spacing(1),
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  addButton: {
    height: 37
  },
  code: {
    marginLeft: 'auto'
  },
  activeCode: {
    color: theme.palette.warnings.success
  },
  inactivedCode: {
    color: theme.palette.warnings.danger
  },
  accessCodes: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    maxHeight: 400,
    overflowY: 'auto'
  },
  accessCode: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  }
})

export default jssStyles
