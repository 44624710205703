import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { useMenu } from 'hooks'

import ColorCircle from 'components/ColorCircle'

import styles from './ColorListStyles'

const AllColorsItem = ({ classes, color, onSetActive, onEditClick }) => {
  const { open, anchorEl, handleToggleMenu, handleClose } = useMenu()
  return (
    <TableRow data-test="all-colors-item">
      <TableCell>
        <Box display="flex">
          <ColorCircle hex={color?.hex} image={color?.multi_color_url} />
          <Box mr={1} />
          {color.label}
        </Box>
      </TableCell>
      <TableCell>{color.shopify_color}</TableCell>
      <TableCell>{color.master_color?.label}</TableCell>
      <TableCell>{color.variants_count}</TableCell>
      <TableCell>
        {color.created_at ? moment(color.created_at).format('L') : '...'}
      </TableCell>
      <TableCell className={classes.statusCell}>
        <Box
          className={classNames({
            [classes.statusTag]: true,
            [classes.active]: color.active,
            [classes.inactive]: !color.active
          })}
        >
          {color.active ? 'Active' : 'Inactive'}
        </Box>
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={e => handleToggleMenu(e)}>
          <MoreVertIcon />
          <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
            {color.active ? (
              <MenuItem onClick={() => onSetActive(false)}>Inactive</MenuItem>
            ) : (
              <MenuItem onClick={() => onSetActive(true)}>Active</MenuItem>
            )}
            <MenuItem onClick={() => onEditClick(color)}>Edit</MenuItem>
          </Menu>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

AllColorsItem.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.object,
  onSetActive: PropTypes.func,
  onEditClick: PropTypes.func
}

export default withStyles(styles)(AllColorsItem)
