import { api } from 'api'
import { useLoaders } from 'hooks'
import { useAppConfigContext } from 'context'

const API_APP_CONFIG = '/api/v1/app/config'

const useConfig = () => {
  const { hideLoading } = useLoaders()
  const { setAppConfig, appConfig, referralTiers } = useAppConfigContext()

  const fetchAppConfig = async () => {
    try {
      const response = await api.get(API_APP_CONFIG)
      setAppConfig(response.data)
      hideLoading()
      return response.data
    } catch (e) {
      console.error(e)
    }
  }

  return {
    fetchAppConfig,
    appConfig,
    referralTiers
  }
}

export default useConfig
