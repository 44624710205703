// very valuable users
// Patritians over Plebs!
export const WHALES = [
  // NY
  6815,
  6300,
  8816,
  6643,
  4527,
  68286,
  10652,
  39385,
  7901,
  28274,
  7962,
  9803,
  11034,
  9729,
  7021,
  7140,
  6950,
  9590,
  9538,
  88419,
  3749,
  6299,
  6104,
  6917,
  7431,
  6704,
  87936,
  72539,
  6113,
  45496,
  6003,
  74379,
  13324,
  6597,
  5608,
  14612,
  33443,
  59222,
  6628,
  35104,
  3930,
  70195,
  7221,
  47024,
  6790,
  11962,
  2800,
  4597,
  33447,
  4082,
  2975,
  10343,
  3485,
  88617,
  6483,
  6936,
  9253,
  4097,
  16346,
  10069,
  88727,
  6564,
  7168,
  9282,
  878,
  2775,
  27892,
  2161,
  12607,
  9193,
  74135,
  11642,
  6953,
  6601,
  13065,
  9242,
  3623,
  6526,
  1498,
  14603,
  22488,
  12858,
  8909,
  85302,
  8468,
  7228,
  10651,

  // LA
  8784,
  7767,
  89338,
  72442,
  5886,
  4153,
  5398,
  3252,
  1066,
  87695,
  1255,
  7087,
  88995,
  8499,
  13808,
  7302,
  50345,
  9161,
  10166,
  3838,
  8397,
  87001,
  10364,
  6144,
  74111,
  7765,
  9352,
  46251,
  2898,
  15040,
  9569,
  34328,
  28,
  1524,
  2957,
  1245,
  7340,
  6877,
  6660,
  4143,
  6901,
  10123,
  12584,
  77740,
  9257,
  8302,
  10043,
  6960,
  8308,
  11588,
  984,
  53203,
  84153,
  7856,

  // SF
  12986,
  18340,
  51912,
  66203,
  28103
]
