import { DELIVERY_METHOD_KEYS } from './enums'
import { API_PARAMS } from './queryParams'

export const DEFAULT_TIMEZONE = 'America/Los_Angeles'

export const TERM_POSSIBILITIES = [
  {
    label: 'Consignment',
    value: 'consignment'
  },
  {
    label: 'Wholesale (CC Pre-Pay)',
    value: 'wholesale_cc_pre_pay'
  },
  {
    label: 'Wholesale (Net 15)',
    value: 'wholesale_net_15'
  },
  {
    label: 'Wholesale (Net 30)',
    value: 'wholesale_net_30'
  },
  {
    label: 'Wholesale (Net 45)',
    value: 'wholesale_net_45'
  },
  {
    label: 'Wholesale (Net 60)',
    value: 'wholesale_net_60'
  },
  {
    label: 'Wholesale (Net 90)',
    value: 'wholesale_net_90'
  }
]

export const TERM_POSSIBILITIES_WITH_NONE = [
  { label: 'None', value: '' },
  ...TERM_POSSIBILITIES
]

export const TERM_POSSIBILITIES_WITH_ALL = [
  { label: 'All Terms', value: '' },
  ...TERM_POSSIBILITIES
]

export const MEMO_MAX_LENGTH = 120

export const BEFORE_UNLOAD_MESSAGE =
  'There are unsaved changes, leaving this page without saving will discard them.'

export const NOTIFICATION_AUTO_HIDE_DURATION_MS = 5000

/** PRODUCT */
export const PRODUCT_PUBLISH_STATES = [
  { label: 'Published', value: 1 },
  { label: 'Unpublished', value: 0 }
]

export const PRODUCT_BLOCK_STATES = [
  { label: 'PDP Blocked', value: 1 },
  { label: 'PDP Unblocked', value: 0 }
]

export const REFRESH_INTERVAL_OPTIONS = [
  {
    label: '15 Seconds',
    value: 15000
  },
  {
    label: '30 Seconds',
    value: 30000
  },
  {
    label: '5 Minutes (default)',
    value: 300000
  }
]

/** ORDER */

export const BOGO_LABELS = {
  BuyOneGetOne: 'FREE',
  BuyXGetY: 'FREE',
  BuyXGetOneOf: 'FREE'
}

export const BOGO_TYPES = Object.keys(BOGO_LABELS)

export const ORDER_SEARCH_TYPES = {
  [API_PARAMS.SEARCH_BY_NUMBER]: 'Order Number'
}

export const ORDER_STATES_TYPES = {
  pending: 'pending',
  paid: 'paid',
  picking: 'picking',
  dispatched: 'dispatched',
  shipped: 'shipped',
  completed: 'completed',
  returned: 'returned',
  canceled: 'canceled',
  declined: 'declined',
  refunded: 'refunded',
  partially_refunded: 'partially_refunded',
  return_in_progress: 'return_in_progress'
}

export const ORDER_REFUND_STATES = [
  { value: ORDER_STATES_TYPES.return_in_progress, label: 'Return In Progress' },
  { value: ORDER_STATES_TYPES.returned, label: 'Returned' }
]
export const ORDER_WRITABLE_TRANSITIONS = [
  { value: ORDER_STATES_TYPES.paid, label: 'Paid' },
  { value: ORDER_STATES_TYPES.pending, label: 'Pending' },
  { value: ORDER_STATES_TYPES.picking, label: 'Picking' },
  { value: ORDER_STATES_TYPES.dispatched, label: 'Dispatched' },
  { value: ORDER_STATES_TYPES.shipped, label: 'In Transit' },
  { value: ORDER_STATES_TYPES.completed, label: 'Completed' },
  { value: ORDER_STATES_TYPES.canceled, label: 'Canceled' }
]

export const ORDER_PAYMENT_STATES = [
  { value: ORDER_STATES_TYPES.declined, label: 'Declined' },
  { value: ORDER_STATES_TYPES.refunded, label: 'Refunded' },
  {
    value: ORDER_STATES_TYPES.partially_refunded,
    label: 'Partially Refunded'
  }
]

export const ORDER_TRANSITIONS = [
  ...ORDER_WRITABLE_TRANSITIONS,
  ...ORDER_PAYMENT_STATES
]

export const ORDER_STATES = [{ value: '', label: 'All' }, ...ORDER_TRANSITIONS]

export const ORDER_STATES_CATEGORIES = {
  total: {
    key: 'total',
    label: 'Orders Total',
    state: null
  },
  picking: {
    key: 'picking',
    label: 'Orders Picking',
    state: ORDER_STATES_TYPES.picking
  },
  dispatched: {
    key: 'dispatched',
    label: 'Orders Dispatched',
    state: ORDER_STATES_TYPES.dispatched
  },
  inTransit: {
    key: 'inTransit',
    label: 'Orders En Route',
    state: ORDER_STATES_TYPES.shipped
  }
}

export const PAYMENT_STATUS = [
  { value: 'all', label: 'All' },
  { value: 'unpaid', label: 'Pending' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'declined', label: 'Declined' },
  { value: 'failed', label: 'Failed' },
  { value: 'fraud', label: 'Fraud' }
]

export const DELIVERY_METHOD_OPTIONS = [
  {
    key: DELIVERY_METHOD_KEYS.doordash,
    label: 'Doordash'
  },
  {
    key: DELIVERY_METHOD_KEYS.onfleet,
    label: 'Onfleet'
  },
  {
    key: DELIVERY_METHOD_KEYS.fastaf,
    label: 'Onfleet (Manual)'
  },
  {
    key: DELIVERY_METHOD_KEYS.roadie,
    label: 'Roadie'
  },
  {
    key: DELIVERY_METHOD_KEYS.global,
    label: 'Carrier (Global)'
  }
]

/** USER */
export const ME = 'me'

export const ADMIN_ROLES = [
  { value: 'admin', label: 'Admin' },
  { value: 'brand', label: 'Brand' },
  { value: 'staff', label: 'Staff' }
]

export const USER_ROLES = [...ADMIN_ROLES, { value: 'user', label: 'User' }]

export const USER_TYPES = [{ value: 'all', label: 'All' }, ...USER_ROLES]

export const YESNO = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
]

export const DELIVERY_STATUS_ICON_NAMES = {
  house: 'house',
  door: 'door',
  fastaf: 'fastaf'
}

/** INVENTORY */
export const INVENTORY_UPDATE_MODES = {
  SET_TO: 'Set To',
  INCREASE_BY: 'Increase By',
  DECREASE_BY: 'Decrease By'
}

export const DEFAULT_VARIANT_TITLE = 'Default Title'

export const ALL_LOCATION = {
  id: null,
  name: 'All Locations',
  code: 'ALL'
}

export const ZIPCODE_SERVICED_OPTIONS = [
  {
    value: true,
    label: 'Serviced'
  },
  {
    value: false,
    label: 'Unserviced'
  }
]

export const DARKSTORE_SERVICED_OPTIONS = [
  {
    value: true,
    label: 'Online'
  },
  {
    value: false,
    label: 'Offline'
  }
]

/** Hours of Operation */
export const DAY_STRING_FORMAT = 'YYYY-MM-DD'

export const SELECT_NONE_OPTION = [{ label: 'None', value: '' }]
export const SELECT_ALL_OPTION = [{ label: 'All', value: '' }]

export const RETURN_REASONS = {
  too_small: 'Size was too small',
  too_large: 'Size was too large',
  changed_mind: 'Customer changed their mind',
  wrong_description: 'Item not as described',
  wrong_item: 'Received the wrong item',
  damaged: 'Damaged or defective',
  style: 'Style',
  color: 'Color',
  other: 'Other'
}

export const REFUND_REASONS = {
  no_promo: 'Promo code not added to purchase',
  wrong_price: 'Wrong price',
  too_small: 'Size was too small',
  too_large: 'Size was too large',
  changed_mind: 'Customer changed their mind',
  wrong_description: 'Item not as described',
  wrong_item: 'Received the wrong item',
  damaged: 'Damaged or defective',
  style: 'Style',
  color: 'Color',
  other: 'Other'
}

export const STOREFRONT_SECTION_TYPES = {
  COLLECTION_SLIDER: 'collection_slider',
  PRODUCT_SLIDER: 'product_slider',
  BRAND_SLIDER: 'brand_slider',
  REFERRAL_CODE_BLOCK: 'referral_code_block',
  ORDER_AGAIN_BLOCK: 'order_again_block',
  RECOMMENDED_PRODUCTS_SLIDER: 'recommended_products_slider',
  COLLECTION_GROUP: 'collection_group'
}

export const STOREFRONT_SECTION_TITLES = {
  [STOREFRONT_SECTION_TYPES.COLLECTION_GROUP]: 'Collection Group',
  [STOREFRONT_SECTION_TYPES.COLLECTION_SLIDER]: 'Browse By Collection',
  [STOREFRONT_SECTION_TYPES.PRODUCT_SLIDER]: 'Browse Products',
  [STOREFRONT_SECTION_TYPES.BRAND_SLIDER]: 'Browse By Brand',
  [STOREFRONT_SECTION_TYPES.REFERRAL_CODE_BLOCK]: 'Referral Code',
  [STOREFRONT_SECTION_TYPES.ORDER_AGAIN_BLOCK]: 'Order Again',
  [STOREFRONT_SECTION_TYPES.RECOMMENDED_PRODUCTS_SLIDER]: 'Recommended For You'
}

export const STOREFRONT_SECTION_FRIENDLY_NAMES = {
  [STOREFRONT_SECTION_TYPES.COLLECTION_GROUP]: 'Swipeable Editorial',
  [STOREFRONT_SECTION_TYPES.COLLECTION_SLIDER]: 'Browse By Collection',
  [STOREFRONT_SECTION_TYPES.PRODUCT_SLIDER]: 'Product Carousel',
  [STOREFRONT_SECTION_TYPES.BRAND_SLIDER]: 'Browse By Brand',
  [STOREFRONT_SECTION_TYPES.REFERRAL_CODE_BLOCK]: 'Referral Code',
  [STOREFRONT_SECTION_TYPES.ORDER_AGAIN_BLOCK]: 'Order Again',
  [STOREFRONT_SECTION_TYPES.RECOMMENDED_PRODUCTS_SLIDER]: 'Recommended For You'
}
