import {
  collectionGroupEndpoint,
  collectionGroupListEndpoint
} from 'api/endpoints'

import { useResourceRQ } from 'hooks'
import { OPERATION_TYPE } from 'hooks/common/useResourceRQ'

const voidFunc = () => undefined

const useAdminCollectionGroups = (
  collectionGroupId,
  { onSuccess = voidFunc, onError = voidFunc } = {}
) => {
  const {
    data: collectionGroup,
    update: updateCollectionGroup,
    create: createCollectionGroup,
    destroy: destroyCollectionGroup
  } = useResourceRQ({
    endpoint: collectionGroupEndpoint,
    args: [collectionGroupId],
    name: 'collection_group',
    dependents: [collectionGroupListEndpoint],
    fetchingEnabled: !!collectionGroupId,
    onSuccess: {
      [OPERATION_TYPE.create]: data =>
        `Created new collection group, ${data?.title}`,
      [OPERATION_TYPE.update]: data =>
        `Updated collection group, ${data?.title}`
    },
    onError: err =>
      onError(
        `Collection Groups error, ${err?.data?.message ?? err?.statusText}`
      )
  })

  return {
    createCollectionGroup,
    collectionGroup,
    updateCollectionGroup,
    destroyCollectionGroup
  }
}

export default useAdminCollectionGroups
