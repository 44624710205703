import React from 'react'
import PropTypes from 'prop-types'
import { FORM_FIELDS } from 'constants/products'
import { withStyles } from '@material-ui/core/styles'
import { TextFieldControlled } from 'components/TextField';

import Box from '@material-ui/core/Box'

import 'react-markdown-editor-lite/lib/index.css'
import styles from './ProductIngredientFieldsStyles'

const ProductIngredientFieldsRaw = ({
  classes,
}) => (
  <Box width="100%" marginBottom="20px" marginTop="30px">
    <Box marginX="30px">
      <TextFieldControlled
        name={FORM_FIELDS.INGREDIENTS}
        dataTest="product-ingredients"
        label={"Ingredients"}
        placeholder="Salt, Vinegar, Pepper"
        fullWidth
        centeredInput
      />
    </Box>
    <Box marginX="30px">
      <TextFieldControlled
        name={FORM_FIELDS.ALLERGENS}
        dataTest="product-allergens"
        label={"ALLERGENS"}
        placeholder="Contatins Nuts"
        fullWidth
        centeredInput
      />
    </Box>
  </Box>
)

ProductIngredientFieldsRaw.propTypes = {}

const ProductIngredientFields = withStyles(styles)(ProductIngredientFieldsRaw)

export default ProductIngredientFields;