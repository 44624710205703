import { useEffect } from 'react'

import { useLoaders, useAlerts, usePrevious } from 'hooks'

/* Provides a decoupled way to show loading and error alerts with
   useResourceRQ and useResourceListRQ. Shows the loading notification
   when isLoading is true. Shows an error alert when isError is true. Can
   be used just for loading or error if desired. */
const useNotifications = (isLoading, isError, error) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError } = useAlerts()

  const previousIsLoading = usePrevious(isLoading)
  useEffect(() => {
    if (isLoading && !previousIsLoading) {
      showLoading()
    } else if (!isLoading && previousIsLoading) {
      hideLoading()
    }
    return () => hideLoading()
  }, [isLoading, previousIsLoading, showLoading, hideLoading])

  const previousError = usePrevious(error)
  useEffect(() => {
    if (isError && previousError !== error) {
      showAlertError(error)
    }
  }, [isError, previousError, showAlertError])

  return null
}

export default useNotifications
