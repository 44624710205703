import { useResourceListRQ } from 'hooks'
import { distributionCenterEndpoint } from 'api/endpoints'

const useAdminDistributionCenterList = ({ supplierId, params }) => {
  const {
    isFetching: isFetchingDistributionCenters,
    data: distributionCenters,
    hasData: hasDistributionCenters,
    hasNextPage: hasDistributionCentersNext,
    fetchNextPage: fetchDistributionCentersNext,
    meta
  } = useResourceListRQ({
    endpoint: distributionCenterEndpoint,
    args: [supplierId],
    dependents: [distributionCenterEndpoint],
    params
  })

  return {
    isFetchingDistributionCenters,
    distributionCenters: distributionCenters || [],
    hasDistributionCenters,
    hasDistributionCentersNext,
    fetchDistributionCentersNext,
    summary: meta?.summary
  }
}

export default useAdminDistributionCenterList
