import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'

import styles from './SpinnerStyles'

const Spinner = ({ active, classes }) => (
  <Backdrop open={active} className={classes.backdrop}>
    <CircularProgress variant="indeterminate" size={70} thickness={5} />
  </Backdrop>
)

Spinner.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool
}

export default withStyles(styles)(Spinner)
