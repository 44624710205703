import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Popover from '@material-ui/core/Popover'
import Box from '@material-ui/core/Box'
import ChevronDownIcon from 'icons/ChevronDownIcon'

import Button from 'components/Button/Button'
import AutocompleteSearchProductTags from 'components/AutocompleteSearchProductTags'

import styles from './SearchByTagStyles'

const SearchByTag = ({ onChange, classes, tags }) => {
  const [clearAll, setClearAll] = useState(false)
  const [anchorEl, setAnchorEl] = useState()
  const id = 'simple-popover-tags'
  return (
    <div>
      <Box
        onClick={e => setAnchorEl(e.currentTarget)}
        aria-describedby={id}
        className={classes.anchorContainer}
      >
        <Typography className={classes.anchorText}>Tagged with</Typography>
        <ChevronDownIcon className={classes.anchorIcon} />
      </Box>

      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Box className={classes.content}>
          <AutocompleteSearchProductTags
            onChange={onChange}
            clearAll={clearAll}
            tagValues={tags}
          />
          <Box pt={2}>
            <Button
              size="medium"
              color="default"
              onClick={() => {
                onChange([])
                setClearAll(true)
              }}
            >
              Clear All
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  )
}

SearchByTag.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.object)
}

export default withStyles(styles)(SearchByTag)
