import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import Menu from '@material-ui/core/Menu'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import {
  MdFiberNew,
  MdOutlineCardGiftcard,
  MdAttachMoney
} from 'react-icons/md'
import { GiSpermWhale } from 'react-icons/gi'

import { useLocationsContext } from 'context'
import { useMenu, useNavigation, useAdminOrders } from 'hooks'

import { URL } from 'constants/navigation'
import { SIZE, DELIVERY_METHOD_KEYS } from 'constants/enums'
import { LIGHT_BLUE } from 'constants/styles'
import {
  DELIVERY_METHOD_OPTIONS,
  ORDER_WRITABLE_TRANSITIONS
} from 'constants/general'
import { WHALES } from 'constants/important_users.js'
import {
  formatAddress,
  formatCurrency,
  formatDateToLocalTimezone
} from 'utils/general'

import OrderStatus from 'components/OrderStatus'
import PaymentStatus from 'components/PaymentStatus'
import ReturnStatus from 'components/ReturnStatus'
import UserAvatar from 'components/UserAvatar'

import { colorsAF } from 'theme/colors'

import FlagIcon from 'icons/FlagIcon'
import Diamond from 'icons/DiamondIcon'

const DeliveredOrderItem = ({ order, onClick }) => {
  const { updateOrder } = useAdminOrders({
    id: order?.id,
    fetchingEnabled: false
  })

  const onUpdateStatus = async status => {
    await updateOrder({ status }, order)
  }

  const onUpdateDeliveryMethod = async delivery_method => {
    await updateOrder({ delivery_method }, order)
  }

  const { getLocationAtId, showAllLocationIds } = useLocationsContext()

  const { open, anchorEl, handleToggleMenu, handleClose } = useMenu()

  const deliveryDate =
    order.delivery_starts_at &&
    formatDateToLocalTimezone(order.delivery_starts_at, order.timezone, 'L')

  const deliveryStartsAtText =
    order.delivery_starts_at &&
    formatDateToLocalTimezone(order.delivery_starts_at, order.timezone, 'h')

  const deliveryEndsAtText =
    order.delivery_ends_at &&
    formatDateToLocalTimezone(order.delivery_ends_at, order.timezone, 'ha')

  const addressMap = formatAddress(order)

  const { handleClick: handleUserClick } = useNavigation({
    url: URL.ADMIN_USERS
  })

  return (
    <TableRow data-test="order-row" key={order?.id}>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Button
            color="primary"
            onClick={e => onClick(e, order)}
            onAuxClick={e => onClick(e, order, true)}
            data-test="order-item"
            style={{ fontWeight: 400 }}
          >
            {`#${order?.number}`}
          </Button>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {addressMap[0]} {addressMap.length === 3 && addressMap[1]}
        </Typography>
        <Typography variant="body1">
          {addressMap[addressMap.length === 3 ? 2 : 1]}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{deliveryDate}</Typography>
        <Typography variant="body1">
          {deliveryStartsAtText} - {deliveryEndsAtText}
        </Typography>
      </TableCell>
      <TableCell>{order.onfleet_driver_name}</TableCell>
      <TableCell>
        <img height="300px" src={order.delivery_photo_url} />
      </TableCell>
    </TableRow>
  )
}

DeliveredOrderItem.propTypes = {
  order: PropTypes.object.isRequired,
  onClick: PropTypes.func
}

export default DeliveredOrderItem
