import React from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { TextFieldControlled } from 'components/TextField'
import { variantFieldName } from 'containers/admin/PurchaseOrderDetails'

import { SIZE } from 'constants/enums'

const TransferOrderShipmentItem = ({ shipmentItem, editable }) => {
  if (isEmpty(shipmentItem)) return

  const { variant } = shipmentItem

  const Ordered = () => {
    if (editable) {
      return (
        <TextFieldControlled
          name={variantFieldName(shipmentItem.variant.id)}
          number
          size={SIZE.small}
          skeletonUntilDefault
          dataTest="variant-ordered"
        />
      )
    }
    return <>{shipmentItem.quantity}</>
  }

  return (
    <TableRow>
      <TableCell>{variant.vendor_style_number ?? 'N/A'}</TableCell>
      <TableCell>{variant.title}</TableCell>
      <TableCell data-test="variant-sku">{variant.sku}</TableCell>
      <TableCell>{variant?.color?.label}</TableCell>
      <TableCell>{variant.size ?? 'N/A'}</TableCell>
      <TableCell>{variant.price}</TableCell>
      <TableCell>
        <Ordered />
      </TableCell>
      <TableCell>{shipmentItem.received ?? 0}</TableCell>
      <TableCell>{shipmentItem.damaged ?? 0}</TableCell>
    </TableRow>
  )
}

TransferOrderShipmentItem.propTypes = {
  shipmentItem: PropTypes.object,
  handleSelect: PropTypes.func,
  selected: PropTypes.bool,
  locationId: PropTypes.number,
  onProductSummaryClick: PropTypes.func,
  editable: PropTypes.bool.isRequired,
  deleteShipmentItem: PropTypes.func.isRequired
}

export default TransferOrderShipmentItem
