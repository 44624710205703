import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import {
  dateRangeToQueryParams,
  sortPossibilitiesFromColumns
} from 'utils/query'
import { URL } from 'constants/navigation'
import { API_PARAMS, LIST_PATHS, SORT } from 'constants/queryParams'

import {
  useNavigation,
  useAdminDeliveredOrderList,
  useQuery,
  useAppVersion,
  useMediaQuery,
  useRestoreScroll
} from 'hooks'
import { useLocationsContext } from 'context'

import DataTable from 'components/DataTable'
import DateRangePicker from 'components/DateRangePickerNew'
import Header from 'components/Header'
import Layout from 'components/Layout'
import DeliveredOrderItem from './DeliveredOrderItem'
import Select from 'components/Select'

import styles from './DeliveredOrderListStyles'

const LOCATION_TITLE = 'Location'

const columns = [
  {
    title: 'Order #',
    sortKeys: [LIST_PATHS.ORDERS_NUMBER]
  },
  { title: 'Address' },
  {
    title: 'Date',
    sortKeys: [LIST_PATHS.ORDERS_DELIVERY_STARTS]
  },
  { title: 'Driver' },
  { title: 'Photo' }
]

const columnsWithoutLocation = columns.filter(c => c.title !== LOCATION_TITLE)
const sortPossibilities = sortPossibilitiesFromColumns(columns)

const defaultSortDirection = {
  [LIST_PATHS.ORDERS_NUMBER]: SORT.DESC
}

const DeliveredOrderList = ({ classes }) => {
  const { isDesktopScreen: isMobile } = useMediaQuery()

  // APP WIDE UPDATE FETCHER
  const { updatePending } = useAppVersion()
  if (updatePending) {
    window.location.reload(true) // force refetch from server
  }

  const { showAllLocationIds, location } = useLocationsContext()

  const { query, setQuery, updateQuery } = useQuery(() => {}, {
    initFallback: {
      [API_PARAMS.SORT]: LIST_PATHS.ORDERS_NUMBER,
      [API_PARAMS.SORT_DIRECTION]:
        defaultSortDirection[LIST_PATHS.ORDERS_NUMBER]
    },
    locationFilter: LIST_PATHS.ORDERS_LOCATION_ID,
    clearParamsOnLocationChange: [
      `${LIST_PATHS.ORDERS_DELIVERY_STARTS}.rel`,
      LIST_PATHS.ORDERS_DELIVERY_STARTS
    ]
  })

  const {
    isLoadingOrders,
    orders,
    hasOrdersNext,
    fetchOrdersNext
  } = useAdminDeliveredOrderList({
    params: query
  })

  const listRef = useRef(null)
  useRestoreScroll(
    listRef,
    'delivered-order-list',
    Array.isArray(orders) && orders.length > 0
  )

  const handleSortSelect = ({ target: { value } }) => {
    const additionalParams =
      value in defaultSortDirection
        ? { [API_PARAMS.SORT_DIRECTION]: defaultSortDirection[value] }
        : { [API_PARAMS.SORT_DIRECTION]: '' }
    updateQuery({ [API_PARAMS.SORT]: value, ...additionalParams })
  }

  const { handleClick } = useNavigation({ url: URL.ADMIN_ORDERS })

  const dateRangeValue = () =>
    query[LIST_PATHS.ORDERS_DELIVERY_STARTS]
      ? query[LIST_PATHS.ORDERS_DELIVERY_STARTS]
          .split(',')
          .map(s =>
            !!location?.timezone ? moment(s).tz(location.timezone) : moment(s)
          )
      : [null, null]

  const clearDateRange = listPath => {
    delete query[listPath]
    delete query[`${listPath}.rel`]
  }

  const handleDateRangeChange = ([start, end]) => {
    if (start === null && end === null) {
      clearDateRange(LIST_PATHS.ORDERS_DELIVERY_STARTS)
      setQuery({ ...query })
      return
    }

    if (start && end) {
      const params = dateRangeToQueryParams(
        LIST_PATHS.ORDERS_DELIVERY_STARTS,
        [start, end],
        location
      )
      setQuery({ ...query, ...params })
    }
  }

  const foldableActions = (
    <Box
      display="flex"
      alignItems={isMobile ? 'flex-start' : 'center'}
      justifyContent="flex-start"
      flexDirection={isMobile ? 'column' : 'row'}
    ></Box>
  )

  const filters = (
    <>
      <DateRangePicker
        name={LIST_PATHS.ORDERS_DELIVERY_STARTS}
        onChange={handleDateRangeChange}
        value={dateRangeValue()}
        onClearDates={() => handleDateRangeChange([null, null])}
        noDatesOutsideRange
      />
      <Select
        minimal
        name={API_PARAMS.SORT}
        innerLabel="Sort By"
        value={query[API_PARAMS.SORT] || ''}
        items={sortPossibilities}
        onChange={handleSortSelect}
        withNone
        dataTest="order-sort"
      />
    </>
  )

  return (
    <Layout fullWidth>
      <Header
        title="Delivered Orders"
        foldableActions={foldableActions}
        filters={filters}
        summarizers={[]}
        iLoveTightBottoms={!!location?.timezone}
      />
      <DataTable
        columns={showAllLocationIds ? columns : columnsWithoutLocation}
        isLoadingList={isLoadingOrders}
        hasListNext={hasOrdersNext}
        listNext={fetchOrdersNext}
        query={query}
        updateQuery={updateQuery}
        ref={listRef}
      >
        {orders?.length > 0 &&
          orders.map(order => (
            <DeliveredOrderItem
              key={order.id}
              order={order}
              showAllLocationIds={showAllLocationIds}
              onClick={handleClick}
            />
          ))}
      </DataTable>
    </Layout>
  )
}

DeliveredOrderList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DeliveredOrderList)
