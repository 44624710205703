import React, { useState, createContext, useMemo } from 'react'
import PropTypes from 'prop-types'

export const AppConfigContext = createContext()

export const AppConfigProvider = ({ children }) => {
  const [appConfig, setAppConfig] = useState({})

  const value = useMemo(
    () => ({
      setAppConfig,
      appConfig,
      referralTiers: appConfig.referral_tiers
    }),
    [appConfig]
  )

  return (
    <AppConfigContext.Provider value={value}>
      {children}
    </AppConfigContext.Provider>
  )
}

AppConfigProvider.propTypes = {
  children: PropTypes.node
}
