import React, { useState } from 'react'

import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

import { COLLECTION_CAN_BE_EDITORIAL } from 'constants/collections'
import { FORM_FIELDS } from 'constants/collectionGroups'
import Dialog from 'components/Dialog'
import AutocompleteSearchCollections from 'components/AutocompleteSearchCollections'

const AddCollectionGroupCollectionModal = ({
  open,
  onClose,
  addCollections
}) => {
  const [newCollections, setNewCollections] = useState([])

  const addAndClose = () => {
    addCollections(newCollections)

    setNewCollections([])
    onClose()
  }

  return (
    <Dialog
      title="Add Collections to Group"
      open={open}
      onCancel={onClose}
      onConfirm={addAndClose}
      dataTest="add-collection-group-collection"
      confirmText="Add"
      confirmDisabled={newCollections.length === 0}
    >
      <Box>
        <AutocompleteSearchCollections
          name={FORM_FIELDS.COLLECTIONS}
          value={newCollections}
          onChange={setNewCollections}
          queryProps={COLLECTION_CAN_BE_EDITORIAL}
          multiple
        />
      </Box>
    </Dialog>
  )
}

AddCollectionGroupCollectionModal.defaultProps = {
  open: false
}

AddCollectionGroupCollectionModal.propTypes = {
  open: PropTypes.bool,
  addCollections: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default AddCollectionGroupCollectionModal
