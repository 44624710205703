import React from 'react'
import PropTypes from 'prop-types'

const HoursIcon = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="9.5" r="8.25" stroke="#8D8D8D" strokeWidth="2" />
    <path
      d="M10 5.75V10.25L11.875 12.125"
      stroke="#8D8D8D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

HoursIcon.propTypes = {
  className: PropTypes.string
}

export default HoursIcon
