import React from 'react'
import PropTypes from 'prop-types'

const CustomerIcon = ({ fill, fontSize, className }) => (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8373 57.7246C44.5954 57.7246 57.3699 44.9502 57.3699 29.192C57.3699 13.4339 44.5954 0.659424 28.8373 0.659424C13.0792 0.659424 0.304688 13.4339 0.304688 29.192C0.304688 44.9502 13.0792 57.7246 28.8373 57.7246Z"
      fill="#305BF2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.7109 42.9782C36.3949 42.9782 42.6239 36.7491 42.6239 29.0651C42.6239 21.3812 36.3949 15.1521 28.7109 15.1521C21.0269 15.1521 14.7979 21.3812 14.7979 29.0651C14.7979 36.7491 21.0269 42.9782 28.7109 42.9782Z"
      fill="#305BF2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.711 32.6618H28.8668L30.8218 25.116L37.4422 25.1161L36.8445 27.3216H33.1034L32.8279 28.3807H35.9199L35.3644 30.4943H32.282L31.711 32.6618ZM25.6676 25.1161V25.116H28.023L28.5613 32.6618L25.7861 32.6617L25.7725 31.793H22.8549L22.2922 32.6617H19.3262L24.4431 25.1161H25.6676ZM25.7038 27.3967L24.1153 29.8482H25.7422L25.7038 27.3967Z"
      fill="white"
    />
  </svg>
)

CustomerIcon.defaultProps = {
  fontSize: 10,
  fill: 'currentColor'
}

CustomerIcon.propTypes = {
  fill: PropTypes.string,
  fontSize: PropTypes.number,
  className: PropTypes.string
}

export default CustomerIcon
