import React, { useState } from 'react'
import PropTypes from 'prop-types'
import uniq from 'lodash/uniq'

import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import pluralize from 'pluralize'

import { useLocationsContext } from 'context'

import { useConditionalEffect } from 'hooks'

import { MARGINS } from 'constants/enums'
import { emptyStringToNull } from 'utils/general'
import { sortBinList } from 'utils/bin'

import Dialog from 'components/Dialog'
import Select from 'components/Select'
import TextField from 'components/TextField'
import ProductSummary from 'components/ProductSummary'
import Tag from 'components/Tag'

import styles from './BulkUpdateVariantModalStyles'

const dimensionUnits = [
  { label: <span>&nbsp;</span>, value: '' },
  { label: 'in', value: 'in' },
  { label: 'cm', value: 'cm' },
  { label: 'ft', value: 'ft' }
]

const BulkUpdateVariantModal = ({
  classes,
  open,
  variants = [],
  disableDimensions = false,
  onUpdate,
  onClose
}) => {
  const { locationId } = useLocationsContext()
  const currentBins = locationId
    ? variants
        .map(
          v =>
            v?.inventory
              ?.find(i => i.location.id === locationId)
              ?.bin?.split(',')
              ?.map(b => b.trim()) || []
        )
        .flat()
    : []
  const [newBins, setNewBins] = useState(currentBins)

  const allLicensePlates = variants.map(v => v.plates).flat()
  const nonEmptyLocation = {}
  allLicensePlates.forEach(lp => {
    if (lp?.quantity > 0) {
      nonEmptyLocation[lp.item_location.label] = true
    }
  })

  useConditionalEffect(() => {
    if (currentBins.length > 0) {
      setNewBins(currentBins)
    }
  }, [variants])

  const { control, register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      unit: '' // this gets rid of warning about switching from an uncontrolled component to a controlled one
    },
    resolver: yupResolver(
      yup.object().shape(
        {
          bin: yup
            .string()
            .nullable()
            .transform(emptyStringToNull),
          unit: yup
            .string()
            .nullable()
            .transform(emptyStringToNull)
            .when(['length', 'width', 'height'], {
              is: (length, width, height) => length || width || height,
              then: yup
                .string()
                .required()
                .when(['length', 'width', 'height'], {
                  is: (length, width, height) => {
                    return length || width || height
                  },
                  then: yup.string().required()
                })
            }),
          length: yup
            .number()
            .typeError('length must be a number')
            .positive('length must be positive')
            .nullable()
            .transform(emptyStringToNull)
            .when(['unit', 'width', 'height'], {
              is: (unit, width, height) => unit || width || height,
              then: yup.number().required()
            }),
          width: yup
            .number()
            .typeError('width must be a number')
            .positive('width must be positive')
            .nullable()
            .transform(emptyStringToNull)
            .when(['unit', 'length', 'height'], {
              is: (unit, length, height) => unit || length || height,
              then: yup.number().required()
            }),
          height: yup
            .number()
            .typeError('height must be a number')
            .positive('height must be positive')
            .nullable()
            .transform(emptyStringToNull)
            .when(['unit', 'length', 'width'], {
              is: (unit, length, width) => unit || length || width,
              then: yup.number().required()
            }),
          weight: yup
            .number()
            .typeError('weight must be a number')
            .positive('weight must be positive')
            .nullable()
            .transform(emptyStringToNull)
        },
        [
          ['length', 'width'],
          ['length', 'height'],
          ['length', 'unit'],
          ['width', 'height'],
          ['width', 'unit'],
          ['height', 'unit']
        ]
      )
    )
  })

  const handleClose = () => {
    onClose && onClose()
  }

  const onSubmit = args => {
    if (args.unit || args.weight || args.bin) {
      onUpdate(
        {
          ...args,
          locationId,
          setBin: true,
          variants
        },
        allLicensePlates
          .filter(lp => newBins.indexOf(lp?.item_location?.label) === -1)
          .map(lp => lp.id)
      )
    }

    handleClose()
  }

  const onDeleteTag = bin => {
    setNewBins(newBins => newBins.filter(nb => nb !== bin))
  }

  return (
    <Dialog
      title={`Update ${pluralize('Variant', variants.length)}`}
      open={open}
      onConfirm={handleSubmit(onSubmit)}
      onCancel={onClose}
      dataTest="bulkUpdateVariantModal"
    >
      <Box mb={1}>
        <Typography variant="body2">
          Bin (Non-empty locations will be disabled)
        </Typography>
      </Box>
      {uniq(newBins).map(bin => (
        <Tag
          key={bin}
          label={bin}
          isNew={false}
          onDelete={() => onDeleteTag(bin)}
          disabled={nonEmptyLocation[bin]}
        />
      ))}
      <input
        type="hidden"
        value={uniq(newBins.sort(sortBinList), true).join(',')}
        name="bin"
        ref={register}
      />

      {!disableDimensions && (
        <>
          <Box mt={2}>
            <Typography variant="body2">Dimensions</Typography>
          </Box>

          <Controller
            name="unit"
            control={control}
            render={({ name, onChange, value }) => (
              <Select
                name={name}
                margin={MARGINS.dense}
                innerLabel="Unit of measure"
                value={value}
                items={dimensionUnits}
                onChange={e => onChange(e.target.value)}
                error={errors.unit?.message}
                fullWidth
              />
            )}
          />

          <Box className={classes.flexRow}>
            <TextField
              name="length"
              placeholder="Length"
              inputRef={register}
              error={Boolean(errors.length?.message)}
              helperText={errors.length?.message}
            />
            <TextField
              name="width"
              placeholder="Width"
              inputRef={register}
              error={Boolean(errors.width?.message)}
              helperText={errors.width?.message}
            />
            <TextField
              name="height"
              placeholder="Height"
              inputRef={register}
              error={Boolean(errors.height?.message)}
              helperText={errors.height?.message}
            />
          </Box>

          <Box mt={2}>
            <Typography variant="body2">Weight</Typography>
          </Box>
          <TextField
            name="weight"
            placeholder="Weight in pounds"
            fullWidth
            inputRef={register}
            error={Boolean(errors.weight?.message)}
            helperText={errors.weight?.message}
          />
        </>
      )}

      <Box mt={2} mb={1}>
        <Typography variant="body2">Selected Variants</Typography>
      </Box>
      <Box width="100%" flexGrow={1} flexShrink={1} overflow="auto" mx={-1}>
        {variants.map((variant, idx) => (
          <ProductSummary
            key={idx}
            product={variant}
            showQuantity
            className={classes.product}
          />
        ))}
      </Box>
    </Dialog>
  )
}

BulkUpdateVariantModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  open: PropTypes.bool,
  disableDimensions: PropTypes.bool,
  variants: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func
}

export default withStyles(styles)(BulkUpdateVariantModal)
