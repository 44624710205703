import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Select from 'components/Select'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import styles from './AdditionalDetailsStyles'

const TEXT_MAX_LENGTH = 480

const AdditionalDetails = ({
  classes,
  selectOptions,
  onTextChange,
  onSelectChange,
  textValue,
  selectValue,
  reasonTitle
}) => {
  const options = [
    { label: 'Select a reason', value: '', disabled: true }
  ].concat(
    Object.keys(selectOptions).map(key => ({
      label: selectOptions[key],
      value: key
    }))
  )

  return (
    <>
      <Box className={classes.paddingBlock}>
        <Typography data-test="Reason" variant="h5">
          {reasonTitle || 'Reason for refund'}
        </Typography>
        <Typography className={classes.subtitle}>
          Only staff will see this information.
        </Typography>
        <Select
          className={classes.selectReason}
          name="select-a-reason"
          value={selectValue || ''}
          items={options}
          displayEmpty
          onChange={onSelectChange}
        />
      </Box>
      <Typography
        data-test="Details"
        variant="caption"
        className={classes.paddingBlock}
      >
        Additional details
      </Typography>
      <Box className={classes.divider} />
      <TextField
        className={classes.textField}
        placeholder="Optionally, add additional detail here if needed."
        variant="outlined"
        inputProps={{
          maxLength: TEXT_MAX_LENGTH
        }}
        multiline
        rows={7}
        helperText={`${textValue?.length || 0} / ${TEXT_MAX_LENGTH}`}
        onChange={onTextChange}
      />
    </>
  )
}

AdditionalDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  selectOptions: PropTypes.object.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  textValue: PropTypes.string,
  selectValue: PropTypes.string,
  reasonTitle: PropTypes.string
}

export default withStyles(styles)(AdditionalDetails)
