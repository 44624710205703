import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { ChromePicker } from 'react-color'
import { withStyles } from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import Cropper from 'react-easy-crop'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Slider from '@material-ui/core/Slider'

import * as propTypes from 'constants/propTypes'
import getCroppedImg from 'utils/cropImage'

import Dialog from 'components/Dialog'
import { TextFieldControlled } from 'components/TextField'
import ImageDropzone from 'components/ImageDropzone'
import Button from 'components/Button'
import IconButton from 'components/IconButton'
import { AutocompleteSearchMasterColorsControlled } from 'components/AutocompleteSearchMasterColors'
import RemoveIcon from 'icons/RemoveIcon'

import styles from './ColorModalStyles'

const COLORWAYS = {
  id: 'id',
  label: 'label',
  hex: 'hex',
  masterColorId: 'master_color_id',
  masterColor: 'master_color',
  shopify_color: 'shopify_color',
  image: 'image',
  cropX: 'crop_x',
  cropY: 'crop_y',
  cropWidth: 'crop_width',
  cropHeight: 'crop_height'
}

const colorwaySchema = yup.object().shape({
  [COLORWAYS.label]: yup.string().required('Display Name is a required field'),
  [COLORWAYS.shopify_color]: yup.string().nullable(true),
  [COLORWAYS.hex]: yup.string().nullable(true),
  [COLORWAYS.masterColor]: yup
    .object()
    .typeError('Master color is a required field')
    .required(),
  [COLORWAYS.image]: yup.mixed().nullable(true)
})

const ColorModal = ({
  classes,
  open,
  masterColors,
  selectedColor,
  onClose,
  onCreate,
  onUpdate,
  onDelete
}) => {
  const defaultValues = {
    [COLORWAYS.masterColor]: selectedColor?.master_color ?? '',
    [COLORWAYS.hex]: selectedColor?.hex ?? '#FFFFFF',
    [COLORWAYS.label]: selectedColor?.label ?? '',
    [COLORWAYS.shopify_color]: selectedColor?.shopify_color ?? ''
  }

  const formContext = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(colorwaySchema),
    defaultValues
  })

  const {
    reset,
    register,
    unregister,
    handleSubmit,
    watch,
    setValue,
    control
  } = formContext

  useEffect(() => {
    register(COLORWAYS.hex)
    return () => unregister()
  }, [register])

  useEffect(() => {
    reset(defaultValues)
  }, [reset, masterColors, selectedColor])

  // start crop utilities
  const DEFAULT_CROP_VALUE = { x: 0, y: 0 }
  const DEFAULT_ZOOM_VALUE = 1

  const [crop, setCrop] = useState(DEFAULT_CROP_VALUE)
  const [zoom, setZoom] = useState(DEFAULT_ZOOM_VALUE)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState()
  const [croppedImage, setCroppedImage] = useState()
  const image = watch(COLORWAYS.image)

  const onCropComplete = useCallback((croppedArea, newCroppedAreaPixels) => {
    setCroppedAreaPixels(newCroppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const newCroppedImage = await getCroppedImg(
        URL.createObjectURL(image),
        croppedAreaPixels
      )
      setCroppedImage(newCroppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])

  const selectedMasterColor = watch(COLORWAYS.masterColor)

  const multiColorSelected =
    masterColors?.filter(
      c => c.label === 'Multi-Color' && selectedMasterColor.id === c.id
    ).length > 0

  // end crop utilities

  const handleClose = () => {
    onClose && onClose()
    setCrop(DEFAULT_CROP_VALUE)
    setZoom(DEFAULT_ZOOM_VALUE)
    setCroppedImage()
  }

  const onSubmit = async colorway => {
    const parsedColorway = {
      ...colorway,
      [COLORWAYS.masterColorId]: colorway.master_color.id
    }
    delete parsedColorway.master_color
    if (!isEmpty(selectedColor)) {
      onUpdate(parsedColorway)
    } else {
      onCreate(parsedColorway)
    }

    handleClose()
  }

  return (
    <Dialog
      classes={{
        subtitle: classes.subtitle,
        header: classes.header,
        content: classes.content
      }}
      title={!isEmpty(selectedColor) ? 'Edit Color' : 'Create Colorway'}
      subtitle="Give the color a display name (what customers see) and an intenal name
          name if needed, Then assign a master color, and set the color value."
      open={open}
      onCancel={handleClose}
      onConfirm={handleSubmit(onSubmit)}
      confirmText={!isEmpty(selectedColor) ? 'Update' : 'Create'}
      confirmDisabled={image && multiColorSelected && !croppedImage}
    >
      <Box>
        <Box mt={3} />
        <FormProvider {...formContext}>
          <input
            type="hidden"
            name={COLORWAYS.id}
            value={selectedColor?.id}
            ref={register}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextFieldControlled
                name={COLORWAYS.label}
                placeholder="Display name"
                fullWidth
                className={classes.textField}
                centeredInput
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldControlled
                name={COLORWAYS.shopify_color}
                placeholder="Internal name"
                fullWidth
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutocompleteSearchMasterColorsControlled
                name={COLORWAYS.masterColor}
                multiple={false}
              />
            </Grid>
          </Grid>

          <Box mt={4.5}>
            {!multiColorSelected ? (
              <Controller
                name={COLORWAYS.hex}
                control={control}
                render={({ name, onChange, value }) => (
                  <ChromePicker
                    width="100%"
                    name={name}
                    color={value}
                    disableAlpha
                    onChange={color => onChange(color?.hex)}
                    onChangeComplete={color => onChange(color?.hex)}
                  />
                )}
              />
            ) : (
              <Box
                height={304}
                mt={2}
                display={(image || croppedImage) && 'none'}
              >
                <ImageDropzone
                  name={COLORWAYS.image}
                  defaultUrl={selectedColor?.multi_color_url}
                  hideClearButton
                  round
                />
              </Box>
            )}
            {image && !croppedImage && multiColorSelected && (
              <>
                <Box className={classes.cropperContainer}>
                  <Cropper
                    image={URL.createObjectURL(image)}
                    crop={crop}
                    cropShape="round"
                    zoom={zoom}
                    showGrid={false}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Box width="80%">
                    <Slider
                      value={zoom}
                      min={0.5}
                      max={5}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e, newZoom) => setZoom(newZoom)}
                      classes={{ root: 'slider' }}
                    />
                  </Box>
                  <Button onClick={() => showCroppedImage()} size="small">
                    Show Result
                  </Button>
                </Box>
              </>
            )}
            {croppedImage && (
              <>
                {multiColorSelected && (
                  <Box mt={2} className={classes.croppedImagePreview}>
                    <img
                      src={croppedImage}
                      alt="Cropped"
                      className={classes.round}
                    />
                    <IconButton
                      classes={{ root: classes.iconButton }}
                      className={classes.deletePreview}
                      onClick={() => {
                        setCroppedImage()
                        setValue(COLORWAYS.image, null)
                      }}
                      color="secondary"
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                )}

                <input
                  type="hidden"
                  name={COLORWAYS.cropX}
                  value={croppedAreaPixels.x}
                  ref={register}
                />
                <input
                  type="hidden"
                  name={COLORWAYS.cropY}
                  value={croppedAreaPixels.y}
                  ref={register}
                />
                <input
                  type="hidden"
                  name={COLORWAYS.cropWidth}
                  value={croppedAreaPixels.width}
                  ref={register}
                />
                <input
                  type="hidden"
                  name={COLORWAYS.cropHeight}
                  value={croppedAreaPixels.height}
                  ref={register}
                />
              </>
            )}
          </Box>
        </FormProvider>
      </Box>
    </Dialog>
  )
}

ColorModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  masterColors: PropTypes.arrayOf(propTypes.color),
  selectedColor: propTypes.color,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default withStyles(styles)(ColorModal)
