import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import { useAdminPurchaseOrder } from 'hooks'
import { URL } from 'constants/navigation'

import Header from 'components/Header'
import Layout from 'components/Layout'
import ProductHistoryTable from 'components/ProductHistoryTable'

import styles from './TransferOrderHistoryStyles'

const PurchaseOrderHistory = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const {
    purchaseOrder,
    history,
    hasHistory,
    hasHistoryNext,
    fetchHistoryNext,
    isFetchingHistory
  } = useAdminPurchaseOrder(id)

  return (
    <Layout>
      {purchaseOrder?.id && (
        <Box>
          <Box className={classes.headerBox}>
            <Header
              breadcrumbs={[
                { title: 'Purchase Order', link: URL.ADMIN_PURCHASE_ORDERS },
                {
                  title: id,
                  link: `${URL.ADMIN_PURCHASE_ORDERS}/${purchaseOrder?.id}`
                }
              ]}
              title="History"
              actions={<></>}
            />
          </Box>
        </Box>
      )}

      <ProductHistoryTable
        hasHistory={hasHistory}
        hasHistoryNext={hasHistoryNext}
        listHistoryNext={fetchHistoryNext}
        isLoadingHistory={isFetchingHistory}
        history={history}
      />
    </Layout>
  )
}

PurchaseOrderHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(PurchaseOrderHistory)
