const jssStyles = theme => ({
  root: {
    margin: 0,
    marginRight: theme.spacing(1)
  },
  small: {
    padding: 4
  },
  medium: {
    padding: 3
  },
  large: {
    padding: 0
  },
  disabled: {
    opacity: 0.65
  },
  boundingBox: {
    padding: '22px 26px',
    marginRight: 0,
    marginBottom: 8,
    height: 60,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grays.med,
    borderRadius: 5,
    '& .MuiFormControlLabel-label': {
      paddingLeft: 16,
      ...theme.typography.body1,
      fontWeight: 400
    }
  }
})

export default jssStyles
