import React, { useState, useMemo } from 'react'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import InputAdornment from '@material-ui/core/InputAdornment'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'

import { MARGINS, ICON_BUTTON_VARIANT } from 'constants/enums'
import IconButton from 'components/IconButton'
import TextField from 'components/TextField'
import Autocomplete from 'components/Autocomplete'
import CloseIcon from 'icons/CloseIcon'
import SearchIcon from 'icons/SearchIcon'
import ChevronDownIcon from 'icons/ChevronDownIcon'

import styles from './AutocompleteSearchStyles'

// It would be nice to have these for multiple=false
// https://github.com/mui-org/material-ui/issues/26440
const Tag = ({ label, onDelete }) => (
  <Chip
    label={label}
    icon={
      <IconButton
        onClick={onDelete}
        circle
        size={16}
        variant={ICON_BUTTON_VARIANT.medium}
      >
        <CloseIcon />
      </IconButton>
    }
  />
)

Tag.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.func
}

const AutocompleteSearchRaw = ({
  name,
  classes,
  value,
  onChange,
  onScrollBottom,
  options,
  getOptionLabel,
  filterOptions,
  className,
  placeholder,
  setQuery,
  loading,
  multiple,
  renderOption,
  defaultOpen,
  withStartAdornment,
  withEndAdornment,
  helperText,
  error,
  clearable,
  ...props
}) => {
  const [open, setOpen] = useState(defaultOpen)

  const handleOptionSelection = (e, option) => {
    if (Array.isArray(option)) {
      // ensures no selection of empty strings
      onChange && onChange(option.filter(opt => typeof opt !== 'string'))
    } else {
      onChange && onChange(option)
    }
    if (!multiple) {
      setOpen(false)
    }
  }

  const handleInputChange = (e, val, reason) => {
    setQuery(val ?? '')
  }

  const renderTags = (localValue, getTagProps) =>
    localValue.map((opt, index) => (
      <Tag label={getOptionLabel(opt)} {...getTagProps({ index })} />
    ))

  const renderInput = ({
    InputProps: { startAdornment, endAdornment, ...InputProps },
    ...params
  }) => {
    const localStartAdornment = (
      <>
        {withStartAdornment && (
          <Box mx={1}>
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          </Box>
        )}
        {startAdornment}
      </>
    )
    const localEndAdornment = withEndAdornment && (
      <InputAdornment position="end" className={classes.endAdornment}>
        {clearable && !isEmpty(value) ? (
          <IconButton
            onClick={() => onChange(multiple ? [] : {})}
            size={20}
            variant={ICON_BUTTON_VARIANT.transparent}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => setOpen(!open)}
            size={20}
            variant={ICON_BUTTON_VARIANT.transparent}
          >
            <ChevronDownIcon className={open ? classes.flipped : null} />
          </IconButton>
        )}
      </InputAdornment>
    )
    return (
      <Box display="flex" alignItems="flex-start">
        <TextField
          {...params}
          fullWidth
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          className={classNames({
            [classes.inputBox]: true,
            [classes.inputBoxError]: Boolean(error)
          })}
          margin={MARGINS.dense}
          InputProps={{
            startAdornment: localStartAdornment ?? '',
            endAdornment: localEndAdornment ?? '',
            ...InputProps,
            'data-test': `${name}-input`
          }}
        />
      </Box>
    )
  }

  // Autocomplete uses === but because we can refetch an object from the server
  // all the fields maybe the same and it would fail, so fix that.
  const sameRefOptions = useMemo(() => {
    if (multiple) {
      return options?.map(opt => value.find(v => isEqual(v, opt)) ?? opt)
    }
    return options?.map(opt => (isEqual(value, opt) ? value : opt))
  }, [value, options])

  return (
    <Autocomplete
      value={value}
      options={sameRefOptions}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(opt, val) => opt?.id === val?.id}
      open={open}
      groupBy={() => 'Results'}
      filterOptions={filterOptions}
      className={className}
      loading={loading}
      multiple={multiple}
      onChange={handleOptionSelection}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      onScrollBottom={onScrollBottom}
      disableCloseOnSelect
      freeSolo
      renderOption={renderOption}
      renderTags={renderTags}
      renderInput={renderInput}
      helperText={helperText}
      {...props}
    />
  )
}

AutocompleteSearchRaw.defaultProps = {
  defaultOpen: false,
  filterOptions: opts => opts,
  withStartAdornment: true,
  withEndAdornment: true,
  clearable: true
}

AutocompleteSearchRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ]),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  setQuery: PropTypes.func,
  onScrollBottom: PropTypes.func,
  renderOption: PropTypes.func,
  getOptionLabel: PropTypes.func,
  filterOptions: PropTypes.func,
  defaultOpen: PropTypes.bool,
  withStartAdornment: PropTypes.bool,
  withEndAdornment: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  clearable: PropTypes.bool
}

const AutocompleteSearch = withStyles(styles)(AutocompleteSearchRaw)

const AutocompleteSearchControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearch
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => {
            option && methods.onChange(option)
          }}
        />
      )}
    />
  )
}

AutocompleteSearchControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export { AutocompleteSearch as default, AutocompleteSearchControlled }
