const jssStyles = theme => ({
  root: {
    marginRight: 0,
    marginLeft: 0,
    marginTop: theme.spacing(1)
  },
  mediumAndBorder: {
    paddingLeft: 7,
    paddingTop: 9,
    paddingBottom: 9
  },
  border: {
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.grays.med
  },
  disabled: {
    opacity: 0.65
  }
})

export default jssStyles
