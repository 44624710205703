import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import styles from './TileLabelStyles'

const TileLabel = ({ classes, label, endAdornment }) => (
  <Box className={classes.root}>
    <Typography className={classes.label}>{label}</Typography>
    {endAdornment}
  </Box>
)

TileLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  endAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
}

export default withStyles(styles)(TileLabel)
