import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import SaveIcon from '@material-ui/icons/Save'

import {
  useAdminInternalUser,
  useBeforeUnload,
  useConditionalEffect,
  useNavigation,
  useMediaQuery
} from 'hooks'
import { useLocationsContext, useAuthContext } from 'context'
import { canEditInternalUser, internalUserRoleOptions } from 'utils/policies'

import {
  INTERNAL_USER_FIELDS,
  INTERNAL_USER_FORM_DEFAULTS,
  INTERNAL_USER_ROLES
} from 'constants/internalUsers'

import { URL } from 'constants/navigation'
import { SIZE, COLOR } from 'constants/enums'

import Layout from 'components/Layout'
import { TitleElement } from 'components/SiteTitle'
import ControlledForm from 'components/ControlledForm/ControlledForm'
import { useControlledForm } from 'components/ControlledForm'
import { TextFieldControlled } from 'components/TextField'
import { CheckboxField } from 'components/Checkbox'
import Header from 'components/Header'
import Button from 'components/Button'
import { SelectField } from 'components/Select'

import styles from './InternalUserFormStyles'

const SaveButton = ({ submitting, formTypeNew }) => {
  const {
    handleSubmit: localHandleSubmit,
    formContext: {
      formState: { dirtyFields }
    }
  } = useControlledForm()

  const { OnBeforeUnloadPrompt } = useBeforeUnload(!isEmpty(dirtyFields))

  return (
    <>
      <Button
        label="save internal user button"
        onClick={localHandleSubmit}
        size={SIZE.medium}
        color={COLOR.primary}
        startIcon={formTypeNew ? <SaveIcon /> : <CheckCircleOutlineIcon />}
        adaptive
        disabled={isEmpty(dirtyFields) || submitting}
        type="submit"
      >
        {formTypeNew ? 'Create' : 'Save'}
      </Button>
      <OnBeforeUnloadPrompt />
    </>
  )
}

const InternalUserForm = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const FORM_TYPE = id === 'new' ? 'new' : 'update'
  const INTERNAL_USER_ID = FORM_TYPE === 'new' ? null : id
  const formTypeNew = FORM_TYPE === 'new'

  const { currentUser } = useAuthContext()
  const { locationId, locationOptionsWithAll } = useLocationsContext()

  const {
    internalUser,
    updateInternalUser,
    createInternalUser,
    isLoadingInternalUser
  } = useAdminInternalUser({
    id: INTERNAL_USER_ID
  })

  const pageTitle =
    internalUser?.first_name && internalUser?.last_name
      ? `${internalUser.first_name} ${internalUser.last_name} (ID ${internalUser.id})`
      : 'New'

  const { go } = useNavigation({})
  const { isMobileScreen } = useMediaQuery()
  const [formDefaults, setFormDefaults] = useState(INTERNAL_USER_FORM_DEFAULTS)

  const constructFormDefaultsFromInternalUser = ({
    first_name,
    last_name,
    email,
    password,
    default_location_id,
    role,
    blocked
  }) => ({
    [INTERNAL_USER_FIELDS.first_name]: first_name,
    [INTERNAL_USER_FIELDS.last_name]: last_name,
    [INTERNAL_USER_FIELDS.email]: email,
    [INTERNAL_USER_FIELDS.password]: password,
    [INTERNAL_USER_FIELDS.default_location_id]: default_location_id,
    [INTERNAL_USER_FIELDS.role]: role,
    [INTERNAL_USER_FIELDS.blocked]: blocked
  })

  useConditionalEffect(
    () =>
      !isEmpty(internalUser) &&
      setFormDefaults(constructFormDefaultsFromInternalUser(internalUser)),
    [internalUser]
  )

  const canEdit =
    currentUser &&
    internalUser &&
    canEditInternalUser(currentUser, internalUser)
  const roleOptions =
    currentUser && internalUser
      ? internalUserRoleOptions(currentUser, internalUser)
      : []

  const handleSubmit = async ({
    first_name,
    last_name,
    email,
    password,
    default_location_id,
    role,
    blocked
  }) => {
    const execute = formTypeNew ? createInternalUser : updateInternalUser

    const { data: localInternalUser } = await execute({
      first_name,
      last_name,
      email,
      password,
      default_location_id,
      role,
      blocked
    })

    if (formTypeNew) {
      go({}, `${URL.ADMIN_INTERNAL_USERS}/${localInternalUser.id}`, false)
      return
    }

    if (localInternalUser.id) {
      constructFormDefaultsFromInternalUser(localInternalUser)
    }
  }

  const actions = (
    <>
      <SaveButton
        id={id}
        formTypeNew={formTypeNew}
        submitting={isLoadingInternalUser}
      />
    </>
  )

  const inlineFieldContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    flexDirection: isMobileScreen ? 'column' : 'row'
  }

  const inlineFieldStyles = {
    width: '100%',
    mr: isMobileScreen ? 0 : 2
  }

  return (
    <Layout id="InternalUserForm">
      <TitleElement title={pageTitle} />
      <ControlledForm
        handleSubmit={handleSubmit}
        defaultValues={formDefaults}
        resetOnSubmit={!id}
        shouldUnregister={false}
      >
        <Header
          sticky
          breadcrumbs={[
            { title: 'Internal Users', link: URL.ADMIN_INTERNAL_USERS }
          ]}
          title={pageTitle}
          actions={actions}
        />
        <Box>
          <Box {...inlineFieldContainerStyles}>
            <Box {...inlineFieldStyles}>
              <Typography variant="h3">First Name</Typography>
              <TextFieldControlled
                className={classes.thinInput}
                name={INTERNAL_USER_FIELDS.first_name}
                disabled={!canEdit}
                fullWidth
              />
            </Box>
            <Box {...inlineFieldStyles}>
              <Typography variant="h3">Last Name</Typography>
              <TextFieldControlled
                className={classes.thinInput}
                name={INTERNAL_USER_FIELDS.last_name}
                disabled={!canEdit}
                fullWidth
              />
            </Box>
          </Box>
          <Box {...inlineFieldContainerStyles}>
            <Box {...inlineFieldStyles}>
              <Typography variant="h3">Email</Typography>
              <TextFieldControlled
                className={classes.thinInput}
                name={INTERNAL_USER_FIELDS.email}
                disabled={!canEdit}
                fullWidth
              />
            </Box>
          </Box>
          <Box {...inlineFieldContainerStyles}>
            <Box {...inlineFieldStyles}>
              <Typography variant="h3">Role</Typography>
              <SelectField
                fullWidth
                defaultValueIndex={INTERNAL_USER_ROLES.standard}
                items={roleOptions}
                displayEmpty
                disabled={!canEdit}
                name={INTERNAL_USER_FIELDS.role}
              />
            </Box>
            {locationOptionsWithAll?.length > 0 && (
              <Box {...inlineFieldStyles}>
                <Typography variant="h3">Default Location</Typography>
                <SelectField
                  fullWidth
                  defaultValueIndex={locationId}
                  items={locationOptionsWithAll}
                  displayEmpty
                  disabled={!canEdit}
                  name={INTERNAL_USER_FIELDS.default_location_id}
                />
              </Box>
            )}
          </Box>
          {FORM_TYPE != 'new' && (
            <Box {...inlineFieldContainerStyles} mt={2}>
              <Box {...inlineFieldStyles} data-test="blocked-checkbox">
                <CheckboxField
                  label="Blocked"
                  name={INTERNAL_USER_FIELDS.blocked}
                  disabled={!canEdit}
                />
              </Box>
            </Box>
          )}
          {FORM_TYPE === 'new' && (
            <Box {...inlineFieldContainerStyles}>
              <Box {...inlineFieldStyles}>
                <Typography variant="h3">Password</Typography>
                <TextFieldControlled
                  className={classes.thinInput}
                  name={INTERNAL_USER_FIELDS.password}
                  disabled={!canEdit}
                  fullWidth
                />
              </Box>
            </Box>
          )}
        </Box>
      </ControlledForm>
    </Layout>
  )
}

export default withStyles(styles)(InternalUserForm)
