import { api } from 'api'
import { useAlerts, useResource, useResourceList, useLoaders } from 'hooks'
import {
  LIST_PATHS,
  LOW_STOCK_INVENTORY,
  HIGH_STOCK_INVENTORY,
  SOLD_OUT_INVENTORY
} from 'constants/queryParams'

const API_VARIANTS = '/api/v1/admin/variants'

const API_RESOURCE_NAME = 'variant'

const useAdminVariants = ({ id }) => {
  const { showLoading, hideLoading } = useLoaders()

  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    isLoading,
    data: variants,
    hasData: hasVariants,
    hasNext: hasVariantsNext,
    list: listVariants,
    listNext: listVariantsNext,
    clearList,
    relist
  } = useResourceList({ url: API_VARIANTS })

  const {
    data: variant,
    read: readVariant,
    destroy: deleteVariant
  } = useResource({
    name: API_RESOURCE_NAME,
    url: API_VARIANTS
  })

  const archiveVariant = async variantId => {
    try {
      const res = await api.put(`${API_VARIANTS}/${variantId}/archive`)
      return res.data
    } catch (e) {
      console.error(e)
      showAlertError(e?.data?.message)
    }
  }

  // will restore a variant from deleted or archived status
  const restoreVariant = async variantId => {
    try {
      const res = await api.put(`${API_VARIANTS}/${variantId}/restore`)
      return res.data
    } catch (e) {
      console.error(e)
      showAlertError(e?.data?.message)
    }
  }

  const updateVariant = async (variantId, locationId, data) => {
    const location_id = locationId
      ? {
          location_id: locationId
        }
      : {}

    try {
      const res = await api.put(`${API_VARIANTS}/${variantId}`, {
        variant: data,
        ...location_id
      })
      return res.data
    } catch (e) {
      console.error(e)
      showAlertError(e?.data?.message)
    }
  }

  const { list: getHighStockCount, count: highStockCount } = useResourceList({
    url: API_VARIANTS,
    perPage: 1
  })

  const { list: getLowStockCount, count: lowStockCount } = useResourceList({
    url: API_VARIANTS,
    perPage: 1
  })

  const fetchVariantsAndSummary = params => {
    listVariants(params)
    const locationParam =
      LIST_PATHS.VARIANTS_LOCATIONS_ID in params
        ? {
            [LIST_PATHS.VARIANTS_LOCATIONS_ID]:
              params[LIST_PATHS.VARIANTS_LOCATIONS_ID]
          }
        : {}
    getHighStockCount({ ...HIGH_STOCK_INVENTORY, ...locationParam })
    getLowStockCount({ ...LOW_STOCK_INVENTORY, ...locationParam })
  }

  const {
    list: getSoldOutVariantsCount,
    count: soldOutVariantsCount
  } = useResourceList({
    url: API_VARIANTS,
    perPage: 1
  })

  const fetchSoldOutVariantsCount = () =>
    getSoldOutVariantsCount(SOLD_OUT_INVENTORY)

  const bulkUpdateVariants = async ({
    variants: variantsToUpdate,
    weight,
    length,
    width,
    height,
    unit,
    appendBin = false,
    setBin = false
  }) => {
    const data = {
      unit,
      length,
      width,
      height,
      weight
      /* quantity */
    }

    Object.keys(data).forEach(k => {
      if (data[k] === null || data[k] === undefined) {
        delete data[k]
      }
    })

    if (!data.bin) {
      delete data.location_id
    }

    try {
      const updateList = variantsToUpdate.map(v => ({
        id: v.id,
        ...data
      }))
      showAlertSuccess('Variant changes have been saved.')
      await api.put(`${API_VARIANTS}/bulk_update`, {
        append_bin: appendBin,
        set_bin: setBin,
        variants: updateList
      })
    } catch (e) {
      console.error(e)
      showAlertError('There was an error updating variants.')
    }
  }

  const {
    isLoading: isLoadingHistory,
    data: historyData,
    hasData: hasHistory,
    hasNext: hasHistoryNext,
    list: fetchHistory,
    listNext: fetchHistoryNext,
    count: historyCount
  } = useResourceList({
    url: `${API_VARIANTS}/${id}/variant_history`
  })

  return {
    variant,
    readVariant,
    deleteVariant,
    archiveVariant,
    restoreVariant,
    isLoading,
    variants,
    lowStockCount,
    highStockCount,
    hasVariants,
    hasVariantsNext,
    listVariants,
    listVariantsNext,
    fetchVariantsAndSummary,
    fetchSoldOutVariantsCount,
    soldOutVariantsCount,
    updateVariant,
    bulkUpdateVariants,
    fetchHistory,
    historyData,
    hasHistory,
    hasHistoryNext,
    fetchHistoryNext,
    isLoadingHistory,
    historyCount
  }
}

export default useAdminVariants
