import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { formatNumberWithPadStart } from 'utils/general'

import styles from './WaitlistPaneStyles'

const WaitlistPane = ({ classes, users }) => (
  <Box className={classes.root}>
    <Box>
      <Typography variant="h5">Waitlist Count</Typography>
      <Typography className={classes.waitlistCount}>
        {formatNumberWithPadStart(!isEmpty(users) ? users?.length : 0)}
      </Typography>

      <Typography className={classes.waitlistCountLabel}>
        Users will be notified
      </Typography>
    </Box>

    {!isEmpty(users) && (
      <>
        <Box className={classes.divider} />
        <Box>
          <Typography variant="h5">Users</Typography>
          <Box mt={3}>
            {users.map(({ name, email, locations }, userIdx) => (
              <Box display="flex" mb={4} key={`waitlist-user-${userIdx}`}>
                <Typography variant="body1">{name}</Typography>
                <Box flex={1} />
                <Typography className={classes.emailLabel}>{email}</Typography>
                {locations.map(location => (
                  <Typography className={classes.locationLabel} key={location}>
                    {location}
                  </Typography>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </>
    )}
  </Box>
)

WaitlistPane.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object)
}

export default withStyles(styles)(WaitlistPane)
