export default theme => ({
  userEmail: {
    fontSize: 10
  },
  userName: {},
  selectedItem: {
    '& $userName': {
      fontWeight: 700
    },
    '& $userEmail': {
      color: theme.palette.common.black
    }
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  }
})
