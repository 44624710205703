import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import SaveIcon from '@material-ui/icons/Save'
import Typography from '@material-ui/core/Typography'

import {
  useAdminProducts,
  useBeforeUnload,
  useLoaders,
  useMediaQuery,
  useNavigation
} from 'hooks'
import { SIZE, COLOR } from 'constants/enums'
import { dollarsToCents } from 'utils/general'

import { URL } from 'constants/navigation'
import { NEW_VARIANT_SCHEMA, FORM_FIELDS } from 'constants/variants'

import Tabs from 'components/Tabs'
import Block from 'components/Block'
import Button from 'components/Button'
import Header from 'components/Header'
import Layout from 'components/Layout'
import MultiImageDropzone from 'components/MultiImageDropzone'
import { TextFieldControlled } from 'components/TextField'
import { CheckboxField } from 'components/Checkbox'
import { SelectField } from 'components/Select'
import ControlledForm, { useControlledForm } from 'components/ControlledForm'
import { AutocompleteSearchColorsControlled } from 'components/AutocompleteSearchColors'
import { AutocompleteSearchSizesControlled } from 'components/AutocompleteSearchSizes'
import { AutocompleteSearchAccessoryOptionsControlled } from 'components/AutocompleteSearchAccessoryOptions'
import SelectImagesDialog from 'components/SelectImagesDialog'

import styles from '../ProductVariantShow/ProductVariantShowStyles'

const SaveButton = () => {
  const { readyToSave, handleSubmit } = useControlledForm()
  const { OnBeforeUnloadPrompt } = useBeforeUnload(readyToSave)
  return (
    <>
      <Button
        label="save variant changes button"
        onClick={handleSubmit}
        size={SIZE.medium}
        color={COLOR.primary}
        dataTest="variant-save-button"
        startIcon={<SaveIcon />}
        adaptive
        disabled={!readyToSave}
        type="submit"
      >
        Save
      </Button>
      <OnBeforeUnloadPrompt />
    </>
  )
}

const BarcodeAndDSINComponenet = ({ classes }) => {
  const {
    formContext: { getValues }
  } = useControlledForm()

  const showDSIN = Boolean((getValues(FORM_FIELDS.UPC) ?? '').length)

  return (
    <>
      <Box mt={1}>
        <Typography variant="caption" className={classes.caption}>
          Barcode
        </Typography>
        <TextFieldControlled
          className={classes.thinInput}
          fullWidth
          placeholder="UPC"
          name={FORM_FIELDS.UPC}
        />
      </Box>
      {showDSIN && (
        <Box mt={1}>
          <Typography variant="caption" className={classes.caption}>
            DSIN
          </Typography>
          <TextFieldControlled
            className={classes.thinInput}
            fullWidth
            placeholder="DSIN"
            name={FORM_FIELDS.DSIN}
          />
        </Box>
      )}
    </>
  )
}

const getDefaultFormValues = product => {
  return {
    [FORM_FIELDS.TITLE]: 'Default Title',
    [FORM_FIELDS.COLOR]: [],
    [FORM_FIELDS.SIZE]: [],
    [FORM_FIELDS.ACCESSORY]: [],
    [FORM_FIELDS.MSRP]: '',
    [FORM_FIELDS.COMPARE_AT_PRICE]: '',
    [FORM_FIELDS.COST_PER_ITEM]: '',
    [FORM_FIELDS.VENDOR_STYLE_NUMBER]: product?.vendor_style_number_is_universal
      ? product?.vendor_style_number
      : '',
    [FORM_FIELDS.SKU]: '',
    [FORM_FIELDS.UPC]: '',
    [FORM_FIELDS.DSIN]: '',
    [FORM_FIELDS.UNIT_OF_MEASURE]: 'in',
    [FORM_FIELDS.LENGTH]: '',
    [FORM_FIELDS.WIDTH]: '',
    [FORM_FIELDS.HEIGHT]: '',
    [FORM_FIELDS.WEIGHT]: '',
    [FORM_FIELDS.IMAGE_FILES]: [],
    [FORM_FIELDS.SELECT_IMAGES]: [],
    [FORM_FIELDS.SAMPLE]: false
  }
}

const ProductVariantShow = ({
  classes,
  match: {
    params: { id: productId }
  }
}) => {
  const { isMobileScreen, isDesktopScreen } = useMediaQuery()
  const { showLoading, hideLoading } = useLoaders()
  const { createVariant, readProduct, product } = useAdminProducts({
    id: productId
  })
  const [defaultFormValues, setDefaultFormValues] = useState(
    getDefaultFormValues({})
  )

  const { handleShowDetailsClick } = useNavigation({
    url: URL.ADMIN_PRODUCTS
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [showImageSelectionPopup, setShowImageSelectionPopup] = useState(false)

  useEffect(() => {
    showLoading()
    ;(async () => {
      ;(productId || productId === 0) && (await readProduct(productId))
    })()
    hideLoading()
  }, [])

  useEffect(() => {
    setDefaultFormValues(product)
  }, [product])

  const handleSave = async data => {
    showLoading()
    delete data[FORM_FIELDS.IMAGE_FILES]
    delete data[FORM_FIELDS.SKU]

    const { msrp, compare_at_price, cost_per_item } = data
    delete data[FORM_FIELDS.MSRP]
    delete data[FORM_FIELDS.COMPARE_AT_PRICE]
    delete data[FORM_FIELDS.COST_PER_ITEM]

    const color_id = data[FORM_FIELDS.COLOR]?.id ?? null
    const size_id = data[FORM_FIELDS.SIZE]?.id ?? null
    const accessory_option_id = data[FORM_FIELDS.ACCESSORY]?.id ?? null
    delete data[FORM_FIELDS.COLOR]
    delete data[FORM_FIELDS.SIZE]
    delete data[FORM_FIELDS.ACCESSORY]

    delete data[FORM_FIELDS.TITLE]

    const newVariant = await createVariant(productId, {
      ...data,
      color_id,
      size_id,
      accessory_option_id,
      cost: dollarsToCents(cost_per_item),
      compare_at_price: dollarsToCents(compare_at_price),
      price: dollarsToCents(msrp),
      select_images: data[FORM_FIELDS.SELECT_IMAGES]?.map(img => img.src),
      title: 'Default Title'
    })

    await readProduct(productId)

    hideLoading()

    if (newVariant) {
      handleShowDetailsClick({}, product, `variant/${newVariant?.id}`)
    }
  }

  return (
    <Layout id="variant create">
      <ControlledForm
        handleSubmit={handleSave}
        schemas={NEW_VARIANT_SCHEMA}
        defaultValues={defaultFormValues}
        resetOnSubmit={false}
      >
        <Box>
          <Box className={classes.headerBox}>
            <Header
              breadcrumbs={[
                { title: 'Products', link: URL.ADMIN_PRODUCTS },
                {
                  title: product?.title ?? 'Loading',
                  link: `${URL.ADMIN_PRODUCTS}/${product?.id}`
                }
              ]}
              title="New"
              actions={<SaveButton />}
            />
          </Box>
          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid item sm={12} md={6} className={classes.gridItem}>
              <Block withPadding>
                <Typography variant="h5">Title</Typography>
                <TextFieldControlled
                  className={classes.thinInput}
                  fullWidth
                  placeholder="Title"
                  name={FORM_FIELDS.TITLE}
                  disabled
                />
              </Block>
              <Block>
                <Box className={classes.paddingBlock}>
                  <Typography variant="h5">Media</Typography>
                </Box>
                <MultiImageDropzone
                  name={FORM_FIELDS.IMAGE_FILES}
                  editName={FORM_FIELDS.SELECT_IMAGES}
                  dropzoneDisabled
                  defaultUrls={[]}
                  emptyImagesPlaceholderText="Hit the edit button to select variant photos!"
                  onEditButtonClick={() =>
                    setShowImageSelectionPopup(!showImageSelectionPopup)
                  }
                />
              </Block>
              <Block>
                <Box className={classes.paddingBlock}>
                  <Typography variant="h5">Options</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    pt={2}
                  >
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection={isDesktopScreen ? 'column' : 'row'}
                      flexWrap="wrap"
                      className={classes.autocompleteBox}
                      width="100%"
                    >
                      <Box
                        width="100%"
                        mb={isDesktopScreen ? 1 : 0}
                        mr={isDesktopScreen ? 0 : 1}
                      >
                        <Typography variant="caption">Color</Typography>
                        <AutocompleteSearchColorsControlled
                          name={FORM_FIELDS.COLOR}
                          multiple={false}
                          disabled={!product?.color_options?.length}
                        />
                      </Box>
                      <Box
                        width="100%"
                        mb={isDesktopScreen ? 1 : 0}
                        mr={isDesktopScreen ? 0 : 1}
                      >
                        <Typography variant="caption">Sizes</Typography>
                        <AutocompleteSearchSizesControlled
                          name={FORM_FIELDS.SIZE}
                          multiple={false}
                          disabled={!product?.size_options?.length}
                        />
                      </Box>
                      <Box width="100%">
                        <Typography variant="caption">
                          Accessory Option
                        </Typography>
                        <AutocompleteSearchAccessoryOptionsControlled
                          name={FORM_FIELDS.ACCESSORY}
                          multiple={false}
                          disabled={!product?.accessory_options?.length}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box px={3} pb={3} pt={0}>
                  <Typography variant="h5">Pricing</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    pt={2}
                  >
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      <Typography variant="caption">Price</Typography>
                      <TextFieldControlled
                        number
                        className={classes.smallInput}
                        startAdornment="$"
                        name={FORM_FIELDS.MSRP}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      <Typography variant="caption">
                        Compare At Price
                      </Typography>
                      <TextFieldControlled
                        number
                        className={classes.smallInput}
                        startAdornment="$"
                        name={FORM_FIELDS.COMPARE_AT_PRICE}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      <Typography variant="caption">Cost Per item</Typography>
                      <TextFieldControlled
                        number
                        className={classes.smallInput}
                        startAdornment="$"
                        name={FORM_FIELDS.COST_PER_ITEM}
                      />
                    </Box>
                  </Box>
                </Box>
              </Block>
            </Grid>

            <Grid item sm={12} md={6} className={classes.gridItem}>
              <Block>
                <Tabs
                  panes={[
                    {
                      label: 'Details',
                      pane: (
                        <>
                          <Box className={classes.paddingBlock}>
                            <Typography variant="h5">Inventory</Typography>
                            <Box mt={1}>
                              <Typography
                                variant="caption"
                                className={classes.caption}
                              >
                                Vendor style number
                              </Typography>
                              <TextFieldControlled
                                className={classes.thinInput}
                                fullWidth
                                placeholder="AES-2030"
                                name={FORM_FIELDS.VENDOR_STYLE_NUMBER}
                                disabled={
                                  product?.vendor_style_number_is_universal
                                }
                              />
                            </Box>
                            <Box mt={1}>
                              <Typography
                                variant="caption"
                                className={classes.caption}
                              >
                                SKU
                              </Typography>
                              <TextFieldControlled
                                className={classes.thinInput}
                                fullWidth
                                placeholder="SKU"
                                name={FORM_FIELDS.SKU}
                                disabled
                              />
                            </Box>
                            <BarcodeAndDSINComponenet classes={classes} />
                          </Box>
                          <Box className={classes.paddingBlock} my={1}>
                            <div className={classes.divider} />
                          </Box>
                          <Box className={classes.paddingBlock}>
                            <Typography variant="h5">Dimensions</Typography>
                            <SelectField
                              fullWidth
                              items={[
                                { label: 'None', value: null },
                                { label: 'Feet', value: 'ft' },
                                { label: 'Inches', value: 'in' },
                                { label: 'Centimeters', value: 'cm' }
                              ]}
                              displayEmpty
                              name={FORM_FIELDS.UNIT_OF_MEASURE}
                            />
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              flexDirection={isMobileScreen ? 'column' : 'row'}
                              className={classes.dimensionsContainer}
                            >
                              <TextFieldControlled
                                number
                                className={classes.thinInput}
                                fullWidth={Boolean(isMobileScreen)}
                                placeholder="Length"
                                name={FORM_FIELDS.LENGTH}
                              />
                              <TextFieldControlled
                                number
                                className={classes.thinInput}
                                fullWidth={Boolean(isMobileScreen)}
                                placeholder="Width"
                                name={FORM_FIELDS.WIDTH}
                              />
                              <TextFieldControlled
                                number
                                className={classes.thinInput}
                                fullWidth={Boolean(isMobileScreen)}
                                placeholder="Height"
                                name={FORM_FIELDS.HEIGHT}
                              />
                            </Box>
                            <Box my={2}>
                              <div className={classes.divider} />
                            </Box>
                            <Typography variant="h5">Weight</Typography>
                            <Box mt={1}>
                              <Box display="flex">
                                <TextFieldControlled
                                  className={classes.thinInput}
                                  fullWidth
                                  placeholder="Weight"
                                  name={FORM_FIELDS.WEIGHT}
                                  number
                                />
                              </Box>
                            </Box>
                            <Box my={2}>
                              <div className={classes.divider} />
                            </Box>
                            <Box mt={1}>
                              <Typography variant="h5">Flags</Typography>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box>
                                  <Typography variant="body1">
                                    Sample
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    This item is intended as a sample from
                                    vendors for performance testing and demand
                                    analysis.
                                  </Typography>
                                </Box>
                                <Box>
                                  <CheckboxField name={FORM_FIELDS.SAMPLE} />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      )
                    }
                  ]}
                />
              </Block>
            </Grid>
          </Grid>
        </Box>
        <SelectImagesDialog
          name={FORM_FIELDS.SELECT_IMAGES}
          title="Select Variant Photos"
          subtitle="Pick photos you've uploaded for this products variant."
          open={showImageSelectionPopup}
          onClose={() => setShowImageSelectionPopup(false)}
          selectableImages={
            Array.isArray(product?.raw_images) ? product?.raw_images : []
          }
        />
      </ControlledForm>
    </Layout>
  )
}

ProductVariantShow.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(ProductVariantShow)
