import { api, downloadApi } from 'api'
import { useResourceList, useResource } from 'hooks'

const API_BRANDS = '/api/v1/admin/brands'
const BRAND = 'brand'

const REPORT_TYPES = {
  customer: 'customer',
  inventory: 'inventory',
  payment: 'payment'
}

const useAdminBrands = () => {
  const { createMultipartFormData: createBrand } = useResource({
    url: API_BRANDS,
    name: BRAND
  })

  const {
    isLoading: isLoadingBrands,
    data: brands,
    hasData: hasBrands,
    hasNext: hasBrandsNext,
    list: fetchBrands,
    listNext: fetchBrandsNext,
    relist: refetchBrands,
    updateItemsLocally: updateBrandsLocally
  } = useResourceList({ url: API_BRANDS })

  const {
    read: readBrand,
    data: brand,
    hasData: hasBrand,
    update: updateBrand,
    updateMultipartFormData: updateBrandWithImage
  } = useResource({
    url: API_BRANDS,
    name: 'brand'
  })

  const updatePublishStatuses = async (brands, active) => {
    try {
      await api.put(`${API_BRANDS}/bulk_update_status`, {
        brand_ids: brands.map(c => c.id),
        active
      })
      refetchBrands()
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const exportReports = (
    brandId,
    dateRange,
    customerReport,
    inventoryReport,
    paymentReport
  ) => {
    const files = []
    if (customerReport) {
      files.push(REPORT_TYPES.customer)
    }
    if (inventoryReport) {
      files.push(REPORT_TYPES.inventory)
    }
    if (paymentReport) {
      files.push(REPORT_TYPES.payment)
    }

    if (files.length === 0) {
      console.info('No report selected.')
      return
    }

    if (files.length === 1) {
      downloadApi.get(
        `${API_BRANDS}/${brandId}/${files[0]}_report_csv?date_range=${dateRange[0]},${dateRange[1]}`
      )
      return
    }

    downloadApi.get(
      `${API_BRANDS}/${brandId}/reports?date_range=${dateRange[0]},${
        dateRange[1]
      }&files=${files.join(',')}`
    )
  }

  const bulkExportBrandReports = async ({
    brandIds,
    dateRange,
    customerReport,
    inventoryReport,
    paymentReport
  }) => {
    const files = []
    if (customerReport) {
      files.push(REPORT_TYPES.customer)
    }
    if (inventoryReport) {
      files.push(REPORT_TYPES.inventory)
    }
    if (paymentReport) {
      files.push(REPORT_TYPES.payment)
    }

    if (files.length === 0) {
      console.info('No report selected.')
      return
    }

    try {
      await api.get(
        `${API_BRANDS}/multi_brand_reports?brand_ids=${brandIds.join(
          ','
        )}&date_range=${dateRange[0]},${dateRange[1]}&files=${files.join(',')}`
      )
    } catch (e) {
      console.error(e)
      return false
    }
  }

  return {
    createBrand,
    isLoadingBrands,
    brands,
    hasBrands,
    hasBrandsNext,
    fetchBrands,
    fetchBrandsNext,
    readBrand,
    hasBrand,
    brand,
    updateBrand,
    updateBrandWithImage,
    updatePublishStatuses,
    updateBrandsLocally,
    exportReports,
    bulkExportBrandReports
  }
}

export default useAdminBrands
