import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import * as yup from 'yup'
import { useForm, FormProvider } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from 'components/TextField'

import {
  DROPZONE_IMAGE_TYPES,
  COLLECTION_IMAGE_WIDTH_PX,
  COLLECTION_IMAGE_HEIGHT_PX,
  DROPZONE_IMAGE_PREVIEW_HEIGHT_PX
} from 'constants/collections'
import {
  makeObjectResolver,
  makeImageSchemaWithResolution
} from 'constants/yupSchemas'
import ImageDropzone from 'components/ImageDropzone'
import Dialog from 'components/Dialog'

import styles from './CreateBrandModalStyles'

const FIELDS = {
  name: 'name',
  brandType: 'brand_type',
  coverPhoto: 'cover_photo',
  stripeAccountId: 'stripe_connect_account_id'
}

const yupUserSchemas = {
  [FIELDS.name]: yup.string().required('Name is required'),
  [FIELDS.image]: makeImageSchemaWithResolution(
    COLLECTION_IMAGE_WIDTH_PX,
    COLLECTION_IMAGE_HEIGHT_PX
  ),
  [FIELDS.stripeAccountId]: yup.string()
}

const CreateBrandModal = ({ classes, open, createBrand, onClose }) => {
  const formMethods = useForm({
    mode: 'onSubmit',
    resolver: makeObjectResolver(yupUserSchemas)
  })
  const { register, handleSubmit, errors } = formMethods

  const onSubmit = brand => {
    const coverPhoto = brand[FIELDS.coverPhoto]
    const imageObject = coverPhoto ? { [FIELDS.coverPhoto]: coverPhoto } : null

    createBrand({
      [FIELDS.name]: brand[FIELDS.name],
      [FIELDS.stripeAccountId]: brand[FIELDS.stripeAccountId],
      ...imageObject
    })
    onClose && onClose()
  }

  return (
    <Dialog
      title="Add Brand"
      open={open}
      onCancel={onClose}
      onConfirm={handleSubmit(onSubmit)}
      dataTest="brand"
    >
      <FormProvider {...formMethods}>
        <Box>
          <Typography variant="body2">Brand Name</Typography>
          <TextField
            name={FIELDS.name}
            placeholder="Enter brand's name"
            fullWidth
            inputRef={register}
            error={Boolean(errors[FIELDS.name]?.message)}
            helperText={errors[FIELDS.name]?.message}
            inputProps={{ 'data-test': 'add-brand-name' }}
          />
        </Box>
        <Box>
          <Typography variant="body2">Stripe Connect ID</Typography>
          <TextField
            name={FIELDS.stripeAccountId}
            placeholder="Enter Stripe ID"
            fullWidth
            inputRef={register}
            error={Boolean(errors[FIELDS.stripeAccountId]?.message)}
            helperText={errors[FIELDS.stripeAccountId]?.message}
            inputProps={{ 'data-test': 'add-brand-stripeid' }}
          />
        </Box>
        <Box>
          <Typography variant="body2">Brand Cover</Typography>
          <Box height={DROPZONE_IMAGE_PREVIEW_HEIGHT_PX} mt={2}>
            <ImageDropzone
              name={FIELDS.coverPhoto}
              accept={DROPZONE_IMAGE_TYPES}
            />
          </Box>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

CreateBrandModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  createBrand: PropTypes.func.isRequired,
  onClose: PropTypes.func
}

export default withStyles(styles)(CreateBrandModal)
