import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import SaveIcon from '@material-ui/icons/Save'

import Box from '@material-ui/core/Box'

import {
  useAdminBrands,
  useLoaders,
  useMediaQuery,
  useBeforeUnload,
  useConditionalEffect
} from 'hooks'
import { BUTTON_VARIANT, COLOR, SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'
import { getBrandName } from 'utils/general'

import Button from 'components/Button'
import Tabs from 'components/Tabs'
import Layout from 'components/Layout'
import ExportDrawer from 'components/ExportDrawer'
import Header from 'components/Header'
import ExportIcon from 'icons/ExportIcon'

import BrandDetails from 'containers/admin/BrandDetails'
import BrandCustomerList from 'containers/admin/BrandCustomerList'

import styles from './BrandShowStyles'

const BrandShow = ({
  classes,
  match: {
    params: { id }
  },
  location,
  history
}) => {
  const [exportDrawerOpen, setExportDrawerOpen] = useState(false)

  const useCustomerTab = location?.search === '?customerTab'
  const { brand, exportReports, readBrand, updateBrand } = useAdminBrands()
  const { showLoading, hideLoading } = useLoaders()

  useConditionalEffect(() => {
    ;(async () => {
      showLoading()
      id && (await readBrand(id))
      hideLoading()
    })()
  }, [id])

  useConditionalEffect(() => {
    if (useCustomerTab) {
      history.replace(location.pathname)
    }
  }, [location])

  const [brandUpdates, setBrandUpdates] = useState({})
  const handleBrandUpdates = update =>
    setBrandUpdates({
      ...brandUpdates,
      ...update
    })

  // (@awitherow) TODO: temporary "hacky" solution to disable/enable brand save
  // button in the case that values are reset to the brand value. ideally will
  // refactor this soon into a controlled form component.
  const hasUpdates = isEmpty(brandUpdates)
    ? false
    : Boolean(
        Object.keys(brandUpdates).filter(key => {
          // compares date (no time) to detect if form was reset to original value.
          if (key === 'signed_at' && Boolean(brandUpdates[key])) {
            return (
              brandUpdates[key].format('YYYY-MM-DD') !==
              moment(brand[key]).format('YYYY-MM-DD')
            )
          }
          // compares empty values from server to blank values in form
          if (!brand[key]) {
            return Boolean(brandUpdates[key]) !== Boolean(brand[key])
          }
          return brandUpdates[key] !== brand[key]
        }).length
      )

  const { OnBeforeUnloadPrompt } = useBeforeUnload(hasUpdates)

  const handleSave = async () => {
    showLoading()
    await updateBrand({
      id: brand.id,
      ...brandUpdates
    })
    setBrandUpdates({})
    hideLoading()
  }

  const handleExport = (
    dateRange,
    customerReport,
    inventoryReport,
    paymentReport
  ) => {
    exportReports(
      brand.id,
      dateRange,
      customerReport,
      inventoryReport,
      paymentReport
    )

    setExportDrawerOpen(false)
  }

  const foldableActions = (
    <Button
      color={COLOR.secondary}
      variant={BUTTON_VARIANT.outlined}
      size={SIZE.medium}
      onClick={() => setExportDrawerOpen(true)}
      startIcon={<ExportIcon />}
    >
      Export
    </Button>
  )

  const {
    isMobileScreen: isMobile,
    isDesktopScreen: isDesktop,
    isDesktopLargeScreen: isDesktopLarge
  } = useMediaQuery()

  return (
    <Layout id="brand-details">
      <Header
        breadcrumbs={[{ title: 'Brand Management', link: URL.ADMIN_BRANDS }]}
        title={getBrandName(brand)}
        foldableActions={foldableActions}
        actions={
          <>
            <Button
              adaptive
              startIcon={<SaveIcon />}
              size="medium"
              label="save product changes button"
              onClick={handleSave}
              disabled={!hasUpdates}
              dataTest="save-brand-button"
            >
              Save
            </Button>
            <OnBeforeUnloadPrompt />
          </>
        }
      />
      <Box
        className={classNames({
          [classes.content]: true,
          [classes.isMobile]: isMobile,
          [classes.isDesktop]: isDesktop,
          [classes.isDesktopLarge]: isDesktopLarge
        })}
      >
        <Tabs
          defaultTabIndex={useCustomerTab ? 1 : 0}
          prerender={false}
          panes={[
            {
              label: 'Details',
              pane: (
                <BrandDetails
                  brand={brand}
                  brandUpdates={brandUpdates}
                  handleBrandUpdates={handleBrandUpdates}
                />
              )
            },
            {
              label: 'Customers',
              pane: brand && <BrandCustomerList brandId={id} />
            }
          ]}
        />
      </Box>

      <ExportDrawer
        open={exportDrawerOpen}
        onClose={() => setExportDrawerOpen(false)}
        onExport={handleExport}
      />
    </Layout>
  )
}

BrandShow.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withStyles(styles)(BrandShow)
