import React from 'react'

const UnlockedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5C11.0197 5 10.4009 5.32414 9.98063 5.78313C9.60767 6.19046 8.97512 6.21833 8.56779 5.84538C8.16045 5.47242 8.13258 4.83987 8.50554 4.43253C9.33896 3.52228 10.5116 3 12 3C13.5539 3 14.9729 3.24481 15.9023 4.39296C16.7692 5.46392 17 7.08937 17 9.177C17 9.29407 16.9993 9.40833 16.998 9.51987C17.5057 9.68616 17.9584 9.90633 18.35 10.2C19.7807 11.273 20 13.011 20 15C20 16.989 19.7807 18.727 18.35 19.8C17.6701 20.3099 16.8056 20.5983 15.7894 20.7676C14.7703 20.9375 13.518 21 12 21C10.482 21 9.2297 20.9375 8.2106 20.7676C7.19438 20.5983 6.32991 20.3099 5.65 19.8C4.21927 18.727 4 16.989 4 15C4 13.011 4.21927 11.273 5.65 10.2C6.32991 9.69007 7.19438 9.40173 8.2106 9.23236C9.2297 9.06251 10.482 9 12 9C13.147 9 14.1423 9.03569 14.9999 9.12641C14.9956 7.07204 14.7277 6.12074 14.3477 5.65129C14.0271 5.25519 13.4461 5 12 5ZM15.8682 11.2837C15.8056 11.2766 15.7447 11.2638 15.6862 11.2458C15.6138 11.2317 15.5387 11.2182 15.4606 11.2051C14.6047 11.0625 13.482 11 12 11C10.518 11 9.3953 11.0625 8.5394 11.2051C7.68062 11.3483 7.17009 11.5599 6.85 11.8C6.28073 12.227 6 12.989 6 15C6 17.011 6.28073 17.773 6.85 18.2C7.17009 18.4401 7.68062 18.6517 8.5394 18.7949C9.3953 18.9375 10.518 19 12 19C13.482 19 14.6047 18.9375 15.4606 18.7949C16.3194 18.6517 16.8299 18.4401 17.15 18.2C17.7193 17.773 18 17.011 18 15C18 12.989 17.7193 12.227 17.15 11.8C16.8842 11.6006 16.4871 11.4209 15.8682 11.2837ZM13 14C13 13.4477 12.5523 13 12 13C11.4477 13 11 13.4477 11 14V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V14Z"
      fill="black"
    />
  </svg>
)

export default UnlockedIcon
