import { useResourceRQ } from 'hooks'
import { deepLinksEndpoint } from 'api/endpoints'

const useDeeplinks = () => {
  const {
    data,
    isFetching,
    isIdle,
    isError,
    error,
    refetch
  } = useResourceRQ({
    endpoint: deepLinksEndpoint
  });

  return {
    data,
    isFetching,
    isIdle,
    isError,
    error,
    refetch
  }
}

export default useDeeplinks;
