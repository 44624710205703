const jssStyles = theme => ({
  previewContainer: {
    borderTop: '1px solid lightgray',
    borderBottom: '1px solid lightgray',
    margin: 'auto',
    marginTop: '91px',
    marginBottom: '91px',
    display: 'flex',
  },
  previewImageContainer: {
    margin: 'auto',
  },
  previewImage: {
    width: "48px",
    height: "48px",
    display: 'block',
    margin: 'auto'
  },
  title: {
    paddingTop: '16px',
    fontSize: '16px',
    fontWeight: 600
  },
  description: {
    paddingTop: '4px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#8D8D8D'
  },
  error: {
    color: "red",
    fontWeight: 600,
    paddingBottom: '10px',
    textAlign: 'center'
  }
})

export default jssStyles
