import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'

const DefaultSizePx = 24

const RadioIcon = ({ fill, fontSize }) => {
  const theme = useTheme()

  return (
    <svg
      width={fontSize || DefaultSizePx}
      height={fontSize || DefaultSizePx}
      viewBox={`0 0 ${DefaultSizePx} ${DefaultSizePx}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill={fill || theme.palette.primary.main}
      />
      <path
        d="M10.7071 15.2071L17.5 8.41421L17.5858 8.5L10 16.0858L6.41421 12.5L6.5 12.4142L9.29289 15.2071L10 15.9142L10.7071 15.2071Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}

RadioIcon.propTypes = {
  fill: PropTypes.string
}

export default RadioIcon
