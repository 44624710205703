const jssStyles = theme => ({
  labeledCheckbox: {
    minHeight: 50,
    borderRadius: 5,
    width: '100%',
    margin: theme.spacing(1, 0),

    '& .MuiFormControlLabel-root': {
      marginLeft: theme.spacing(2)
    }
  }
})
export default jssStyles
