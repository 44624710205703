import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Radio } from '@material-ui/core'

import { PROMO_CODE_FIELDS, DISCOUNT_TYPE_LABELS } from 'constants/promoCodes'

import { useControlledForm } from 'components/ControlledForm'

const DiscountTypeRadioInputs = ({ classes }) => {
  const {
    formContext: { watch, setValue, register, unregister, getValues }
  } = useControlledForm()

  useEffect(() => {
    register(PROMO_CODE_FIELDS.discount_type)
    register(PROMO_CODE_FIELDS.amount)
    return () => {
      unregister(PROMO_CODE_FIELDS.discount_type)
      unregister(PROMO_CODE_FIELDS.amount)
    }
  }, [
    register,
    unregister,
    PROMO_CODE_FIELDS.discount_type,
    PROMO_CODE_FIELDS.amount
  ])

  const { [PROMO_CODE_FIELDS.discount_type]: discountType } = watch(
    [PROMO_CODE_FIELDS.discount_type].filter(n => n && typeof n === 'string')
  )

  const renderInput = DISCOUNT_TYPE_LABELS?.filter(
    ({ value }) => value === discountType
  )[0]?.renderInput

  useEffect(() => {
    if (discountType === DISCOUNT_TYPE_LABELS[2].value) {
      const currentValue = getValues(PROMO_CODE_FIELDS.amount)
      const reset = 0
      if (currentValue !== reset) {
        setValue(PROMO_CODE_FIELDS.amount, reset, {
          shouldDirty: true
        })
      }
    }
  }, [discountType])

  return (
    <>
      <Typography variant="caption">Discount Type</Typography>
      <Box className={classes.radioButtonContainer}>
        {DISCOUNT_TYPE_LABELS.map(({ label: thisLabel, value }) => (
          <Box className={classes.radioButtonWithLabel} key={value}>
            <Radio
              color="primary"
              checked={discountType === value}
              onChange={e =>
                setValue(PROMO_CODE_FIELDS.discount_type, e.target.value, {
                  shouldDirty: true
                })
              }
              value={value}
            />
            <Typography variant="body1">{thisLabel}</Typography>
          </Box>
        ))}
      </Box>
      {Boolean(renderInput) && (
        <Box className={classes.verticalDisplay}>
          <Typography variant="caption">Amount</Typography>
          {renderInput({
            number: true,
            className: classes.controlContainer,
            name: PROMO_CODE_FIELDS.amount
          })}
        </Box>
      )}
    </>
  )
}

DiscountTypeRadioInputs.propTypes = {
  classes: PropTypes.object.isRequired
}

export default DiscountTypeRadioInputs
