import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { withStyles } from '@material-ui/core/styles'

import uniqBy from 'lodash/uniqBy'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminProducts } from 'hooks'
import AutocompleteSearch from 'components/AutocompleteSearch'

import styles from './AutocompleteSearchTaxCodesStyles'

const AutocompleteSearchTaxCodesRaw = ({
  name,
  classes,
  onChange,
  value,
  defaultOpen,
  multiple = false,
  helperText,
  error
}) => {
  const {
    isLoadingTaxCodes,
    taxCodes,
    listTaxCodes,
    listTaxCodesNext
  } = useAdminProducts({})
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (query.length) {
      listTaxCodes({ search: query })
    } else {
      listTaxCodes()
    }
  }, [query])

  const options = useMemo(
    () => uniqBy((taxCodes ?? []).concat(value), v => v?.id),
    [taxCodes]
  )

  return (
    <Box mt={1}>
      <AutocompleteSearch
        name={name}
        helperText={helperText}
        error={error}
        withStartAdornment={false}
        defaultOpen={defaultOpen}
        value={value}
        loading={isLoadingTaxCodes}
        setQuery={setQuery}
        onChange={onChange}
        onScrollBottom={listTaxCodesNext}
        options={options}
        placeholder="Search Tax Categories"
        multiple={multiple}
        getOptionLabel={opt =>
          opt?.name && opt?.code ? `${opt?.name} - ${opt?.code}` : ''
        }
        renderOption={(option, { selected }) => (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="100%"
            className={selected ? classes.selectedItem : null}
          >
            <Typography variant="body1" className={classes.taxCategoryName}>
              {option?.name}
            </Typography>
            <Typography variant="body1" className={classes.taxCategoryCode}>
              {option?.code}
            </Typography>
          </Box>
        )}
      />
    </Box>
  )
}

AutocompleteSearchTaxCodesRaw.defaultProps = {
  value: [],
  defaultOpen: false,
  multiple: false
}

AutocompleteSearchTaxCodesRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  defaultOpen: PropTypes.bool,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool
}

const AutocompleteSearchTaxCodes = withStyles(styles)(
  AutocompleteSearchTaxCodesRaw
)

const AutocompleteSearchTaxCodesControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchTaxCodes
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchTaxCodesControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchTaxCodes as default,
  AutocompleteSearchTaxCodesControlled
}
