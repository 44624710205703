import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

/* Just a wrapper for a hidden input that connects to a React Hook
   Form form context. */
export default function FormHiddenInput({ name }) {
  const { register } = useFormContext()
  return <input type="hidden" name={name} ref={register} />
}

FormHiddenInput.propTypes = {
  name: PropTypes.string.isRequired
}
