import React from 'react'
import PropTypes from 'prop-types'

const SquareCloverIcon = ({ fontSize, className }) => (
  <svg
    width={fontSize}
    height={fontSize}
    viewBox={`0 0 ${fontSize} ${fontSize}`}
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.99902"
      y="2"
      width={`${fontSize / 3}`}
      height={`${fontSize / 3}`}
      rx="2"
      stroke="#111111"
      strokeWidth="2"
    />
    <rect
      x="13.999"
      y="2"
      width={`${fontSize / 3}`}
      height={`${fontSize / 3}`}
      rx="2"
      stroke="#111111"
      strokeWidth="2"
    />
    <rect
      x="1.99902"
      y="14"
      width={`${fontSize / 3}`}
      height={`${fontSize / 3}`}
      rx="2"
      stroke="#111111"
      strokeWidth="2"
    />
    <rect
      x="13.999"
      y="14"
      width={`${fontSize / 3}`}
      height={`${fontSize / 3}`}
      rx="2"
      stroke="#111111"
      strokeWidth="2"
    />
  </svg>
)

SquareCloverIcon.defaultProps = {
  fontSize: 24,
  fill: 'currentColor'
}

SquareCloverIcon.propTypes = {
  fill: PropTypes.string,
  fontSize: PropTypes.number,
  className: PropTypes.string
}

export default SquareCloverIcon
