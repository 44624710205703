import React, { useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { COLLECTION_CAN_BE_CHILD } from 'constants/collections'
import Button from 'components/Button'
import Drawer from 'components/Drawer'
import AutocompleteSearchCollections from 'components/AutocompleteSearchCollections'

import styles from './AddCollectionChildDrawerStyles'

const AddCollectionChildDrawer = ({
  classes,
  open,
  onClose,
  activeCollections,
  onSubmit
}) => {
  const [selectedCollections, setSelectedCollections] = useState([])

  const handleSubmit = () => {
    onSubmit(selectedCollections)
    onClose()
  }

  const drawerActions = (
    <Button
      fullWidth
      disabled={isEmpty(selectedCollections)}
      onClick={handleSubmit}
    >
      Add to Collection
    </Button>
  )

  return (
    <Drawer
      title="Add Child Collection"
      open={open}
      onClose={onClose}
      actions={drawerActions}
    >
      <Box mt={4} mb={2}>
        <Typography variant="h6">Search Collections</Typography>
      </Box>

      <AutocompleteSearchCollections
        value={selectedCollections}
        onChange={setSelectedCollections}
        hiddenItems={activeCollections}
        queryProps={COLLECTION_CAN_BE_CHILD}
        multiple
      />
    </Drawer>
  )
}

AddCollectionChildDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  activeCollections: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func
}

export default withStyles(styles)(AddCollectionChildDrawer)
