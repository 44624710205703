const jssStyles = theme => ({
  root: {
    position: 'relative'
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 3, 0, 2),
    height: 52
  },
  priceTagList: {
    '& li:nth-child(odd)': { background: theme.palette.grays.ultralight }
  },
  priceValue: {
    fontWeight: 700
  },
  stripeContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0'
  },
  negativeTag: {
    '&:before': {
      content: '"-"',
      position: 'relative'
    }
  },
  dividerSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0'
  },
  paddingBlock: {
    padding: theme.spacing(3)
  },
  paymentId: {
    flexDirection: 'column'
  },
  bold: {
    fontWeight: 700
  }
})

export default jssStyles
