import React, { createContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import { ALL_LOCATION } from 'constants/general'
import { useAdminLocations, useConditionalEffect } from 'hooks'
import { useAuthContext, useLocalStorageContext } from 'context'

export const LocationsContext = createContext()

export const LocationsProvider = ({ children }) => {
  const { currentUser } = useAuthContext()
  const { locationId } = useLocalStorageContext()
  const { locations, fetchLocations } = useAdminLocations()

  useConditionalEffect(() => {
    if (!isEmpty(currentUser)) {
      fetchLocations()
    }
  }, [currentUser])

  const locationOptions = isEmpty(locations)
    ? null
    : locations
        ?.filter(l => l.active)
        .map(location => ({
          label: location.name,
          value: location.id
        }))

  const locationOptionsWithAll = locationOptions
    ? locationOptions.concat([
        { label: ALL_LOCATION.name, value: ALL_LOCATION.id }
      ])
    : null

  const locationOptionsWithAllSelector = locationOptions
    ? locationOptions.concat([
        {
          label: ALL_LOCATION.name,
          value: ALL_LOCATION.code,
          id: ALL_LOCATION.id
        }
      ])
    : null

  const locationOptionsDisplayName = isEmpty(locations)
    ? null
    : locations?.map(location => ({
        label: location.display_name,
        value: location.id
      }))

  const getLocationAtId = id => {
    if (id === ALL_LOCATION.id) {
      return ALL_LOCATION
    }
    return locations?.[locations?.findIndex(v => v.id === id)]
  }

  const value = useMemo(
    () => ({
      locationId,
      getLocationAtId,
      location: getLocationAtId(locationId),
      showAllLocationIds: locationId === ALL_LOCATION.id,
      locations,
      locationOptions,
      locationOptionsWithAll,
      locationOptionsDisplayName,
      locationOptionsWithAllSelector
    }),
    [locations, locationId]
  )

  return (
    <LocationsContext.Provider value={value}>
      {children}
    </LocationsContext.Provider>
  )
}

LocationsProvider.propTypes = {
  children: PropTypes.node
}
