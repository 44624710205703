export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 16px)'
  },
  gridContainer: {
    flex: 1,
    height: 'calc(100% - 80px)',
    overflow: 'hidden'
  },
  isDesktop: {
    '& $gridContainer': {
      marginTop: 0,
      flex: 'none',
      height: 'calc(100% - 32px)',
      overflow: 'auto'
    }
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    width: '100%',
    height: '100%'
  },
  mapContainer: {
    height: '100%',
    '&:last-child': {
      height: '100%'
    }
  },
  allLocationsNotice: {
    textAlign: 'center',
    color: theme.palette.grays.dark
  },
  codesPanelContainer: {
    width: '100%'
  },
  codesPanel: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    height: '100%'
  },
  panelHeader: {
    position: 'sticky',
    backgroundColor: theme.palette.background.default,
    zIndex: 100,
    top: 0,
    padding: theme.spacing(3, 3, 0, 3),
    display: 'flex',
    alignItems: 'center'
  },
  panelBody: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  codeLabel: {
    ...theme.typography.body2,
    textTransform: 'uppercase'
  },
  servicedLabel: {
    color: theme.palette.warnings.danger
  },
  isServicedLabel: {
    color: theme.palette.warnings.success
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 3),
    marginBottom: theme.spacing(1)
  },
  listItemActive: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grays.lightest
    }
  }
})
