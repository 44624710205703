import { BoxShadow } from 'constants/styles'

const jssStyles = theme => ({
  errorText: {
    color: 'orange',
    marginTop: theme.spacing(1)
  },
  autocompleteGroupUl: {
    color: 'red'
  },
  autocompletePaper: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    margin: 0
  },
  autocompleteOption: {
    ...theme.typography.body1,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent'
    },
    '&[data-focus="true"]': {
      backgroundColor: 'transparent'
    },
    '&:hover': {
      backgroundColor: theme.palette.grays.light
    }
  },
  autocompleteListbox: {
    padding: 0,
    '& ul': {
      margin: 0
    },
    '& .MuiListSubheader-root': {
      ...theme.typography.caption,
      padding: theme.spacing(2.5, 2, 0)
    }
  },
  autocompleteInputRoot: {
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: theme.spacing(0.5)
    }
  },
  popperContainer: {
    '& > div': {
      boxShadow: BoxShadow,
      marginTop: theme.spacing(2),
      borderRadius: 5,
      backgroundColor: theme.palette.background.default
    }
  },
  inputBox: {
    margin: 0
  }
})

export default jssStyles
