import { BoxShadow, MINIMAL_LABEL } from 'constants/styles'

const jssStyles = theme => ({
  label: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(0.5)
  },
  labelError: {
    color: theme.palette.warnings.danger
  },
  menuPaper: {
    background: theme.palette.background.paper,
    borderRadius: 5
  },
  minimalPaper: {
    background: theme.palette.background.default,
    boxShadow: BoxShadow,
    borderRadius: 5
  },
  disabled: {
    opacity: 0.65
  },
  multipleMenuList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  multipleMenuItemRoot: {
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    margin: theme.spacing(1),
    lineHeight: '16px',
    padding: '12px 16px',
    borderRadius: '5px',
    width: '280px',
    '&:hover': {
      backgroundColor: theme.palette.grays.light
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.blues.neue
    }
  },
  input: {
    background: theme.palette.background.default,
    color: theme.palette.grays.dark,
    borderRadius: 5
  },
  centeredInput: {
    padding: '12px'
  },
  centeredInputDense: {
    padding: '12px !important'
  },
  icon: {
    fill: theme.palette.grays.dark,
    fontSize: 12
  },
  selectIconContainer: {
    display: 'inline',
    marginLeft: 'auto',
    paddingLeft: theme.spacing(4)
  },
  minimal: {
    '& .MuiSelect-root': {
      background: theme.palette.background.default,
      borderRadius: 5,
      fontWeight: 200,
      paddingRight: '32px !important',
      paddingTop: '4px !important',
      minWidth: 120,

      '& div': {
        display: 'none'
      }
    },
    '& .MuiSelect-icon': { top: 'calc(50% - 9px)', height: 18 },
    '& .MuiInputLabel-outlined': {
      fontWeight: 400,
      transform: 'translate(12px, 4px) scale(1)',
      fontSize: 14
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense.MuiInputLabel-shrink': {
      transform: 'translate(12px, -12px) scale(1) !important',
      marginBottom: theme.spacing(1),
      ...MINIMAL_LABEL(theme)
    },
    '& $label': {
      ...MINIMAL_LABEL(theme)
    },
    '& fieldset': {
      border: 'none'
    }
  },
  inverted: {
    '& .MuiSelect-root': {
      background: 'black  !important',
      border: 'none',
      minWidth: 64,
      color: 'white'
    },
    '& .MuiInputLabel-outlined': {
      color: 'white'
    },
    '& .MuiSelect-icon': { fill: 'white' },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense.MuiInputLabel-shrink': {
      transform: 'translate(12px, -8px) scale(1) !important',
      color: 'white'
    },
    '& $label': {
      color: 'white'
    }
  },
  checkMarkIcon: {
    marginRight: 0
  },
  selectRoot: {},
  skeletonText: {
    marginTop: -16
  }
})

export default jssStyles
