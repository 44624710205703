const jssStyles = theme => ({
  isNew: {
    backgroundColor: theme.palette.blues.fast,
    color: 'white'
  },
  tag: {
    borderRadius: 5,
    '& .MuiChip-label': {
      fontSize: 14
    }
  },
  icon: {
    stroke: 'white'
  }
})

export default jssStyles
