import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import styles from './PaneStyles'

const formatPercentage = (percentage) => Math.round(percentage)

const AnalyticsPaneRow = ({ classes, notification }) => {
  const analytics = notification?.user_notification_counts
  return (
    <TableRow className={classes.row}>
      <TableCell>{notification?.title}</TableCell>
      <TableCell>{analytics?.sent} Users</TableCell>
      <TableCell>{formatPercentage(analytics?.seen / analytics?.sent)}% ({analytics?.seen})</TableCell>
      <TableCell>{formatPercentage(analytics?.clicked / analytics?.sent)}% ({analytics?.clicked})</TableCell>
      <TableCell>{formatPercentage(analytics?.deleted / analytics?.sent)}% ({analytics?.deleted})</TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(AnalyticsPaneRow)
