import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useForm, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import { SIZE } from 'constants/enums'
import { LIST_PATHS } from 'constants/queryParams'
import { URL } from 'constants/navigation'
import { useQuery, useAdminAccessoryOptions } from 'hooks'

import Header from 'components/Header'
import Layout from 'components/Layout'
import DataTable from 'components/DataTable'
import SearchInput from 'components/SearchInput'
import Select, { SelectField } from 'components/Select'
import GlanceTile from 'components/GlanceTile'
import Dialog from 'components/Dialog'
import TextField from 'components/TextField'

import AccessoryOptionsItem from './AccessoryOptionsItem'
import styles from './AccessoryOptionsStyles'

const TEST_ID = 'accessory'

const columns = [
  {
    title: 'Option',
    sortKeys: [LIST_PATHS.ACCESSORY_OPTIONS_NAME]
  },
  {
    title: 'Usage',
    sortKeys: [LIST_PATHS.ACCESSORY_OPTIONS_USAGE]
  },
  {
    title: 'Created On',
    sortKeys: [LIST_PATHS.ACCESSORY_OPTIONS_CREATED_AT]
  },
  {
    title: 'Status'
  },
  { title: '' }
]

const FIELDS = {
  name: 'name',
  active: 'active'
}

const accessoryOptionsSchema = {
  [FIELDS.name]: yup.string().required(),
  [FIELDS.active]: yup.bool().required()
}

const accessoryOptionsDefaults = {
  [FIELDS.name]: '',
  [FIELDS.active]: true
}

const AccessoryOptions = ({ classes }) => {
  const { query, handleQueryChange, updateQuery } = useQuery(false)
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [itemToEdit, setItemToEdit] = useState(null)

  const {
    accessoryOptions,
    hasAccessoryOptions,
    hasAccessoryOptionsNext,
    isLoadingAccessoryOptions,
    accessoryOptionsCount,
    listAccessoryOptionsNext,
    createAccessoryOption,
    updateAccessoryOption,
    activeAccessoryOptionsCount
  } = useAdminAccessoryOptions(query)

  const formContext = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: accessoryOptionsDefaults,
    resolver: yupResolver(yup.object().shape(accessoryOptionsSchema))
  })

  const { register, handleSubmit, errors, formState, reset } = formContext

  const foldableActions = (
    <SearchInput
      name="search"
      placeholder="Search..."
      value={query.search}
      onChange={handleQueryChange}
      dataTest={`${TEST_ID}-search`}
    />
  )

  const actions = (
    <Button
      color="primary"
      variant="contained"
      size={SIZE.medium}
      onClick={() => setOpenCreateDialog(true)}
      adaptive
      label="new Code"
      data-test={`${TEST_ID}-new-item`}
      startIcon={<AddIcon />}
    >
      New Accessory Option
    </Button>
  )

  const filters = (
    <Select
      minimal
      name={LIST_PATHS.ACCESSORY_OPTIONS_STATUS}
      innerLabel="Status"
      value={query[LIST_PATHS.ACCESSORY_OPTIONS_STATUS] || ''}
      items={[
        { label: 'All', value: '' },
        { label: 'Active', value: 'true' },
        { label: 'Inactive', value: 'false' }
      ]}
      onChange={handleQueryChange}
      dataTest={`${TEST_ID}-select-${LIST_PATHS.ACCESSORY_OPTIONS_STATUS}`}
    />
  )

  const summarizers = (
    <>
      <GlanceTile minimal label="Total Options" value={accessoryOptionsCount} />
      <GlanceTile
        minimal
        label="Active Options"
        value={activeAccessoryOptionsCount}
      />
    </>
  )

  const handleSubmitForm = async payload => {
    await (itemToEdit
      ? updateAccessoryOption({ id: itemToEdit.id, payload })
      : createAccessoryOption(payload))
    setOpenCreateDialog(false)
    updateQuery(query)
  }

  const handleSetActive = async (id, active) => {
    await updateAccessoryOption({ id, payload: { active } })
    updateQuery(query)
  }

  const handleEdit = item => {
    reset(item)
    setItemToEdit(item)
    setOpenCreateDialog(true)
  }

  useEffect(() => {
    if (openCreateDialog) return
    setItemToEdit(null)
    reset(accessoryOptionsDefaults)
  }, [openCreateDialog])

  return (
    <Layout id="accessory_options-list">
      <Header
        title="Accessory Options"
        breadcrumbs={[
          {
            title: 'Back Of House',
            link: URL.ADMIN_BOH
          }
        ]}
        foldableActions={foldableActions}
        actions={actions}
        filters={filters}
        summarizers={summarizers}
      />
      <DataTable
        columns={columns}
        message={accessoryOptions?.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingAccessoryOptions}
        hasListNext={hasAccessoryOptionsNext}
        listNext={listAccessoryOptionsNext}
        query={query}
        updateQuery={updateQuery}
        id={TEST_ID}
      >
        {hasAccessoryOptions &&
          accessoryOptions.map(accessoryOption => (
            <AccessoryOptionsItem
              accessoryOption={accessoryOption}
              key={accessoryOption.id}
              onSetActive={active =>
                handleSetActive(accessoryOption.id, active)
              }
              onClickEdit={handleEdit}
              dataTest={TEST_ID}
            />
          ))}
      </DataTable>
      <FormProvider {...formContext}>
        {openCreateDialog && (
          <Dialog
            classes={{
              subtitle: classes.subtitle
            }}
            title={
              itemToEdit ? 'Edit Accessory Option.' : 'Create Accessory Option.'
            }
            subtitle={
              itemToEdit
                ? 'Update accessory option name and status'
                : 'Give the accessory option a name, and choose the active status.'
            }
            open={openCreateDialog}
            onCancel={() => setOpenCreateDialog(false)}
            onConfirm={handleSubmit(handleSubmitForm)}
            confirmText={itemToEdit ? 'Update' : 'Create'}
            confirmDisabled={!formState.isDirty || !formState.isValid}
            dataTest={TEST_ID}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textField}
                  name={FIELDS.name}
                  placeholder="Option name"
                  inputRef={register}
                  fullWidth
                  error={Boolean(errors[FIELDS.name]?.message)}
                  helperText={errors[FIELDS.name]?.message}
                  dataTest={`${TEST_ID}-${FIELDS.name}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectField
                  name={FIELDS.active}
                  items={[
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false }
                  ]}
                  fullWidth
                  error={Boolean(errors[FIELDS.active]?.message)}
                  helperText={errors[FIELDS.active]?.message}
                />
              </Grid>
            </Grid>
          </Dialog>
        )}
      </FormProvider>
    </Layout>
  )
}

AccessoryOptions.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AccessoryOptions)
