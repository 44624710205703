import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  useAdminPrinters,
  useAdminOrders,
  useAlerts,
  useAdminVariant,
  useLoaders
} from 'hooks'

import { PRINTER_TYPES } from 'constants/enums'

import PrinterShow from './PrinterShow'
import PrinterForm from './PrinterForm'

import styles from './PrinterConfigStyles'

const MODES = { FORM: 'form', SHOW: 'show' }

const PrinterConfig = ({ classes, locationId }) => {
  const [selectedPrinter, setSelectedPrinter] = useState()
  const [mode, setMode] = useState(MODES.SHOW)

  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    printers,
    createPrinter,
    updatePrinter,
    destroyPrinter,
    isLoading,
    isFetching
  } = useAdminPrinters(locationId, selectedPrinter?.id)

  const { printLabels } = useAdminVariant(false)

  const { printLabel } = useAdminOrders({})

  const handleEditClick = newPrinter => {
    setSelectedPrinter(newPrinter)
    setMode(MODES.FORM)
  }
  const handleFormCancel = () => {
    setMode(MODES.SHOW)
    setSelectedPrinter()
  }
  const handleDelete = () => {
    destroyPrinter()
    handleFormCancel()
  }
  const handleTest = async () => {
    if (selectedPrinter.type === PRINTER_TYPES.LABEL) {
      // 7988, is an Order available on both staging and prod
      // (ThomasHintz): would be nicer to have a special test label
      const res = await printLabel(7988, selectedPrinter.id)
      if (!res.success) {
        showAlertError(
          `Error printing: ${res?.response?.data?.message || 'Unknown Error'}`
        )
      }
    } else if (selectedPrinter.type === PRINTER_TYPES.BARCODE) {
      try {
        showLoading()
        const res = await printLabels({
          sku: 'COFFEE-MANUFACTORY-04-LATIN-AMERICA', // available on both staging and prod
          locationId: locationId,
          quantity: 1,
          printerId: selectedPrinter.id
        })
        if (res.success) {
          showAlertSuccess('Printing barcode')
        } else {
          showAlertError(
            `Error printing: ${res?.response?.data?.message || 'Unknown Error'}`
          )
        }
      } finally {
        hideLoading()
      }
    }
  }
  const handleFormSuccess = newPrinter => {
    selectedPrinter ? updatePrinter(newPrinter) : createPrinter(newPrinter)
    handleFormCancel()
  }

  return (
    <Box mt={2}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="body2">
            Printers: {`${printers?.length || 'No printers'}`}
          </Typography>
          <Box mr={2} />
          {(isLoading || isFetching) && <CircularProgress size={16} />}
        </Box>
        {mode === MODES.SHOW && (
          <Typography
            onClick={() => setMode(MODES.FORM)}
            className={classes.add}
          >
            Add
          </Typography>
        )}
      </Box>
      {mode === MODES.SHOW ? (
        <PrinterShow printers={printers} onEditClick={handleEditClick} />
      ) : (
        <PrinterForm
          printer={selectedPrinter}
          onCancel={handleFormCancel}
          onSuccess={handleFormSuccess}
          onDelete={handleDelete}
          onTest={handleTest}
        />
      )}
    </Box>
  )
}

PrinterConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired
}

export default withStyles(styles)(PrinterConfig)
