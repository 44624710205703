const jssStyles = theme => ({
  imageCell: {
    paddingRight: 0
  },
  nameCell: {
    paddingLeft: theme.spacing(1)
  },
  avatar: {
    paddingLeft: 0,
    paddingRight: 0
  }
})

export default jssStyles
