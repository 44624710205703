import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { Controller, useFormContext } from 'react-hook-form'
import MdEditor from 'react-markdown-editor-lite'

import 'react-markdown-editor-lite/lib/index.css'
import './editorOverride.css'

import styles from './MarkdownEditorStyles'
import MarkdownIt from 'markdown-it'

const mdParser = new MarkdownIt()

const MarkdownEditorRaw = ({
  classes,
  name,
  value,
  placeholder,
  config,
  onChange,
  height,
  error,
  characterLimit
}) => (
  <Box width="100%" height={height} className={classes.root}>
    <MdEditor
      name={name}
      value={value}
      placeholder={placeholder ?? ''}
      plugins={[
        'font-bold',
        'font-italic',
        'block-quote',
        'list-unordered',
        'list-ordered',
        'link',
        'logger'
      ]}
      config={{
        fullScreen: false,
        view: {
          menu: true,
          md: true
        },
        ...config
      }}
      renderHTML={text => mdParser.render(text)}
      onChange={onChange}
    />
    <Box className={classes.bottomOverlay}>
      {error && (
        <Typography variant="body1" color="error" style={{ fontSize: 14 }}>
          {error.toUpperCase()}
        </Typography>
      )}

      {characterLimit && value && (
        <Typography
          variant="body1"
          color={value.length > characterLimit ? 'error' : 'textSecondary'}
        >
          {value?.length} / {characterLimit}
        </Typography>
      )}
    </Box>
  </Box>
)

MarkdownEditorRaw.defaultProps = {
  height: 500
}

const sharedProps = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  config: PropTypes.object,
  height: PropTypes.number,
  characterLimit: PropTypes.number
}

MarkdownEditorRaw.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  ...sharedProps
}

const MarkdownEditor = withStyles(styles)(MarkdownEditorRaw)

const MarkdownEditorControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()

  return (
    <Controller
      name={name}
      render={({ onChange, value }) => (
        <MarkdownEditor
          name={name}
          value={value}
          onChange={({ text }) => onChange(text)}
          error={errors[name]?.message}
          {...rest}
        />
      )}
    />
  )
}

MarkdownEditorControlled.propTypes = {
  ...sharedProps
}

export { MarkdownEditor as default, MarkdownEditorControlled }
