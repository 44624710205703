import React from 'react'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import moment from 'moment-timezone'

import Zipcodes from 'zipcodes'

import { SERVER_ENV } from 'lib/config'
import { DAY_STRING_FORMAT, DEFAULT_TIMEZONE } from 'constants/general'
import { ENVIRONMENT_INSTANCES } from 'constants/enums'
import citiesByStatesUSA from 'constants/citiesByStatesUSA'

export const trimStringAndAppend = (str = '', trimLen = 0, append = '...') =>
  str.length > trimLen
    ? `${str.substring(0, trimLen).trim()}${append}`
    : str.trim()

export const centsToDollars = (val = 0) => parseFloat(val / 100).toFixed(2)
export const dollarsToCents = (val = 0) => Math.round(parseFloat(val) * 100)

// can be used in an async func: await delay(sec)
export const delay = sec => new Promise(res => setTimeout(res, sec * 1000))

export const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const setOptions = (items, key, value) => {
  if (!items) return null
  let options = []
  let list = []
  list = items
  list.forEach((item, idx) => {
    let label = item[value]
    if (Array.isArray(value)) {
      label = value.map(v => eval(`item.${v}`)).join(' - ')
    }
    return options.push({ value: item[key], label: label })
  })
  return options
}

export const buildOptions = (items, key, value) => {
  if (!items) return null
  let options = []
  let list = []
  if (items.data) {
    list = items.data
  } else {
    list = items
  }
  list.forEach((item, idx) => {
    let label = item[value]
    if (Array.isArray(value)) {
      label = value.map(v => eval(`item.${v}`)).join(' - ')
    }
    return options.push({ value: item[key], label: label })
  })
  return options
}

export const formatDigits = amount => {
  let decimalCount = 0
  let decimal = '.'
  let thousands = ','
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    let formatted =
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    return formatted
  } catch (e) {
    console.error(e)
  }
}

export const formatNumberWithPadStart = (
  value,
  targetLength = 2,
  padString = '0'
) => value.toString().padStart(targetLength, padString)

export const formatCurrency = amount =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(Number(amount))

export const formatNumber = count => {
  let value = parseInt(count)
  let precision = 0
  let scale = ''

  if (value >= 1000000) {
    precision = 0
    scale = 'M'
    value = value / 1000000
  } else if (value >= 10000) {
    precision = 0
    scale = 'k'
    value = value / 1000
  } else if (value >= 1000) {
    precision = 1
    scale = 'k'
    value = value / 1000
  }
  value = value.toFixed(precision)
  return `${value}${scale}`
}

export const syntheticEvent = (value, name, type = 'text') => ({
  target: {
    value,
    name,
    type
  }
})

export const truncate = (text, len) => {
  if (!text) {
    return
  }
  let truncated = text.slice(0, len)
  if (text.length > len) {
    truncated += '...'
  }
  return truncated
}

export const splitStringAt = (slicable, ...indices) =>
  [0, ...indices].map((n, i, m) => slicable.slice(n, m[i + 1]))

export const generateToken = (len = 5) => {
  let token = [...Array(len)]
    .map(i => (~~(Math.random() * 36)).toString(36))
    .join('')
    .toUpperCase()
  return token
}

export const isPersisted = resp => {
  return resp && resp.data && resp.data.id
}

export const isLoaded = resp => {
  return resp && resp.data ? true : false
}

export const isEmpty = resources => {
  return !(resources && resources.data && resources.data.length > 0)
}

export const isPaginated = resources => {
  return (
    resources &&
    resources.meta &&
    resources.meta.page &&
    resources.meta.perPage &&
    resources.meta.totalCount
  )
}

export const formatPhoneNumber = phoneNumberString => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (!match) return phoneNumberString

  const intlCode = match[1] ? '+1 ' : ''
  return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
}

export const isProductionInstance = () =>
  SERVER_ENV === ENVIRONMENT_INSTANCES.production

export const zipcodeLookupByQuery = query => {
  if (!query) {
    return []
  }

  const isNumeric = /^\d+$/.test(query)

  if (isNumeric) {
    const codes = Object.keys(Zipcodes.codes)
      .filter(c => c.startsWith(query))
      .map(v => Zipcodes.codes[v])

    return codes
  }

  const citiesFiltered = Object.keys(citiesByStatesUSA).reduce((acc, state) => {
    const citiesByState = citiesByStatesUSA[state]

    const lowerCaseQuery = query.toLowerCase()

    const citiesFound = citiesByState
      .filter(city => city.toLowerCase().includes(lowerCaseQuery))
      .map(city => ({
        city,
        state
      }))

    return acc.concat([...citiesFound])
  }, [])

  const options = flatten(
    citiesFiltered.map(c => Zipcodes.lookupByName(c.city, c.state))
  )

  return options
}

export const getValueAtBreakpoint = (
  breakpoints,
  width,
  { xs, sm, md, lg, xl }
) => {
  let result = xs
  result = width > breakpoints.sm && sm ? sm : result
  result = width > breakpoints.md && md ? md : result
  result = width > breakpoints.lg && lg ? lg : result
  result = width > breakpoints.xl && xl ? xl : result

  return result
}

export const getBrandName = brand => brand?.name

export const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }
  return value
}

// Replicate in Firefox and Safari, what Chrome has as Event.path
export const getDOMElementsPathToRoot = elem => {
  const path = []
  let currentElem = elem
  while (currentElem) {
    path.push(currentElem)
    currentElem = currentElem.parentElement
  }
  if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
    path.push(document)
  if (path.indexOf(window) === -1) path.push(window)
  return path
}

export const isMiddleButtonClicked = e => e?.button === 1

// Return true if two arrays have the same elements in any order.
export const haveSameArrayElements = (arr1, arr2) => {
  if (
    !Array.isArray(arr1) ||
    !Array.isArray(arr2) ||
    arr1?.length !== arr2?.length
  ) {
    return false
  }
  const set1 = new Set(arr1)
  return arr2.every(e => set1.has(e))
}

export const addOpacity = (hex, opacity) => {
  const rgb = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))
    .concat(opacity)
  return `rgba(${rgb.join(', ')})`
}

export const formatAddress = obj =>
  compact([
    obj?.address1,
    obj?.address2,
    `${compact([obj?.city, obj?.state_code])
      .join(', ')
      .trim()} ${obj?.zipcode ?? ''}`
  ])

export const renderAddress = obj =>
  formatAddress(obj).map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))

export const getDatesBetweenDates = (startDate, endDate) => {
  const dateArray = []
  const stopDate = moment(endDate)
  let currentDate = moment(startDate)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format(DAY_STRING_FORMAT))
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}

export const parseDateIgnoreTZ = date => {
  return moment(date, 'YYYY-MM-DD HH:mm:ss')
}

export const isAtBottom = (element, threshold = 100) =>
  Math.trunc(element.scrollTop) >=
  element.scrollHeight - element.clientHeight - threshold

export const formatDateToLocalTimezone = (
  date,
  timezone = DEFAULT_TIMEZONE,
  format = 'L'
) =>
  moment(date)
    .tz(timezone)
    .format(format)

// returns true if input is of type number or can be coerced to an Int
export const isNumber = x =>
  typeof x === 'number' ||
  (typeof parseInt(x) === 'number' && !isNaN(parseInt(x)))
