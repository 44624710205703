const jssStyles = theme => ({
  textarea: {
    '& .MuiInputBase-root': {
      alignItems: 'flex-start',
      height: 120
    }
  },
  addButton: {
    height: 33,
    margin: theme.spacing(0, 1, 0, 0)
  },
  textField: {
    margin: 0,
    '& .MuiSelect-selectMenu': {
      padding: theme.spacing(1.5, 1.5)
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.5, 1.5)
    }
  },
  darkstoreSubtitle: {
    ...theme.typography.overline,
    textTransform: 'none',
    color: theme.palette.grays.dark
  }
})

export default jssStyles
