import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'

import Autocomplete from 'components/Autocomplete'

import styles from './AutocompleteInputBoxStyles'

const AutocompleteInputBox = ({
  classes,
  placeholder,
  options,
  noOptionsText,
  getOptionLabel,
  renderOption,
  highlightedLabel,
  highlightedOptions,
  ListboxProps,
  onChange,
  value,
  inputValue,
  loading,
  endAdornment,
  className
}) => {
  const [open, setOpen] = useState(false)

  const handleInputChange = (e, val) => {
    onChange && onChange(val ?? '')
  }

  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}
      options={options}
      open={open}
      loading={loading}
      groupBy={() => 'Results'}
      fullWidth
      className={className}
      placeholder={placeholder}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      freeSolo
      openOnFocus
      renderOption={
        renderOption ??
        (option => (
          <>
            <Box flex={1}>{option}</Box>
            {highlightedOptions.includes(option) && (
              <Box className={classes.highlightedLabel}>{highlightedLabel}</Box>
            )}
          </>
        ))
      }
      endAdornment={endAdornment}
      noOptionsText={noOptionsText}
      getOptionLabel={getOptionLabel}
      ListboxProps={ListboxProps}
    />
  )
}

AutocompleteInputBox.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object)
  ]),
  noOptionsText: PropTypes.node,
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  highlightedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  highlightedOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object)
  ]),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputValue: PropTypes.string,
  loading: PropTypes.bool,
  endAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  groupBy: PropTypes.func,
  className: PropTypes.string,
  ListboxProps: PropTypes.shape({
    headerHeight: PropTypes.number,
    itemHeight: PropTypes.number
  })
}

export default withStyles(styles)(AutocompleteInputBox)
