import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import * as propTypes from 'constants/propTypes'
import { PUBLISHED_STATUS_VARIANT, TOOLTIP_PLACEMENT } from 'constants/enums'

import Tooltip from 'components/Tooltip'

import styles from './PublishStatusIndicatorStyles'

const PublishStatusIndicator = ({
  classes,
  variant,
  onClick,
  tooltipMessage,
  size
}) => {
  const icon = (
    <svg
      className={classNames({
        [classes.active]: variant === PUBLISHED_STATUS_VARIANT.active,
        [classes.inactive]: variant === PUBLISHED_STATUS_VARIANT.inactive,
        [classes.clickable]: Boolean(onClick)
      })}
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4994 9.03234H10.2564C10.2204 9.03234 10.1913 9.00319 10.1913 8.96724V5.59049C10.1913 5.55454 10.1621 5.52539 10.1261 5.52539C10.1014 5.52539 10.0788 5.53944 10.0678 5.56164L7.4423 10.8725C7.42637 10.9047 7.43958 10.9438 7.47181 10.9597C7.48078 10.9641 7.49066 10.9664 7.50067 10.9664H9.74369C9.77964 10.9664 9.80879 10.9956 9.80879 11.0316V14.4083C9.80879 14.4443 9.83794 14.4734 9.8739 14.4734C9.89866 14.4734 9.92128 14.4593 9.93226 14.4371L12.5577 9.1263C12.5737 9.09406 12.5605 9.05502 12.5282 9.03908C12.5193 9.03465 12.5094 9.03234 12.4994 9.03234Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17.8125C14.3147 17.8125 17.8125 14.3147 17.8125 10C17.8125 5.68528 14.3147 2.1875 10 2.1875C5.68528 2.1875 2.1875 5.68528 2.1875 10C2.1875 14.3147 5.68528 17.8125 10 17.8125ZM12.4994 9.03295H10.2563C10.2204 9.03295 10.1912 9.0038 10.1912 8.96784V5.5911C10.1912 5.55514 10.1621 5.526 10.1261 5.526C10.1014 5.526 10.0787 5.54005 10.0678 5.56225L7.44228 10.8731C7.42635 10.9053 7.43956 10.9444 7.47179 10.9603C7.48076 10.9647 7.49064 10.9671 7.50064 10.9671H9.74367C9.77962 10.9671 9.80877 10.9962 9.80877 11.0322V14.4089C9.80877 14.4449 9.83792 14.474 9.87387 14.474C9.89864 14.474 9.92126 14.46 9.93224 14.4378L12.5577 9.1269C12.5737 9.09467 12.5604 9.05562 12.5282 9.03969C12.5192 9.03525 12.5094 9.03295 12.4994 9.03295Z"
      />
    </svg>
  )

  return tooltipMessage ? (
    <Tooltip title={tooltipMessage} placement={TOOLTIP_PLACEMENT.bottom}>
      {icon}
    </Tooltip>
  ) : (
    icon
  )
}

PublishStatusIndicator.defaultProps = {
  variant: PUBLISHED_STATUS_VARIANT,
  size: 24
}

PublishStatusIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: propTypes.publishedStatusVariants,
  onClick: PropTypes.func,
  tooltipMessage: PropTypes.string,
  size: PropTypes.number
}

export default withStyles(styles)(PublishStatusIndicator)
