const jssStyles = theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: 60,
    alignItems: 'center',
    border: `1px solid ${theme.palette.grays.med}`,
    display: 'flex',
    borderRadius: 5,
    padding: theme.spacing(2.5, 2)
  },
  label: {
    ...theme.typography.subtitle1,
    fontWeight: 400,
    flex: 1
  }
})

export default jssStyles
