import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useDropzone } from 'react-dropzone'
import { withStyles } from '@material-ui/core/styles'

import { Box, Typography, IconButton } from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Replay'
import ReorderIcon from 'icons/ReorderIcon'

import { SIZE } from 'constants/enums'
import { getProductImageUrls } from 'utils/products'
import {
  useAlerts,
  useLoaders,
  useMediaQuery,
  useConditionalEffect
} from 'hooks'

import {
  PRODUCT_IMAGE_WIDTH_PX,
  PRODUCT_IMAGE_HEIGHT_PX
} from 'constants/products'

import Button from 'components/Button'
import { useControlledForm } from 'components/ControlledForm'

import styles from './MultiImageDropzoneStyles'

// prevalidate size vs having to clear all, or implement clear by single item here not in designs.
const validateImage = async file =>
  new Promise((resolve, reject) => {
    try {
      const img = new Image()
      img.src = URL.createObjectURL(file)
      img.onload = () => {
        if (
          img.width === PRODUCT_IMAGE_WIDTH_PX &&
          img.height === PRODUCT_IMAGE_HEIGHT_PX
        ) {
          resolve(true)
        } else {
          resolve(false)
        }
        URL.revokeObjectURL(img.src)
      }
      img.onerror = () => {
        resolve(false)
        URL.revokeObjectURL(img.src)
      }
    } catch (e) {
      resolve(false)
    }
  })

const getValidImageDrops = async files =>
  Promise.all(files.map(async file => (await validateImage(file)) && file))

const DEFAULT_IMAGE_ACCEPTS = 'image/png, image/jpg, image/jpeg, image/gif'

const MultiImageDropzone = ({
  classes,
  className,
  name,
  defaultUrls,
  onEditButtonClick,
  dropzoneDisabled,
  editName,
  emptyImagesPlaceholderText,
  autoUpload = false,
  handleUploadImages
}) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertGeneral } = useAlerts()
  const {
    formContext: {
      register,
      unregister,
      setValue,
      getValues,
      watch,
      errors,
      clearErrors,
      formState: { dirtyFields }
    }
  } = useControlledForm()

  const { isMobileScreen } = useMediaQuery()

  useConditionalEffect(() => {
    if (name) {
      register(name)
      return () => {
        unregister(name)
      }
    }
  }, [register, unregister, name])

  const { [name]: currentImages } = watch(
    [name].filter(n => n && typeof n === 'string')
  )

  const editImages = editName ? getValues(editName) : []
  const previewUrls = useMemo(() => {
    const existingImages =
      editImages?.length || dirtyFields?.[editName]
        ? editImages?.map(img => img.url || img.src)
        : defaultUrls

    return currentImages
      ? [
          ...existingImages,
          ...currentImages?.map(img => URL.createObjectURL(img))
        ]
      : existingImages
  }, [editImages, currentImages, defaultUrls, dirtyFields, editName])

  const shouldDropzoneDisable =
    (dirtyFields?.[editName] && !autoUpload) || dropzoneDisabled

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      showLoading()
      const existingImages = getValues(name) ?? []
      const validatedFiles = (await getValidImageDrops(acceptedFiles)).filter(
        Boolean
      )

      const fileDiff = acceptedFiles?.length - validatedFiles?.length

      if (fileDiff) {
        showAlertError(
          `${fileDiff} image${
            fileDiff > 1 ? 's are' : ' is'
          } not ${PRODUCT_IMAGE_WIDTH_PX} x ${PRODUCT_IMAGE_HEIGHT_PX}. Please resize and upload again.`
        )
      }

      // autoUpload allows the uploading of photos automatically to a variant or product
      // as of now. to use you must pass in a `handleUploadImages` function which takes
      // files, and a callback function. we use the callback function to get the latest
      // product, and the type of images we are getting (see getProductImageUrls) to get
      // the latest images.
      // we then setValue on the editName (the images that are being reordered or selected)
      // to the currentImages that are existing in the react-hook-form state which will
      // preserve any ordering or selection, and then we filter out those existing images
      // from the latestProduct response images, and then add the remaining (newly uploaded)
      // ones to the end of the array, thus preserving and updating the existing images form state!
      if (autoUpload) {
        handleUploadImages
          ? await handleUploadImages(validatedFiles, (latestProduct, type) => {
              const currentImages = getValues(editName) ?? []
              setValue(editName, [
                ...(currentImages ?? []),
                ...(getProductImageUrls(latestProduct, type).filter(
                  ({ asset_id }) =>
                    !currentImages
                      ?.map(({ asset_id }) => asset_id)
                      .includes(asset_id)
                ) ?? [])
              ])
            })
          : console.log('handleUploadImages not defined.')
      } else {
        setValue(name, [...existingImages, ...validatedFiles], {
          shouldDirty: true
        })
      }

      hideLoading()

      if (!isEmpty(fileRejections)) {
        showAlertError(
          fileRejections.flatMap(f => f.errors.map(e => e.message)).join(', ')
        )
      }
    },
    [setValue, name]
  )

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
    accept: DEFAULT_IMAGE_ACCEPTS,
    multiple: true
  })

  const rootProps = getRootProps()
  const rootPropsNoContainerClick = {
    ...rootProps,
    onClick: () =>
      console.info(
        `click disabled on main container props, but available still in
        the rootProps (whereas "onClick: false" in useDropzone removes it fully)`
      )
  }

  const handleClear = e => {
    setValue(name, [], { shouldDirty: true })
    clearErrors(name)

    e.stopPropagation()
  }

  const handleEdit = e => {
    rootProps.onClick(e)
    e.stopPropagation()
  }

  const dropzoneInputProps = shouldDropzoneDisable ? {} : getInputProps()

  return (
    <Box
      className={classNames({
        [classes.root]: true,
        [className]: Boolean(className)
      })}
      {...rootPropsNoContainerClick}
    >
      <input id={name} {...dropzoneInputProps} style={{ display: 'none' }} />
      <Box
        height={isMobileScreen ? '100%' : 216}
        minHeight={216}
        pr={2}
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection={isMobileScreen ? 'column' : 'row'}
        className={classNames({
          [classes.imageContainer]: true,
          [classes.mobileImageContainer]: isMobileScreen
        })}
      >
        {previewUrls.length ? (
          <>
            <Box ml={2}>
              {previewUrls.length >= 1 && (
                <img
                  className={classNames({
                    [classes.imageLarge]: true,
                    [classes.imageShared]: true
                  })}
                  src={previewUrls[0]}
                  alt={name}
                />
              )}
            </Box>

            {previewUrls.length > 1 && (
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                flexWrap="wrap"
                px={isMobileScreen ? 2 : 0}
                className={classes.scrollablePreview}
              >
                {previewUrls.map(
                  (url, i) =>
                    i !== 0 && (
                      <img
                        key={i}
                        className={classNames({
                          [classes.imageSmall]: true,
                          [classes.imageShared]: true
                        })}
                        src={url}
                        alt={name}
                      />
                    )
                )}
              </Box>
            )}
          </>
        ) : (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1">
              {emptyImagesPlaceholderText}
            </Typography>
          </Box>
        )}
      </Box>

      {errors?.[name] && (
        <Box className={classes.errorBox}>
          <Typography variant="body1">{errors?.[name].message}</Typography>
          <IconButton onClick={handleClear}>
            <ReplayIcon />
          </IconButton>
        </Box>
      )}

      <div className={classes.divider} />

      <Box m={2} display="flex" alignItems="center" justifyContent="flex-start">
        {!dropzoneDisabled && (
          <Button
            size={SIZE.small}
            className={classes.blackButton}
            onClick={e =>
              shouldDropzoneDisable
                ? showAlertGeneral(
                    'Please save your image edits before uploading new ones!'
                  )
                : handleEdit(e)
            }
          >
            Add Files
          </Button>
        )}
        {onEditButtonClick && (editImages?.length || !defaultUrls.length) ? (
          <Button
            onClick={() =>
              currentImages?.length
                ? showAlertGeneral(
                    'Save image uploads before reordering or removing'
                  )
                : onEditButtonClick()
            }
            className={classes.editButton}
            size={SIZE.small}
          >
            <ReorderIcon color="#000" />
          </Button>
        ) : null}
        {!shouldDropzoneDisable && (
          <Typography variant="body1" className={classes.muted}>
            or drag and drop files to upload.
          </Typography>
        )}
      </Box>
    </Box>
  )
}

MultiImageDropzone.defaultProps = {
  accept: DEFAULT_IMAGE_ACCEPTS,
  defaultUrls: [],
  dropzoneDisabled: false,
  editName: '',
  emptyImagesPlaceholderText: 'Loading...'
}

MultiImageDropzone.propTypes = {
  editName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  defaultUrls: PropTypes.arrayOf(PropTypes.string),
  onEditButtonClick: PropTypes.func,
  dropzoneDisabled: PropTypes.bool,
  emptyImagesPlaceholderText: PropTypes.string
}

export default withStyles(styles)(MultiImageDropzone)
