import { useResourceListRQ, useResourceRQ } from 'hooks'
import { promotionsEndpoint } from 'api/endpoints'

const API_RESOURCE_NAME = 'promotion'

const useAdminPromotions = ({ params }) => {
  const {
    isLoading: isLoadingPromotions,
    data: promotions,
    hasData: hasPromotions,
    hasNextPage: hasPromotionsNext,
    count: promotionsCount,
    fetchNextPage: listPromotionsNext
  } = useResourceListRQ({
    endpoint: promotionsEndpoint,
    params
  })

  return {
    isLoadingPromotions,
    promotions,
    hasPromotions,
    hasPromotionsNext,
    promotionsCount,
    listPromotionsNext
  }
}

export default useAdminPromotions
