import React from 'react'
import PropTypes from 'prop-types'

import { MdBlock } from 'react-icons/md'

import { formatCurrency } from 'utils/general'
import { toTitleCase } from 'utils/strings'
import { SIZE } from 'constants/enums'
import { LIGHT_BLUE } from 'constants/styles'

import Diamond from 'icons/DiamondIcon'

import { withStyles } from '@material-ui/core/styles'

import {
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Box
} from '@material-ui/core'

import UserAvatar from 'components/UserAvatar'
import TierBadge from 'components/TierBadge'

import styles from './UserItemStyles'

const DEFAULT_ROLE = 'No Role Set'
const DEFAULT_CODE = 'No Code Set'

const UserItem = ({ classes, user, onClick }) => (
  <TableRow className={classes.row} data-test="user-item">
    <TableCell className={classes.imageCell}>
      <Box display="flex" alignItems="center" justifyContent="center">
        {user?.blocked && <MdBlock color="red" fontSize={26} />}
        <IconButton
          size={SIZE.medium}
          onClick={e => onClick(e, user)}
          onAuxClick={e => onClick(e, user, true)}
          data-test="user-link"
        >
          <UserAvatar
            className={classes.avatar}
            size={SIZE.medium}
            src={user?.image_url}
          />
        </IconButton>
      </Box>
    </TableCell>
    <TableCell className={classes.nameCell}>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Typography variant="body2" data-test="user-item-name">
          {user?.name}
        </Typography>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="row"
          flexWrap="wrap"
          width={60}
        >
          {user.vip && <Diamond fill={LIGHT_BLUE} fontSize={26} />}
        </Box>
      </Box>
    </TableCell>
    <TableCell data-test="user-item-email">
      <Typography variant="body1">
        {user?.email || user?.checkout_email}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography variant="body1" data-test="user-item-role">
        {toTitleCase(user?.role) || DEFAULT_ROLE}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography variant="body1">
        {formatCurrency(user?.credit_balance)}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography variant="body1">
        {user?.referral_code || DEFAULT_CODE}
      </Typography>
    </TableCell>
    <TableCell>
      <TierBadge user={user} />
    </TableCell>
  </TableRow>
)

UserItem.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

export default withStyles(styles)(UserItem)
