import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import AddIcon from '@material-ui/icons/Add'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import {
  useQuery,
  useAdminPromoCodes,
  useLoaders,
  useNavigation,
  useAlerts
} from 'hooks'

import { SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'
import {
  STATUS_TYPE_LABELS,
  METHOD_TYPE_LABELS,
  PROMO_CODE_SERVER_FILTERS
} from 'constants/promoCodes'
import { API_PARAMS, LIST_PATHS, SORT } from 'constants/queryParams'

import { dateRangeToQueryParams } from 'utils/query'
import { useLocationsContext } from 'context'

import Button from 'components/Button'
import Header from 'components/Header'
import Layout from 'components/Layout'
import SearchInput from 'components/SearchInput'
import GlanceTile from 'components/GlanceTile'
import Select from 'components/Select'
import DataTable from 'components/DataTable'
import Dialog from 'components/Dialog'
import DateRangePicker from 'components/DateRangePickerNew'
import AutocompleteSearchUser from 'components/AutocompleteSearchUser'

import SquareCloverIcon from 'icons/SquareCloverIcon'

import PromoCodeItem from './PromoCodeItem'

const promoCodeColumns = [
  {
    title: 'Code',
    sortKeys: [LIST_PATHS.PROMO_CODES_CODE]
  },
  {
    title: 'Created By',
    sortKeys: [LIST_PATHS.PROMO_CODES_AUTHOR]
  },
  { title: 'Usage', sortKeys: [LIST_PATHS.PROMO_CODES_USE_COUNT] },
  { title: 'Start Date', sortKeys: [LIST_PATHS.PROMO_CODES_START_TIME] },
  { title: 'End Date', sortKeys: [LIST_PATHS.PROMO_CODES_END_TIME] },
  {
    title: 'Status'
  },
  { title: '' }
]

const PromoCodesList = ({ classes }) => {
  const { showAlertGeneral } = useAlerts()
  const { locationId, showAllLocationIds } = useLocationsContext()
  const {
    hasPromoCodes,
    isLoadingPromoCodes,
    promoCodes,
    listPromoCodes,
    listPromoCodesNext,
    hasPromoCodesNext,
    updatePromoCode,
    updatePromoCodesLocally,
    deletePromoCode,
    promosMeta
  } = useAdminPromoCodes(null, { locationId })

  const { showLoading, hideLoading } = useLoaders()
  const { go } = useNavigation({})

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [codeToDelete, setCodeToDelete] = useState(null)
  const [filteredUsers, setFilteredUsers] = useState([])

  const { query, handleQueryChange, updateQuery, setQuery } = useQuery(
    listPromoCodes,
    {
      initFallback: {
        [API_PARAMS.SORT]: LIST_PATHS.PROMO_CODES_UPDATED,
        [API_PARAMS.SORT_DIRECTION]: SORT.DESC,
        ...(showAllLocationIds ? {} : { location_id: locationId })
      }
    }
  )

  const toggleCodeActivation = async promoCode => {
    showLoading()
    const updated = await updatePromoCode({
      id: promoCode.id,
      active: !promoCode?.active
    })
    updatePromoCodesLocally([updated])
    hideLoading()
  }

  const handleDeletePromoCode = async () => {
    showLoading()
    await deletePromoCode(codeToDelete)
    updateQuery(query)
    setShowConfirmDeleteModal(false)
    setCodeToDelete(null)
    hideLoading()
  }

  const getDatesFromQueryParams = () =>
    query[PROMO_CODE_SERVER_FILTERS.END_TIME].split(',').map(s => moment(s))

  const dateRangeValue = () =>
    query[PROMO_CODE_SERVER_FILTERS.END_TIME]
      ? getDatesFromQueryParams()
      : [null, null]

  const clearDateRange = listPath => {
    delete query[listPath]
    delete query[`${listPath}.rel`]
  }

  const handleDateRangeChange = ([start, end]) => {
    if (start === null && end === null) {
      clearDateRange(PROMO_CODE_SERVER_FILTERS.END_TIME)
      setQuery({ ...query })
      return
    }

    if (start && end) {
      const params = dateRangeToQueryParams(
        PROMO_CODE_SERVER_FILTERS.END_TIME,
        [start, end]
      )
      setQuery({ ...query, ...params })
    }
  }

  const foldableActions = (
    <>
      <SearchInput
        name="search"
        placeholder="Search promos..."
        value={query.search}
        onChange={handleQueryChange}
        dataTest="search-promos"
      />
    </>
  )

  const actions = (
    <>
      <Button
        color="secondary"
        variant="contained"
        size={SIZE.medium}
        onClick={() =>
          go({}, `${URL.ADMIN_PROMO_CODES}/new`, false, { mode: 'bulk' })
        }
        startIcon={<SquareCloverIcon />}
        adaptive
        label="bulk create promo code button"
      >
        Bulk Create
      </Button>
      <Button
        color="primary"
        variant="contained"
        size={SIZE.medium}
        onClick={() => go({}, `${URL.ADMIN_PROMO_CODES}/new`, false)}
        startIcon={<AddIcon />}
        adaptive
        label="add promo code button"
        dataTest="create-promo-button"
      >
        Code
      </Button>
    </>
  )

  const summarizers = (
    <>
      <GlanceTile
        minimal
        label="Total Promos"
        value={promosMeta?.total_count}
      />
      <GlanceTile
        minimal
        label="Pending Promos"
        value={promosMeta?.summary?.scheduled}
      />
      <GlanceTile
        minimal
        label="Active Promos"
        value={promosMeta?.summary?.active}
      />
    </>
  )

  const filters = (
    <>
      <DateRangePicker
        name={PROMO_CODE_SERVER_FILTERS.END_TIME}
        onChange={handleDateRangeChange}
        value={dateRangeValue()}
        onClearDates={() => handleDateRangeChange([null, null])}
        noDatesOutsideRange
      />
      <AutocompleteSearchUser
        getOptionLabel={opt => opt?.first_name ?? opt?.name}
        name={PROMO_CODE_SERVER_FILTERS.AUTHOR}
        className={classes.userSearch}
        onChange={users => {
          setFilteredUsers(users)
          updateQuery({
            [PROMO_CODE_SERVER_FILTERS.AUTHOR]: users
              .map(user => user.id)
              .join(',')
              .trim()
          })
        }}
        value={filteredUsers}
        withStartAdornment={false}
        placeholder="Creator"
        limitTags={2}
        optWithEmail={false}
        queryParams={{
          [LIST_PATHS.USERS_ROLE]: 'admin'
        }}
      />
      <Select
        minimal
        name={PROMO_CODE_SERVER_FILTERS.CREATION_SOURCE}
        innerLabel="Method"
        value={query[PROMO_CODE_SERVER_FILTERS.CREATION_SOURCE] || ''}
        items={METHOD_TYPE_LABELS}
        onChange={handleQueryChange}
        withNone
        className={classes.select}
        dataTest="promo-method-select"
      />
      <Select
        minimal
        name={PROMO_CODE_SERVER_FILTERS.STATUS}
        innerLabel="Status"
        value={query[PROMO_CODE_SERVER_FILTERS.STATUS] || ''}
        items={STATUS_TYPE_LABELS}
        onChange={e =>
          locationId
            ? handleQueryChange(e)
            : showAlertGeneral(
                'Please select a location to use the status filter'
              )
        }
        withNone
        className={classes.select}
        dataTest="promo-status-select"
      />
    </>
  )

  return (
    <Layout>
      <Header
        title="Promo Codes"
        foldableActions={foldableActions}
        breadcrumbs={[
          {
            title: 'Back Of House',
            link: URL.ADMIN_BOH
          }
        ]}
        actions={actions}
        summarizers={summarizers}
        filters={filters}
      />
      <DataTable
        columns={promoCodeColumns}
        message={
          !isLoadingPromoCodes && promoCodes?.length === 0
            ? 'No promo codes'
            : ''
        }
        isLoadingList={isLoadingPromoCodes}
        hasListNext={hasPromoCodesNext}
        listNext={listPromoCodesNext}
        query={query}
        updateQuery={updateQuery}
      >
        {hasPromoCodes &&
          promoCodes.map((item, idx) => (
            <PromoCodeItem
              key={idx}
              item={item}
              handleEditClick={code =>
                go({}, `${URL.ADMIN_PROMO_CODES}/${item.id}`, false)
              }
              handleDuplicateClick={() =>
                go({}, `${`${URL.ADMIN_PROMO_CODES}/new`}`, false, {
                  code: item.id
                })
              }
              toggleCodeActivation={toggleCodeActivation}
              deleteItem={() => {
                setShowConfirmDeleteModal(true)
                setCodeToDelete(item)
              }}
            />
          ))}
      </DataTable>
      <Dialog
        open={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleDeletePromoCode}
        confirmText="Okay"
        hideCancel
      >
        <Box mt={1}>
          <Typography variant="h5">Confirm Action</Typography>
        </Box>
        <Box my={3}>
          <Typography variant="body1">
            Are you sure you want to delete this promo code? This action cannot
            be undone.
          </Typography>
        </Box>
      </Dialog>
    </Layout>
  )
}

PromoCodesList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(theme => ({
  select: {
    '& .MuiSelect-root': {
      minWidth: 90
    }
  },
  userSearch: {
    '& .MuiFormControl-root': {
      minWidth: '240px',
      marginBottom: '0 ',

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      }
    }
  }
}))(PromoCodesList)
