import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { Box, Tooltip, IconButton } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

import { useLocationsContext } from 'context'
import {
  useQuery,
  useConditionalEffect,
  useAlerts,
  useAdminPlateList
} from 'hooks'

import { API_PARAMS, LIST_PATHS } from 'constants/queryParams'

import { sortPossibilitiesFromColumns } from 'utils/query'

import Layout from 'components/Layout'
import Header from 'components/Header'
import DataTable from 'components/DataTable'
import SearchInput from 'components/SearchInput'
import Select from 'components/Select'
import PlateModal from 'components/PlateModal'

import PlateItem from './PlateItem'

const ALL_LOCATION_UPDATE_MSG =
  'To update variants, please first select a specific Darkstore location.'

const BIN_TITLE = 'Bin'

const InfoColumn = ({ title, description }) => {
  return (
    <Tooltip title={description}>
      <Box>
        {title}
        <IconButton
          color="secondary"
          aria-label="Help for this column."
          size="small"
        >
          <InfoIcon />
        </IconButton>
      </Box>
    </Tooltip>
  )
}

const columns = [
  {
    title: 'Product'
  },
  {
    title: 'SKU'
  },
  { title: 'Location' },
  {
    title: 'Expiration',
    sortKeys: [LIST_PATHS.PLATE_EXPIRATION_DATE]
  },
  {
    title: 'Pull',
    sortKeys: [LIST_PATHS.PLATE_PULL_DATE]
  },
  {
    title: 'Qty',
    sortKeys: ['plates.quantity']
  },
  {
    title: (
      <InfoColumn
        title="Total"
        description="The TOTAL physical inventory for this variant at this warehouse."
      />
    ),
    sortKeys: ['variant_total_quantity']
  },
  {
    title: (
      <InfoColumn
        title="Committed"
        description="The quantity of this variant committed to orders that have not been picked yet."
      />
    )
  },
  {
    title: (
      <InfoColumn
        title="Available"
        description="The quantity of this variant available to buy, if this variant is published."
      />
    )
  }
]

const columnsWithoutBin = columns.filter(c => c.title !== BIN_TITLE)

const sortPossibilities = sortPossibilitiesFromColumns(columns)

const PlateList = ({ classes }) => {
  const { showAllLocationIds } = useLocationsContext()
  const { showAlertGeneral } = useAlerts()

  const { query, handleQueryChange, updateQuery } = useQuery(() => null, {
    locationFilter: LIST_PATHS.VARIANTS_LOCATIONS_ID
  })

  const {
    isFetchingPlates,
    plates,
    hasPlatesNext,
    fetchPlatesNext
  } = useAdminPlateList({ params: query })

  const [selections, setSelections] = useState({})

  const [plateModalOpen, setPlateModalOpen] = useState(false)
  const [activePlate, setActivePlate] = useState({})

  useConditionalEffect(() => {
    if (showAllLocationIds) {
      showAlertGeneral(ALL_LOCATION_UPDATE_MSG)
    }
  }, [showAllLocationIds])

  useConditionalEffect(() => {
    setSelections({})
  }, [query])

  const handleInventoryUpdate = () => {
    setSelections({})
    updateQuery(query)
  }

  const handleOpenPlateModal = lp => {
    setPlateModalOpen(true)
    setActivePlate(lp)
  }

  const foldableActions = (
    <>
      <SearchInput
        name="search"
        placeholder="Search By Location, Brand, or SKU..."
        value={query.search}
        onChange={handleQueryChange}
        dataTest="plate-search"
      />
    </>
  )

  const summarizers = <></>

  const filters = (
    <>
      <Select
        minimal
        name={API_PARAMS.SORT}
        innerLabel="Sort By"
        value={query[API_PARAMS.SORT] || ''}
        items={sortPossibilities}
        onChange={handleQueryChange}
        withNone
        dataTest="plate-sort"
      />
    </>
  )

  return (
    <Layout maxWidth="xl" id="inventory-list">
      <Header
        title="Inventory"
        foldableActions={foldableActions}
        summarizers={summarizers}
        filters={filters}
      />

      <DataTable
        columns={showAllLocationIds ? columnsWithoutBin : columns}
        message={plates?.length === 0 ? 'No results found.' : null}
        isLoadingList={isFetchingPlates}
        hasListNext={hasPlatesNext}
        listNext={fetchPlatesNext}
        query={query}
        updateQuery={updateQuery}
      >
        {plates?.map(lp => (
          <PlateItem
            key={lp.id}
            plate={lp}
            openPlate={() => handleOpenPlateModal(lp)}
          />
        ))}
      </DataTable>

      <PlateModal
        open={plateModalOpen}
        variants={Object.values(selections)}
        onUpdate={handleInventoryUpdate}
        onClose={() => setPlateModalOpen(false)}
        plate={activePlate}
      />
    </Layout>
  )
}

PlateList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(theme => ({
  brandSearch: {
    '& .MuiFormControl-root': {
      width: 180,
      marginBottom: theme.spacing(1),

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      }
    }
  }
}))(PlateList)
