import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import uniqBy from 'lodash/uniqBy'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminProducts } from 'hooks'
import AutocompleteSearch from 'components/AutocompleteSearch'

import styles from './AutocompleteSearchStatusCodesStyles'

const AutocompleteSearchStatusCodesRaw = ({
  name,
  classes,
  onChange,
  value,
  defaultOpen,
  multiple,
  helperText,
  error
}) => {
  const {
    isLoadingStatusCodes,
    statusCodes,
    listStatusCodes,
    listStatusCodesNext
  } = useAdminProducts({})
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (query.length) {
      listStatusCodes({ search: query })
    } else {
      listStatusCodes()
    }
  }, [query])

  const options = useMemo(
    () =>
      uniqBy((statusCodes ?? []).concat(value), v => v?.id).filter(
        statusCode => statusCode?.active
      ),
    [statusCodes]
  )

  return (
    <Box mt={1}>
      <AutocompleteSearch
        name={name}
        helperText={helperText}
        error={error}
        defaultOpen={defaultOpen}
        value={value}
        loading={isLoadingStatusCodes}
        setQuery={setQuery}
        onChange={onChange}
        onScrollBottom={listStatusCodesNext}
        options={options}
        placeholder="Status Code"
        multiple={multiple}
        getOptionLabel={opt => opt?.name ?? ''}
        renderOption={(option, { selected }) => (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="100%"
            className={selected ? classes.selectedItem : null}
          >
            <Typography variant="body1" className={classes.name}>
              {option?.name ?? ''}
            </Typography>
          </Box>
        )}
      />
    </Box>
  )
}

AutocompleteSearchStatusCodesRaw.defaultProps = {
  value: {},
  defaultOpen: false,
  multiple: false
}

AutocompleteSearchStatusCodesRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  defaultOpen: PropTypes.bool,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool
}

const AutocompleteSearchStatusCodes = withStyles(styles)(
  AutocompleteSearchStatusCodesRaw
)

const AutocompleteSearchStatusCodesControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchStatusCodes
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchStatusCodesControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchStatusCodes as default,
  AutocompleteSearchStatusCodesControlled
}
