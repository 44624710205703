export const primary = '#305BF2'
export const secondary = '#E6E7EA'

export const white = '#FFFFFF'
export const black = '#000000'

export const primaryText = '#FFFFFF'

// Colors for our Design System
export const colorsAF = {
  fast: '#305BF2',
  darkFast: '#264FE1',
  lightFast: '#F2F6FC',
  fastRed: '#E75656',
  black,
  darkGray: '#111111',
  mediumGray: '#505050',
  lightGray: '#8D8D8D',
  semiGray: '#E5E5E5',
  ultraLightGray: '#F6F6F6',
  white,
  danger: '#E75656',
  warning: '#FFB800'
}

export const grays = {
  ultradark: '#111111',
  darkest: '#515151',
  dark: '#858585',
  med: '#E0E0E0',
  light: '#F7F7FA',
  ultralight: '#f6f6f6',
  // TODO: Below are legacy colors used in dark mode
  lightest: '#F9F9F9',
  semiGrey: '#e5e5e5'
}

export const blues = {
  fast: primary,
  dark: '#7F99F3',
  med: '#A7B8F4',
  light: '#BBC7F5',
  // TODO: Below are legacy colors used in dark mode
  neue: '#1F3A8F',
  denim: '#15265F'
}

export const warnings = {
  success: '#4CAF50',
  caution: '#FD6B6B',
  error: '#FF9800',
  danger: '#E52B20'
}

export const tierBadge = {
  blue: '#305BF2',
  bronze: '#8B634A',
  silver: '#76787C',
  gold: '#AFA072',
  platinum: '#43464B',
  employee: '#111',
  investor: '#111'
}
