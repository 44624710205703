import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { fromPairs } from 'lodash'

import {
  useLoaders,
  useAdminCollectionGroups,
  useAlerts,
  useNavigation
} from 'hooks'

import { Grid, Box, Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from 'components/Button'
import Header from 'components/Header'
import Layout from 'components/Layout'
import Dialog from 'components/Dialog'
import { URL } from 'constants/navigation'
import ControlledForm, { useControlledForm } from 'components/ControlledForm'
import { TextFieldControlled } from 'components/TextField'
import {
  FORM_FIELDS,
  EDIT_COLLECTION_GROUP_FIELDS,
  EDIT_COLLECTION_GROUP_SCHEMA
} from 'constants/collectionGroups'
import { BUTTON_VARIANT } from 'constants/enums'
import { TAB_ORDER } from 'containers/admin/CollectionTabs'
import { useUIContext } from 'context'

import CollectionGroupCollections from './CollectionGroupCollections'

const HeaderActions = ({ onDestroy }) => {
  const { handleSubmit, readyToSave } = useControlledForm()

  return (
    <>
      <Button
        adaptive
        variant={BUTTON_VARIANT.outlined}
        label="delete collection group button"
        startIcon={<DeleteIcon />}
        size="medium"
        onClick={onDestroy}
        dataTest="collection-group-delete-button"
      >
        Delete
      </Button>

      <Button
        adaptive
        label="save collection group button"
        startIcon={<SaveIcon />}
        size="medium"
        onClick={handleSubmit}
        disabled={!readyToSave}
        dataTest="collection-group-save-button"
      >
        Save
      </Button>
    </>
  )
}
HeaderActions.propTypes = {
  onDestroy: PropTypes.func.isRequired
}

const ControlledFormLoader = ({ placeholder, children }) => {
  const { loading } = useControlledForm()
  return loading ? placeholder : children
}

ControlledFormLoader.propTypes = {
  children: PropTypes.node,
  placeholder: PropTypes.node
}

const CollectionsPlaceholder = () => (
  <Grid spacing={3} container>
    <Grid item xs={12} md={6}>
      <Skeleton variant="rect" height={400} />
    </Grid>
    <Grid item xs={12} md={6}>
      <Skeleton variant="rect" height={600} />
    </Grid>
  </Grid>
)

const getChanges = (fieldNames, changedNames, data) =>
  fromPairs(
    fieldNames
      .filter(name => changedNames.has(name))
      .map(name => [name, data[name]])
  )

const CollectionGroupDetails = ({
  match: {
    params: { id }
  }
}) => {
  const collectionGroupId = Number.parseInt(id, 10)
  const { showAlertError, showAlertSuccess } = useAlerts()
  const { showLoading, hideLoading } = useLoaders()
  const { go } = useNavigation({})

  const [destroyDialogOpen, setDestroyDialogOpen] = useState(false)

  const {
    collectionGroup,
    updateCollectionGroup,
    destroyCollectionGroup
  } = useAdminCollectionGroups(collectionGroupId, {
    onSuccess: showAlertSuccess,
    onError: showAlertError
  })

  const { setCollectionTab } = useUIContext()

  const handleFormErrors = errors =>
    Object.entries(errors).forEach(([name, err]) =>
      showAlertError(`${name}: ${err.message}`)
    )

  const { loading, onSubmit, defaultValues, onDestroy } = useMemo(() => {
    if (!collectionGroup) {
      return {
        loading: true,
        onSubmit: () => undefined,
        onDestroy: () => undefined,
        defaultValues: {}
      }
    }

    const getIds = items => (items ? items.map(item => item.id) : null)
    const defaultCollections = collectionGroup?.collections ?? []
    const defaultTitle = collectionGroup?.title ?? ''
    const defaultValuesInner = {
      [FORM_FIELDS.TITLE]: defaultTitle,
      [FORM_FIELDS.COLLECTIONS]: defaultCollections,
      [FORM_FIELDS.COLLECTION_IDS]: getIds(defaultCollections)
    }

    const onDestroyInner = async () => {
      try {
        showLoading()
        await destroyCollectionGroup(collectionGroup.id)
      } finally {
        setCollectionTab(TAB_ORDER.indexOf('collectionGroups'))
        go({}, URL.ADMIN_COLLECTIONS)
        hideLoading()
      }
    }

    const onSubmitInner = async (data, event, changedNames) => {
      const collectionChanges = getChanges(
        EDIT_COLLECTION_GROUP_FIELDS,
        changedNames,
        data
      )

      try {
        const changedKeys = Object.keys(collectionChanges)
        showLoading()

        if (changedKeys?.length) {
          await updateCollectionGroup(collectionChanges)
        }
      } catch (e) {
        showAlertError('Collection Group not updated, please try again')

        console.error('error updating collection', e)
      } finally {
        hideLoading()
      }
    }

    return {
      loading: false,
      onSubmit: onSubmitInner,
      onDestroy: onDestroyInner,
      defaultValues: defaultValuesInner
    }
  }, [collectionGroup])

  return (
    <ControlledForm
      handleSubmit={onSubmit}
      schemas={EDIT_COLLECTION_GROUP_SCHEMA}
      defaultValues={defaultValues}
      boxProps={{ flex: 1 }}
      loading={loading}
      resetOnSubmit={false}
      onError={handleFormErrors}
    >
      <Layout id="collection-group-details">
        <Header
          sticky
          breadcrumbs={[
            { title: 'Collection Group', link: URL.ADMIN_COLLECTIONS }
          ]}
          title={collectionGroup?.title}
          actions={
            <HeaderActions onDestroy={() => setDestroyDialogOpen(true)} />
          }
        />

        <ControlledFormLoader placeholder={<CollectionsPlaceholder />}>
          <Box>
            <TextFieldControlled
              name={FORM_FIELDS.TITLE}
              placeholder="Name"
              fullWidth
              dataTest="collection-group-title"
            />
          </Box>

          <CollectionGroupCollections />
        </ControlledFormLoader>

        <Dialog
          title="Delete Collection Group?"
          open={destroyDialogOpen}
          onClose={() => setDestroyDialogOpen(false)}
          onConfirm={onDestroy}
          confirmText="Delete"
        >
          <Box mb={2}>
            <Typography variant="body1">
              This will delete the collection group, and remove it from any
              storefronts that use it. This cannot be undone.
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">Press 'Delete' to continue.</Typography>
          </Box>
        </Dialog>
      </Layout>
    </ControlledForm>
  )
}

CollectionGroupDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired
  }).isRequired
}

export default CollectionGroupDetails
