const jssStyles = theme => ({
  imageCell: {
    paddingRight: 0
  },
  nameCell: {
    paddingLeft: theme.spacing(1)
  },
  row: {
    '& .MuiTypography-body1': {
      color: theme.palette.grays.dark
    }
  },
  avatar: {
    paddingLeft: 0,
    paddingRight: 0
  }
})

export default jssStyles
