export default theme => ({
  gridContainer: {
    flex: 1,
    marginTop: theme.spacing(7)
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    width: '100%'
  },
  controlContainer: {
    marginBottom: theme.spacing(4),
    width: '100%',

    '& .MuiOutlinedInput-root': {
      width: '100%',
      minHeight: 50,

      '& .MuiOutlinedInput-input': {
        padding: 12
      }
    },

    '& .MuiFormGroup-root': {
      width: '100%'
    }
  },
  formSection: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(3)
  },
  verticalDisplay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%'
  },
  filledInput: {
    width: '100%'
  },
  checkboxesDesktop: {
    flexDirection: 'row'
  },
  radioButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  radioButtonWithLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginRight: theme.spacing(2)
  },
  flagBlock: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  divider: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grays.med}`,
    margin: `${theme.spacing(3)}px 0`
  },
  generateButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    minWidth: 124,
    height: 50
  },
  generateButtonMobile: {
    minWidth: 'auto'
  },
  csvUploadButton: {
    marginLeft: theme.spacing(2),
    height: 50
  },
  menuButton: {
    cursor: 'pointer',
    width: 42,
    height: 42,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      backgroundColor: '#e5e5e5'
    }
  }
})
