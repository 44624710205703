import React from 'react'
import PropTypes from 'prop-types'

const BrandsIcon = ({ fill }) => (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 12.25C18 12.6642 18.3358 13 18.75 13C19.1642 13 19.5 12.6642 19.5 12.25V0.75C19.5 0.335786 19.1642 0 18.75 0C18.3358 0 18 0.335786 18 0.75V12.25Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12.25C0 12.6642 0.335786 13 0.75 13C1.16421 13 1.5 12.6642 1.5 12.25V0.75C1.5 0.335786 1.16421 0 0.75 0C0.335786 0 0 0.335786 0 0.75V12.25Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 10.25C15 10.6642 15.3358 11 15.75 11C16.1642 11 16.5 10.6642 16.5 10.25V0.75C16.5 0.335786 16.1642 0 15.75 0C15.3358 0 15 0.335786 15 0.75V10.25Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 12.75C15 13.1642 15.3358 13.5 15.75 13.5C16.1642 13.5 16.5 13.1642 16.5 12.75C16.5 12.3358 16.1642 12 15.75 12C15.3358 12 15 12.3358 15 12.75Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10.25C12 10.6642 12.3358 11 12.75 11C13.1642 11 13.5 10.6642 13.5 10.25V0.75C13.5 0.335786 13.1642 0 12.75 0C12.3358 0 12 0.335786 12 0.75V10.25Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 12.75C12 13.1642 12.3358 13.5 12.75 13.5C13.1642 13.5 13.5 13.1642 13.5 12.75C13.5 12.3358 13.1642 12 12.75 12C12.3358 12 12 12.3358 12 12.75Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 10.25C9 10.6642 9.33579 11 9.75 11C10.1642 11 10.5 10.6642 10.5 10.25V0.75C10.5 0.335786 10.1642 0 9.75 0C9.33579 0 9 0.335786 9 0.75V10.25Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12.75C9 13.1642 9.33579 13.5 9.75 13.5C10.1642 13.5 10.5 13.1642 10.5 12.75C10.5 12.3358 10.1642 12 9.75 12C9.33579 12 9 12.3358 9 12.75Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10.25C6 10.6642 6.33579 11 6.75 11C7.16421 11 7.5 10.6642 7.5 10.25V0.75C7.5 0.335786 7.16421 0 6.75 0C6.33579 0 6 0.335786 6 0.75V10.25Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12.75C6 13.1642 6.33579 13.5 6.75 13.5C7.16421 13.5 7.5 13.1642 7.5 12.75C7.5 12.3358 7.16421 12 6.75 12C6.33579 12 6 12.3358 6 12.75Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 10.25C3 10.6642 3.33579 11 3.75 11C4.16421 11 4.5 10.6642 4.5 10.25V0.75C4.5 0.335786 4.16421 0 3.75 0C3.33579 0 3 0.335786 3 0.75V10.25Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12.75C3 13.1642 3.33579 13.5 3.75 13.5C4.16421 13.5 4.5 13.1642 4.5 12.75C4.5 12.3358 4.16421 12 3.75 12C3.33579 12 3 12.3358 3 12.75Z"
      fill={fill}
    />
  </svg>
)

BrandsIcon.propTypes = {
  fill: PropTypes.string
}

export default BrandsIcon
