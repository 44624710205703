import React, { useState, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { COLOR, BUTTON_VARIANT, SIZE, TOOLTIP_PLACEMENT } from 'constants/enums'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import Button from 'components/Button'
import TextField from 'components/TextField'
import AddAccessCodesDrawer from 'components/AddAccessCodesDrawer'
import Checkbox from 'components/Checkbox'
import Confirm from 'components/Confirm'

import styles from './ProductAccessCodesPanelStyles'

const SEARCH_DEBOUNCE_DURATION_MS = 350

const ProductAccessCodesPanel = ({
  classes,
  product,
  addAccessCodes,
  updateAccessCodeAvailability,
  onDelete
}) => {
  const [accessCodes, setAccessCodes] = useState([])
  const [addDrawerOpen, setAddDrawerOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [queryRegex, setQueryRegex] = useState()

  // -- begin editable panel helpers
  const [isEditablePanel, setIsEditablePanel] = useState(false)
  const [popoverAnchorElement, setPopoverAnchorElement] = useState(null)
  const [openPopover, setOpenPopover] = useState(false)
  const [codesToDelete, setCodesToDelete] = useState({})

  const handleMenuClick = e => setPopoverAnchorElement(e.currentTarget)
  const handleMenuClose = () => setPopoverAnchorElement(null)
  const toggleEditMenu = () => {
    setCodesToDelete({})
    setIsEditablePanel(!isEditablePanel)
    setOpenPopover(false)
    handleMenuClose()
  }

  const handleCheckboxCodeChange = (codeId, value) =>
    setCodesToDelete({ ...codesToDelete, [codeId]: value })

  const handleDeleteCodes = () => {
    const toDelete = Object.keys(codesToDelete).map(
      i => codesToDelete[i] && Number(i)
    )
    setAccessCodes([...accessCodes.filter(code => !toDelete.includes(code.id))])
    onDelete && onDelete(toDelete)
    toggleEditMenu()
  }

  const showDeleteButton = useMemo(
    () => Object.values(codesToDelete).find(val => val),
    [codesToDelete]
  )
  // -- end editable panel helpers

  useEffect(() => {
    if (!product) {
      return
    }
    const allAccessCodes = product?.access_codes ?? []

    setAccessCodes(
      queryRegex === null
        ? allAccessCodes
        : allAccessCodes.filter(c => c.code.search(queryRegex) > -1)
    )
  }, [queryRegex, product])

  const debouncedSetRegex = debounce(
    q => setQueryRegex(q ? new RegExp(q, 'i') : null),
    SEARCH_DEBOUNCE_DURATION_MS
  )

  const handleSearchChange = ({ target: { value } }) => {
    setSearchQuery(value)
    debouncedSetRegex(value)
  }

  return (
    <Box>
      <Box pl={4} pt={2} pr={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">User Access Codes</Typography>
          <Box className={classes.headerButtons}>
            {!isEditablePanel && !showDeleteButton && (
              <Button
                color={COLOR.default}
                className={classes.addButton}
                variant={BUTTON_VARIANT.outlined}
                onClick={() => setAddDrawerOpen(true)}
              >
                + Code
              </Button>
            )}
            {isEditablePanel && (
              <>
                <Confirm
                  description="Are you sure you want to delete the selected access codes?"
                  onConfirm={handleDeleteCodes}
                  onClose={() => setOpenPopover(false)}
                  placement={TOOLTIP_PLACEMENT.left}
                  open={openPopover}
                  classes={{ root: classes.popover }}
                >
                  <Box marginRight={1}>
                    <Button
                      className={classes.addButton}
                      disabled={!showDeleteButton}
                      color={COLOR.primary}
                      size={SIZE.small}
                      variant={BUTTON_VARIANT.contained}
                      onClick={() => setOpenPopover(true)}
                    >
                      Delete
                    </Button>
                  </Box>
                </Confirm>
                <Button
                  className={classes.addButton}
                  color={COLOR.default}
                  size={SIZE.small}
                  variant={BUTTON_VARIANT.outlined}
                  onClick={toggleEditMenu}
                >
                  Cancel
                </Button>
              </>
            )}
            <IconButton
              className={classes.menuIconButton}
              edge="end"
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              className={classes.menu}
              classes={{ paper: classes.menuPaper }}
              open={Boolean(popoverAnchorElement)}
              keepMounted
              anchorEl={popoverAnchorElement}
              onClose={handleMenuClose}
            >
              <MenuItem className={classes.menuItem} onClick={toggleEditMenu}>
                {isEditablePanel ? 'View' : 'Edit'}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <TextField
          name="search"
          placeholder="Search codes"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Box className={classes.accessCodes}>
          {accessCodes?.map(({ code, available, id }) => (
            <Box key={id} className={classes.accessCode}>
              {isEditablePanel && (
                <Checkbox
                  onChange={value => handleCheckboxCodeChange(id, value)}
                />
              )}
              <Typography variant="body2">{code}</Typography>
              <MuiSelect
                value={available}
                input={<Input disableUnderline />}
                className={classNames({
                  [classes.code]: [true],
                  [classes.activeCode]: available,
                  [classes.inactivatedCode]: !available
                })}
              >
                <MenuItem
                  value={true}
                  onClick={() => updateAccessCodeAvailability(id, true)}
                >
                  Active
                </MenuItem>
                <MenuItem
                  value={false}
                  onClick={() => updateAccessCodeAvailability(id, false)}
                >
                  Deactivated
                </MenuItem>
              </MuiSelect>
            </Box>
          ))}
        </Box>
      </Box>
      <AddAccessCodesDrawer
        open={addDrawerOpen}
        onClose={() => setAddDrawerOpen(false)}
        preexistingCodes={accessCodes?.map(({ code }) => code)}
        onSubmit={addAccessCodes}
      />
    </Box>
  )
}

ProductAccessCodesPanel.defaultProps = {}

ProductAccessCodesPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object,
  addAccessCodes: PropTypes.func,
  updateAccessCodeAvailability: PropTypes.func,
  onDelete: PropTypes.func
}

export default withStyles(styles)(ProductAccessCodesPanel)
