import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import SaveIcon from '@material-ui/icons/Save'

import { DAY_STRING_FORMAT } from 'constants/general'
import { COLOR, BUTTON_VARIANT, SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'
import { getDatesBetweenDates } from 'utils/general'
import { useLocationsContext } from 'context'
import {
  useAdminBlockedWindows,
  useAlerts,
  useBeforeUnload,
  useConditionalEffect,
  useLoaders
} from 'hooks'

import Block from 'components/Block'
import Header from 'components/Header'
import Layout from 'components/Layout'
import DayRangePicker from 'components/DayRangePicker'

import styles from './HoursOfOperationStyles'

const TEST_ID = 'hours'
// Matching app/models/location.rb
const DELIVERY_WINDOW_STARTING_HOUR = 9
const DELIVERY_WINDOW_ENDING_HOUR = 21
const DELIVERY_WINDOW_DURATION_HOURS = 4
const DELIVERY_WINDOWS_COUNT =
  (DELIVERY_WINDOW_ENDING_HOUR - DELIVERY_WINDOW_STARTING_HOUR) /
  DELIVERY_WINDOW_DURATION_HOURS

const toTimeString = n => {
  const m = n < 12 || n >= 24 ? 'AM' : 'PM'
  const h = String(n <= 12 ? n : n === 24 ? 12 : n % 12)
  return `${h}:00 ${m}`
}

const DELIVERY_WINDOWS = Object.fromEntries(
  Array(DELIVERY_WINDOWS_COUNT)
    .fill()
    .map((a, b) => {
      const start =
        DELIVERY_WINDOW_STARTING_HOUR + b * DELIVERY_WINDOW_DURATION_HOURS
      const end = start + DELIVERY_WINDOW_DURATION_HOURS
      return [start, `${toTimeString(start)} - ${toTimeString(end)}`]
    })
)

const ALL_LOCATION_HOURS_MSG =
  'To change hours of operation, please first select a specific Darkstore location.'

const INITIAL_BLOCKED_WINDOWS = [
  { hour: '9', order_limit: 0, active: false },
  { hour: '13', order_limit: 0, active: false },
  { hour: '17', order_limit: 0, active: false }
]

const HoursOfOperation = ({ classes }) => {
  const { showAlertGeneral } = useAlerts()
  const { showLoading, hideLoading } = useLoaders()
  const { location, showAllLocationIds } = useLocationsContext()

  const [
    originalBlockedWindowsByDay,
    setOriginalBlockedWindowsByDay
  ] = useState(false)
  const [
    originalBlockedWindowsByRange,
    setOriginalBlockedWindowsByRange
  ] = useState(false)
  const [blockedWindowsByDay, setBlockedWindowsByDay] = useState({})
  const [selectedDate, setSelectedDate] = useState(null)
  const [dateRange, setDateRange] = useState([])
  const [blockedWindows, setBlockedWindows] = useState(INITIAL_BLOCKED_WINDOWS)

  const areUnsavedChanges =
    !isEqual(
      originalBlockedWindowsByDay,
      omitBy(blockedWindowsByDay, value => isEmpty(value))
    ) ||
    (!isEmpty(dateRange) &&
      !isEqual(originalBlockedWindowsByRange, blockedWindows))

  const { OnBeforeUnloadPrompt } = useBeforeUnload(
    areUnsavedChanges && (Boolean(selectedDate) || !isEmpty(dateRange))
  )

  const {
    blockedWindows: allBlockedWindows,
    fetchBlockedWindows,
    bulkUpdateBlockedWindows
  } = useAdminBlockedWindows({ locationId: location?.id })

  const selectedDateStr =
    selectedDate && selectedDate?.format(DAY_STRING_FORMAT)
  const startDate = dateRange[0]
  const endDate = dateRange[1]

  useConditionalEffect(() => {
    if (!startDate && !endDate) {
      return setBlockedWindows(INITIAL_BLOCKED_WINDOWS)
    }

    const dateArray = getDatesBetweenDates(startDate, endDate)
    const selectedBlockedWindows = dateArray.map(
      date => blockedWindowsByDay[date] || {}
    )
    const newBlockedWindows = INITIAL_BLOCKED_WINDOWS.map(blockedWindow => {
      const matchedSelectedBlockedWindows = selectedBlockedWindows?.map(
        dateRangeBlockedWindow =>
          Object.keys(dateRangeBlockedWindow).find(
            dateRangeHours => dateRangeHours === blockedWindow?.hour
          ) && dateRangeBlockedWindow
      )

      const orderLimit = head(matchedSelectedBlockedWindows)?.[
        blockedWindow?.hour
      ]?.order_limit

      const areSame = matchedSelectedBlockedWindows?.every(foundWindow => {
        return (
          foundWindow?.[blockedWindow?.hour] &&
          foundWindow?.[blockedWindow?.hour]?.order_limit === orderLimit
        )
      })

      if (areSame) {
        return orderLimit
          ? { ...blockedWindow, active: false, order_limit: orderLimit }
          : { ...blockedWindow, active: true }
      }
      return blockedWindow
    })

    setOriginalBlockedWindowsByRange(newBlockedWindows)
    return setBlockedWindows(newBlockedWindows)
  }, [dateRange])

  useConditionalEffect(() => {
    if (showAllLocationIds) {
      showAlertGeneral(ALL_LOCATION_HOURS_MSG)
      return
    }

    if (location) {
      showLoading()
      fetchBlockedWindows()
    }
  }, [location])

  useConditionalEffect(() => {
    if (!allBlockedWindows) {
      return
    }
    const result = {}
    allBlockedWindows.forEach(({ hour, date, order_limit, id }) => {
      const dateStr = moment(date).format(DAY_STRING_FORMAT)
      if (!(dateStr in result)) {
        result[dateStr] = {}
      }
      result[dateStr][hour] = {
        hour,
        date: dateStr,
        ...(order_limit && { order_limit })
      }
    })
    setOriginalBlockedWindowsByDay(result)
    setBlockedWindowsByDay(result)
    hideLoading()
  }, [allBlockedWindows])

  const addNewBlockedWindowsByDay = (dateStr, start) => {
    setBlockedWindowsByDay({
      ...blockedWindowsByDay,
      [dateStr]: {
        ...blockedWindowsByDay[dateStr],
        [start]: {
          ...(blockedWindowsByDay[dateStr] &&
            blockedWindowsByDay[dateStr][start]),
          hour: parseInt(start, 10),
          date: dateStr
        }
      }
    })
  }

  const removeNewBlocekedWindowsByDay = (dateStr, start) => {
    setBlockedWindowsByDay({
      ...blockedWindowsByDay,
      [dateStr]: omit(blockedWindowsByDay[dateStr], [start])
    })
  }

  const toggleBlockedWindow = async start => {
    if (!selectedDateStr) {
      setBlockedWindows(
        blockedWindows.map(item =>
          item.hour === start
            ? {
                ...item,
                active: !item.active
              }
            : item
        )
      )

      return
    }

    if (!blockedWindowsByDay[selectedDateStr]?.[start]) {
      addNewBlockedWindowsByDay(selectedDateStr, start)
    } else {
      removeNewBlocekedWindowsByDay(selectedDateStr, start)
    }
  }

  const getLimitValue = start => {
    const limitValue = (selectedDate
      ? (blockedWindowsByDay[selectedDateStr] &&
          blockedWindowsByDay[selectedDateStr]?.[start]?.order_limit) ||
        0
      : blockedWindows.find(item => item.hour === start).order_limit
    )?.toString()
    return limitValue?.length === 1
      ? limitValue
      : limitValue?.replace(/^0*/g, '')
  }

  const handleSingleDates = date => {
    setSelectedDate(date)
    setDateRange([])
  }

  const handleRangeDates = range => {
    setSelectedDate(null)
    setDateRange(range)
  }

  const handleSelectDates = (startDate, endDate) => {
    startDate === endDate
      ? handleSingleDates(startDate && moment(startDate))
      : handleRangeDates([startDate, endDate])
  }

  const handleOrderLimitChange = (e, start) => {
    if (selectedDate) {
      const object = {
        ...blockedWindowsByDay,
        [selectedDateStr]: {
          ...blockedWindowsByDay[selectedDateStr],
          [start]: {
            ...(blockedWindowsByDay[selectedDateStr] &&
              blockedWindowsByDay[selectedDateStr][start]),
            ...blockedWindowsByDay[start],
            hour: parseInt(start, 10),
            order_limit: parseInt(e.target.value, 10),
            date: selectedDateStr
          }
        }
      }
      if (e.target.value === '0') {
        delete object[selectedDateStr][start].order_limit
      }
      if (e.target.value === '') {
        delete object[selectedDateStr][start]
      }
      setBlockedWindowsByDay(object)
      return
    }

    setBlockedWindows(
      blockedWindows.map(item =>
        item.hour === start
          ? {
              ...item,
              order_limit: parseFloat(e.target.value)
            }
          : item
      )
    )
  }

  const isWindowServiced = start => {
    const window = blockedWindowsByDay?.[selectedDateStr]?.[start]
    if (!isEmpty(dateRange)) {
      return !blockedWindows.find(timeWindow => timeWindow.hour === start)
        ?.active
    }
    if (!window) {
      return true
    }

    return window?.order_limit > 0
  }

  const handleSave = async () => {
    if (!isEmpty(dateRange)) {
      await bulkUpdateBlockedWindows({
        start_date: dateRange[0],
        end_date: dateRange[1],
        blocked_windows: blockedWindows
          ?.filter(item => item.active || item.order_limit)
          ?.map(item => omit(item, ['active']))
      })
    }

    const originalEntries = Object.entries(originalBlockedWindowsByDay)
    const windowEntries = Object.entries(blockedWindowsByDay)

    const unsavedDays = windowEntries.filter(
      window =>
        !originalEntries.some(originalWindow =>
          isEqual(originalWindow[1], window[1])
        )
    )

    await Promise.all(
      unsavedDays.map(order =>
        bulkUpdateBlockedWindows({
          start_date: order[0],
          end_date: order[0],
          blocked_windows: Object.values(order[1]).map(window => ({
            hour: window.hour.toString(),
            order_limit: parseFloat(window.order_limit)
          }))
        })
      )
    )

    showLoading()
    await fetchBlockedWindows()
  }

  useEffect(() => {
    handleSingleDates(moment(new Date()))
  }, [])

  if (showAllLocationIds) {
    return (
      <Layout>
        <Header title="Hours of Operation" />
        <Box>
          <Typography variant="h4">{ALL_LOCATION_HOURS_MSG}</Typography>
        </Box>
      </Layout>
    )
  }

  return (
    <Layout>
      <Header
        breadcrumbs={[
          {
            title: 'Back Of House',
            link: URL.ADMIN_BOH
          }
        ]}
        title="Hours of Operation"
        actions={
          <>
            <Button
              startIcon={<SaveIcon />}
              size="medium"
              label="save product changes button"
              variant={BUTTON_VARIANT.contained}
              color={COLOR.primary}
              disabled={!areUnsavedChanges}
              onClick={handleSave}
              data-test={`${TEST_ID}-save-button`}
            >
              Save
            </Button>
            <OnBeforeUnloadPrompt />
          </>
        }
      />
      <Box className={classes.container}>
        <Box flexGrow={1}>
          <DayRangePicker
            serviced={<Box className={classes.serviced} />}
            indicatedDays={omitBy(blockedWindowsByDay, value => isEmpty(value))}
            onSelectDates={handleSelectDates}
          />
        </Box>
        <Block withPadding fitContent>
          <Box display="flex" gridGap={24} mb={4}>
            <Box width={200}>
              <Typography className={classes.windowTitle}>
                Order Windows
              </Typography>
            </Box>
            <Typography className={classes.windowTitle}>Order Limit</Typography>
          </Box>
          {Object.entries(DELIVERY_WINDOWS).map(([start, label]) => (
            <Box mb={3} key={start} display="flex" gridGap={24}>
              <Button
                variant={
                  isWindowServiced(start)
                    ? BUTTON_VARIANT.contained
                    : BUTTON_VARIANT.outlined
                }
                color={
                  isWindowServiced(start) ? COLOR.primary : COLOR.secondary
                }
                size={SIZE.medium}
                onClick={() => toggleBlockedWindow(start)}
                className={classes.modeButton}
                data-test={`${TEST_ID}-order-window-button`}
              >
                {label}
              </Button>
              <OutlinedInput
                className={classes.numberInput}
                type="number"
                size={SIZE.medium}
                name={start}
                inputProps={{ min: 0 }}
                disabled={!isWindowServiced(start)}
                value={getLimitValue(start)}
                onChange={e => handleOrderLimitChange(e, start)}
                data-test={`${TEST_ID}-order-limit-input`}
              />
            </Box>
          ))}
          <Box mt={3}>
            <Typography className={classes.windowTitle}>Key</Typography>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Box display="flex" alignItems="center" gridGap={6}>
                <Box className={classes.unserviced} />
                <Typography>Unserviced Windows</Typography>
              </Box>
              <Box display="flex" alignItems="center" gridGap={6}>
                <Box className={classes.serviced} />
                <Typography>Serviced Windows</Typography>
              </Box>
            </Box>
          </Box>
        </Block>
      </Box>
    </Layout>
  )
}

HoursOfOperation.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(HoursOfOperation)
