import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SaveIcon from '@material-ui/icons/Save'
import AddIcon from '@material-ui/icons/Add'

import {
  makeObjectResolver,
  emailSchema,
  passwordMinStrengthSchema,
  makePasswordConfirm,
  nameSchema,
  userRoleSchema,
  locationSchema,
  userTypeSchema,
  blockedSchema
} from 'constants/yupSchemas'
import { SIZE, COLOR } from 'constants/enums'
import { USER_ROLES, ME, ALL_LOCATION } from 'constants/general'
import { URL } from 'constants/navigation'
import { useAuthContext, useLocationsContext } from 'context'
import { useAdminUsers, useAlerts, useLoaders } from 'hooks'
import { canBlockUser } from 'utils/policies'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Button from 'components/Button'
import TextField from 'components/TextField'
import Select from 'components/Select'
import Checkbox from 'components/Checkbox'
import Block from 'components/Block'

import styles from './UserEditStyles'

const DEFAULT_FIRST = 1

const yupPasswordSchemas = {
  password: passwordMinStrengthSchema,
  passwordConfirm: makePasswordConfirm('password')
}

const yupUserSchemas = {
  first_name: nameSchema,
  last_name: nameSchema,
  email: emailSchema,
  role: userRoleSchema,
  default_location_id: locationSchema,
  employee: userTypeSchema,
  investor: userTypeSchema,
  vip: userTypeSchema,
  blocked: blockedSchema
}

const yupAllSchemas = {
  ...yupUserSchemas,
  ...yupPasswordSchemas
}

const DISABLED_ROLE_VALUES = ['user']

const UserEdit = ({
  classes,
  match: {
    params: { id: paramId }
  }
}) => {
  const isNew = paramId === 'new'

  const { showAlertError, showAlertSuccess } = useAlerts()
  const { showLoading, hideLoading } = useLoaders()
  const history = useHistory()
  const { currentUser } = useAuthContext()
  const {
    locationOptions,
    locationOptionsWithAllSelector
  } = useLocationsContext()

  // The token has user_id and it is used to initialize currentUser.
  const id = paramId === ME ? currentUser.id || currentUser.user_id : paramId

  const {
    register: registerMain,
    handleSubmit: handleMainSubmit,
    errors: mainErrors,
    setValue: setMainFormValue,
    control: mainControl
  } = useForm({
    mode: 'onSubmit',
    resolver: makeObjectResolver(isNew ? yupAllSchemas : yupUserSchemas),
    defaultValues: {
      role: '',
      default_location_id: DEFAULT_FIRST
    }
  })

  const {
    user,
    readUser,
    isLoadingUser,
    hasUser,
    createUser,
    updateUser,
    userError
  } = useAdminUsers()

  const parseData = data => {
    if (data.default_location_id === ALL_LOCATION.code)
      return { ...data, default_location_id: ALL_LOCATION.id }
    if (data.default_location_id === null)
      return { ...data, default_location_id: ALL_LOCATION.code }
    return data
  }

  const setFields = result => {
    Object.keys(yupUserSchemas).map(field =>
      setMainFormValue(field, result[field])
    )
  }

  useEffect(() => {
    if (!isNew && id) {
      ;(async () => {
        showLoading()
        const response = await readUser(id)
        const result = parseData(response)
        if (result) {
          setFields(result)
        } else {
          showAlertError('Failed to get user data from server')
        }
        hideLoading()
      })()
    }
  }, [id])

  if (!isNew && !hasUser) {
    return null
  }

  const onCreateUser = async ({ passwordConfirm, ...data }) => {
    const result = await createUser(parseData(data))
    if (!result) {
      showAlertError('Failed to create user.')
      return
    }
    showAlertSuccess('New user created.')
    history.push(`${URL.ADMIN_USERS}/${result.id}`)
  }

  const onUpdateUser = async data => {
    const result = await updateUser({ id, ...parseData(data) })
    if (!result) {
      showAlertError('Failed to update user.')
    } else {
      setFields(result)
      showAlertSuccess('User updated.')
    }
  }

  const onMainSubmit = isNew ? onCreateUser : onUpdateUser

  const inputDisabled = !isNew && (!hasUser || isLoadingUser)
  const userName = isNew ? 'New User' : user?.name

  const isBlockedEnabled = currentUser && canBlockUser(currentUser)

  return (
    <Layout classes={{ root: classes.root }}>
      <form onSubmit={handleMainSubmit(onMainSubmit)}>
        <Header
          breadcrumbs={[
            { title: 'Users', link: URL.ADMIN_USERS },
            ...(isNew
              ? []
              : [{ title: userName, link: `${URL.ADMIN_USERS}/${id}` }])
          ]}
          title={isNew ? 'Add' : 'Edit'}
          actions={
            <Button
              size={SIZE.medium}
              color={COLOR.primary}
              fullWidth
              type="submit"
              className={classes.submit}
              disabled={inputDisabled}
              startIcon={isNew ? <AddIcon /> : <SaveIcon />}
              dataTest="user-confirm-button"
            >
              {isNew ? 'Create User' : 'Save'}
            </Button>
          }
        />
        <Box className={classes.container}>
          <Block className={classes.block}>
            <Typography variant="body1" className={classes.sectionTitle}>
              User Info
            </Typography>
          </Block>
          <Block
            className={classNames({
              [classes.block]: true,
              [classes.middleBlock]: true
            })}
          >
            <Box className={classes.flexRow}>
              <FormControlLabel
                classes={{ root: classes.formField }}
                control={
                  <TextField
                    name="first_name"
                    placeholder="First Name"
                    inputRef={registerMain}
                    error={Boolean(mainErrors.first_name?.message)}
                    helperText={mainErrors.first_name?.message}
                    disabled={inputDisabled}
                    fullWidth
                  />
                }
                labelPlacement="top"
                label="First Name"
              />
              <FormControlLabel
                classes={{ root: classes.formField }}
                control={
                  <TextField
                    name="last_name"
                    placeholder="Last Name"
                    inputRef={registerMain}
                    error={Boolean(mainErrors.last_name?.message)}
                    helperText={mainErrors.last_name?.message}
                    disabled={inputDisabled}
                    fullWidth
                  />
                }
                labelPlacement="top"
                label="Last Name"
              />
            </Box>
            <Box className={classes.flexRow}>
              <FormControlLabel
                classes={{ root: classes.formField }}
                control={
                  <TextField
                    name="email"
                    placeholder="Account Email"
                    fullWidth
                    inputRef={registerMain}
                    error={Boolean(mainErrors.email?.message)}
                    helperText={mainErrors.email?.message}
                    disabled={inputDisabled}
                    size="medium"
                    dataTest="user-email"
                  />
                }
                labelPlacement="top"
                label="Email"
              />
              <FormControlLabel
                classes={{ root: classes.formField }}
                control={
                  <Controller
                    name="default_location_id"
                    control={mainControl}
                    render={({ onChange, onBlur, value, name }) => (
                      <Select
                        classes={{ selectRoot: classes.selectRoot }}
                        name={name}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        onBlur={onBlur}
                        fullWidth
                        items={
                          locationOptionsWithAllSelector || [
                            { label: 'loading', value: DEFAULT_FIRST }
                          ]
                        }
                        inputRef={registerMain}
                        error={Boolean(mainErrors.default_location_id?.message)}
                        helperText={mainErrors.default_location_id?.message}
                        disabled={inputDisabled}
                      />
                    )}
                  />
                }
                labelPlacement="top"
                label="Default Location"
              />
            </Box>
            <Box className={classes.flexRow}>
              {isNew && (
                <>
                  <FormControlLabel
                    classes={{ root: classes.formField }}
                    control={
                      <TextField
                        name="password"
                        placeholder="Password"
                        password
                        inputRef={registerMain}
                        error={Boolean(mainErrors.password?.message)}
                        helperText={mainErrors.password?.message}
                        disabled={inputDisabled}
                        fullWidth
                      />
                    }
                    labelPlacement="top"
                    label="Password"
                  />
                  <FormControlLabel
                    classes={{ root: classes.formField }}
                    control={
                      <TextField
                        name="passwordConfirm"
                        placeholder="Confirm New Password"
                        password
                        inputRef={registerMain}
                        error={Boolean(mainErrors.passwordConfirm?.message)}
                        helperText={mainErrors.passwordConfirm?.message}
                        disabled={inputDisabled}
                        fullWidth
                      />
                    }
                    labelPlacement="top"
                    label="Confirm Password"
                  />
                </>
              )}
            </Box>
          </Block>
          <Block className={classes.block}>
            <Typography variant="body1" className={classes.sectionTitle}>
              Options
            </Typography>
            <Box className={classes.flexRow} mt={3}>
              <FormControlLabel
                classes={{ root: classes.formField }}
                control={
                  <Controller
                    name="role"
                    control={mainControl}
                    render={({ onChange, onBlur, value, name }) => (
                      <Select
                        classes={{ selectRoot: classes.selectRoot }}
                        name={name}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        onBlur={onBlur}
                        fullWidth
                        items={USER_ROLES.map(role =>
                          DISABLED_ROLE_VALUES.includes(role.value)
                            ? {
                                ...role,
                                disabled: true
                              }
                            : role
                        )}
                        inputRef={registerMain}
                        error={Boolean(mainErrors.role?.message)}
                        helperText={mainErrors.role?.message}
                        disabled={inputDisabled}
                      />
                    )}
                  />
                }
                labelPlacement="top"
                label="Role"
              />
              <Box className={classes.checkboxGroup}>
                <FormControlLabel
                  control={
                    <Controller
                      name="employee"
                      control={mainControl}
                      render={({ onChange, onBlur, value, name }) => (
                        <Checkbox
                          name={name}
                          checked={value}
                          onChange={onChange}
                          inputRef={registerMain}
                        />
                      )}
                    />
                  }
                  label="Employee"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="investor"
                      control={mainControl}
                      render={({ onChange, onBlur, value, name }) => (
                        <Checkbox
                          name={name}
                          checked={value}
                          onChange={onChange}
                          inputRef={registerMain}
                        />
                      )}
                    />
                  }
                  label="Investor"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="vip"
                      control={mainControl}
                      render={({ onChange, onBlur, value, name }) => (
                        <Checkbox
                          name={name}
                          checked={value}
                          onChange={onChange}
                          inputRef={registerMain}
                        />
                      )}
                    />
                  }
                  label="VIP"
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="blocked"
                      control={mainControl}
                      render={({ onChange, onBlur, value, name }) => (
                        <Checkbox
                          name={name}
                          checked={value}
                          onChange={onChange}
                          inputRef={registerMain}
                          disabled={!isBlockedEnabled}
                        />
                      )}
                    />
                  }
                  label="Blocked"
                />
              </Box>
            </Box>
          </Block>
          <FormHelperText className={classes.errorMessage}>
            {userError?.message}
          </FormHelperText>
        </Box>
      </form>
    </Layout>
  )
}

UserEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(UserEdit)
