import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isEqual, uniqBy, countBy } from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { AiFillCheckCircle, AiFillCalendar } from 'react-icons/ai'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'

import { colorsAF } from 'theme/colors'
import { URL } from 'constants/navigation'
import { STOREFRONT_SECTION_TYPES } from 'constants/general'
import { SIZE, COLOR, BUTTON_VARIANT, DRAG_N_DROP_MODE } from 'constants/enums'
import {
  DRAGGABLE_COLLECTION_SMALL_DIMS,
  DRAGGABLE_SECTION_DIMS
} from 'constants/styles'
import { useLocationsContext } from 'context'
import {
  useAlerts,
  useToggleButton,
  useAdminStorefront,
  useConditionalEffect,
  useMediaQuery
} from 'hooks'

import AddCollectionDialog from 'components/AddCollectionDialog'
import AddCollectionGroupDialog from 'components/AddCollectionGroupDialog.jsx'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import DraggableCollection from 'components/DraggableCollection'
import DraggableSection from 'components/DraggableSection'
import DragNDropGrid from 'components/DragNDropGrid'
import Header from 'components/Header'
import Layout from 'components/Layout'
import TextField from 'components/TextField'

import SubmitIcon from 'icons/SubmitIcon'
import EyeIcon from 'icons/EyeIcon'
import AddIcon from 'icons/AddIcon'
import CloseIcon from 'icons/CloseIcon'

import { parseDateIgnoreTZ } from 'utils/general'

import StorefrontPreview from './StorefrontPreview'
import styles from './StorefrontStyles'

const ALL_LOCATION_UPDATE_MSG =
  'To update storefront, please select a location.'

const isCollectionSlider = s =>
  s.type === STOREFRONT_SECTION_TYPES.COLLECTION_SLIDER
const isProductSlider = s => s.type === STOREFRONT_SECTION_TYPES.PRODUCT_SLIDER
const isCollectionGroup = s =>
  s.type === STOREFRONT_SECTION_TYPES.COLLECTION_GROUP

const SECTION_TYPE_CONSTRAINTS = [
  STOREFRONT_SECTION_TYPES.COLLECTION_SLIDER,
  STOREFRONT_SECTION_TYPES.BRAND_SLIDER,
  STOREFRONT_SECTION_TYPES.REFERRAL_CODE_BLOCK,
  STOREFRONT_SECTION_TYPES.RECOMMENDED_PRODUCTS_SLIDER
]

const getMissingSections = storefront => {
  const result = []
  const typeCounts = countBy(storefront, s => s.type)

  SECTION_TYPE_CONSTRAINTS.forEach(key => {
    const count = typeCounts[key] ?? 0
    if (count > 1) {
      console.error(`Have ${count} ${key} sections, more than expected.`)
      return
    }
    if (count === 0) {
      result.push({ type: key })
    }
  })

  return result
}

const DATE_FORMAT = 'YYYYMMDD'
const TIME_FORMAT = 'HHmm'

const writeableSection = ({ type, data }) => {
  switch (type) {
    case STOREFRONT_SECTION_TYPES.COLLECTION_GROUP:
      return { type, data: { collection_group_id: data.id } }
    case STOREFRONT_SECTION_TYPES.COLLECTION_SLIDER:
      return { type, data: { collection_ids: (data ?? []).map(c => c.id) } }
    case STOREFRONT_SECTION_TYPES.PRODUCT_SLIDER:
      return { type, data: { collection_id: data.id } }
    default:
      return { type }
  }
}

const Storefront = ({
  classes,
  match: {
    params: { id: storefrontId }
  }
}) => {
  const { isMobileScreen } = useMediaQuery()
  const { showAlertError, showAlertGeneral } = useAlerts()
  const {
    editing: editingCollections,
    Component: CollectionsToggleButton
  } = useToggleButton()

  const {
    editing: editingSections,
    Component: SectionsToggleButton
  } = useToggleButton()
  const {
    location,
    locations,
    locationId,
    showAllLocationIds
  } = useLocationsContext()

  const { isLoading, fetchStorefront, updateStorefront } = useAdminStorefront({
    id: storefrontId
  })

  const [localCollectionsSlider, setLocalCollectionsSlider] = useState([])
  const [initialStorefrontInfo, setInitialStorefrontInfo] = useState({}) // to track changes
  const [storefrontInfo, setStorefrontInfo] = useState({})
  const [storefrontSections, setStorefrontSections] = useState([])
  const [localStorefrontSections, setLocalStorefrontSections] = useState([])

  const [addCollectionsOpen, setAddCollectionsOpen] = useState(false)
  const [addSectionCollectionsOpen, setAddSectionCollectionsOpen] = useState(
    false
  )
  const [
    addSectionCollectionGroupsOpen,
    setAddSectionCollectionGroupsOpen
  ] = useState(false)
  const [showPreview, setShowPreview] = useState(false)

  const fetchStorefrontInLocation = async () => {
    const result = (await fetchStorefront(storefrontId)) ?? []
    const missingSections = getMissingSections(result.storefront_sections)

    if (missingSections.length > 0) {
      showAlertError(
        `Missing sections: ${missingSections
          .map(s => s.type)
          .join(', ')}. They've been added, save to fix on server.`
      )
    }

    // The GridDropZone needs to have ids for GridItem keys
    const storefrontWithIds = result.storefront_sections.map((s, id) => ({
      id,
      ...s
    }))
    const filledResult = result.storefront_sections
      .concat(missingSections)
      .map((s, id) => ({ id, ...s }))
    setStorefrontSections(storefrontWithIds)
    setLocalStorefrontSections(filledResult)
    setLocalCollectionsSlider(filledResult.find(isCollectionSlider)?.data ?? [])
    delete result.storefront_sections
    setInitialStorefrontInfo(result)
    setStorefrontInfo(result)
  }

  const locationMatch = locationId
    ? storefrontInfo.location_id === locationId
    : true

  const storefrontLocation = locations?.find(
    loc => loc.id === storefrontInfo.location_id
  )?.display_name

  useConditionalEffect(() => {
    if (showAllLocationIds) {
      setStorefrontInfo({})
      setStorefrontSections([])
      setLocalStorefrontSections([])
      setLocalCollectionsSlider([])
      return
    }

    if (storefrontInfo.publish_at && !locationMatch) {
      showAlertGeneral(
        `Switch to the ${storefrontLocation} to modify this Storefront`
      )
    } else {
      fetchStorefrontInLocation()
    }
  }, [locationId])

  useConditionalEffect(() => {
    if (showAllLocationIds) {
      showAlertGeneral(ALL_LOCATION_UPDATE_MSG)
    }
  }, [showAllLocationIds])

  const storefrontNeedSave = !isEqual(
    localStorefrontSections,
    storefrontSections
  )
  const needSave =
    (!isLoading && storefrontNeedSave) ||
    !isEqual(initialStorefrontInfo, storefrontInfo)

  const updateLocalCollectionsSlider = newCollections => {
    setLocalCollectionsSlider(newCollections)

    setLocalStorefrontSections(
      localStorefrontSections.map(section =>
        isCollectionSlider(section)
          ? { ...section, data: newCollections }
          : section
      )
    )
  }

  const handleAddCollections = newCollections => {
    updateLocalCollectionsSlider(
      uniqBy(localCollectionsSlider.concat(newCollections), c => c.id)
    )
  }

  const nextIndex = () => {
    const nextIdx =
      1 +
      localStorefrontSections.reduce(
        (acc, curr) => (acc > curr.id ? acc : curr.id),
        0
      )

    return nextIdx
  }

  const handleAddSections = newCollections => {
    const newSections = newCollections.map((c, idx) => ({
      type: STOREFRONT_SECTION_TYPES.PRODUCT_SLIDER,
      data: c,
      // The GridDropZone needs to have ids for GridItem keys
      id: idx + nextIndex()
    }))
    setLocalStorefrontSections(localStorefrontSections.concat(newSections))
  }

  const handleAddCollectionGroupSections = newCollectionGroups => {
    const newSections = newCollectionGroups.map((c, idx) => ({
      type: STOREFRONT_SECTION_TYPES.COLLECTION_GROUP,
      data: c,
      // The GridDropZone needs to have ids for GridItem keys
      id: idx + nextIndex()
    }))
    setLocalStorefrontSections(localStorefrontSections.concat(newSections))
  }

  const handlePublish = async () => {
    const { name, publish_at } = storefrontInfo
    await updateStorefront(
      {
        name,
        publish_at_date: parseDateIgnoreTZ(publish_at).format(DATE_FORMAT),
        publish_at_time: parseDateIgnoreTZ(publish_at).format(TIME_FORMAT),
        publish_at_timezone: location.timezone,
        sections: localStorefrontSections.map(section =>
          writeableSection(section)
        )
      },
      locationId
    )

    if (addCollectionsOpen) {
      setAddCollectionsOpen(false)
    }

    if (addSectionCollectionsOpen) {
      setAddSectionCollectionsOpen(false)
    }

    fetchStorefrontInLocation()
  }

  const getCollectionSliderProps = useCallback(
    () => ({
      locationId
    }),
    [locationId]
  )

  const allowRemoval = section => {
    return isProductSlider(section) || isCollectionGroup(section)
  }

  const getSectionProps = useCallback(
    section => ({
      locationId,
      ...(allowRemoval(section) ? {} : { onRemove: null })
    }),
    [locationId, localStorefrontSections]
  )

  const actions = showPreview ? (
    <Button
      className={classes.closeButton}
      size={SIZE.medium}
      color={COLOR.default}
      variant={BUTTON_VARIANT.contained}
      endIcon={<CloseIcon />}
      adaptive
      label="Preview"
      onClick={() => setShowPreview(false)}
    />
  ) : (
    <>
      <Button
        size={SIZE.medium}
        color={COLOR.default}
        variant={BUTTON_VARIANT.contained}
        startIcon={<EyeIcon />}
        adaptive
        label="Preview"
        onClick={() => setShowPreview(true)}
      >
        Preview
      </Button>
      <Button
        size={SIZE.medium}
        color={COLOR.primary}
        variant={BUTTON_VARIANT.contained}
        startIcon={<SubmitIcon />}
        adaptive
        label="Publish"
        disabled={!needSave || !locationMatch}
        onClick={handlePublish}
      >
        Publish
      </Button>
    </>
  )

  if (isLoading) {
    return (
      <Layout id="storefront">
        <Header title="Loading..." />
        <Box className={classes.root}>
          <Box className={classes.subheader}>
            <Typography variant="h5">Collection Slider</Typography>
            <Skeleton variant="rect" height={34} width={34} />
          </Box>
          <Skeleton variant="rect" height={200} />
          <Box className={classes.subheader}>
            <Typography variant="h5">Sections</Typography>
            <Skeleton variant="rect" height={34} width={34} />
          </Box>
          <Skeleton variant="rect" height={400} />
        </Box>
      </Layout>
    )
  }

  if (showAllLocationIds || !locationMatch) {
    return (
      <Layout id="storefront">
        <Header title="No Storefront Available" />
        <Box className={classes.root}>
          <Box className={classes.subheader}>
            <Typography variant="h5">
              Switch Location to {storefrontLocation} to Edit
            </Typography>
          </Box>
        </Box>
      </Layout>
    )
  }

  return (
    <Layout id="storefront">
      <Header
        title={
          <>
            {storefrontInfo.name}
            <Box ml={1} component="span" position="relative" top="4px">
              {storefrontInfo.current ? (
                <AiFillCheckCircle color={colorsAF.fast} fontSize={26} />
              ) : (
                <AiFillCalendar color={colorsAF.lightGray} fontSize={26} />
              )}
            </Box>
          </>
        }
        breadcrumbs={[
          {
            title: 'Storefronts',
            link: URL.ADMIN_STOREFRONT
          }
        ]}
        actions={actions}
      />
      {showPreview ? (
        <Box className={classes.root}>
          <Box className={classes.subheader}>
            <Typography variant="h5">Preview</Typography>
          </Box>
          <StorefrontPreview storefront={localStorefrontSections} />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection={isMobileScreen ? 'column' : 'row'}
            mb={2}
          >
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
              width="100%"
            >
              <Typography variant="body2">Storefront Name</Typography>
              <TextField
                fullWidth
                className={classes.thinInput}
                value={storefrontInfo.name}
                onChange={e =>
                  setStorefrontInfo({
                    ...storefrontInfo,
                    name: e.target.value
                  })
                }
              />
            </Box>
            <Box ml={isMobileScreen ? 0 : 1} width="100%">
              <Typography variant="body2">Publish Date/Time</Typography>
              <DatePicker
                fullWidth
                className={classes.thinInput}
                placeholder="Day"
                name="publish-date-time"
                withTime
                dataTest="promotion-starts-at"
                onChange={publish_at =>
                  setStorefrontInfo({
                    ...storefrontInfo,
                    publish_at
                  })
                }
                value={parseDateIgnoreTZ(storefrontInfo.publish_at)}
              />
              <Box mt={-1}>
                <Typography variant="caption">{location?.timezone}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.root}>
            <Box className={classes.subheader}>
              <Typography variant="h5">Collection Slider</Typography>
              <Box display="flex">
                {editingCollections && (
                  <Button
                    className={classes.addButton}
                    size={SIZE.medium}
                    color={COLOR.default}
                    variant={BUTTON_VARIANT.contained}
                    startIcon={<AddIcon fontSize={14} />}
                    label="Collection"
                    onClick={() => setAddCollectionsOpen(true)}
                  >
                    Collection
                  </Button>
                )}
                <CollectionsToggleButton />
              </Box>
            </Box>
            <Box>
              <DragNDropGrid
                items={localCollectionsSlider}
                setItems={updateLocalCollectionsSlider}
                ItemComponent={DraggableCollection}
                getItemProps={getCollectionSliderProps}
                dragAndRemoveMode={editingCollections}
                mode={
                  editingCollections
                    ? DRAG_N_DROP_MODE.REMOVE
                    : DRAG_N_DROP_MODE.ORDERING
                }
                {...DRAGGABLE_COLLECTION_SMALL_DIMS}
              />
              <AddCollectionDialog
                title="Add Collections"
                subtitle="Select the collections you want to showcase in the consumer app collection carousel."
                open={addCollectionsOpen}
                onClose={() => setAddCollectionsOpen(false)}
                existingCollections={localCollectionsSlider}
                onConfirm={handleAddCollections}
              />
            </Box>

            <Box className={classes.subheader}>
              <Typography variant="h5">Sections</Typography>
              <Box display="flex">
                {editingSections && (
                  <>
                    <Button
                      className={classes.addButton}
                      size={SIZE.medium}
                      color={COLOR.default}
                      variant={BUTTON_VARIANT.contained}
                      startIcon={<AddIcon fontSize={14} />}
                      label="Collection"
                      onClick={() => setAddSectionCollectionsOpen(true)}
                    >
                      Collection
                    </Button>
                    <Button
                      className={classes.addButton}
                      size={SIZE.medium}
                      color={COLOR.default}
                      variant={BUTTON_VARIANT.outlined}
                      startIcon={<AddIcon fontSize={14} />}
                      label="CollectionGroup"
                      onClick={() => setAddSectionCollectionGroupsOpen(true)}
                    >
                      Collection Group
                    </Button>
                  </>
                )}
                <SectionsToggleButton />
              </Box>
            </Box>
            <Box>
              <DragNDropGrid
                items={localStorefrontSections}
                setItems={setLocalStorefrontSections}
                ItemComponent={DraggableSection}
                getItemProps={getSectionProps}
                dragAndRemoveMode={editingSections}
                mode={
                  editingSections
                    ? DRAG_N_DROP_MODE.REMOVE
                    : DRAG_N_DROP_MODE.ORDERING
                }
                {...DRAGGABLE_SECTION_DIMS}
              />
              <AddCollectionDialog
                title="Add Product Sliders"
                subtitle="Select the collections you want to showcase in the consumer app as product carousels.."
                open={addSectionCollectionsOpen}
                onClose={() => setAddSectionCollectionsOpen(false)}
                existingCollections={localStorefrontSections
                  .filter(isProductSlider)
                  .map(s => s.data)}
                onConfirm={handleAddSections}
              />
              <AddCollectionGroupDialog
                title="Add Collection Groups"
                subtitle="Select the collection groups you want to showcase in the consumer app"
                open={addSectionCollectionGroupsOpen}
                onClose={() => setAddSectionCollectionGroupsOpen(false)}
                existingCollections={localStorefrontSections
                  .filter(isCollectionGroup)
                  .map(s => s.data)}
                onConfirm={handleAddCollectionGroupSections}
              />
            </Box>
          </Box>
        </>
      )}
    </Layout>
  )
}

Storefront.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Storefront)
