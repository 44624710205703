import React, { useState, createContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import { api, downloadApi } from 'api'
import { useLocalStorageContext } from 'context'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const { authToken } = useLocalStorageContext()
  const [currentUser, setCurrentUser] = useState(null)
  const [isInitialized, setIsInitialized] = useState(false)

  const authorization = `Bearer ${authToken}`
  api.defaults.headers.common.Authorization = authorization
  downloadApi.defaults.headers.common.Authorization = authorization

  const value = useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      isInitialized,
      setIsInitialized
    }),
    [currentUser, isInitialized]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node
}
