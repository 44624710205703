import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

import { USER_ROLES } from './general'

export const makeObjectResolver = schema =>
  yupResolver(yup.object().shape(schema))

export const emailSchema = yup
  .string()
  .email('expected email address')
  .required('email address is required')

export const makePasswordConfirm = ref =>
  yup
    .string()
    .oneOf([yup.ref(ref)], 'Passwords must match')
    .required()

export const passwordSchema = yup.string().required('Password is required')

export const passwordMinStrengthSchema = yup
  .string()
  .min(8, 'Password must have minimum 6 characters')
  .required('Password is required')

export const passwordStrengthSchema = yup
  .string()
  .min(8, 'Password must have minimum 8 characters')
  .matches(/[A-Z]/, 'Password must have at least one uppercase character')
  .matches(/\d/, 'Password must contain a number')
  .matches(
    /[*.! @#$%^&(){}[\]:;<>,.?/~_+\-=|\\]/,
    'Password must contain a special character'
  )
  .required('Password is required')

export const nameSchema = yup.string().required('Name is required')

export const userRoleSchema = yup.string().oneOf(
  Object.values(USER_ROLES).map(s => s.value),
  'Must be a valid user role'
)

export const locationSchema = yup
  .string()
  .nullable()
  .required('A location is required')

export const userTypeSchema = yup.bool()

export const blockedSchema = yup.bool()

export const collectionsImageSchema = yup
  .mixed()
  .required('An image is required')

export const makeImageSchemaWithResolution = (width, height) =>
  yup
    .mixed()
    .test(
      'checkWidthHeight',
      `Expected image with dimensions, ${width}x${height}`,
      file =>
        file === undefined || file === null
          ? true
          : new Promise((resolve, reject) => {
              try {
                const img = new Image()
                img.src = URL.createObjectURL(file)
                img.onload = () => {
                  if (img.width === width && img.height === height) {
                    resolve(true)
                  } else {
                    resolve(false)
                  }
                  URL.revokeObjectURL(img.src)
                }
                img.onerror = () => {
                  resolve(false)
                  URL.revokeObjectURL(img.src)
                }
              } catch (e) {
                resolve(false)
              }
            })
    )

export const makeImageSchemaWithMinMaxSquareResolution = (
  minLength,
  maxLength
) =>
  yup
    .mixed()
    .test(
      'checkWidthHeight',
      `Expected square image between ${minLength}x${minLength}px and ${maxLength}x${maxLength}px`,
      file =>
        file === undefined || file === null
          ? true
          : new Promise((resolve, reject) => {
              try {
                const img = new Image()
                img.src = URL.createObjectURL(file)
                img.onload = () => {
                  if (
                    img.width === img.height &&
                    img.width >= minLength &&
                    img.width <= maxLength
                  ) {
                    resolve(true)
                  } else {
                    resolve(false)
                  }
                  URL.revokeObjectURL(img.src)
                }
                img.onerror = () => {
                  resolve(false)
                  URL.revokeObjectURL(img.src)
                }
              } catch (e) {
                resolve(false)
              }
            })
    )

export const makeMediaSchemaWithImageResolution = (width, height) =>
  yup
    .mixed()
    .test(
      'checkWidthHeight',
      `Expected image with dimensions, ${width}x${height}`,
      file =>
        file === undefined || file === null
          ? true
          : new Promise((resolve, reject) => {
              try {
                if (file.type === 'video/mp4') {
                  resolve(true)
                }
                const img = new Image()
                img.src = URL.createObjectURL(file)
                img.onload = () => {
                  if (img.width === width && img.height === height) {
                    resolve(true)
                  } else {
                    resolve(false)
                  }
                  URL.revokeObjectURL(img.src)
                }
                img.onerror = () => {
                  resolve(false)
                  URL.revokeObjectURL(img.src)
                }
              } catch (e) {
                resolve(false)
              }
            })
    )
