import { addOpacity } from 'utils/general'
const jssStyles = theme => ({
  statusCell: {
    width: 75
  },
  statusTag: {
    borderRadius: 5,
    width: 'fit-content',
    padding: theme.spacing(0.5, 1)
  },
  active: {
    backgroundColor: addOpacity(theme.palette.warnings.success, 0.1),
    color: theme.palette.warnings.success
  },
  inactive: {
    backgroundColor: addOpacity(theme.palette.warnings.danger, 0.1),
    color: theme.palette.warnings.danger
  },
  textField: {
    '& .MuiInputBase-root': {
      height: 44
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 12px 14px'
    }
  },
  subtitle: {
    color: theme.palette.grays.dark,
    fontSize: 10
  },
  TableContainer: {
    height: 'calc(100vh - 229px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 139px)'
    }
  }
})

export default jssStyles
