import React, { useEffect } from 'react'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import Box from '@material-ui/core/Box'

import { DRAG_N_DROP_MODE } from 'constants/enums'
import { DRAGGABLE_IMAGE_DIMS } from 'constants/styles'

import Dialog from 'components/Dialog'
import DragNDropGrid from 'components/DragNDropGrid'
import DraggableImage from 'components/DraggableImage'

const ReorderImagesDialog = ({
  name,
  title,
  subtitle,
  open,
  originalImages,
  onClose,
  onConfirm
}) => {
  const {
    register,
    unregister,
    setValue,
    watch,
    clearErrors
  } = useFormContext()

  useEffect(() => {
    register(name)
    return () => {
      unregister(name)
    }
  }, [register, unregister, name])

  const { [name]: currentImages } = watch(
    [name].filter(n => n && typeof n === 'string')
  )

  const removeImage = rImage =>
    setValue(name, [
      ...currentImages.filter(img => img.id !== rImage.id, {
        shouldDirty: true
      })
    ])

  const handleClose = () => {
    clearErrors(name)
    onClose()
  }
  const handleConfirm = () => {
    onConfirm && onConfirm()
    onClose()
  }
  return (
    <Dialog
      title={title}
      subtitle={subtitle}
      open={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirmText="Commit"
      confirmDisabled={isEqual(originalImages, currentImages)}
    >
      <Box my={2}>
        <DragNDropGrid
          items={currentImages}
          setItems={i => setValue(name, i, { shouldDirty: true })}
          ItemComponent={DraggableImage}
          onRemove={removeImage}
          mode={DRAG_N_DROP_MODE.ORDERING}
          dragAndRemoveMode
          {...DRAGGABLE_IMAGE_DIMS}
        />
      </Box>
    </Dialog>
  )
}

ReorderImagesDialog.defaultProps = {
  originalImages: []
}

ReorderImagesDialog.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  originalImages: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func
}

export default ReorderImagesDialog
