import * as yup from 'yup'
import fromPairs from 'lodash/fromPairs'

import {
  COLLECTION_IMAGE_WIDTH_PX,
  COLLECTION_IMAGE_HEIGHT_PX,
  COLLECTION_DESCRIPTION_MAX_CHARACTERS,
  EDITORIAL_COVER_HEIGHT_PX,
  EDITORIAL_COVER_WIDTH_PX
} from 'constants/collections'
import {
  makeImageSchemaWithResolution,
  makeMediaSchemaWithImageResolution
} from 'constants/yupSchemas'

export const FORM_FIELDS_FULL = {
  // Collection fields
  TITLE: {
    name: 'title',
    collectionField: true,
    locationCollectionField: false,
    schema: yup.string().min(2, 'must have at least 2 characters')
  },
  SUBHEADER: {
    name: 'subheader',
    collectionField: true,
    locationCollectionField: false,
    schema: yup.string().nullable()
  },
  DESCRIPTION: {
    name: 'description',
    collectionField: true,
    locationCollectionField: false,
    schema: yup
      .string()
      .max(
        COLLECTION_DESCRIPTION_MAX_CHARACTERS,
        `Description is to be less than ${COLLECTION_DESCRIPTION_MAX_CHARACTERS} characters in length`
      )
  },
  HAS_SEE_ALL_LINK: {
    name: 'has_see_all_link',
    collectionField: true,
    locationCollectionField: false,
    schema: yup.bool()
  },
  COLLECTION_TYPE: {
    name: 'collection_type',
    collectionField: true,
    locationCollectionField: false,
    schema: yup.string()
  },
  PARENT_COLLECTION_ID: {
    name: 'parent_collection_id',
    collectionField: true,
    locationCollectionField: false,
    schema: yup.number().nullable()
  },
  LOCATION_IDS: {
    name: 'location_ids',
    collectionField: true,
    locationCollectionField: false,
    schema: yup.array().of(yup.number())
  },
  // Local collection fields
  LOCATIONS: {
    name: 'locations',
    collectionField: false,
    locationCollectionField: false,
    schema: yup.array().of(yup.object())
  },
  PARENT_COLLECTION: {
    name: 'parent_collection',
    collectionField: false,
    locationCollectionField: false,
    schema: yup.object().nullable()
  },
  IMAGE: {
    name: 'image',
    collectionField: true,
    locationCollectionField: false,
    schema: makeImageSchemaWithResolution(
      COLLECTION_IMAGE_WIDTH_PX,
      COLLECTION_IMAGE_HEIGHT_PX
    )
  },
  IMAGE_URL: {
    name: 'image_url',
    collectionField: false,
    locationCollectionField: false,
    schema: yup.string().nullable()
  },
  EDITORIAL_MEDIA: {
    name: 'editorial_media',
    collectionField: true,
    locationCollectionField: false,
    schema: makeMediaSchemaWithImageResolution(
      EDITORIAL_COVER_WIDTH_PX,
      EDITORIAL_COVER_HEIGHT_PX
    )
  },
  EDITORIAL_MEDIA_URL: {
    name: 'editorial_media_url',
    collectionField: false,
    locationCollectionField: false,
    schema: yup.string().nullable()
  },
  // Location collection fields
  PRODUCT_SORT: {
    name: 'product_sort',
    collectionField: false,
    locationCollectionField: true,
    schema: yup.string()
  },
  PRODUCT_IDS: {
    name: 'product_ids',
    collectionField: false,
    locationCollectionField: true,
    schema: yup.array().of(yup.number())
  },
  FEATURED_PRODUCT_IDS: {
    name: 'featured_product_ids',
    collectionField: false,
    locationCollectionField: true,
    schema: yup.array().of(yup.number())
  },
  CHILDREN_IDS: {
    name: 'child_collection_ids',
    collectionField: false,
    locationCollectionField: true,
    schema: yup.array().of(yup.number())
  },
  // Local location collection fields
  PRODUCTS: {
    name: 'products',
    collectionField: false,
    locationCollectionField: false,
    schema: yup.array().of(yup.object())
  },
  CHILDREN: {
    name: 'children',
    collectionField: false,
    locationCollectionField: false,
    schema: yup.array().of(yup.object())
  }
}

export const FORM_FIELDS = fromPairs(
  Object.entries(FORM_FIELDS_FULL).map(([key, { name }]) => [key, name])
)
export const COLLECTION_FIELDS = Object.values(FORM_FIELDS_FULL)
  .filter(v => v.collectionField)
  .map(v => v.name)
export const LOCOLLECTION_FIELDS = Object.values(FORM_FIELDS_FULL)
  .filter(v => v.locationCollectionField)
  .map(v => v.name)
export const SCHEMAS = fromPairs(
  Object.values(FORM_FIELDS_FULL).map(({ name, schema }) => [name, schema])
)

export const VIDEO_TYPE = 'video'
export const IMAGE_TYPE = 'image'
