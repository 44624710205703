const jssStyles = theme => ({
  header: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  text: {
    color: theme.palette.grays.dark,
    marginTop: 24,
    maxHeight: 240,
    overflow: 'auto',
    lineHeight: '19px',
    whiteSpace: 'pre-line'
  },
  textField: {
    flex: 1,
    width: '100%'
  },
  input: {
    padding: 0
  }
})

export default jssStyles
