import { useState } from 'react'
import { isEmpty, keyBy } from 'lodash'

// TODO(exrhizo): refactor out the axios dependency
import axios from 'axios'

import { api } from 'api'
import { useAlerts, useApiCancel } from 'hooks'
import { getPerPageDefault } from 'utils/api'

const useResourceList = ({ url = '/' }) => {
  const { showAlertError } = useAlerts()

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)
  const [meta, setMeta] = useState({})
  const [params, setParams] = useState(null)
  const [error, setError] = useState(null)

  const [listCancel, getListCancel] = useApiCancel()

  const list = async ({ page = 1, per_page, ...newParams } = {}) => {
    try {
      listCancel()
      // Otherwise there is a rerender for each set call.
      // (@thomashintz) would be better to put all the state together and use useReducer
      setIsLoading(true)
      setParams(newParams)
      setError(null)
      if (page === 1) {
        setData(null)
        setMeta({})
      }
      const res = await api.get(`${url}`, {
        params: {
          page,
          per_page: per_page ? per_page : getPerPageDefault(),
          ...newParams
        },
        cancelToken: new axios.CancelToken(getListCancel)
      })
      setData(originalData =>
        page === 1 ? res.data : [...originalData, ...res.data]
      )
      setMeta(res.meta)
      setIsLoading(false)
      return res.data
    } catch (e) {
      // Ignore cancel because a new request has been sent
      if (!axios.isCancel(e)) {
        setIsLoading(false)
        showAlertError('Error getting results from server.')
        console.error(e)
        setError(e)
      }
    }
  }

  const clearList = () => {
    setData(null)
    setMeta({})
    setError(null)
    setIsLoading(false)
    listCancel()
  }

  const updateItemsLocally = items => {
    const newAtID = keyBy(items, item => item.id)
    const oldAtID = keyBy(data, d => d)
    const newItems = data.map(item => newAtID[item.id] ?? item)
    newItems.push(...Object.keys(newAtID).filter(id => id in oldAtID))
    setData(newItems)
  }

  const hasNext = Boolean(!isEmpty(meta) && meta.page < meta.num_pages)
  const listNext = () => hasNext && list({ ...params, page: meta?.page + 1 })
  const relist = () => list({ ...params, page: 1 })

  return {
    isLoading,
    hasData: data !== null,
    error,
    data,
    meta,
    count: meta?.total_count,
    hasNext,
    list,
    listNext,
    clearList,
    relist,
    updateItemsLocally
  }
}

export default useResourceList
