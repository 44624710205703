import { BoxShadowFaint, ABSOLUTE_FILL } from 'constants/styles'

const jssStyles = theme => ({
  root: {
    position: 'relative',
    boxShadow: BoxShadowFaint,
    overflow: 'hidden',
    borderRadius: 12,
    margin: 'auto',
    border: '2px solid transparent'
  },
  faded: {
    opacity: 0.5
  },
  featured: {
    border: '2px solid #FFD700'
  },
  details: {
    ...ABSOLUTE_FILL,
    width: '100%',
    height: '100%',
    padding: theme.spacing(4, 2, 2, 2)
  },
  overlay: {
    ...ABSOLUTE_FILL,
    padding: theme.spacing(1, 1.5)
  },
  grab: {
    marginLeft: theme.spacing(-1)
  },
  image: {
    width: '100%',
    height: 167,
    '& > img': {
      width: '100%',
      height: '100%',
      borderRadius: 5
    }
  }
})

export default jssStyles
