import { useResourceRQ, useResourceListRQ } from 'hooks'
import { downloadApi } from 'api'

import {
  purchaseOrderEndpoint,
  purchaseOrderExportEndpoint,
  variantPurchaseOrderListEndpoint
} from 'api/endpoints'

const useAdminPurchaseOrder = purchaseOrderId => {
  const {
    create: createPurchaseOrder,
    data: purchaseOrder,
    update: updatePurchaseOrder,
    isLoading: isLoadingPurchaseOrder,
    isError: isPurchaseOrderError,
    error: purchaseOrderError
  } = useResourceRQ({
    endpoint: purchaseOrderEndpoint,
    args: [purchaseOrderId],
    name: 'purchase_order',
    fetchingEnabled: Boolean(purchaseOrderId),
    dependents: [{ func: variantPurchaseOrderListEndpoint }]
  })

  const { destroy: destroyPurchaseOrder } = useResourceRQ({
    endpoint: purchaseOrderEndpoint,
    args: [purchaseOrderId],
    fetchingEnabled: false
  })

  const getExport = async id => {
    await downloadApi.get(purchaseOrderExportEndpoint(id))
  }

  return {
    purchaseOrder,
    updatePurchaseOrder,
    createPurchaseOrder,
    isLoadingPurchaseOrder,
    isPurchaseOrderError,
    purchaseOrderError,
    getExport,
    destroyPurchaseOrder
  }
}

export default useAdminPurchaseOrder
