import React from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { pickBy } from 'lodash'
import * as yup from 'yup'
import { Controller, useForm, FormProvider } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from 'components/TextField'

import {
  DROPZONE_IMAGE_TYPES,
  COLLECTION_IMAGE_WIDTH_PX,
  COLLECTION_IMAGE_HEIGHT_PX,
  DROPZONE_IMAGE_PREVIEW_HEIGHT_PX,
  COLLECTION_TYPE_BY_LABEL,
  COLLECTION_TYPES
} from 'constants/collections'
import {
  makeObjectResolver,
  makeImageSchemaWithResolution
} from 'constants/yupSchemas'
import Button from 'components/Button'
import RadioGroup from 'components/RadioGroup'
import ImageDropzone from 'components/ImageDropzone'
import Dialog from 'components/Dialog'

import styles from './CreateCollectionModalStyles'

const TEST_ID = 'collection'

const COLLECTION_TYPES_WITHOUT_BRAND = pickBy(
  COLLECTION_TYPE_BY_LABEL,
  v => v !== COLLECTION_TYPES.brand
)

const FIELDS = {
  title: 'title',
  collectionType: 'collection_type',
  image: 'image'
}

const yupUserSchemas = {
  [FIELDS.title]: yup.string().required('Title is required'),
  [FIELDS.collectionType]: yup.string().required('Collection Type is required'),
  [FIELDS.image]: makeImageSchemaWithResolution(
    COLLECTION_IMAGE_WIDTH_PX,
    COLLECTION_IMAGE_HEIGHT_PX
  )
}

const CreateCollectionModal = ({ open, createCollection, onClose }) => {
  const formMethods = useForm({
    mode: 'onSubmit',
    resolver: makeObjectResolver(yupUserSchemas)
  })
  const { register, handleSubmit, control, errors } = formMethods

  const createAndClose = collection => {
    const typeLabel = collection[FIELDS.collectionType]
    const image = collection[FIELDS.image]
    const imageObject = image ? { [FIELDS.image]: image } : null
    createCollection({
      [FIELDS.title]: collection[FIELDS.title],
      [FIELDS.collectionType]: COLLECTION_TYPES_WITHOUT_BRAND[typeLabel],
      ...imageObject
    })
    onClose()
  }

  return (
    <Dialog
      title="Create Collections"
      open={open}
      onCancel={onClose}
      onConfirm={handleSubmit(createAndClose)}
      dataTest={TEST_ID}
    >
      <FormProvider {...formMethods}>
        <Box>
          <Typography variant="h6">Collection Name</Typography>
          <TextField
            name={FIELDS.title}
            placeholder="Enter Name"
            fullWidth
            inputRef={register}
            error={Boolean(errors[FIELDS.title]?.message)}
            helperText={errors[FIELDS.title]?.message}
            dataTest={`${TEST_ID}-create-name`}
          />
        </Box>
        <Box>
          <Typography variant="h6">Collection type</Typography>
          <Controller
            name={FIELDS.collectionType}
            control={control}
            defaultValue={Object.keys(COLLECTION_TYPES_WITHOUT_BRAND)[0]}
            render={({ name, onChange, value }) => (
              <RadioGroup
                dataTest={name}
                name={name}
                labels={Object.keys(COLLECTION_TYPES_WITHOUT_BRAND)}
                value={value}
                onChange={e => onChange(e.target.value)}
                withBorders
                error={errors[name]?.message}
              />
            )}
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6">Collection Cover</Typography>
          <Box height={DROPZONE_IMAGE_PREVIEW_HEIGHT_PX} mt={2}>
            <ImageDropzone
              dataTest={`${TEST_ID}-image`}
              name={FIELDS.image}
              accept={DROPZONE_IMAGE_TYPES}
            />
          </Box>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

CreateCollectionModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  createCollection: PropTypes.func.isRequired,
  onClose: PropTypes.func
}

export default withStyles(styles)(CreateCollectionModal)
