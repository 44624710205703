import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { useMenu } from 'hooks'

import styles from './AccessoryOptionsStyles'

const AccessoryOptionsItem = ({
  classes,
  accessoryOption,
  onSetActive,
  onClickEdit,
  dataTest
}) => {
  const { open, anchorEl, handleToggleMenu, handleClose } = useMenu()

  return (
    <TableRow data-test={`${dataTest}-item`}>
      <TableCell data-test={`${dataTest}-item-title`}>
        {accessoryOption.name}
      </TableCell>
      <TableCell>{accessoryOption.variants_count} Products</TableCell>
      <TableCell>{moment(accessoryOption.created_at).format('L')}</TableCell>
      <TableCell className={classes.statusCell}>
        <Box
          className={classNames({
            [classes.statusTag]: true,
            [classes.active]: accessoryOption.active,
            [classes.inactive]: !accessoryOption.active
          })}
          data-test={`${dataTest}-item-status-tag`}
        >
          {accessoryOption.active ? 'Active' : 'Inactive'}
        </Box>
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={e => handleToggleMenu(e)}
          data-test={`${dataTest}-item-menu`}
        >
          <MoreVertIcon />
          <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
            {accessoryOption.active ? (
              <MenuItem
                onClick={() => onSetActive(false)}
                data-test={`${dataTest}-item-status`}
              >
                Inactive
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => onSetActive(true)}
                data-test={`${dataTest}-item-status`}
              >
                Active
              </MenuItem>
            )}
            <MenuItem
              onClick={() => onClickEdit(accessoryOption)}
              data-test={`${dataTest}-item-edit`}
            >
              Edit
            </MenuItem>
          </Menu>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

AccessoryOptionsItem.propTypes = {
  classes: PropTypes.object.isRequired,
  accessoryOption: PropTypes.object,
  onSetActive: PropTypes.func,
  onClickEdit: PropTypes.func,
  dataTest: PropTypes.string
}

export default withStyles(styles)(AccessoryOptionsItem)
