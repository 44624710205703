import isEmpty from 'lodash/isEmpty'
import { LIST_LABELS } from 'constants/queryParams'

export const sortPossibilitiesFromColumns = columns =>
  columns
    .flatMap(c => c.sortKeys)
    .filter(Boolean)
    .map(value => ({ label: LIST_LABELS[value], value }))

export const dateRangeToQueryParams = (name, [start, end], location) => {
  const startDate = Boolean(location?.id)
    ? start
        .tz(location.timezone)
        .startOf('day')
        .format()
    : start.startOf('day').format()

  const endDate = Boolean(location?.id)
    ? end
        .tz(location.timezone)
        .endOf('day')
        .format()
    : end.endOf('day').format()

  return {
    [name]: `${startDate},${endDate}`,
    [`${name}.rel`]: 'between'
  }
}
