import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { LIST_PATHS } from 'constants/queryParams'

import Header from 'components/Header'
import DataTable from 'components/DataTable'
import Select from 'components/Select'
import GlanceTile from 'components/GlanceTile'
import AllColorsItem from 'containers/admin/ColorList/AllColorsItem'

import styles from './ColorListStyles'

const columns = [
  {
    title: 'Colorway',
    sortKeys: [LIST_PATHS.COLOR_LABEL]
  },
  {
    title: 'Internal',
    sortKeys: [LIST_PATHS.COLOR_SHOPIFY_COLOR]
  },
  {
    title: 'Master',
    sortKeys: [LIST_PATHS.COLOR_MASTER_COLOR]
  },
  {
    title: 'Usage',
    sortKeys: [LIST_PATHS.COLOR_VARIANTS_COUNT]
  },
  {
    title: 'Created On',
    sortKeys: [LIST_PATHS.COLOR_CREATED_AT]
  },
  {
    title: 'Status'
  },
  { title: '' }
]

const AllColorsPane = ({
  classes,
  query,
  colors = [],
  hasColors,
  colorsCount,
  hasListNext,
  isLoadingList,
  updateQuery,
  listNext,
  handleQueryChange,
  updateColorway,
  onEditClick
}) => {
  const handleSetActive = async (id, active) => {
    await updateColorway({ id, payload: { active } })
    updateQuery(query)
  }

  const filters = (
    <Select
      minimal
      name={LIST_PATHS.COLOR_STATUS}
      innerLabel="Status"
      value={query?.[LIST_PATHS.COLOR_STATUS] || ''}
      items={[
        { label: 'All', value: '' },
        { label: 'Active', value: 'true' },
        { label: 'Inactive', value: 'false' }
      ]}
      onChange={handleQueryChange}
    />
  )

  const summarizers = (
    <GlanceTile minimal label="Total Colorways" value={colorsCount} />
  )

  return (
    <>
      <Header filters={filters} summarizers={summarizers} hideHeader />
      <DataTable
        classes={{ TableContainer: classes.TableContainer }}
        columns={columns}
        message={colors?.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingList}
        hasListNext={hasListNext}
        listNext={listNext}
        query={query}
        updateQuery={updateQuery}
      >
        {hasColors &&
          colors.map(color => (
            <AllColorsItem
              color={color}
              key={color.id}
              onSetActive={active => handleSetActive(color.id, active)}
              onEditClick={onEditClick}
            />
          ))}
      </DataTable>
    </>
  )
}

AllColorsPane.propTypes = {
  classes: PropTypes.object.isRequired,
  colors: PropTypes.object,
  query: PropTypes.object,
  isLoadingList: PropTypes.bool,
  hasListNext: PropTypes.bool,
  listNext: PropTypes.func,
  updateQuery: PropTypes.func,
  handleQueryChange: PropTypes.func,
  onEditClick: PropTypes.func,
  hasColors: PropTypes.bool,
  colorsCount: PropTypes.number,
  updateColorway: PropTypes.func
}

export default withStyles(styles)(AllColorsPane)
