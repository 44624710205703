import React from 'react'
import PropTypes from 'prop-types'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'

import { LIST_PATHS } from 'constants/queryParams'
import { SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'
import { TO_LIST_COLUMNS } from 'constants/transferOrders'

import { useLocationsContext } from 'context'
import { useQuery, useNavigation, useAdminTransferOrdersList } from 'hooks'

import Layout from 'components/Layout'
import Header from 'components/Header'
import DataTable from 'components/DataTable'
import SearchInput from 'components/SearchInput'
import Button from 'components/Button'

import TransferOrderItem from './TransferOrderItem'

import styles from './TransferOrderStyles'

const TransferOrders = () => {
  const { getLocationAtId } = useLocationsContext()

  const { query, handleQueryChange, updateQuery } = useQuery(false, {
    locationFilter: LIST_PATHS.LOCATION_ID
  })

  const {
    isFetchingTransferOrders: isLoadingTransferOrders,
    transferOrders,
    hasTransferOrdersNext,
    fetchTransferOrdersNext
  } = useAdminTransferOrdersList(query)

  const { go } = useNavigation({})

  const foldableActions = (
    <>
      <SearchInput
        name="search"
        placeholder="Search TO's"
        value={query.search}
        onChange={handleQueryChange}
      />
    </>
  )

  const actions = (
    <Button
      adaptive
      startIcon={<AddIcon />}
      color="primary"
      variant="contained"
      label="new transfer order button"
      size={SIZE.medium}
      onClick={e => go(e, `${URL.ADMIN_TRANSFER_ORDERS}/new`)}
      dataTest="new-to"
    >
      New TO
    </Button>
  )

  return (
    <Layout>
      <Header
        foldableActions={foldableActions}
        title="Transfer Orders"
        // filters={filters}
        actions={actions}
        breadcrumbs={[
          {
            title: 'Back Of House',
            link: URL.ADMIN_BOH
          }
        ]}
      />
      <DataTable
        columns={TO_LIST_COLUMNS}
        message={transferOrders.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingTransferOrders}
        hasListNext={hasTransferOrdersNext}
        listNext={fetchTransferOrdersNext}
        query={query}
        updateQuery={updateQuery}
      >
        {transferOrders.map(to => (
          <TransferOrderItem
            key={to.id}
            transferOrder={{
              ...to,
              to: getLocationAtId(to.to)?.display_name,
              from: getLocationAtId(to.from)?.display_name
            }}
          />
        ))}
      </DataTable>
    </Layout>
  )
}

TransferOrders.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TransferOrders)
