import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import uniqBy from 'lodash/uniqBy'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminUsers } from 'hooks'
import AutocompleteSearch from 'components/AutocompleteSearch'

import styles from './AutocompleteSearchUserStyles'

const AutocompleteSearchUserRaw = ({
  classes,
  onChange,
  value,
  defaultOpen,
  getOptionLabel,
  placeholder,
  optWithEmail,
  queryParams,
  multiple,
  name,
  ...rest
}) => {
  const { isLoadingUsers, users, listUsers, listUsersNext } = useAdminUsers()
  const [query, setQuery] = useState('')

  useEffect(() => {
    listUsers({ search: query, ...queryParams })
  }, [query])

  const options = useMemo(
    () => uniqBy((users ?? []).concat(value), v => v.id),
    [users]
  )

  return (
    <AutocompleteSearch
      name={name}
      defaultOpen={defaultOpen}
      value={value}
      loading={isLoadingUsers}
      setQuery={setQuery}
      onChange={onChange}
      onScrollBottom={listUsersNext}
      options={options}
      placeholder={placeholder}
      multiple={multiple}
      getOptionLabel={getOptionLabel}
      renderOption={(option, { selected }) => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          className={selected ? classes.selectedItem : null}
          data-test={`option-${option?.name}`}
        >
          <Typography variant="body1" className={classes.userName}>
            {option.name}
          </Typography>
          {optWithEmail && (
            <Typography
              className={classes.userEmail}
              variant="body1"
              color="textSecondary"
            >
              {option.email}
            </Typography>
          )}
        </Box>
      )}
      {...rest}
    />
  )
}

AutocompleteSearchUserRaw.defaultProps = {
  value: [],
  defaultOpen: false,
  getOptionLabel: opt => opt?.email ?? opt?.name,
  placeholder: 'Search Customers',
  optWithEmail: true,
  multiple: true
}

AutocompleteSearchUserRaw.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string
  ]),
  defaultOpen: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  placeholder: PropTypes.string,
  optWithEmail: PropTypes.bool,
  queryParams: PropTypes.object,
  name: PropTypes.string.isRequired
}

const AutocompleteSearchUser = withStyles(styles)(AutocompleteSearchUserRaw)

const AutocompleteSearchUserControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchUser
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchUserControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export { AutocompleteSearchUser as default, AutocompleteSearchUserControlled }
