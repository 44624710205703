import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import styles from './BlockStyles'

const Block = ({
  classes,
  title,
  p,
  autoHeight,
  withPadding,
  className,
  children
}) => (
  <Box
    p={p}
    className={classNames({
      [classes.root]: true,
      [className]: Boolean(className),
      [classes.autoHeight]: autoHeight,
      [classes.withPadding]: withPadding
    })}
  >
    {title && (
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
    )}
    <Box
      className={classes.container}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      {children}
    </Box>
  </Box>
)

Block.defaultProps = {
  p: 1,
  autoHeight: false
}

Block.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  autoHeight: PropTypes.bool,
  title: PropTypes.string,
  p: PropTypes.number,
  withPadding: PropTypes.bool,
  children: PropTypes.node
}

export default withStyles(styles)(Block)
