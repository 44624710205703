import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import { Typography, TableRow, TableCell, Button } from '@material-ui/core'

import ProductSummary from 'components/ProductSummary'

const BrandCustomerSaleItem = ({
  sale,
  navigateToOrder,
  navigateToProduct
}) => {
  const deliveryStartsAtText =
    sale?.delivery_starts_at && moment(sale?.delivery_starts_at).format('h')
  const deliveryEndsAtText =
    sale?.delivery_ends_at && moment(sale?.delivery_ends_at).format('ha')

  const handleOrderClick = (e, aux = false) =>
    navigateToOrder(e, { id: sale?.order?.id }, aux, 'fromBrandDashboard')
  const handleProductClick = e =>
    navigateToProduct(e, { id: sale?.variant?.product_id }, false)

  return (
    <TableRow>
      <TableCell>
        <Button
          color="primary"
          onClick={e => handleOrderClick(e, false)}
          onAuxClick={e => handleOrderClick(e, true)}
        >
          #{sale?.order_number}
        </Button>
      </TableCell>
      <TableCell>
        <ProductSummary product={sale} onClick={handleProductClick} />
      </TableCell>
      <TableCell>
        <Typography variant="body2">{sale?.sku}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{sale?.quantity}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{sale?.variant?.size ?? 'N/A'}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {sale?.variant?.color?.label ?? 'N/A'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {deliveryStartsAtText}-{deliveryEndsAtText}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{sale?.returned ? 'Yes' : 'No'}</Typography>
      </TableCell>
    </TableRow>
  )
}

BrandCustomerSaleItem.propTypes = {
  sale: PropTypes.object.isRequired,
  navigateToOrder: PropTypes.func.isRequired,
  navigateToProduct: PropTypes.func.isRequired
}

export default BrandCustomerSaleItem
