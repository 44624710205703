import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import Layout from 'components/Layout'
import Header from 'components/Header'
import Button from 'components/Button'
import { TitleElement } from 'components/SiteTitle'
import DataTable from 'components/DataTable'
import SearchInput from 'components/SearchInput'
import InternalUserItem from './InternalUserItem'

import {
  useConditionalEffect,
  useQuery,
  useAdminInternalUserList,
  useNavigation
} from 'hooks'
import { useLocationsContext } from 'context'

import { LIST_PATHS } from 'constants/queryParams'
import { SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'

const InternalUserList = props => {
  const { locationId } = useLocationsContext()

  const { query, updateQuery, handleQueryChange } = useQuery(false, {
    [LIST_PATHS.INTERNAL_USER_DEFAULT_LOCATION_ID]: locationId
  })

  const {
    isLoadingInternalUsers,
    isFetchingInternalUsers,
    internalUsers,
    hasInternalUsersNext,
    fetchInternalUsersNext
  } = useAdminInternalUserList({ params: query })

  useConditionalEffect(() => {
    updateQuery({
      [LIST_PATHS.INTERNAL_USER_DEFAULT_LOCATION_ID]: locationId
    })
  }, [locationId])

  const pageTitle = 'Internal Users'

  const columns = [
    { title: 'ID' },
    { title: 'Name', sortKeys: [LIST_PATHS.INTERNAL_USER_FIRST_NAME] },
    { title: 'Email', sortKeys: [LIST_PATHS.INTERNAL_USER_EMAIL] },
    { title: 'Role' }
  ]

  if (!locationId) {
    columns.push({ title: 'Location' })
  }

  const { handleAddClick: createNewInternalUserClick } = useNavigation({
    url: URL.ADMIN_INTERNAL_USERS
  })

  const actions = (
    <>
      <Button
        adaptive
        label="new internal user button"
        startIcon={<AddIcon />}
        color="primary"
        variant="contained"
        size={SIZE.medium}
        onClick={createNewInternalUserClick}
      >
        New Internal User
      </Button>
    </>
  )

  const foldableActions = (
    <>
      <SearchInput
        name="search"
        placeholder="Search Internal Users..."
        value={query.search}
        onChange={handleQueryChange}
      />
    </>
  )

  return (
    <Layout id="InternalUserList">
      <TitleElement title={pageTitle} />
      <Header
        title={pageTitle}
        actions={actions}
        foldableActions={foldableActions}
      />
      <DataTable
        columns={columns}
        message={
          isLoadingInternalUsers || internalUsers.length > 0
            ? null
            : 'No Internal Users Found'
        }
        isLoadingList={isFetchingInternalUsers}
        hasListNext={hasInternalUsersNext}
        listNext={fetchInternalUsersNext}
        query={query}
        updateQuery={updateQuery}
      >
        {internalUsers.map(internalUser => (
          <InternalUserItem internalUser={internalUser} key={internalUser.id} />
        ))}
      </DataTable>
    </Layout>
  )
}

export default InternalUserList
