import { ALL_LOCATION } from 'constants/general'

export const STORE_CREDIT_UPDATE_TYPES = {
  SET: { name: 'set', label: 'Set to' },
  ADD: { name: 'add', label: 'Increase by' },
  SUB: { name: 'sub', label: 'Reduce by' }
}

export const calculateCreditBalance = (
  { credit_balance: balance },
  { type, amount }
) => {
  let final = Number(balance)
  const num = Number(amount)

  if (type === 'set') {
    final = num
  } else if (type === 'sub') {
    final -= num
  } else if (type === 'add') {
    final += num
  }

  if (final <= 0) {
    return 0
  }

  return final.toFixed(2)
}

export const getMemoSubject = (type, amount) =>
  `${
    STORE_CREDIT_UPDATE_TYPES[
      Object.keys(STORE_CREDIT_UPDATE_TYPES).filter(
        i => STORE_CREDIT_UPDATE_TYPES[i].name === type
      )[0]
    ].label
  } $${amount}`

export const isUserLocationAll = user =>
  user.default_location_id === ALL_LOCATION.id

export const isSwitchLocationsEnabled = user => isUserLocationAll(user)

export const isAdmin = user => ['admin', 'super_admin'].includes(user.role)
export const isSuper = user => user.role === 'super_admin'
