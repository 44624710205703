import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'
import { SIZE_PX } from '../../constants/enums'

const { large } = SIZE_PX

const RemoveIcon = ({ fill, fontSize, className }) => {
  const theme = useTheme()

  return (
    <svg
      width={fontSize || large}
      height={fontSize || large}
      viewBox="0 0 8 8"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.18596 3.33904C1.01845 3.33903 0.672287 3.46187 0.672287 4.00905C0.672287 4.55622 1.06731 4.66789 1.18596 4.66789H6.84753C7.01312 4.66789 7.32771 4.50932 7.32771 4.00905C7.32771 3.50878 7.00759 3.35393 6.84753 3.33904H1.18596Z"
        fill={fill || theme.palette.grays.med}
      />
    </svg>
  )
}

RemoveIcon.propTypes = {
  fill: PropTypes.string,
  fontSize: PropTypes.number,
  className: PropTypes.string
}

export default RemoveIcon
