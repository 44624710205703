import React from 'react'
import PropTypes from 'prop-types'

const SyncIcon = ({ fill, size, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99967 3.83325V1.33325L6.66634 4.66659L9.99967 7.99992V5.49992C12.758 5.49992 14.9997 7.74159 14.9997 10.4999C14.9997 11.3416 14.7913 12.1416 14.4163 12.8333L15.633 14.0499C16.283 13.0249 16.6663 11.8083 16.6663 10.4999C16.6663 6.81659 13.683 3.83325 9.99967 3.83325ZM9.99967 15.4999C7.24134 15.4999 4.99967 13.2583 4.99967 10.4999C4.99967 9.65825 5.20801 8.85825 5.58301 8.16658L4.36634 6.94992C3.71634 7.97492 3.33301 9.19159 3.33301 10.4999C3.33301 14.1833 6.31634 17.1666 9.99967 17.1666V19.6666L13.333 16.3333L9.99967 12.9999V15.4999Z"
      fill={fill}
    />
  </svg>
)

SyncIcon.defaultProps = {
  size: 20,
  fill: 'currentColor'
}

SyncIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
}

export default SyncIcon
