export const MARGINS = {
  none: 'none',
  normal: 'normal',
  dense: 'dense'
}

export const MESSAGE = {
  default: 'default',
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

export const NOTIFICATION_TYPE = {
  info: 'info',
  success: 'success',
  error: 'error',
  warning: 'warning',
  general: 'general'
}

export const SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge'
}

export const SIZE_PX = {
  small: 16,
  medium: 18,
  large: 24,
  xlarge: 72
}

export const COLOR = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary'
}

export const BUTTON_VARIANT = {
  secondary: 'secondary',
  contained: 'contained',
  outlined: 'outlined',
  text: 'text'
}

export const TOOLTIP_VARIANT = {
  dark: 'dark',
  light: 'light',
  blue: 'blue'
}

export const CHECKBOX_VARIANT = {
  default: 'default',
  light: 'light'
}

export const ICON_BUTTON_VARIANT = {
  light: 'light',
  medium: 'medium',
  transparent: 'transparent',
  black: 'black',
  outlined: 'outlined',
  blue: 'blue',
  '': ''
}

export const TOOLTIP_PLACEMENT = {
  top: 'top',
  left: 'left',
  right: 'right',
  bottom: 'bottom'
}

export const TAB_VARIANT_TYPES = {
  fullWidth: 'fullWidth',
  scrollable: 'scrollable',
  standard: 'standard'
}

export const THEME_TYPES = {
  dark: 'dark',
  light: 'light'
}

export const STATUS_DOT_VARIANTS = {
  gray: 'gray',
  blue: 'blue',
  green: 'green',
  orange: 'orange',
  red: 'red'
}

export const MAP_MARKER_VARIANTS = {
  black: 'black',
  green: 'green',
  red: 'red'
}

export const DELIVERY_METHOD_KEYS = {
  onfleet: 'onfleet',
  doordash: 'doordash',
  fastaf: 'fastaf',
  roadie: 'roadie',
  pickup: 'pickup',
  global: 'global'
}

export const DELIVERY_METHOD_LABELS = {
  doordash: 'DoorDash',
  fastaf: 'FASTAF',
  onfleet: 'Onfleet',
  roadie: 'Roadie',
  pickup: 'Pickup',
  global: 'Carrier (Global)'
}

export const ENVIRONMENT_INSTANCES = {
  staging: 'staging',
  production: 'production',
  development: 'development'
}

export const PUBLISHED_STATUS_VARIANT = {
  active: 'active',
  inactive: 'inactive'
}

export const COLLECTION_LAYOUT_VARIANT = {
  default: 'default',
  brand: 'brand',
  group: 'group'
}

export const PRODUCT_RESULTS_POPOVER_TYPES = {
  collection: 'collection',
  po: 'po', // purchase orders
  distribution_center: 'distribution_center' // distribution center
}

export const HISTORY_CHANGE_TYPES = {
  images: 'images',
  string: 'string',
  boolean: 'boolean',
  datetime: 'datetime',
  arrayOfStrings: 'arrayOfStrings'
}

export const DRAG_N_DROP_MODE = {
  VIEW: 'VIEW',
  ORDERING: 'ORDERING',
  REMOVE: 'REMOVE',
  SELECTING: 'SELECTING'
}

export const PAYMENT_METHODS = {
  credit_card: 'Credit Card',
  apple_pay: 'Apple Pay'
}

export const USER_TIERS = {
  blue: 'blue',
  bronze: 'bronze',
  silver: 'silver',
  gold: 'gold',
  platinum: 'platinum',
  employee: 'employee',
  investor: 'investor'
}

export const USER_TIERS_WITH_BENEFITS = [
  USER_TIERS.bronze,
  USER_TIERS.silver,
  USER_TIERS.gold,
  USER_TIERS.platinum
]

export const CATEGORY_TYPES = {
  editorial: 'editorial'
}

export const MEDIA_RESOURCE_TYPES = {
  video: 'video'
}

export const PRINTER_TYPES = {
  LABEL: 'label',
  BARCODE: 'general'
}

export const PRINTER_TYPE_LABEL = {
  label: 'Label',
  general: 'Barcode'
}

export const TEXT_FIELD_TYPES = {
  text: 'text',
  number: 'number',
  password: 'password'
}
