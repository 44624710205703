import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { dollarsToCents } from 'utils/general'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SaveIcon from '@material-ui/icons/Save'

import {
  useAdminProducts,
  useLoaders,
  useBeforeUnload,
  useMediaQuery,
  useNavigation,
  useAlerts
} from 'hooks'
import { NEW_PRODUCT_SCHEMA, FORM_FIELDS } from 'constants/products'
import { SIZE, COLOR } from 'constants/enums'
import { URL } from 'constants/navigation'

import { AutocompleteSearchBadgesControlled } from 'components/AutocompleteSearchBadges'
import { AutocompleteSearchProductTagsControlled } from 'components/AutocompleteSearchProductTags'
import { AutocompleteSearchStatusCodesControlled } from 'components/AutocompleteSearchStatusCodes'
import { AutocompleteSelectLocationsControlled } from 'components/AutocompleteSelectLocations'
import { AutocompleteSearchColorsControlled } from 'components/AutocompleteSearchColors'
import { AutocompleteSearchSizesControlled } from 'components/AutocompleteSearchSizes'
import { AutocompleteSearchAccessoryOptionsControlled } from 'components/AutocompleteSearchAccessoryOptions'
import { AutocompleteSearchTaxCodesControlled } from 'components/AutocompleteSearchTaxCodes'
import { AutocompleteSearchBrandsControlled } from 'components/AutocompleteSearchBrands'
import { CheckboxBlockControlled } from 'components/CheckboxBlock'
import { CheckboxField } from 'components/Checkbox'
import { MarkdownEditorControlled } from 'components/MarkdownEditor'
import { TextFieldControlled } from 'components/TextField'
import Block from 'components/Block'
import Button from 'components/Button'
import ControlledForm, { useControlledForm } from 'components/ControlledForm'
import Header from 'components/Header'
import Layout from 'components/Layout'
import MultiImageDropzone from 'components/MultiImageDropzone'
import SetPDPBlockedButton from 'components/SetPDPBlockedButton'
import Tabs from 'components/Tabs'
import ProductIngredientFields from 'components/ProductIngredientFields'
import ProductNutriFactFields from 'components/ProductNutriFactFields'

import { AutocompleteSelectCategoriesControlled } from '../ProductShow/ProductShow'

import styles from '../ProductShow/ProductShowStyles'

const SaveButton = () => {
  const { readyToSave, handleSubmit } = useControlledForm()
  const { OnBeforeUnloadPrompt } = useBeforeUnload(readyToSave)

  return (
    <>
      <Button
        label="save new product button"
        onClick={handleSubmit}
        size={SIZE.medium}
        color={COLOR.primary}
        dataTest="po-save-button"
        startIcon={<SaveIcon />}
        adaptive
        disabled={!readyToSave}
        type="submit"
      >
        Save
      </Button>
      <OnBeforeUnloadPrompt />
    </>
  )
}

const ProductCreate = ({ classes }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError } = useAlerts()
  const { go } = useNavigation({})

  const { isMobileScreen } = useMediaQuery()

  const {
    createProduct,
    uploadProductImages,
    generateNutritionFactsRequestBody,
    createNutritionFacts,
    getAllNutrientOptions
  } = useAdminProducts({})

  const handleSave = async newProductData => {
    showLoading()

    const nutritionParams = generateNutritionFactsRequestBody(newProductData)

    delete newProductData[FORM_FIELDS.INGREDIENTS]
    delete newProductData[FORM_FIELDS.ALLERGENS]
    delete newProductData[FORM_FIELDS.SERVING_SIZE]
    delete newProductData[FORM_FIELDS.SERVINGS_PER_CONTAINER]
    delete newProductData[FORM_FIELDS.CALORIES_PER_SERVING]
    delete newProductData[FORM_FIELDS.FAT_CALORIES_PER_SERVING]
    delete newProductData[FORM_FIELDS.NUTRITION_FACTS]

    const locationIds = newProductData?.location_ids?.map(loc => loc.id)
    delete newProductData[FORM_FIELDS.LOCATION_IDS]

    if (locationIds?.length && !newProductData[FORM_FIELDS.DESCRIPTION]) {
      showAlertError(
        'Description is required when publishing a product to a location.'
      )
      hideLoading()
      return
    }

    const taxCategoryId = newProductData?.tax_category_id?.id
    delete newProductData[FORM_FIELDS.TAX_CATEGORY_ID]

    const badgeIds = newProductData?.badges?.map(badge => badge.id)
    delete newProductData[FORM_FIELDS.BADGES]

    const status_code_id = newProductData?.status_code?.id
    delete newProductData[FORM_FIELDS.STATUS_CODE]

    const category_id = newProductData?.category?.id
    delete newProductData[FORM_FIELDS.PARENT_CATEGORY]

    const subcategory_id = newProductData?.subcategory?.id
    delete newProductData[FORM_FIELDS.SUB_CATEGORY]

    const tag_ids = newProductData?.tags?.map(tag => tag.id)
    delete newProductData[FORM_FIELDS.TAGS]

    const brandId = newProductData?.brand?.id
    delete newProductData[FORM_FIELDS.BRAND]

    const fileUploads = newProductData?.upload_images_files
    delete newProductData[FORM_FIELDS.IMAGE_FILES]

    const colorIds = newProductData[FORM_FIELDS.VARIANT_COLORS]?.map(
      color => color?.id
    )
    const sizeIds = newProductData[FORM_FIELDS.VARIANT_SIZES]?.map(
      size => size?.id
    )
    const accessoryOptionIds = newProductData[
      FORM_FIELDS.VARIANT_ACCESSORIES
    ]?.map(opt => opt?.id)

    const variantData = {
      variant: {
        cost: dollarsToCents(newProductData[FORM_FIELDS.COST_PER_ITEM]),
        price: dollarsToCents(newProductData[FORM_FIELDS.MSRP]),
        compare_at_price: dollarsToCents(
          newProductData[FORM_FIELDS.COMPARE_AT_PRICE]
        ),
        title: 'Default Title',
        ...(newProductData[FORM_FIELDS.VENDOR_STYLE_NUMBER_IS_UNIVERSAL]
          ? {
              vendor_style_number:
                newProductData[FORM_FIELDS.VENDOR_STYLE_NUMBER]
            }
          : {})
      },
      color_ids: colorIds,
      size_ids: sizeIds,
      accessory_option_ids: accessoryOptionIds
    }

    delete newProductData[FORM_FIELDS.MSRP]
    delete newProductData[FORM_FIELDS.COST_PER_ITEM]
    delete newProductData[FORM_FIELDS.COMPARE_AT_PRICE]
    delete newProductData[FORM_FIELDS.VARIANT_COLORS]
    delete newProductData[FORM_FIELDS.VARIANT_SIZES]
    delete newProductData[FORM_FIELDS.VARIANT_ACCESSORIES]

    try {
      const newProduct = await createProduct(
        {
          ...newProductData,
          brand_id: brandId,
          location_ids: locationIds,
          tax_category_id: taxCategoryId,
          badge_ids: badgeIds,
          status_code_id,
          tag_ids,
          category_id,
          subcategory_id,
          color_option_ids: colorIds,
          size_option_ids: sizeIds,
          accessory_option_ids: accessoryOptionIds
        },
        variantData
      )

      // TODO: catch new product creation error

      await createNutritionFacts({
        product_id: newProduct?.id,
        ...nutritionParams
      })

      if (fileUploads.length) {
        await uploadProductImages(newProduct.id, fileUploads)
        // TODO: catch file upload error
      }

      hideLoading()
      if (newProduct) {
        go({}, `${URL.ADMIN_PRODUCTS}/${newProduct?.id}`)
      }
    } catch (e) {
      console.error(e)
    }

    hideLoading()
  }

  const FORM_DEFAULT_VALUES = {
    [FORM_FIELDS.TITLE]: 'Untitled',
    [FORM_FIELDS.DESCRIPTION]: '',
    [FORM_FIELDS.MSRP]: '',
    [FORM_FIELDS.COMPARE_AT_PRICE]: '',
    [FORM_FIELDS.COST_PER_ITEM]: '',
    [FORM_FIELDS.MAX_ORDER_QUANTITY]: '',
    [FORM_FIELDS.CONSIGNMENT]: false,
    [FORM_FIELDS.LOCKED]: false,
    [FORM_FIELDS.AGES_21_AND_OVER]: false,
    [FORM_FIELDS.RESTOCK_NOTIFICATIONS_ENABLED]: true,
    [FORM_FIELDS.LOCATION_IDS]: [],
    [FORM_FIELDS.VENDOR_STYLE_NUMBER]: '',
    [FORM_FIELDS.VENDOR_STYLE_NUMBER_IS_UNIVERSAL]: false,
    [FORM_FIELDS.TAX_CATEGORY_ID]: {},
    [FORM_FIELDS.BADGES]: [],
    [FORM_FIELDS.STATUS_CODE]: {},
    [FORM_FIELDS.BRAND]: null,
    [FORM_FIELDS.TAGS]: [],
    [FORM_FIELDS.PARENT_CATEGORY]: null,
    [FORM_FIELDS.SUB_CATEGORY]: null,
    [FORM_FIELDS.IMAGE_FILES]: [],
    [FORM_FIELDS.VARIANT_COLORS]: [],
    [FORM_FIELDS.VARIANT_SIZES]: [],
    [FORM_FIELDS.VARIANT_ACCESSORIES]: [],
    [FORM_FIELDS.SERVING_SIZE]: '',
    [FORM_FIELDS.SERVINGS_PER_CONTAINER]: '',
    [FORM_FIELDS.CALORIES_PER_SERVING]: '',
    [FORM_FIELDS.FAT_CALORIES_PER_SERVING]: '',
    [FORM_FIELDS.INGREDIENTS]: '',
    [FORM_FIELDS.ALLERGENS]: '',
    [FORM_FIELDS.NUTRITION_FACTS]: { data: [], lastUpdate: '' } // lastUpdate allows changes to data obj to be seen (mostly for ProductShow, but needed here nonetheless)
  }

  return (
    <Layout>
      <ControlledForm
        handleSubmit={handleSave}
        schemas={NEW_PRODUCT_SCHEMA}
        defaultValues={FORM_DEFAULT_VALUES}
      >
        <Box>
          <Box className={classes.headerBox}>
            <Header
              sticky
              breadcrumbs={[{ title: 'Products', link: URL.ADMIN_PRODUCTS }]}
              title="New Product"
              actions={
                <>
                  <SetPDPBlockedButton />
                  <SaveButton />
                </>
              }
            />
          </Box>

          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid item sm={12} md={6} className={classes.gridItem}>
              <Block withPadding>
                <Box>
                  <Typography variant="h5">Name</Typography>
                  <TextFieldControlled
                    className={classes.thinInput}
                    name={FORM_FIELDS.TITLE}
                    fullWidth
                  />
                </Box>
              </Block>
              <Block>
                <Box pt={3} px={3}>
                  <Typography variant="h5">Media</Typography>
                </Box>
                <MultiImageDropzone
                  name={FORM_FIELDS.IMAGE_FILES}
                  emptyImagesPlaceholderText="Upload photos for your product!"
                />
              </Block>
              <Block>
                <Tabs
                  noPaneMargin
                  panes={[
                    {
                      label: 'Description',
                      pane: (
                        <MarkdownEditorControlled
                          name={FORM_FIELDS.DESCRIPTION}
                          placeholder="Product description goes here"
                        />
                      )
                    },
                    {
                      label: 'Ingredients',
                      pane: <ProductIngredientFields />
                    },
                    {
                      label: 'Nutrition Facts',
                      pane: (
                        <ProductNutriFactFields
                          getAllNutrientOptions={getAllNutrientOptions}
                        />
                      )
                    }
                  ]}
                />
              </Block>
              <Block>
                <Box className={classes.paddingBlock}>
                  <Typography variant="h5">Options</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    pt={2}
                  >
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      className={classes.autocompleteBox}
                    >
                      <Box width="100%" mb={1}>
                        <Typography variant="caption">Color</Typography>
                        <AutocompleteSearchColorsControlled
                          name={FORM_FIELDS.VARIANT_COLORS}
                        />
                      </Box>
                      <Box width="100%" mb={1}>
                        <Typography variant="caption">Sizes</Typography>
                        <AutocompleteSearchSizesControlled
                          name={FORM_FIELDS.VARIANT_SIZES}
                        />
                      </Box>
                      <Box width="100%" mb={1}>
                        <Typography variant="caption">
                          Accessory Option
                        </Typography>
                        <AutocompleteSearchAccessoryOptionsControlled
                          name={FORM_FIELDS.VARIANT_ACCESSORIES}
                          multiple
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.paddingBlock}>
                  <Typography variant="h5">Pricing</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    pt={2}
                  >
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      <Typography variant="caption">Price</Typography>
                      <TextFieldControlled
                        inputProps={{ min: 0 }}
                        number
                        className={classes.smallInput}
                        startAdornment="$"
                        name={FORM_FIELDS.MSRP}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      <Typography variant="caption">
                        Compare At Price
                      </Typography>
                      <TextFieldControlled
                        inputProps={{ min: 0 }}
                        number
                        className={classes.smallInput}
                        startAdornment="$"
                        name={FORM_FIELDS.COMPARE_AT_PRICE}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                    >
                      <Typography variant="caption">Cost Per item</Typography>
                      <TextFieldControlled
                        inputProps={{ min: 0 }}
                        number
                        className={classes.smallInput}
                        startAdornment="$"
                        name={FORM_FIELDS.COST_PER_ITEM}
                      />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="caption">Tax Code</Typography>
                    <AutocompleteSearchTaxCodesControlled
                      name={FORM_FIELDS.TAX_CATEGORY_ID}
                    />
                  </Box>
                  <Box mt={3}>
                    <Typography variant="h5">Vendor Style</Typography>
                    <Box mt={2}>
                      <Typography variant="caption">
                        Vendor style number
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <TextFieldControlled
                        className={classes.thinInput}
                        placeholder="AES-2030"
                        name={FORM_FIELDS.VENDOR_STYLE_NUMBER}
                      />
                      <Box ml={2} />
                      <Box>
                        <CheckboxBlockControlled
                          className={classes.thinInput}
                          label={
                            isMobileScreen
                              ? 'Apply to all'
                              : 'Apply to all variants'
                          }
                          name={FORM_FIELDS.VENDOR_STYLE_NUMBER_IS_UNIVERSAL}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Block>
            </Grid>

            <Grid item sm={12} md={6} className={classes.gridItem}>
              <Block>
                <Tabs
                  panes={[
                    {
                      label: 'Details',
                      pane: (
                        <Box>
                          <Box className={classes.paddingBlock}>
                            <Typography variant="h5">Availability</Typography>
                            <Box mt={3}>
                              <Box>
                                <Typography variant="body1">
                                  Publish product to
                                </Typography>
                                <AutocompleteSelectLocationsControlled
                                  name={FORM_FIELDS.LOCATION_IDS}
                                />
                              </Box>
                            </Box>
                            <Box mt={3} className={classes.flagBlock}>
                              <Box>
                                <Typography variant="body1">
                                  On Consignment?
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  This product is being provided by a brand
                                  partner.
                                </Typography>
                              </Box>
                              <Box>
                                <CheckboxField name={FORM_FIELDS.CONSIGNMENT} />
                              </Box>
                            </Box>
                            <Box mt={3} className={classes.flagBlock}>
                              <Box>
                                <Typography variant="body1">
                                  For users over age 21?
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  This product has a legal age limit.
                                </Typography>
                              </Box>
                              <Box>
                                <CheckboxField
                                  name={FORM_FIELDS.AGES_21_AND_OVER}
                                />
                              </Box>
                            </Box>
                            <Box mt={3} className={classes.flagBlock}>
                              <Box>
                                <Typography variant="body1">
                                  Enable waitlist?
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  This will enable waitlisting for this variant.
                                </Typography>
                              </Box>
                              <Box>
                                <CheckboxField
                                  name={
                                    FORM_FIELDS.RESTOCK_NOTIFICATIONS_ENABLED
                                  }
                                />
                              </Box>
                            </Box>
                            <Box mt={3} className={classes.flagBlock}>
                              <Box>
                                <Typography variant="body1">
                                  Max Order Quantity
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  This limits how much a user can buy per order.
                                </Typography>
                              </Box>
                              <Box>
                                <TextFieldControlled
                                  inputProps={{ min: 0 }}
                                  number
                                  className={classes.qtyInput}
                                  startAdornment={
                                    <Typography variant="body2">Qty</Typography>
                                  }
                                  name={FORM_FIELDS.MAX_ORDER_QUANTITY}
                                />
                              </Box>
                            </Box>
                          </Box>

                          <Box className={classes.divider} />

                          <Box className={classes.divider} />

                          <Box className={classes.paddingBlock}>
                            <Typography variant="h5">Organization</Typography>
                            <Box mt={2}>
                              <Box
                                mx={0}
                                mt={0.5}
                                mb={3}
                                className={classes.autocompleteBox}
                              >
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Brand
                                </Typography>
                                <AutocompleteSearchBrandsControlled
                                  name={FORM_FIELDS.BRAND}
                                />
                              </Box>
                              <AutocompleteSelectCategoriesControlled />
                              <Box mx={0} mb={0.5}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Status Code
                                </Typography>
                                <AutocompleteSearchStatusCodesControlled
                                  name={FORM_FIELDS.STATUS_CODE}
                                />
                              </Box>
                              <Box my={2} className={classes.divider} />
                              <Box mx={0} mb={0.5}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Badges
                                </Typography>
                                <AutocompleteSearchBadgesControlled
                                  name={FORM_FIELDS.BADGES}
                                />
                              </Box>
                              <Box mx={0} mb={0.5}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Tags
                                </Typography>
                                <AutocompleteSearchProductTagsControlled
                                  name={FORM_FIELDS.TAGS}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )
                    }
                  ]}
                />
              </Block>
            </Grid>
          </Grid>
        </Box>
      </ControlledForm>
    </Layout>
  )
}

ProductCreate.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProductCreate)
