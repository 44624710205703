import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import uniqBy from 'lodash/uniqBy'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminProducts } from 'hooks'
import AutocompleteSearch from 'components/AutocompleteSearch'

import styles from './AutocompleteSearchAccessoryOptionsStyles'

const AutocompleteSearchAccessoryOptionsRaw = ({
  name,
  classes,
  onChange,
  value,
  defaultOpen,
  multiple,
  disabled
}) => {
  const {
    isLoadingAccessoryOptions,
    accessoryOptions,
    listAccessoryOptions,
    listAccessoryOptionsNext
  } = useAdminProducts({})
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (query.length) {
      listAccessoryOptions({ search: query })
    } else {
      listAccessoryOptions()
    }
  }, [query])

  const options = useMemo(
    () =>
      uniqBy((accessoryOptions ?? []).concat(value), v => v?.id).filter(
        accesoryOption => accesoryOption?.active
      ),
    [accessoryOptions]
  )

  return (
    <Box mt={1}>
      <AutocompleteSearch
        name={name}
        withEndAdornment={false}
        defaultOpen={defaultOpen}
        value={value}
        loading={isLoadingAccessoryOptions}
        setQuery={setQuery}
        onChange={onChange}
        onScrollBottom={listAccessoryOptionsNext}
        options={options}
        placeholder="Accessory Option"
        multiple={multiple}
        getOptionLabel={opt => opt?.name ?? ''}
        disabled={disabled}
        renderOption={(option, { selected }) => (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="100%"
            className={selected ? classes.selectedItem : null}
          >
            <Typography variant="body1" className={classes.name}>
              {option?.name ?? ''}
            </Typography>
          </Box>
        )}
      />
    </Box>
  )
}

AutocompleteSearchAccessoryOptionsRaw.defaultProps = {
  value: {},
  defaultOpen: false,
  multiple: false,
  disabled: false
}

AutocompleteSearchAccessoryOptionsRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultOpen: PropTypes.bool,
  multiple: PropTypes.bool, // value [] === true, value === {} = false
  disabled: PropTypes.bool
}

const AutocompleteSearchAccessoryOptions = withStyles(styles)(
  AutocompleteSearchAccessoryOptionsRaw
)

const AutocompleteSearchAccessoryOptionsControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchAccessoryOptions
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchAccessoryOptionsControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchAccessoryOptions as default,
  AutocompleteSearchAccessoryOptionsControlled
}
