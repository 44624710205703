import React from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'

import CheckBoxGroup from './CheckboxGroup'

const CheckboxGroupField = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={methods => (
        <CheckBoxGroup
          name={name}
          error={errors[name]}
          {...rest}
          {...methods}
        />
      )}
    />
  )
}

CheckboxGroupField.propTypes = {
  name: PropTypes.string.isRequired
}

export default CheckboxGroupField
