const jssStyles = theme => ({
  root: {
    maxWidth: 324
  },
  innerRoot: {
    position: 'relative',
    zIndex: 600
  },
  popover: {
    position: 'absolute',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    boxShadow:
      '0px 8px 16px rgba(0, 0, 0, 0.1), 0px 0px 10px rgba(0, 0, 0, 0.0001)',
    overflowY: 'auto',
    borderRadius: 5,
    width: '100%'
  },
  popoverHeader: {
    position: 'sticky',
    top: 0,
    padding: theme.spacing(3, 2, 0),
    backgroundColor: theme.palette.background.default
  },
  popoverBody: {
    padding: theme.spacing(3, 0)
  },
  addButton: {
    height: 33,
    margin: 12
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '5px',
    backgroundColor: theme.palette.grays.light
  },
  brand: {
    color: theme.palette.grays.dark
  },
  title: {
    ...theme.typography.subtitle1,
    width: '100%',
    height: 58,
    overflow: 'hidden'
  },
  oneLine: {
    textOverflow: 'ellipsis',
    height: 'auto',
    whiteSpace: 'nowrap'
  },
  addedLabel: {
    ...theme.typography.subtitle2,
    color: theme.palette.blues.fast,
    fontSize: 12,
    whiteSpace: 'pre'
  },
  addIcon: {
    color: theme.palette.blues.fast,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9
    }
  },
  removeIcon: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9
    }
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 500
  }
})

export default jssStyles
