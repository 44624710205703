const jssStyles = theme => ({
  root: {},
  title: {
    marginBottom: theme.spacing(2)
  },
  dynamicTextContainer: {
    position: 'absolute'
  }
})

export default jssStyles
