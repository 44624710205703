import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import ButtonBase from '@material-ui/core/ButtonBase'

import styles from './FabButtonStyles'

const FabButton = ({ classes, className, children, onClick, style }) => (
  <ButtonBase
    className={classNames({
      [classes.root]: true,
      [className]: Boolean(className)
    })}
    onClick={onClick}
    style={style}
    data-test="fab-button"
  >
    {children}
  </ButtonBase>
)

FabButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClick: PropTypes.func,
  style: PropTypes.object
}

export default withStyles(styles)(FabButton)
