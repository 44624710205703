const jssStyles = theme => ({
  root: {
    paddingTop: theme.spacing(1),

    '& .chrome-picker': {
      width: '100% !important',
      boxShadow: 'none !important'
    },
    '& h6': {
      ...theme.typography.overline,
      color: theme.palette.grays.dark
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiSelect-select:focus': {
      background: theme.palette.background.default
    }
  },
  paper: {
    backgroundColor: theme.palette.background.default
  },
  actions: {
    '& > *': {
      flexGrow: 1,
      margin: '0 10px',
      '&:first-child': {
        marginLeft: 0
      },
      '&:last-child': {
        marginRight: 0
      }
    }
  }
})

export default jssStyles
