import React, { useState, useEffect, useRef } from 'react'

import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { URL } from 'constants/navigation'
import { SIZE, COLLECTION_LAYOUT_VARIANT } from 'constants/enums'
import { TERM_POSSIBILITIES } from 'constants/general'
import { TERM_POSSIBILITIES_ALL } from 'constants/purchaseOrders'
import { GROUPED_STATUS_FILTER_ITEMS } from 'constants/brands'
import {
  useAdminBrands,
  useNavigation,
  useLoaders,
  useConditionalEffect
} from 'hooks'
import { getBrandName } from 'utils/general'

import Header from 'components/Header'
import Layout from 'components/Layout'
import Button from 'components/Button'
import GlanceTile from 'components/GlanceTile'
import SearchInput from 'components/SearchInput'
import CollectionLayout from 'components/CollectionLayout'
import Select from 'components/Select'
import Dialog from 'components/Dialog'
import CreateBrandModal from 'components/CreateBrandModal'
import BulkExportModal from 'components/BulkExportModal'

const BrandList = () => {
  const { showLoading, hideLoading } = useLoaders()
  const {
    createBrand,
    isLoadingBrands,
    brands: allBrands,
    hasBrands,
    fetchBrands,
    updatePublishStatuses,
    updateBrandsLocally,
    bulkExportBrandReports
  } = useAdminBrands()

  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [bulkExportModalOpen, setBulkExportModalOpen] = useState(false)
  const [bulkExportEmailModalOpen, setBulkExportEmailModalOpen] = useState(
    false
  )
  const [bulkExportBrandIds, setBulkExportBrandIds] = useState([])
  const collectionLayoutRef = useRef(null)

  const [brands, setBrands] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [queryRegex, setQueryRegex] = useState()

  const [statusFilter, setStatusFilter] = useState([])
  const handleSetStatusFilter = e => {
    const localStatusFilters = [...e.target.value.filter(Boolean)].flat()
    setStatusFilter(localStatusFilters)
    let filteredBrands = allBrands

    if (localStatusFilters.length) {
      const active = localStatusFilters.includes('active')
      const inactive = localStatusFilters.includes('inactive')
      const partner = localStatusFilters.includes('partner')
      const nonPartner = localStatusFilters.includes('non-partner')

      const exclusive = localStatusFilters.includes('exclusive')
      const notExclusive = localStatusFilters.includes('not-exclusive')
      const onAmazon = localStatusFilters.includes('on-amazon')
      const notOnAmazon = localStatusFilters.includes('not-on-amazon')
      const onQComm = localStatusFilters.includes('on-qcomm')
      const notOnQComm = localStatusFilters.includes('not-on-qcomm')

      if (active && !inactive)
        filteredBrands = [...filteredBrands?.filter(brand => brand?.active)]

      if (!active && inactive)
        filteredBrands = [...filteredBrands?.filter(brand => !brand?.active)]

      if (partner && !nonPartner)
        filteredBrands = [...filteredBrands?.filter(brand => brand?.partner)]

      if (!partner && nonPartner)
        filteredBrands = [...filteredBrands?.filter(brand => !brand?.partner)]

      if (exclusive && !notExclusive)
        filteredBrands = [
          ...filteredBrands?.filter(brand => brand?.contract_exclusive)
        ]

      if (!exclusive && notExclusive)
        filteredBrands = [
          ...filteredBrands?.filter(brand => !brand?.contract_exclusive)
        ]

      if (!onAmazon && notOnAmazon)
        filteredBrands = [
          ...filteredBrands?.filter(brand => brand?.not_on_amazon)
        ]

      if (onAmazon && !notOnAmazon)
        filteredBrands = [
          ...filteredBrands?.filter(brand => !brand?.not_on_amazon)
        ]

      if (!onQComm && notOnQComm)
        filteredBrands = [
          ...filteredBrands?.filter(brand => brand?.not_on_other_qcomm)
        ]

      if (onQComm && !notOnQComm)
        filteredBrands = [
          ...filteredBrands?.filter(brand => !brand?.not_on_other_qcomm)
        ]
    }

    setBrands(filteredBrands)
  }

  const [termsFilter, setTermsFilter] = useState()
  const handlerTermsFilterChange = e => {
    const localTermsFilter = TERM_POSSIBILITIES.filter(
      pos => pos.value === e.target.value
    )[0]

    setTermsFilter(localTermsFilter)

    let filteredBrands = allBrands
    const term = localTermsFilter?.value

    if (term !== '' && term !== undefined) {
      setBrands([
        ...filteredBrands?.filter(brand => brand?.payment_terms === term)
      ])
    }
  }

  const { handleClick } = useNavigation({ url: URL.ADMIN_BRANDS })

  useEffect(() => {
    ;(async () => {
      showLoading()
      await fetchBrands()
      hideLoading()
    })()
  }, [])

  useConditionalEffect(() => {
    if (!hasBrands) {
      return
    }

    setBrands(
      queryRegex === null
        ? allBrands
        : allBrands.filter(
            brand => getBrandName(brand)?.search(queryRegex) > -1
          )
    )
  }, [queryRegex, allBrands])

  const handleBulkExportSelection = brandIds => {
    setBulkExportModalOpen(true)
    setBulkExportBrandIds(brandIds)
  }

  const handleBulkExport = async args => {
    showLoading()
    setBulkExportModalOpen(false)
    await bulkExportBrandReports(args)
    collectionLayoutRef?.current &&
      collectionLayoutRef.current.clearSelections()
    hideLoading()
    setBulkExportEmailModalOpen(true)
  }

  const handleCreateBrand = async vals => {
    const c = await createBrand(vals)
    updateBrandsLocally([c])
  }

  const manageBrandPublishState = (theseBrands, active) => {
    updatePublishStatuses(theseBrands, active)
  }

  const foldableActions = (
    <>
      <SearchInput
        dataTest="brand-search"
        name="search"
        placeholder="Search Brand..."
        value={searchQuery}
        onChange={({ target: { value } }) => {
          setSearchQuery(value)
          setQueryRegex(value && new RegExp(value, 'i'))
        }}
      />
    </>
  )

  const actions = (
    <Button
      adaptive
      label="new brand button"
      startIcon={<AddIcon />}
      color="primary"
      variant="contained"
      size={SIZE.medium}
      onClick={() => setCreateModalOpen(true)}
      dataTest="add-new-brands"
    >
      New Brand
    </Button>
  )

  const summarizers = (
    <GlanceTile
      minimal
      label={
        statusFilter?.value ? `${statusFilter?.label} Brands` : 'Total Brands'
      }
      value={brands?.length}
      dataTest="brands-length"
    />
  )

  const filters = (
    <>
      <Select
        minimal
        name="terms"
        innerLabel="Terms"
        value={termsFilter?.value ? termsFilter.value : ''}
        items={TERM_POSSIBILITIES_ALL}
        onChange={handlerTermsFilterChange}
      />
      <Select
        minimal
        name="statuses"
        innerLabel="All Statuses"
        value={statusFilter}
        groupedItems={GROUPED_STATUS_FILTER_ITEMS}
        onChange={handleSetStatusFilter}
        multiple
        dataTest="brands-status"
      />
    </>
  )

  return (
    <Layout id="brands-list">
      <Header
        title="Brand Management"
        foldableActions={foldableActions}
        summarizers={summarizers}
        filters={filters}
        actions={actions}
      />
      <CollectionLayout
        ref={collectionLayoutRef}
        items={brands}
        variant={COLLECTION_LAYOUT_VARIANT.brand}
        hasItems={hasBrands}
        isLoading={isLoadingBrands || !hasBrands}
        onItemClick={handleClick}
        onManageSubmit={manageBrandPublishState}
        onExportClick={handleBulkExportSelection}
      />
      <CreateBrandModal
        open={createModalOpen}
        createBrand={handleCreateBrand}
        onClose={() => setCreateModalOpen(false)}
      />
      <BulkExportModal
        open={bulkExportModalOpen}
        onClose={() => {
          setBulkExportModalOpen(!bulkExportModalOpen)
          setBulkExportBrandIds([])
          collectionLayoutRef?.current &&
            collectionLayoutRef.current.clearSelections()
        }}
        onExport={handleBulkExport}
        items={allBrands?.filter(brand =>
          bulkExportBrandIds?.includes(brand?.id)
        )}
        handleRemoveBrandFromBulkExports={({ id }) =>
          setBulkExportBrandIds(
            bulkExportBrandIds.filter(thisId => thisId !== id)
          )
        }
      />
      <Dialog
        open={bulkExportEmailModalOpen}
        onClose={() => setBulkExportEmailModalOpen(false)}
        onConfirm={() => setBulkExportEmailModalOpen(false)}
        confirmText="Okay"
        hideCancel
      >
        <Box mt={1}>
          <Typography variant="h5">Heads Up</Typography>
        </Box>
        <Box my={3}>
          <Typography variant="body1">
            Your export is a bit large and may take longer to complete. You can
            continue working and we will email you when your download is ready.
          </Typography>
        </Box>
      </Dialog>
    </Layout>
  )
}

export default BrandList
