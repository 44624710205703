import React, { createContext, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { TAB_ORDER } from 'containers/admin/CollectionTabs'

export const UIContext = createContext()

export const UIProvider = ({ children }) => {
  const [alert, setAlert] = useState()
  const [collectionTab, setCollectionTab] = useState(
    TAB_ORDER.indexOf('collections')
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [showSwitchDarkstoreModal, setShowSwitchDarkstoreModal] = useState(
    false
  )

  const value = useMemo(
    () => ({
      alert,
      // This is wrapped in a timeout because somehow the onClickAway can be
      // called immediately and the alert would close.
      setAlert: a => setTimeout(() => setAlert(a), 200),
      closeAlert: () => setAlert({ ...alert, open: false }),
      isLoading,
      setIsLoading,
      showMenu,
      handleToggleMenu: () => setShowMenu(!showMenu),
      showSwitchDarkstoreModal,
      setShowSwitchDarkstoreModal,
      collectionTab,
      setCollectionTab
    }),
    [alert, isLoading, showMenu, showSwitchDarkstoreModal, collectionTab]
  )

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

UIProvider.propTypes = {
  children: PropTypes.node
}
