import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'
import { withStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useAdminColorways } from 'hooks'
import { isAtBottom } from 'utils/general'

import AutocompleteSearch from 'components/AutocompleteSearch'

import styles from './AutocompleteSearchColorsStyles'

const AutocompleteSearchColorsRaw = ({
  name,
  classes,
  onChange,
  value,
  defaultOpen,
  multiple,
  disabled
}) => {
  const [query, setQuery] = useState('')

  const {
    isLoadingColorways,
    colorways,
    listColorwaysNext
  } = useAdminColorways({ search: query })

  const options = useMemo(
    () => uniqBy((colorways ?? []).concat(value), v => v?.id),
    [colorways]
  )

  const listboxProps = {
    onScroll: ({ currentTarget: el }) => {
      if (isAtBottom(el)) {
        listColorwaysNext()
      }
    }
  }

  return (
    <Box mt={1} width="100%">
      <AutocompleteSearch
        name={name}
        disabled={disabled}
        withEndAdornment={false}
        defaultOpen={defaultOpen}
        value={value}
        loading={isLoadingColorways}
        setQuery={setQuery}
        onChange={onChange}
        ListboxProps={listboxProps}
        getOptionDisabled={option => !option.active}
        options={options}
        placeholder="Color"
        multiple={multiple}
        getOptionLabel={opt => opt?.label ?? ''}
        renderOption={(option, { selected }) => (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="100%"
            className={selected ? classes.selectedItem : null}
          >
            <Typography variant="body1" className={classes.label}>
              {`${option?.label}${
                option?.shopify_color && option?.shopify_color !== option?.label
                  ? ` / ${option?.shopify_color}`
                  : ''
              }`}
            </Typography>
          </Box>
        )}
      />
    </Box>
  )
}

AutocompleteSearchColorsRaw.defaultProps = {
  value: [],
  defaultOpen: false,
  multiple: true,
  disabled: false
}

AutocompleteSearchColorsRaw.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultOpen: PropTypes.bool,
  multiple: PropTypes.bool, // value [] === true, value === {} = false
  disabled: PropTypes.bool
}

const AutocompleteSearchColors = withStyles(styles)(AutocompleteSearchColorsRaw)

const AutocompleteSearchColorsControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <AutocompleteSearchColors
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={option => methods.onChange(option)}
        />
      )}
    />
  )
}

AutocompleteSearchColorsControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export {
  AutocompleteSearchColors as default,
  AutocompleteSearchColorsControlled
}
