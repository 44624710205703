const jssStyles = theme => ({
  root: {
    '& .CalendarMonth_table': {
      marginTop: theme.spacing(0.5)
    },
    '& .CalendarDay__default': {
      border: 'none'
    },
    '& .CalendarDay__default:hover': {
      border: 'none'
    },
    '& .CalendarDay__selected_span': {
      background: theme.palette.blues.dark,
      color: 'white',
      border: 'none'
    },
    '& .CalendarDay__selected': {
      background: theme.palette.blues.fast,
      color: 'white',
      border: 'none'
    },
    '& .CalendarDay__selected:hover': {
      background: theme.palette.blues.light,
      color: 'white',
      border: 'none'
    },
    '& .CalendarDay__hovered_span:hover, .CalendarDay__hovered_span': {
      background: theme.palette.blues.light,
      color: 'white',
      border: 'none'
    },
    '& .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover': {
      background: theme.palette.blues.light,
      border: 'none'
    },
    '& .DayPicker_weekHeader_li': {
      fontSize: 16
    }
  },
  resetButton: {
    color: theme.palette.blues.fast,
    fontWeight: 'bold',
    fontVariant: 'all-petite-caps',
    minWidth: 0,
    margin: '12px 0px',
    padding: '2px 8px',
    height: 'auto'
  },
  day: {
    height: '100%',
    padding: theme.spacing(1)
  },
  today: {
    border: `2px solid ${theme.palette.blues.fast}`
  }
})

export default jssStyles
