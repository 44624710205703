import { downloadApi } from 'api'
import { useResourceListRQ } from 'hooks'
import {
  transferOrderListEndpoint,
  transferOrderExportEndpoint
} from 'api/endpoints'

const useAdminTransferOrderList = params => {
  const {
    isFetching: isFetchingTransferOrders,
    data: transferOrders,
    hasData: hasTransferOrders,
    hasNextPage: hasTransferOrdersNext,
    fetchNextPage: fetchTransferOrdersNext,
    meta
  } = useResourceListRQ({ endpoint: transferOrderListEndpoint, params })

  const getExport = async transferOrderId => {
    await downloadApi.get(transferOrderExportEndpoint(transferOrderId))
  }

  return {
    isFetchingTransferOrders,
    transferOrders: transferOrders || [],
    hasTransferOrders,
    hasTransferOrdersNext,
    fetchTransferOrdersNext,
    summary: meta?.summary,
    getExport
  }
}

export default useAdminTransferOrderList
