import { BoxShadowFaint, ABSOLUTE_FILL } from 'constants/styles'

const jssStyles = theme => ({
  root: {
    position: 'relative',
    boxShadow: BoxShadowFaint,
    overflow: 'hidden',
    margin: 'auto'
  },
  overlay: {
    ...ABSOLUTE_FILL,
    padding: theme.spacing(1, 1.5)
  },
  grab: {
    marginLeft: theme.spacing(-1)
  }
})

export default jssStyles
