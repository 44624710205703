import { useEffect } from 'react'
import { usePrevious } from 'hooks'

/* Can be used in place of `useEffect` when the effect should only run
when specified values change (conditionals). This is a drop-in
replacement for `useEffect` but handles the conditionals semantically
correctly. */
export default function useConditionalEffect(proc, conditionals) {
  const prev = usePrevious(conditionals)
  useEffect(() => {
    if (!prev || conditionals.some((x, i) => x !== prev[i])) {
      proc()
    }
  })
}
