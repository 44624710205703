import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import LinearProgress from '@material-ui/core/LinearProgress'

const ProgressBar = ({ className, value }) => (
  <LinearProgress
    value={value || 0}
    variant={typeof value === 'number' ? 'determinate' : 'indeterminate'}
    className={classNames({ [className]: Boolean(className) })}
  />
)

ProgressBar.propTypes = {
  value: PropTypes.number
}

export default ProgressBar
