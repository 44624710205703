import * as yup from 'yup'

export const PROMOTION_FOCUSES = {
  collection: 'Collection',
  product: 'Product'
}

export const PROMOTION_FOCUS_LABELS = Object.entries(
  PROMOTION_FOCUSES
).map(([key, value]) => ({ label: value, value: key }))

export const PROMOTION_TYPES = {
  BuyOneGetOne: 'Buy One Get One Free'
  // BuyXGetY: 'Buy One Get A Gift',
  // BuyXGetOneOf: 'Buy One Select a Gift',
  // BuyOneGetAmountOff: 'Buy One Get Amount Off'
}

export const PROMOTION_TYPE_LABELS = Object.entries(
  PROMOTION_TYPES
).map(([key, value]) => ({ label: value, value: key }))

export const PROMOTION_TYPE_OPTIONS = PROMOTION_TYPE_LABELS.map(
  discountType => discountType.value
)

export const AMOUNT_TYPES = {
  percentage: 'Percentage',
  fixed: 'Fixed Amount'
}

export const AMOUNT_TYPE_LABELS = Object.entries(
  AMOUNT_TYPES
).map(([key, value]) => ({ label: value, value: key }))

export const AMOUNT_TYPE_OPTIONS = AMOUNT_TYPE_LABELS.map(
  amountTypeLabel => amountTypeLabel.value
)

export const ACCESSORY_FORM_FIELDS = {
  // delete these values
  form_type: 'form_type'
}

export const SHARED_PROMOTION_FIELDS = {
  ...ACCESSORY_FORM_FIELDS,
  type: 'type',
  title: 'title',
  description: 'description',
  starts_at: 'starts_at',
  ends_at: 'ends_at',
  amount_type: 'amount_type',
  amount_value: 'amount_value',
  max_use_per_order: 'max_use_per_order',
  max_use_per_user: 'max_use_per_user',
  collection: 'collection',
  excluded_products: 'excluded_products',
  product_selection: 'product_selection',
  locations: 'locations'
}

export const COLLECTION_PROMOTION_FIELDS = {
  ...SHARED_PROMOTION_FIELDS,
  collection: 'collection',
  excluded_products: 'excluded_products'
}

export const PRODUCT_PROMOTION_FIELDS = {
  ...SHARED_PROMOTION_FIELDS,
  buy_product: 'buy_product'
}

export const ALL_PROMOTION_FIELDS = {
  ...COLLECTION_PROMOTION_FIELDS,
  ...PRODUCT_PROMOTION_FIELDS
}

export const SHARED_PROMOTION_SCHEMA = {
  [SHARED_PROMOTION_FIELDS.type]: yup
    .mixed()
    .oneOf([...PROMOTION_TYPE_OPTIONS])
    .required(),
  [SHARED_PROMOTION_FIELDS.title]: yup.string().required(),
  [SHARED_PROMOTION_FIELDS.description]: yup.string().nullable(),
  [SHARED_PROMOTION_FIELDS.starts_at]: yup.date().nullable(),
  [SHARED_PROMOTION_FIELDS.ends_at]: yup.date().nullable(),
  [SHARED_PROMOTION_FIELDS.amount_type]: yup
    .mixed()
    .oneOf([...AMOUNT_TYPE_OPTIONS])
    .required(),
  [SHARED_PROMOTION_FIELDS.max_use_per_order]: yup.number().positive(),
  [SHARED_PROMOTION_FIELDS.max_use_per_user]: yup.number().positive(),
  [SHARED_PROMOTION_FIELDS.product_selection]: yup.array().nullable(),
  [SHARED_PROMOTION_FIELDS.locations]: yup.array().nullable()
}

export const COLLECTION_PROMOTION_SCHEMA = {
  ...SHARED_PROMOTION_SCHEMA,
  [SHARED_PROMOTION_FIELDS.collection]: yup.object().nullable(),
  [SHARED_PROMOTION_FIELDS.excluded_products]: yup.array().nullable()
}

export const PRODUCT_PROMOTION_SCHEMA = {
  ...SHARED_PROMOTION_SCHEMA,
  [PRODUCT_PROMOTION_FIELDS.buy_product]: yup.object()
}
export const ALL_PROMOTION_SCHEMA = {
  ...COLLECTION_PROMOTION_SCHEMA,
  ...PRODUCT_PROMOTION_SCHEMA
}

export const SHARED_PROMOTION_FORM_DEFAULTS = {
  [SHARED_PROMOTION_FIELDS.form_type]: 'product',
  [SHARED_PROMOTION_FIELDS.type]: PROMOTION_TYPE_LABELS[0].value,
  [SHARED_PROMOTION_FIELDS.title]: '',
  [SHARED_PROMOTION_FIELDS.description]: '',
  [SHARED_PROMOTION_FIELDS.starts_at]: null,
  [SHARED_PROMOTION_FIELDS.ends_at]: null,
  [SHARED_PROMOTION_FIELDS.amount_type]: AMOUNT_TYPE_LABELS[0].value,
  [SHARED_PROMOTION_FIELDS.amount_value]: 0,
  [SHARED_PROMOTION_FIELDS.max_use_per_order]: 1,
  [SHARED_PROMOTION_FIELDS.max_use_per_user]: 1,
  [SHARED_PROMOTION_FIELDS.product_selection]: [],
  [SHARED_PROMOTION_FIELDS.locations]: []
}

export const COLLECTION_PROMOTION_FORM_DEFAULTS = {
  ...SHARED_PROMOTION_FORM_DEFAULTS,
  [SHARED_PROMOTION_FIELDS.collection]: null,
  [SHARED_PROMOTION_FIELDS.excluded_products]: [],
  [SHARED_PROMOTION_FIELDS.product_selection]: []
}

export const PRODUCT_PROMOTION_FORM_DEFAULTS = {
  ...SHARED_PROMOTION_FORM_DEFAULTS,
  [SHARED_PROMOTION_FIELDS.buy_product]: {}
}

export const ALL_PROMOTION_FORM_DEFAULTS = {
  ...COLLECTION_PROMOTION_FORM_DEFAULTS,
  ...PRODUCT_PROMOTION_FORM_DEFAULTS
}
