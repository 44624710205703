import { useResourceRQ } from 'hooks'
import { promotionsEndpoint } from 'api/endpoints'

const API_RESOURCE_NAME = 'discount'

const useAdminPromotion = id => {
  const {
    data: promotion,
    create: createPromotion,
    update: updatePromotion
  } = useResourceRQ({
    endpoint: promotionsEndpoint,
    args: [id],
    name: API_RESOURCE_NAME,
    fetchingEnabled: Boolean(id),
    dependents: [{ func: promotionsEndpoint }]
  })

  return {
    promotion,
    createPromotion,
    updatePromotion
  }
}

export default useAdminPromotion
