import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { FORM_FIELDS } from 'constants/products'
import { SIZE } from 'constants/enums'

import { useControlledForm } from 'components/ControlledForm'
import Button from 'components/Button'

import LockedIcon from 'icons/LockedIcon'
import UnlockedIcon from 'icons/UnlockedIcon'

import styles from './SetPDPBlockedButtonStyles'

const SetPDPBlockedButton = ({ classes }) => {
  const {
    formContext: { setValue, register, unregister, watch }
  } = useControlledForm()

  const { [FORM_FIELDS.LOCKED]: pdpBlockedState } = watch(
    [FORM_FIELDS.LOCKED].filter(n => n && typeof n === 'string')
  )

  useEffect(() => {
    register(FORM_FIELDS.LOCKED)
    return () => {
      unregister(FORM_FIELDS.LOCKED)
    }
  }, [register, unregister, FORM_FIELDS.LOCKED])

  return (
    <Button
      className={classes.pdpButton}
      label="save product changes button"
      name={FORM_FIELDS.LOCKED}
      size={SIZE.medium}
      dataTest="set-pdp-blocked-button"
      startIcon={pdpBlockedState ? <LockedIcon /> : <UnlockedIcon />}
      adaptive
      onClick={() => {
        setValue(FORM_FIELDS.LOCKED, !pdpBlockedState)
      }}
    >
      {pdpBlockedState ? 'Blocked' : 'Unblocked'}
    </Button>
  )
}

SetPDPBlockedButton.defaultProps = {}

SetPDPBlockedButton.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SetPDPBlockedButton)
