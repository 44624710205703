import { useFormContext, useController } from 'react-hook-form'

// This uses the same interface as useController, but assumes that defaults
// will be set from the useHook and reset.
// TODO(DAB-532): Migrate to v7 and use watch(() => setValue)
const useControlledFormController = ({
  name,
  rules,
  onChange: outerOnChange
}) => {
  const formContext = useFormContext()
  const {
    field: { onChange, ...otherField },
    meta
  } = useController({ name, control: formContext.control, rules })

  const composedOnChange = outerOnChange
    ? value => {
        onChange(value)
        outerOnChange(value, formContext)
      }
    : onChange

  return {
    field: { onChange: composedOnChange, ...otherField },
    meta
  }
}

export default useControlledFormController
