import React from 'react'
import PropTypes from 'prop-types'
import { colorsAF } from 'theme/colors'

const ExportIcon = ({ color = colorsAF.darkGray, fontSize = 24 }) => (
  <svg
    width={fontSize}
    height={fontSize}
    viewBox={`0 0 ${fontSize} ${fontSize}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="M17.72 13.519a1 1 0 00-.024-1.414l-5-4.83a1 1 0 00-1.39 0l-5 4.83a1 1 0 101.389 1.438l3.275-3.162V20a1 1 0 102 0v-9.679l3.337 3.222a1 1 0 001.414-.024z"
    />
    <path
      fill={color}
      d="M20 14a1 1 0 102 0V9a6 6 0 00-6-6H8a6 6 0 00-6 6v5a1 1 0 102 0V9a4 4 0 014-4h8a4 4 0 014 4v5z"
    />
  </svg>
)

ExportIcon.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.number
}

export default ExportIcon
