import React from 'react'
import PropTypes from 'prop-types'

const DefaultSizePx = 24

const CollectionsIcon = ({ fill, className, fontSize }) => (
  <svg
    width={fontSize}
    height={fontSize}
    viewBox="0 0 24 24"
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.59711 19.4024C4.75 20.8622 5.98356 21.9998 7.48378 22H19.0967C20.7002 21.9998 21.9998 20.7002 22 19.0969V7.484C21.9998 5.98378 20.8622 4.75 19.4027 4.59711C19.2478 3.14133 18.0116 2.00111 16.5162 2H4.90333C3.30422 2.00133 2.00133 3.30422 2 4.90333V16.5162C2.00111 18.0116 3.14133 19.2478 4.59711 19.4024ZM21.3549 7.484V19.0969C21.3547 19.7213 21.1027 20.2838 20.6936 20.6936C20.284 21.1027 19.7213 21.3547 19.0969 21.3549H7.48378C6.85911 21.3547 6.29667 21.1027 5.88711 20.6936C5.478 20.2838 5.226 19.7213 5.22578 19.0969V7.484C5.226 6.85933 5.478 6.29689 5.88711 5.88733C6.29667 5.47822 6.85933 5.22622 7.48378 5.226H19.0962C19.7207 5.22622 20.2838 5.47822 20.6936 5.88733C21.1027 6.29689 21.3547 6.85933 21.3549 7.484ZM2.64511 4.90333C2.64511 4.28267 2.89844 3.71956 3.30867 3.30889C3.71933 2.89867 4.28244 2.64533 4.90311 2.64533H16.516C17.1367 2.64533 17.6998 2.89867 18.1104 3.30889C18.4482 3.64689 18.6769 4.08911 18.748 4.58089H7.48356C5.88 4.58111 4.58044 5.88067 4.58022 7.48422V18.7487C4.08844 18.6773 3.64644 18.4487 3.30822 18.1111C2.898 17.7004 2.64467 17.1373 2.64467 16.5167L2.64511 4.90333Z"
      stroke={fill}
      // TODO(exrhizo): Fix that this isn't being picked up by the react...
      strokeWidth="1.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6043 10.9706L13.1106 15.4531C13.1167 15.5077 13.074 15.5553 13.0193 15.5553H11.5573C11.5092 15.5553 11.4693 15.5183 11.4656 15.4703L11.438 15.1031C11.4343 15.0553 11.3944 15.0183 11.3464 15.0183H9.68123C9.64876 15.0183 9.61861 15.0353 9.60209 15.0635L9.33974 15.5098C9.32313 15.5381 9.29292 15.5553 9.26028 15.5553H7.64765C7.57511 15.5553 7.53121 15.4753 7.57011 15.414C7.57011 15.414 9.6897 12.0818 10.399 10.9659C10.4078 10.9518 10.429 10.9201 10.4579 10.9043C10.4876 10.8882 10.5243 10.8887 10.5467 10.8889L10.5546 10.889C10.6854 10.8883 11.0685 10.889 11.0685 10.889L12.513 10.889C12.5598 10.889 12.5992 10.9241 12.6043 10.9706ZM11.1395 12.4479L10.3834 13.7341C10.3624 13.77 10.3883 13.8154 10.4301 13.8154H11.2829C11.3142 13.8154 11.339 13.7887 11.3367 13.7574L11.2397 12.4712C11.2357 12.4185 11.1664 12.4023 11.1395 12.4479ZM13.4269 15.5553H15.0217C15.0641 15.5553 15.1012 15.5272 15.112 15.4868L15.4342 14.2834C15.4449 14.2431 15.4821 14.2149 15.5244 14.2149H17.3174C17.3599 14.2149 17.3969 14.1867 17.4078 14.1462L17.7079 13.023C17.7234 12.9647 17.6787 12.9077 17.6175 12.9077H15.9165C15.8554 12.9077 15.8107 12.8508 15.826 12.7925L15.9507 12.3215C15.9613 12.2809 15.9985 12.2527 16.041 12.2527H18.2403C18.2872 12.2527 18.3281 12.2219 18.3405 12.1774L18.6634 11.0046C18.6795 10.9462 18.6347 10.8887 18.5732 10.8887H14.6073C14.5646 10.8887 14.5274 10.917 14.5168 10.9576L13.3364 15.4403C13.321 15.4986 13.3658 15.5553 13.4269 15.5553Z"
    />
  </svg>
)

CollectionsIcon.defaultProps = {
  fontSize: DefaultSizePx
}

CollectionsIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
  fontSize: PropTypes.number
}

export default CollectionsIcon
