import { masterColorsEndpoint } from 'api/endpoints'
import { useResourceListRQ } from 'hooks'

const useMasterColors = params => {
  const {
    data: masterColors,
    count: masterColorsCount,
    hasData: hasMasterColors,
    hasNextPage: hasMasterColorsNext,
    isLoading: isLoadingMasterColors,
    fetchNextPage: listMasterColorsNext
  } = useResourceListRQ({
    endpoint: masterColorsEndpoint,
    params,
    disableLoading: true
  })

  return {
    masterColors,
    hasMasterColors,
    masterColorsCount,
    hasMasterColorsNext,
    isLoadingMasterColors,
    listMasterColorsNext
  }
}

export default useMasterColors
