import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { isEmpty, keyBy } from 'lodash'
import moment from 'moment-timezone'

import { withStyles } from '@material-ui/core/styles'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import { centsToDollars, dollarsToCents } from 'utils/general'
import { getPromoCodeCaption } from 'utils/promoCodes'

import { CHECKBOX_VARIANT } from 'constants/enums'
import { URL } from 'constants/navigation'
import {
  PROMO_CODE_SCHEMAS,
  PROMO_CODE_BULK_SCHEMAS,
  PROMO_CODE_FORM_DEFAULTS,
  PROMO_CODE_FIELDS,
  APPLIES_TO_LABELS,
  MINIMUM_REQUIREMENT_LABELS,
  ELIGIBLE_CUSTOMER_LABELS,
  DISCOUNT_TYPE_NAME_OPTIONS
} from 'constants/promoCodes'

import {
  useMenu,
  useAdminPromoCodes,
  useLoaders,
  useNavigation,
  useMediaQuery,
  useAlerts,
  useConditionalEffect
} from 'hooks'
import { useLocationsContext } from 'context'
import { rawDate } from 'utils/dates'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'

import FileCopyIcon from '@material-ui/icons/FileCopy'

import { AutocompleteSelectLocationsControlled } from 'components/AutocompleteSelectLocations'
import { TitleElement } from 'components/SiteTitle'
import { DatePickerField } from 'components/DatePicker'
import Block from 'components/Block'
import ControlledForm from 'components/ControlledForm'
import Checkbox, { CheckboxField } from 'components/Checkbox'
import Dialog from 'components/Dialog'
import Header from 'components/Header'
import Layout from 'components/Layout'
import DataTable from 'components/DataTable'

import ExportIcon from 'icons/ExportIcon'

import BulkCodeInput from './BulkCodeInput'
import UsageLimitsCheckboxes from './UsageLimitsCheckboxes'
import CodeInputField from './CodeInputField'
import EligibleUsersInputRadios from './EligibleUsersInputRadios'
import MinimumRequirementsInputRadios from './MinimumRequirementsInputRadios'
import DiscountTypeRadioInputs from './DiscountTypeRadioInputs'
import ApplicationRadioInputs from './ApplicationRadioInputs'
import SaveButton from './SaveButton'

import styles from './PromoCodeFormStyles'

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm'

const FORM_MODES = {
  bulk: 'bulk'
}

const PromoCodeForm = ({
  classes,
  match: {
    params: { id: promoCodeId }
  },
  history: {
    location: { state }
  }
}) => {
  const { locationId } = useLocationsContext()
  const { showAlertGeneral, showAlertError } = useAlerts()
  const {
    open: menuOpen,
    anchorEl: menuAnchorEl,
    handleToggleMenu: toggleMenu,
    handleClose: handleMenuClose
  } = useMenu()
  const { showLoading, hideLoading } = useLoaders()
  const { go } = useNavigation({})
  const { isDesktopScreen } = useMediaQuery()
  const {
    promoCode,
    createPromoCode,
    readPromoCode,
    updatePromoCode,
    generatePromoCode,
    deletePromoCode,
    bulkCreate,
    exportPromosToCsv,
    importCSV
  } = useAdminPromoCodes(
    promoCodeId,
    { locationId },
    {
      onImportCSVSuccess: async () => {
        showLoading()
        await readPromoCode(promoCodeId)
        hideLoading()
      }
    }
  )

  const [bulkPromoCodes, setBulkPromoCodes] = useState([])
  const [showBulkPromosDialog, setShowBulkPromosDialog] = useState(false)
  const [bulkPromoSelections, setBulkPromoCodeSelections] = useState({})

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [formModified, setFormModified] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [formDefaults, setFormDefaults] = useState(PROMO_CODE_FORM_DEFAULTS)

  const defaultTitle = promoCodeId ? 'Loading' : 'Untitled'
  const [title, setTitle] = useState(defaultTitle)

  const transformPromoToFormFields = ({
    code,
    code_type,
    amount,
    min_purchase_amount,
    first_time_only,
    once_per_customer,
    eligible_user_ids,
    eligible_products,
    eligible_collections,
    excluded_products,
    excluded_collections,
    start_time,
    max_uses,
    end_time,
    active,
    locations,
    ...rest
  }) => {
    let finalAmount = amount ?? 0
    if (code_type === 'fixed') {
      finalAmount = centsToDollars(amount)
    }

    setTitle(promoCodeId ? code : 'Untitled')

    const getApplicationSelection = () => {
      if (
        !eligible_products?.length &&
        !eligible_collections?.length &&
        !excluded_products?.length &&
        !excluded_collections?.length
      ) {
        return 0
      }

      if (eligible_products?.length || excluded_products?.length) {
        return 1
      }

      if (eligible_collections?.length || excluded_collections?.length) {
        return 2
      }
    }

    return {
      [PROMO_CODE_FIELDS.label]: promoCodeId ? code : '',
      [PROMO_CODE_FIELDS.discount_type]: code_type,
      [PROMO_CODE_FIELDS.amount]: finalAmount,
      [PROMO_CODE_FIELDS.eligible_product_ids]: eligible_products,
      [PROMO_CODE_FIELDS.eligible_collection_ids]: eligible_collections,
      [PROMO_CODE_FIELDS.excluded_product_ids]: excluded_products,
      [PROMO_CODE_FIELDS.excluded_collection_ids]: excluded_collections,
      [PROMO_CODE_FIELDS.minimum_requirement]: centsToDollars(
        min_purchase_amount ?? 0
      ),
      [PROMO_CODE_FIELDS.eligible_user_ids]:
        eligible_user_ids?.length > 15
          ? eligible_user_ids
          : rest.eligible_users,
      [PROMO_CODE_FIELDS.usage_limits]: {
        one_per: once_per_customer ?? false,
        first_time: first_time_only ?? false,
        max_uses: Boolean(max_uses)
      },
      [PROMO_CODE_FIELDS.max_uses]: max_uses ?? null,
      [PROMO_CODE_FIELDS.start_time]: rawDate(start_time),
      [PROMO_CODE_FIELDS.end_time]: rawDate(end_time),
      [PROMO_CODE_FIELDS.application_selection]:
        APPLIES_TO_LABELS[getApplicationSelection()].value,
      [PROMO_CODE_FIELDS.min_req_selection]:
        MINIMUM_REQUIREMENT_LABELS[min_purchase_amount ? 1 : 0].value,
      [PROMO_CODE_FIELDS.eligible_users_selection]:
        ELIGIBLE_CUSTOMER_LABELS[eligible_user_ids?.length ? 1 : 0].value,
      [PROMO_CODE_FIELDS.active]: active,
      [PROMO_CODE_FIELDS.locations]: locations ?? []
    }
  }

  const handleImportEligibleUserIdsClick = async () => {
    showLoading()
    await exportPromosToCsv()
    hideLoading()
  }

  const handleExportEligibleUserIdsClick = async e => {
    if (e.target.files.length > 0) {
      importCSV(e.target)
    } else {
      showAlertError('No files specified for upload')
    }
  }

  const generatePromo = async () => {
    showLoading()
    const { code } = await generatePromoCode()
    hideLoading()
    return code
  }

  const handleDeletePromoCode = async () => {
    showLoading()
    await deletePromoCode(promoCode)
    setShowConfirmDeleteModal(false)
    go({}, URL.ADMIN_PROMO_CODES)
    hideLoading()
  }

  const handleSelect = (promo, isSelected) => {
    const clone = { ...bulkPromoSelections }
    if (isSelected) {
      clone[promo.id] = promo
    } else {
      delete clone[promo.id]
    }

    setBulkPromoCodeSelections(clone)
  }

  const handleSelectAll = isChecked => {
    if (isChecked) {
      setBulkPromoCodeSelections(keyBy(bulkPromoCodes, v => v.id))
    } else {
      setBulkPromoCodeSelections({})
    }
  }

  const handleExportPromoCodes = async () => {
    showLoading()
    isEmpty(bulkPromoSelections)
      ? showAlertGeneral('Select promo codes to export')
      : await exportPromosToCsv(
          Object.values(bulkPromoSelections).map(item => item.id)
        )
    hideLoading()
  }

  const copyBulkPromoCodes = async () => {
    if (isEmpty(bulkPromoSelections)) {
      showAlertGeneral('Select promo codes to copy/paste')
    } else {
      await navigator.clipboard.writeText(
        Object.values(bulkPromoSelections)
          .map(item => item.code)
          .join('\n')
      )
      showAlertGeneral(
        `${Object.keys(bulkPromoSelections).length} promos copied to clipboard.`
      )
    }
  }

  const allSelected =
    bulkPromoCodes &&
    Object.keys(bulkPromoSelections).length === bulkPromoCodes.length

  useConditionalEffect(() => {
    ;(async () => {
      if (promoCodeId || state?.code) {
        showLoading()
        const promo = await readPromoCode(promoCodeId ?? state?.code)
        setFormDefaults(transformPromoToFormFields(promo))
        hideLoading()
      }
    })()
  }, [state, promoCodeId])

  const handleSubmit = async ({
    prefix,
    quantity,
    discount_type: discountType,
    minimum_requirement: minReq,
    usage_limits: limits,
    label,
    start_time: start,
    end_time: end,
    eligible_product_ids: eligibleProducts,
    eligible_collection_ids: eligibleCollections,
    excluded_product_ids: excludedProducts,
    excluded_collection_ids: excludedCollections,
    eligible_user_ids: eligibleUsers,
    amount,
    max_uses,
    application_selection: applicationSelection,
    active,
    locations
  }) => {
    setSubmitting(true)
    showLoading()

    const promoObject = {
      code_type: discountType,
      active,
      amount:
        discountType === DISCOUNT_TYPE_NAME_OPTIONS.FIXED
          ? dollarsToCents(amount)
          : amount ?? 0,

      min_purchase_amount: dollarsToCents(minReq ?? 0),

      // applies to
      once_per_customer: limits.one_per,
      first_time_only: limits.first_time,
      max_uses: limits.max_uses && (max_uses ?? null),
      location_ids:
        locations?.reduce((ids, location) => [...ids, location.id], []) ?? [],

      eligible_user_ids:
        !eligibleUsers.length ||
        typeof eligibleUsers[0] === 'number' ||
        (typeof eligibleUsers[0] === 'string' &&
          typeof eligibleUsers[0] !== 'object')
          ? eligibleUsers
          : eligibleUsers.reduce((ids, user) => [...ids, user.id], []),

      eligible_product_ids:
        applicationSelection === 'select_products'
          ? eligibleProducts.reduce((ids, product) => [...ids, product.id], [])
          : [],
      eligible_collection_ids:
        applicationSelection === 'select_collections'
          ? eligibleCollections.reduce(
              (ids, collection) => [...ids, collection.id],
              []
            )
          : [],

      excluded_product_ids:
        applicationSelection === 'select_products'
          ? excludedProducts.reduce((ids, product) => [...ids, product.id], [])
          : [],
      excluded_collection_ids:
        applicationSelection === 'select_collections'
          ? excludedCollections.reduce(
              (ids, collection) => [...ids, collection.id],
              []
            )
          : [],

      code: label,

      ...(!!start && {
        start_date_string: moment(start).format(DATE_FORMAT),
        start_time_string: moment(start).format(TIME_FORMAT)
      }),
      ...(!!end && {
        end_date_string: moment(end).format(DATE_FORMAT),
        end_time_string: moment(end).format(TIME_FORMAT)
      })
    }

    if (state?.mode === 'bulk') {
      const bulkPromos = await bulkCreate({
        prefix,
        quantity,
        discount_code: promoObject
      })
      setBulkPromoCodes(bulkPromos)
      setShowBulkPromosDialog(true)
      hideLoading()
    } else {
      const handlePromoFn = promoCodeId ? updatePromoCode : createPromoCode
      const promo = await handlePromoFn({
        ...(promoCodeId ? { id: promoCodeId } : {}),
        ...promoObject
      })

      if (!promoCodeId) {
        setFormDefaults(PROMO_CODE_FORM_DEFAULTS)
        go({}, `${URL.ADMIN_PROMO_CODES}/${promo.id}`, false)
      } else {
        setFormDefaults(transformPromoToFormFields(promo))
        setTitle(label)
      }
    }

    setSubmitting(false)
    formModified && setFormModified(false)
  }

  return (
    <Layout id="promo-code-form">
      <TitleElement title={title} />
      <ControlledForm
        handleSubmit={handleSubmit}
        schemas={
          state?.mode === 'bulk' ? PROMO_CODE_BULK_SCHEMAS : PROMO_CODE_SCHEMAS
        }
        defaultValues={formDefaults}
        resetOnSubmit={!promoCodeId}
        shouldUnregister={false}
      >
        <Box className={classes.headerContainer}>
          <Header
            sticky
            breadcrumbs={[
              { title: 'Back of House', link: URL.ADMIN_BOH },
              { title: 'Promo Codes', link: URL.ADMIN_PROMO_CODES }
            ]}
            title={title}
            actions={
              <>
                {[promoCode?.can_delete, promoCodeId].filter(Boolean).length ? (
                  <Box
                    className={classes.menuButton}
                    onClick={e => toggleMenu(e)}
                    data-test="promo-code-actions"
                  >
                    <MoreVertIcon />
                    <Menu
                      open={menuOpen}
                      onClose={handleMenuClose}
                      anchorEl={menuAnchorEl}
                    >
                      {promoCode?.can_delete && (
                        <MenuItem
                          onClick={() => setShowConfirmDeleteModal(true)}
                          data-test="promo-delete-action"
                        >
                          Delete
                        </MenuItem>
                      )}
                      {promoCodeId && (
                        <MenuItem
                          data-test="promo-duplicate-action"
                          onClick={() =>
                            go({}, `${URL.ADMIN_PROMO_CODES_FORM}`, false, {
                              code: promoCode?.id
                            })
                          }
                        >
                          Duplicate
                        </MenuItem>
                      )}
                      {promoCodeId && (
                        <MenuItem
                          onClick={() =>
                            go(
                              {},
                              `${URL.ADMIN_PROMO_CODES_HISTORY}/${promoCode?.id}`,
                              false
                            )
                          }
                        >
                          View History
                        </MenuItem>
                      )}
                    </Menu>
                  </Box>
                ) : null}

                <SaveButton
                  promoCodeId={promoCodeId}
                  submitting={submitting}
                  formModified={formModified}
                />
              </>
            }
          />
        </Box>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid item sm={12} md={6} className={classes.gridItem}>
            <Block withPadding>
              <Box className={classes.formSection}>
                <Box mb={2}>
                  <Typography variant="h4">Promo Code</Typography>
                </Box>
                {state?.mode === FORM_MODES.bulk ? (
                  <BulkCodeInput classes={classes} />
                ) : (
                  <CodeInputField
                    classes={classes}
                    generatePromo={generatePromo}
                    setFormModified={setFormModified}
                    setFormDefaults={setFormDefaults}
                    formModified={formModified}
                  />
                )}
              </Box>
              <Box className={classes.formSection}>
                <Box mb={6} width="100%">
                  <Box mb={2}>
                    <Box>
                      <Typography variant="h4">
                        Applies to These Locations
                      </Typography>
                    </Box>
                    <Box
                      my={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Box mr={1} mt={0.5}>
                        <AiOutlineInfoCircle />
                      </Box>
                      <Typography variant="caption">
                        NOTE: No Location Selections = "All Locations"
                      </Typography>
                    </Box>
                  </Box>
                  <AutocompleteSelectLocationsControlled
                    name={PROMO_CODE_FIELDS.locations}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box className={classes.formSection}>
                <Box mb={2}>
                  <Typography variant="h4">Discount Type</Typography>
                </Box>
                <DiscountTypeRadioInputs classes={classes} />
              </Box>
              <Box className={classes.formSection}>
                <Box mb={2}>
                  <Typography variant="h4">Application</Typography>
                </Box>
                <ApplicationRadioInputs classes={classes} />
              </Box>
              <Box className={classes.formSection}>
                <Box mb={2}>
                  <Typography variant="h4">Options</Typography>
                </Box>
                <MinimumRequirementsInputRadios classes={classes} />
                <EligibleUsersInputRadios
                  editMode={Boolean(promoCodeId)}
                  formModified={formModified}
                  classes={classes}
                  handleImport={handleImportEligibleUserIdsClick}
                  handleExport={handleExportEligibleUserIdsClick}
                  savedEligibleUserIds={promoCode?.eligible_user_ids ?? []}
                />
              </Box>
              <Box className={classes.formSection}>
                <Box mb={2}>
                  <Typography variant="h4">Usage Limits</Typography>
                </Box>
                <Typography variant="caption">Limit to</Typography>
                <UsageLimitsCheckboxes classes={classes} />
              </Box>
            </Block>
          </Grid>
          <Grid item sm={12} md={6} className={classes.gridItem}>
            <Block withPadding>
              {promoCodeId && (
                <>
                  <Box mb={2} width="100%" className={classes.formSection}>
                    <Typography variant="h4">Summary</Typography>
                    <Box mt={3} width="100%" className={classes.flagBlock}>
                      <Box>
                        <Typography variant="body1">Activate Promo?</Typography>
                        <Typography variant="body1" color="textSecondary">
                          {!promoCode?.active
                            ? 'Unchecking will deactivate this promo'
                            : 'Checking will activate this promo'}
                        </Typography>
                      </Box>
                      <Box>
                        <CheckboxField name={PROMO_CODE_FIELDS.active} />
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.divider} />
                </>
              )}
              <Box className={classes.formSection}>
                <Box mb={2}>
                  <Typography variant="h4">Schedule Promo</Typography>
                  <Box
                    mb={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <AiOutlineInfoCircle />
                    <Box ml={1}>
                      <Typography variant="body1">
                        Note: This Start / End Date Time is applied locally to
                        the Mobile App User's time zone automatically.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection={isDesktopScreen ? 'column' : 'row'}
                >
                  <DatePickerField
                    className={classNames({
                      [classes.dateInput]: true,
                      [classes.controlContainer]: true
                    })}
                    name={PROMO_CODE_FIELDS.start_time}
                    label="Start Date and Time"
                    withTime
                    forceMobile
                    showClearButton
                  />

                  <DatePickerField
                    className={classNames({
                      [classes.dateInput]: true,
                      [classes.controlContainer]: true
                    })}
                    name={PROMO_CODE_FIELDS.end_time}
                    label="End Date and Time"
                    withTime
                    forceMobile
                    showClearButton
                  />
                </Box>
              </Box>
            </Block>
          </Grid>
        </Grid>
      </ControlledForm>
      <Dialog
        open={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleDeletePromoCode}
        confirmText="Continue"
        hideCancel
      >
        <Box mt={1}>
          <Typography variant="h5">Confirm Action</Typography>
        </Box>
        <Box my={3}>
          <Typography variant="body1">
            Are you sure you want to delete this promo code? This action cannot
            be undone.
          </Typography>
        </Box>
      </Dialog>
      {state?.mode === 'bulk' && (
        <Dialog
          open={showBulkPromosDialog}
          onClose={() => setShowBulkPromosDialog(false)}
          onConfirm={() => go({}, URL.ADMIN_PROMO_CODES)}
          confirmText="Continue"
          additionalActions={
            <>
              <Box mr={2} display="inline">
                <IconButton
                  edge="end"
                  onClick={copyBulkPromoCodes}
                  type="button"
                >
                  <FileCopyIcon />
                </IconButton>
              </Box>
              <IconButton
                edge="end"
                onClick={() => {
                  locationId
                    ? handleExportPromoCodes()
                    : showAlertGeneral(
                        'Please select a location to export promo codes.'
                      )
                }}
                type="button"
              >
                <ExportIcon />
              </IconButton>
            </>
          }
        >
          <Box mt={1}>
            <Typography variant="h5">Generated Promos</Typography>
            <Typography variant="body1">
              This is a list of all generated promos
            </Typography>
          </Box>
          <Box my={3}>
            <DataTable
              columns={[{ title: 'Code' }]}
              headerActionOpen={true}
              headerAction={
                <Box display="flex" alignItems="center" pl={0.5}>
                  <Checkbox
                    variant={CHECKBOX_VARIANT.light}
                    onChange={handleSelectAll}
                    indeterminate={!allSelected}
                    checked={allSelected}
                  />
                  <Typography variant="body2">Select All</Typography>
                </Box>
              }
            >
              {bulkPromoCodes?.map(item => (
                <TableRow>
                  <TableCell>
                    <Box display="flex">
                      <Box mr={2}>
                        <Checkbox
                          onChange={checked => handleSelect(item, checked)}
                          checked={item.id in bulkPromoSelections}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body1">{item.code}</Typography>
                        <Typography variant="caption">
                          {getPromoCodeCaption(item)}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </DataTable>
          </Box>
        </Dialog>
      )}
    </Layout>
  )
}

PromoCodeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(PromoCodeForm)
