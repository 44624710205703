import { useResourceListRQ, useResourceRQ } from 'hooks'
import { LIST_PATHS } from 'constants/queryParams'
import { masterBadgeEndpoint, masterBadgeActiveEndpoint } from 'api/endpoints'
import { multiFieldFileInputProcessor } from 'hooks/common/useResourceRQ'

const API_RESOURCE_NAME = 'badge'

const useAdminBadges = params => {
  const ACTIVE_BADGE = `${LIST_PATHS.BADGES_STATUS}=true`

  const {
    isLoading: isLoadingBadges,
    data: badges,
    hasData: hasBadges,
    hasNextPage: hasBadgesNext,
    count: badgesCount,
    fetchNextPage: listBadgesNext
  } = useResourceListRQ({
    endpoint: masterBadgeEndpoint,
    params
  })

  const {
    count: activeBadgesCount,
    list: listActiveBadges
  } = useResourceListRQ({
    endpoint: () => masterBadgeActiveEndpoint(ACTIVE_BADGE)
  })

  const { create: createBadge, update: updateBadge } = useResourceRQ({
    endpoint: masterBadgeEndpoint,
    name: API_RESOURCE_NAME,
    inputProcessor: multiFieldFileInputProcessor(API_RESOURCE_NAME),
    fetchingEnabled: false,
    dependents: [
      () => masterBadgeEndpoint(),
      () => masterBadgeActiveEndpoint(ACTIVE_BADGE)
    ],
    activeLoaders: true,
    showServerError: true,
    onSuccess: {
      updateMsg: () => 'Badge successfully updated',
      createMsg: () => 'Badge successfully created'
    }
  })

  return {
    isLoadingBadges,
    badges,
    hasBadges,
    hasBadgesNext,
    badgesCount,
    listBadgesNext,
    createBadge,
    updateBadge,
    activeBadgesCount,
    listActiveBadges
  }
}

export default useAdminBadges
