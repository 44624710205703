import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { isEmpty, isEqual } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { RiErrorWarningLine } from 'react-icons/ri'

import { Box, Typography } from '@material-ui/core'

import SaveIcon from '@material-ui/icons/Save'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { SIZE, COLOR } from 'constants/enums'
import { URL } from 'constants/navigation'
import {
  ALL_PROMOTION_SCHEMA,
  ALL_PROMOTION_FORM_DEFAULTS,
  PROMOTION_TYPE_LABELS,
  AMOUNT_TYPE_LABELS,
  ALL_PROMOTION_FIELDS,
  PROMOTION_FOCUS_LABELS,
  ACCESSORY_FORM_FIELDS,
  AMOUNT_TYPE_OPTIONS
} from 'constants/promotions'
import {
  useBeforeUnload,
  useLoaders,
  useAdminPromotion,
  useConditionalEffect,
  usePrevious,
  useNavigation
} from 'hooks'

import { AutocompleteSearchCollectionsControlled } from 'components/AutocompleteSearchCollections'
import { AutocompleteSelectLocationsControlled } from 'components/AutocompleteSelectLocations/'
import { AutocompleteSearchProductControlled } from 'components/AutocompleteSearchProduct'
import { DatePickerField } from 'components/DatePicker'
import { SelectField } from 'components/Select'
import { TextFieldControlled } from 'components/TextField'
import { TitleElement } from 'components/SiteTitle'
import { useControlledForm } from 'components/ControlledForm'
import Button from 'components/Button'
import ControlledForm from 'components/ControlledForm/ControlledForm'
import Header from 'components/Header'
import Layout from 'components/Layout'

import styles from './PromotionFormStyles'

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm'

const SmartFormFields = ({ classes }) => {
  const {
    formContext: { getValues, setValue }
  } = useControlledForm()

  // when user updates the form, not when the initial default values change from a new object
  // we check to see if the values change to ensure the set
  const { form_type, type, amount_type } = getValues()
  const prevFormType = usePrevious(form_type)
  useConditionalEffect(() => {
    if (!!prevFormType && !isEqual(form_type, prevFormType)) {
      setValue(ALL_PROMOTION_FIELDS.buy_product, {})
      setValue(ALL_PROMOTION_FIELDS.collection, {})
    }
  }, [form_type])

  return (
    <>
      <Box mb={4}>
        <Box mb={1}>
          <Typography variant="body2">Location</Typography>
        </Box>
        <AutocompleteSelectLocationsControlled
          className={classes.thinInput}
          name={ALL_PROMOTION_FIELDS.locations}
          fullWidth
        />
      </Box>
      <Box mb={2}>
        <Box>
          <Typography variant="body2">Type of Promotion</Typography>
        </Box>
        <SelectField
          fullWidth
          name={ALL_PROMOTION_FIELDS.type}
          items={PROMOTION_TYPE_LABELS}
          dataTest="promotion-type"
        />
      </Box>
      <Box>
        <Typography variant="body2">Title</Typography>
        <TextFieldControlled
          className={classes.thinInput}
          name={ALL_PROMOTION_FIELDS.title}
          fullWidth
        />
      </Box>
      <Box mb={2}>
        <Typography variant="body2">Collection or a Product?</Typography>
        <SelectField
          fullWidth
          name={ALL_PROMOTION_FIELDS.form_type}
          items={PROMOTION_FOCUS_LABELS}
          dataTest="promotion-type"
        />
      </Box>
      {form_type === 'collection' && (
        <>
          <Box mb={2}>
            <Box mb={1}>
              <Typography variant="body2">Collection</Typography>
            </Box>
            <AutocompleteSearchCollectionsControlled
              fullWidth
              name={ALL_PROMOTION_FIELDS.collection}
            />
          </Box>
        </>
      )}
      {form_type === 'product' && (
        <Box mb={2}>
          <Box mb={1}>
            <Typography variant="body2">
              What Product Does it Apply to?
            </Typography>
          </Box>
          <AutocompleteSearchProductControlled
            fullWidth
            name={ALL_PROMOTION_FIELDS.buy_product}
          />
        </Box>
      )}
      <Box display="flex" alignItems="flex-start" mb={2}>
        <Box mr={2} width="100%">
          <Typography variant="body2">Start Time</Typography>
          <Typography variant="body1" className={classes.smallNoticeText}>
            Empty = Starts Now.
          </Typography>
          <DatePickerField
            fullWidth
            placeholder="Day"
            name={ALL_PROMOTION_FIELDS.starts_at}
            withTime
            dataTest="promotion-starts-at"
          />
        </Box>
        <Box width="100%">
          <Typography variant="body2">End Time</Typography>
          <Typography variant="body1" className={classes.smallNoticeText}>
            Empty = Never Ending.
          </Typography>
          <DatePickerField
            fullWidth
            placeholder="Day"
            name={ALL_PROMOTION_FIELDS.ends_at}
            withTime
            dataTest="promotion-ends-at"
          />
        </Box>
      </Box>
      <Box width="100%">
        <Typography variant="body2">Max Limit per Order</Typography>
        <TextFieldControlled
          number
          className={classes.thinInput}
          name={ALL_PROMOTION_FIELDS.max_use_per_order}
          fullWidth
        />
      </Box>
      {type === 'BuyOneGetAmountOff' && (
        <Box display="flex" alignItems="flex-start">
          <Box mr={2} width="100%">
            <Typography variant="body2">What's the Amount Type?</Typography>
            <SelectField
              className={classes.thinInput}
              fullWidth
              name={ALL_PROMOTION_FIELDS.amount_type}
              items={AMOUNT_TYPE_LABELS}
              dataTest="promotion-type"
            />
          </Box>
          <Box width="100%">
            <Typography variant="body2">What's the Amount Value?</Typography>
            <TextFieldControlled
              number
              className={classes.thinInput}
              name={ALL_PROMOTION_FIELDS.amount_value}
              fullWidth
              startAdornment={
                amount_type === AMOUNT_TYPE_OPTIONS[0] ? '%' : '$'
              }
            />
          </Box>
        </Box>
      )}
      {type !== 'BuyOneGetOne' && (
        <Box mb={2}>
          <Box mb={1}>
            <Typography variant="body2">
              What Are the Promotional Products?
            </Typography>
            <Box>
              <Box
                mt={1}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <RiErrorWarningLine fontSize={20} />{' '}
                <Box ml={1}>
                  <Typography variant="body1" style={{ marginTop: 3 }}>
                    Selection is limited based on promotional type, these apply:
                  </Typography>
                </Box>
              </Box>
              <Box mt={-1}>
                <Typography variant="body1">
                  <ul>
                    <li>
                      <strong>No Selection</strong> will be saved for{' '}
                      <u>Buy One Get One</u>,
                    </li>
                    <li>
                      <strong>Only One Selection</strong> will be saved for{' '}
                      <u>Buy One Get One Of</u>,
                    </li>
                    <li>
                      <strong>All Selections</strong> will be saved for{' '}
                      <u>Buy X Get Y</u>
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Box>
          </Box>
          <AutocompleteSearchProductControlled
            fullWidth
            multiple
            name={ALL_PROMOTION_FIELDS.product_selection}
          />
        </Box>
      )}
    </>
  )
}

const PromotionForm = ({
  classes,
  match: {
    params: { id: promotionId }
  }
}) => {
  const { go } = useNavigation({})
  const { showLoading, hideLoading } = useLoaders()
  const { promotion, updatePromotion, createPromotion } = useAdminPromotion(
    promotionId
  )

  const [submitting, setSubmitting] = useState(false)
  const [formDefaults, setFormDefaults] = useState(ALL_PROMOTION_FORM_DEFAULTS)

  const constructFormDefaultsFromPromotion = ({
    type,
    title,
    starts_at,
    ends_at,
    amount_type,
    amount_value,
    max_use_per_order,
    buy_product,
    // collection specific
    collection,
    // product specific
    product_selection,
    locations
  }) => {
    const sharedFields = {
      [ALL_PROMOTION_FIELDS.type]: type,
      [ALL_PROMOTION_FIELDS.title]: title,
      [ALL_PROMOTION_FIELDS.starts_at]: starts_at,
      [ALL_PROMOTION_FIELDS.ends_at]: ends_at,
      [ALL_PROMOTION_FIELDS.amount_type]: amount_type,
      [ALL_PROMOTION_FIELDS.amount_value]: amount_value,
      [ALL_PROMOTION_FIELDS.max_use_per_order]: max_use_per_order,
      [ALL_PROMOTION_FIELDS.product_selection]: product_selection ?? [],
      [ALL_PROMOTION_FIELDS.locations]: locations
    }

    return {
      ...(!isEmpty(collection)
        ? {
            [ALL_PROMOTION_FIELDS.form_type]: 'collection',
            [ALL_PROMOTION_FIELDS.collection]: collection ?? {}
          }
        : {
            [ALL_PROMOTION_FIELDS.form_type]: 'product',
            [ALL_PROMOTION_FIELDS.buy_product]: buy_product ?? {
              title: 'Empty'
            }
          }),
      ...sharedFields
    }
  }

  useConditionalEffect(
    () =>
      !isEmpty(promotion) &&
      setFormDefaults(constructFormDefaultsFromPromotion(promotion)),
    [promotion]
  )

  const handleSubmit = async ({
    // helpers
    form_type,
    // shared
    amount_type,
    amount_value,
    ends_at,
    max_use_per_order,
    product_selection,
    starts_at,
    title,
    type,
    // product specific
    buy_product,
    // collection specific
    collection,
    locations
  }) => {
    setSubmitting(true)
    showLoading()

    const execute = promotionId ? updatePromotion : createPromotion

    const getProductSelection = () => {
      if (!product_selection?.length || type === 'BuyOneGetOne') {
        return []
      }

      const productIds = product_selection.map(product => product.id)

      if (type === 'BuyXGetY') {
        return !!productIds?.[0] ? [productIds?.[0]] : []
      }

      return productIds
    }

    const { data: promo } = await execute({
      title,
      type,
      max_use_per_order,
      location_ids: !isEmpty(locations)
        ? [...locations.map(loc => loc.id)]
        : [],
      offering_product_ids: getProductSelection(),
      ...(type === 'BuyOneGetAmountOff'
        ? {
            amount_type,
            amount_value
          }
        : {}),
      ...(!!starts_at
        ? {
            starts_at_date: moment(starts_at).format(DATE_FORMAT),
            starts_at_time: moment(starts_at).format(TIME_FORMAT)
          }
        : {}),
      ...(!!ends_at
        ? {
            ends_at_date: moment(ends_at).format(DATE_FORMAT),
            ends_at_time: moment(ends_at).format(TIME_FORMAT)
          }
        : {}),
      ...(form_type === 'collection'
        ? {
            collection_id: collection.id
          }
        : {
            buy_product_id: buy_product.id
          })
    })

    if (!promotionId) {
      go({}, `${URL.ADMIN_PROMOTIONS}/${promo.id}`, false)
      return
    }

    if (promo.id) {
      setSubmitting(false)
      hideLoading()
    }
  }

  const SaveButton = () => {
    const {
      handleSubmit: localHandleSubmit,
      formContext: {
        formState: { dirtyFields }
      }
    } = useControlledForm()

    // remove form utils
    Object.keys(ACCESSORY_FORM_FIELDS).forEach(
      field => delete dirtyFields[field]
    )

    const { OnBeforeUnloadPrompt } = useBeforeUnload(!isEmpty(dirtyFields))

    return (
      <>
        <Button
          label="save promotion button"
          onClick={localHandleSubmit}
          size={SIZE.medium}
          color={COLOR.primary}
          dataTest="promotion-save-button"
          startIcon={promotionId ? <SaveIcon /> : <CheckCircleOutlineIcon />}
          adaptive
          disabled={isEmpty(dirtyFields) || submitting}
          type="submit"
        >
          {promotionId ? 'Save' : 'Create'}
        </Button>
        <OnBeforeUnloadPrompt />
      </>
    )
  }

  const actions = (
    <>
      <SaveButton
        promotionId={promotionId}
        submitting={false}
        formModified={false}
      />
    </>
  )

  const pageTitle = promotionId ? promotion?.title ?? promotionId : 'New'

  return (
    <Layout className={classes.root} id="PromotionForm">
      <TitleElement title={pageTitle} />
      <ControlledForm
        handleSubmit={handleSubmit}
        schemas={ALL_PROMOTION_SCHEMA}
        defaultValues={formDefaults}
        resetOnSubmit={!promotionId}
        shouldUnregister={false}
      >
        <Header
          sticky
          breadcrumbs={[
            { title: 'Back of House', link: URL.ADMIN_BOH },
            { title: 'Promotions', link: URL.ADMIN_PROMOTIONS }
          ]}
          title={pageTitle}
          actions={actions}
        />

        <Box px={1}>
          <Box className={classes.divider} mb={2} />
          <Box mb={4}>
            <Box mb={2}>
              <Typography variant="h3">
                {promotionId ? 'Update Promotion' : 'Create Promotion'}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <RiErrorWarningLine fontSize={20} />{' '}
              <Box ml={1}>
                <Typography variant="body1">
                  Before creating a promotion, ensure you have selected the
                  correct location in the sidebar.
                </Typography>
              </Box>
            </Box>
          </Box>
          <SmartFormFields classes={classes} />
        </Box>
      </ControlledForm>
    </Layout>
  )
}

PromotionForm.defaultProps = {}

PromotionForm.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PromotionForm)
