import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@material-ui/core'

import { useLocationsContext } from 'context'

import { SIZE, COLOR, BUTTON_VARIANT } from 'constants/enums'
import { INVENTORY_UPDATE_MODES } from 'constants/general'

import { useAdminPlate, useConditionalEffect } from 'hooks'

import Button from 'components/Button'
import TextField from 'components/TextField'
import ProductSummary from 'components/ProductSummary'
import Dialog from 'components/Dialog'
import BinSelector from 'components/BinSelector'
import DatePicker from 'components/DatePicker'
import Tabs from 'components/Tabs'
import Checkbox from 'components/Checkbox'

import styles from './PlateModalStyles'

const REASON_TYPE = {
  other: 'other',
  expired: 'expired'
}

const PlateModal = ({ classes, open, variants = [], onClose, plate }) => {
  const { locationId } = useLocationsContext()

  const [active, setActive] = useState(INVENTORY_UPDATE_MODES.SET_TO)
  const [quantity, setQuantity] = useState(plate?.quantity)

  const [quantityEntered, setQuantityEntered] = useState(false)

  const [binLabel, setBinLabel] = useState(plate?.item_location?.label)
  const [expirationDate, setExpirationDate] = useState(plate?.expiration_date)
  const [selectedBin, setSelectedBin] = useState('')
  const [transferQuantity, setTransferQuantity] = useState(plate?.quantity)
  const [deactivateBin, setDeactivateBin] = useState(true)
  const [tab, setTab] = useState(0)
  const [reasonType, setReasonType] = useState(REASON_TYPE.other)
  const [auditComment, setAuditComment] = useState('')

  useConditionalEffect(() => {
    if (plate?.item_location) {
      setBinLabel(plate.item_location.label)
      setExpirationDate(plate.expiration_date)
      setQuantity(plate.quantity)
      setTransferQuantity(plate.quantity)
    }
  }, [plate])

  const { updatePlate, transfer } = useAdminPlate(plate?.id)

  if (!locationId) {
    return null
  }

  const onChange = ({ target: { value } }) => {
    if (value === '') {
      setQuantity('')
    } else {
      const newVal = parseInt(value, 10)
      if (quantityValid(active, newVal)) {
        setQuantity(newVal)
      }
    }
  }

  const onChangeTransfer = ({ target: { value } }) => {
    if (value === '') {
      setTransferQuantity('')
    } else {
      const newVal = parseInt(value, 10)
      if (newVal <= plate?.quantity && newVal > 0) {
        setTransferQuantity(newVal)
      }
    }
  }

  const handleClose = () => {
    setActive(INVENTORY_UPDATE_MODES.SET_TO)
    setQuantityEntered(false)
    setSelectedBin('')
    setAuditComment('')
    setTab(0)
    onClose && onClose()
  }

  const handleBinSelected = bin => {
    setSelectedBin(bin)
    setTransferQuantity(plate.quantity)
    setDeactivateBin(true)
  }

  const toggleDeactivateBin = () => setDeactivateBin(d => !d)

  const handleUpdate = () => {
    const updates = {}
    if (expirationDate) {
      updates['expiration_date'] = expirationDate
    }
    let q = quantity
    if (active === INVENTORY_UPDATE_MODES.DECREASE_BY) {
      q = Math.max(plate.quantity - quantity, 0)
    } else if (active === INVENTORY_UPDATE_MODES.INCREASE_BY) {
      q = plate.quantity + quantity
    } else {
      q = quantity
    }
    updates['quantity'] = q
    if (Object.keys(updates).length > 0) {
      // currently the way we capture expired quantity
      // data science parses this out of the audit comment text
      updates['audit_comment'] =
        reasonType === REASON_TYPE.expired
          ? `expired_product: ${plate.quantity - q}`
          : auditComment
      updatePlate(updates)
    }
    handleClose()
  }

  const handleTransfer = () => {
    transfer({
      quantity: transferQuantity,
      transfer_plate: deactivateBin && transferQuantity === plate?.quantity,
      label: selectedBin,
      audit_comment: auditComment
    })
    handleClose()
  }

  const handleSetActive = mode => () => {
    setActive(mode)
    if (!quantityValid(mode, quantity)) {
      setQuantity('')
    }
  }

  const quantityValid = (mode, qty) => {
    if (typeof qty !== 'number') {
      return false
    }
    if (
      mode === INVENTORY_UPDATE_MODES.SET_TO ||
      mode === INVENTORY_UPDATE_MODES.INCREASE_BY
    ) {
      return qty >= 0
    }
    return qty >= 0 && qty <= maxDecrease
  }

  const maxDecrease = variants.reduce(
    (p, c) => Math.min(p, c.inventory_quantity),
    Number.MAX_SAFE_INTEGER
  )

  const updateAuditComment = ({ target: { value } }) => {
    setAuditComment(value)
  }

  const reasonTypeChange = evt => {
    const val = evt.target.value
    setReasonType(val)
    setAuditComment(val === REASON_TYPE.expired ? 'expired_product' : '')
  }

  return (
    <Dialog
      title={`${plate?.variant?.sku} at ${binLabel}`}
      open={open}
      onCancel={handleClose}
      onConfirm={tab === 0 ? handleUpdate : handleTransfer}
      confirmDisabled={!auditComment}
      dataTest="PlateModal"
    >
      <>
        <ProductSummary product={plate?.variant} showVariantTitle showSku />
        <Box mt={1}>
          <Link
            to={`${window.location.pathname}/${plate?.variant?.id}/history`}
          >
            History
          </Link>
        </Box>
        <Tabs
          onChange={setTab}
          panes={[
            {
              label: 'Details',
              pane: (
                <>
                  <Box mt={2} mb={2}>
                    <Typography variant="body2">Quantity</Typography>
                  </Box>
                  <Box mt={1}>
                    {Object.values(INVENTORY_UPDATE_MODES).map(mode => (
                      <Button
                        key={mode}
                        size={SIZE.small}
                        variant={
                          active === mode
                            ? BUTTON_VARIANT.contained
                            : BUTTON_VARIANT.outlined
                        }
                        color={
                          active === mode ? COLOR.primary : COLOR.secondary
                        }
                        onClick={handleSetActive(mode)}
                        className={classes.modeButton}
                        dataTest={`inventory-mode-button-${mode}`}
                        disabled={quantityEntered}
                      >
                        {mode}
                      </Button>
                    ))}
                    <TextField
                      name="quantity"
                      placeholder="Enter Quantity"
                      fullWidth
                      number
                      onChange={onChange}
                      value={quantity}
                      dataTest="quantity-input"
                    />
                  </Box>
                  <Box>
                    <Box mt={2} mb={2}>
                      <Typography variant="body2">Expiration Date</Typography>
                    </Box>
                    <DatePicker
                      name="expiration_date"
                      fullWidth={true}
                      value={expirationDate}
                      onChange={setExpirationDate}
                      dataTest="expiration-date"
                    />
                  </Box>
                  <Box>
                    <Box mt={2} mb={2}>
                      <Typography variant="body2">
                        Reason for Change (Required)
                      </Typography>
                    </Box>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Type</FormLabel>
                      <RadioGroup
                        aria-label="reason type"
                        name="reason-type"
                        value={reasonType}
                        onChange={reasonTypeChange}
                        style={{ flexDirection: 'row' }}
                      >
                        <FormControlLabel
                          value={REASON_TYPE.expired}
                          control={<Radio color="primary" />}
                          label="Expired"
                        />
                        <FormControlLabel
                          value={REASON_TYPE.other}
                          control={<Radio color="primary" />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box hidden={reasonType === REASON_TYPE.expired}>
                      <TextField
                        name="audit-comment"
                        fullWidth
                        onChange={updateAuditComment}
                        value={auditComment}
                        dataTest="audit-comment"
                      />
                    </Box>
                  </Box>
                </>
              )
            },
            {
              label: 'Transfer',
              pane: (
                <>
                  <Box>
                    <Box mt={2} mb={2}>
                      <Typography variant="body2">
                        From {binLabel} To: {selectedBin}
                      </Typography>
                    </Box>
                    {!selectedBin && (
                      <BinSelector
                        onScan={handleBinSelected}
                        currentBins={plate?.variant?.all_locations?.filter(
                          l => l !== plate.item_location.label
                        )}
                      />
                    )}
                    {selectedBin && (
                      <TextField
                        name="quantity-transfer"
                        label={`Quantity (of ${plate?.quantity})`}
                        placeholder="Enter Quantity"
                        fullWidth
                        number
                        onChange={onChangeTransfer}
                        value={transferQuantity}
                        dataTest="quantity-transfer-input"
                      />
                    )}
                  </Box>
                  {selectedBin && (
                    <Box mt={2} mb={2}>
                      <Checkbox
                        label={`Transfer expiration and other meta information? ${selectedBin} MUST be empty for this to work`}
                        checked={
                          deactivateBin && transferQuantity === plate?.quantity
                        }
                        onChange={toggleDeactivateBin}
                        disabled={transferQuantity !== plate?.quantity}
                      />
                    </Box>
                  )}
                  {selectedBin && (
                    <Box mt={2} mb={2}>
                      <TextField
                        name="audit-comment-transfer"
                        label="Reason for Change"
                        fullWidth
                        onChange={updateAuditComment}
                        value={auditComment}
                        dataTest="audit-comment-transfer"
                      />
                    </Box>
                  )}
                </>
              )
            }
          ]}
        />
      </>
    </Dialog>
  )
}

PlateModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  variants: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func
}

export default withStyles(styles)(PlateModal)
