import React from 'react'
import { useFormContext } from 'react-hook-form'

import { useBeforeUnload } from 'hooks'

/* Use this to intercept and alert the user to a form that has not
been saved. If the user tries to leave the page when the form is dirty
a browser alert will intercept the action and allow the user to
continue leaving or remain on the page.  */
const ConfirmLeaveWhenDirty = () => {
  const { formState } = useFormContext()
  const { isDirty, dirtyFields } = formState
  // Unsure why but sometimes isDirty will be true even though there are no dirty fields
  const { OnBeforeUnloadPrompt } = useBeforeUnload(
    isDirty && Object.keys(dirtyFields).length > 0
  )
  return <OnBeforeUnloadPrompt />
}

ConfirmLeaveWhenDirty.defaultProps = {}

ConfirmLeaveWhenDirty.propTypes = {}

export default ConfirmLeaveWhenDirty
