const jssStyles = theme => ({
  customerAddress: {
    color: theme.palette.grays.dark,
    fontWeight: 400,
    marginTop: 4
  },
  item: {
    marginBottom: theme.spacing(3)
  }
})

export default jssStyles
