import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Chip from '@material-ui/core/Chip'

import { ICON_BUTTON_VARIANT } from 'constants/enums'

import CloseIcon from 'icons/CloseIcon'
import IconButton from 'components/IconButton'

import styles from './TagStyles'

const Tag = ({ label, onDelete, isNew, classes, disabled }) => (
  <Chip
    label={label}
    onDelete={onDelete}
    className={classNames({ [classes.isNew]: isNew, [classes.tag]: true })}
    disabled={disabled}
    deleteIcon={
      <IconButton
        onClick={onDelete}
        circle
        size={16}
        variant={ICON_BUTTON_VARIANT.transparent}
      >
        <CloseIcon className={classNames({ [classes.icon]: isNew })} />
      </IconButton>
    }
  />
)
Tag.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  onDelete: PropTypes.func,
  isNew: PropTypes.bool
}

export default withStyles(styles)(Tag)
