import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, useTheme } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import GoogleMapReact from 'google-map-react'

import { GOOGLE_API_KEY } from 'lib/config'
import { getMapStyles } from 'constants/mapStyles'

import styles from './GoogleMapStyles'

const controls = {
  mapTypeControl: false,
  panControl: false,
  streetViewControl: false,
  zoomControl: false,
  scaleControl: false,
  disableDefaultUI: true,
  clickableIcons: false
}

const GoogleMap = ({ classes, lat, lng, zoom = 14, children }) => {
  const theme = useTheme()
  const mapStyles = getMapStyles(theme.palette.type)

  return (
    <Box className={classes.root}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        center={{ lat, lng }}
        zoom={zoom}
        options={{
          styles: mapStyles,
          ...controls
        }}
      >
        {children}
      </GoogleMapReact>
    </Box>
  )
}

GoogleMap.propTypes = {
  classes: PropTypes.object.isRequired,
  lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zoom: PropTypes.number,
  children: PropTypes.node
}

export default withStyles(styles)(GoogleMap)
