export default {
  root: {
    margin: '4px 4px 4px 0',
    height: 'auto'
  },
  label: {
    padding: '6px 12px',
    letterSpacing: '0,19px',
    fontSize: 10,
    lineHeight: '15px',
    fontWeight: 400
  }
}
