import { addOpacity } from 'utils/general'

const jssStyles = theme => ({
  statusCell: {
    width: 75
  },
  statusTag: {
    borderRadius: 5,
    width: 'fit-content',
    padding: theme.spacing(0.5, 1)
  },
  active: {
    backgroundColor: addOpacity(theme.palette.warnings.success, 0.1),
    color: theme.palette.warnings.success
  },
  inactive: {
    backgroundColor: addOpacity(theme.palette.warnings.danger, 0.1),
    color: theme.palette.warnings.danger
  },
  textField: {
    '& .MuiInputBase-root': {
      height: 44
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 12px 14px'
    }
  },
  subtitle: {
    color: theme.palette.grays.dark,
    fontSize: 10
  },
  imageDropzone: {
    height: 96,
    backgroundColor: 'transparent',
    zIndex: 999
  },
  imageDropzonePlaceholder: {
    backgroundColor: theme.palette.grays.ultralight,
    opacity: 0.6
  }
})

export default jssStyles
