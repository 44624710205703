const jssStyles = theme => ({
  root: {
    '&::before': {
      background: 'none'
    },
    '&.Mui-expanded': {
      margin: '0 0 16px 0'
    }
  },
  header: {
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 700
  },
  content: {},
  minimal: {
    border: 'none',
    background: theme.palette.background.default,
    boxShadow: 'none',

    '& .MuiAccordionSummary-root': {
      padding: '0 !important',
      justifyContent: 'space-between',

      '& .MuiAccordionSummary-content': {
        flexGrow: 0,
        margin: 0
      }
    }
  }
})
export default jssStyles
