const jssStyles = theme => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  medium: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  xlarge: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
})

export default jssStyles
