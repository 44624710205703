import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import SaveIcon from '@material-ui/icons/Save'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { useBeforeUnload } from 'hooks'

import { SIZE, COLOR } from 'constants/enums'

import Button from 'components/Button'

import { useControlledForm } from 'components/ControlledForm'

const SaveButton = ({ promoCodeId, submitting, formModified }) => {
  const {
    handleSubmit,
    formContext: {
      formState: { dirtyFields }
    }
  } = useControlledForm()
  const { OnBeforeUnloadPrompt } = useBeforeUnload(!isEmpty(dirtyFields))
  return (
    <>
      <Button
        label="save promo code button"
        onClick={handleSubmit}
        size={SIZE.medium}
        color={COLOR.primary}
        dataTest="promo-code-save-button"
        startIcon={promoCodeId ? <SaveIcon /> : <CheckCircleOutlineIcon />}
        adaptive
        disabled={(isEmpty(dirtyFields) || submitting) && !formModified}
        type="submit"
      >
        {promoCodeId ? 'Save' : 'Create'}
      </Button>
      <OnBeforeUnloadPrompt />
    </>
  )
}

SaveButton.propTypes = {
  promoCodeId: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  formModified: PropTypes.bool
}

export default SaveButton
