import { MINIMAL_LABEL } from 'constants/styles'

const jssStyles = theme => ({
  profile: {
    maxHeight: 420,
    position: 'relative',
    overflowY: 'scroll'
  },
  avatar: { margin: theme.spacing(2, 0) },
  tierBadgeButton: {
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  forwardArrow: {
    height: 12,
    color: theme.palette.grays.dark,
    marginLeft: theme.spacing(2)
  },
  stat: {
    margin: theme.spacing(1, 0),
    '& .MuiTypography-caption': {
      ...MINIMAL_LABEL(theme)
    },
    '& .MuiTypography-body2': {
      fontWeight: 400
    }
  },
  memosTitle: {
    marginBottom: theme.spacing(1)
  },
  largeTile: { border: 'none', width: '100%' },
  orderStatsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',

    '& > *': {
      margin: theme.spacing(0, 1.5),
      flexGrow: 1,

      '&:first-of-type': {
        marginLeft: 0
      },
      '&:last-of-type': {
        marginRight: 0
      }
    }
  },
  dialogContent: {
    padding: 0
  },
  benefitsList: {
    padding: 0,
    '& li:nth-child(even)': { background: theme.palette.grays.ultralight },
    marginBottom: theme.spacing(8)
  },
  benefitRow: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: theme.spacing(4)
  },
  benefitLabel: {
    color: theme.palette.grays.dark
  },
  benefitValue: {
    color: 'black'
  }
})
export default jssStyles
