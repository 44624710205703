const jssStyles = theme => ({
  gmv: {
    lineHeight: '32px',
    fontWeight: '600',
    letterSpacing: '-0.7px'
  },
  overline: {
    color: theme.palette.colorsAF.lightGray
  }
})

export default jssStyles
