import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import { PROMO_CODE_FIELDS } from 'constants/promoCodes'
import { useMediaQuery } from 'hooks'

import { TextFieldControlled } from 'components/TextField'

const BulkCodeInput = ({ classes }) => {
  const { isDesktopScreen } = useMediaQuery()
  return (
    <Box
      display="flex"
      flexDirection={isDesktopScreen ? 'column' : 'row'}
      justifyContent="space-between"
      width="100%"
    >
      <Box width="100%">
        <Typography variant="caption">Assign Prefix</Typography>
        <TextFieldControlled
          placeholder="eg: FASTAF"
          name={PROMO_CODE_FIELDS.prefix}
          className={classes.controlContainer}
        />
      </Box>
      <Box
        width="100%"
        mt={isDesktopScreen ? 1 : 0}
        ml={isDesktopScreen ? 0 : 1}
      >
        <Typography variant="caption">Assign Prefix</Typography>
        <TextFieldControlled
          startAdornment="Qty"
          number
          name={PROMO_CODE_FIELDS.quantity}
          className={classes.controlContainer}
        />
      </Box>
    </Box>
  )
}

BulkCodeInput.propTypes = {
  classes: PropTypes.object.isRequired
}

export default BulkCodeInput
