import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { STATUS_DOT_VARIANTS } from 'constants/enums'
import { URL } from 'constants/navigation'
import { useLocationsContext } from 'context'
import { useNavigation } from 'hooks'
import { stockSummary } from 'utils/products'
import { isMiddleButtonClicked } from 'utils/general'

import Checkbox from 'components/Checkbox'
import ProductSummary from 'components/ProductSummary'
import StatusDot from 'components/StatusDot'

import LockedIcon from 'icons/LockedIcon'
import UnlockedIcon from 'icons/UnlockedIcon'

import styles from './ProductItemStyles'

const ProductItem = ({ classes, product, handleSelect, selected }) => {
  const { locationId, locations, showAllLocationIds } = useLocationsContext()
  const locationsCount = locations?.length

  const { handleShowClick } = useNavigation({
    url: URL.ADMIN_PRODUCTS
  })

  const handleClick = useCallback(
    e => {
      handleShowClick(e, product, isMiddleButtonClicked(e))
    },
    [product]
  )

  const stockData = stockSummary(product, locationId)

  const getProductStatus = () => {
    if (showAllLocationIds) {
      const publishedCount = product.location_ids.length

      // eslint-disable-next-line no-nested-ternary
      return !publishedCount ? (
        <StatusDot variant={STATUS_DOT_VARIANTS.gray} text="Unpublished" />
      ) : publishedCount === locationsCount ? (
        <StatusDot variant={STATUS_DOT_VARIANTS.green} text="Published" />
      ) : (
        <StatusDot
          variant={STATUS_DOT_VARIANTS.green}
          halfFilled
          text={`Published at ${publishedCount} / ${locationsCount} locations`}
        />
      )
    }

    if (!product.location_ids.includes(locationId)) {
      return <StatusDot variant={STATUS_DOT_VARIANTS.gray} text="Unpublished" />
    }

    return stockData.inStock ? (
      <StatusDot variant={STATUS_DOT_VARIANTS.green} text="Published" />
    ) : (
      <StatusDot variant={STATUS_DOT_VARIANTS.red} text="Sold Out" />
    )
  }

  return (
    <TableRow>
      <TableCell className={classes.productCell}>
        <Checkbox
          onChange={state => handleSelect(product, state)}
          checked={selected}
        />
        <ProductSummary product={product} onClick={handleClick} />
      </TableCell>
      <TableCell>
        {stockData.quantity} in stock for {stockData.inStock} /{' '}
        {product.variants.length} variants
      </TableCell>
      <TableCell>{getProductStatus()}</TableCell>
      <TableCell>
        {product.locked ? <LockedIcon /> : <UnlockedIcon />}
      </TableCell>
    </TableRow>
  )
}

ProductItem.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  handleSelect: PropTypes.func,
  selected: PropTypes.bool
}

export default withStyles(styles)(ProductItem)
