import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { renderPaymentMethod } from 'utils/orders'
import { renderAddress, formatDateToLocalTimezone } from 'utils/general'
import { DELIVERY_METHOD_KEYS, DELIVERY_METHOD_LABELS } from 'constants/enums'

import styles from './ShippingDetailsStyles'

const ShippingDetails = ({ classes, order }) => {
  const deliveryWindow = () => {
    const deliveryStartsAtText =
      order.delivery_starts_at &&
      formatDateToLocalTimezone(order.delivery_starts_at, order.timezone, 'h A')
    const deliveryEndsAtText =
      order.delivery_ends_at &&
      formatDateToLocalTimezone(order.delivery_ends_at, order.timezone, 'h A')
    const deliveryMessage =
      deliveryStartsAtText && deliveryEndsAtText
        ? `${deliveryStartsAtText} - ${deliveryEndsAtText}`
        : ''
    return deliveryMessage
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Typography className={classes.caption} variant="caption">
          Order Date
        </Typography>
        <Typography variant="body1">
          {order?.created_at && moment(order?.created_at).format('MMM D, YYYY')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Typography className={classes.caption} variant="caption">
          Delivery Date
        </Typography>
        <Typography variant="body1">
          {order?.created_at &&
            moment(order?.delivery_starts_at).format('MMM D, YYYY')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Typography className={classes.caption} variant="caption">
          Payment Method
        </Typography>
        <Typography variant="body1">{renderPaymentMethod(order)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Typography className={classes.caption} variant="caption">
          Delivery Window
        </Typography>
        <Typography variant="body1">{deliveryWindow()}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Typography className={classes.caption} variant="caption">
          Delivery Address
        </Typography>
        <Typography variant="body1">{renderAddress(order)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Typography className={classes.caption} variant="caption">
          First Order
        </Typography>
        <Typography variant="body1">
          {order?.user_first_order ? 'Yes' : 'No'}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.item}>
        <Typography className={classes.caption} variant="caption">
          Delivery Method
        </Typography>
        <Typography variant="body1">
          {DELIVERY_METHOD_LABELS[order?.delivery_method]}
          {order?.delivery_method === DELIVERY_METHOD_KEYS.onfleet &&
            order?.delivery_id && (
              <>
                {' '}
                <span variant="body1">
                  <a
                    href={`https://onfleet.com/dashboard#/manage?taskEdit=false&open=task&taskId=${order?.delivery_id}`}
                    target="_blank"
                  >
                    Details
                  </a>
                </span>
              </>
            )}
        </Typography>
      </Grid>
    </Grid>
  )
}
ShippingDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object
}

export default withStyles(styles)(ShippingDetails)
