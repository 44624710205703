import * as yup from 'yup'
import { centsToDollars, dollarsToCents } from 'utils/general'
import { toTitleCase } from 'utils/strings'

// SUPPLIERS

export const SUPPLIER_FIELDS = {
  name: 'name',
  point_of_contact: 'point_of_contact',
  payment_terms: 'payment_terms',
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  country: 'country',
  country_code: 'country_code',
  phone: 'phone',
  state: 'state',
  zipcode: 'zipcode'
}

export const SUPPLIER_FORM_DEFAULTS = {
  [SUPPLIER_FIELDS.name]: '',
  [SUPPLIER_FIELDS.payment_terms]: '',
  [SUPPLIER_FIELDS.address1]: '',
  [SUPPLIER_FIELDS.address2]: '',
  [SUPPLIER_FIELDS.city]: '',
  [SUPPLIER_FIELDS.state]: '',
  [SUPPLIER_FIELDS.zipcode]: '',
  [SUPPLIER_FIELDS.country]: 'United States of America',
  [SUPPLIER_FIELDS.point_of_contact]: '',
  [SUPPLIER_FIELDS.country_code]: '1',
  [SUPPLIER_FIELDS.phone]: ''
}

export const SUPPLIER_SCHEMA = {
  [SUPPLIER_FIELDS.name]: yup.string().required(),
  [SUPPLIER_FIELDS.point_of_contact]: yup.string().nullable(),
  [SUPPLIER_FIELDS.payment_terms]: yup.string().nullable(),
  [SUPPLIER_FIELDS.address1]: yup.string().nullable(),
  [SUPPLIER_FIELDS.address2]: yup.string().nullable(),
  [SUPPLIER_FIELDS.city]: yup.string().nullable(),
  [SUPPLIER_FIELDS.country]: yup.string().nullable(),
  [SUPPLIER_FIELDS.country_code]: yup.string().nullable(),
  [SUPPLIER_FIELDS.phone]: yup.string().nullable(),
  [SUPPLIER_FIELDS.state]: yup.string().nullable(),
  [SUPPLIER_FIELDS.zipcode]: yup.string().nullable()
}

export const SUPPLIER_PAYMENT_TERMS = {
  consignment: 'consignment',
  wholesale_cc_pre_pay: 'wholesale_cc_pre_pay',
  wholesale_net_15: 'wholesale_net_15',
  wholesale_net_30: 'wholesale_net_30',
  wholesale_net_45: 'wholesale_net_45',
  wholesale_net_60: 'wholesale_net_60',
  wholesale_net_90: 'wholesale_net_90',
  retail: 'retail'
}

export const SUPPLIER_PAYMENT_TERMS_OPTIONS = Object.keys(
  SUPPLIER_PAYMENT_TERMS
).map(value => ({
  value,
  label: toTitleCase(value)
}))

// DISTRIBUTION CENTERS

export const DISTRIBUTION_CENTER_FIELDS = {
  warehouses: 'warehouses',
  minimum_order_value: 'minimum_order_value'
}

export const DISTRIBUTION_CENTER_FORM_DEFAULTS = {
  [DISTRIBUTION_CENTER_FIELDS.warehouses]: null,
  [DISTRIBUTION_CENTER_FIELDS.minimum_order_value]: 0
}

export const DISTRIBUTION_CENTER_SCHEMA = {
  [DISTRIBUTION_CENTER_FIELDS.warehouses]: yup
    .object()
    .required('Please... select at least one of these.'),
  [DISTRIBUTION_CENTER_FIELDS.minimum_order_value]: yup
    .number()
    .min(0)
    .nullable()
}

export const DISTRIBUTION_CENTER_VARIANT_FIELDS = {
  minimum_order_quantity: 'minimum_order_quantity',
  minimum_case_pack_quantity: 'minimum_case_pack_quantity',
  minimum_order_value: 'minimum_order_value',
  case_cost: 'case_cost',
  case_length: 'case_length',
  case_width: 'case_width',
  case_height: 'case_height',
  case_pack_quantity: 'case_pack_quantity',
  case_upc: 'case_upc',
  case_weight: 'case_weight',
  lead_time: 'lead_time',
  shelf_life_guarantee_at_delivery: 'shelf_life_guarantee_at_delivery',
  total_shelf_life: 'total_shelf_life',
  unit_cost: 'unit_cost'
}

export const DISTRIBUTION_CENTER_VARIANT_FIELD_SCHEMAS = {
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_order_quantity]: yup
    .number()
    .min(0)
    .nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_case_pack_quantity]: yup
    .number()
    .min(0)
    .nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_order_value]: yup
    .number()
    .min(0)
    .nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.case_cost]: yup
    .number()
    .min(0)
    .nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.case_length]: yup.number().nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.case_width]: yup.number().nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.case_height]: yup.number().nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.case_weight]: yup.number().nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.case_pack_quantity]: yup
    .number()
    .min(0)
    .nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.case_upc]: yup.string().nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.lead_time]: yup.number().nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_order_quantity]: yup
    .number()
    .min(0)
    .nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.shelf_life_guarantee_at_delivery]: yup
    .number()
    .nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.total_shelf_life]: yup
    .string()
    .nullable(),
  [DISTRIBUTION_CENTER_VARIANT_FIELDS.unit_cost]: yup
    .number()
    .min(0)
    .nullable()
}

export const getVariantFieldServerConversion = (fieldName, value) => {
  if (
    [
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_cost,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.unit_cost,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_order_value
    ].includes(fieldName)
  ) {
    return dollarsToCents(value) ?? 0
  } else if (
    [
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_pack_quantity,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.lead_time,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_order_quantity,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_order_value,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.shelf_life_guarantee_at_delivery,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.total_shelf_life,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_case_pack_quantity,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_length,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_width,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_height,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_weight
    ].includes(fieldName)
  ) {
    return value ?? 0
  } else {
    return value ?? ''
  }
}

export const getVariantFieldFrontendConversion = (fieldName, value) => {
  if (
    [
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_cost,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.unit_cost,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_order_value
    ].includes(fieldName)
  ) {
    return centsToDollars(value) ?? 0
  } else if (
    [
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_pack_quantity,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.lead_time,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_order_quantity,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.shelf_life_guarantee_at_delivery,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.total_shelf_life,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.minimum_case_pack_quantity,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_length,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_width,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_height,
      DISTRIBUTION_CENTER_VARIANT_FIELDS.case_weight
    ].includes(fieldName)
  ) {
    return value ?? 0
  } else {
    return value ?? ''
  }
}
