import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useAuthContext } from 'context'
import { Route } from 'react-router'
import { useHistory } from 'react-router-dom'

import { URL } from 'constants/navigation'

const defaultPolicy = user => true

const AuthenticatedRoute = ({ component, policy = defaultPolicy, ...rest }) => {
  const history = useHistory()
  const { currentUser, isInitialized } = useAuthContext()

  useEffect(() => {
    if (
      (isInitialized && currentUser == null) ||
      (isInitialized && !policy(currentUser))
    ) {
      console.info('User not authorized')
      history.push(URL.LOGIN)
    }
  }, [currentUser, isInitialized])

  return currentUser != null && policy(currentUser) ? (
    <Route {...rest} component={component} />
  ) : (
    <></>
  )
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

export default AuthenticatedRoute
