import React from 'react'
import PropTypes from 'prop-types'

const BadgeIcon = ({ color, size, className, outlined }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.94198 1.18715C8.527 0.604283 9.47182 0.604283 10.0568 1.18715L11.5942 2.69046H13.7691C14.5974 2.69046 15.269 3.36351 15.269 4.19377V6.38108L16.8063 7.92197H16.8138C17.3954 8.50832 17.3954 9.45529 16.8138 10.0416L15.2765 11.5825V13.7623C15.2765 14.5926 14.6049 15.2656 13.7766 15.2656H11.5942L10.0568 16.814C9.77561 17.0936 9.39549 17.2503 8.99941 17.25C8.60333 17.2503 8.2232 17.0936 7.94198 16.814L6.40459 15.2731H4.22975C3.40138 15.2731 2.72986 14.6001 2.72986 13.7698V11.5825L1.18497 10.0416C0.906049 9.75978 0.749685 9.37879 0.75 8.9818C0.759564 8.57937 0.929756 8.1976 1.22247 7.92197L2.72236 6.38108V4.20128C2.72236 3.37103 3.39388 2.69798 4.22225 2.69798H6.40459L7.94198 1.18715Z"
      fill={color}
      stroke={outlined && '#111'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.63019 11.0625H7.82401C7.73931 11.0625 7.66784 10.9967 7.65631 10.9088L7.57442 10.2727H6.27724L5.96969 10.9604C5.94183 11.0224 5.88246 11.0622 5.8169 11.0622H5.04393C4.91872 11.0622 4.83712 10.9241 4.89283 10.8064L6.67709 7.03574C6.70551 6.97556 6.76404 6.9375 6.82819 6.9375H8.04012C8.12285 6.9375 8.19319 7.00034 8.20671 7.0859L8.79675 10.8569C8.81363 10.9646 8.7343 11.0625 8.63019 11.0625ZM7.26124 8.07334L6.65233 9.43487H7.46693L7.29191 8.07334H7.26124ZM9.79748 7.0856L9.2004 10.8563C9.18352 10.9643 9.26284 11.0625 9.36752 11.0619H10.0932C10.1759 11.0619 10.2463 10.9994 10.2598 10.9138L10.49 9.4508H12.445C12.5275 9.4508 12.5978 9.38855 12.6116 9.30329L12.7003 8.75513C12.7177 8.64716 12.6381 8.54862 12.5337 8.54862H10.6357L10.7432 7.83968H12.8674C12.9498 7.83968 13.0202 7.77743 13.034 7.69217L13.1226 7.14402C13.14 7.03604 13.0604 6.9375 12.956 6.9375H9.96406C9.88134 6.9375 9.81099 7.00034 9.79748 7.0856Z"
      fill={outlined ? '#111' : 'white'}
    />
  </svg>
)

BadgeIcon.defaultProps = {
  size: 18
}

BadgeIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
  outlined: PropTypes.bool
}

export default BadgeIcon
