import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { useMenu } from 'hooks'

import styles from './StatusCodesStyles'

const StatusCodesItem = ({ classes, statusCode, onSetActive, onClickEdit }) => {
  const { open, anchorEl, handleToggleMenu, handleClose } = useMenu()

  return (
    <TableRow>
      <TableCell>{statusCode.name}</TableCell>
      <TableCell>{statusCode.products_count} Products</TableCell>
      <TableCell>{moment(statusCode.created_at).format('L')}</TableCell>
      <TableCell className={classes.statusCell}>
        <Box
          className={classNames({
            [classes.statusTag]: true,
            [classes.active]: statusCode.active,
            [classes.inactive]: !statusCode.active
          })}
        >
          {statusCode.active ? 'Active' : 'Inactive'}
        </Box>
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={e => handleToggleMenu(e)}>
          <MoreVertIcon />
          <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
            {statusCode.active ? (
              <MenuItem onClick={() => onSetActive(false)}>Inactive</MenuItem>
            ) : (
              <MenuItem onClick={() => onSetActive(true)}>Active</MenuItem>
            )}
            <MenuItem onClick={() => onClickEdit(statusCode)}>Edit</MenuItem>
          </Menu>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

StatusCodesItem.propTypes = {
  classes: PropTypes.object.isRequired,
  statusCode: PropTypes.object,
  onSetActive: PropTypes.func,
  onClickEdit: PropTypes.func
}

export default withStyles(styles)(StatusCodesItem)
