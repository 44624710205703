import { api } from 'api'
import {
  useAlerts,
  useResourceList,
  useResourceRQ,
  useResourceListRQ,
  useLoaders
} from 'hooks'

import {
  fileInputProcessor,
  multiPartFormParams
} from 'hooks/common/useResourceRQ'

import {
  variantEndpoint,
  variantCsvImportEndpoint,
  variantListEndpoint,
  variantBulkUpdateEndpoint,
  purchaseOrderEndpoint,
  variantLocationsBulkUpdateEndpoint
} from 'api/endpoints'

import {
  LIST_PATHS,
  LOW_STOCK_INVENTORY,
  HIGH_STOCK_INVENTORY,
  SOLD_OUT_INVENTORY
} from 'constants/queryParams'

const API_VARIANTS = '/api/v1/admin/variants'

const useAdminVariantsRQ = ({
  params,
  onImportCSVSuccess,
  onBulkUpdateVariantLocationsSuccess,
  fetchingEnabled = true
}) => {
  const { showAlertError, showAlertSuccess } = useAlerts()
  const { showLoading, hideLoading } = useLoaders()

  const {
    isFetching,
    isLoading,
    data: variants,
    hasData: hasVariants,
    hasNextPage: hasVariantsNext,
    fetchNextPage: fetchVariantsNext,
    meta: variantsMeta,
    refetch
  } = useResourceListRQ({
    endpoint: variantListEndpoint,
    params,
    fetchingEnabled: fetchingEnabled && Boolean(params)
  })

  const { operate: importCSV } = useResourceRQ({
    endpoint: variantCsvImportEndpoint,
    fetchingEnabled: false,
    inputProcessor: fileInputProcessor('variant[csv]'),
    params: multiPartFormParams,
    /* resetQueries: [
     *   () => [
     *     variantPurchaseOrderListEndpoint(),
     *     { purchase_order_id: purchaseOrderId }
     *   ]
     * ], */
    onSuccess: onImportCSVSuccess
  })

  const { operate: bulkUpdateVariantLocations } = useResourceRQ({
    endpoint: variantLocationsBulkUpdateEndpoint,
    fetchingEnabled: false,
    inputProcessor: fileInputProcessor('csv_file'),
    params: multiPartFormParams,
    onSuccess: onBulkUpdateVariantLocationsSuccess
  })

  const { list: getHighStockCount, count: highStockCount } = useResourceList({
    url: API_VARIANTS,
    perPage: 1
  })

  const { list: getLowStockCount, count: lowStockCount } = useResourceList({
    url: API_VARIANTS,
    perPage: 1
  })

  const fetchVariantsAndSummary = params => {
    const locationParam =
      LIST_PATHS.VARIANTS_LOCATIONS_ID in params
        ? {
            [LIST_PATHS.VARIANTS_LOCATIONS_ID]:
              params[LIST_PATHS.VARIANTS_LOCATIONS_ID]
          }
        : {}
    getHighStockCount({ ...HIGH_STOCK_INVENTORY, ...locationParam })
    getLowStockCount({ ...LOW_STOCK_INVENTORY, ...locationParam })
  }

  const { count: soldOutVariantsCount } = useResourceListRQ({
    endpoint: () => API_VARIANTS,
    params: SOLD_OUT_INVENTORY
  })

  const { operate: bulkUpdateVariants } = useResourceRQ({
    endpoint: variantBulkUpdateEndpoint,
    args: [],
    name: 'variants',
    dependents: [variantEndpoint, purchaseOrderEndpoint],
    fetchingEnabled: false,
    inputProcessor: ({
      variants: variantsToUpdate,
      weight,
      length,
      width,
      height,
      unit,
      appendBin = false,
      setBin = false
    }) => {
      const data = {
        unit,
        length,
        width,
        height,
        weight
      }

      Object.keys(data).forEach(k => {
        if (data[k] === null || data[k] === undefined) {
          delete data[k]
        }
      })

      if (!data.bin) {
        delete data.location_id
      }

      const updateList = variantsToUpdate.map(v => ({
        id: v.id,
        ...data
      }))

      return { append_bin: appendBin, variants: updateList }
    }
  })

  return {
    isFetching,
    isLoading,
    variants: variants || [],
    lowStockCount,
    highStockCount,
    hasVariants,
    hasVariantsNext,
    fetchVariantsNext,
    fetchVariantsAndSummary,
    soldOutVariantsCount,
    bulkUpdateVariants,
    bulkUpdateVariantLocations,
    variantsMeta,
    importCSV
  }
}

export default useAdminVariantsRQ
