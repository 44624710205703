import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Popper from '@material-ui/core/Popper'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'

import { getDOMElementsPathToRoot } from 'utils/general'
import { MARGINS } from 'constants/enums'
import useEventListener from 'hooks/common/useEventListener'
import Autocomplete from 'components/Autocomplete'
import TextField from 'components/TextField'
import CheckIcon from 'icons/CheckIcon'
import SearchIcon from 'icons/SearchIcon'
import ChevronDownIcon from 'icons/ChevronDownIcon'

const AutocompleteSelect = ({
  classes,
  value,
  options,
  className,
  placeholder,
  onChange,
  loading,
  multiple,
  getOptionLabel,
  getTagLabel,
  groupBy,
  limitTags,
  actions,
  disabled,
  helperText
}) => {
  const [open, setOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const autocompleteRef = useRef(null)
  const popperRef = useRef(null)

  const close = () => {
    setOpen(false)
    setSearchQuery('')
  }

  // For detecting when to open and close the autocomplete.
  useEventListener(document, 'mouseup', e => {
    const path = e.path ?? getDOMElementsPathToRoot(e.target)
    if (
      path.some(
        el => el === autocompleteRef.current || el === popperRef.current
      )
    ) {
      !disabled && setOpen(true)
      return
    }
    open && close()
  })

  const handleChange = (e, option) => {
    onChange && onChange(option)
    !multiple && close()
  }

  const filterOptions = opts => {
    if (!searchQuery) {
      return opts
    }
    try {
      const regex = new RegExp(searchQuery, 'i')
      return opts.filter(opt => getOptionLabel(opt).search(regex) > -1)
    } catch (e) {
      // Catch Invalid regular expression
      console.warn('Searching options failed', e.message)
    }
    return []
  }

  const getInputLabel = getTagLabel ?? getOptionLabel
  const createTagString = values => {
    if (limitTags <= 0) {
      return `${values.length} item(s)`
    }
    const limitValues = limitTags ? values.slice(0, limitTags) : values
    const stringValues = limitValues.map(getInputLabel).join(', ')
    const otherCount = values.length - limitTags
    if (otherCount > 0) {
      return `${stringValues} +${otherCount} other${otherCount > 1 ? 's' : ''}`
    }
    return stringValues
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        value={value}
        options={options}
        open={open}
        groupBy={groupBy}
        autocompleteRef={autocompleteRef}
        className={className}
        fullWidth
        getOptionLabel={getOptionLabel}
        getOptionSelected={(opt, val) => opt.id === val.id}
        loading={loading}
        multiple={multiple}
        onChange={handleChange}
        disableClearable
        disableCloseOnSelect
        filterOptions={filterOptions}
        renderOption={(option, { selected }) => (
          <>
            <Box flex={1}>{getOptionLabel(option)}</Box>
            {selected ? <CheckIcon fontSize={20} /> : null}
          </>
        )}
        renderTags={values => (
          <Box mx="12px">
            <Typography variant="body1">{createTagString(values)}</Typography>
          </Box>
        )}
        renderInput={({ InputProps, ...params }) => (
          <TextField
            {...params}
            placeholder={value && value.length ? '' : placeholder}
            className={classes.inputBox}
            margin={MARGINS.dense}
            InputProps={{
              ...InputProps,
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <ChevronDownIcon className={classes.chevronDown} />
                </InputAdornment>
              )
            }}
          />
        )}
        PopperComponent={({ children, ...props }) => (
          <Popper {...props} placement="bottom">
            <Box className={classes.popperContainer} ref={popperRef}>
              <Box>
                <TextField
                  name="search"
                  fullWidth
                  autoFocus
                  margin="dense"
                  placeholder={placeholder}
                  onChange={({ target: { value: v } }) => setSearchQuery(v)}
                  value={searchQuery}
                  className={classes.searchBox}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    inputProps: {
                      autoComplete: 'off'
                    }
                  }}
                />
              </Box>
              {children}
              {!isEmpty(actions) && (
                <Box className={classes.actions}>
                  {actions.map((item, i) => (
                    <React.Fragment key={i}>{item}</React.Fragment>
                  ))}
                </Box>
              )}
            </Box>
          </Popper>
        )}
      />
      {helperText && (
        <FormHelperText className={classes.errorText}>
          {helperText}
        </FormHelperText>
      )}
    </>
  )
}

AutocompleteSelect.defaultProps = {
  groupBy: () => 'Results',
  limitTags: 3,
  actions: [],
  getOptionLabel: i => i,
  disabled: false
}

AutocompleteSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ]),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  groupBy: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getTagLabel: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  limitTags: PropTypes.number,
  actions: PropTypes.arrayOf(PropTypes.node),
  disabled: PropTypes.bool
}

export default withStyles(theme => ({
  errorText: {
    color: 'orange',
    marginTop: theme.spacing(1)
  },
  searchBox: {
    padding: theme.spacing(0, 2),
    margin: theme.spacing(1, 0, 0.5)
  },
  actions: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderTop: `1px solid ${theme.palette.grays.med}`
  },
  inputBox: {
    margin: 0,
    height: 40,
    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(1)}px !important`
    }
  },
  chevronDown: {
    fill: theme.palette.grays.dark
  }
}))(AutocompleteSelect)
