const jssStyles = theme => ({
  product: {
    marginBottom: theme.spacing(2)
  },
  smallInput: {
    height: 25,
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.5, 1.5, 1.5, 1)
    },
    '&:not(last-of-type)': {
      marginRight: theme.spacing(2)
    }
  },
  '& .MuiFormControl-root': {
    width: 180,
    marginBottom: theme.spacing(1),

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    }
  }
})
export default jssStyles
