import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { Controller, useFormContext } from 'react-hook-form'

import { CHECKBOX_VARIANT } from 'constants/enums'

import FormControlLabel from '@material-ui/core/FormControlLabel'

import Checkbox from 'components/Checkbox'

import styles from './CheckboxBlockStyles'

const CheckboxBlockRaw = ({
  className,
  classes,
  value,
  onChange,
  label,
  error,
  inputRef
}) => (
  <FormControlLabel
    inputRef={inputRef}
    className={classNames({
      [className]: !!className,
      [classes.root]: true,
      [classes.error]: error
    })}
    label={label}
    control={
      <Checkbox
        onChange={onChange}
        checked={Boolean(value)}
        variant={CHECKBOX_VARIANT.default}
      />
    }
  />
)

CheckboxBlockRaw.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

const CheckboxBlock = withStyles(styles)(CheckboxBlockRaw)

const CheckboxBlockControlled = ({ name, ...rest }) => {
  const { errors } = useFormContext()
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <CheckboxBlock
          name={name}
          error={Boolean(errors[name])}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={val => methods.onChange(val)}
        />
      )}
    />
  )
}

CheckboxBlockControlled.propTypes = {
  name: PropTypes.string.isRequired
}

export { CheckboxBlock as default, CheckboxBlockControlled }
