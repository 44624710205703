const jssStyles = theme => ({
  mapPlaceholder: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grays.light,
    color: theme.palette.grays.dark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default jssStyles
