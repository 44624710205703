import React from 'react'
import PropTypes from 'prop-types'

const AddIcon = ({ fill, fontSize, className }) => (
  <svg
    width={fontSize}
    height={fontSize}
    viewBox="0 0 10 10"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99967 0.333313C4.63148 0.333313 4.33301 0.63179 4.33301 0.999979V4.33331H0.999674C0.631485 4.33331 0.333008 4.63179 0.333008 4.99998C0.333008 5.36817 0.631484 5.66665 0.999674 5.66665H4.33301V8.99998C4.33301 9.36817 4.63148 9.66665 4.99967 9.66665C5.36786 9.66665 5.66634 9.36817 5.66634 8.99998V5.66665H8.99967C9.36786 5.66665 9.66634 5.36817 9.66634 4.99998C9.66634 4.63179 9.36787 4.33331 8.99968 4.33331H5.66634V0.99998C5.66634 0.63179 5.36786 0.333313 4.99967 0.333313Z"
      fill={fill}
    />
  </svg>
)

AddIcon.defaultProps = {
  fontSize: 10,
  fill: 'currentColor'
}

AddIcon.propTypes = {
  fill: PropTypes.string,
  fontSize: PropTypes.number,
  className: PropTypes.string
}

export default AddIcon
