import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import { useMediaQuery } from 'hooks'

import { Box, Typography } from '@material-ui/core'

import styles from './FilterBarStyles'

const FilterBar = ({ classes, summarizers, filters, iLoveTightBottoms }) => {
  const { isDesktopLargeScreen: isDesktopLarge } = useMediaQuery()
  if (!filters && !summarizers) return null

  return (
    <Box
      className={classNames({
        [classes.root]: true,
        [classes.iLoveTightBottoms]: !!iLoveTightBottoms
      })}
    >
      {summarizers && <Box className={classes.summaries}>{summarizers}</Box>}
      {filters && (
        <Box className={classes.filters}>
          {isDesktopLarge && (
            <Typography className={classes.filterText} variant="body1">
              Filter
            </Typography>
          )}
          {filters}
        </Box>
      )}
    </Box>
  )
}

FilterBar.defaultProps = {}

FilterBar.propTypes = {
  classes: PropTypes.object.isRequired,
  summarizers: PropTypes.node,
  filters: PropTypes.node
}

export default withStyles(styles)(FilterBar)
