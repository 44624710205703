import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import styles from './MemoListStyles'

const MemoList = ({ classes, className, memos = [] }) => (
  <Box
    className={classNames({
      [className]: Boolean(className)
    })}
    my={3}
  >
    {memos?.map(
      ({
        id,
        body,
        updated_at: updatedAt,
        subject,
        author = 'FastAF Admin'
      }) => (
        <Box className={classes.memo} key={id} data-test="memo-item">
          {subject && <Typography variant="body1">{subject}</Typography>}
          <Typography variant="body2">{body}</Typography>
          <Typography variant="caption">
            {author}, {moment(updatedAt).calendar()}
          </Typography>
        </Box>
      )
    )}
  </Box>
)

MemoList.propTypes = {
  classes: PropTypes.object.isRequired,
  memos: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string
}

export default withStyles(styles)(MemoList)
