import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import { Controller, useFormContext } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Skeleton from '@material-ui/lab/Skeleton'

import { MARGINS, SIZE } from 'constants/enums'
import { syntheticEvent } from 'utils/general'

import styles from './TextFieldStyles'

const TextFieldRaw = ({
  classes,
  name,
  margin,
  fullWidth,
  value,
  placeholder,
  innerLabel,
  label,
  disabled,
  error,
  helperText,
  inputProps,
  InputProps,
  password,
  number,
  onChange,
  onKeyPress,
  inputRef,
  autoFocus,
  multiline,
  rowsMax,
  className,
  size,
  dataTest,
  endAdornment,
  startAdornment,
  onFocus,
  onBlur,
  centeredInput,
  ref,
  noMarginBottom,
  min
}) => {
  const localOnChange = event => {
    const val = event.target.value
    onChange && onChange(syntheticEvent(val, name))
  }

  const hasInnerLabel = Boolean(innerLabel)
  const hasLabel = Boolean(label)

  const labelClassNames = classNames({
    [classes.label]: true,
    [classes.labelError]: error,
    [classes.disabled]: disabled
  })

  const formControlClassNames = classNames({
    [classes.formControl]: true,
    [classes.disabled]: disabled,
    [className]: Boolean(className),
    [classes.formControlNoMarginBottom]: Boolean(noMarginBottom)
  })

  const inputClasses =
    innerLabel || !centeredInput
      ? {
          root: classes.input,
          multiline: classes.multiline
        }
      : {
          root: classes.input,
          input: classes.centeredInput,
          inputMarginDense: classes.centeredInputDense,
          multiline: classes.multiline
        }

  const getInputType = () => {
    if (password) return 'password'
    if (number) return 'number'
    return 'text'
  }

  return (
    <>
      {hasLabel ? (
        <Typography variant="caption" className={labelClassNames}>
          {label}
        </Typography>
      ) : null}
      <FormControl
        margin={margin}
        variant="outlined"
        className={formControlClassNames}
        fullWidth={fullWidth}
        disabled={disabled}
        error={error}
        ref={ref}
        size={size}
      >
        {hasInnerLabel ? <InputLabel>{innerLabel}</InputLabel> : null}
        <OutlinedInput
          name={name}
          classes={inputClasses}
          onKeyPress={onKeyPress}
          type={getInputType()}
          placeholder={placeholder}
          value={value}
          onChange={localOnChange}
          inputRef={inputRef}
          multiline={multiline}
          rowsMax={rowsMax}
          autoFocus={autoFocus}
          inputProps={{
            'data-test': dataTest ? dataTest : name && `${name}-input`,
            ...(getInputType() === 'number' && !!min && { min }),
            ...inputProps
          }}
          endAdornment={endAdornment}
          startAdornment={startAdornment}
          onFocus={onFocus}
          onBlur={onBlur}
          {...InputProps}
        />
        {helperText ? (
          <FormHelperText
            className={classNames({ [classes.disabled]: disabled })}
          >
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </>
  )
}

const textFieldDefaultProps = {
  margin: MARGINS.normal,
  password: false,
  number: false,
  autoFocus: false,
  multiline: false,
  size: SIZE.medium,
  centeredInput: true
}

const textFieldPropTypes = {
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  margin: PropTypes.oneOf(Object.keys(MARGINS)),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  innerLabel: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  password: PropTypes.bool,
  number: PropTypes.bool,
  autoFocus: PropTypes.bool,
  centeredInput: PropTypes.bool,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  className: PropTypes.string,
  size: PropTypes.oneOf([SIZE.small, SIZE.medium]),
  dataTest: PropTypes.string,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node
}

TextFieldRaw.defaultProps = textFieldDefaultProps
TextFieldRaw.propTypes = {
  ...textFieldPropTypes,
  classes: PropTypes.object.isRequired
}

const TextField = withStyles(styles)(TextFieldRaw)

const TextFieldControlled = ({ name, skeletonUntilDefault, ...rest }) => {
  const { errors, watch } = useFormContext()
  const watchField = watch(name, undefined)
  if (skeletonUntilDefault && watchField === undefined) {
    return <Skeleton width="90px" height="44px" />
  }
  return (
    <Controller
      name={name}
      render={({ ref, ...methods }) => (
        <TextField
          name={name}
          error={Boolean(errors[name])}
          helperText={errors[name] && errors[name].message}
          inputRef={ref}
          {...rest}
          {...methods}
          onChange={e => methods.onChange(e.target.value)}
        />
      )}
    />
  )
}

const RHFTextField = ({ name, register, errors, ...props }) => (
  <TextField
    name={name}
    inputRef={register}
    error={Boolean(errors[name]?.message)}
    helperText={errors[name]?.message}
    {...props}
  />
)

export { TextField as default, TextFieldControlled, RHFTextField }
