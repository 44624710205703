import React, { useEffect, useState } from 'react'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import { URL } from 'constants/navigation'
import {
  BUTTON_VARIANT,
  COLOR,
  SIZE,
  USER_TIERS_WITH_BENEFITS,
  STATUS_DOT_VARIANTS
} from 'constants/enums'
import { LIST_PATHS } from 'constants/queryParams'
import {
  useAdminUsers,
  useMediaQuery,
  useQuery,
  useNavigation,
  useLoaders,
  useAlerts,
  useConfig,
  useAdminOrdersList
} from 'hooks'

import { calculateCreditBalance, getMemoSubject } from 'utils/user'
import { dollarsToCents } from 'utils/general'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import ButtonBase from '@material-ui/core/ButtonBase'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import Block from 'components/Block'
import Button from 'components/Button'
import CreditHistoryDrawer from 'components/CreditHistoryDrawer/'
import Currency from 'components/Currency'
import DataTable from 'components/DataTable'
import Dialog from 'components/Dialog'
import EditStoreCreditDrawer from 'components/EditStoreCreditDrawer'
import GlanceTile from 'components/GlanceTile/'
import Header from 'components/Header'
import Layout from 'components/Layout'
import MemoList from 'components/MemoList'
import StatusDot from 'components/StatusDot'
import TierBadge from 'components/TierBadge'
import UserAvatar from 'components/UserAvatar'

import UserOrderItem from './UserOrderItem'

import styles from './UserDetailsStyles'

const userOrders = [
  { title: 'Order #', sortKeys: [LIST_PATHS.ORDERS_NUMBER] },
  { title: 'Total' },
  { title: 'Payment' },
  { title: 'Items' },
  { title: 'Date', sortKeys: [LIST_PATHS.ORDERS_DELIVERY_STARTS] },
  { title: 'Window' },
  { title: 'Method' },
  { title: 'Status' }
]

const UserDetails = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const { referralTiers } = useConfig()
  const { user, readUser, updateUser } = useAdminUsers()
  const { showLoading, hideLoading, isLoading } = useLoaders()
  const { showAlertSuccess } = useAlerts()

  const [editStoreCreditDrawerOpen, setEditStoreCreditDrawerOpen] = useState(
    false
  )
  const [openBenefitsDialog, setOpenBenefitsDialog] = useState(false)

  const [creditHistoryDrawerOpen, showCreditHistoryDrawer] = useState(false)

  useEffect(() => {
    showLoading()
    if (id || id === 0) {
      readUser(id)
    }
  }, [])

  useEffect(() => {
    isLoading && hideLoading()
  }, [user])

  const { handleClick: editUser } = useNavigation({
    url: URL.ADMIN_USERS_EDIT
  })

  const { query, updateQuery } = useQuery(() => {}, {
    initFallback: {
      [LIST_PATHS.ORDER_USER_ID]: id
    },
    locationFilter: LIST_PATHS.ORDERS_LOCATION_ID
  })

  const {
    fetchOrdersNext,
    orders,
    isLoadingOrders,
    hasOrdersNext
  } = useAdminOrdersList({ params: query })

  const onUpdateStoreCredit = async ({ type, amount, memo }) => {
    showLoading()
    await updateUser({
      id,
      credit_balance: dollarsToCents(
        calculateCreditBalance(user, {
          type,
          amount
        })
      ),
      memo: {
        subject: getMemoSubject(type, amount),
        body: memo
      }
    })

    showAlertSuccess('Store credit updated!')
    readUser(id)
  }

  const { handleClick: navigateToOrder } = useNavigation({
    url: URL.ADMIN_ORDERS
  })

  const { isMobileScreen } = useMediaQuery()

  const userName = user?.name
  const userDetails = [
    {
      name: 'member_since',
      label: 'Member Since',
      value: (
        <Typography variant="body2">
          {moment(user?.created_at).format('MMM DD, yyyy')}
        </Typography>
      )
    },
    {
      name: 'contact_info',
      label: 'Contact Info',
      value: (
        <>
          <Typography variant="body2">
            {user?.email || user?.checkout_email}
          </Typography>
          <Typography variant="body2">{user?.phone}</Typography>
        </>
      )
    },
    {
      name: 'promo_code',
      label: 'User Code',
      value: <Typography variant="body2">{user?.referral_code}</Typography>
    }
  ]

  const orderStatistics = [
    {
      name: 'user_code_uses',
      label: 'Code Uses',
      value: user?.all_time_referrals_count || 0
    },
    {
      name: 'total_orders',
      label: 'Total Orders',
      value: user?.total_orders || 0
    },
    {
      name: 'total_returns',
      label: 'Total Returns',
      value: user?.total_returns || 0
    }
  ]

  const benefits = [
    {
      label: 'Date of Upgrade',
      value:
        user?.referral_tier_upgraded_at &&
        moment(user?.referral_tier_upgraded_at).format('MM/DD/YYYY')
    },
    {
      label: 'Promo Code',
      value: user?.referral_tier_discount_code
    },
    {
      label: 'Free Shipping for Orders < $35',
      value:
        referralTiers &&
        referralTiers[user?.referral_tier]?.free_shipping_threshold -
          user?.free_shipping_count
    }
  ].concat(
    referralTiers &&
      referralTiers[user?.referral_tier]?.benefits.map(benefit => ({
        label: benefit
      }))
  )

  const foldableActions = (
    <>
      <Button
        color="primary"
        variant="contained"
        size={SIZE.medium}
        onClick={e => editUser(e, user)}
        dataTest="user-edit-button"
      >
        Edit User
      </Button>
    </>
  )

  const memos = user?.memos || []
  const newestMemos = sortBy(memos, o => new Date(o.updated_at)).reverse()
  const latestThreeMemos = newestMemos.slice(0, 3)

  const hasBenefits = USER_TIERS_WITH_BENEFITS.includes(user?.referral_tier)

  if (!user || !referralTiers) return null

  return (
    <Layout id="user-details">
      <Header
        foldableActions={foldableActions}
        breadcrumbs={[{ title: 'Users', link: URL.ADMIN_USERS }]}
        title={userName}
      />
      {user?.blocked && (
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <StatusDot variant={STATUS_DOT_VARIANTS.red} text="Blocked" />
        </Box>
      )}
      <Box mt={2} mb={isMobileScreen ? 1 : 4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Block
              withPadding
              title="Customer Details"
              className={classes.profile}
            >
              <UserAvatar
                size={SIZE.xlarge}
                src={user?.image_url}
                className={classes.avatar}
              />
              <Typography variant="body2">{userName}</Typography>
              {hasBenefits ? (
                <ButtonBase
                  className={classes.tierBadgeButton}
                  onClick={() => setOpenBenefitsDialog(true)}
                >
                  <Box display="flex" alignItems="center">
                    <TierBadge user={user} />
                    <ArrowForwardIosIcon className={classes.forwardArrow} />
                  </Box>
                </ButtonBase>
              ) : (
                <Box mt={1} mb={2}>
                  <TierBadge user={user} />
                </Box>
              )}

              {userDetails.map(stat => (
                <Box key={stat.name} className={classes.stat}>
                  <Typography variant="caption">{stat.label}</Typography>
                  {stat.value}
                </Box>
              ))}
              {memos.length > 0 && (
                <Box my={2}>
                  <Box mb={3}>
                    <Divider />
                  </Box>
                  <Box>
                    <Typography variant="h5" className={classes.memosTitle}>
                      Store Credit History
                    </Typography>
                    <MemoList memos={latestThreeMemos} />
                    <Button
                      color={COLOR.secondary}
                      variant={BUTTON_VARIANT.outlined}
                      size={SIZE.medium}
                      onClick={() => showCreditHistoryDrawer(true)}
                    >
                      View All
                    </Button>
                  </Box>
                </Box>
              )}
            </Block>
          </Grid>

          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.orderStatsContainer}>
                {orderStatistics.map(tile => (
                  <GlanceTile
                    className={classes.topStat}
                    key={tile.name}
                    name={tile.name}
                    label={tile.label}
                    value={tile.value}
                    large
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <GlanceTile
                  key="store_credit"
                  name="store_credit"
                  label="Store Credit"
                  value={<Currency val={user?.credit_balance} />}
                  className={classes.largeTile}
                  onEdit={() => setEditStoreCreditDrawerOpen(true)}
                  large
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <EditStoreCreditDrawer
        open={editStoreCreditDrawerOpen}
        onClose={() => setEditStoreCreditDrawerOpen(false)}
        updateStoreCredit={onUpdateStoreCredit}
      />
      <CreditHistoryDrawer
        open={creditHistoryDrawerOpen}
        onClose={() => showCreditHistoryDrawer(false)}
        memos={newestMemos}
      />
      <DataTable
        columns={userOrders}
        message={orders?.length === 0 ? 'No results found.' : null}
        isLoadingList={isLoadingOrders}
        hasListNext={hasOrdersNext}
        listNext={fetchOrdersNext}
        query={query}
        updateQuery={updateQuery}
        useWindowScrollListener
      >
        {orders?.map(order => (
          <UserOrderItem
            key={order.id}
            order={order}
            onClick={navigateToOrder}
          />
        ))}
      </DataTable>

      <Dialog
        classes={{ content: classes.dialogContent }}
        open={openBenefitsDialog}
        onConfirm={() => setOpenBenefitsDialog(false)}
        confirmText="Okay"
        title={`${startCase(user?.referral_tier)} Member Benefits`}
        hideCancel
      >
        <ul className={classes.benefitsList}>
          {benefits.map((benefit, idx) => (
            <li
              display="flex"
              justifyContent="space-between"
              className={classes.benefitRow}
              key={idx}
            >
              <Typography className={classes.benefitLabel} variant="body1">
                {benefit.label}
              </Typography>
              {Boolean(benefit.value) && (
                <Typography className={classes.benefitValue} variant="body1">
                  {benefit.value}
                </Typography>
              )}
            </li>
          ))}
        </ul>
      </Dialog>
    </Layout>
  )
}
UserDetails.defaultProps = {}
UserDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}
export default withStyles(styles)(UserDetails)
