const jssStyles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center'
  },
  button: {
    height: 20,
    padding: 0
  },
  flipped: {
    transform: 'scaleY(-1)'
  },
  active: {
    fill: theme.palette.common.white
  },
  inactive: {
    fill: theme.palette.grays.dark
  }
})

export default jssStyles
