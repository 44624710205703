import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

import {
  useAdminStorefrontList,
  useAlerts,
  useConditionalEffect,
  useNavigation
} from 'hooks'
import { SIZE, MARGINS } from 'constants/enums'
import { URL } from 'constants/navigation'
import { useLocationsContext } from 'context'
import { thinInput } from 'constants/styles'

import { TitleElement } from 'components/SiteTitle'
import AutocompleteSelectLocations from 'components/AutocompleteSelectLocations'
import Button from 'components/Button'
import DataTable from 'components/DataTable'
import DatePicker from 'components/DatePicker'
import Dialog from 'components/Dialog'
import Header from 'components/Header'
import Layout from 'components/Layout'
import Select from 'components/Select'
import TextField from 'components/TextField'
import { parseDateIgnoreTZ } from 'utils/general'

import StorefrontItem from './StorefrontItem'

const columns = [
  {
    title: 'Name'
  },
  {
    title: 'Publish Time'
  },
  { title: '' }
]

const DATE_FORMAT = 'YYYYMMDD'
const TIME_FORMAT = 'HHmm'

const StorefrontList = ({ classes }) => {
  const { go } = useNavigation({})
  const { showAlertError, showAlertGeneral } = useAlerts()
  const { locationId, location, showAllLocationIds } = useLocationsContext()
  const {
    storefronts,
    isLoadingStorefronts,
    createStorefront
  } = useAdminStorefrontList({
    params: { location_id: locationId },
    fetchingEnabled: !showAllLocationIds
  })

  const [newStorefrontName, setNewStorefrontName] = useState('')
  const [newStorefrontLocation, setNewStorefrontLocation] = useState({})
  const [duplicateThisStorefront, setDuplicateThisStorefront] = useState('')
  const [publishNewStorefrontAt, setPublishNewStorefrontAt] = useState(null)

  const [
    openCreateNewStorefrontModal,
    setOpenCreateNewStorefrontModal
  ] = useState(false)

  useConditionalEffect(() => {
    if (location) {
      setNewStorefrontLocation(location)
    }
  }, [location])

  if (showAllLocationIds) {
    showAlertGeneral('Please select a Location to view storefronts')
  }

  const createNewStorefront = async () => {
    const nowThere = moment().tz(location.timezone)
    //need the second param to be true, otherwise it will change the tz of the date
    if (publishNewStorefrontAt.tz(location.timezone, true).isBefore(nowThere)) {
      showAlertError('Please pick a time in the future.')
      setOpenCreateNewStorefrontModal(true)
      return
    }

    const { data: newStorefront } = await createStorefront({
      location_id: newStorefrontLocation.id,
      name: newStorefrontName,
      copy_sections_from_storefront_id: duplicateThisStorefront,
      publish_at_date: parseDateIgnoreTZ(publishNewStorefrontAt).format(
        DATE_FORMAT
      ),
      publish_at_time: parseDateIgnoreTZ(publishNewStorefrontAt).format(
        TIME_FORMAT
      ),
      publish_at_timezone: location.timezone
    })

    if (newStorefront.id) {
      go({}, `${URL.ADMIN_STOREFRONT}/${newStorefront?.id}`)
    } else {
      showAlertError('Something did not go as expected... please try again!')
    }
  }

  const actions = (
    <>
      <Button
        adaptive
        startIcon={<AddIcon />}
        color="primary"
        variant="contained"
        label="new storefront button"
        size={SIZE.medium}
        onClick={() => setOpenCreateNewStorefrontModal(true)}
        dataTest="new-storefront"
        disabled={showAllLocationIds}
      >
        New Storefront
      </Button>
      <Dialog
        open={openCreateNewStorefrontModal}
        onClose={() => setOpenCreateNewStorefrontModal(false)}
        onConfirm={createNewStorefront}
        confirmText="Create"
        title="Create a New Storefront"
        subtitle="Create a new storefront by name and based off an existing storefront of your choice."
      >
        <Box width="100%" mb={2}>
          <Typography variant="caption">Location</Typography>
          <AutocompleteSelectLocations
            name="select-storefront-location"
            multiple={false}
            value={newStorefrontLocation}
            onChange={value => setNewStorefrontLocation(value)}
          />
        </Box>
        <Box width="100%">
          <Typography variant="caption">New Storefront Name</Typography>
          <TextField
            margin={MARGINS.dense}
            fullWidth
            value={newStorefrontName}
            onChange={e => setNewStorefrontName(e.target.value)}
          />
        </Box>
        <Box width="100%">
          <Typography variant="caption">Duplicate This Storefront</Typography>
          <Select
            fullWidth
            margin={MARGINS.dense}
            value={duplicateThisStorefront}
            onChange={e => setDuplicateThisStorefront(e.target.value)}
            items={storefronts?.map(sf => ({
              value: sf.id,
              label: sf.name
            }))}
          />
        </Box>
        <Box width="100%" mt={2}>
          <Box mb={-1}>
            <Typography variant="caption">Publish Date/Time</Typography>
          </Box>
          <DatePicker
            fullWidth
            className={classes.thinInput}
            placeholder="Day"
            name="publish-date-time"
            withTime
            dataTest="promotion-starts-at"
            onChange={dt => setPublishNewStorefrontAt(dt)}
            value={publishNewStorefrontAt}
            disablePast
          />
        </Box>
      </Dialog>
    </>
  )
  const pageTitle = 'Storefronts'

  return (
    <Layout className={classes.root} id="StorefrontList">
      <TitleElement title={pageTitle} />
      <Header title={pageTitle} actions={actions} />
      <DataTable
        columns={columns}
        message={
          storefronts?.length === 0 && !isLoadingStorefronts
            ? 'No results found.'
            : null
        }
        isLoadingList={isLoadingStorefronts && !showAllLocationIds}
      >
        {storefronts?.map(storefront => (
          <StorefrontItem
            storefront={storefront}
            key={storefront.id}
            timezone={location?.timezone}
          />
        ))}
      </DataTable>
    </Layout>
  )
}

StorefrontList.defaultProps = {}

StorefrontList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(theme => ({
  thinInput: thinInput(theme)
}))(StorefrontList)
