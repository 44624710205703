import { COLLECTIONS_BLOCK_ASPECT_RATIO } from 'constants/collections'
import { ABSOLUTE_FILL } from 'constants/styles'

// Percentage padding is calculated as from width
const ASPECT_RATIO_PADDING_PERCENT = `${Math.round(
  100 / COLLECTIONS_BLOCK_ASPECT_RATIO
)}%`

const jssStyles = theme => ({
  root: {
    position: 'relative',
    borderRadius: 5,
    color: 'white',
    overflow: 'hidden'
  },
  rootClickable: {
    cursor: 'pointer',

    '&:hover': {
      '& $backgroundImage': {
        transition: theme.transitions.create(['transform']),
        transform: 'scale(1.05)'
      }
    }
  },
  backgroundImage: {
    ...ABSOLUTE_FILL,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grays.dark,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    zIndex: 10
  },
  aspectRatio: {
    width: '100%',
    paddingBottom: ASPECT_RATIO_PADDING_PERCENT
  },
  title: {
    ...ABSOLUTE_FILL,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 50
  },
  content: {
    ...ABSOLUTE_FILL,
    padding: theme.spacing(1.5),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'flex-start',
    zIndex: 100
  },
  parentCollectionInfo: {
    position: 'absolute',
    right: 10,
    bottom: 10
  },
  menuIconButton: {
    padding: 4
  },
  removeButton: {
    opacity: 0.7
  }
})

export default jssStyles
