import React, { useState, useMemo } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import uniqueId from 'lodash/uniqueId'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from 'react-grid-dnd'

import Box from '@material-ui/core/Box'

import { DRAG_N_DROP_MODE } from 'constants/enums'
import * as propTypes from 'constants/propTypes'
import ProgressBar from 'components/ProgressBar'

import styles from './DragNDropGridStyles'

const DragNDropGrid = ({
  classes,
  items,
  setItems,
  itemWidth,
  itemHeight,
  marginWidth,
  marginHeight,
  ItemComponent,
  getItemProps,
  mode,
  onSelectClick,
  onFeatureClick,
  dragAndRemoveMode
}) => {
  const [labelId] = useState(uniqueId('dnd-'))

  const removeItem = item => {
    setItems(items.filter(i => i !== item))
  }

  const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    if (targetId) {
      return
    }
    const result = swap(items, sourceIndex, targetIndex)
    return setItems(result)
  }

  const SizedGrid = useMemo(
    () => ({ width }) => {
      if (isEmpty(items)) {
        return (
          <Box my={4}>
            <ProgressBar />
          </Box>
        )
      }
      const overHangWidth = width + marginWidth
      const itemsPerRow = Math.max(
        Math.floor(overHangWidth / (itemWidth + marginWidth)),
        1
      )
      const rowCount = Math.ceil(items.length / itemsPerRow)
      const height = rowCount * (itemHeight + marginWidth)

      const disableDrag = mode !== DRAG_N_DROP_MODE.ORDERING

      const shouldDisableDrag = dragAndRemoveMode ? false : disableDrag

      return (
        <GridContextProvider onChange={onChange}>
          <Box
            height={height + 2 * marginWidth}
            width={overHangWidth}
            mx={`${-marginWidth / 2}px`}
            className={classes.container}
          >
            <GridDropZone
              disableDrag={shouldDisableDrag}
              className={classes.dropZone}
              id={labelId}
              boxesPerRow={itemsPerRow}
              rowHeight={itemHeight + marginHeight}
            >
              {items.map(item => (
                <GridItem
                  key={item.id || item?.signature}
                  style={
                    disableDrag && !dragAndRemoveMode
                      ? { cursor: 'initial' }
                      : { cursor: 'grab' }
                  }
                >
                  <Box padding={1} width="100%" height="100%">
                    <ItemComponent
                      item={item}
                      width={itemWidth}
                      height={itemHeight}
                      draggable={!shouldDisableDrag}
                      onRemove={
                        mode === DRAG_N_DROP_MODE.REMOVE || dragAndRemoveMode
                          ? removeItem
                          : null
                      }
                      onSelectClick={
                        mode === DRAG_N_DROP_MODE.SELECTING
                          ? onSelectClick
                          : null
                      }
                      onFeatureClick={onFeatureClick}
                      {...(getItemProps?.(item) ?? {})}
                    />
                  </Box>
                </GridItem>
              ))}
            </GridDropZone>
          </Box>
        </GridContextProvider>
      )
    },
    [
      items,
      mode,
      getItemProps,
      classes.container,
      classes.dropZone,
      dragAndRemoveMode,
      itemHeight,
      itemWidth,
      labelId,
      marginHeight,
      marginWidth,
      onChange,
      onSelectClick,
      onFeatureClick,
      removeItem
    ]
  )

  SizedGrid.propTypes = {
    width: PropTypes.number
  }

  return <AutoSizer disableHeight>{SizedGrid}</AutoSizer>
}

DragNDropGrid.defaultProps = {
  marginWidth: 20,
  marginHeight: 16,
  mode: DRAG_N_DROP_MODE.VIEW,
  dragAndRemoveMode: false,
  setItems: () => {}
}

DragNDropGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  itemWidth: PropTypes.number.isRequired,
  itemHeight: PropTypes.number.isRequired,
  ItemComponent: PropTypes.elementType.isRequired,
  setItems: PropTypes.func,
  marginWidth: PropTypes.number,
  marginHeight: PropTypes.number,
  mode: propTypes.dragNDropMode,
  getItemProps: PropTypes.func,
  onSelectClick: PropTypes.func,
  onFeatureClick: PropTypes.func,
  dragAndRemoveMode: PropTypes.bool
}

export default withStyles(styles)(DragNDropGrid)
