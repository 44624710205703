import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'

const DefaultSizePx = 24

const CheckIcon = ({ fill, fontSize }) => {
  const theme = useTheme()

  return (
    <svg
      width={fontSize || DefaultSizePx}
      height={fontSize || DefaultSizePx}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2025 3.43391L15.4248 5.65625L8.53051 12.5505L6.23242 14.8486L4.01008 12.6263L0.575563 9.19178L2.87366 6.89369L6.30818 10.3282L13.2025 3.43391Z"
        fill={fill || theme.palette.primary.main}
      />
    </svg>
  )
}

CheckIcon.propTypes = {
  fill: PropTypes.string,
  fontSize: PropTypes.number
}

export default CheckIcon
