import React from 'react'
import PropTypes from 'prop-types'

import { LocalStorageProvider } from './LocalStorageContext'
import { UIProvider } from './UIContext'
import { AuthProvider } from './AuthContext'
import { LocationsProvider } from './LocationsContext'
import { AppConfigProvider } from './AppConfigContext'

const AppProvider = ({ children }) => (
  <LocalStorageProvider>
    <UIProvider>
      <AuthProvider>
        <LocationsProvider>
          <AppConfigProvider>{children}</AppConfigProvider>
        </LocationsProvider>
      </AuthProvider>
    </UIProvider>
  </LocalStorageProvider>
)

AppProvider.propTypes = {
  children: PropTypes.node
}

export default AppProvider
