import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { URL } from 'constants/navigation'

import { useNavigation } from 'hooks'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

const PromotionItem = ({ promotion }) => {
  const { handleClick: handlePromotionClick } = useNavigation({
    url: URL.ADMIN_PROMOTIONS
  })

  return (
    <TableRow data-test="promo-code-row">
      <TableCell>
        <Box onClick={e => handlePromotionClick(e, promotion)}>
          <Typography variant="body1">{promotion.title}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {promotion?.starts_at
            ? moment(promotion?.starts_at).format('L')
            : 'N/A'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {promotion?.ends_at ? moment(promotion?.ends_at).format('L') : 'N/A'}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

PromotionItem.propTypes = {
  promotion: PropTypes.object.isRequired
}

export default PromotionItem
