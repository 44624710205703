import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import * as yup from 'yup'
import { useFormContext } from 'react-hook-form'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import {
  TO_FIELDS,
  TO_SCHEMAS,
  TO_SUBMIT_EDITABLE_SCHEMAS,
  TO_FORM_DEFAULTS,
  SHIPMENT_ITEM_COLUMNS,
  TRANSFER_ORDER_STATE_TYPES
} from 'constants/transferOrders'
import { BUTTON_VARIANT, COLOR, SIZE } from 'constants/enums'
import { URL } from 'constants/navigation'

import { useLocationsContext } from 'context'

import {
  useMenu,
  useNavigation,
  usePrevious,
  useAdminTransferOrder,
  useMediaQuery,
  useAlerts,
  useLoaders,
  useNotifications,
  useConditionalEffect
} from 'hooks'

import { withStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import SaveIcon from '@material-ui/icons/Save'
import CloseIcon from '@material-ui/icons/Close'

import ImportIcon from 'icons/ImportIcon'
import AddIcon from 'icons/AddIcon'
import SubmitIcon from 'icons/SubmitIcon'

import { variantFieldName } from 'containers/admin/PurchaseOrderDetails'

import { DatePickerField } from 'components/DatePicker'
import { SelectField } from 'components/Select'
import Block from 'components/Block'
import Button from 'components/Button'
import Confirm from 'components/Confirm'
import ConfirmLeaveWhenDirty from 'components/ConfirmLeaveWhenDirty'
import ControlledForm, { useControlledForm } from 'components/ControlledForm'
import DataTable from 'components/DataTable'
import Fluid from 'components/Fluid'
import Header from 'components/Header'
import Layout from 'components/Layout'
import MemoContainer from 'components/MemoContainer'
import MemoList from 'components/MemoList'
import PurchaseOrderStatus from 'components/PurchaseOrderStatus'
import TransferOrderShipmentItemsDrawer from 'components/TransferOrderShipmentItemsDrawer'

import TransferOrderShipmentItem from './TransferOrderShipmentItem'

import styles from './TransferOrderDetailsStyles'

const LocationDropdowns = ({ editable, isMobile }) => {
  const { locationOptionsDisplayName } = useLocationsContext()
  const {
    formContext: { getValues, setValue }
  } = useControlledForm()

  const {
    destination_location_id: destinationLocationId,
    origin_location_id: originLocationId
  } = getValues([
    TO_FIELDS.destination_location_id,
    TO_FIELDS.origin_location_id
  ])

  const getFilteredLocationOptions = excludedId =>
    locationOptionsDisplayName?.filter(({ value }) => value !== excludedId)

  const prevDestinationLocationId = usePrevious(destinationLocationId)
  useConditionalEffect(() => {
    if (
      destinationLocationId !== prevDestinationLocationId &&
      !!prevDestinationLocationId
    ) {
      setValue(TO_FIELDS.origin_location_id, '')
    }
  }, [destinationLocationId, originLocationId])

  return (
    <>
      <SelectField
        isLoaded={Boolean(locationOptionsDisplayName)}
        name={TO_FIELDS.destination_location_id}
        items={getFilteredLocationOptions() || []}
        label="To Location"
        fullWidth={isMobile}
        disabled={!editable}
        dataTest="to-to_location"
      />
      <SelectField
        isLoaded={Boolean(locationOptionsDisplayName)}
        name={TO_FIELDS.origin_location_id}
        items={getFilteredLocationOptions(destinationLocationId) || []}
        label="From Location"
        fullWidth={isMobile}
        disabled={!editable}
        dataTest="to-from_location"
      />
    </>
  )
}

const TOMenu = ({
  classes,
  menuToggle,
  menuOpen,
  menuClose,
  menuEl,
  transferOrder
}) => {
  const { go } = useNavigation({
    url: URL.ADMIN_PRODUCTS
  })

  return (
    <Box
      className={classes.menuButton}
      onClick={menuToggle}
      data-test="to-menu"
    >
      <MoreVertIcon />
      <Menu open={menuOpen} onClose={menuClose} anchorEl={menuEl}>
        <MenuItem
          onClick={e =>
            go(e, `${URL.ADMIN_TRANSFER_ORDERS}/${transferOrder?.id}/history`)
          }
          data-test="to-history-menu-item"
        >
          History
        </MenuItem>
      </Menu>
    </Box>
  )
}

const TransferOrderDetails = ({
  classes,
  match: {
    params: { id }
  },
  location: { pathname }
}) => {
  const { locations } = useLocationsContext()
  const { go } = useNavigation({
    url: URL.ADMIN_TRANSFER_ORDERS
  })
  const isCreateNewTransferOrderForm = pathname === '/admin/to/new'

  const { isMobileScreen } = useMediaQuery()
  const isMobile = Boolean(isMobileScreen)
  const { showAlertError, showAlertSuccess, showAlertGeneral } = useAlerts()
  const { showLoading, hideLoading } = useLoaders()
  const {
    open: menuOpen,
    anchorEl: menuEl,
    handleToggleMenu: menuToggle
  } = useMenu()

  const [addProductDrawerOpen, setAddProductDrawerOpen] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)

  const onImportCSVSuccess = res => {
    if (res.id) {
      showAlertSuccess('Successfully imported variant SKUs')
    }
  }

  const {
    transferOrder,
    createTransferOrder,
    updateTransferOrder,
    isLoadingTransferOrder,
    isTransferOrderError,
    transferOrderError,
    updateTransferOrderShipmentItems,
    submitTransferOrder,
    closeTransferOrder,
    importCSV
  } = useAdminTransferOrder(id, onImportCSVSuccess)

  useNotifications(
    isLoadingTransferOrder,
    isTransferOrderError,
    transferOrderError
  )

  const [formDefaults, setFormDefaults] = useState(TO_FORM_DEFAULTS)
  const getDefaultValues = async () =>
    !isEmpty(transferOrder)
      ? {
          [TO_FIELDS.origin_location_id]:
            transferOrder?.origin_location?.id || '',

          [TO_FIELDS.destination_location_id]:
            transferOrder?.destination_location?.id || '',

          [TO_FIELDS.order_date]: transferOrder?.order_date
            ? moment(transferOrder?.order_date)
            : null,
          [TO_FIELDS.estimated_arrival_at]: transferOrder?.estimated_arrival_at
            ? moment(transferOrder?.estimated_arrival_at)
            : null,
          [TO_FIELDS.memo]: ''
        }
      : TO_FORM_DEFAULTS

  useConditionalEffect(() => {
    ;(async () => {
      if (!isEmpty(transferOrder)) {
        setFormDefaults(await getDefaultValues())
      }
    })()
  }, [transferOrder])

  const handleImportClick = async e => {
    if (e.target.files.length > 0) {
      importCSV(e.target)
    } else {
      showAlertError('No files specified for upload')
    }
  }

  const handleSubmitTransferOrder = async () => {
    setOpenPopover(false)
    showLoading()

    if (
      transferOrder?.status === TRANSFER_ORDER_STATE_TYPES.submitted ||
      formSubmitting
    ) {
      showAlertGeneral('Invalid submission')
    } else {
      setFormSubmitting(true)
      await submitTransferOrder(id)
      setFormSubmitting(false)
    }

    hideLoading()
  }

  const handleCloseTransferOrder = async () => {
    showLoading()
    closeTransferOrder(id)
    hideLoading()
  }

  const editable =
    isCreateNewTransferOrderForm ||
    transferOrder?.status === TRANSFER_ORDER_STATE_TYPES.draft

  const submitEditable =
    editable || transferOrder?.status === TRANSFER_ORDER_STATE_TYPES.submitted

  const SaveButton = ({ label = 'Save' }) => {
    const {
      formContext: {
        formState: { isDirty, dirtyFields }
      }
    } = useControlledForm()
    return (
      <Button
        size={SIZE.medium}
        color={COLOR.primary}
        dataTest="to-save-button"
        variant={BUTTON_VARIANT.contained}
        startIcon={<SaveIcon />}
        adaptive
        label={label}
        disabled={!isDirty || Object.keys(dirtyFields).length === 0}
        type="submit"
      >
        Save
      </Button>
    )
  }

  let actions = (
    <>
      <Box ml={1}>
        <Skeleton width="90px" height="50px" />
      </Box>
      <Skeleton width="90px" height="50px" />
    </>
  )

  if (isCreateNewTransferOrderForm) {
    actions = <SaveButton label="Create" />
  } else if (transferOrder?.status === TRANSFER_ORDER_STATE_TYPES.draft) {
    const SubmitButton = () => {
      const {
        formContext: { getValues }
      } = useControlledForm()

      const { destination_location_id: destinationLocationId } = getValues([
        TO_FIELDS.destination_location_id
      ])

      const locationDisplayName = locations.filter(
        loc => loc.id === destinationLocationId
      )[0]?.display_name

      return (
        <Confirm
          description={
            <>
              This will{' '}
              <strong>
                immediately remove inventory from {locationDisplayName}
              </strong>
              . Are you <strong>REALLY REALLY</strong> sure you want to do this?
            </>
          }
          onConfirm={handleSubmitTransferOrder}
          onClose={() => setOpenPopover(false)}
          open={openPopover}
        >
          <Button
            variant={BUTTON_VARIANT.outlined}
            size={SIZE.medium}
            color={COLOR.secondary}
            startIcon={<SubmitIcon />}
            adaptive
            disabled={
              transferOrder?.shipping_items?.length === 0 || formSubmitting
            }
            label="Submit"
            onClick={() => setOpenPopover(true)}
            dataTest="to-submit-button"
          >
            Submit
          </Button>
        </Confirm>
      )
    }
    actions = (
      <>
        <TOMenu
          classes={classes}
          menuToggle={menuToggle}
          menuOpen={menuOpen}
          menuEl={menuEl}
          transferOrder={transferOrder}
        />
        <SubmitButton />
        <SaveButton />
      </>
    )
  } else if (transferOrder?.status === TRANSFER_ORDER_STATE_TYPES.submitted) {
    const CloseButton = () => (
      <Button
        variant={BUTTON_VARIANT.outlined}
        size={SIZE.medium}
        type="submit"
        color={COLOR.primary}
        startIcon={<CloseIcon />}
        adaptive
        label="Close"
        onClick={handleCloseTransferOrder}
        name="type"
        value="closed"
      >
        Close
      </Button>
    )
    actions = (
      <>
        <TOMenu
          classes={classes}
          menuToggle={menuToggle}
          menuOpen={menuOpen}
          menuEl={menuEl}
          transferOrder={transferOrder}
        />
        <CloseButton />
        <SaveButton />
      </>
    )
  } else {
    actions = <></>
  }

  const handleSaveTransferOrder = async ({ memo, ...formValues }) => {
    showLoading()
    const memoObject = memo ? { memo: { subject: '', body: memo } } : {}
    const transferOrderBody = { ...formValues, ...memoObject }

    if (isCreateNewTransferOrderForm) {
      createTransferOrder({ ...transferOrderBody })
      return
    }

    if (transferOrder?.status === TRANSFER_ORDER_STATE_TYPES.draft) {
      updateTransferOrder({ ...transferOrderBody })
    }

    hideLoading()
  }

  const prevTransferOrder = usePrevious(transferOrder)
  useConditionalEffect(() => {
    if (
      !prevTransferOrder &&
      isCreateNewTransferOrderForm &&
      !isEmpty(transferOrder)
    ) {
      const transferOrderId = transferOrder?.id
      if (transferOrderId) {
        showAlertSuccess(`Transfer Order ${transferOrderId} Created`)
        go({}, `${URL.ADMIN_TRANSFER_ORDERS}/${transferOrderId}`)
      }
    }
  }, [transferOrder])

  // The update endpoint replaces so we make sure to also include the existing variants.
  const handleVariantSubmit = variant_quantities => {
    showLoading()
    updateTransferOrderShipmentItems({ variant_quantities })
    hideLoading()
  }

  let variantFormDefaults = {}
  let variantSchemas = {}
  if (Array.isArray(transferOrder?.shipment_items)) {
    transferOrder.shipment_items.forEach(shipmentItem => {
      variantFormDefaults = {
        ...variantFormDefaults,
        [variantFieldName(shipmentItem.variant.id)]: shipmentItem.quantity
      }
      variantSchemas = {
        ...variantSchemas,
        [variantFieldName(shipmentItem.variant.id)]: yup
          .number()
          .positive()
          .required('Must be positive number')
      }
    })
  }

  const VariantSaveButton = () => {
    const {
      formState: { isDirty, dirtyFields }
    } = useFormContext()
    return isDirty && Object.keys(dirtyFields).length > 0 ? (
      <Button
        className={classes.productButton}
        variant="outlined"
        size={SIZE.medium}
        fullWidth={isMobile}
        type="submit"
        dataTest="variant-save-button"
      >
        Save
      </Button>
    ) : (
      <></>
    )
  }

  const handleVariantQuantitySubmit = async quantities => {
    showLoading()
    updateTransferOrderShipmentItems({
      variant_quantities: transferOrder.shipment_items
        .filter(
          shipmentItem =>
            shipmentItem.quantity !==
            quantities[variantFieldName(shipmentItem.variant.id)]
        )
        .map(shipmentItem => ({
          id: shipmentItem.variant.id,
          quantity: quantities[variantFieldName(shipmentItem.variant.id)]
        }))
    })
    hideLoading()
  }

  return (
    <Layout id="transfer-order-details">
      <ControlledForm
        handleSubmit={handleSaveTransferOrder}
        schemas={
          editable
            ? submitEditable
              ? TO_SUBMIT_EDITABLE_SCHEMAS
              : TO_SCHEMAS
            : {}
        }
        defaultValues={formDefaults}
        resetOnSubmit={false}
      >
        {!isCreateNewTransferOrderForm && <ConfirmLeaveWhenDirty />}
        <Header
          breadcrumbs={[
            { title: 'Transfer Order', link: URL.ADMIN_TRANSFER_ORDERS }
          ]}
          title={isCreateNewTransferOrderForm ? 'New TO' : id}
          actions={actions}
        />
        <Box
          className={classNames({
            [classes.root]: true,
            [classes.isMobile]: isMobile
          })}
          data-test="to-details"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.gridItem}>
              <Block withPadding className={classes.details}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Box mb={2}>
                      <Typography variant="h5">
                        Details for Transfer Order #{id}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row">
                      <Typography variant="h5">Status: </Typography>
                      {transferOrder?.status ? (
                        <PurchaseOrderStatus status={transferOrder?.status} />
                      ) : isCreateNewTransferOrderForm ? (
                        <PurchaseOrderStatus status="draft" />
                      ) : (
                        <Skeleton height="20px" width="60px" />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Fluid classes={{ root: classes.detailsContainer }}>
                  {transferOrder || isCreateNewTransferOrderForm ? (
                    <>
                      <LocationDropdowns
                        editable={editable}
                        isMobile={isMobile}
                      />
                      <DatePickerField
                        name={TO_FIELDS.order_date}
                        label="Order Date"
                        fullWidth={isMobile}
                        disabled={!editable}
                        dataTest="to-order-date"
                        disablePortal={false}
                      />
                      <DatePickerField
                        name={TO_FIELDS.estimated_arrival_at}
                        label="Estimated Arrival"
                        fullWidth={isMobile}
                        disabled={!submitEditable}
                        dataTest="to-eta-date"
                        disablePortal={false}
                      />
                    </>
                  ) : (
                    <Skeleton />
                  )}
                </Fluid>
              </Block>
            </Grid>
            <Grid item xs={12} md={4}>
              <Block withPadding className={classes.memoContainer}>
                {((!isLoadingTransferOrder && transferOrder?.id) ||
                  isCreateNewTransferOrderForm) && (
                  <MemoContainer
                    name={TO_FIELDS.memo}
                    placeholder="Transfer Order Notes"
                    defaultActive={false}
                    selfContainedStyles={false}
                  />
                )}
                <MemoList
                  memos={transferOrder?.memos
                    ?.slice(0)
                    .sort(
                      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                    )}
                />
              </Block>
            </Grid>
          </Grid>
        </Box>
        {transferOrder?.id && submitEditable && (
          <TransferOrderShipmentItemsDrawer
            open={addProductDrawerOpen}
            onClose={() => setAddProductDrawerOpen(false)}
            onSubmit={handleVariantSubmit}
            orderShipmentItems={transferOrder?.shipment_items}
          />
        )}
      </ControlledForm>

      {!isCreateNewTransferOrderForm && (
        <ControlledForm
          handleSubmit={handleVariantQuantitySubmit}
          schemas={variantSchemas}
          defaultValues={variantFormDefaults}
        >
          <Box mt={2} mb={2}>
            <Box display="flex" justifyContent="flex-end">
              {!isEmpty(transferOrder) && submitEditable && (
                <Box pr={isMobile ? 1 : 0}>
                  <VariantSaveButton />
                  <label htmlFor="import-csv">
                    <input
                      accept=".csv"
                      style={{ display: 'none' }}
                      id="import-csv"
                      name="import-csv"
                      type="file"
                      onChange={handleImportClick}
                    />
                    <Button
                      className={classes.productButton}
                      variant="outlined"
                      size={SIZE.medium}
                      startIcon={<ImportIcon />}
                      fullWidth={isMobile}
                      component="span"
                    >
                      Import
                    </Button>
                  </label>
                  <Button
                    className={classes.productButton}
                    variant="outlined"
                    size={SIZE.medium}
                    onClick={() => setAddProductDrawerOpen(true)}
                    startIcon={<AddIcon />}
                    fullWidth={isMobile}
                    dataTest="add-products"
                  >
                    Products
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <DataTable
            message={
              isEmpty(transferOrder)
                ? 'Loading...'
                : !transferOrder?.shipment_items
                ? 'No results found.'
                : ''
            }
            isLoadingList={false}
            columns={SHIPMENT_ITEM_COLUMNS}
          >
            {transferOrder?.shipment_items?.map(item => (
              <TransferOrderShipmentItem
                key={item.id}
                shipmentItem={item}
                openShipmentItemDrawer={() => setAddProductDrawerOpen(true)}
                editable={submitEditable}
              />
            ))}
          </DataTable>
        </ControlledForm>
      )}
    </Layout>
  )
}

TransferOrderDetails.defaultProps = {}

TransferOrderDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(TransferOrderDetails)
