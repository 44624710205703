import { useQueryClient, useMutation } from 'react-query'

import { api } from 'api'
import { locationCollectionEndpoint } from 'api/endpoints'
import { useResourceRQ } from 'hooks'
import { STALE_TIME_MEDIUM } from 'constants/reactQuery'

const voidFunc = () => undefined
const isNum = n => Number.isInteger(n)

const useAdminLocationCollection = (
  collectionId,
  locationId,
  params,
  { onSuccess = voidFunc, onError = voidFunc } = {}
) => {
  const queryClient = useQueryClient()

  const {
    data: locationCollection,
    update: updateLocationCollection
  } = useResourceRQ({
    endpoint: locationCollectionEndpoint,
    params,
    args: [collectionId, locationId],
    name: 'location_collection',
    fetchingEnabled: isNum(collectionId) && isNum(locationId),
    disableOptimisticUpdate: true,
    options: { staleTime: STALE_TIME_MEDIUM },
    onSuccess: d => onSuccess('Updated location collection'),
    onError: err =>
      onError(
        `Error updating location collection, ${err?.data?.message ??
          err?.statusText}`
      )
  })

  const fetchLocationCollectionAlt = (cid, lid, prms) => {
    const url = locationCollectionEndpoint(cid, lid)
    return queryClient.fetchQuery([url, prms], val => {
      return api.get(val.queryKey[0], { params: val.queryKey[1] })
    })
  }

  const { mutateAsync: updateLocationCollectionAlt } = useMutation(
    ([cid, lid, payload]) =>
      api.put(locationCollectionEndpoint(cid, lid), payload),
    {
      onSuccess: ({ data }, [cid, lid]) => {
        queryClient.setQueryData(locationCollectionEndpoint(cid, lid), data)
      },
      onError: err => {
        onError(`Error updating, ${err?.data?.message ?? err?.statusText}`)
      }
    }
  )

  return {
    locationCollection,
    updateLocationCollection,
    fetchLocationCollectionAlt,
    updateLocationCollectionAlt
  }
}

export default useAdminLocationCollection
