const jssStyles = theme => ({
  paddingBlock: {
    padding: theme.spacing(3)
  },
  divider: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grays.med}`
  },
  selectReason: {
    marginTop: theme.spacing(2),
    width: '100%',
    maxWidth: 320
  },
  textField: {
    '& .MuiInputBase-root': {
      background: 'white',
      borderRadius: 0,
      '& fieldset': {
        display: 'none'
      }
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
      paddingBottom: theme.spacing(2)
    }
  }
})

export default jssStyles
