import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useLocationsContext } from 'context'
import { DARKSTORE_SERVICED_OPTIONS } from 'constants/general'
import { useMediaQuery, useToggleButton, useAdminLocation } from 'hooks'

import Block from 'components/Block'
import ZipCodeMap from 'components/ZipCodeMap'
import ServiceSelect from 'components/ServiceSelect'
import DarkstoreModal from 'components/DarkstoreModal'

import styles from './DarkstoresPaneStyles'

const DarkstoreItem = ({
  classes,
  idx,
  editing,
  handleUpdateModal,
  darkstore: { id, name, active }
}) => {
  const { updateLocation } = useAdminLocation({ id })

  return (
    <Box
      className={classNames({
        [classes.listItem]: true,
        [classes.listItemActive]: editing
      })}
      display="flex"
      alignItems="center"
      key={name}
      onClick={() => handleUpdateModal(idx)}
      data-test={`dark-stores-pane-${name}`}
    >
      <Typography className={classes.codeLabel}>{name}</Typography>
      <Box flex={1} />
      <ServiceSelect
        value={active}
        options={DARKSTORE_SERVICED_OPTIONS}
        onChange={({ value }) => updateLocation({ active: value })}
        id={name}
      />
    </Box>
  )
}

const DarkstoresPane = ({
  classes,
  darkstores,
  openModal,
  onCloseModal,
  onSubmit
}) => {
  const { location } = useLocationsContext()
  const { isDesktopScreen } = useMediaQuery()
  const { editing, Component: ToggleButton } = useToggleButton()

  const [modalOpen, setModalOpen] = useState(false)
  const [activeDarkstoreId, setActiveDarkstoreId] = useState(null)

  useEffect(() => {
    setModalOpen(openModal)
  }, [openModal])

  useEffect(() => {
    !editing && setActiveDarkstoreId(null)
  }, [editing])

  const handleCloseModal = () => {
    setActiveDarkstoreId(null)
    setModalOpen(false)
    onCloseModal && onCloseModal()
  }

  const handleSubmit = () => {
    handleCloseModal()
    onSubmit && onSubmit()
  }

  const handleUpdateModal = idx => {
    if (!editing) return
    setActiveDarkstoreId(darkstores[idx]?.id)
    setModalOpen(true)
  }

  return (
    <Box
      className={classNames({
        [classes.root]: true,
        [classes.isDesktop]: isDesktopScreen
      })}
    >
      <Grid className={classes.gridContainer} container spacing={3}>
        <Grid item sm={12} md={8} className={classes.gridItem}>
          <Block className={classes.mapContainer}>
            <ZipCodeMap location={location} />
          </Block>
        </Grid>
        <Grid item sm={12} md={4} className={classes.gridItem}>
          <Block
            className={classes.codesPanel}
            classes={{ container: classes.codesPanelContainer }}
          >
            <Box className={classes.panelHeader}>
              <Typography variant="h5">All Darkstores</Typography>
              <Box flex={1} />
              <ToggleButton />
            </Box>
            <Box className={classes.panelBody}>
              <Box
                display="flex"
                flexDirection="column"
                data-test="dark-stores-pane"
              >
                {darkstores?.map((darkstore, idx) => (
                  <DarkstoreItem
                    handleUpdateModal={handleUpdateModal}
                    editing={editing}
                    classes={classes}
                    darkstore={darkstore}
                    idx={idx}
                  />
                ))}
              </Box>
              <DarkstoreModal
                open={modalOpen}
                onSubmit={handleSubmit}
                onClose={handleCloseModal}
                activeDarkstoreId={activeDarkstoreId}
              />
            </Box>
          </Block>
        </Grid>
      </Grid>
    </Box>
  )
}

DarkstoresPane.propTypes = {
  classes: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
  darkstores: PropTypes.arrayOf(PropTypes.object),
  showAll: PropTypes.bool,
  openModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onSubmit: PropTypes.func
}

export default withStyles(styles)(DarkstoresPane)
