import { collectionGroupListEndpoint } from 'api/endpoints'
import { useResourceListRQ } from 'hooks'

const useAdminCollectionGroupsList = params => {
  const {
    isFetching: isLoadingCollectionGroups,
    data: collectionGroups,
    hasData: hasCollectionGroups,
    hasNextPage: hasCollectionGroupsNext,
    fetchNextPage: fetchCollectionGroupsNext,
    meta: collectionGroupsMeta
  } = useResourceListRQ({
    endpoint: collectionGroupListEndpoint,
    params
  })

  return {
    isLoadingCollectionGroups,
    collectionGroups,
    hasCollectionGroups,
    hasCollectionGroupsNext,
    fetchCollectionGroupsNext,
    collectionGroupsMeta
  }
}

export default useAdminCollectionGroupsList
