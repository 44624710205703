import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { useAlerts, useAuth } from 'hooks'
import {
  makeObjectResolver,
  passwordStrengthSchema,
  makePasswordConfirm
} from 'constants/yupSchemas'
import { SIZE, COLOR } from 'constants/enums'
import { URL } from 'constants/navigation'
import Button from 'components/Button'
import TextField from 'components/TextField'
import LoginLayout from 'containers/auth/LoginLayout'

import styles from './LoginStyles'

const ResetPassword = ({ classes }) => {
  const params = useParams()
  const history = useHistory()

  const { resetPassword } = useAuth()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const { register, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    resolver: makeObjectResolver({
      password: passwordStrengthSchema,
      passwordConfirm: makePasswordConfirm('password')
    })
  })

  useEffect(() => {
    if (!params.token) {
      showAlertError('Password reset token is not valid.')
      history.push(URL.LOGIN)
    }
  }, [])

  const onSubmit = async ({ password }) => {
    try {
      await resetPassword(password, params.token)
      showAlertSuccess('Your password has been updated.')
      history.push(URL.LOGIN)
    } catch (e) {
      showAlertError('Reset token is expired or incorrect.')
      history.push(URL.FORGOT_PASSWORD)
    }
  }

  return (
    <LoginLayout title="Reset your password" subtitle="Select a new password.">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="password"
          placeholder="Password"
          fullWidth
          password
          inputRef={register}
          error={Boolean(errors.password?.message)}
          helperText={errors.password?.message}
        />
        <TextField
          name="passwordConfirm"
          placeholder="Confirm New Password"
          fullWidth
          password
          inputRef={register}
          error={Boolean(errors.passwordConfirm?.message)}
          helperText={errors.passwordConfirm?.message}
        />
        <Typography variant="body2" className={classes.requirements}>
          Requirements
        </Typography>
        <Box width={270}>
          <Typography
            variant="body2"
            className={classes.requirementsDescription}
          >
            Minimum 8 Characters, 1 Uppercase, 1 Number, 1 Special Character
          </Typography>
        </Box>
        <Button
          size={SIZE.large}
          color={COLOR.primary}
          fullWidth
          type="submit"
          className={classes.submit}
        >
          Reset password
        </Button>
      </form>
    </LoginLayout>
  )
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ResetPassword)
