import { ABSOLUTE_FILL } from 'constants/styles'

const jssStyles = theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    margin: 'auto'
  },
  title: {
    ...ABSOLUTE_FILL,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      color: 'white',
      textShadow: '2px 2px 1px rgba(0,0,0,0.1)',
      textAlign: 'center'
    }
  },
  overlay: {
    ...ABSOLUTE_FILL,
    padding: theme.spacing(1, 1, 2)
  },
  grab: {
    marginLeft: theme.spacing(-0.5),
    color: theme.palette.grays.dark,
    opacity: 0.4
  }
})

export default jssStyles
