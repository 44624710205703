export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.colorsAF.semiGray}`,
    backgroundColor: theme.palette.colorsAF.white,
    position: 'relative',
    overflow: 'hidden',
    margin: 'auto'
  },
  grab: {
    marginLeft: theme.spacing(-0.5),
    color: theme.palette.grays.dark,
    opacity: 0.4
  },
  title: {
    fontSize: 13,
    lineHeight: '16px'
  },
  subtitle: {
    padding: '2px 8px',
    backgroundColor: theme.palette.colorsAF.ultraLightGray
  },
  chip: {
    height: 20,
    margin: theme.spacing(0, 0.5),
    borderRadius: 4,
    '& .MuiChip-label': {
      padding: '3px 10px',
      fontSize: 11,
      lineHeight: '14px'
    }
  },
  pointer: {
    cursor: 'pointer',
    '& .MuiChip-root': {
      cursor: 'pointer'
    }
  },
  body: {
    display: 'flex'
  },
  preview: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    flexGrow: 1,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center'
  },
  previewCard: {
    width: 116,
    height: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      pointerEvents: 'none',
      objectFit: 'cover',
      width: 110,
      height: 110
    }
  },
  circleImage: {
    marginTop: theme.spacing(2),
    borderRadius: 55
  },
  previewBrand: {
    fontSize: 10,
    lineHeight: '8px',
    fontWeight: 400,
    marginTop: 10,
    marginBottom: 3
  },
  previewTitle: {
    fontSize: 9,
    lineHeight: '14px',
    marginBottom: 5
  },
  previewPrice: {
    fontSize: 9,
    lineHeight: '11px'
  }
})
