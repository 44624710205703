import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import DateRangePicker from 'components/DateRangePickerNew'
import Drawer from 'components/Drawer'

import styles from './ExportDrawerStyles'

const ExportDrawer = ({ classes, open, onClose, onExport }) => {
  const [customerReport, setCustomerReport] = useState(false)
  const [inventoryReport, setInventoryReport] = useState(false)
  const [paymentReport, setPaymentReport] = useState(false)
  const [dateRange, setDateRange] = useState([
    moment().startOf('day'),
    moment().endOf('day')
  ])

  useEffect(() => {
    setCustomerReport(false)
    setInventoryReport(false)
    setPaymentReport(false)
  }, [open])

  const drawerActions = (
    <Box>
      <Box mb={1.5}>
        <Typography variant="h6">
          Your download will start automatically
        </Typography>
      </Box>
      <Button
        disabled={!customerReport && !inventoryReport && !paymentReport}
        fullWidth
        onClick={() =>
          onExport(dateRange, customerReport, inventoryReport, paymentReport)
        }
      >
        Export Now
      </Button>
    </Box>
  )

  return (
    <Drawer
      className={classes.root}
      title="Export Brand Data"
      open={open}
      disableEnforceFocus
      actions={drawerActions}
      onClose={onClose}
    >
      <Box mb={1}>
        <Box mb={2}>
          <Typography variant="h6">Date Range</Typography>
        </Box>
        <DateRangePicker
          className={classes.dateRangePicker}
          name="dateRange"
          value={dateRange}
          onChange={newDateRange => setDateRange(newDateRange)}
          clearable={false}
        />
      </Box>

      <Box mt={5}>
        <Box mb={2}>
          <Typography variant="h6">I want to export</Typography>
        </Box>

        <Box display="flex" flexDirection="column">
          <Checkbox
            label="Customer Report"
            boundingBox
            onChange={checked => setCustomerReport(checked)}
          />
          <Checkbox
            label="Inventory Report"
            boundingBox
            onChange={checked => setInventoryReport(checked)}
          />
          <Checkbox
            label="Payment Report"
            boundingBox
            onChange={checked => setPaymentReport(checked)}
          />
        </Box>
      </Box>
    </Drawer>
  )
}

ExportDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired
}

export default withStyles(styles)(ExportDrawer)
