import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { URL } from 'constants/navigation'
import { useAdminGMV } from 'hooks'
import { useLocationsContext } from 'context'

import Header from 'components/Header'
import Layout from 'components/Layout'

import styles from './GMVStyles'

const GMV = ({ classes }) => {
  const { locationId } = useLocationsContext()
  const { data } = useAdminGMV({ location_id: locationId })

  return (
    <Layout>
      <Header
        breadcrumbs={[
          {
            title: 'GMV',
            link: URL.ADMIN_BOH
          }
        ]}
        title="Hours of Operation"
      />
      {data && (
        <Box display="flex" alignItems="center" p={4}>
          <Typography variant="h2" className={classes.gmv} data-test="gmv">
            {data.gmv}
          </Typography>
          <Box mr={1} />
          <Typography variant="overline" className={classes.overline}>
            Today&apos;s <br /> GMV
          </Typography>
        </Box>
      )}
    </Layout>
  )
}

GMV.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(GMV)
