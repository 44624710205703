import React from 'react'
import PropTypes from 'prop-types'

import { STATUS_DOT_VARIANTS } from 'constants/enums'
import { ORDER_STATES_TYPES } from 'constants/general'

import StatusDot from 'components/StatusDot'

const ReturnStatus = ({ className, order, filledBackground }) => {
  if (!order?.return_status) return null
  const getStatusDotProps = () => {
    // eslint-disable-next-line default-case
    switch (order?.return_status) {
      case ORDER_STATES_TYPES.returned: {
        return {
          variant: STATUS_DOT_VARIANTS.orange,
          text: 'Returned'
        }
      }
      case ORDER_STATES_TYPES.return_in_progress: {
        return {
          variant: STATUS_DOT_VARIANTS.red,
          text: 'Return In Progress',
          halfFilled: true
        }
      }
    }
  }

  return (
    <StatusDot
      className={className}
      {...getStatusDotProps()}
      filledBackground={filledBackground}
    />
  )
}

ReturnStatus.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object,
  filledBackground: PropTypes.bool
}

export default ReturnStatus
