import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { useMenu } from 'hooks'

import styles from './SizesStyles'

const SizesItem = ({ classes, size, onSetActive, onClickEdit, dataTest }) => {
  const { open, anchorEl, handleToggleMenu, handleClose } = useMenu()

  return (
    <TableRow data-test="sizing-item">
      <TableCell>{size.name}</TableCell>
      <TableCell>{size.variants_count} Products</TableCell>
      <TableCell>{moment(size.created_at).format('L')}</TableCell>
      <TableCell className={classes.statusCell}>
        <Box
          className={classNames({
            [classes.statusTag]: true,
            [classes.active]: size.active,
            [classes.inactive]: !size.active
          })}
          data-test={`${dataTest}-item-status-tag`}
        >
          {size.active ? 'Active' : 'Inactive'}
        </Box>
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={e => handleToggleMenu(e)}
          data-test={`${dataTest}-item-menu`}
        >
          <MoreVertIcon />
          <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
            {size.active ? (
              <MenuItem
                onClick={() => onSetActive(false)}
                data-test={`${dataTest}-item-status`}
              >
                Inactive
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => onSetActive(true)}
                data-test={`${dataTest}-item-status`}
              >
                Active
              </MenuItem>
            )}
            <MenuItem
              onClick={() => onClickEdit(size)}
              data-test={`${dataTest}-item-edit`}
            >
              Edit
            </MenuItem>
          </Menu>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

SizesItem.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.object,
  onSetActive: PropTypes.func,
  onClickEdit: PropTypes.func,
  dataTest: PropTypes.string
}

export default withStyles(styles)(SizesItem)
