import React from 'react'
import PropTypes from 'prop-types'

const DefaultFill = '#FFFFFF'
const DefaultWidth = 111
const DefaultHeight = 20

const FastAFLogo = ({ fill, width, height }) => {
  let useWidth = DefaultWidth
  let useHeight = DefaultHeight
  if (!width && height) {
    useWidth = 73 * (height / 16)
    useHeight = height
  } else if (width && !height) {
    useWidth = width
    useHeight = 16 * (width / 73)
  } else if (width && height) {
    useWidth = width
    useHeight = height
  }

  return (
    <svg
      width={useWidth}
      height={useHeight}
      viewBox="0 0 73 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8712 11.1687C35.8712 14.1519 33.5113 15.9105 29.6313 15.9105C25.6238 15.9105 23.0513 14.1101 23.0513 10.3627H26.6762C26.7081 11.9433 27.7923 12.8226 29.6101 12.8226C31.1515 12.8226 32.0125 12.1213 32.0125 11.1792C32.0125 10.2162 31.1515 9.83937 29.44 9.46255L27.8242 9.09619C25.2198 8.53094 23.4233 7.31672 23.4233 4.87782C23.4233 1.98881 25.9214 0 29.5782 0C33.2775 0 35.5736 2.04115 35.6055 5.0453H31.9806C31.8849 3.86248 31.1089 3.07742 29.5676 3.07742C28.0687 3.07742 27.3565 3.7578 27.3565 4.64753C27.3565 5.5896 28.377 5.95596 29.8333 6.28045L31.1302 6.57354C34.3192 7.26439 35.8712 8.7717 35.8712 11.1687ZM48.5538 15.5417L50.0766 12.428H55.4747L55.9263 15.5417H59.6462H59.9697H63.5279L64.4878 9.86814H70.5109L71.0277 6.72316H64.9941L65.5532 3.44234H72.4518L73.0003 0.161526H62.2199L59.805 14.5923L57.3021 0.161526H52.282L44.4789 15.5417H48.5538ZM55.0307 9.43984H51.523L54.2115 3.9335H54.2325L55.0307 9.43984ZM44.0597 3.52683V15.6261H40.2144V3.52683H35.46V0.246012H48.8141V3.52683H44.0597ZM3.84682 10.0345V15.708H0V0.327908H10.6833V3.60872H3.84682V6.88954H9.81567V10.0345H3.84682ZM13.3918 12.428L12.3941 15.5417H8.40324L13.6438 0.161526H18.6639L23.894 15.5417H19.7666L18.7899 12.428H13.3918ZM14.3482 9.43984H17.856L16.1336 3.9335H16.1126L14.3482 9.43984Z"
        fill={fill}
      />
    </svg>
  )
}

FastAFLogo.defaultProps = {
  fill: DefaultFill
}

FastAFLogo.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

export default FastAFLogo
