import React, { useCallback, useState } from 'react'

import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'
import Button from 'components/Button'
import DragNDropGrid from 'components/DragNDropGrid'
import DraggableCollection from 'components/DraggableCollection'
import { BUTTON_VARIANT, SIZE } from 'constants/enums'
import { DRAGGABLE_COLLECTION_DIMS } from 'constants/styles'
import { useControlledFormController } from 'hooks'
import { FORM_FIELDS } from 'constants/collectionGroups'
import AddCollectionGroupCollectionModal from './AddCollectionGroupCollectionModal'

const CollectionGroupCollections = () => {
  const [addCollectionModalOpen, setAddCollectionModalOpen] = useState(false)

  const {
    field: { onChange: setCollectionIds }
  } = useControlledFormController({
    name: FORM_FIELDS.COLLECTION_IDS
  })

  const {
    field: { onChange: setCollections, value: collections }
  } = useControlledFormController({
    name: FORM_FIELDS.COLLECTIONS,
    onChange: (ps, { setValue: setVal }) => {
      setCollectionIds(ps.map(p => p.id))
    }
  })

  const addCollections = newCollections => {
    setCollections([...collections, ...newCollections])
  }

  const getItemProps = useCallback(
    p => ({
      showPublishStatus: false
    }),
    [collections]
  )

  return (
    <>
      <Box display="flex" alignItems="end" flexDirection="column">
        <Button
          adaptive
          label="add collections button"
          startIcon={<AddIcon />}
          variant={BUTTON_VARIANT.outlined}
          size={SIZE.medium}
          onClick={() => setAddCollectionModalOpen(true)}
          dataTest="add-collections-button"
        >
          Add Collections
        </Button>
      </Box>
      <Box width="100%" mt={3}>
        <DragNDropGrid
          items={collections}
          setItems={setCollections}
          ItemComponent={DraggableCollection}
          getItemProps={getItemProps}
          dragAndRemoveMode
          {...DRAGGABLE_COLLECTION_DIMS}
        />
      </Box>

      <AddCollectionGroupCollectionModal
        open={addCollectionModalOpen}
        addCollections={addCollections}
        onClose={() => setAddCollectionModalOpen(false)}
      />
    </>
  )
}

export default CollectionGroupCollections
