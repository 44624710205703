import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { useAuthContext } from 'context'
import { useAuth } from 'hooks'
import {
  makeObjectResolver,
  emailSchema,
  passwordSchema
} from 'constants/yupSchemas'
import { SIZE, COLOR } from 'constants/enums'
import { URL } from 'constants/navigation'

import Button from 'components/Button'
import Link from 'components/Link'
import LoginLayout from 'containers/auth/LoginLayout'
import TextField from 'components/TextField'

import styles from './LoginStyles'

const Login = ({ classes }) => {
  const history = useHistory()
  const { logoutUser, loginUser } = useAuth()
  const { currentUser } = useAuthContext()

  const { register, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    resolver: makeObjectResolver({
      email: emailSchema,
      password: passwordSchema
    })
  })

  const onSubmit = async data => {
    const result = await loginUser(data)
    if (result) {
      history.push(URL.ADMIN_ORDERS)
    }
  }

  if (currentUser) {
    return (
      <LoginLayout title={`Logged in as ${currentUser.name}`} subtitle="">
        <Button
          size={SIZE.large}
          color={COLOR.primary}
          fullWidth
          onClick={logoutUser}
        >
          Log out
        </Button>
      </LoginLayout>
    )
  }

  return (
    <LoginLayout title="Log in" subtitle="Continue to the dashboard.">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="email"
          placeholder="Account Email"
          fullWidth
          inputRef={register}
          error={Boolean(errors.email?.message)}
          helperText={errors.email?.message}
          dataTest="email"
        />
        <TextField
          name="password"
          placeholder="Password"
          fullWidth
          password
          inputRef={register}
          error={Boolean(errors.password?.message)}
          helperText={errors.password?.message}
          dataTest="password"
        />
        <div>
          <Link dataTest="forgotPassword" to={URL.FORGOT_PASSWORD}>
            Forgot password?
          </Link>
        </div>

        <Button
          size={SIZE.large}
          color={COLOR.primary}
          fullWidth
          type="submit"
          className={classes.submit}
          dataTest="submit-login"
        >
          Log in
        </Button>
      </form>
    </LoginLayout>
  )
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Login)
