import React from 'react'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import moment from 'moment-timezone'
import styles from './PaneStyles'
import classNames from 'classnames'

const SENT_STATUS = 'sent'
const SENDING_STATUS = 'sending'
const PENDING_STATUS = 'pending'
const ALL_STATUSES = [SENT_STATUS, SENDING_STATUS, PENDING_STATUS]

const NotificationsPaneRow = ({ classes, notification }) => {
  const status = notification?.status || "No Status";

  return (
    <TableRow className={classes.row}>
      <TableCell>{notification?.title}</TableCell>
      <TableCell>{notification?.path_link}</TableCell>
      <TableCell>{moment(notification?.created_at).format('MM/DD/YYYY')}</TableCell>
      <TableCell>
        <Box className={classNames({
          [classes.statusLabel]: true,
          [classes.statusLabelSent]: status === SENT_STATUS,
          [classes.statusLabelSending]: status === SENDING_STATUS,
          [classes.statusLabelPending]: status === PENDING_STATUS,
          [classes.statusLabelError]: !status || !ALL_STATUSES.includes(status),
        })}>{status}</Box>
      </TableCell>
    </TableRow>
  )
}


export default withStyles(styles)(NotificationsPaneRow)
