export default theme => ({
  content: {
    padding: theme.spacing(2),
    width: 320,
    backgroundColor: theme.palette.background.default
  },
  anchorContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1.5),
    cursor: 'pointer'
  },
  anchorText: {
    minWidth: 120,
    fontWeight: 400,
    flex: 1,
    color: theme.palette.grays.darkest
  },
  anchorIcon: {
    height: 18,
    fill: theme.palette.grays.dark,
    fontSize: 12
  }
})
