import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { CHECKBOX_VARIANT } from 'constants/enums'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'

import Checkbox from 'components/Checkbox'

import styles from './CheckboxGroupStyles'

const CheckboxGroup = ({ classes, value, onChange, options, ...rest }) => {
  const handleCheckboxChange = newValue => {
    onChange &&
      onChange({
        ...value,
        ...newValue
      })
  }

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="flexStart"
      flexDirection="column"
      {...rest}
    >
      {options.map(({ label: thisLabel, value: thisValue }) => (
        <FormControlLabel
          key={thisLabel}
          control={
            <Checkbox
              onChange={v => handleCheckboxChange({ [thisValue]: v })}
              checked={Boolean(value?.[thisValue])}
              variant={CHECKBOX_VARIANT.default}
            />
          }
          label={thisLabel}
          className={classes.labeledCheckbox}
        />
      ))}
    </Box>
  )
}

CheckboxGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
}

export default withStyles(styles)(CheckboxGroup)
