import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { uniqBy } from 'lodash'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import { SIZE, PRODUCT_RESULTS_POPOVER_TYPES } from 'constants/enums'
import { useAdminVariantsRQ } from 'hooks'

import Button from 'components/Button'
import Drawer from 'components/Drawer'
import ProductSummary from 'components/ProductSummary'
import TextField from 'components/TextField'
import ProductResultsPopover from 'components/ProductResultsPopover'

import styles from './TransferOrderShipmentItemsDrawerStyles'

const TransferOrderShipmentItemsDrawer = ({
  classes,
  open,
  onClose,
  onSubmit,
  orderShipmentItems
}) => {
  const [searchString, setSearchString] = useState('')
  const [selectedVariants, setSelectedVariants] = useState([])
  const [quantities, setQuantities] = useState({})

  const { variants } = useAdminVariantsRQ({
    params: {
      ...{ search: searchString }
    }
  })

  const handleClose = () => {
    setSelectedVariants([])
    setSearchString('')
    onClose && onClose()
  }

  const handleSearchChange = e => {
    setSearchString(e.target.value)
  }

  const handleSubmit = () => {
    handleClose()
    onSubmit(
      selectedVariants
        .filter(v => quantities[v.id])
        .map(v => {
          return { id: v.id, quantity: quantities[v.id] }
        })
    )
  }

  const handleAddVariant = item => {
    setSelectedVariants(uniqBy([...selectedVariants, item], v => v.id))
  }

  const handleRemoveVariant = currVariant => {
    setSelectedVariants(
      selectedVariants.filter(variant => variant.id !== currVariant.id)
    )
  }

  const handleQuantityChange = (id, val) => {
    const num = parseInt(val)
    if (num >= 0) {
      setQuantities({ ...quantities, [id]: num })
    } else {
      setQuantities({ ...quantities, [id]: '' })
    }
  }

  const drawerActions = (
    <Button
      fullWidth
      disabled={
        selectedVariants.length === 0 ||
        !selectedVariants.every(v => quantities[v.id] && quantities[v.id] > 0)
      }
      onClick={handleSubmit}
      dataTest="add-to-to-button"
    >
      Add to TO
    </Button>
  )

  return (
    <Drawer
      title="Add Products"
      open={open}
      onClose={handleClose}
      actions={drawerActions}
    >
      <Box className={classes.container}>
        <Box mb={1}>
          <Typography variant="h6">Search Products</Typography>
        </Box>

        <ProductResultsPopover
          options={variants}
          selectedOptions={selectedVariants}
          onTextChange={handleSearchChange}
          onAddItem={handleAddVariant}
          onRemoveItem={handleRemoveVariant}
          activeProducts={orderShipmentItems.map(({ variant }) => variant)}
          type={PRODUCT_RESULTS_POPOVER_TYPES.po}
        />

        <Box mt={4}>
          {selectedVariants.map((variant, i) => (
            <Box key={variant.id} className={classes.selectedItem}>
              <Box className={classes.productSummary}>
                <ProductSummary product={variant} showPrice />
              </Box>
              <Box className={classes.itemActions}>
                <IconButton
                  edge="end"
                  onClick={() => handleRemoveVariant(variant)}
                >
                  <RemoveCircleIcon />
                </IconButton>
                <Box className={classes.quantity}>
                  <Box mr={1}>Qty</Box>
                  <TextField
                    dataTest={'product-quantity' + i}
                    size={SIZE.small}
                    value={quantities[variant.id] || ''}
                    onChange={e =>
                      handleQuantityChange(variant.id, e.target.value)
                    }
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  )
}

TransferOrderShipmentItemsDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  location: PropTypes.string,
  orderShipmentItems: PropTypes.arrayOf(PropTypes.object)
}

export default withStyles(styles)(TransferOrderShipmentItemsDrawer)
