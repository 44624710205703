const jssStyles = theme => ({
  inputCaption: {
    paddingBottom: theme.spacing(1)
  },
  checkbox: {
    '& .MuiFormControlLabel-label': {
      paddingLeft: theme.spacing(1)
    }
  }
})

export default jssStyles
