import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { formatCurrency } from 'utils/general'

import styles from './CurrencyStyles'

const Currency = ({ classes, val }) => {
  const formatted = formatCurrency(Number(val, 2))
  const symbol = formatted.charAt(0)

  // TODO:extend intl format catcher
  if (symbol === '$') {
    return (
      <>
        <span className={classes.symbol}>{symbol}</span>
        {formatted.substring(1)}
      </>
    )
  }

  return formatted
}

Currency.defaultProps = {
  val: 0
}

Currency.propTypes = {
  classes: PropTypes.object.isRequired,
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default withStyles(styles)(Currency)
