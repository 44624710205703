import React, { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'

import FabButton from 'components/FabButton'
import EditIcon from 'icons/EditIcon'
import CheckThinIcon from 'icons/CheckThinIcon'

const useToggleButton = () => {
  const theme = useTheme()
  const [editing, setEditing] = useState(false)

  const Component = () => (
    <FabButton
      onClick={() => setEditing(!editing)}
      style={{
        backgroundColor: editing
          ? theme.palette.primary.main
          : theme.palette.grays.lightest
      }}
    >
      {editing ? <CheckThinIcon /> : <EditIcon />}
    </FabButton>
  )

  return { editing, Component }
}

export default useToggleButton
