import React from 'react'
import PropTypes from 'prop-types'

const EyeIcon = ({ fill, size, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 18"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.00123 9.94204C1.66626 9.35862 1.66626 8.64148 2.00122 8.05805C3.76372 4.98823 6.58284 3 9.75985 3C12.9368 3 15.7559 4.9882 17.5184 8.05799C17.8534 8.64142 17.8534 9.35855 17.5184 9.94198C15.7559 13.0118 12.9368 15 9.75981 15C6.58283 15 3.76373 13.0118 2.00123 9.94204Z"
      stroke={fill}
      strokeWidth="2"
    />
    <circle cx="9.75977" cy="9" r="2.25" stroke={fill} strokeWidth="2" />
  </svg>
)

EyeIcon.defaultProps = {
  size: 19,
  fill: 'currentColor'
}

EyeIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string
}

export default EyeIcon
