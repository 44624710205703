export default theme => ({
  taxCategoryCode: {
    fontSize: 10
  },
  taxCategoryName: {},
  selectedItem: {
    '& $taxCategoryCode': {
      fontWeight: 700
    },
    '& $taxCategoryName': {
      color: theme.palette.common.black
    }
  }
})
