import React, { useState, useCallback } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'

import Box from '@material-ui/core/Box'
import CreateIcon from '@material-ui/icons/Create'

import { ICON_BUTTON_VARIANT, DRAG_N_DROP_MODE } from 'constants/enums'
import { DRAGGABLE_COLLECTION_DIMS } from 'constants/styles'
import { useLocationsContext } from 'context'
import { useControlledFormController } from 'hooks'
import Button from 'components/Button'
import IconButton from 'components/IconButton'
import DragNDropGrid from 'components/DragNDropGrid'
import DraggableCollection from 'components/DraggableCollection'
import AddCollectionChildDrawer from 'components/AddCollectionChildDrawer'
import CheckIcon from 'icons/CheckIcon'

import { FORM_FIELDS } from './CollectionFormFields'
import styles from './CollectionChildrenStyles'

const CollectionChildren = ({ classes, collection }) => {
  const [dndMode, setDndMode] = useState(DRAG_N_DROP_MODE.ORDERING)
  const [addChildOpen, setAddChildOpen] = useState(false)

  const { locationId } = useLocationsContext()

  const removeMode = dndMode === DRAG_N_DROP_MODE.REMOVE
  const toggleRemoveMode = () =>
    dndMode === DRAG_N_DROP_MODE.REMOVE
      ? setDndMode(DRAG_N_DROP_MODE.ORDERING)
      : setDndMode(DRAG_N_DROP_MODE.REMOVE)

  const {
    field: { onChange: setOrderedChildrenIds }
  } = useControlledFormController({
    name: FORM_FIELDS.CHILDREN_IDS
  })
  const {
    field: { onChange: setOrderedChildren, value: orderedChildren }
  } = useControlledFormController({
    name: FORM_FIELDS.CHILDREN,
    onChange: children => {
      setOrderedChildrenIds(children.map(child => child.id))
    }
  })

  const handleAddChildSubmit = productCollection => {
    setOrderedChildren(
      uniqBy(orderedChildren.concat(productCollection), p => p.id)
    )
  }

  const getItemProps = useCallback(
    () => ({
      locationId,
      showMetaData: true
    }),
    [locationId]
  )

  return (
    <Box mt={6}>
      <Box mt={3} alignItems="flex-end" flexDirection="row">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flexDirection="row"
          flexWrap="wrap"
          className={classes.actions}
        >
          <Button
            className={classes.grayButton}
            variant="outlined"
            size="medium"
            onClick={() => setAddChildOpen(true)}
          >
            + Collection
          </Button>
          <IconButton
            size={44}
            variant={
              removeMode
                ? ICON_BUTTON_VARIANT.blue
                : ICON_BUTTON_VARIANT.outlined
            }
            onClick={toggleRemoveMode}
          >
            {removeMode ? (
              <CheckIcon fontSize={12} />
            ) : (
              <CreateIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
      </Box>

      <Box width="100%" mt={3}>
        <DragNDropGrid
          items={orderedChildren}
          setItems={setOrderedChildren}
          ItemComponent={DraggableCollection}
          getItemProps={getItemProps}
          mode={dndMode}
          {...DRAGGABLE_COLLECTION_DIMS}
        />
      </Box>

      <AddCollectionChildDrawer
        open={addChildOpen}
        activeCollections={(orderedChildren ?? []).concat(collection)}
        onClose={() => setAddChildOpen(false)}
        onSubmit={handleAddChildSubmit}
      />
    </Box>
  )
}

CollectionChildren.propTypes = {
  classes: PropTypes.object.isRequired,
  collection: PropTypes.object.isRequired
}

export default withStyles(styles)(CollectionChildren)
