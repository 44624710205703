import { useResource, useResourceRQ } from 'hooks'

import { API_PARAMS } from 'constants/queryParams'
import { storefrontsEndpoint } from 'api/endpoints'

const API_ADMIN_STOREFRONT = '/api/v1/admin/storefronts'
const STOREFRONT = 'storefront'

const useAdminStorefront = ({ id }) => {
  const { read: rawFetchStorefront, data: storefront, isLoading } = useResource(
    {
      url: API_ADMIN_STOREFRONT,
      name: STOREFRONT
    }
  )

  const { update: updateStorefrontRaw } = useResource({
    url: `${API_ADMIN_STOREFRONT}/${id}`,
    name: STOREFRONT
  })

  const { destroy: deleteStoreFront } = useResourceRQ({
    endpoint: storefrontsEndpoint,
    args: [id],
    fetchingEnabled: false,
    dependents: [
      {
        func: storefrontsEndpoint
      }
    ]
  })

  const fetchStorefront = storefrontId => rawFetchStorefront(storefrontId)

  const updateStorefront = (newStorefront, locationId) =>
    updateStorefrontRaw(newStorefront, {
      [API_PARAMS.LOCATION]: locationId
    })

  return {
    storefront,
    isLoading,
    fetchStorefront,
    updateStorefront,
    deleteStoreFront
  }
}

export default useAdminStorefront
