import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { keyBy, isEmpty } from 'lodash'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import ReorderIcon from 'icons/ReorderIcon'
import SaveIcon from '@material-ui/icons/Save'
import AddIcon from '@material-ui/icons/Add'

import { useLocationsContext } from 'context'
import {
  useAdminProducts,
  useAdminVariants,
  useAlerts,
  useLoaders,
  useBeforeUnload,
  useNavigation,
  useMenu,
  useMediaQuery,
  useConditionalEffect
} from 'hooks'
import { stockSummary, getProductImageUrls } from 'utils/products'
import { EXISTING_PRODUCT_SCHEMA, FORM_FIELDS } from 'constants/products'
import {
  SIZE,
  CHECKBOX_VARIANT,
  COLOR,
  STATUS_DOT_VARIANTS
} from 'constants/enums'
import { URL } from 'constants/navigation'

import { AutocompleteSearchBadgesControlled } from 'components/AutocompleteSearchBadges'
import { AutocompleteSearchBrandsControlled } from 'components/AutocompleteSearchBrands'
import { AutocompleteSearchParentCategoriesControlled } from 'components/AutocompleteSearchParentCategories'
import { AutocompleteSearchProductTagsControlled } from 'components/AutocompleteSearchProductTags'
import { AutocompleteSearchStatusCodesControlled } from 'components/AutocompleteSearchStatusCodes'
import { AutocompleteSearchSubCategoriesControlled } from 'components/AutocompleteSearchSubCategories'
import { AutocompleteSearchTaxCodesControlled } from 'components/AutocompleteSearchTaxCodes'
import { AutocompleteSelectLocationsControlled } from 'components/AutocompleteSelectLocations'
import { CheckboxBlockControlled } from 'components/CheckboxBlock'
import { MarkdownEditorControlled } from 'components/MarkdownEditor'
import ProductIngredientFields from 'components/ProductIngredientFields'
import ProductNutriFactFields from 'components/ProductNutriFactFields'
import { TextFieldControlled } from 'components/TextField'
import { TitleElement } from 'components/SiteTitle'
import Block from 'components/Block'
import BulkUpdateVariantModal from 'components/BulkUpdateVariantModal'
import Button from 'components/Button'
import Checkbox, { CheckboxField } from 'components/Checkbox'
import ControlledForm, { useControlledForm } from 'components/ControlledForm'
import DataTable from 'components/DataTable'
import Dialog from 'components/Dialog'
import Header from 'components/Header'
import Layout from 'components/Layout'
import MultiImageDropzone from 'components/MultiImageDropzone'
import ProductAccessCodesPanel from 'components/ProductAccessCodesPanel'
import ReorderImagesDialog from 'components/ReorderImagesDialog'
import ReorderVariantsDialog from 'components/ReorderVariantsDialog'
import SearchInput from 'components/SearchInput'
import SetPDPBlockedButton from 'components/SetPDPBlockedButton'
import StatusDot from 'components/StatusDot'
import Tabs from 'components/Tabs'
import VariantItem from 'components/VariantItem'

import styles from './ProductShowStyles'

const ALL_LOCATION_UPDATE_MSG =
  'To update a variant or set similar products, please first select a specific Darkstore location.'

const ALL_LOCATIONS_COLUMNS = [
  { title: 'Variant' },
  { title: 'SKU' },
  { title: 'UPC' },
  { title: 'Dimensions' },
  { title: 'Size' },
  { title: 'Archived?' },
  { title: 'Holiday' }
]

const SPECIFIC_LOCATION_COLUMNS = [
  ...ALL_LOCATIONS_COLUMNS,
  { title: 'Do Not Buy' },
  { title: 'Bin' },
  { title: 'Quantity' }
]

export const AutocompleteSelectCategoriesControlled = () => {
  const {
    formContext: { getValues, setValue }
  } = useControlledForm()
  const parentCategory = getValues(FORM_FIELDS.PARENT_CATEGORY)
  const parentCategoryId = parentCategory?.id

  useConditionalEffect(() => {
    if (!parentCategoryId) {
      setValue(FORM_FIELDS.SUB_CATEGORY, {})
    }
  }, [parentCategoryId])

  return (
    <>
      <Box mx={0} mb={0.5}>
        <Typography variant="caption" color="textSecondary">
          Parent Category
        </Typography>
        <AutocompleteSearchParentCategoriesControlled
          name={FORM_FIELDS.PARENT_CATEGORY}
        />
      </Box>
      {parentCategoryId && (
        <Box mx={0} mb={0.5}>
          <Typography variant="caption" color="textSecondary">
            Subcategory
          </Typography>
          <AutocompleteSearchSubCategoriesControlled
            name={FORM_FIELDS.SUB_CATEGORY}
            parentCategoryId={parentCategoryId}
            clearOnParentCategoryIdClear
          />
        </Box>
      )}
    </>
  )
}

const SaveButton = () => {
  const { readyToSave, handleSubmit } = useControlledForm()
  const { OnBeforeUnloadPrompt } = useBeforeUnload(readyToSave)
  return (
    <>
      <Button
        label="save product changes button"
        onClick={handleSubmit}
        size={SIZE.medium}
        color={COLOR.primary}
        dataTest="po-save-button"
        startIcon={<SaveIcon />}
        adaptive
        disabled={!readyToSave}
        type="submit"
      >
        Save
      </Button>
      <OnBeforeUnloadPrompt />
    </>
  )
}

const getDefaultFormValues = (product = {}, locations = []) => ({
  [FORM_FIELDS.TITLE]: product?.title ?? '',
  [FORM_FIELDS.HANDLE]: product?.handle ?? '',
  [FORM_FIELDS.DESCRIPTION]: product?.description ?? '',
  [FORM_FIELDS.USAGE_INSTRUCTIONS]: product?.usage_instructions,
  [FORM_FIELDS.CONSIGNMENT]: product?.consignment ?? '',
  [FORM_FIELDS.LOCKED]: product?.locked ?? false,
  [FORM_FIELDS.AGES_21_AND_OVER]: product?.ages_21_and_over ?? false,
  [FORM_FIELDS.RESTOCK_NOTIFICATIONS_ENABLED]:
    product?.restock_notifications_enabled ?? true,
  [FORM_FIELDS.LOCATION_IDS]: product?.location_ids
    ? locations?.filter(loc => product.location_ids.includes(loc.id))
    : [],
  [FORM_FIELDS.VENDOR_STYLE_NUMBER]: product?.vendor_style_number ?? '',
  [FORM_FIELDS.VENDOR_STYLE_NUMBER_IS_UNIVERSAL]:
    product?.vendor_style_number_is_universal ?? false,
  [FORM_FIELDS.TAX_CATEGORY_ID]: product?.tax_category ?? {},
  [FORM_FIELDS.BLACKLISTED_PRODUCT_RECOMMENDATION_IDS]:
    product?.black_listed_recommened_product_ids ?? [],
  [FORM_FIELDS.MAX_ORDER_QUANTITY]: product?.max_purchase_quantity ?? '',
  [FORM_FIELDS.BADGES]: product?.badges ?? [],
  [FORM_FIELDS.STATUS_CODE]: product?.status_code ?? {},
  [FORM_FIELDS.TAGS]: product?.tags ?? [],
  [FORM_FIELDS.EXCLUDE_FROM_PROMOS]: product?.exclude_from_all_promo_codes,
  [FORM_FIELDS.PARENT_CATEGORY]: product?.category ?? null,
  [FORM_FIELDS.SUB_CATEGORY]: product?.subcategory ?? null,
  [FORM_FIELDS.BRAND]: product?.brand_object || {},
  [FORM_FIELDS.VARIANT_OPTION_SIZES_ORDER]: product?.size_options ?? [],
  [FORM_FIELDS.VARIANT_OPTION_COLORS_ORDER]: product?.color_options ?? [],
  [FORM_FIELDS.VARIANT_OPTION_ACCESSORIES_ORDER]:
    product?.accessory_option_ids ?? [],
  [FORM_FIELDS.ALLOW_STORE_CREDIT]: product?.allow_store_credit ?? true,
  [FORM_FIELDS.SERVING_SIZE]: product?.nutrition_fact?.serving_size ?? '',
  [FORM_FIELDS.SERVINGS_PER_CONTAINER]:
    product?.nutrition_fact?.servings_per_container ?? '',
  [FORM_FIELDS.CALORIES_PER_SERVING]:
    product?.nutrition_fact?.calories_per_serving ?? '',
  [FORM_FIELDS.FAT_CALORIES_PER_SERVING]:
    product?.nutrition_fact?.calories_from_fat_per_serving ?? '',
  [FORM_FIELDS.INGREDIENTS]: product?.nutrition_fact?.ingredients ?? '',
  [FORM_FIELDS.ALLERGENS]: product?.nutrition_fact?.allergens ?? '',
  [FORM_FIELDS.NUTRITION_FACTS]: {
    data: product?.nutrition_fact?.nutrient_lines ?? [],
    lastUpdate: ''
  }, // lastUpdate allows changes to data obj to be seen
  [FORM_FIELDS.IMAGES]: getProductImageUrls(product, 'product')
})

const ProductStatusDot = ({ status }) => {
  if (!status || status === 'live') return null
  const { label, indicator } = [
    ...[
      {
        value: 'archived',
        label: 'Archived',
        indicator: STATUS_DOT_VARIANTS.red
      }
    ]
  ].find(t => t?.value === status)

  return <StatusDot text={label} variant={indicator} />
}

ProductStatusDot.propTypes = {
  status: PropTypes.string
}

const ProductShow = ({
  classes,
  match: {
    params: { id }
  }
}) => {
  const {
    product,
    readProduct,
    updateProduct,
    generateNutritionFactsRequestBody,
    createNutritionFacts,
    updateNutritionFacts,
    getAllNutrientOptions,
    addAccessCodes,
    updateAccessCodeAvailability,
    deleteAccessCode,
    uploadProductImages,
    deleteProduct,
    archiveProduct,
    restoreProduct
  } = useAdminProducts({ id })
  const { bulkUpdateVariants } = useAdminVariants({})
  const { showAlertGeneral, showAlertError } = useAlerts()
  const {
    locationId,
    locations = [],
    showAllLocationIds
  } = useLocationsContext()
  const { showLoading, hideLoading, isLoading } = useLoaders()

  const {
    open: historyMenuOpen,
    anchorEl: historyMenuAnchorEl,
    handleToggleMenu: handleHistoryMenuToggle,
    handleClose: handleHistoryMenuClose
  } = useMenu()

  const { isMobileScreen } = useMediaQuery()

  const { go, handleShowDetailsClick } = useNavigation({
    url: URL.ADMIN_PRODUCTS
  })

  const [defaultFormValues, setDefaultFormValues] = useState(
    getDefaultFormValues()
  )

  const [updateDrawerOpen, setUpdateDrawerOpen] = useState(false)
  const [variantSelections, setVariantSelections] = useState({})
  const [showImageOrderPopup, setShowImageOrderPopup] = useState(false)
  const [showVariantOrderDialog, setShowVariantOrderDialog] = useState(false)

  const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState(false)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)

  const [variantSearchQuery, setVariantSearchQuery] = useState('')
  const [queryRegex, setVariantQueryRegex] = useState()
  const [filteredVariants, setFilteredVariants] = useState([])

  const searchByQuery = variant =>
    !variantSearchQuery || variant.title?.search(queryRegex) > -1

  useConditionalEffect(() => {
    if (isEmpty(product)) {
      return
    }

    setFilteredVariants(
      product?.variants?.filter(variant => searchByQuery(variant))
    )
  }, [product, queryRegex])

  const readProductWithLocation = async productId => {
    await readProduct(productId, locationId ? { location_id: locationId } : {})
  }

  const reloadProduct = async productId =>
    (productId || productId === 0) && (await readProductWithLocation(id))

  useEffect(() => {
    window && window.scrollTo(0, 0)
    showLoading()
    reloadProduct(id)
    hideLoading()
  }, [locationId])

  useConditionalEffect(() => {
    if (product && locations.length > 0)
      setDefaultFormValues(getDefaultFormValues(product, locations))
  }, [product, locations])

  useConditionalEffect(
    () => showAllLocationIds && showAlertGeneral(ALL_LOCATION_UPDATE_MSG),
    [locationId]
  )

  const handleSave = async productUpdates => {
    showLoading()

    const nutritionFactId = product?.nutrition_fact?.id
    const nutritionParams = {
      product_id: product?.id,
      ...generateNutritionFactsRequestBody(productUpdates)
    }

    !!nutritionFactId
      ? await updateNutritionFacts(nutritionFactId, nutritionParams)
      : await createNutritionFacts(nutritionParams)
    delete productUpdates[FORM_FIELDS.INGREDIENTS]
    delete productUpdates[FORM_FIELDS.ALLERGENS]
    delete productUpdates[FORM_FIELDS.SERVING_SIZE]
    delete productUpdates[FORM_FIELDS.SERVINGS_PER_CONTAINER]
    delete productUpdates[FORM_FIELDS.CALORIES_PER_SERVING]
    delete productUpdates[FORM_FIELDS.FAT_CALORIES_PER_SERVING]
    delete productUpdates[FORM_FIELDS.NUTRITION_FACTS]

    const locationIds = productUpdates.location_ids.map(loc => loc.id)

    if (locationIds?.length && !productUpdates[FORM_FIELDS.DESCRIPTION]) {
      showAlertError(
        'Description is required when publishing a product to a location.'
      )
      hideLoading()
      return
    }

    const taxCategoryId = productUpdates.tax_category_id.id || null
    const badgeIds = productUpdates.badges.map(badge => badge.id)
    const status_code_id = productUpdates.status_code.id
    const category_id = productUpdates?.category?.id ?? null
    const subcategory_id = productUpdates?.subcategory?.id ?? null
    const tag_ids = productUpdates.tags.map(tag => tag.id)
    const brandId = productUpdates.brand.id
    const orderOptions = {
      color_option_ids: productUpdates[
        FORM_FIELDS.VARIANT_OPTION_COLORS_ORDER
      ].map(i => i.id),
      size_option_ids: productUpdates[
        FORM_FIELDS.VARIANT_OPTION_SIZES_ORDER
      ].map(i => i.id),
      accessory_option_ids: productUpdates[
        FORM_FIELDS.VARIANT_OPTION_ACCESSORIES_ORDER
      ].map(i => i.id)
    }

    const deletions = [
      FORM_FIELDS.LOCATION_IDS,
      FORM_FIELDS.TAX_CATEGORY_ID,
      FORM_FIELDS.HANDLE,
      FORM_FIELDS.BADGES,
      FORM_FIELDS.STATUS_CODE,
      FORM_FIELDS.PARENT_CATEGORY,
      FORM_FIELDS.SUB_CATEGORY,
      FORM_FIELDS.TAGS,
      FORM_FIELDS.BRAND,
      FORM_FIELDS.VARIANT_OPTION_COLORS_ORDER,
      FORM_FIELDS.VARIANT_OPTION_SIZES_ORDER,
      FORM_FIELDS.VARIANT_OPTION_ACCESSORIES_ORDER
    ]

    deletions.forEach(deletion => delete productUpdates[deletion])

    await updateProduct(product?.id, {
      ...productUpdates,
      ...orderOptions,
      tag_ids,
      status_code_id,
      badge_ids: badgeIds,
      brand_id: brandId,
      location_ids: locationIds,
      tax_category_id: taxCategoryId,
      category_id,
      subcategory_id
    })

    await readProductWithLocation(id)
    hideLoading()
  }

  const handleDeleteProduct = async () => {
    showLoading()
    await deleteProduct(product)
    setShowConfirmDeleteModal(false)
    go({}, URL.ADMIN_PRODUCTS)
    hideLoading()
  }

  const handleArchiveProduct = async () => {
    showLoading()
    await archiveProduct(product?.id)
    await readProductWithLocation(product?.id)
    setShowConfirmArchiveModal(false)
    hideLoading()
  }

  const handleRestoreProduct = async () => {
    showLoading()
    await restoreProduct(product?.id)
    await readProductWithLocation(product?.id)
    setShowConfirmArchiveModal(false)
    hideLoading()
  }

  const handleDeleteAccessCodes = async codesToDelete =>
    await Promise.all(codesToDelete.map(i => deleteAccessCode(i)))

  const handleClickManageVariants = e => {
    if (showAllLocationIds) {
      // stop propagation is necessary to not trigger alert's Snackbar's ClickAway handler
      e.stopPropagation()
      showAlertGeneral(ALL_LOCATION_UPDATE_MSG)
      return
    }

    setUpdateDrawerOpen(true)
  }

  const handleBulkVariantsUpdate = async args => {
    showLoading()
    setVariantSelections({})
    await bulkUpdateVariants(args)
    await readProductWithLocation(id)
    hideLoading()
  }

  const handleVariantSelect = (variant, isSelected) => {
    const clone = { ...variantSelections }
    if (isSelected) {
      clone[variant.id] = variant
    } else {
      delete clone[variant.id]
    }
    setVariantSelections(clone)
  }

  const handleSelectAllVariants = isChecked => {
    if (isChecked) {
      setVariantSelections(keyBy(filteredVariants, v => v.id))
    } else {
      setVariantSelections({})
    }
  }

  const handleVariantClick = (e, variant) => {
    handleShowDetailsClick(e, product, `variant/${variant.id}`)
  }

  const handleProductImageUpload = async (files, cb) => {
    showLoading()
    const product = await uploadProductImages(id, files)
    cb(product, 'product')
    hideLoading()
  }

  const allVariantsSelected =
    filteredVariants &&
    Object.keys(variantSelections).length === filteredVariants.length

  const variantHeaderActions = (
    <Box display="flex" alignItems="center">
      <Checkbox
        variant={CHECKBOX_VARIANT.light}
        onChange={handleSelectAllVariants}
        indeterminate={!allVariantsSelected}
        value={allVariantsSelected}
      />
      <Typography variant="body2">Select All</Typography>
      <Button size={SIZE.small} onClick={handleClickManageVariants}>
        Manage
      </Button>
    </Box>
  )

  const stockData = stockSummary(product, locationId)
  const disableVariantCreate =
    product?.accessory_options?.length === 0 &&
    product?.size_options?.length === 0 &&
    product?.color_options?.length === 0

  return (
    <Layout id="product-detail">
      <TitleElement title={product ? product.title : 'Loading...'} />
      <ControlledForm
        handleSubmit={handleSave}
        schemas={EXISTING_PRODUCT_SCHEMA}
        defaultValues={defaultFormValues}
        resetOnSubmit={false}
      >
        <Box>
          <Box className={classes.headerBox}>
            <Header
              sticky
              breadcrumbs={[{ title: 'Products', link: URL.ADMIN_PRODUCTS }]}
              title={product?.title ?? 'Loading'}
              actions={
                <>
                  <Box
                    className={classes.historyMenuButton}
                    onClick={e => handleHistoryMenuToggle(e)}
                  >
                    <MoreVertIcon />
                    <Menu
                      open={historyMenuOpen}
                      onClose={handleHistoryMenuClose}
                      anchorEl={historyMenuAnchorEl}
                    >
                      <MenuItem
                        onClick={e =>
                          go(e, `${URL.ADMIN_PRODUCTS}/${product?.id}/history`)
                        }
                      >
                        View History
                      </MenuItem>
                      {product?.can_delete && (
                        <MenuItem
                          onClick={e => setShowConfirmDeleteModal(true)}
                        >
                          Delete
                        </MenuItem>
                      )}
                      {product?.status === 'live' && (
                        <MenuItem
                          onClick={e => setShowConfirmArchiveModal(true)}
                        >
                          Archive
                        </MenuItem>
                      )}
                      {product?.status === 'archived' && (
                        <MenuItem
                          onClick={e => setShowConfirmArchiveModal(true)}
                        >
                          Unarchive
                        </MenuItem>
                      )}
                    </Menu>
                  </Box>
                  <SetPDPBlockedButton />
                  <SaveButton />
                </>
              }
            />
          </Box>

          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid item sm={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mx={1}
              >
                <Typography variant="body2">
                  Updated {moment(product?.updated_at).calendar()}
                </Typography>
                <ProductStatusDot status={product?.status} />
              </Box>
            </Grid>
            <Grid item sm={12} md={6} className={classes.gridItem}>
              <Block withPadding>
                <Box>
                  <Typography variant="h5">Name</Typography>
                  <TextFieldControlled
                    className={classes.thinInput}
                    name={FORM_FIELDS.TITLE}
                    fullWidth
                  />
                </Box>
                <Box>
                  <Typography variant="h5">Handle</Typography>
                  <TextFieldControlled
                    className={classes.thinInput}
                    name={FORM_FIELDS.HANDLE}
                    fullWidth
                    disabled
                  />
                </Box>
              </Block>
              <Block>
                <Box className={classes.paddingBlock}>
                  <Typography variant="h5">Media</Typography>
                </Box>
                <MultiImageDropzone
                  autoUpload
                  handleUploadImages={(files, cb) =>
                    handleProductImageUpload(files, cb)
                  }
                  editName={FORM_FIELDS.IMAGES}
                  defaultUrls={
                    Array.isArray(product?.raw_images)
                      ? product?.raw_images?.map(img => img.src)
                      : []
                  }
                  emptyImagesPlaceholderText={
                    isLoading ? 'Loading...' : 'Upload some photos!'
                  }
                  onEditButtonClick={() =>
                    setShowImageOrderPopup(!showImageOrderPopup)
                  }
                />
              </Block>
              <Block>
                <Tabs
                  noPaneMargin
                  panes={[
                    {
                      label: 'Description',
                      pane: (
                        <MarkdownEditorControlled
                          name={FORM_FIELDS.DESCRIPTION}
                          placeholder="Product description goes here"
                          height={300}
                          characterLimit={480}
                        />
                      )
                    },
                    {
                      label: 'Ingredients',
                      pane: <ProductIngredientFields />
                    },
                    {
                      label: 'Nutrition Facts',
                      pane: (
                        <ProductNutriFactFields
                          getAllNutrientOptions={getAllNutrientOptions}
                        />
                      )
                    },
                    {
                      label: 'How To Use',
                      pane: (
                        <MarkdownEditorControlled
                          name={FORM_FIELDS.USAGE_INSTRUCTIONS}
                          placeholder="Product usage instructions go here"
                          height={300}
                          characterLimit={480}
                        />
                      )
                    }
                  ]}
                />
              </Block>
              <Block>
                <Box className={classes.paddingBlock}>
                  <Typography variant="h5">Pricing</Typography>
                  <Box mt={2}>
                    <Typography variant="caption">Tax Code</Typography>
                    <AutocompleteSearchTaxCodesControlled
                      name={FORM_FIELDS.TAX_CATEGORY_ID}
                    />
                  </Box>
                  <Box mt={3}>
                    <Typography variant="h5">Vendor Style</Typography>
                    <Box mt={2}>
                      <Typography variant="caption">
                        Vendor style number
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <TextFieldControlled
                        className={classes.thinInput}
                        placeholder="AES-2030"
                        name={FORM_FIELDS.VENDOR_STYLE_NUMBER}
                      />
                      <Box ml={2} />
                      <Box>
                        <CheckboxBlockControlled
                          className={classes.thinInput}
                          label={
                            isMobileScreen
                              ? 'Apply to all'
                              : 'Apply to all variants'
                          }
                          name={FORM_FIELDS.VENDOR_STYLE_NUMBER_IS_UNIVERSAL}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Block>
            </Grid>

            <Grid item sm={12} md={6} className={classes.gridItem}>
              <Block>
                <Tabs
                  panes={[
                    {
                      label: 'Details',
                      pane: (
                        <Box>
                          <Box className={classes.paddingBlock}>
                            <Typography variant="h5">Availability</Typography>
                            <Box mt={3}>
                              <Typography variant="body1">
                                {stockData.quantity} in stock for{' '}
                                {stockData.inStock} out of{' '}
                                {product?.variants.length} variants
                              </Typography>
                              <Box mt={3}>
                                <Typography variant="body1">
                                  Publish product to
                                </Typography>
                                <AutocompleteSelectLocationsControlled
                                  disabled={
                                    !product?.status ||
                                    product?.status === 'archived'
                                  }
                                  name={FORM_FIELDS.LOCATION_IDS}
                                  groupBy={o =>
                                    product?.location_ids.includes(o.id)
                                      ? 'Published'
                                      : 'Unpublished'
                                  }
                                />
                              </Box>
                            </Box>
                            <Box mt={3} className={classes.flagBlock}>
                              <Box>
                                <Typography variant="body1">
                                  On Consignment?
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  This product is being provided by a brand
                                  partner.
                                </Typography>
                              </Box>
                              <Box>
                                <CheckboxField name={FORM_FIELDS.CONSIGNMENT} />
                              </Box>
                            </Box>
                            <Box mt={3} className={classes.flagBlock}>
                              <Box>
                                <Typography variant="body1">
                                  For users over age 21?
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  This product has a legal age limit.
                                </Typography>
                              </Box>
                              <Box>
                                <CheckboxField
                                  name={FORM_FIELDS.AGES_21_AND_OVER}
                                />
                              </Box>
                            </Box>
                            <Box mt={3} className={classes.flagBlock}>
                              <Box>
                                <Typography variant="body1">
                                  Enable waitlist?
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  This will enable waitlisting for this variant.
                                </Typography>
                              </Box>
                              <Box>
                                <CheckboxField
                                  name={
                                    FORM_FIELDS.RESTOCK_NOTIFICATIONS_ENABLED
                                  }
                                />
                              </Box>
                            </Box>
                            <Box mt={3} className={classes.flagBlock}>
                              <Box>
                                <Typography variant="body1">
                                  Max Order Quantity
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  This limits how much a user can buy per order.
                                </Typography>
                              </Box>
                              <Box>
                                <TextFieldControlled
                                  inputProps={{ min: 0 }}
                                  number
                                  className={classes.qtyInput}
                                  startAdornment={
                                    <Typography variant="body2">Qty</Typography>
                                  }
                                  name={FORM_FIELDS.MAX_ORDER_QUANTITY}
                                />
                              </Box>
                            </Box>
                            <Box mt={3} className={classes.flagBlock}>
                              <Box>
                                <Typography variant="body1">
                                  Exclude From All Promos?
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  This product should be excluded from all
                                  promos.
                                </Typography>
                              </Box>
                              <Box>
                                <CheckboxField
                                  name={FORM_FIELDS.EXCLUDE_FROM_PROMOS}
                                />
                              </Box>
                            </Box>
                            <Box mt={3} className={classes.flagBlock}>
                              <Box>
                                <Typography variant="body1">
                                  Allow Store Credit to Purchase Product
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  Store Credit can be used to purchase this
                                  product.
                                </Typography>
                              </Box>
                              <Box>
                                <CheckboxField
                                  name={FORM_FIELDS.ALLOW_STORE_CREDIT}
                                />
                              </Box>
                            </Box>
                          </Box>

                          <Box className={classes.divider} />

                          <Box className={classes.divider} />

                          <Box className={classes.paddingBlock}>
                            <Typography variant="h5">Organization</Typography>

                            <Box mt={2}>
                              <Box mx={0} my={0.5}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Brand
                                </Typography>
                                <AutocompleteSearchBrandsControlled
                                  name={FORM_FIELDS.BRAND}
                                />
                              </Box>
                              <AutocompleteSelectCategoriesControlled />
                              <Box mx={0} mb={0.5}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Status Code
                                </Typography>
                                <AutocompleteSearchStatusCodesControlled
                                  name={FORM_FIELDS.STATUS_CODE}
                                />
                              </Box>
                              <Box my={2} className={classes.divider} />
                              <Box mx={0} mb={0.5}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Badges
                                </Typography>
                                <AutocompleteSearchBadgesControlled
                                  name={FORM_FIELDS.BADGES}
                                />
                              </Box>
                              <Box mx={0} mb={0.5}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Tags
                                </Typography>
                                <AutocompleteSearchProductTagsControlled
                                  name={FORM_FIELDS.TAGS}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )
                    },
                    {
                      label: 'Access Codes',
                      pane: (
                        <ProductAccessCodesPanel
                          product={product}
                          onDelete={handleDeleteAccessCodes}
                          addAccessCodes={addAccessCodes}
                          updateAccessCodeAvailability={
                            updateAccessCodeAvailability
                          }
                        />
                      )
                    }
                  ]}
                />
              </Block>
            </Grid>
          </Grid>
          <Box mt={4} mx={0}>
            <Tabs
              panes={[
                {
                  label: 'Product Variants',
                  pane: (
                    <Box mt={2}>
                      <Box
                        mt={4}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <SearchInput
                          dataTest="search-variants"
                          name="search"
                          placeholder="Search Variants"
                          value={variantSearchQuery}
                          onChange={({ target: { value } }) => {
                            setVariantSearchQuery(value)
                            setVariantQueryRegex(
                              value && new RegExp(value, 'i')
                            )
                          }}
                        />
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          justifyContent="center"
                          mb={3}
                        >
                          <Button
                            className={classes.reorderButton}
                            label="set-variant-order-button"
                            onClick={() => setShowVariantOrderDialog(true)}
                            size={SIZE.medium}
                            color={COLOR.secondary}
                            dataTest="set-variant-order-button"
                          >
                            <ReorderIcon />
                          </Button>
                          <Button
                            label="add-variant-button"
                            onClick={e =>
                              go(
                                e,
                                `${URL.ADMIN_PRODUCTS}/${product?.id}/create_variant`
                              )
                            }
                            size={SIZE.medium}
                            color={COLOR.secondary}
                            dataTest="variant-add-button"
                            startIcon={<AddIcon />}
                            adaptive
                            disabled={disableVariantCreate}
                          >
                            Variant
                          </Button>
                        </Box>
                      </Box>
                      <DataTable
                        columns={
                          showAllLocationIds
                            ? ALL_LOCATIONS_COLUMNS
                            : SPECIFIC_LOCATION_COLUMNS
                        }
                        headerAction={variantHeaderActions}
                        headerActionOpen={
                          Object.keys(variantSelections).length !== 0
                        }
                        useWindowListener
                      >
                        {filteredVariants.map((variant, idx) => (
                          <VariantItem
                            key={idx}
                            variant={variant}
                            handleSelect={handleVariantSelect}
                            selected={variant.id in variantSelections}
                            locationId={locationId}
                            onProductSummaryClick={e =>
                              handleVariantClick(e, variant)
                            }
                          />
                        ))}
                      </DataTable>
                    </Box>
                  )
                }
              ]}
            />
          </Box>
        </Box>
        <ReorderImagesDialog
          name={FORM_FIELDS.IMAGES}
          title="Manage Photos"
          subtitle="You can remove or sort images to change the order they display on the Product Page"
          open={showImageOrderPopup}
          onClose={() => setShowImageOrderPopup(false)}
          originalImages={
            Array.isArray(product?.raw_images) ? product?.raw_images : []
          }
        />
        <ReorderVariantsDialog
          open={showVariantOrderDialog}
          onClose={() => setShowVariantOrderDialog(false)}
          onConfirm={() => setShowVariantOrderDialog(false)}
        />
      </ControlledForm>
      <BulkUpdateVariantModal
        open={updateDrawerOpen}
        variants={Object.values(variantSelections)}
        onUpdate={handleBulkVariantsUpdate}
        onClose={() => setUpdateDrawerOpen(false)}
      />

      <Dialog
        open={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleDeleteProduct}
        confirmText="Okay"
        hideCancel
      >
        <Box mt={1}>
          <Typography variant="h5">Confirm Action</Typography>
        </Box>
        <Box my={3}>
          <Typography variant="body1">
            Are you sure you want to delete this product? This action cannot be
            undone.
          </Typography>
        </Box>
      </Dialog>

      <Dialog
        open={showConfirmArchiveModal}
        onClose={() => setShowConfirmArchiveModal(false)}
        onConfirm={
          product?.status === 'live'
            ? handleArchiveProduct
            : handleRestoreProduct
        }
        confirmText="Okay"
        hideCancel
      >
        <Box mt={1}>
          <Typography variant="h5">Confirm Action</Typography>
        </Box>
        <Box my={3}>
          <Typography variant="body1">
            Are you sure you want to{' '}
            {product?.status === 'live' ? 'archive' : 'unarchive'} this product?
          </Typography>
        </Box>
      </Dialog>
    </Layout>
  )
}

ProductShow.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(ProductShow)
