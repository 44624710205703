export const FIELD_PATHS = {
  ORDER_STATUS: 'order.status',
  ORDER_DELIVERY_INSTRUCTIONS: 'order.delivery_instructions',
  ORDER_DELIVERY_METHOD: 'order.delivery_method',
  INVENTORY_QUANTITY: 'inventory.quantity'
}

export const LIST_PATHS = {
  LOCATION_ID: 'locations.id',

  PRODUCTS_BRAND_ID: 'products.brand_id',
  PRODUCTS_TITLE: 'products.title',
  PRODUCT_PDP_BLOCKED: 'products.locked',
  PRODUCT_PUBLISHED: 'products.published',
  PRODUCT_IMAGES: 'products.has_images',
  PRODUCTS_VARIANTS_BRAND_ID: 'products_variants.brand_id',
  PRODUCTS_VARIANTS_STATUS: 'variants.status',
  PRODUCTS_VARIANTS_TITLE: 'products_variants.title',
  PRODUCTS_INVENTORY_LOCATION_ID: 'inventory.location_id',
  PRODUCTS_INVENTORY_DO_NOT_BUY: 'inventory.do_not_buy',
  PRODUCTS_TAGS_ID: 'products.tag_ids',
  PRODUCTS_TAGS_NAME: 'products.tag_name',
  PRODUCTS_TIER: 'products.user_tier',
  PRODUCT_STATUS: 'products.status',

  ORDERS_FIRST_NAME: 'orders.first_name',
  ORDERS_NUMBER: 'orders.number',
  ORDERS_DELIVERY_STARTS: 'orders.delivery_starts_at',
  ORDERS_CREATED_AT: 'orders.created_at',
  ORDERS_LOCATION_ID: 'orders.location_id',
  ORDER_USER_ID: 'orders.user_id',
  ORDERS_EMAIL: 'orders.email',
  ORDERS_ADDRESS: 'orders.address1',
  ORDERS_PROMO_ABUSE_RED_FLAGS: 'orders.promo_code_red_flags',
  ORDERS_STATUS: 'orders.status',
  ORDERS_PAYMENT_STATUS: 'orders.payment_status',
  ORDERS_RETURN_STATUS: 'orders.return_status',
  ORDERS_DELIVERY_WINDOWS: 'orders.delivery_window',
  ORDERS_USER_TYPE: 'orders.user_type',

  VARIANTS_SKU: 'variants.sku',
  VARIANTS_TITLE: 'variants.title',
  VARIANTS_PRICE: 'variants.price',
  VARIANTS_LOCATIONS_ID: 'location_id',
  VARIANTS_PO_QUANTITY: 'purchase_order_variants.quantity',
  VARIANTS_TO_QUANTITY: 'transfer_order_variants.quantity',
  VARIANTS_INVENTORY_QUANTITY: 'inventory_quantity',

  USERS_ROLE: 'users.role',
  USERS_FIRST_NAME: 'users.first_name',
  USERS_LAST_NAME: 'users.last_name',
  USERS_EMAIL: 'users.email',
  USERS_CREDIT_BALANCE: 'users.credit_balance',
  USERS_TIER: 'users.referral_tier',
  USERS_EMPLOYEE: 'users.employee',
  USERS_INVESTOR: 'users.investor',
  USERS_BLOCKED: 'users.blocked',

  BRANDS_ID: 'brands.id',
  BRANDS_NAME: 'brands.name',

  BRAND_CUSTOMERS_TOTAL_ORDERS: 'brand_customers.total_orders',
  BRAND_CUSTOMERS_LAST_ORDER_AT: 'brand_customers.last_order_at',
  BRAND_CUSTOMERS_LIFETIME_VALUE: 'brand_customers.lifetime_value',

  BRAND_SALES_ORDER_NUMBER: 'orders.number',

  PURCHASE_ORDERS_ETA: 'purchase_orders.estimated_arrival_at',
  PURCHASE_ORDERS_PO_NUMBER: 'purchase_orders.id',
  PURCHASE_ORDERS_TERMS: 'purchase_orders.terms',
  PURCHASE_ORDERS_ORDER_DATE: 'purchase_orders.order_date',
  PURCHASE_ORDERS_ETA_SORT: 'purchase_orders.estimated_arrival_at',
  PURCHASE_ORDERS_BRAND_FILTER: 'brands.id',
  PURCHASE_ORDERS_TERM_FILTER: 'purchase_orders.terms',
  PURCHASE_ORDERS_STATUS_FILTER: 'purchase_orders.status',
  PURCHASE_ORDERS_PURPOSE_FILTER: 'purchase_orders.purpose',

  TRANSFER_ORDERS_ETA: 'transfer_orders.estimated_arrival_at',
  TRANSFER_ORDERS_TO_NUMBER: 'transfer_orders.id',
  TRANSFER_ORDERS_ORDER_DATE: 'transfer_orders.order_date',
  TRANSFER_ORDERS_ETA_SORT: 'transfer_orders.estimated_arrival_at',
  TRANSFER_ORDERS_STATUS_FILTER: 'transfer_orders.status',
  TRANSFER_ORDERS_TO_LOCATION_FILTER: 'transfer_orders.to_location',
  TRANSFER_ORDERS_FROM_LOCATION_FILTER: 'transfer_orders.from_location',

  COLLECTIONS_PUBLISHED: 'collections.published',
  COLLECTIONS_COLLECTION_TYPE: 'collections.collection_type',
  COLLECTIONS_PARENT: 'collections.is_parent',
  COLLECTIONS_CHILD: 'collections.is_child',

  STATUS_CODES_CODE: 'status_codes.name',
  STATUS_CODES_USAGE: 'status_codes.products_count',
  STATUS_CODES_CREATED_AT: 'status_codes.created_at',
  STATUS_CODES_STATUS: 'status_codes.active',

  BADGES_BADGE_NAME: 'badges.name',
  BADGES_USAGE: 'badges.products_count',
  BADGES_CREATED_AT: 'badges.created_at',
  BADGES_STATUS: 'badges.active',

  SIZES_NAME: 'sizes.name',
  SIZES_USAGE: 'sizes.variants_count',
  SIZES_CREATED_AT: 'sizes.created_at',
  SIZES_STATUS: 'sizes.active',

  ACCESSORY_OPTIONS_NAME: 'accessory_options.name',
  ACCESSORY_OPTIONS_USAGE: 'accessory_options.variants_count',
  ACCESSORY_OPTIONS_CREATED_AT: 'accessory_options.created_at',
  ACCESSORY_OPTIONS_STATUS: 'accessory_options.active',

  CATEGORIES_NAME: 'categories.name',
  CATEGORIES_CREATED_AT: 'categories.created_at',
  CATEGORIES_STATUS: 'categories.active',

  SUBCATEGORIES_NAME: 'subcategories.name',
  SUBCATEGORIES_PARENT: 'subcategories.category',
  SUBCATEGORIES_CREATED_AT: 'subcategories.created_at',
  SUBCATEGORIES_STATUS: 'subcategories.active',

  COLOR_LABEL: 'colors.label',
  COLOR_SHOPIFY_COLOR: 'colors.shopify_color',
  COLOR_MASTER_COLOR: 'master_colors.label',
  COLOR_VARIANTS_COUNT: 'colors.variants_count',
  COLOR_CREATED_AT: 'colors.created_at',
  COLOR_STATUS: 'colors.active',

  MASTER_COLOR_LABEL: 'master_colors.label',
  MASTER_COLOR_COLORS_COUNT: 'master_colors.colors_count',
  MASTER_COLOR_CREATED_AT: 'master_colors.created_at',

  PROMO_CODES_CREATED: 'discount_codes.created_at',
  PROMO_CODES_UPDATED: 'discount_codes.updated_at',
  PROMO_CODES_CODE: 'discount_codes.code',
  PROMO_CODES_CREATED_BY: 'discount_codes.author',
  PROMO_CODES_USE_COUNT: 'discount_codes.use_count',
  PROMO_CODES_START_TIME: 'discount_codes.start_time',
  PROMO_CODES_END_TIME: 'discount_codes.end_time',
  PROMO_CODES_STATUS: 'discount_codes.status',

  PLATE_EXPIRATION_DATE: 'plates.expiration_date',
  PLATE_QUANTITY: 'plates.quantity',
  PLATE_TOTAL_QUANTITY: 'variant_total_quantity',
  PLATE_PULL_DATE: 'plate_pull_date',

  DISCOUNTS_CREATED_AT: 'discounts.created_at',
  PROMOTIONS_ACTIVE_STATUS: 'active_status',

  INTERNAL_USER_LAST_NAME: 'internal_users.last_name',
  INTERNAL_USER_FIRST_NAME: 'internal_users.first_name',
  INTERNAL_USER_EMAIL: 'internal_users.email',
  INTERNAL_USER_DEFAULT_LOCATION_ID: 'internal_users.default_location_id',

  SUPPLIER_NAME: 'suppliers.name',
  DISTRIBUTION_CENTER_ID: 'distribution_centers.id',
  DISTRIBUTION_CENTER_NAME: 'distribution_centers.name'
}

export const LIST_LABELS = {
  [LIST_PATHS.PRODUCTS_TITLE]: 'Product title',
  [LIST_PATHS.PRODUCTS_VARIANTS_TITLE]: 'Product title',
  [LIST_PATHS.ORDERS_STATUS]: 'Order status',
  [LIST_PATHS.ORDERS_FIRST_NAME]: 'Customer Name',
  [LIST_PATHS.ORDERS_NUMBER]: 'Order number',
  [LIST_PATHS.ORDERS_DELIVERY_STARTS]: 'Pickup time',
  [LIST_PATHS.VARIANTS_SKU]: 'Sku',
  [LIST_PATHS.VARIANTS_TITLE]: 'Variant title',
  [LIST_PATHS.VARIANTS_PRICE]: 'Variant price',
  [LIST_PATHS.USERS_ROLE]: 'Role',
  [LIST_PATHS.USERS_FIRST_NAME]: 'First name',
  [LIST_PATHS.USERS_LAST_NAME]: 'Last name',
  [LIST_PATHS.USERS_REFERRAL_TIER]: 'Tier',
  [LIST_PATHS.USERS_CREDIT_BALANCE]: 'Credit Balance',
  [LIST_PATHS.USERS_EMAIL]: 'Email',

  [LIST_PATHS.PLATE_EXPIRATION_DATE]: 'Expiration Date',
  [LIST_PATHS.PLATE_QUANTITY]: 'Quantity'
}

export const API_PARAMS = {
  SEARCH: 'search',
  SEARCH_BY_NUMBER: 'search_by_number',
  SORT: 'sort_key',
  SORT_DIRECTION: 'sort_direction',
  LOCATION: 'location_id'
}

export const SORT = {
  ASC: 'asc',
  DESC: 'desc'
}

export const SORT_DEFAULT = SORT.ASC

// rel can be ['=', '!=', '>', '>=', '<', '<=', 'between'], default '='
const makeFilter = (field, relationship, value) => ({
  [field]: value,
  ...(relationship === '=' ? {} : { [`${field}.rel`]: relationship })
})

// So these can fit on one line
const FP = FIELD_PATHS

export const LOW_STOCK_INVENTORY = makeFilter(FP.INVENTORY_QUANTITY, '<', 2)

export const HIGH_STOCK_INVENTORY = makeFilter(FP.INVENTORY_QUANTITY, '>=', 5)

export const SOLD_OUT_INVENTORY = makeFilter(FP.INVENTORY_QUANTITY, '=', 0)
